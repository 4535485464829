import React from 'react';
import { Chip, TableContainer } from '@mui/material';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
/* eslint-disable camelcase */
const HeadDetails = ({ selectedCustomer, clientInfo }) => (React.createElement(TableContainer, null,
    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
        React.createElement(TableBody, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Client: "),
                React.createElement(TableCell, null, (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name) || '')),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Customer name"),
                React.createElement(TableCell, null, (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.company_name) || '')),
            React.createElement(TableRow, null,
                React.createElement(TableCell, null, "Customer code"),
                React.createElement(TableCell, null,
                    React.createElement(Chip, { size: "small", label: (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.code) || '' })))))));
export default HeadDetails;
