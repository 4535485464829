import React, { Fragment, useCallback, useState } from 'react';
import classNames from 'classnames';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorsForm from './ErrorsForm';
import showNotification from '../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { Dialog } from '@mui/material';
const ErrorsModal = ({ open, theme, classProps, toggleFunc, }) => {
    const { ErrorLogger } = window;
    const loggedErrors = ErrorLogger.getErrors();
    const [colored] = useState(false);
    const [header] = useState(true);
    const [color] = useState('primary');
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleFunction = (logs) => {
        const payload = {
            logs: JSON.stringify(logs),
        };
        axios.post('/user/registration', payload)
            .then(() => {
            showNotification({
                message: 'Errors successfully sent',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
        });
    };
    const backdropTrue = true;
    return (React.createElement(Fragment, null,
        React.createElement(Dialog, { open: open, onClose: toggleFunc },
            React.createElement("div", { className: "modal__header" },
                React.createElement(FontAwesomeIcon, { className: "modal__close-btn", icon: faTimes, onClick: toggleFunc }),
                React.createElement("h4", { className: "text-modal  modal__title" }, "Errors reporting")),
            React.createElement("div", { className: "modal__body" },
                React.createElement("div", { className: "loading_block_flex" },
                    React.createElement(ErrorsForm, { logs: loggedErrors, toggleFunc: toggleFunc, handleFunction: handleFunction }))))));
};
const mapStateToProps = (state) => ({
    theme: state.theme,
});
export default connect(mapStateToProps)(ErrorsModal);
