import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import { WithdrawalBoost, WithdrawalAbort, WithdrawalDelete, WithdrawalMasterSign, } from './components/WithdrawalsActions';
import { MaterialPopoverWrap } from '../../styling/style';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
const WithdrawalsCellActions = ({ item, admin, features, fetchAllWithDrawals, currencies, clientInfo, }) => {
    var _a;
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [boostableList, setBoostableList] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [action, setAction] = useState(undefined);
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (userAction) => {
        toggleOpenDialog(prev => !prev);
        setAction(userAction);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const deleteStates = ['pending_admin_approve', 'pending_sign'];
    const states = ['pending_admin_approve', 'pending_sign', 'unknown_creation_error', 'pending'];
    const renderActionsContent = (userAction, toggle) => {
        if (!userAction) {
            return React.createElement("div", null, "something went wrong");
        }
        if (userAction === 'masterSign') {
            return (React.createElement(WithdrawalMasterSign, { item: item, actionKey: "mastersign", toggleDialog: toggle, fetchAllWithDrawals: fetchAllWithDrawals }));
        }
        if (userAction === 'sign') {
            return (React.createElement(WithdrawalMasterSign, { item: item, actionKey: "sign", toggleDialog: toggle, fetchAllWithDrawals: fetchAllWithDrawals }));
        }
        if (userAction === 'abort') {
            return React.createElement(WithdrawalAbort, { item: item, toggleDialog: toggle, fetchAllWithDrawals: fetchAllWithDrawals });
        }
        if (userAction === 'boost') {
            return React.createElement(WithdrawalBoost, { item: item, toggleDialog: toggle, fetchAllWithDrawals: fetchAllWithDrawals });
        }
        return React.createElement(WithdrawalDelete, { item: item, toggleDialog: toggle, fetchAllWithDrawals: fetchAllWithDrawals });
    };
    useEffect(() => {
        if (currencies.length) {
            setBoostableList(currencies.filter(c => c.network.is_boostable).map(c => c.code.toUpperCase()));
        }
        else {
            setBoostableList([]);
        }
    }, [currencies]);
    const canSign = (item) => {
        let result = false;
        if (!admin) {
            result = true;
        }
        else if (item.client_code === (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code)) {
            result = true;
        }
        return result;
    };
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { size: "small", "aria-label": "more", onClick: handleActionsClick, disabled: !states.includes(item.state) },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { component: "nav", "aria-label": "more actions", dense: true },
                    deleteStates.includes(item.state) && (React.createElement(Permissions, { features: features, permissions: ['client_custody_transaction.delete'] },
                        React.createElement(ListItem, { onClick: () => toggleDialog('delete'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(DeleteIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { className: "trading_clients_th_name", primary: "Delete" })))),
                    item.state === 'pending_sign' && (React.createElement(Permissions, { features: features, permissions: ['client_custody_withdrawal_master_sign.create'] },
                        React.createElement(ListItem, { onClick: () => toggleDialog('masterSign'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(CreateIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { className: "trading_clients_th_name", primary: "Master sign" })))),
                    (item.state === 'pending_sign' && canSign(item)) && (React.createElement(Permissions, { features: features, permissions: ['client_custody_transaction.update'] },
                        React.createElement(ListItem, { onClick: () => toggleDialog('sign'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(CreateIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { className: "trading_clients_th_name", primary: "Sign" })))),
                    (item.state === 'pending' && boostableList.includes((_a = item.currency_code) === null || _a === void 0 ? void 0 : _a.toUpperCase())) && (React.createElement(Permissions, { features: features, permissions: ['client_custody_transaction.create'] },
                        React.createElement(ListItem, { onClick: () => toggleDialog('boost'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(CreateIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { className: "trading_clients_th_name", primary: "Boost" })))),
                    (item.state === 'unknown_creation_error' && admin) && (React.createElement(Permissions, { features: features, permissions: ['client_custody_withdrawal_problem.update'] },
                        React.createElement(ListItem, { onClick: () => toggleDialog('abort'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(CreateIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { className: "trading_clients_th_name", primary: "Abort" }))))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Actions"),
            React.createElement(DialogContent, null, action ? renderActionsContent(action, toggleDialog) : null))));
};
export default WithdrawalsCellActions;
