/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any */
const recordsDependsOnPage = (records, page) => {
    const startSlice = page * 10 - 10;
    const endSlice = page * 10;
    const tenRecords = records.length < 10 ? records : records.slice(startSlice, endSlice);
    return tenRecords;
};
export const materialUiPaginator = (records, page, rowsPerPage) => {
    const startSlice = page * rowsPerPage;
    const endSlice = page * rowsPerPage + rowsPerPage;
    return records.length < rowsPerPage ? records : records.slice(startSlice, endSlice);
};
export default recordsDependsOnPage;
