import { Box, Button, Checkbox, Grid, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
const typeOptions = [
    { value: 'ethereum', label: 'Ethereum' },
    { value: 'bitcoin', label: 'Bitcoin' },
    { value: 'bitcoin_cash', label: 'Bitcoin Cash' },
    { value: 'litecoin', label: 'Litecoin' },
    { value: 'ripple', label: 'Ripple' },
    { value: 'polkadot', label: 'Polkadot' },
    { value: 'stellar', label: 'Stellar' },
    { value: 'avalanche', label: 'Avalanche' },
    { value: 'ethereum_classic', label: 'Ethereum Classic' },
    { value: 'solana', label: 'Solana' },
    { value: 'algorand', label: 'Algorand' },
    { value: 'binance_smart_chain', label: 'Binance Smart Chain' },
    { value: 'cardano', label: 'Cardano' },
    { value: 'cosmos', label: 'Cosmos' },
    { value: 'polygon', label: 'Polygon' },
    { value: 'celestia', label: 'Celestia' },
    { value: 'celo', label: 'Celo' },
    { value: 'arbitrum', label: 'Arbitrum' },
    { value: 'optimism', label: 'Optimism' },
    { value: 'tron', label: 'Tron' },
    { value: 'near_network', label: 'Near Network' },
    { value: 'tezos', label: 'Tezos' },
    { value: 'zcash', label: 'Zcash' },
    { value: 'dogecoin', label: 'Dogecoin' },
    { value: 'dash_network', label: 'Dash Network' },
    { value: 'flare', label: 'Flare' },
    { value: 'fantom', label: 'Fantom' },
    { value: 'zksync_era_network', label: 'Zkync Era Network' },
];
const feeOptions = [
    { value: 'fee_per_byte', label: 'Fee per Byte' },
    { value: 'network_fee', label: 'Network Fee' },
    { value: 'gas_price', label: 'Gas Price' },
];
const formSchema = yup
    .object({
    name: yup.string().required().default(''),
    type: yup
        .string()
        .required()
        .default('')
        .oneOf([
        'ethereum',
        'bitcoin',
        'bitcoin_cash',
        'litecoin',
        'ripple',
        'polkadot',
        'stellar',
        'avalanche',
        'ethereum_classic',
        'solana',
        'algorand',
        'binance_smart_chain',
        'cardano',
        'cosmos',
        'polygon',
        'celestia',
        'celo',
        'arbitrum',
        'optimism',
        'tron',
        'near_network',
        'tezos',
        'zcash',
        'dogecoin',
        'dash_network',
        'flare',
        'fantom',
        'zksync_era_network',
    ]),
    fees_type: yup.string().required().default('').oneOf(['fee_per_byte', 'network_fee', 'gas_price']),
    native_currency_code: yup.string().required().default(''),
    can_have_child_wallets: yup.boolean(),
    is_multi_source: yup.boolean(),
    is_boostable: yup.boolean(),
    is_utxo: yup.boolean(),
    elliptic_network_code: yup.string(),
    trmlabs_network_code: yup.string(),
    explorer_url: yup.string(),
})
    .required();
const formConfig = {
    name: { label: 'Name', type: 'text', required: true },
    type: {
        label: 'Type',
        type: 'select',
        required: true,
        options: typeOptions,
    },
    fees_type: {
        label: 'Fee Type',
        type: 'select',
        required: true,
        options: feeOptions,
    },
    native_currency_code: { label: 'Native Currency Code', type: 'text', required: true },
    elliptic_network_code: { label: 'Elliptic Network Code', type: 'text', required: false },
    trmlabs_network_code: { label: 'TRMLabs Network Code', type: 'text', required: false },
    explorer_url: { label: 'Explorer URL', type: 'text', required: false },
    empty: { type: 'empty' },
    can_have_child_wallets: { label: 'Child Wallets', type: 'checkbox', required: false },
    is_multi_source: { label: 'Multi Source', type: 'checkbox', required: false },
    is_boostable: { label: 'Boostable', type: 'checkbox', required: false },
    is_utxo: { label: 'UTXO', type: 'checkbox', required: false },
};
const NetworkCreate = (props) => {
    const { onClose, onSave, loading } = props;
    const { handleSubmit, control, register, trigger, setValue, getValues, formState: { errors }, } = useForm({
        defaultValues: formSchema.cast(formSchema.getDefault()),
        resolver: yupResolver(formSchema),
    });
    return (React.createElement(Box, { component: 'form', onSubmit: handleSubmit(onSave) },
        React.createElement(Grid, { container: true, spacing: 2, py: 2 }, Object.keys(formConfig).map((s, i) => {
            const key = s;
            const field = formConfig[key];
            return (React.createElement(Grid, { item: true, xs: 6, key: i },
                field.type === 'empty' && React.createElement(Box, null),
                field.type === 'text' && (React.createElement(TextField, Object.assign({ fullWidth: true, InputLabelProps: { shrink: true }, error: !!errors[key], size: 'small', required: field.required, label: field.label, placeholder: field.label }, register(key), { onChange: (e) => {
                        setValue(key, e.target.value);
                        trigger(key);
                    } }))),
                field.type === 'select' && (React.createElement(Controller, { name: key, control: control, render: ({ field }) => {
                        var _a;
                        return (React.createElement(TextField, Object.assign({ fullWidth: true, select: true }, field, { size: 'small', error: !!errors[key], InputLabelProps: { shrink: true }, required: formConfig[key].required, label: formConfig[key].label, onChange: (e) => {
                                field.onChange(e.target.value);
                                setValue(key, e.target.value);
                                trigger(key);
                            } }), (_a = formConfig[key].options) === null || _a === void 0 ? void 0 : _a.map((o, i) => (React.createElement(MenuItem, { key: i, value: o.value }, o.label)))));
                    } })),
                field.type === 'checkbox' && (React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' } },
                    React.createElement(Controller, { name: key, control: control, render: ({ field }) => (React.createElement(Checkbox, Object.assign({}, field, { required: formConfig[key].required, checked: !!getValues(key), onChange: (e, checked) => {
                                field.onChange(checked);
                                setValue(key, checked);
                                trigger(key);
                            } }))) }),
                    React.createElement(Typography, { variant: 'body1' }, field.label)))));
        })),
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'flex-end', gap: 1 } },
            React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained' }, "Add"),
            React.createElement(Button, { onClick: onClose }, "Cancel"))));
};
export default NetworkCreate;
