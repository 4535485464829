import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tab, Tabs } from '@mui/material';
import OrderFormContainer from './OrderFormContainer';
import { NEW_ORDER_AMOUNT_SET_UPDATE, NEW_ORDER_TOTAL_PRICE_UPDATE, } from '../../../../../../redux/actionTypes/placeOrderActionTypes';
const PlaceOrderBody = () => {
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const changeTab = (tab) => {
        setActiveTab(tab);
        // clean amount and price fields
        dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: '' });
        dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: '' });
    };
    return (React.createElement("div", null,
        React.createElement(Tabs, { value: activeTab, variant: "fullWidth" },
            React.createElement(Tab, { onClick: () => { changeTab(0); }, label: "BUY" }),
            React.createElement(Tab, { onClick: () => { changeTab(1); }, label: "SELL" })),
        React.createElement("div", { hidden: activeTab !== 0 },
            React.createElement(OrderFormContainer, { action: "Buy" })),
        React.createElement("div", { hidden: activeTab !== 1 },
            React.createElement(OrderFormContainer, { action: "Sell" }))));
};
export default PlaceOrderBody;
