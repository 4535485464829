var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useCallback } from 'react';
import { Tab, Tabs } from '@mui/material';
import buildUrl from 'build-url';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { getAddressesToRender, getWithdrawalsToRender, getMasterTenantWithdrawals, } from '../../../../../../redux/selectors/masterTenantSelectors';
import { LOADING_ADDRESSES_UPDATE, MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE, MASTER_TENANT_APPROVALS_WITHDRAWALS_UPDATE, } from '../../../../../../redux/actionTypes/masterTenantActionTypes';
import Addresses from './Addresses';
import Withdrawals from './Withdrawals';
import UsersService from '../../../../../../services/usersService';
import Messages from '../../../../../../shared/helpers/errorMessages';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import showNotification from '../../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { materialUiPaginator } from '../../../../../../shared/helpers/recordsDependsOnPage';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import WithdrawalsAddressesService from '../../../../../../services/withdrawalsAddressesService';
import CustodyClientWithdrawalsService from '../../../../../../services/custodyWithdrawalsService';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { AddressDelete, AddressSign } from '../../../../Custody/components/Whitelist/components/AddressesActions';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const Approve = ({ meCode, features, selectedClient, withdrawals, walletsLoading, masterTenant, clientInfo, allAddresses, columns, allWithdrawals, approveWidgetHeight, currencies, }) => {
    const [state, setState] = useState('');
    const [loading, setLoading] = useState(false);
    const [clientCode, setClientCode] = useState('');
    const [usersRead, setUsersRead] = useState(false);
    const [maxHeightA, setMaxHeightA] = useState(480);
    const [maxHeightW, setMaxHeightW] = useState(480);
    const [rowsPerPageA, setRowsPerPageA] = useState(10);
    const [rowsPerPageW, setRowsPerPageW] = useState(10);
    const [pageAddresses, setPageAddresses] = useState(0);
    const [clientsRead, setClientsRead] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [pageWithdrawals, setPageWithdrawals] = useState(0);
    const [users, setUsers] = useState([]);
    const [totalAddresses, setTotalAddresses] = useState(allAddresses.length);
    const [sortDirectionA, setSortDirectionA] = useState('asc');
    const [sortDirectionW, setSortDirectionW] = useState('asc');
    const [totalWithdrawals, setTotalWithdrawals] = useState(allWithdrawals.length);
    const [anchorColumnsA, setAnchorColumnsA] = useState(null);
    const [anchorColumnsW, setAnchorColumnsW] = useState(null);
    const [sortByW, setSortByW] = useState('created');
    const [columnsToRenderA, setColumnsToRenderA] = useState([]);
    const [columnsToRenderW, setColumnsToRenderW] = useState([]);
    const [withdrawalsToRender, setWithdrawalsToRender] = useState([]);
    const [addressesToRender, setAddressesToRender] = useState([]);
    const [sortByA, setSortByA] = useState('created_at');
    const columnsOpenA = Boolean(anchorColumnsA);
    const columnsOpenW = Boolean(anchorColumnsW);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClickA = (event) => {
        setAnchorColumnsA(event.currentTarget);
    };
    const handleColumnsClickW = (event) => {
        setAnchorColumnsW(event.currentTarget);
    };
    const handleColumnsCloseA = () => {
        setAnchorColumnsA(null);
    };
    const handleColumnsCloseW = () => {
        setAnchorColumnsW(null);
    };
    const onSortHandleA = (key) => {
        setPageAddresses(0);
        setSortByA(key);
        setSortDirectionA(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const onSortHandleW = (key) => {
        setPageWithdrawals(0);
        setSortByW(key);
        setSortDirectionW(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const hideShowColumnW = (colId) => {
        const column = columns.find(c => c.id === colId);
        const colIndex = columnsToRenderW.findIndex(c => c.id === (column === null || column === void 0 ? void 0 : column.id));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRenderW([...columnsToRenderW]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const hideShowColumnA = (colId) => {
        const column = columns.find(c => c.id === colId);
        const colIndex = columnsToRenderA.findIndex(c => c.id === (column === null || column === void 0 ? void 0 : column.id));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRenderA([...columnsToRenderA]);
        }
        dispatch(applyAllSettingsChanges('custody'));
    };
    const fetchAllWithDrawals = (code, cancelToken) => {
        setLoading(true);
        const url = `/custody/withdrawals?limit=1000&clientCode=${code}`;
        const service = new CustodyClientWithdrawalsService({ url, method: 'get', cancelToken });
        service.makeRequest()
            .then((data) => {
            setLoading(false);
            dispatch({ type: MASTER_TENANT_APPROVALS_WITHDRAWALS_UPDATE, approvalsWithdrawals: data.records });
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
            const message = composeErrorMessage(e, Messages.MANAGE_WITHDRAWALS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchAllAddresses = (code, cancelToken) => {
        setLoading(true);
        dispatch({ type: LOADING_ADDRESSES_UPDATE, loadingAddresses: true });
        const url = `/custody/withdrawals/addresses?limit=1000&clientCode=${code}`;
        const service = new WithdrawalsAddressesService({ url, method: 'get', cancelToken });
        service.makeRequest()
            .then((data) => {
            setLoading(false);
            dispatch({ type: LOADING_ADDRESSES_UPDATE, loadingAddresses: false });
            dispatch({ type: MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE, approvalsAddresses: data.records });
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
            dispatch({ type: LOADING_ADDRESSES_UPDATE, loadingAddresses: false });
            dispatch({ type: MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE, approvalsAddresses: [] });
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const onChangePageA = (event, pageNumber) => {
        setPageAddresses(pageNumber);
    };
    const onChangePageW = (event, pageNumber) => {
        setPageWithdrawals(pageNumber);
    };
    const changeTab = (tab) => {
        setActiveTab(tab);
    };
    const fetchUsers = (code) => __awaiter(void 0, void 0, void 0, function* () {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                search: '',
                client_code: code,
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
            path: '/users',
        };
        const endpointUrl = buildUrl('', urlOptions);
        const usersService = new UsersService({
            url: endpointUrl,
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        const usersByClient = yield usersService.makeRequest();
        return usersByClient;
    });
    const updateItemInArray = (items, item) => {
        const addressIndex = items.findIndex(a => a.code === item.code);
        return items.map((address, index) => {
            if (index !== addressIndex) {
                return address;
            }
            const mergedItem = Object.assign(Object.assign({}, address), { state: item.state });
            return Object.assign(Object.assign({}, address), mergedItem);
        });
    };
    const cellActions = {
        actions: {
            deleteItem: (item) => {
                const updatedAddresses = updateItemInArray(allAddresses, item);
                dispatch({ type: MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE, approvalsAddresses: updatedAddresses });
            },
            sign: (item) => {
                const updatedAddresses = updateItemInArray(allAddresses, item);
                dispatch({ type: MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE, approvalsAddresses: updatedAddresses });
            },
            masterSign: (item) => {
                const updatedAddresses = updateItemInArray(allAddresses, item);
                dispatch({ type: MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE, approvalsAddresses: updatedAddresses });
            },
            approve: (item) => {
                const updatedAddresses = updateItemInArray(allAddresses, item);
                dispatch({ type: MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE, approvalsAddresses: updatedAddresses });
            },
            confirm: (item) => {
                const addressIndex = addressesToRender.findIndex(a => a.code === item.code);
                if (addressIndex !== -1) {
                    const r = [...addressesToRender.slice(0, addressIndex), ...addressesToRender.slice(addressIndex + 1)];
                    dispatch({ type: MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE, approvalsAddresses: r });
                }
            },
        },
        actionsComponents: {
            deleteItem: AddressDelete,
            confirm: AddressDelete,
            sign: AddressSign,
            masterSign: AddressSign,
            approve: AddressSign,
        },
        actionsList: [{ name: 'Delete', action: 'deleteItem' },
            { name: 'Master sign', action: 'masterSign' },
            { name: 'Approve', action: 'approve' },
            { name: 'Confirm', action: 'confirm' },
            { name: 'Sign', action: 'sign' },
        ],
    };
    const sortStoredAddresses = (addressesToSort, direction, by) => {
        const sorter = (dir, a, b) => dir === 'desc'
            ? (a > b)
                ? 1 : -1
            : (a < b)
                ? 1 : -1;
        const sortFunc = (a, b, dir, key) => {
            const k = key;
            let valueA;
            let valueB;
            if (k) {
                valueA = a[k];
                valueB = b[k];
                if (valueA && valueB) {
                    // value is of a string type
                    if (typeof valueA === 'string' && typeof valueB === 'string') {
                        return sorter(dir, valueA, valueB);
                    }
                }
            }
            // or maybe throw exception
            return 0;
        };
        return addressesToSort.sort((a, b) => sortFunc(a, b, direction, by));
    };
    const sortStoredWithdrawals = (withdrawalsToSort, direction, by) => {
        const sorterNum = (dir, a, b) => dir === 'desc'
            ? (a - b) : (b - a);
        const sorter = (dir, a, b) => dir === 'desc'
            ? (a > b)
                ? 1 : -1
            : (a < b)
                ? 1 : -1;
        const sortFunc = (a, b, dir, key) => {
            const valueA = a[key];
            const valueB = b[key];
            if (valueA && valueB) {
                if (isNaN(+valueA) && isNaN(+valueB)) {
                    return sorter(dir, valueA, valueB);
                }
                return sorterNum(dir, +valueA, +valueB);
            }
            return 0;
        };
        return withdrawalsToSort.sort((a, b) => sortFunc(a, b, direction, by));
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (clientCode) {
            fetchAllWithDrawals(clientCode, cancelTokenSource.token);
            fetchAllAddresses(clientCode, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [clientCode]);
    useEffect(() => {
        const canReadUsers = features && Array.isArray(features) && features.length && features.includes('users.read');
        setUsersRead(canReadUsers || false);
        const canReadClients = features && Array.isArray(features) && features.length && features.includes('tenants.read');
        setClientsRead(canReadClients || false);
        const canUpdateAddresses = features && Array.isArray(features) && features.length
            && features.includes('client_custody_withdrawal_address.update');
        const canSignAddresses = features && Array.isArray(features) && features.length
            && features.includes('client_custody_address_master_sign.create');
        if (!canUpdateAddresses && !canSignAddresses) {
            setActiveTab(1);
        }
    }, [features]);
    useEffect(() => {
        if (meCode) {
            let filtered = allAddresses.filter(a => a.creator_code !== meCode && a.state === state);
            if (users.length !== 0) {
                filtered = filtered.map((a) => {
                    const creatorUser = users.find(user => user.code === a.creator_code);
                    const creatorName = creatorUser
                        ? creatorUser.name ? `${creatorUser.name} (${creatorUser.email})` : creatorUser.email
                        : '';
                    return Object.assign({}, a, { creator: creatorName });
                });
            }
            const items = materialUiPaginator(filtered, pageAddresses, rowsPerPageA);
            setAddressesToRender([...items]);
            setTotalAddresses(filtered.length);
        }
    }, [allAddresses, meCode, state, users, rowsPerPageA]);
    useEffect(() => {
        if (sortByA) {
            let filtered = allAddresses.filter(a => a.creator_code !== meCode && a.state === state);
            if (users.length !== 0) {
                filtered = filtered.map((a) => {
                    const creatorUser = users.find(user => user.code === a.creator_code);
                    const creatorName = creatorUser
                        ? creatorUser.name ? `${creatorUser.name} (${creatorUser.email})` : creatorUser.email
                        : '';
                    return Object.assign({}, a, { creator: creatorName });
                });
            }
            const sorted = sortStoredAddresses(filtered, sortDirectionA, sortByA);
            const items = materialUiPaginator(sorted, pageAddresses, rowsPerPageA);
            setAddressesToRender([...items]);
        }
    }, [sortByA, sortDirectionA, pageAddresses, meCode, state, users, allAddresses, rowsPerPageA]);
    useEffect(() => {
        let filtered = allWithdrawals.filter(w => w.state === 'pending_sign');
        if (users.length !== 0) {
            filtered = filtered.map((a) => {
                const item = withdrawals.find(w => w.code === a.code);
                const creatorUser = item ? users.find(user => user.code === item.creator_code) : null;
                const creatorName = creatorUser
                    ? creatorUser.name ? `${creatorUser.name} (${creatorUser.email})` : creatorUser.email
                    : '';
                return Object.assign({}, a, { creator: creatorName });
            });
        }
        const items = materialUiPaginator(filtered, pageWithdrawals, rowsPerPageW);
        setWithdrawalsToRender([...items]);
        setTotalWithdrawals(filtered.length);
    }, [allWithdrawals, withdrawals, rowsPerPageW]);
    useEffect(() => {
        if (sortByW) {
            let filtered = allWithdrawals.filter(w => w.state === 'pending_sign');
            if (users.length !== 0) {
                filtered = filtered.map((a) => {
                    const item = withdrawals.find(w => w.code === a.code);
                    const creatorUser = item ? users.find(user => user.code === item.creator_code) : null;
                    const creatorName = creatorUser
                        ? creatorUser.name ? `${creatorUser.name} (${creatorUser.email})` : creatorUser.email
                        : '';
                    return Object.assign({}, a, { creator: creatorName });
                });
            }
            const sorted = sortStoredWithdrawals(filtered, sortDirectionW, sortByW);
            const items = materialUiPaginator(sorted, pageWithdrawals, rowsPerPageW);
            setWithdrawalsToRender([...items]);
        }
    }, [sortByW, sortDirectionW, pageWithdrawals, rowsPerPageW, allWithdrawals, columnsToRenderW]);
    useEffect(() => {
        if (usersRead && clientsRead) {
            const status = masterTenant ? 'pending_admin_approve' : 'pending_sign';
            setState(status);
            setLoading(true);
            if (selectedClient) {
                const uuid = selectedClient.value || '';
                const fetchClientUser = () => __awaiter(void 0, void 0, void 0, function* () {
                    const clientUsers = yield fetchUsers(uuid);
                    setUsers(clientUsers.records);
                    setLoading(false);
                });
                fetchClientUser().catch((e) => {
                    const message = composeErrorMessage(e, Messages.USERS_FETCH);
                    showNotification({
                        message,
                        color: 'error',
                        dispatch: errorNotice,
                    });
                });
            }
            else {
                const fetchClientUser = () => __awaiter(void 0, void 0, void 0, function* () {
                    const clientUsers = yield fetchUsers('');
                    setUsers(clientUsers.records);
                    setLoading(false);
                });
                fetchClientUser().catch((e) => {
                    const message = composeErrorMessage(e, Messages.USERS_FETCH);
                    showNotification({
                        message,
                        color: 'error',
                        dispatch: errorNotice,
                    });
                });
            }
        }
    }, [masterTenant, selectedClient, usersRead, clientsRead]);
    useEffect(() => {
        let code = '';
        if (selectedClient) {
            code = selectedClient.value;
        }
        setClientCode(code);
    }, [selectedClient]);
    useEffect(() => {
        if (columns && columns.length) {
            const colsA = columns.filter(c => c.value).filter(c => c.id.includes('addressesApproveComplianceColumns'));
            const colsW = columns.filter(c => c.value).filter(c => c.id.includes('withdrawalsApproveComplianceColumns'));
            setColumnsToRenderA([...colsA]);
            setColumnsToRenderW([...colsW]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(approveWidgetHeight, setRowsPerPageA, setMaxHeightA);
        resizeWidgetHeight(approveWidgetHeight, setRowsPerPageW, setMaxHeightW);
    }, [approveWidgetHeight]);
    return (React.createElement("div", { className: "tabs tabs--bordered-bottom" },
        React.createElement("div", { className: "tabs__wrap" },
            React.createElement(Tabs, { value: activeTab },
                React.createElement(Permissions, { oneOf: true, features: features, permissions: ['client_custody_address_master_sign.create', 'client_custody_withdrawal_address.update'] },
                    React.createElement(Tab, { label: "Addresses", onClick: () => { changeTab(0); } })),
                React.createElement(Permissions, { oneOf: true, features: features, permissions: ['client_custody_withdrawal_master_sign.create', 'client_custody_transaction.update'] },
                    React.createElement(Tab, { label: "Withdrawals", onClick: () => { changeTab(1); } }))),
            activeTab === 0 ?
                React.createElement(Permissions, { oneOf: true, features: features, permissions: ['client_custody_address_master_sign.create', 'client_custody_withdrawal_address.update'] },
                    React.createElement(Addresses, { meCode: meCode, sortBy: sortByA, loading: loading, columns: columns, features: features, admin: masterTenant, maxHeight: maxHeightA, clientInfo: clientInfo, clientCode: clientCode, cellActions: cellActions, columnsOpen: columnsOpenA, rowsPerPage: rowsPerPageA, onSortHandle: onSortHandleA, pageAddresses: pageAddresses, onChangePageA: onChangePageA, sortDirection: sortDirectionA, anchorColumns: anchorColumnsA, totalAddresses: totalAddresses, hideShowColumn: hideShowColumnA, columnsToRender: columnsToRenderA, addressesToRender: addressesToRender, handleColumnsClose: handleColumnsCloseA, handleColumnsClick: handleColumnsClickA }))
                : null,
            activeTab === 1 ?
                React.createElement(Permissions, { oneOf: true, features: features, permissions: ['client_custody_withdrawal_master_sign.create', 'client_custody_transaction.update'] },
                    React.createElement(Withdrawals, { sortBy: sortByW, loading: loading, columns: columns, features: features, admin: masterTenant, maxHeight: maxHeightW, clientCode: clientCode, currencies: currencies, withdrawals: withdrawals, rowsPerPage: rowsPerPageW, columnsOpen: columnsOpenW, onSortHandle: onSortHandleW, onChangePageW: onChangePageW, sortDirection: sortDirectionW, anchorColumns: anchorColumnsW, walletsLoading: walletsLoading, hideShowColumn: hideShowColumnW, pageWithdrawals: pageWithdrawals, columnsToRender: columnsToRenderW, totalWithdrawals: totalWithdrawals, handleColumnsClick: handleColumnsClickW, handleColumnsClose: handleColumnsCloseW, withdrawalsToRender: withdrawalsToRender, fetchAllWithDrawals: fetchAllWithDrawals })) : null)));
};
const mapStateToProps = (state, ownProps) => ({
    meCode: state.client.meCode,
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    currencies: state.masterTenant.currencies,
    allAddresses: getAddressesToRender(state),
    selectedClient: getSelectedClientInputValue(state),
    walletsLoading: state.custody.loadingWallets,
    allWithdrawals: getWithdrawalsToRender(state),
    withdrawals: getMasterTenantWithdrawals(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    approveWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(Approve);
