import React, { useRef, useState, useEffect } from 'react';
import { Dialog, Button, Checkbox, MenuItem, TextField, FormGroup, DialogTitle, DialogContent, DialogActions, FormControlLabel, CircularProgress, } from '@mui/material';
import { connect } from 'react-redux';
import { getTradingBases, getTradingQuotes } from '../../../../../redux/selectors/selectors';
import { ModalLoadingContainer, ModalSpinnerContainer } from '../../../Custody/styling/style';
import { FETCH_MARKETS_REQUEST, FETCH_EXCHANGES_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
const AutohedgerModal = ({ open, toggleFunc, updateAutohedger, loading, optionsBase, optionsQuotes, dispatchLoadExchanges, dispatchLoadMarkets, }) => {
    const dirty = useRef(false);
    const [base, setBase] = useState('');
    const [quote, setQuote] = useState('');
    const [minAmount, setMinAmount] = useState('');
    const [threshold, setThreshold] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [dailyLimit, setDailyLimit] = useState('');
    const [autoHedging, setAutoHedging] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const validateFields = (fieldsToValidate) => {
        const errors = [];
        for (const [key, value] of Object.entries(fieldsToValidate)) {
            if (`${value}`.trim() === '') {
                errors.push(key);
            }
        }
        return errors;
    };
    const toggleAutoHedging = (evt) => {
        const { checked } = evt.target;
        setAutoHedging(checked);
    };
    const onBaseChange = (event) => {
        const value = event.target.value;
        setBase(value);
    };
    const onQuoteChange = (event) => {
        const value = event.target.value;
        setQuote(value);
    };
    const onValueChange = (evt) => {
        const val = evt.target.value.trim();
        const { name } = evt.target;
        if (isNaN(Number(val))) {
            return;
        }
        if (name === 'minAmount') {
            setMinAmount(val);
        }
        if (name === 'threshold') {
            setThreshold(val);
        }
        if (name === 'dailyLimit') {
            setDailyLimit(val);
        }
    };
    const addNewSetting = () => {
        dirty.current = true;
        const errors = validateFields({
            base, quote, minAmount, dailyLimit, threshold,
        });
        if (errors.length) {
            setInvalidFields([...errors]);
            return;
        }
        const payload = {
            threshold,
            base_code: base,
            quote_code: quote,
            min_amount: minAmount,
            daily_limit: dailyLimit,
            auto_hedging: autoHedging,
        };
        updateAutohedger(payload);
        setBase('');
        setQuote('');
        setMinAmount('');
        setThreshold('');
        setDailyLimit('');
        setAutoHedging(false);
        toggleFunc();
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            dispatchLoadMarkets();
            dispatchLoadExchanges();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (dirty.current) {
            const errors = validateFields({
                base, quote, minAmount, dailyLimit, threshold,
            });
            setInvalidFields([...errors]);
        }
        if (!minAmount || !threshold || !dailyLimit || !base || !quote) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [minAmount, threshold, dailyLimit, base, quote]);
    if (loading) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement("h4", null, "Loading"),
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, { size: "33px" }))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Create new Autohedger"),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { variant: "outlined", label: "Base", fullWidth: true, margin: "normal", size: "small", value: base, select: true, onChange: onBaseChange }, optionsBase.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label)))),
            React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", select: true, label: "Quote", value: quote, onChange: onQuoteChange }, optionsQuotes.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label)))),
            React.createElement(TextField, { fullWidth: true, size: "small", name: "minAmount", margin: "normal", value: minAmount, label: "Min amount", variant: "outlined", onChange: onValueChange, helperText: `${invalidFields.includes('minAmount') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", name: "threshold", value: threshold, label: "Threshold", variant: "outlined", onChange: onValueChange, helperText: `${invalidFields.includes('threshold') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", name: "dailyLimit", value: dailyLimit, variant: "outlined", label: "Daily limit", onChange: onValueChange, helperText: `${invalidFields.includes('dailyLimit') ? 'Field is required' : ''}` }),
            React.createElement(FormGroup, null,
                React.createElement(FormControlLabel, { label: "Auto hedging", control: React.createElement(Checkbox, { value: autoHedging, onChange: toggleAutoHedging, color: "primary" }) }))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { size: "small", color: "primary", variant: "contained", disabled: disabled, onClick: addNewSetting }, "Create"),
            React.createElement(Button, { variant: "outlined", size: "small", onClick: () => toggleFunc() }, "Cancel"))));
};
const mapStateToProps = (state) => ({
    optionsBase: getTradingBases(state),
    optionsQuotes: getTradingQuotes(state),
});
const mapDispatchToProps = dispatch => ({
    dispatchLoadMarkets: () => dispatch({ type: FETCH_MARKETS_REQUEST }),
    dispatchLoadExchanges: () => dispatch({ type: FETCH_EXCHANGES_REQUEST }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AutohedgerModal);
