import React from 'react';
import Decimal from 'decimal.js';
const ValueCmlBid = ({ value }) => (React.createElement("div", { className: "container_trade_color_green" }, value === ''
    ? React.createElement("span", null, "--")
    : React.createElement("span", null, value)));
/* eslint-disable camelcase */
const ValueCmlBidSize = ({ orderBookItem }) => {
    if (orderBookItem && orderBookItem.bid && Object.keys(orderBookItem.bid).length) {
        const { volume, volume_split, price } = orderBookItem.bid;
        const splitVolumeArr = volume_split ? Object.values(volume_split) : [];
        const sumVolume = splitVolumeArr.reduce((acm, item) => Decimal.add(acm, item).toFixed(), '0');
        const size = volume_split ? sumVolume : volume;
        const value = Decimal.mul(size, price)
            .toFixed(2)
            .replace(/\.?0+$/, '');
        return React.createElement(ValueCmlBid, { value: value });
    }
    return (React.createElement("div", { className: "container_trade_color_green" },
        React.createElement("span", null, "--")));
};
export default React.memo(ValueCmlBidSize);
