import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CircleIcon from '@mui/icons-material/Circle';
import DLTFTable from '../../../../../../shared/components/table/DLTTableComponent';
import ClientModal from '../../../../Crypto/components/ClientSearch/ClientModal';
import CustomersService from '../../../../../../services/customersService';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import Messages from '../../../../../../shared/helpers/errorMessages';
import { CUSTOMER_SEARCH_FETCH_REQUEST, } from '../../../../../../redux/actionTypes/customerSearchActionsTypes';
const tableCols = [
    { id: 'company_name', label: 'Name', width: 20, sortable: true },
    { id: 'contact_person_name', label: 'Contact', width: 20 },
    { id: 'phone_number', label: 'Phone', width: 20 },
    { id: 'email', label: 'Email', width: 20 },
    { id: 'is_enabled', label: 'Status', width: 20, align: 'right' },
];
const fetchCustomers = () => ({
    type: CUSTOMER_SEARCH_FETCH_REQUEST,
});
function mapCustomers(customers, searchString) {
    return customers
        .filter((p) => {
        var _a;
        let result = false;
        for (const prop in p) {
            const search = (_a = p[prop]) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().includes(searchString.toLowerCase());
            if (search) {
                result = true;
            }
        }
        return result;
    })
        .map((c) => (Object.assign(Object.assign({}, c), { id: c.code, is_enabled: c.is_enabled ? React.createElement(CircleIcon, { color: 'success' }) : React.createElement(CircleIcon, { color: 'error' }) })));
}
const CustomersTable = (props) => {
    const [customers, setCustomers] = useState([]);
    const [createCustomer, setCreateCustomer] = useState(false);
    const [searchString, setSearchString] = useState('');
    const history = useHistory();
    useEffect(() => {
        setCustomers(mapCustomers(props.customers, searchString));
    }, [searchString, props.customers]);
    const handleCreateCustomer = (payload, setErr) => {
        const service = new CustomersService({
            url: '/customers',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().apiUrl,
        });
        service
            .makeRequest()
            .then(() => {
            showNotification({
                message: 'Customer successfully created',
                color: 'success',
            });
            setCreateCustomer(false);
            props.dispatchFetchCustomers();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTOMER_CREATE);
            setErr(message);
        });
    };
    const handleRowClick = (id) => {
        history.push(`/manage/customers/${id}`);
    };
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, py: 2 },
            React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 2 },
                React.createElement(Typography, { variant: 'h6' }, "Customers"),
                React.createElement(TextField, { size: 'small', value: searchString, onChange: (e) => setSearchString(e.target.value), InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                            React.createElement(SearchIcon, null))),
                    } })),
            React.createElement(Button, { variant: 'outlined', size: 'small', onClick: () => setCreateCustomer(true) }, "Create"),
            React.createElement(ClientModal, { user: 'customer', currentUser: null, open: createCustomer, toggleFunc: () => {
                    setCreateCustomer(false);
                }, updateClient: () => { }, createClient: handleCreateCustomer })),
        React.createElement(DLTFTable, { pagination: true, defaultOrder: 'asc', defaultOrderProp: 'company_name', columns: tableCols, data: customers, total: customers.length, onRowClick: handleRowClick })));
};
const mapDispatchToProps = (dispatch) => ({
    dispatchFetchCustomers: () => dispatch(fetchCustomers()),
});
const stateToProps = (state) => {
    const customers = state.customersSearch.customers;
    const user = state.client.clientInfo;
    return {
        customers,
        user,
    };
};
export default connect(stateToProps, mapDispatchToProps)(CustomersTable);
