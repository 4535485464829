import * as types from '../actionTypes/masterTenantActionTypes';
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
export const initialState = {
    currencies: [],
    approvalsAddresses: [],
    loadingAddresses: false,
    approvalsWithdrawals: [],
    urlOptionsHedgeOrders: '',
    rfqManagementClient: null,
    urlOptionsStrategies: '',
};
const masterTenantReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MASTER_TENANT_RFQ_MANAGEMET_CLIENT:
            return Object.assign(Object.assign({}, state), { rfqManagementClient: action.rfqManagementClient });
        case types.MASTER_TENANT_CURRENCIES_UPDATE:
            return Object.assign(Object.assign({}, state), { currencies: action.currencies });
        case types.MASTER_TENANT_ORDERS_URL_OPTIONS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { urlOptionsHedgeOrders: action.urlOptionsHedgeOrders });
        case types.LOADING_ADDRESSES_UPDATE:
            return Object.assign(Object.assign({}, state), { loadingAddresses: action.loadingAddresses });
        case types.MASTER_TENANT_APPROVALS_ADDRESSES_UPDATE:
            return Object.assign(Object.assign({}, state), { approvalsAddresses: action.approvalsAddresses });
        case types.MASTER_TENANT_APPROVALS_WITHDRAWALS_UPDATE:
            return Object.assign(Object.assign({}, state), { approvalsWithdrawals: action.approvalsWithdrawals });
        case types.MASTER_TENANT_STRATEGIES_URL_OPTIONS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { urlOptionsStrategies: action.urlOptionsStrategies });
        default:
            return state;
    }
};
export default masterTenantReducer;
