import React, { useEffect, useState } from 'react';
import DLTFTable from '../../../../../shared/components/table/DLTTableComponent';
import { Box } from '@mui/material';
import { accountsColumns, balancesColumns } from './Columns';
import { useTheme } from '@mui/styles';
const mappedTypes = {
    virtual_wallet: 'Wallet',
    trading_wallet: 'Trading wallet',
    settlement_line: 'Settlement line',
};
function mapBalances(balances, currencies, actions, accountActions, theme) {
    return balances.map((b) => {
        var _a, _b, _c;
        return (Object.assign(Object.assign({ id: b.code }, b), { collapsible: !!b.accounts, code: (React.createElement(React.Fragment, null,
                React.createElement(Box, { sx: {
                        background: `url(/img/currencies/${b.code.toLowerCase()}.svg) no-repeat center/cover`,
                        backgroundColor: theme.palette.secondary.dark,
                        width: '36px',
                        height: '36px',
                        borderRadius: '50%',
                    } }),
                React.createElement(Box, null, b.code))), precision: ((_a = currencies.find((c) => c.ticker === b.code)) === null || _a === void 0 ? void 0 : _a.decimals) || 2, children: b.accounts && ((_b = b.accounts) === null || _b === void 0 ? void 0 : _b.length) > 1 ? (React.createElement(DLTFTable, { noHead: true, squareCorners: true, columns: accountsColumns, total: ((_c = b.accounts) === null || _c === void 0 ? void 0 : _c.length) || 0, data: b.accounts
                    ? b.accounts.map((acc) => (Object.assign(Object.assign({ id: acc.code }, acc), { type: mappedTypes[acc.type], actions: accountActions.filter((a) => a.id === 'transfer' && acc.type === 'trading_wallet') })))
                    : [] })) : null, actions }));
    });
}
const BalancesContainer = ({ hideZero, currencies, balances, actions, accountActions }) => {
    const [mappedBalances, setMappedBalances] = useState([]);
    const theme = useTheme();
    useEffect(() => {
        if (balances.length) {
            let filteredBalances = balances;
            if (hideZero) {
                filteredBalances = balances.filter((b) => b.available !== '0');
            }
            setMappedBalances(mapBalances(filteredBalances, currencies, actions, accountActions, theme));
        }
    }, [balances, hideZero]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DLTFTable, { columns: balancesColumns, data: mappedBalances, total: balances.length, internalSorting: true, skipFirstSorting: true, defaultOrderProp: 'id', rowsPerPage: 'all' })));
};
export default BalancesContainer;
