import React from 'react';
import NotificationSystem from 'rc-notification';
import { Alert, Box } from '@mui/material';
import { ConnectedThemeComponent } from '../../theme/ConnectedThemeComponent';
import { Provider } from 'react-redux';
import store from '../../containers/App/store';
import { generate } from 'shortid';
let notification;
// eslint-disable-next-line no-return-assign
NotificationSystem.newInstance({ style: { top: 65, zIndex: 10000 } }, (n) => (notification = n));
const showNotification = ({ message, color, dispatch, options }) => {
    const key = generate();
    notification.notice({
        key,
        content: (React.createElement(Provider, { store: store },
            React.createElement(ConnectedThemeComponent, null,
                React.createElement(Box, { mb: 1 },
                    React.createElement(Alert, { style: { display: 'flex', justifyContent: 'center', alignItems: 'center' }, onClose: () => {
                            dispatch && dispatch();
                            notification.removeNotice(key);
                        }, severity: color, variant: 'filled', action: (options === null || options === void 0 ? void 0 : options.action) || null }, message))))),
        duration: (options === null || options === void 0 ? void 0 : options.action) ? null : 7,
    });
};
export default showNotification;
