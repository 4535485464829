import React, { useState } from 'react';
import { Dialog, Button, TextField, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
const TwoFAModal = ({ open, handleSave, toggleFunc, modalLabel, }) => {
    const [twoFA, setTwoFA] = useState('');
    const sendRequest = () => {
        handleSave(twoFA);
        setTwoFA('');
    };
    const onTwoFAChange = (evt) => {
        const { value } = evt.target;
        if (isNaN(Number(value)) || value.length > 6) {
            return;
        }
        setTwoFA(value);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" },
                "2FA for ",
                modalLabel,
                " "),
            React.createElement(DialogContent, null,
                React.createElement(TextField, { fullWidth: true, size: "small", name: "twoFA", value: twoFA, label: "Two FA", margin: "normal", variant: "outlined", onChange: onTwoFAChange, InputLabelProps: { shrink: true } })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { color: "primary", variant: "contained", onClick: sendRequest, disabled: twoFA.length !== 6 }, "Confirm"),
                React.createElement(Button, { variant: "outlined", onClick: () => toggleFunc() }, "Cancel"))));
};
export default TwoFAModal;
