export default class Subscriber {
    constructor(callback, componentId, wait) {
        this.componentId = componentId;
        this.callback = wait ? this.debounce(callback, wait) : callback;
    }
    debounce(callback, wait) {
        return (...args) => {
            clearTimeout(this.timeoutId);
            this.timeoutId = +setTimeout(() => {
                callback.apply(null, args);
            }, wait);
        };
    }
}
