import { createSelector } from 'reselect';
export const getMarkets = (state) => state.placeOrder.markets;
export const getExchanges = (state) => state.placeOrder.exchanges;
export const getSelectedPair = (state) => state.placeOrder.selectedPair;
/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/explicit-module-boundary-types,
  no-param-reassign, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any */
const groupMarkets = (objectArray, property) => objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
        acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
}, {});
export const groupByObjectKey = (objectArray, property) => objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
        acc[key] = [];
    }
    acc[key].push(Object.assign({}, { base: obj.base, quote: obj.quote, exchange_code: obj.exchange_code }));
    return acc;
}, {});
export const getTradingBases = createSelector(getMarkets, (markets) => {
    if (markets.length) {
        const bases = markets.map((market) => ({ value: market.base, label: market.base }))
            .filter((market, index, self) => index === self.findIndex(m => (m.value === market.value && m.label === market.label)));
        const btcIndex = bases.findIndex(b => b.value === 'BTC');
        const btc = bases.splice(btcIndex, 1)[0];
        const ethIndex = bases.findIndex(b => b.value === 'ETH');
        const eth = bases.splice(ethIndex, 1)[0];
        bases.sort((a, b) => a.label.localeCompare(b.label));
        bases.unshift(eth);
        bases.unshift(btc);
        return bases;
    }
    return [];
});
export const getTradingQuotes = createSelector(getMarkets, (markets) => {
    if (markets.length) {
        return markets.map(market => ({ value: market.quote, label: market.quote }))
            .filter((market, index, self) => index === self.findIndex(m => (m.value === market.value && m.label === market.label)));
    }
    return [];
});
export const getTradingPairs = createSelector(getMarkets, markets => markets.map(market => ({
    base: market.base,
    quote: market.quote,
})).filter((market, index, self) => index === self.findIndex(m => (m.base === market.base
    && m.quote === market.quote))));
export const getEnabledExchanges = createSelector(getExchanges, exchanges => exchanges);
export const getExchangesOptions = createSelector(getExchanges, (exchanges) => {
    if (exchanges.length) {
        return exchanges.filter(e => e.enabled).map(exchange => ({ value: exchange.code, label: exchange.name }));
    }
    return [];
});
// Allows to generate key map in:
// [exchange_code]:[{[base], [quote]}]
export const getMarketsByExchange = createSelector(getMarkets, (markets) => {
    if (markets.length) {
        const groupedMarkets = groupByObjectKey(markets, 'exchange_code');
        return groupedMarkets;
    }
    return [];
});
// Allows to generate key map in:
// [BASE/QUOTE]:[exchange_code...]
export const getMarketsByPairs = createSelector(getMarkets, (markets) => {
    markets.forEach((market) => {
        market.pair = `${market.base}/${market.quote}`;
    });
    const groupedPair = groupByObjectKey(markets, 'pair');
    return groupedPair;
});
export const getMarketsByBase = createSelector(getMarkets, getTradingPairs, (markets, pairs) => {
    const group = groupMarkets(markets, 'base');
    const pairsByBase = groupMarkets(pairs, 'base');
    const groupKeys = Object.keys(group);
    const exchangesByBase = {};
    groupKeys.forEach((key) => {
        const r = {};
        r[key] = group[key].reduce((acm, current) => {
            const k = current.exchange_code;
            if (!acm[k]) {
                acm[k] = [];
            }
            acm[k].push(current);
            return acm;
        }, {});
        exchangesByBase[key] = Object.assign({}, r[key]);
    });
    return { pairsByBase, exchangesByBase };
});
export const getAvailableExchanges = createSelector(getMarketsByExchange, getSelectedPair, (markets, pair) => {
    if (markets) {
        const marketsKeys = Object.keys(markets);
        // all exchanges contained selected pair BASE/QUOTE
        const availableExchanges = marketsKeys.filter((key) => {
            const index = markets[key]
                .findIndex((el) => el.base === (pair === null || pair === void 0 ? void 0 : pair.base) && el.quote === (pair === null || pair === void 0 ? void 0 : pair.quote));
            if (index !== -1)
                return true;
            return false;
        }).sort((a, b) => a.localeCompare(b));
        const output = availableExchanges.map(exchange => ({ value: exchange, label: exchange.toUpperCase() }));
        // not sure if needed: filter off from enabled exchanges
        // const filteredAvailable = enabledExchangesOptions
        //   .map(option => availableExchanges.filter(exchange => exchange === option.value))
        //   .flat();
        // // get an actual options
        // const output = filteredAvailable
        //   .map(exchange => enabledExchangesOptions.filter(option => option.value === exchange)).flat();
        return output;
    }
    return [];
});
