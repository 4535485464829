import React, { useEffect, useState } from 'react';
import { Box, Chip, Button, TextField, MenuItem, } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Status } from './RfqModels';
import { streamTypes } from './RfqSettingModal';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
const useStyles = makeStyles(() => createStyles({
    formGroup: {
        minWidth: 200,
    },
}));
const StreamsFiltersContent = ({ state, features, toggleModal, baseOptions, quoteOptions, resetFilters, selectedClient, exchangeOptions, handleBaseChange, handleQuoteChange, handleExhangeChange, removeSelectedFilter, handleAssignedChange, handleStreamTypeChange, }) => {
    const classes = useStyles();
    const [base, setBase] = useState(null);
    const [quote, setQuote] = useState(null);
    const [exchange, setExchange] = useState(null);
    const [assinged, setAssigned] = useState(null);
    const [streamType, setStreamType] = useState(null);
    useEffect(() => {
        const objIndexExchange = state.filters.findIndex((obj => obj.name === 'exchange_code'));
        const exchangeData = state.filters[objIndexExchange];
        setExchange(exchangeData || null);
        const objIndexStreamType = state.filters.findIndex((obj => obj.name === 'stream_type'));
        const streamTypeData = state.filters[objIndexStreamType];
        setStreamType(streamTypeData || null);
        const objIndexBase = state.filters.findIndex((obj => obj.name === 'base'));
        const baseData = state.filters[objIndexBase];
        setBase(baseData || null);
        const objIndexQuote = state.filters.findIndex((obj => obj.name === 'quote'));
        const quoteData = state.filters[objIndexQuote];
        setQuote(quoteData || null);
        const objIndexAssigned = state.filters.findIndex((obj => obj.name === Status.ASSIGNED));
        const assignedData = state.filters[objIndexAssigned];
        setAssigned(assignedData || null);
    }, [state]);
    const assignedOptions = [
        { value: Status.ASSIGNED, label: Status.ASSIGNED },
        { value: Status.UNASIGNED, label: Status.UNASIGNED },
    ];
    return (React.createElement("div", null,
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'space-between' }, mb: 1, flexWrap: 'wrap', gap: 1, alignItems: 'center' },
            React.createElement(Box, { style: { display: 'flex' }, flexWrap: 'wrap', gap: 1 },
                React.createElement(Box, { className: classes.formGroup },
                    React.createElement(TextField, { value: (assinged === null || assinged === void 0 ? void 0 : assinged.value) || '', variant: "outlined", size: "small", label: "Assigned", fullWidth: true, onChange: (event) => handleAssignedChange({ value: event.target.value, label: event.target.value }), select: true }, assignedOptions.map((o, i) => React.createElement(MenuItem, { key: i, value: o.value }, o.label)))),
                React.createElement(Box, { className: classes.formGroup },
                    React.createElement(TextField, { value: (base === null || base === void 0 ? void 0 : base.value) || '', variant: "outlined", size: "small", label: "Base", fullWidth: true, onChange: (event) => handleBaseChange({ value: event.target.value, label: 'Base' }), select: true }, baseOptions.map((o, i) => React.createElement(MenuItem, { key: i, value: o.value }, o.label)))),
                React.createElement(Box, { className: classes.formGroup },
                    React.createElement(TextField, { value: (quote === null || quote === void 0 ? void 0 : quote.value) || '', variant: "outlined", size: "small", label: "Quote", fullWidth: true, onChange: (event) => handleQuoteChange({ value: event.target.value, label: 'Quote' }), select: true }, quoteOptions.map((o, i) => React.createElement(MenuItem, { key: i, value: o.value }, o.label)))),
                React.createElement(Box, { className: classes.formGroup },
                    React.createElement(TextField, { value: (exchange === null || exchange === void 0 ? void 0 : exchange.value) || '', variant: "outlined", size: "small", label: "Exchange", fullWidth: true, onChange: (event) => handleExhangeChange({ value: event.target.value, label: 'Exchange' }), select: true }, exchangeOptions.map((o, i) => React.createElement(MenuItem, { key: i, value: o.value }, o.label)))),
                React.createElement(Box, { className: classes.formGroup },
                    React.createElement(TextField, { value: (streamType === null || streamType === void 0 ? void 0 : streamType.value) || '', variant: "outlined", size: "small", label: "Stream type", fullWidth: true, onChange: (event) => handleStreamTypeChange({ value: event.target.value, label: 'Stream type' }), select: true }, streamTypes.map((o, i) => React.createElement(MenuItem, { key: i, value: o.value }, o.label))))),
            React.createElement(Box, null, state.filters.length ? (React.createElement(Button, { style: { minWidth: '150px' }, type: "button", onClick: resetFilters, variant: "outlined" }, "Reset filters")) : null),
            React.createElement(Permissions, { features: features, permissions: ['client_rfq_settings.create'] },
                React.createElement(Button, { size: "small", type: "button", variant: "contained", color: "primary", onClick: () => toggleModal() }, "Add Stream"))),
        React.createElement("div", null, state.filters.length ? (React.createElement(Box, { display: 'flex', flexWrap: 'wrap', gap: 1 }, state.filters
            .filter(f => (f.type !== 'assigned' && !selectedClient) || selectedClient)
            .map((filter, i) => (React.createElement(Chip, { key: i, label: filter.label, color: "primary", onDelete: () => {
                removeSelectedFilter(filter);
                if (filter.name === 'stream_type') {
                    setStreamType(null);
                }
                else if (filter.name === 'exchange_code') {
                    setExchange(null);
                }
                else if (filter.name === 'quote') {
                    setQuote(null);
                }
                else if (filter.name === 'base') {
                    setBase(null);
                }
                else if (filter.name === 'assigned') {
                    setAssigned(null);
                }
            }, size: "small" }))))) : null)));
};
export default StreamsFiltersContent;
