import { createSelector } from 'reselect';
import adminWidgets from '../../config/adminWidgets';
const widgetsById = (state) => state.widgetSettings.widgets;
const widgetWorkingSettings = (state) => state.widgetSettings.workingSettings;
const widgetSettingsEntities = (state) => state.widgetSettings.settings;
const getWidgetByKey = (state, key) => state.widgetSettings.widgets.find(w => w.widgetKey === key);
const getClient = (state) => state.client;
export const getWidgets = createSelector(widgetsById, getClient, (widgets, client) => {
    if (!client.masterTenant) {
        return widgets.filter(widget => adminWidgets.indexOf(widget.widgetKey) === -1);
    }
    return widgets;
});
export const getSettings = createSelector(widgetSettingsEntities, (settingEntities) => {
    const settings = [];
    const entries = Object.entries(settingEntities.byId);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-restricted-syntax
    for (const [key, value] of entries) {
        settings.push(value);
    }
    return settings;
});
export const getWidgetWorkingSettings = createSelector(widgetsById, widgetWorkingSettings, (widgets, settings) => {
    if (Object.keys(settings).length === 0) {
        return [];
    }
    const allSettings = widgets.map(widget => widget.settings.map(setting => settings.byId[setting]));
    return allSettings.flat();
});
export const getWidgetSettings = createSelector(widgetsById, widgetSettingsEntities, (widgets, settings) => {
    if (Object.keys(settings).length === 0) {
        return [];
    }
    const allSettings = widgets.map(widget => widget.settings.map(setting => settings.byId[setting]));
    return allSettings.flat();
});
export const getWidgetsSettingsByKey = createSelector(getWidgetByKey, widgetSettingsEntities, (widget, settingEntities) => {
    const r = widget ? widget.settings.map(settingStr => settingEntities.byId[settingStr]) : [];
    return r;
});
export const getWidgetsWorkingSettingsByKey = createSelector(getWidgetByKey, widgetWorkingSettings, (widget, settingEntities) => {
    const r = widget && widget.settings.map(settingStr => settingEntities.byId[settingStr]);
    return r;
});
