import axios from 'axios';
import MarketsService from '../../services/marketsService';
import ExchangesService from '../../services/exchangesService';
import { getEnvSettings } from '../../config/environmentSettings';
/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-explicit-any */
const marketsService = new MarketsService({ url: '/markets', method: 'get' });
export const fetchMarkets = () => marketsService.makeRequest()
    .then((responseData) => responseData.markets
    .sort((a, b) => a.exchange_code.localeCompare(b.exchange_code)))
    .catch((e) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(e, new Date());
    throw new Error(`Something went wrong, error: ${e}`);
});
const exchangesService = new ExchangesService({
    baseURL: getEnvSettings().adminApiUrl,
    url: '/trade/exchanges',
    method: 'get',
});
export const fetchExchanges = () => exchangesService.makeRequest()
    .then((responseData) => responseData.records)
    .catch((e) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(e, new Date());
    throw new Error(`Something went wrong, error: ${e}`);
});
export const postSubmitNewOrder = (payload) => axios.post('/orders', payload)
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const postSubmitNewPartialOrder = (payload) => axios
    .post('/orders/partials', payload)
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const postClosePartialOrder = (partialCode) => axios.post(`/orders/partials/${partialCode}/cancel`)
    .then((response) => response.data.code)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
