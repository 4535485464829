import React, { useMemo } from 'react';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import { bindActionCreators } from 'redux';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Box, IconButton } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { connect } from 'react-redux';
import { collapseExchangesBalancesItemOpen, collapseExchangesBalancesItemClose, } from '../../../../../../redux/actions/clientActions';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import getDecimalsByCurrencyCode from '../../../../../../config/currencyDecimal';
import { getExchangesOptions } from '../../../../../../redux/selectors/selectors';
const envSettings = getEnvSettings();
const memoItem = (item) => item;
const getExchangeOption = (exchanges, code) => {
    const option = exchanges.find(exchange => exchange.value === code);
    if (option) {
        return option.label;
    }
    return code;
};
const ExchangesBalancesItem = ({ item, exchanges, openItems, currencies, dispatchUpdateCollapseOpen, dispatchUpdateCollapseClose, collapsedExchangesBalancesArray, }) => {
    const mItem = useMemo(() => memoItem(item), [item]);
    const isItemOpen = () => {
        const openedItem = openItems.find(i => i === item.label);
        return openedItem;
    };
    const Icon = () => {
        if (isItemOpen()) {
            return React.createElement(ExpandLessIcon, null);
        }
        return React.createElement(ExpandMoreIcon, null);
    };
    const onItemClick = () => {
        if (!isItemOpen()) {
            dispatchUpdateCollapseOpen(item.label);
        }
        else {
            dispatchUpdateCollapseClose(item.label);
        }
    };
    const fiatLabel = ['USD', 'EUR'];
    const decimals = getDecimalsByCurrencyCode('USD', currencies) || 2;
    const mItemValue = fiatLabel.includes(mItem.label)
        ? new Decimal(mItem.value).toFixed(decimals) : mItem.value;
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { key: shortid.generate(), onClick: onItemClick },
            React.createElement(TableCell, null,
                React.createElement(Box, { display: 'flex', flexDirection: 'row' },
                    React.createElement(Box, null,
                        React.createElement(IconButton, { size: "small" },
                            React.createElement(Icon, null))),
                    React.createElement(Box, { marginTop: '5px' }, mItem.label))),
            React.createElement(TableCell, null),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { value: mItemValue, displayType: "text", thousandSeparator: envSettings.thousandDivider }))),
        isItemOpen() ? (collapsedExchangesBalancesArray && collapsedExchangesBalancesArray.map((i) => {
            const available = i.balance.is_fiat
                ? new Decimal(i.balance.available).toFixed(decimals) : i.balance.available;
            const exchangeName = getExchangeOption(exchanges, i.exchange_code);
            return (React.createElement(TableRow, { key: shortid.generate() },
                React.createElement(TableCell, null),
                React.createElement(TableCell, null, exchangeName),
                React.createElement(TableCell, null,
                    React.createElement(NumericFormat, { value: available, displayType: "text", thousandSeparator: envSettings.thousandDivider }))));
        })) : null));
};
const stateToProps = (state) => {
    const exchanges = getExchangesOptions(state);
    return {
        exchanges,
        currencies: state.placeOrder.currencies,
    };
};
const dispatchToProps = dispatch => ({
    dispatchUpdateCollapseClose: bindActionCreators(collapseExchangesBalancesItemClose, dispatch),
    dispatchUpdateCollapseOpen: bindActionCreators(collapseExchangesBalancesItemOpen, dispatch),
});
export default connect(stateToProps, dispatchToProps)(ExchangesBalancesItem);
