import React, { useState } from 'react';
import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import InternalTransfersListItem from './InternalTransfersListItem';
const InternalTransfersRow = ({ item, columns }) => {
    const [open, setOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { hover: true, sx: { '& > *': { borderBottom: 'unset' } } },
            React.createElement(TableCell, null,
                React.createElement(IconButton, { "aria-label": "expand row", size: "small", onClick: () => setOpen(!open) }, open ? React.createElement(KeyboardArrowUpIcon, null) : React.createElement(KeyboardArrowDownIcon, null))),
            React.createElement(InternalTransfersListItem, { item: item, columns: columns })),
        React.createElement(TableRow, null,
            React.createElement(TableCell, { style: { paddingBottom: 0, paddingTop: 0 }, colSpan: columns.length },
                React.createElement(Collapse, { in: open },
                    React.createElement(Box, null,
                        React.createElement(Typography, { variant: "overline" }, "Withdrawal"),
                        React.createElement(Typography, { variant: "body2" },
                            "Source address: ",
                            item.withdrawal.source_address),
                        React.createElement(Typography, { variant: "body2" },
                            "Created at: ",
                            item.withdrawal.created_at),
                        React.createElement(Typography, { variant: "body2" },
                            "Fee: ",
                            item.withdrawal.fee),
                        React.createElement(Typography, { variant: "body2" },
                            "State: ",
                            item.withdrawal.state),
                        React.createElement(Typography, { variant: "body2" },
                            "Created at: ",
                            item.withdrawal.created_at),
                        React.createElement(Typography, { variant: "body2" },
                            "Updated at: ",
                            item.withdrawal.updated_at)))))));
};
export default InternalTransfersRow;
