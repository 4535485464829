import React, { useRef, useState, useEffect } from 'react';
import shortid from 'shortid';
import * as Styled from '../../styling/styled';
const LiftAsk = ({ channel }) => {
    const [askUpdate, setAskUpdate] = useState('');
    const prevChannel = useRef('');
    const refId = useRef(shortid.generate());
    const liftAskUpdate = (data) => {
        const { lift_ask } = data.payload;
        setAskUpdate(lift_ask);
    };
    useEffect(() => {
        const { OrderBookWS } = window;
        if (prevChannel.current !== '') {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        }
        OrderBookWS.addSubscription(channel, { callback: liftAskUpdate, componentId: refId.current });
        setAskUpdate('');
        prevChannel.current = channel;
        return () => {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        };
    }, [channel]);
    return (React.createElement(Styled.PanelAsk, { className: "price_ladder_header_color" },
        askUpdate === '' ? React.createElement("span", null, "--") : React.createElement("span", null, askUpdate),
        React.createElement("div", { className: "price_ladder_header_text" }, "lift ask")));
};
export default LiftAsk;
