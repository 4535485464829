import React, { useState, useEffect } from 'react';
import Decimal from 'decimal.js';
import { useTimer } from 'react-timer-hook';
import Cached from '@mui/icons-material/Cached';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { green, grey, red } from '@mui/material/colors';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, Chip, Button } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => createStyles({
    redText: {
        color: red[400],
    },
    greenText: {
        color: green[400],
    },
    subtitle: {
        marginBottom: '30px',
    },
    label: {
        fontSize: '0.7rem',
        color: grey[500],
    },
    orderLabels: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '20px',
    },
}));
const MarketForm = ({ toggleFunc, orderRfqPrice, handleOrderCreate, quote, placeRfqPrice, orderAmt, action, base, streamType, }) => {
    const [total, setTotal] = useState('');
    const [price, setPrice] = useState('');
    const [color, setColor] = useState('');
    const [rounding, setRounding] = useState(5);
    const [disabled, setDisabled] = useState(false);
    const time = new Date();
    time.setSeconds(time.getSeconds() + 5);
    const { seconds, restart } = useTimer({ expiryTimestamp: time, onExpire: () => setDisabled(prev => !prev) });
    const styles = useStyles();
    const addNewOrder = () => {
        handleOrderCreate();
        toggleFunc();
    };
    useEffect(() => {
        if (action === 'Buy') {
            setColor('#4ce1b6');
        }
        else if (action === 'Sell') {
            setColor('#ff4861');
        }
    }, [action]);
    useEffect(() => {
        setRounding(base.toLowerCase() === 'btc' ? 2 : 5);
    }, [base]);
    useEffect(() => {
        if (streamType === 'standard') {
            setPrice(new Decimal((orderRfqPrice === null || orderRfqPrice === void 0 ? void 0 : orderRfqPrice.offered_price) || '0').toFixed(rounding));
        }
        else {
            setPrice(new Decimal((orderRfqPrice === null || orderRfqPrice === void 0 ? void 0 : orderRfqPrice.offered_base_amount) || '0').toFixed());
        }
    }, [orderRfqPrice, rounding, streamType]);
    useEffect(() => {
        if (streamType === 'standard') {
            setTotal(new Decimal(orderAmt || '0').mul(price || '0').toFixed(rounding));
        }
        else {
            setTotal(orderRfqPrice === null || orderRfqPrice === void 0 ? void 0 : orderRfqPrice.quote_amount_to_spend);
        }
    }, [orderAmt, price, rounding]);
    return React.createElement(React.Fragment, null,
        React.createElement(DialogContent, null,
            React.createElement("div", { className: "form_container_padding_flex" },
                React.createElement("div", { style: {
                        width: '100%',
                        fontSize: '1rem',
                        textAlign: 'center',
                        marginBottom: '20px',
                    } },
                    React.createElement("div", { className: styles.subtitle }, (streamType === 'standard') ? (React.createElement("span", null,
                        "Are you sure you want to place",
                        React.createElement("span", { style: { color } },
                            "\u00A0",
                            action.toUpperCase(),
                            "\u00A0"),
                        "order with fixed price?"))
                        : (React.createElement("span", null,
                            "Are you sure you want to place",
                            React.createElement("span", { style: { color } },
                                "\u00A0",
                                action.toUpperCase(),
                                "\u00A0"),
                            "order?"))),
                    React.createElement("div", null, (streamType === 'standard') ? (React.createElement("div", null,
                        React.createElement("div", { className: styles.orderLabels },
                            React.createElement(Chip, { size: "small", color: "primary", label: `
                    ${action === 'Buy' ? total : orderAmt}
                    ${action === 'Buy' ? quote.toUpperCase() : base.toUpperCase()}` }),
                            React.createElement(NavigateNext, null),
                            React.createElement(Chip, { size: "small", color: "primary", label: `
                    ${action === 'Buy' ? orderAmt : total}
                    ${action === 'Buy' ? base.toUpperCase() : quote.toUpperCase()}` }))))
                        : (React.createElement("div", null,
                            React.createElement("div", { className: styles.orderLabels },
                                React.createElement(Chip, { size: "small", color: "primary", label: `
                      ${action === 'Buy' ? orderAmt : price}
                      ${action === 'Buy' ? quote.toUpperCase() : base.toUpperCase()}` }),
                                React.createElement(NavigateNext, null),
                                React.createElement(Chip, { size: "small", color: "primary", label: `
                      ${action === 'Buy' ? price : orderAmt}
                      ${action === 'Buy' ? base.toUpperCase() : quote.toUpperCase()}` }))))),
                    (streamType === 'standard') ? (React.createElement("div", { style: { marginBottom: '30px' } },
                        React.createElement(Box, { display: 'flex', justifyContent: 'center', gap: 1 },
                            "1 ",
                            base.toUpperCase(),
                            " ",
                            React.createElement(Cached, null),
                            " ",
                            price,
                            "\u00A0",
                            quote.toUpperCase()),
                        React.createElement("div", { className: styles.label }, "Exchange rate"))) : (null),
                    (seconds > 0) ? (React.createElement("span", null,
                        "You have ",
                        seconds,
                        " seconds to confirm your order.")) : (React.createElement("span", null,
                        "You have ",
                        seconds,
                        " seconds to confirm your order."))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", variant: "contained", disabled: disabled, onClick: addNewOrder }, "Confirm"),
            disabled ? (React.createElement(Button, { color: "primary", variant: "contained", onClick: () => {
                    const restartTime = new Date();
                    restartTime.setSeconds(restartTime.getSeconds() + 5);
                    restart(restartTime);
                    placeRfqPrice();
                } }, "Refresh")) : null,
            React.createElement(Button, { variant: "outlined", onClick: toggleFunc }, "Cancel")));
};
export default MarketForm;
