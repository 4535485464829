import { createSelector } from 'reselect';
const tradeMarkets = (state) => state.placeOrder.markets;
/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-explicit-any */
export const groupByObjectKey = (objectArray, property) => objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
        acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
}, {});
export const getMarketsByExchange = createSelector(tradeMarkets, (markets) => {
    const groupedMarkets = groupByObjectKey(markets, 'exchange_code');
    return groupedMarkets;
});
