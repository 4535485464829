import styled from 'styled-components';
// Containers
export const PanelContainer = styled.div `
  margin-bottom: 10px;
  .react-grid-item {
    &.react-grid-placeholder {
      background-color: #0b0b0d;
      border-radius: 4px;
    }
  }
`;
export const BodyContainer = styled.div `
  min-height: 410px;
`;
export const PanelTitle = styled.div `
  h5 {
    text-transform: uppercase;
    span {
      margin-left: 10px;
    }
  }
`;
export const SpinnerContainer = styled.div `
  text-align: center;
  padding: 50px 10px;
`;
export const IconContainer = styled.div `
  text-align: center;
  padding: 30px 10px;
  font-size: 14px;
`;
export const ClientSelectContainer = styled.div `
  margin-top: 0px;
  width: 180px;
`;
export const TableBody = styled.tbody `
  > tr > td {
    padding: 10px;
  }
`;
// Filters
export const FiltersContainer = styled.div `
  display: flex;
  padding: 10px;
`;
export const SelectedFilters = styled.div `
  flex: 1;
`;
export const MoreFilters = styled.div `
  flex: none;
  width: 145px;
`;
// Sidebar
export const LinkWrap = styled.span `
  text-transform: uppercase;
`;
export const SidebarSearch = styled.div `
  padding: 10px;
`;
// Reports
export const FieldLabel = styled.span `
  font-weight: bold;
`;
export const PickerContainer = styled.div `
  display: flex;
`;
export const DatePickerContainer = styled.div `
  flex: 2;
  padding-right: 20px;
`;
export const TimePickerContainer = styled.div `
  flex: 1;
`;
export const TimePickerCleanIcon = styled.span `
  position: absolute;
  right: 10px;
  top: 5px;
`;
export const FooterContainer = styled.div `
  text-align: right;
  padding: 20px 0;
`;
export const HeaderContainer = styled.div `
  display: block;
  text-align: left;
`;
export const HeaderContainerItem = styled.div `
  text-align: left;
`;
export const CancelButtonContainer = styled.div `
  padding-top: 0px;
`;
export const TitleContainer = styled.div `
  margin-bottom: 15px;
`;
