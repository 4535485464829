import React, { useEffect, useState } from 'react';
import { useCurrencies } from '../CurrenciesProvider';
import DLTFTable from '../../../../../../shared/components/table/DLTTableComponent';
import { CELL_TYPE } from '../../../../../../shared/components/table/DLTTableModel';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import PriceFeedCreate from './PriceFeedCreate';
import PriceFeedEdit from './PriceFeedEdit';
const columns = [
    { id: 'name', label: 'Name', sortable: true },
    { id: 'type', label: 'Type', sortable: true },
    { id: 'contract_address', label: 'Contract Address', sortable: true },
    { id: 'created_at', label: 'Created At', sortable: true, type: CELL_TYPE.DATE_TIME },
    { id: 'updated_at', label: 'Updated At', sortable: true, type: CELL_TYPE.DATE_TIME },
];
function mapPriceFeed(currencies, searchString) {
    return currencies
        .filter((p) => {
        var _a;
        let result = false;
        for (const prop in p) {
            const search = (_a = p[prop]) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().includes(searchString.toLowerCase());
            if (search) {
                result = true;
            }
        }
        return result;
    })
        .map((c) => (Object.assign(Object.assign({}, c), { id: c.uuid, name: c.name.toUpperCase(), type: c.type.toUpperCase(), contract_address: c.contract_address || '' })));
}
const PriceFeedsContainer = (props) => {
    const { addNew, searchString, onAddClose } = props;
    const { priceFeeds, getPriceFeeds, updatePriceFeed, addPriceFeed } = useCurrencies();
    const [mappedPriceFeeds, setMappedPriceFeeds] = useState([]);
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [selectedFeed, setSelectedFeed] = useState();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    useEffect(() => {
        setMappedPriceFeeds(mapPriceFeed(priceFeeds, ''));
    }, [priceFeeds]);
    useEffect(() => {
        setMappedPriceFeeds(mapPriceFeed(priceFeeds, searchString));
    }, [searchString]);
    useEffect(() => {
        getPriceFeeds();
    }, []);
    useEffect(() => {
        if (addNew) {
            setAddDialog(true);
        }
    }, [addNew]);
    const handleRowClick = (id) => {
        const feed = priceFeeds.find((p) => p.uuid === id);
        setSelectedFeed(feed);
        setEditDialog(true);
    };
    const handleEditClose = () => {
        setEditDialog(false);
        setSelectedFeed(undefined);
    };
    const handleUdpate = (data) => {
        if (selectedFeed) {
            setUpdateLoading(true);
            updatePriceFeed(data, selectedFeed.uuid).finally(() => {
                setEditDialog(false);
                setSelectedFeed(undefined);
                setUpdateLoading(false);
            });
        }
    };
    const handleAdd = (data) => {
        setCreateLoading(true);
        addPriceFeed(data).finally(() => {
            setCreateLoading(false);
            setAddDialog(false);
            onAddClose();
        });
    };
    if (!priceFeeds)
        return;
    return (React.createElement("div", null,
        React.createElement(DLTFTable, { columns: columns, defaultOrder: 'asc', defaultOrderProp: 'name', total: mappedPriceFeeds.length, data: mappedPriceFeeds, pagination: true, onRowClick: handleRowClick }),
        React.createElement(Dialog, { open: addDialog, maxWidth: 'md', fullWidth: true },
            React.createElement(DialogTitle, null, "Add Price Feed"),
            React.createElement(DialogContent, null,
                React.createElement(PriceFeedCreate, { loading: createLoading, onClose: () => {
                        setAddDialog(false);
                        onAddClose();
                    }, onSave: handleAdd }))),
        React.createElement(Dialog, { open: editDialog, maxWidth: 'sm', fullWidth: true },
            React.createElement(DialogTitle, null, "Edit Price Feed"),
            React.createElement(DialogContent, null,
                React.createElement(PriceFeedEdit, { priceFeed: selectedFeed, loading: updateLoading, onClose: handleEditClose, onSave: handleUdpate })))));
};
export default PriceFeedsContainer;
