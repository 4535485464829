import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Box, Button, Container, InputAdornment, TextField, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import SearchIcon from '@mui/icons-material/Search';
import DLTTable from '../../../../../shared/components/table/DLTTableComponent';
import ClientModal from '../../../Crypto/components/ClientSearch/ClientModal';
import ClientsService from '../../../../../services/clientsService';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import Messages from '../../../../../shared/helpers/errorMessages';
const columns = [
    { id: 'company_name', label: 'Name', width: 20, sortable: true },
    { id: 'contact_person_name', label: 'Contact', width: 20, sortable: true },
    { id: 'phone_number', label: 'Phone', width: 20 },
    { id: 'email', label: 'Email', width: 20 },
    { id: 'is_enabled', label: 'Status', width: 20, align: 'right' },
];
function mapClients(clients, searchString) {
    return clients
        .filter((p) => {
        var _a;
        let result = false;
        for (const prop in p) {
            const search = (_a = p[prop]) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().includes(searchString.toLowerCase());
            if (search) {
                result = true;
            }
        }
        return result;
    })
        .map((c) => (Object.assign(Object.assign({}, c), { id: c.code, is_enabled: c.is_enabled ? React.createElement(CircleIcon, { color: 'success' }) : React.createElement(CircleIcon, { color: 'error' }) })));
}
const ClientsContainer = (props) => {
    const [createClient, setCreateClient] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [mappedClients, setMappedClients] = useState([]);
    const history = useHistory();
    const handleRowSelect = (id) => {
        history.push(`./clients/${id}`);
    };
    useEffect(() => {
        if (props.clients)
            setMappedClients(mapClients(props.clients, searchString));
    }, [searchString, props.clients]);
    const handleCreateClient = (payload, setErr) => {
        const clientsService = new ClientsService({
            url: 'user/clients',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        clientsService
            .makeRequest()
            .then((response) => {
            showNotification({
                color: 'success',
                message: 'Client successfully created',
            });
            setCreateClient(false);
            history.push(`./clients/${response.code}`);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CLIENT_CREATE);
            setErr(message);
        });
    };
    return (React.createElement(Container, { maxWidth: 'xl' },
        React.createElement(Box, null,
            React.createElement(Box, { py: 2, sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 2 },
                    React.createElement(Typography, { variant: 'h6' }, "Clients"),
                    React.createElement(TextField, { size: 'small', value: searchString, onChange: (e) => setSearchString(e.target.value), InputProps: {
                            endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                                React.createElement(SearchIcon, null))),
                        } })),
                React.createElement(Button, { variant: 'outlined', size: 'small', onClick: () => setCreateClient(true) }, "Create"),
                React.createElement(ClientModal, { user: 'client', currentUser: null, open: createClient, toggleFunc: () => {
                        setCreateClient(false);
                    }, updateClient: () => { }, createClient: handleCreateClient })),
            React.createElement(DLTTable, { defaultOrderProp: 'company_name', defaultOrder: 'asc', pagination: true, onRowClick: handleRowSelect, total: mappedClients.length, columns: columns, data: mappedClients }))));
};
const stateToProps = (state) => {
    const { allClients } = state.clientSearch;
    return {
        clients: allClients,
    };
};
export default connect(stateToProps)(ClientsContainer);
