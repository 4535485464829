import * as types from '../actionTypes/apiErrorsActionTypes';
const initialState = {
    open: false,
    error: '',
};
/* eslint-disable @typescript-eslint/no-unsafe-return,
   @typescript-eslint/no-unsafe-assignment */
const apiErrorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.OPEN_ERROR_NOTICE: {
            return Object.assign(Object.assign({}, state), { open: true, error: action.message });
        }
        case types.CLOSE_ERROR_NOTICE: {
            return Object.assign(Object.assign({}, state), { open: false, error: '' });
        }
        default:
            return state;
    }
};
export default apiErrorsReducer;
