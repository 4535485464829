import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import PartialOrderbookContent from './PartialOrderbookContent';
import { TableContainer } from '@mui/material';
const PartialOrderbook = ({ pageSplit, partialOrders, total, onPageChange, }) => {
    const [rowsPerPage] = useState(10);
    return (React.createElement(React.Fragment, null,
        React.createElement(TableContainer, null,
            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, "ID"),
                        React.createElement(TableCell, null, "Action"),
                        React.createElement(TableCell, null, "Size"),
                        React.createElement(TableCell, null, "Pair"),
                        React.createElement(TableCell, null, "Executed"),
                        React.createElement(TableCell, null, "Percentage"),
                        React.createElement(TableCell, null, "Execution Price"),
                        React.createElement(TableCell, null, "Status"))),
                React.createElement(TableBody, null,
                    React.createElement(PartialOrderbookContent, { partialOrders: partialOrders })))),
        React.createElement(TablePagination, { component: "div", count: +total, page: pageSplit, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [rowsPerPage] })));
};
export default PartialOrderbook;
