import React from 'react';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';
const PartialOrderCheckbox = ({ name, color = '', label, check, onChange, validation, limitPrice, amount, orderType, }) => {
    const handleCheckboxChange = (evt) => {
        const { checked } = evt.target;
        onChange(checked);
        validation(amount, limitPrice, orderType === null || orderType === void 0 ? void 0 : orderType.value, checked);
    };
    return (React.createElement("label", { className: "checkbox-btn place_order_check_partial", htmlFor: name },
        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { id: name, name: name, checked: check, onChange: handleCheckboxChange, color: "primary" }), label: label })));
};
const stateToProps = (state) => {
    const { amount, limitPrice, orderType } = state.placeOrder;
    return {
        amount,
        orderType,
        limitPrice,
    };
};
export default connect(stateToProps)(PartialOrderCheckbox);
