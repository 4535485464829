import React, { useEffect, useState } from 'react';
import { Box, Chip, Button, Checkbox, Collapse, TextField, FormControlLabel, } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles } from '@mui/styles';
import { FiltersBox, FiltersBody, SelectedFilters, FiltersContainer, CustodyFilterPanelContainer, } from '../../styling/style';
const useStyles = makeStyles(() => createStyles({
    formGroup: {
        minWidth: 200,
    },
}));
const stateOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Unknown transaction', value: 'unknown_transaction' },
];
const FilterButton = ({ item, onChange }) => {
    if (item.value === '')
        return null;
    return (React.createElement(Chip, { size: "small", color: "primary", label: (React.createElement(Box, { gap: 3, display: 'flex' },
            React.createElement(Box, null, item.label),
            React.createElement(Box, null,
                React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onChange })))) }));
};
const ClientsFiltersContent = ({ state, hideZero, resetFilters, withMigrated, allCurrencies, setWithMigrated, handleStateChange, toggleZeroBalances, removeSelectedFilter, handleCurrencyChange, }) => {
    const classes = useStyles();
    const [collapse, setCollapse] = useState(false);
    const [status, setStatus] = useState(null);
    const [currency, setCurrency] = useState(null);
    useEffect(() => {
        const objIndexCurrency = state.filters.findIndex((obj => obj.name === 'currency_code'));
        const currencyData = state.filters[objIndexCurrency];
        setCurrency(currencyData || null);
        const objIndexStatus = state.filters.findIndex((obj => obj.name === 'state'));
        const stateData = state.filters[objIndexStatus];
        setStatus(stateData || null);
    }, [state]);
    const toggleCollapseFilter = () => {
        setCollapse(!collapse);
    };
    const toggleWithMigrated = () => {
        setWithMigrated(!withMigrated);
    };
    return (React.createElement(CustodyFilterPanelContainer, null,
        React.createElement(FiltersContainer, null,
            React.createElement(Box, { display: 'flex', justifyContent: 'space-between' },
                React.createElement(Box, { display: 'flex', flexDirection: 'row', gap: 1 },
                    React.createElement(Box, null,
                        React.createElement(Button, { size: "small", color: "primary", variant: "outlined", onClick: toggleCollapseFilter },
                            collapse ? '-' : '+',
                            " Quick filters")),
                    state.filters.length ? (React.createElement(Box, null,
                        React.createElement(Button, { size: "small", color: "inherit", variant: "outlined", onClick: resetFilters }, "Reset filters"))) : null,
                    React.createElement(Box, null,
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: hideZero, name: "hideZeroClients", onChange: toggleZeroBalances }), label: "Hide zero balances" })))),
            React.createElement(SelectedFilters, null,
                React.createElement("div", null,
                    React.createElement(FiltersBox, null, state.filters.map((filter) => (React.createElement(FilterButton, { key: filter.name, item: filter, onChange: () => removeSelectedFilter(filter) }))))))),
        React.createElement(Collapse, { in: collapse },
            React.createElement(FiltersBody, null,
                React.createElement("div", null,
                    React.createElement(Box, { style: { display: 'flex' } },
                        React.createElement(Box, { className: classes.formGroup, mr: 1 },
                            React.createElement(Autocomplete, { loading: false, options: stateOptions, getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleStateChange(newValue), renderInput: (params) => React.createElement(TextField, Object.assign({ name: "state" }, params, { variant: "outlined", value: {
                                        value: status,
                                        label: 'State',
                                    }, size: "small" })) })),
                        React.createElement(Box, { className: classes.formGroup, mr: 1 },
                            React.createElement(Autocomplete, { loading: false, options: allCurrencies, getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleCurrencyChange(newValue), renderInput: (params) => React.createElement(TextField, Object.assign({ name: "currency_code" }, params, { variant: "outlined", value: {
                                        value: currency,
                                        label: 'Currency',
                                    }, size: "small" })) })),
                        React.createElement(Box, null,
                            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { checked: withMigrated, name: "withClientMigrated", onChange: toggleWithMigrated }), label: "With migrated" }))))))));
};
export default ClientsFiltersContent;
