import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import RegularOrderbookContent from './RegularOrderlistContent';
import { TableContainer } from '@mui/material';
const RegularOrderbook = ({ total, pageRegular, onPageChange, regularOrders, handleExchangeChange, }) => {
    const [rowsPerPage] = useState(10);
    return (React.createElement("div", null,
        React.createElement(TableContainer, null,
            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, "Action"),
                        React.createElement(TableCell, null, "Type"),
                        React.createElement(TableCell, null, "Size"),
                        React.createElement(TableCell, null, "Pair"),
                        React.createElement(TableCell, null, "Exchange"),
                        React.createElement(TableCell, null, "Price Per"),
                        React.createElement(TableCell, null, "Total"),
                        React.createElement(TableCell, null, "Created"),
                        React.createElement(TableCell, null, "Status"),
                        React.createElement(TableCell, { style: { whiteSpace: 'nowrap' } }, "Cust. Code"),
                        React.createElement(TableCell, null, "Ref. ID"),
                        React.createElement(TableCell, null, "Actions"))),
                React.createElement(TableBody, null,
                    React.createElement(RegularOrderbookContent, { regularOrders: regularOrders, handleExchangeChange: handleExchangeChange })))),
        React.createElement(TablePagination, { component: "div", count: +total, page: pageRegular, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [rowsPerPage] })));
};
export default RegularOrderbook;
