import React from 'react';
import { Container } from '@mui/material';
import { connect } from 'react-redux';
import { getWidgets } from '../../../../../../redux/selectors/widgetSettingsSelector';
import { SCHEMA_UPDATE_LAYOUT_PROPS } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import CustomersTable from './CustomersTable';
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
const Customers = () => {
    return (React.createElement(Container, { maxWidth: 'xl' },
        React.createElement(CustomersTable, null)));
};
const stateToProps = (state) => {
    const { loading } = state.workspace;
    const widgets = getWidgets(state);
    return {
        widgets,
        loadingWorkspaces: loading,
    };
};
const dispatchToProps = (dispatch) => ({
    dispatchUpdateLayoutProps: (widgets) => dispatch({ type: SCHEMA_UPDATE_LAYOUT_PROPS, widgets }),
});
export default connect(stateToProps, dispatchToProps)(Customers);
