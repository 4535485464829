import { NEW_ORDER_TOTAL_PRICE_UPDATE } from '../../../../../../redux/actionTypes/placeOrderActionTypes';
const calculateMarketAmountFromPrice = (total, bids, asks, action, dispatch) => {
    let orders = [];
    if (action === 'Sell') {
        orders = bids;
    }
    else if (action === 'Buy') {
        orders = asks;
    }
    let calculatedTotal = 0;
    let resAmount = 0;
    for (const order of orders) {
        if (calculatedTotal === +total) {
            break;
        }
        const growth = (calculatedTotal + (+order.price * +order.volume) < +total)
            ? (+order.price * +order.volume)
            : (+total - calculatedTotal);
        resAmount += (growth / +order.price);
        calculatedTotal += growth;
    }
    if (calculatedTotal < +total) {
        dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: String(calculatedTotal) });
    }
    return resAmount;
};
export default calculateMarketAmountFromPrice;
