var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box, Button, Checkbox, Container, Divider, MenuItem, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { UPDATE_LAST_SEEN_VERSION } from '../../redux/actionTypes/workspaceActions';
import ReactMarkdown from 'react-markdown';
import { loadMarkdown } from './loadFile';
function compareVersions(version1, version2) {
    const v1Parts = version1.split('.').map(Number);
    const v2Parts = version2.split('.').map(Number);
    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
        const v1Part = v1Parts[i] || 0;
        const v2Part = v2Parts[i] || 0;
        if (v1Part > v2Part) {
            return 1;
        }
        if (v1Part < v2Part) {
            return -1;
        }
    }
    return 0;
}
const savedVersionUpdate = (version) => ({
    type: UPDATE_LAST_SEEN_VERSION,
    newVersion: version,
});
const ReleaseNotes = (props) => {
    var _a;
    const { masterTenant } = props;
    const [selectedVersion, setSelectedVersion] = useState();
    const [releaseIndex, setReleaseIndex] = useState(0);
    const [releases, setReleases] = useState([]);
    const [hasRead, setHasRead] = useState(false);
    const [disabled, setDisabled] = useState();
    const [content, setContent] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        fetch('./releases/releases.json')
            .then((res) => res.json())
            .then((data) => {
            let filtered;
            if (!masterTenant) {
                filtered = data.filter((d) => !d.adminOnly);
            }
            else {
                filtered = data;
            }
            setReleases(filtered);
            const savedVersion = localStorage.getItem('lastSeenVersion') || '0';
            const currentSavedIndex = data.findIndex((release) => release.version === savedVersion);
            if (currentSavedIndex === 0) {
                setSelectedVersion(data[0].version);
            }
            else {
                const index = currentSavedIndex > -1 ? currentSavedIndex - 1 : data.length - 1;
                setSelectedVersion(data[index].version);
            }
        });
    }, []);
    useEffect(() => {
        if (!selectedVersion)
            return;
        const index = releases === null || releases === void 0 ? void 0 : releases.findIndex((r) => r.version === selectedVersion);
        const fetchFile = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const module = yield loadMarkdown(selectedVersion);
                setContent(module.default);
            }
            catch (error) {
                console.error('Error loading markdown file:', error);
            }
        });
        fetchFile();
        setReleaseIndex(index);
        const savedVersion = localStorage.getItem('lastSeenVersion') || '0';
        setHasRead(compareVersions(savedVersion, selectedVersion) > -1);
        setDisabled(compareVersions(localStorage.getItem('lastSeenVersion') || '0', selectedVersion) > -1);
    }, [selectedVersion]);
    const handleReadNote = () => {
        if (!selectedVersion)
            return;
        dispatch(savedVersionUpdate(selectedVersion));
        const nextIndex = releaseIndex > 0 ? releaseIndex - 1 : 0;
        if (nextIndex === 0) {
            setDisabled(true);
        }
        setSelectedVersion(releases[nextIndex].version);
    };
    if (!releases || !selectedVersion) {
        return;
    }
    return (React.createElement(Box, { p: 1 },
        React.createElement(Paper, { elevation: 0 },
            React.createElement(Container, { maxWidth: 'lg' },
                React.createElement(Box, { p: 2 },
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                        React.createElement(Typography, { variant: 'h4' }, "Release Notes"),
                        selectedVersion ? (React.createElement(TextField, { size: 'small', fullWidth: true, select: true, label: 'Version', value: selectedVersion, onChange: (e) => setSelectedVersion(e.target.value), sx: { maxWidth: '200px' } }, releases.map((option) => (React.createElement(MenuItem, { key: option.version, value: option.version }, option.version))))) : null)),
                React.createElement(Divider, null),
                React.createElement(Box, { p: 2 },
                    React.createElement(ReactMarkdown, null, content),
                    selectedVersion === ((_a = releases[0]) === null || _a === void 0 ? void 0 : _a.version) ? (React.createElement(Box, { sx: { display: 'flex', justifyContent: 'end', alignItems: 'center' }, gap: 2 },
                        React.createElement(Box, null,
                            React.createElement(Checkbox, { disabled: disabled, checked: hasRead, onChange: () => setHasRead(!hasRead) }),
                            React.createElement(Typography, { variant: 'overline' }, "I have read release notes")),
                        React.createElement(Button, { disabled: disabled || !hasRead, variant: 'contained', onClick: handleReadNote }, "Confirm"))) : null)))));
};
const stateToProps = (state) => ({
    masterTenant: state.client.masterTenant,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchSavedVersion: (version) => dispatch(savedVersionUpdate(version)),
});
export default connect(stateToProps, mapDispatchToProps)(ReleaseNotes);
