import React, { useCallback, useContext, useEffect, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Box, Grid, LinearProgress } from '@mui/material';
import Paper from '@mui/material/Paper';
import format from 'date-fns/format';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import buildUrl from 'build-url';
import { ADMIN_LINE_CHART_DATA, } from '../../../../../../redux/actionTypes/adminActionTypes';
import chartLineData from './ChartLineData';
import OrdersService from '../../../../../../services/ordersService';
import calculateStats from './CalculateStats';
import Messages from '../../../../../../shared/helpers/errorMessages';
import { RatesContext } from '../../../../../../providers/RatesProvider';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO, isValid } from 'date-fns';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => createStyles({
    root: {},
    order: {
        backgroundColor: theme.palette.background.default,
    }
}));
const WidgetTradingStats = ({ activeClient }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const getCalendarDates = () => {
        const d = new Date();
        const month = d.getMonth();
        d.setMonth(d.getMonth() - 1);
        while (d.getMonth() === month) {
            d.setDate(d.getDate() - 1);
        }
        return [new Date(), d];
    };
    const classes = useStyles();
    const [isToOpen, setToOpen] = useState(false);
    const [isFromOpen, setFromOpen] = useState(false);
    const [loadingSub, setLoadingSub] = useState(false);
    const [loadingReg, setLoadingReg] = useState(false);
    const [totalRegular, setTotalRegular] = useState(0);
    const [totalSuborders, setTotalSuborders] = useState(0);
    const [filteredOrders] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [subOrders, setSuborders] = useState([]);
    const [regOrders, setRegOrders] = useState([]);
    const [dateEnd, setDateEnd] = useState(getCalendarDates()[0]);
    const [ordersStat, setOrdersStat] = useState(null);
    const [dateStart, setDateStart] = useState(getCalendarDates()[1]);
    const ctx = useContext(RatesContext);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleDateFromChange = (date) => {
        if (date && isValid(date)) {
            const value = new Date(date.setUTCHours(0, 0, 0, 0));
            setDateStart(value);
            setFromOpen(false);
        }
    };
    const handleDateToChange = (date) => {
        if (date && isValid(date)) {
            const value = new Date(date.setUTCHours(0, 0, 0, 0));
            setDateEnd(value);
            setToOpen(false);
        }
    };
    const requestFetchOrders = (page, client, customerCode) => {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                is_suborder: 'false',
                page: `${page}`,
                client_code: client ? client.value : '',
                customer_code: customerCode || '',
                from: (dateStart === null || dateStart === void 0 ? void 0 : dateStart.toISOString()) || '',
                to: (dateEnd === null || dateEnd === void 0 ? void 0 : dateEnd.toISOString()) || '',
            },
        };
        const url = `/orders${buildUrl('', urlOptions)}`;
        const serviceInstance = new OrdersService({ url, method: 'get' });
        setLoadingReg(true);
        return serviceInstance.makeRequest();
    };
    const requestFetchSubOrders = (page, client, customerCode) => {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                is_suborder: 'true',
                page: `${page}`,
                client_code: client ? client.value : '',
                customer_code: customerCode || '',
                from: (dateStart === null || dateStart === void 0 ? void 0 : dateStart.toISOString()) || '',
                to: (dateEnd === null || dateEnd === void 0 ? void 0 : dateEnd.toISOString()) || '',
            },
        };
        const url = `/orders${buildUrl('', urlOptions)}`;
        const serviceInstance = new OrdersService({ url, method: 'get' });
        setLoadingSub(true);
        return serviceInstance.makeRequest();
    };
    // Effects
    // SZ: activeClient is essential to begin any operations in useEffects
    useEffect(() => {
        if (!activeClient)
            return;
        // Major state reset here
        setOrdersStat(null);
        setTotalRegular(0);
        setTotalSuborders(0);
        dispatch({ type: ADMIN_LINE_CHART_DATA, lineChartData: null });
        requestFetchOrders(1, activeClient)
            .then((data) => {
            if (data.total === 0) {
                setLoadingReg(false);
                setRegOrders([]);
            }
            else {
                setTotalRegular(data.total / 1000);
            }
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.ORDERS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
        requestFetchSubOrders(1, activeClient)
            .then((data) => {
            if (data.total === 0) {
                setLoadingSub(false);
                setSuborders([]);
            }
            else {
                setTotalSuborders(data.total / 1000);
            }
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.ORDERS_SUBORDERS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    }, [activeClient, dateEnd, dateStart]);
    useEffect(() => {
        if (activeClient && totalRegular) {
            let n = 0;
            const allRequests = [];
            while (n < totalRegular) {
                n += 1;
                allRequests.push(requestFetchOrders(n, activeClient));
            }
            Promise.all(allRequests)
                .then((data) => {
                const ordersArr = [];
                data.forEach((result) => ordersArr
                    .push(...result.records.filter(o => o.status === 'Filled' || o.status === 'CanceledPartiallyFilled')));
                setLoadingReg(false);
                setRegOrders(ordersArr);
            })
                .catch((e) => {
                if (e.message === 'canceled') {
                    return;
                }
                setLoadingReg(false);
                const message = composeErrorMessage(e, Messages.ORDERS_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
        }
    }, [totalRegular]);
    useEffect(() => {
        if (activeClient && totalSuborders) {
            let n = 0;
            const allRequests = [];
            while (n < totalSuborders) {
                n += 1;
                allRequests.push(requestFetchSubOrders(n, activeClient));
            }
            Promise.all(allRequests)
                .then((data) => {
                const ordersArr = [];
                data.forEach((result) => ordersArr
                    .push(...result.records.filter(o => o.status === 'Filled' || o.status === 'CanceledPartiallyFilled')));
                setLoadingSub(false);
                setSuborders(ordersArr);
            })
                .catch((e) => {
                if (e.message === 'canceled') {
                    return;
                }
                setLoadingSub(false);
                const message = composeErrorMessage(e, Messages.ORDERS_SUBORDERS_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
        }
    }, [totalSuborders]);
    useEffect(() => {
        if (!activeClient)
            return;
        if (!loadingReg && !loadingSub) {
            setAllOrders([...subOrders, ...regOrders]);
        }
    }, [subOrders, regOrders]);
    useEffect(() => {
        if (!activeClient)
            return;
        if (ctx.service && ctx.rates) {
            const statForFiltered = calculateStats(allOrders, ctx.service);
            setOrdersStat(statForFiltered);
            const result = chartLineData(allOrders, ctx.service);
            const lineChartData = result || 'n/a';
            dispatch({ type: ADMIN_LINE_CHART_DATA, lineChartData });
        }
    }, [allOrders, ctx.rates]);
    useEffect(() => {
        if (!activeClient)
            return;
        if (ctx.service && ctx.rates) {
            setTimeout(() => {
                if (ctx.service) {
                    const statForFiltered = calculateStats(filteredOrders, ctx.service);
                    setOrdersStat(statForFiltered);
                    const result = chartLineData(filteredOrders, ctx.service);
                    const lineChartData = result || 'n/a';
                    dispatch({ type: ADMIN_LINE_CHART_DATA, lineChartData });
                }
            }, 0);
        }
    }, [filteredOrders, ctx.service]);
    if (!activeClient) {
        return (React.createElement(Box, { p: 2 },
            React.createElement(Paper, { className: "wallets_empty_paper_root" },
                React.createElement(Alert, { severity: "info", variant: "outlined" }, "Select client for more details"))));
    }
    if (loadingReg || loadingSub) {
        return (React.createElement(Box, { p: 2 },
            React.createElement(Typography, { variant: 'subtitle1' }, "Gathering data..."),
            React.createElement(LinearProgress, null)));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { p: 2 },
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { alignContent: "flex-start", container: true, item: true, spacing: 2, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Box, { display: 'flex', gap: 1 },
                            React.createElement(DatePicker, { open: isFromOpen, onOpen: () => setFromOpen(true), onClose: () => setFromOpen(false), format: "dd/MM/yyyy", value: dateStart, disableFuture: true, label: "Transactions start date", slotProps: {
                                    textField: {
                                        size: 'small',
                                    },
                                    field: { clearable: false }
                                }, onChange: (date) => handleDateFromChange(date) }),
                            React.createElement(DatePicker, { open: isToOpen, onOpen: () => setToOpen(true), onClose: () => setToOpen(false), format: "dd/MM/yyyy", label: "Transactions end date", value: dateEnd, disableFuture: true, slotProps: {
                                    textField: {
                                        size: 'small',
                                    },
                                    field: { clearable: false }
                                }, onChange: (date) => handleDateToChange(date) }))),
                    (ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.count) === '0' ? (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Alert, { severity: "warning", variant: "outlined" }, "No data for selected dates"))) : (React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                                React.createElement(Box, null,
                                    React.createElement(Typography, { variant: "h6", color: "Highlight" }, "Max order"),
                                    React.createElement("div", null, (_a = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.maxOrder) === null || _a === void 0 ? void 0 : _a.code)),
                                React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true, xs: 4 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Date created"),
                                        React.createElement(Typography, { sx: { fontWeight: 'bold' } }, ((_b = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.maxOrder) === null || _b === void 0 ? void 0 : _b.created) ?
                                            format(parseISO((_c = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.maxOrder) === null || _c === void 0 ? void 0 : _c.created), 'dd/MM/yyyy') : null)),
                                    React.createElement(Grid, { item: true, xs: 4 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Type"),
                                        React.createElement(Typography, { sx: { fontWeight: 'bold' } }, (_d = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.maxOrder) === null || _d === void 0 ? void 0 : _d.type)),
                                    React.createElement(Grid, { item: true, xs: 4 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Amount"),
                                        React.createElement(Typography, { variant: 'h6', sx: { fontWeight: 'bold' } },
                                            "$ ", (_e = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.maxOrder) === null || _e === void 0 ? void 0 :
                                            _e.amount)))),
                            React.createElement(Divider, null),
                            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                                React.createElement("div", null,
                                    React.createElement(Typography, { variant: "h6", color: "Highlight" }, "Min order"),
                                    React.createElement("div", null, (_f = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.minOrder) === null || _f === void 0 ? void 0 : _f.code)),
                                React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { item: true, xs: 4 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Date created"),
                                        React.createElement(Typography, { sx: { fontWeight: 'bold' } }, ((_g = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.minOrder) === null || _g === void 0 ? void 0 : _g.created) ?
                                            format(parseISO((_h = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.minOrder) === null || _h === void 0 ? void 0 : _h.created), 'dd/MM/yyyy') : null)),
                                    React.createElement(Grid, { item: true, xs: 4 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Type"),
                                        React.createElement(Typography, { sx: { fontWeight: 'bold' } }, (_j = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.minOrder) === null || _j === void 0 ? void 0 : _j.type)),
                                    React.createElement(Grid, { item: true, xs: 4 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Amount"),
                                        React.createElement(Typography, { variant: 'h6', sx: { fontWeight: 'bold' } },
                                            "\u2248 $ ", (_k = ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.minOrder) === null || _k === void 0 ? void 0 :
                                            _k.amount)))),
                            React.createElement(Divider, null),
                            React.createElement(Grid, { item: true, xs: 12 },
                                React.createElement(Grid, { container: true },
                                    React.createElement(Grid, { xs: 6 },
                                        React.createElement(Typography, { variant: "h6", color: "Highlight" }, "Total transactions"),
                                        React.createElement(Typography, { variant: "h6", sx: { fontWeight: 'bold' } }, ordersStat === null || ordersStat === void 0 ? void 0 : ordersStat.count)),
                                    React.createElement(Grid, { xs: 6 },
                                        React.createElement(Typography, { variant: "h6", color: "Highlight" }, "Average total"),
                                        React.createElement(Typography, { variant: "h6", sx: { fontWeight: 'bold' } },
                                            "$ ", ordersStat === null || ordersStat === void 0 ? void 0 :
                                            ordersStat.avgAmount))))))))))));
};
export default WidgetTradingStats;
