/* eslint-disable prefer-arrow-callback, @typescript-eslint/no-floating-promises */
import React from 'react';
// import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'material-icons/iconfont/material-icons.css';
import store from './store';
import Login from './Login';
import '../../scss/app.scss';
import Router from './Router';
import Register from './Register';
import ResetPass from './ResetPass';
import ResetTOTP from './ResetTOTP';
import { ConnectedThemeComponent } from '../../theme/ConnectedThemeComponent';
const isLoginPage = () => window.location.href.includes('/login');
const isRegisterPage = () => window.location.href.includes('/register');
const isReset = () => window.location.href.includes('/password_reset');
const isResetTOTP = () => window.location.href.includes('/2fa_reset');
let mainView = React.createElement(Router, null);
const App = () => {
    if (isLoginPage()) {
        mainView = React.createElement(Login, null);
    }
    if (isRegisterPage()) {
        mainView = React.createElement(Register, null);
    }
    if (isReset()) {
        mainView = React.createElement(ResetPass, null);
    }
    if (isResetTOTP()) {
        mainView = React.createElement(ResetTOTP, null);
    }
    return (React.createElement(Provider, { store: store },
        React.createElement(BrowserRouter, { basename: '/' },
            React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns },
                React.createElement(React.Fragment, null,
                    React.createElement(ConnectedThemeComponent, null, mainView))))));
};
export default App;
