import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import buildUrl from 'build-url';
import { Box, Button, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ReportsService from '../../../../../services/reportsService';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import DateFnsAdapter from "@date-io/date-fns";
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
const CreateFee = () => {
    const defaultDateTill = new Date();
    const defaultDateFrom = (d => new Date(d.setDate(d.getDate() - 1)))(new Date());
    const [loading, setLoading] = useState(false);
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [dateFrom, setDateFrom] = useState(defaultDateFrom);
    const [dateTill, setDateTill] = useState(defaultDateTill);
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTill, setTimeTill] = useState(null);
    const [errors, setErrors] = useState([]);
    const handleChangeInvoice = (evt) => {
        const { value } = evt.target;
        setInvoiceNumber(value);
    };
    const dateFns = new DateFnsAdapter();
    const handleDateFromChange = (date, context) => {
        setDateFrom(!context.validationError ? date : null);
    };
    const handleDateTillChange = (date, context) => {
        setDateTill(!context.validationError ? date : null);
    };
    const setTimeFromChange = (date, context) => {
        setTimeFrom(!context.validationError ? date : null);
    };
    const setTimeTillChange = (date, context) => {
        setTimeTill(!context.validationError ? date : null);
    };
    const generatePdf = (amountUSD, amountEUR) => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF();
        doc.setFontSize(18);
        doc.setFont('helvetica');
        doc.text('DLT MARKETS', 100, 20);
        doc.setTextColor(150);
        doc.setFontSize(10);
        doc.text('DLT Markets AG | Landstrasse 14 | 9496 Balzers | Liechtenstein', 20, 40, { align: 'left' });
        doc.setTextColor('black');
        doc.text('Bank Frick & Co. AG', 20, 60, { align: 'left' }, null);
        doc.setFont('helvetica', 'normal');
        doc.text('Landstrasse 14', 20, 65, { align: 'left' }, null);
        doc.text('9496 Balzers', 20, 70, { align: 'left' }, null);
        doc.text('Liechtenstein', 20, 75, { align: 'left' }, null);
        let totalDate = dateFrom === null || dateFrom === void 0 ? void 0 : dateFrom.toLocaleDateString();
        if (dateFrom && dateTill && dateFrom !== dateTill) {
            totalDate = `${dateFrom.toLocaleDateString()} - ${dateTill.toLocaleDateString()}`;
        }
        autoTable(doc, {
            head: [{
                    Kundennummer: 'Kundennummer',
                    Leistungszeitraum: 'Leistungszeitraum',
                    Datum: 'Datum',
                }],
            body: [{
                    Kundennummer: '001',
                    Leistungszeitraum: totalDate || '',
                    Datum: defaultDateTill.toLocaleDateString(),
                }],
            startY: 85,
            theme: 'plain',
            margin: { left: 107 },
        });
        doc.setFontSize(18);
        doc.text(`Rechnung Nr. ${invoiceNumber} -`, Math.floor(doc.internal.pageSize.width / 2), 112, { align: 'center' }, null);
        doc.text('Gebührenabberechnung DLT Markets Plattform', Math.floor(doc.internal.pageSize.width / 2), 120, { align: 'center' }, null);
        doc.setFontSize(10);
        doc.text('Wir stellen folgende Leistungen in Rechnung gemäß Auflistung in Anhang:', 20, 130, { align: 'left' }, null);
        autoTable(doc, {
            head: [{
                    'Nr.': 'Nr.',
                    Beschreibung: 'Beschreibung',
                    Menge: 'Menge',
                    Summe: 'Summe',
                }],
            body: [{
                    'Nr.': '1',
                    Beschreibung: 'Gebührenabrechnung USD',
                    Menge: '1',
                    Summe: `${amountUSD} USD`,
                }, {
                    'Nr.': '2',
                    Beschreibung: 'Gebührenabrechnung EUR',
                    Menge: '1',
                    Summe: `${amountEUR} EUR`,
                }],
            columnStyles: {
                Beschreibung: {
                    fontStyle: 'bold',
                },
            },
            startY: 150,
            theme: 'plain',
            margin: { left: 20 },
        });
        doc.setFontSize(10);
        doc.text('Zahlbar innerhalb von 10 Tagen ab Rechnungseingang ohne Abzug.', 20, 190, { align: 'left' }, null);
        doc.text('Mit freundlichen Grüßen', 20, 210, { align: 'left' }, null);
        doc.setFontSize(18);
        doc.setFont('helvetica', 'bold');
        doc.text('Roger Wurzel', 20, 230, { align: 'left' }, null);
        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');
        doc.text('Bankverbindung: Bank Frick & Co AG; BIC: BFRILI22, IBAN: LI07 0881 1010 3047 K000 C', 20, 285, { align: 'left' }, null);
        doc.save(`DLT_Finance_Invoice_${dateFrom === null || dateFrom === void 0 ? void 0 : dateFrom.toLocaleDateString()}-${dateTill === null || dateTill === void 0 ? void 0 : dateTill.toLocaleDateString()}.pdf`);
    };
    const validateFields = () => {
        const map = {
            dateFrom,
            dateTill,
            timeFrom,
            timeTill,
        };
        const errs = [];
        for (const [key, value] of Object.entries(map)) {
            if (!value) {
                errs.push(key);
            }
        }
        if (errs.length) {
            setErrors(() => [...errs]);
        }
        else {
            setErrors(() => []);
        }
    };
    useEffect(() => {
        validateFields();
    }, [dateFrom, dateTill, timeFrom, timeTill]);
    const handleDownload = () => {
        const dateFromFormatted = dateFrom && dateFns.isValid(dateFrom) ? dateFns.format(dateFrom, 'yyyy-MM-dd') : '';
        const dateTillFormatted = dateTill && dateFns.isValid(dateTill) ? dateFns.format(dateTill, 'yyyy-MM-dd') : '';
        const timeFromFormatted = timeFrom && dateFns.isValid(timeFrom) ? dateFns.format(timeFrom, 'HH:mm:ss') : '';
        const timeTillFormatted = timeTill && dateFns.isValid(timeTill) ? dateFns.format(timeTill, 'HH:mm:ss') : '';
        validateFields();
        if (!dateFromFormatted || !dateTillFormatted || !timeFromFormatted || !timeTillFormatted) {
            return;
        }
        const reportUrlOptions = {
            queryParams: {
                from: `${dateFromFormatted}T${timeFromFormatted}`,
                to: `${dateTillFormatted}T${timeTillFormatted}`,
            },
            path: 'trades/fees',
        };
        const reportService = new ReportsService({
            url: buildUrl('', reportUrlOptions),
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        reportService.makeRequest()
            .then((response) => {
            setLoading(false);
            let eurAmount = '0.00';
            let usdAmount = '0.00';
            const r = response.fees || [];
            r.forEach((fee) => {
                if (fee.currency_code === 'EUR') {
                    eurAmount = fee.amount;
                }
                if (fee.currency_code === 'USD') {
                    usdAmount = fee.amount;
                }
            });
            generatePdf(usdAmount, eurAmount);
        })
            .catch((e) => {
            setLoading(false);
            throw new Error(e);
        });
    };
    useEffect(() => {
        const m = dateFns.date(new Date());
        m.setMinutes(0);
        m.setSeconds(0);
        m.setHours(0);
        setTimeFrom(m);
        setTimeTill(m);
    }, []);
    return (React.createElement(Box, { pt: 3, display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { maxWidth: '460px', display: 'flex', gap: 2, flexDirection: 'column' },
            React.createElement(Box, null,
                React.createElement(TextField, { variant: "outlined", size: "small", label: "Invoice number", onChange: handleChangeInvoice })),
            React.createElement(Box, { display: 'flex', gap: 1 },
                React.createElement(DatePicker, { disableFuture: true, format: "dd/MM/yyyy", label: "Date created from", value: dateFrom, onClose: validateFields, slotProps: {
                        actionBar: {
                            actions: ['clear', 'cancel', 'today']
                        },
                        textField: {
                            size: 'small',
                            variant: 'outlined',
                            error: errors.includes('dateFrom'),
                            helperText: errors.includes('dateFrom') ? 'Field is required' : '',
                        }
                    }, onChange: handleDateFromChange }),
                React.createElement(TimePicker, { label: "Time created from", value: timeFrom, onChange: setTimeFromChange, ampm: false, slotProps: {
                        actionBar: {
                            actions: ['clear', 'cancel', 'today']
                        },
                        textField: {
                            size: 'small',
                            variant: 'outlined',
                            error: errors.includes('timeFrom'),
                            helperText: errors.includes('timeFrom') ? 'Field is required' : '',
                        }
                    } })),
            React.createElement(Box, { display: 'flex', gap: 1 },
                React.createElement(DatePicker, { disableFuture: true, format: "dd/MM/yyyy", label: "Date created till", value: dateTill, onClose: validateFields, slotProps: {
                        actionBar: {
                            actions: ['clear', 'cancel', 'today']
                        },
                        textField: {
                            size: 'small',
                            error: errors.includes('dateTill'),
                            helperText: errors.includes('dateTill') ? 'Field is required' : '',
                        }
                    }, onChange: handleDateTillChange }),
                React.createElement(TimePicker, { label: "Time created till", value: timeTill, ampm: false, slotProps: {
                        actionBar: {
                            actions: ['clear', 'cancel', 'today']
                        },
                        textField: {
                            size: 'small',
                            error: errors.includes('timeTill'),
                            helperText: errors.includes('timeTill') ? 'Field is required' : '',
                        }
                    }, onChange: setTimeTillChange }))),
        React.createElement(Box, { display: 'flex', justifyContent: 'flex-end' },
            React.createElement(Button, { variant: "contained", type: "button", color: "primary", disabled: loading, onClick: handleDownload },
                loading ? 'Downloading' : ' Download PDF',
                "\u00A0",
                loading ? React.createElement(CircularProgress, { size: 18 }) : ''))));
};
export default CreateFee;
