import styled from 'styled-components';
// Containers
export const PanelContainer = styled.div `
  margin-bottom: 10px;
  width: 100%;
`;
export const BodyContainer = styled.div `
  min-height: 410px;
`;
export const PanelTitle = styled.div `
  h5 {
    text-transform: uppercase;
    span {
      margin-left: 10px;
    }
  }
`;
export const SpinnerContainer = styled.div `
  text-align: center;
  padding: 50px 10px;
`;
export const ClientSelectContainer = styled.div `
  margin-top: 0px;
`;
// Filters
export const FiltersContainer = styled.div `
  padding: 10px;
  
`;
export const FiltersBody = styled.div `
  padding: 15px 5px;
`;
export const SelectedFilters = styled.div `
  padding-top: 20px;
  > div {
    display: flex;
  }
`;
export const FiltersBox = styled.div `
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  > .form__form-group {
    max-width: 180px;
  }
  > .form__form-group_multi {
    max-width: 750px;
  }
  span.checkbox-btn__label {
    line-height: 1;
  }
`;
export const FiltersSelectBox = styled.div `
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  > .form__form-group {
    max-width: 225px;
  }
  > .form__form-group_multi {
    max-width: 750px;
  }
  span.checkbox-btn__label {
    line-height: 1;
  }
`;
export const FiltersReset = styled.div `
  display: inline-block;
  flex: none;
  width: 200px;
  > button  {
    margin-bottom: 0;
    margin-left: 20px;
  }
`;
export const MoreFilters = styled.span `
  flex: none;
  width: 145px;
  > button {
    margin-bottom: 0;
  }
`;
export const HideZeroBalances = styled.span `
  margin-left: 20px;
`;
export const WalletsClientContainer = styled.div `
  display: flex;
  position: relative;
  margin-bottom: 10px;
  min-height: 30px;
  > h4 {
    flex: none;
  }
  > span {
    position: relative;
    top: 3px;
  }
  > div {
    position: absolute;
    top: -10px;
    right: 0px;
  }
`;
export const CreateWithdrawal = styled.span `
  flex: none;
  float: right;
  > button {
    margin-bottom: 0;
  }
`;
export const FilterTitle = styled.div `
  font-weight: bold;
  margin: 15px 0 10px;
  text-transform: uppercase;
`;
// Wallets
export const MaterialPopoverWrap = styled.div `
  .MuiList-root {
    .MuiListItemText-root {
      > span.MuiTypography-root {
        font-size: 12px;
      }
    }
    .MuiListItem-root {
      > button {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  } 
`;
export const MaterialTableWrap = styled.div `
  .MuiTableCell-root {
    font-size: 12px;
  }
  .MuiTableSortLabel-root {
    &.MuiTableSortLabel-active {
      &.MuiTableSortLabel-root {
        &.MuiTableSortLabel-active {
          > svg.MuiTableSortLabel-icon {
            color: #32C6A1;
          }  
        }
      }
    }
  }
  .MuiTableCell-sizeSmall {
    padding: 6px 7px;
  }
`;
export const WalletCell = styled.span `
  padding-right: 5px;
  padding-left: 5px;
  max-width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const TransfersCellItem = styled.span `
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const LabelCell = styled.span `
  padding-right: 10px;
  max-width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: -0.425em;
`;
export const CurrencyCell = styled.span `
  max-width: 35px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
`;
// Deposits
export const DepositIdCell = styled.span `
  max-width: 80px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
`;
export const DepositAddressCell = styled.span `
  max-width: 90px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const CustodyFilterPanelContainer = styled.div `
  margin-bottom: 10px;
`;
// Withdrawals
export const WithdrawalIdCell = styled.span `
  max-width: 80px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const WithdrawalAddressCell = styled.span `
  max-width: 90px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
// Sidebar
export const LinkWrap = styled.span `
  text-transform: uppercase;
`;
export const SidebarSearch = styled.div `
  padding: 10px;
`;
// Reports
export const FieldLabel = styled.span `
  font-weight: bold;
`;
export const FlexieContainer = styled.div `
  display: flex;
  align-items: center;
  &.avg-height {
    min-height: 100px;
  }
  > .flx-item {
    flex: 1;
    padding: 10px;
    > .date-box {
      white-space: nowrap;
      &.__upper {
        text-transform: uppercase;
      }
    }
    &.flx__btn {
      text-align: right;
    }
  }
`;
export const MuiDatepicker = styled.div `
  > .MuiFormControl-root {
    > .MuiInputBase-root {
      > .MuiInputBase-input {
        background: none;
        font-size: 14px;
        padding: 5px 0 10px;
      }
    }
  }
`;
export const ContentBox = styled.div `
  padding: 20px;
  background-color: #636363;
  border-top: 1px solid #424242;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #e7e7e7;
`;
export const EntityBadge = styled.div `
  border-radius: 3px;
  border: 1px solid #6c6a6a;
  display: block;
  white-space: nowrap;
  padding: 3px;
  background: #595959;
`;
export const DatePickerContainer = styled.div `
  flex: 2;
  padding-right: 20px;
`;
export const TimePickerContainer = styled.div `
  flex: 1;
`;
export const ModalLoadingContainer = styled.div `
  text-align: center;
  padding-top: 25px;
`;
export const ModalSpinnerContainer = styled.div `
  padding: 50px;
  text-align: center;
`;
export const TimePickerCleanIcon = styled.span `
  position: absolute;
  right: 10px;
  top: 5px;
`;
export const FooterContainer = styled.div `
  text-align: right;
  padding: 20px 0;
`;
export const HeaderContainer = styled.div `
  display: block;
  text-align: left;
`;
export const HeaderContainerItem = styled.div `
  text-align: left;
`;
export const CancelButtonContainer = styled.div `
  padding-top: 0px;
`;
export const TitleContainer = styled.div `
  margin-bottom: 15px;
`;
// Info mega modal
export const InfoBox = styled.div `
  padding: 10px;
  > .row {
    > .col-md-12 {
      > hr {
        margin: 10px 0 20px;
        border: 1px solid #333;
      }
    }
  }
`;
export const InfoModalTitle = styled.div `
  > h4 {
    margin-bottom: 20px;
  }
`;
export const BalanceContainer = styled.div `
  > .dashboard__card-widget.card-body {
    background-color: #2b2b32;
    padding: 0.8rem;
    border: 1px solid #1f1f1f;
    > .card__title {
      margin-bottom: 10px;
    }
  }
`;
export const BalanceInfo = styled.div `
  display: flex;
  > div:first-child {
    flex: none;
    line-height: 22px;
    > span {
      text-transform: uppercase;
      font-size: 0.8rem;
      font-weight: 300;
    }
  }
  > div:last-child {
    flex: 1;
    text-align: left;
    padding-left: 10px;
  }
`;
export const DetailsInfo = styled(BalanceInfo) `
  > div:first-child {
    min-width: 120px
  }
`;
