import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import { Box, Button } from '@mui/material';
const PlaceOrderButton = ({ handleClick, action, loading, locked, modalOpen, features, tooShallowAmount, }) => {
    const isButtonLocked = (isLocked, isLoading, modal, tooShallow) => {
        if (isLocked) {
            return true;
        }
        if (isLoading) {
            return true;
        }
        if (tooShallow) {
            return true;
        }
        return !!modal;
    };
    if (action === 'Buy') {
        return (React.createElement(Permissions, { features: features, permissions: ['order.create'] },
            React.createElement(Button, { variant: "contained", onClick: handleClick, style: { width: '100%' }, disabled: isButtonLocked(locked, loading, modalOpen, tooShallowAmount) }, !loading ? 'BUY'
                : (React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                    "PLACING ORDER",
                    React.createElement(CircularProgress, { size: "33px" }))))));
    }
    return (React.createElement(Permissions, { features: features, permissions: ['order.create'] },
        React.createElement(Button, { variant: "contained", onClick: handleClick, style: { width: '100%' }, disabled: isButtonLocked(locked, loading, modalOpen, tooShallowAmount) }, !loading ? 'SELL'
            : (React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                "PLACING ORDER",
                React.createElement(CircularProgress, { size: "33px" }))))));
};
export default PlaceOrderButton;
