/* eslint-disable camelcase, class-methods-use-this, @typescript-eslint/no-explicit-any */
import axios from 'axios';
import Messages from '../shared/helpers/errorMessages';
import showNotification from '../shared/helpers/notifications';
import { getEnvSettings } from '../config/environmentSettings';
import { composeErrorMessage } from '../shared/helpers/interceptors';
export default class LoginService {
    login(fields, errorNotice) {
        const { ErrorLogger } = window;
        const apipath = `${String(getEnvSettings().frontendApiUrl)}/login`;
        const form = new FormData();
        form.append('login', fields.login);
        form.append('password', fields.password);
        return axios.post(apipath, form, {
            headers: {
                accept: 'multipart/form-data',
            },
        })
            .then((response) => {
            if (response.headers['x-login-session']) {
                window.sessionStorage.setItem('xLoginSession', response.headers['x-login-session']);
            }
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.LOGIN_USER);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            ErrorLogger.setErrors(e, new Date());
            throw e;
        });
    }
    sendTotp(fields) {
        const { ErrorLogger } = window;
        const uri = `${String(getEnvSettings().frontendApiUrl)}/check2fa`;
        const form = new FormData();
        form.append('totp_code', fields.totp_code);
        return axios.post(uri, form, {})
            .then((response) => {
            if (response.headers['x-ws-token']) {
                window.localStorage.setItem('wsToken', response.headers['x-ws-token']);
            }
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
            throw e;
        });
    }
}
