import React, { useMemo } from 'react';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import AutohedgerFields from './AutohedgerFields';
import AutohedgerCheckbox from './AutohedgerCheckbox';
const memoItem = (item) => item;
const realFields = ['min_amount', 'daily_limit', 'threshold'];
const Main = ({ value, columnKey, item, updateAutohedger, setAutohedgers, autohedgers, }) => {
    const mItem = useMemo(() => memoItem(item), [item]);
    if (realFields.includes(`${columnKey}`)) {
        return (React.createElement(AutohedgerFields, { item: mItem, columnKey: columnKey, data: value, handleFieldChange: updateAutohedger }));
    }
    if (columnKey === 'auto_hedging') {
        return (React.createElement(AutohedgerCheckbox, { item: mItem, columnKey: columnKey, value: value, autohedgers: autohedgers, setAutohedgers: setAutohedgers, updateAutohedger: updateAutohedger }));
    }
    if (columnKey === 'created' || columnKey === 'updated') {
        return (React.createElement(TableCell, { align: "right" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a'));
    }
    return (React.createElement(TableCell, { align: "right" }, value.toUpperCase() || 'N/A'));
};
export default Main;
