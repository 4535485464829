import styled from 'styled-components';
export const OrderInfoContent = styled.div `
  padding: 20px;
  display: flex;
`;
export const OrderInfo = styled.div `
  flex: 1;
  padding-right: 20px;
`;
export const OrderBalances = styled.div `
  flex: 1;
`;
export const InfoItem = styled.div `
  display: flex;
  padding-bottom: 5px;
`;
export const InfoItemLabel = styled.div `
  flex: none;
  width: 120px;
  font-weight: bold;
`;
export const InfoItemValue = styled.div `
  flex: 1;
  font-weight: 300;
`;
export const BalanceLabel = styled.div `
  flex: none;
  width: 120px;
  font-weight: bold;
`;
export const BalanceValue = styled.div `
  flex: 1;
  font-weight: 300;
`;
export const Delimiter = styled.hr `
  border-color: #363c4e;
`;
export const TruncateLeft = styled.span `
  display: inline-block;
  white-space: nowrap;                   
  overflow: hidden;
  text-overflow: ellipsis; 
  width: ${props => (props.width ? props.width : '100')}px;
  max-width: 250px;
  direction: rtl;
  text-align: left;
`;
export const Clickable = styled.span `
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
export const SelectedFilters = styled.div `
  padding-bottom: 15px;
`;
export const FilterKey = styled.span `
  text-transform: uppercase;
  font-size: 10px;
`;
export const FilterGroup = styled.div `
  display: inline;
  margin-right: 10px;
  .badge {
    margin-right: 5px;
  };
`;
