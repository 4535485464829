/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { Box, Button } from '@mui/material';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import SchedulerdCell from './SchedulerdCell';
import SchedulersCellActions from './SchedulersCellActions';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import SchedulersService from '../../../../../services/schedulersService';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { BodyContainer, PanelContainer } from '../../../Admin/styling/style';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { materialUiPaginator } from '../../../../../shared/helpers/recordsDependsOnPage';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { MaterialPopoverWrap, MaterialTableWrap, WalletsClientContainer } from '../../../Custody/styling/style';
const RunScheduler = ({ item, toggle, cellAction }) => {
    const handleRunScheduler = () => {
        cellAction(item);
        toggle();
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 }, "Confirm run scheduler"),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "primary", variant: "outlined", onClick: handleRunScheduler }, "Run"),
            React.createElement(Button, { color: "inherit", variant: "outlined", onClick: () => toggle() }, "Close"))));
};
const SchedulersContainer = ({ columns, schedulersWidgetHeight }) => {
    const [status, setStatus] = useState('init');
    const [maxHeight, setMaxHeight] = useState(480);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [schedulers, setSchedulers] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [schedulersToRender, setSchedulersToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageSchedulers'));
    };
    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const fetchSchedulers = (cancelToken) => {
        setStatus('fetching');
        const schedulersService = new SchedulersService({
            url: '/report/schedulers',
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        schedulersService.makeRequest()
            .then((responseData) => {
            const { records } = responseData;
            setSchedulers([...records]);
            setTotalItems(records.length);
            setStatus('fetched');
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SCHEDULERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const cellActions = {
        actions: {
            runScheduler: (item) => {
                const schedulersService = new SchedulersService({
                    url: `/report/schedulers?name=${item.name}`,
                    method: 'post',
                    baseURL: getEnvSettings().adminApiUrl,
                });
                schedulersService.makeRequest()
                    .then(() => {
                    showNotification({
                        message: 'Scheduler successfully ran',
                        color: 'success',
                        dispatch: errorNotice,
                    });
                    fetchSchedulers();
                })
                    .catch((e) => {
                    showNotification({
                        message: `Error: could not run scheduler: ${e.message} `,
                        color: 'error',
                        dispatch: errorNotice,
                    });
                });
            },
        },
        actionsComponents: {
            runScheduler: RunScheduler,
        },
        actionsList: [
            { name: 'Run', action: 'runScheduler' },
        ],
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        fetchSchedulers(cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, []);
    useEffect(() => {
        const items = materialUiPaginator(schedulers, currentPage, rowsPerPage);
        setSchedulersToRender(items);
    }, [schedulers, currentPage, rowsPerPage]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(schedulersWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [schedulersWidgetHeight]);
    if (status === 'init' || status === 'fetching') {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement("div", null,
                React.createElement(BodyContainer, null,
                    React.createElement(WalletsClientContainer, null,
                        React.createElement("div", null,
                            React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                                React.createElement(ViewColumnIcon, null)),
                            React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                }, transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                } },
                                React.createElement(MaterialPopoverWrap, null,
                                    React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                        React.createElement(ListItemText, { primary: col.title }),
                                        React.createElement(ListItemSecondaryAction, null,
                                            React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
                    React.createElement(MaterialTableWrap, null,
                        React.createElement(Paper, { className: "mui_table_root" },
                            React.createElement(TableContainer, { style: { maxHeight } },
                                React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                    React.createElement(TableHead, null,
                                        React.createElement(TableRow, null,
                                            columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'name' ? 'left' : 'right' }, column.title))),
                                            React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                                    React.createElement(TableBody, null, schedulersToRender.map(scheduler => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
                                        columnsToRender.map((column, i) => {
                                            const key = column.key;
                                            const value = key ? scheduler[key] : undefined;
                                            return React.createElement(SchedulerdCell, { key: i, value: value, keyOf: key });
                                        }),
                                        React.createElement(SchedulersCellActions, { actions: cellActions, item: scheduler }))))))),
                            React.createElement(TablePagination, { component: "div", count: totalItems, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: handlePageChange, rowsPerPageOptions: [maxHeight] }))))))));
};
const mapStateToProps = (state, ownProps) => ({
    columns: getWidgetColumns(state, ownProps.widgetKey),
    schedulersWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(SchedulersContainer);
