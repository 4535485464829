import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import DLTTable from '../../../../../shared/components/table/DLTTableComponent';
import CustodyCurrencyDetails from './CustodyCurrencyDetails';
import CustodyCurrencyCreate from './CustodyCurrencyCreate';
import { useCurrencies } from './CurrenciesProvider';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
const statusIcon = (value) => {
    return value ? React.createElement(CheckIcon, { color: 'success' }) : React.createElement(ClearIcon, { color: 'error' });
};
const columns = [
    { id: 'code', label: 'Code', sortable: true },
    { id: 'ticker', label: 'Ticker', sortable: true },
    { id: 'parent', label: 'Parent', sortable: true },
    { id: 'label', label: 'Label', sortable: true },
    { id: 'contract_address', label: 'Contract Address', sortable: true, width: '100px' },
    { id: 'precision', label: 'Precision', sortable: true },
    { id: 'fee_value', label: 'Fee', sortable: true },
    { id: 'network_name', label: 'Network Name', sortable: true },
    { id: 'network_type', label: 'Network Type', sortable: true },
    { id: 'network_boostable', label: 'Boostable', sortable: true },
];
function mapCurrencies(currencies, searchString) {
    return currencies
        .filter((p) => {
        var _a;
        let result = false;
        for (const prop in p) {
            const search = (_a = p[prop]) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().includes(searchString.toLowerCase());
            if (search) {
                result = true;
            }
        }
        return result;
    })
        .map((c) => {
        var _a;
        return (Object.assign(Object.assign({}, c), { parent: (_a = c.parent_currency) === null || _a === void 0 ? void 0 : _a.code.toUpperCase(), code: c.code.toUpperCase(), ticker: c.ticker.toUpperCase(), id: c.code, network_name: c.network.name, network_type: c.network.type.toUpperCase(), network_boostable: statusIcon(c.network.is_boostable) }));
    });
}
const CustodyCurrenciesContainer = (props) => {
    const { searchString, addNew, onAddClose } = props;
    const { getCurrencies, updateCurrency, addCurrency, getPriceFeeds, getNetworks, currencies, priceFeeds, networks, } = useCurrencies();
    const [editDialog, setEditDialog] = useState(false);
    const [addDialog, setAddDialog] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState();
    const [mappedCurrencies, setMappedCurrencies] = useState([]);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [page, setPage] = useState(0);
    const handleSave = (data) => {
        if (selectedCurrency) {
            setUpdateLoading(true);
            updateCurrency(selectedCurrency.code, data).finally(() => {
                setUpdateLoading(false);
                setEditDialog(false);
                setSelectedCurrency(undefined);
            });
        }
    };
    const handleAddNew = (e) => {
        setAddLoading(true);
        addCurrency(e)
            .then(() => {
            setAddDialog(false);
            onAddClose();
        })
            .finally(() => {
            setAddLoading(false);
        });
    };
    useEffect(() => {
        getCurrencies();
        getPriceFeeds();
        getNetworks();
    }, []);
    useEffect(() => {
        if (!editDialog) {
            setSelectedCurrency(undefined);
        }
    }, [editDialog]);
    useEffect(() => {
        if (addNew) {
            setAddDialog(true);
        }
    }, [addNew]);
    useEffect(() => {
        setMappedCurrencies(mapCurrencies(currencies, ''));
    }, [currencies]);
    useEffect(() => {
        setMappedCurrencies(mapCurrencies(currencies, searchString));
        setPage(0);
    }, [searchString]);
    const handleRowClick = (id) => {
        const currency = currencies.find((c) => c.code === id);
        setSelectedCurrency(currency);
        setEditDialog(true);
    };
    const handleEditDialogClose = () => {
        setEditDialog(false);
    };
    const handleAddDialogClose = () => {
        setAddDialog(false);
        onAddClose();
    };
    return (React.createElement(Box, null,
        React.createElement(DLTTable, { columns: columns, defaultOrder: 'asc', defaultOrderProp: 'code', total: mappedCurrencies.length, data: mappedCurrencies, pagination: true, page: page, onRowClick: handleRowClick }),
        React.createElement(Dialog, { open: addDialog, maxWidth: 'md', fullWidth: true },
            React.createElement(DialogTitle, null, "Add Currency"),
            React.createElement(DialogContent, null,
                React.createElement(CustodyCurrencyCreate, { currencies: currencies, networks: networks, loading: addLoading, priceFeeds: priceFeeds, onSave: handleAddNew, onClose: handleAddDialogClose }))),
        React.createElement(Dialog, { open: editDialog, maxWidth: 'md', fullWidth: true, onClose: () => {
                handleEditDialogClose();
            } },
            React.createElement(DialogTitle, null, "Edit Currency"),
            React.createElement(DialogContent, null,
                React.createElement(CustodyCurrencyDetails, { loading: updateLoading, onCancel: () => handleEditDialogClose(), onSave: (e) => handleSave(e), currencyObj: selectedCurrency })))));
};
export default CustodyCurrenciesContainer;
