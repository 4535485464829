var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { SpinnerContainer } from '../../../styling/style';
import AddRoleDialog from './AddRoleDialog';
import PermissionsContent from './PermissionsContent';
import UpdatePermissionDialog from './UpdatePermissionDialog';
import RolesService from '../../../../../../services/rolesService';
import Messages from '../../../../../../shared/helpers/errorMessages';
import ResourceService from '../../../../../../services/resourceService';
import showNotification from '../../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import PermissionsService from '../../../../../../services/permissionsService';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getPermissionsDescription1 } from '../../../../../../config/permissionsDescription1';
import { CLIENT_INFO_FETCH_REQUEST } from '../../../../../../redux/actionTypes/clientActionTypes';
import { PERMISSIONS_ACTIVETAB_UPDATE } from '../../../../../../redux/actionTypes/adminActionTypes';
import { Box, Button, CircularProgress, MenuItem, TextField } from '@mui/material';
import { permissionsCategories } from './permissionsCategories';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const loadClientInfo = () => ({
    type: CLIENT_INFO_FETCH_REQUEST,
});
const WidgetPermissions = ({ columns, activeTab, clientInfo, selectedClient, permissionsWidgetHeight }) => {
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState();
    const [maxHeight, setMaxHeight] = useState(480);
    const tooltipText = getPermissionsDescription1();
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [loadingRest, setLoadingRest] = useState(true);
    const [roles, setRoles] = useState([]);
    const [modalOpen, toggleOpenModal] = React.useState(false);
    const [dialogOpen, toggleOpenDialog] = React.useState(false);
    const [loadingPermissions, setLoadingPermissions] = useState(true);
    const [resources, setResources] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [permissionsArr, setPermissionsArr] = useState([]);
    const [selectedRole, setSelectedRole] = useState();
    const [selectedRoleName, setSelectedRoleName] = useState('');
    const dispatch = useDispatch();
    const notice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const updateRole = useCallback((data) => {
        setRole(data);
    }, [role]);
    const requestUpdatePermission = (payload) => __awaiter(void 0, void 0, void 0, function* () {
        const config = {
            url: `/clients/${code}/permissions/${role === null || role === void 0 ? void 0 : role.role_code}`,
            method: 'post',
            data: payload,
            timeout: 1000,
            baseURL: getEnvSettings().adminApiUrl,
        };
        return axios(config)
            .then()
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
            setPermissionsArr([]);
            const message = composeErrorMessage(e, Messages.PERMISSIONS_UPDATE);
            showNotification({
                message,
                color: 'error',
                dispatch: notice,
            });
            toggleSavePermissions();
        });
    });
    const updateAllPermissions = (arr) => __awaiter(void 0, void 0, void 0, function* () {
        if (arr.length) {
            setLoading(true);
            let n = 0;
            while (n < arr.length) {
                yield requestUpdatePermission(arr[n]);
                n += 1;
            }
            showNotification({
                message: 'Permissions updated successfully',
                color: 'success',
                dispatch: notice,
            });
        }
        setLoading(false);
        toggleSavePermissions();
        setPermissionsArr([]);
    });
    const fetchAll = (clientCode, cancelToken) => {
        setLoadingRest(true);
        const resourcesService = new ResourceService({
            url: 'user/resources',
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        const rolesService = new RolesService({
            url: clientCode ? `/roles?client_code=${clientCode}` : '/roles',
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        Promise.all([resourcesService.makeRequest(), rolesService.makeRequest()])
            .then(([dataResources, dataRoles]) => {
            setRoles(dataRoles);
            if (dataRoles === null || dataRoles === void 0 ? void 0 : dataRoles.length) {
                setSelectedRoleName(dataRoles[0].name);
                setSelectedRole(dataRoles[0]);
            }
            const mappedResources = [];
            dataResources.forEach((r) => {
                const item = Object.assign({}, {
                    resource: r.resource,
                    create: r.actions.find((a) => a === 'create') ? true : false,
                    read: r.actions.find((a) => a === 'read') ? true : false,
                    update: r.actions.find((a) => a === 'update') ? true : false,
                    delete: r.actions.find((a) => a === 'delete') ? true : false,
                    actions: r.actions,
                    category: permissionsCategories[r.resource],
                    description: tooltipText[r.resource] ? `${tooltipText[r.resource]}` : '',
                });
                mappedResources.push(item);
            });
            setResources(mappedResources);
            setLoadingRest(false);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = `Failed fetch roles and permissions ${e}`;
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: notice,
            });
            setLoadingRest(false);
        });
    };
    const fetchPermissions = (selectedClientCode, cancelToken) => {
        setLoadingPermissions(true);
        let isCancelled = false;
        const permissionsService = new PermissionsService({
            url: `/clients/${selectedClientCode}/permissions`,
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        permissionsService
            .makeRequest()
            .then((responseData) => {
            setPermissions(responseData.permissions || []);
            setLoadingPermissions(false);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            if (!isCancelled) {
                const message = composeErrorMessage(e, Messages.PERMISSIONS_FETCH);
                showNotification({
                    message: `Error: ${message}`,
                    color: 'error',
                    dispatch: notice,
                });
                setLoadingPermissions(false);
            }
        });
        return () => {
            isCancelled = true;
        };
    };
    const changeTab = (event) => {
        const roleName = event.target.value;
        setSelectedRoleName(roleName);
        setSelectedRole(roles.find((r) => r.name === roleName));
    };
    const toggleSavePermissions = () => {
        toggleOpenModal((prev) => !prev);
    };
    const toggleDialog = () => {
        toggleOpenDialog((prev) => !prev);
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        let currentCode = code;
        if (selectedClient) {
            currentCode = selectedClient.value;
        }
        else if (clientInfo) {
            currentCode = clientInfo.code;
        }
        setCode(currentCode);
        if (currentCode) {
            fetchPermissions(currentCode, cancelTokenSource.token);
            fetchAll(currentCode, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [clientInfo, selectedClient]);
    useEffect(() => {
        if (roles.length && !activeTab) {
            dispatch({ type: PERMISSIONS_ACTIVETAB_UPDATE, permissionsActiveTab: 0 });
        }
    }, [roles]);
    useEffect(() => {
        resizeWidgetHeight(permissionsWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [permissionsWidgetHeight]);
    if (loadingPermissions || loadingRest) {
        return (React.createElement(SpinnerContainer, null,
            React.createElement(CircularProgress, null)));
    }
    return (React.createElement(Box, { p: 2, sx: { display: 'flex', flexDirection: 'column', gap: 1 } },
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
            React.createElement(Box, null,
                React.createElement(TextField, { sx: { width: '25ch' }, value: selectedRoleName, select: true, label: 'Role Name', size: 'small', fullWidth: true, onChange: (e) => changeTab(e) }, roles.map((r) => (React.createElement(MenuItem, { key: r.role_code, value: r.name }, r.name))))),
            React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
                React.createElement(Button, { disabled: permissionsArr.length === 0, variant: 'contained', size: 'small', color: 'primary', className: 'btn-cancel-order', onClick: () => toggleSavePermissions() }, "Save changes"),
                React.createElement(Button, { variant: 'outlined', size: 'small', type: 'button', color: 'primary', onClick: toggleDialog }, "Add new role"))),
        dialogOpen && React.createElement(AddRoleDialog, { code: code, open: dialogOpen, toggle: toggleDialog, fetchAll: fetchAll }),
        modalOpen && (React.createElement(UpdatePermissionDialog, { open: modalOpen, loading: loading, toggle: toggleSavePermissions, permissionsArr: permissionsArr, updateAllPermissions: updateAllPermissions })),
        React.createElement(Box, null, selectedRole && (React.createElement(PermissionsContent, { role: selectedRole, columns: columns, maxHeight: maxHeight, resources: resources, updateRole: updateRole, permissions: permissions, rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage, permissionsArr: permissionsArr, setPermissionsArr: setPermissionsArr })))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    activeTab: state.admin.permissionsActiveTab,
    columns: getWidgetColumns(state, ownProps.widgetKey),
    permissionsWidgetHeight: state.widgets[ownProps.widgetKey],
});
const mapDispatchToProps = (dispatch) => ({
    dispatchLoadClientInfo: () => dispatch(loadClientInfo()),
});
export default connect(mapStateToProps, mapDispatchToProps)(WidgetPermissions);
