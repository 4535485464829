import React from 'react';
import Chart from 'react-apexcharts';
const zoomableOptions = {
    chart: {
        type: 'bar',
        width: '100%',
        height: 200,
        stacked: false,
        animations: {
            enabled: false,
        },
    },
    fill: {
        opacity: 1,
    },
    legend: {
        labels: {
            colors: '#ccc',
        },
    },
    plotOptions: {
        bar: {
            horizontal: false,
            rangeBarGroupRows: true,
        },
    },
    dataLabels: {
        enabled: false,
    },
    xaxis: {
        type: 'datetime',
        labels: {
            style: {
                colors: '#ccc',
            },
        },
        axisTicks: {
            show: false,
        },
    },
    yaxis: {
        labels: {
            style: {
                colors: '#ccc',
            },
            formatter: (val) => `$ ${val}`,
        },
    },
    stroke: {
        width: 3,
    },
};
const WidgetCharts = ({ series }) => (React.createElement(Chart, { type: "bar", series: series, options: zoomableOptions }));
export default WidgetCharts;
