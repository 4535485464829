import React, { useState, useEffect, useCallback } from 'react';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { Box, CircularProgress, Container } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { PanelContainer, MaterialPopoverWrap, WalletsClientContainer } from '../../styling/style';
import { getWidgetColumns, } from '../../../../../redux/selectors/custodySelectors';
import { SpinnerContainer } from '../../../Admin/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import CustodyClientWithdrawalsService from '../../../../../services/custodyWithdrawalsService';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import InternalTransfersRow from './InternalTransferRow';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const WidgetInternalTransfers = ({ selectedClient, columns, masterTenant, withdrawalsWidgetHeight, }) => {
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [maxHeight, setMaxHeight] = useState(480);
    const [clientCode, setClientCode] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [urlWithdrawals, setUrlWithdrawals] = useState('');
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortBy, setSortBy] = useState('created');
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [transfers, setTransfers] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const forbidForSorting = [
        'usd_fee',
        'wallet_label',
        'customer_code',
        'tx_hash',
        'destination_type',
        'address',
        'address_label',
    ];
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find((c) => c.key === colKey);
        const colIndex = columnsToRender.findIndex((c) => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRender([...columnsToRender]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const fetchTransfers = (cancelToken) => {
        const url = `/internal_transfers?page=
${currentPage}&limit=${rowsPerPage}&clientCode=${clientCode}`;
        const service = new CustodyClientWithdrawalsService({ url, method: 'get', cancelToken });
        setLoading(true);
        service
            .makeRequest()
            .then((data) => {
            setLoading(false);
            setTransfers(data.records);
            setTotalPages(+data.total);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTODY_WITHDRAWALS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const onChangePage = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const onSortHandle = (key) => {
        setCurrentPage(0);
        setSortBy(key);
        setSortDirection((prev) => (prev === 'desc' ? 'asc' : 'desc'));
    };
    useEffect(() => {
        let code = '';
        if (selectedClient) {
            code = selectedClient.value;
        }
        setClientCode(code);
    }, [selectedClient]);
    useEffect(() => {
        fetchTransfers();
    }, [currentPage]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (urlWithdrawals !== '') {
            fetchTransfers(cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [urlWithdrawals]);
    useEffect(() => {
        if (columns && columns.length) {
            let cols = [];
            cols = columns.filter((c) => c.value);
            if (transfers.length) {
                cols = columns.filter((c) => c.value);
            }
            setColumnsToRender([...cols]);
        }
    }, [columns, masterTenant, transfers]);
    useEffect(() => {
        resizeWidgetHeight(withdrawalsWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [withdrawalsWidgetHeight]);
    return (React.createElement(PanelContainer, { className: 'blocks_wrapp_body_style' }, loading ? (React.createElement(SpinnerContainer, null,
        React.createElement(CircularProgress, null))) : (React.createElement(React.Fragment, null,
        React.createElement(WalletsClientContainer, null,
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement(IconButton, { onClick: handleColumnsClick, size: 'large' },
                        React.createElement(ViewColumnIcon, null))),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: 'nav', "aria-label": 'more actions', className: 'mui_table_settings_wrap' }, columns.map((col) => (React.createElement(ListItem, { key: col.id },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: 'end', size: 'small', color: 'default', onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find((c) => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        transfers.length === 0 ? (React.createElement(Container, null,
            React.createElement(Alert, { severity: 'info', variant: 'outlined' },
                React.createElement(Box, null, "No items found")))) : (React.createElement(React.Fragment, null,
            React.createElement(TableContainer, { style: { maxHeight } },
                React.createElement(Table, { size: 'small', stickyHeader: true, "aria-label": 'sticky table' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null),
                            columnsToRender.map((column) => {
                                if (forbidForSorting.includes(column.key || '')) {
                                    return (React.createElement(TableCell, { align: 'left', key: column.key, className: 'withdrawal_disable_sorting', style: { minWidth: column.minWidth } },
                                        React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc' }, column.title)));
                                }
                                return (React.createElement(TableCell, { key: column.key, align: 'left', style: { minWidth: column.minWidth } },
                                    React.createElement(TableSortLabel, { direction: column.key === sortBy ? sortDirection : 'asc', active: column.key === sortBy, onClick: () => onSortHandle(column.key) }, column.title)));
                            }))),
                    React.createElement(TableBody, null, transfers.map((withdrawal) => (React.createElement(InternalTransfersRow, { key: withdrawal.code, item: withdrawal, columns: columnsToRender })))))),
            React.createElement(TablePagination, { component: 'div', count: totalPages, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [maxHeight] })))))));
};
const mapStateToProps = (state, ownProps) => ({
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    withdrawalsWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(WidgetInternalTransfers);
