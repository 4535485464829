import React, { useEffect, useState, useCallback } from 'react';
import Decimal from 'decimal.js';
import classNames from 'classnames';
import Draggable from 'react-draggable';
import Alert from '@mui/material/Alert';
import { Box, Dialog, DialogContent } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import { cleanSuborders, populateSubordersList, } from '../../../../../redux/actions/subOrdersActions';
import ModalBodyContainer from './Modal/ModalBodyContainer';
import ModalFooterContainer from './Modal/ModalFooterContainer';
import Messages from '../../../../../shared/helpers/errorMessages';
import SplitItemService from '../../../../../services/splitItemService';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { SUBORDERS_CLEAN_ERRORS } from '../../../../../redux/actionTypes/subOrdersActionTypes';
import DialogHeader from '../../../../../shared/components/DialogHeader';
const calculateRemainder = (subOrders, amount) => {
    const subordersFiltered = subOrders.filter((subOrder) => {
        if (subOrder.status === 'Rejected') {
            return false;
        }
        return subOrder.status !== 'Canceled';
    });
    const subordersTotal = subordersFiltered.reduce((acm, order) => {
        const orderAmount = order.status === 'CanceledPartiallyFilled' ? order.executed.amount : order.amount;
        return Decimal.add(acm, orderAmount);
    }, new Decimal(0));
    const decimalAmount = new Decimal(amount);
    if (decimalAmount.minus(subordersTotal).lessThan(0))
        return 0;
    const val = decimalAmount.minus(subordersTotal).toFixed(8).replace(/\.?0+$/, '');
    return parseFloat(val);
};
const updateDisbaledCloseOrder = (suborders, remainder) => {
    if (suborders.length === 0) {
        return false;
    }
    if (remainder > 0) {
        return false;
    }
    const notFilled = suborders.filter(sub => sub.status !== 'Filled');
    return !notFilled.length;
};
const SuborderModal = ({ splitCode, open, toggleFunc, subOrdersFailed, theme, suborderItems, }) => {
    const [remainder, setRemainder] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [disabledClose, setDesabledClose] = useState(false);
    const [splitOrder, setSplitOrder] = useState(null);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleUpdateRemainder = (suborders, amount) => {
        const computedRemainder = calculateRemainder(suborders, amount);
        setRemainder(computedRemainder);
    };
    useEffect(() => {
        setLoading(true);
        const service = new SplitItemService({ url: `/orders/partials/${splitCode}`, method: 'get' });
        service.makeRequest()
            .then((response) => {
            setSplitOrder(response);
            setRemainder(new Decimal(response.amount).toNumber());
            dispatch(populateSubordersList(response.suborders));
            setLoading(false);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.ORDERS_SUBORDERS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoading(false);
            setShowAlert(true);
            setAlertMessage(`Something went wrong, error: ${e}`);
            throw new Error(`Something went wrong, error: ${e}`);
        });
    }, []);
    useEffect(() => {
        if (Array.isArray(subOrdersFailed) && subOrdersFailed.length) {
            const errMessage = subOrdersFailed
                .reduce((str, err) => `${str}${err.message} `, '');
            setShowAlert(true);
            setAlertMessage(`Error: ${errMessage}`);
        }
    }, [subOrdersFailed]);
    useEffect(() => {
        if (suborderItems && suborderItems.length) {
            const isDisabled = updateDisbaledCloseOrder(suborderItems, remainder);
            setDesabledClose(isDisabled);
        }
    }, [suborderItems]);
    const modalClass = classNames({
        'modal-dialog--colored': false,
        'modal-dialog--header': true,
    });
    const closeAlert = () => {
        setShowAlert(false);
        setAlertMessage('');
    };
    const onExit = () => {
        dispatch(cleanSuborders());
        dispatch({ type: 'STOP_SUBORDERS_STATUS_POLLING' });
        dispatch({ type: SUBORDERS_CLEAN_ERRORS });
        closeAlert();
    };
    return React.createElement(React.Fragment, null,
        React.createElement(Draggable, { handle: ".modal__title", bounds: {
                top: -30,
            } },
            React.createElement(Dialog, { open: open, maxWidth: "md", fullWidth: true, onAbort: onExit, onClose: toggleFunc },
                React.createElement(DialogHeader, { title: 'Edit Split Order', toggleFunc: toggleFunc }),
                React.createElement(DialogContent, null,
                    showAlert ? (React.createElement(Alert, { severity: "error", variant: "outlined" }, alertMessage)) : null,
                    React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 }, loading
                        ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
                            React.createElement(CircularProgress, { size: "33px" })))
                        : (React.createElement(React.Fragment, null,
                            React.createElement(ModalBodyContainer, { remainder: remainder, splitOrderItem: splitOrder, setShowAlert: setShowAlert, setAlertMessage: setAlertMessage, handleUpdateRemainder: handleUpdateRemainder }),
                            React.createElement(ModalFooterContainer, { remainder: remainder, toggleFunc: toggleFunc, splitOrderItem: splitOrder, disabledClose: disabledClose }))))))));
};
const mapStateToProps = (state) => {
    const { subOrdersFailed, suborderItems } = state.subOrders;
    const { theme } = state;
    return {
        theme,
        suborderItems,
        subOrdersFailed,
    };
};
export default connect(mapStateToProps)(SuborderModal);
