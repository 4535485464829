import React, { useState, useEffect } from 'react';
import Decimal from 'decimal.js';
import { connect } from 'react-redux';
import * as Styled from './styling/styled';
import LadderHead from './components/LadderHead/LadderHead';
import LadderFooter from './components/LadderBody/LadderFooter';
import BodyContainer from './components/LadderBody/BodyContainer';
import { getWidgetsSettingsByKey } from '../../../../../redux/selectors/widgetSettingsSelector';
/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/restrict-template-expressions */
const Main = ({ settings, widgetKey, selectedPair, selectedExchange, }) => {
    const { OrderBookWS } = window;
    const availableChannels = OrderBookWS.getAvailableChannels();
    const [steps, setSpeps] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const handleCurrentStep = (step) => {
        setCurrentStep(step);
    };
    useEffect(() => {
        if (availableChannels.length && (selectedExchange === null || selectedExchange === void 0 ? void 0 : selectedExchange.value)) {
            const pair = `${selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base}/${selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote}`.toLowerCase();
            const filtered = availableChannels
                .filter(c => c.includes(pair))
                .filter(c => c.includes(`-${selectedExchange.value}`))
                .filter(c => c.includes('aggregated'))
                .map((c) => {
                if (c.split('-').length > 5) {
                    return new Decimal(c.split('-')[4]).toNumber();
                }
                return 0;
            })
                .sort((a, b) => a - b);
            setSpeps(filtered);
            if (filtered.length) {
                setCurrentStep(filtered[0]);
            }
        }
    }, [availableChannels, selectedPair, selectedExchange]);
    return (React.createElement(Styled.MainContainer, null,
        React.createElement(LadderHead, { step: currentStep, settings: settings, widgetKey: widgetKey }),
        React.createElement(BodyContainer, { step: currentStep, settings: settings }),
        React.createElement(LadderFooter, { steps: steps, currentStep: currentStep, changeStep: handleCurrentStep })));
};
const stateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    const { selectedPair, selectedExchange } = state.placeOrder;
    return {
        widgetKey,
        selectedPair,
        selectedExchange,
        /* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        settings: getWidgetsSettingsByKey(state, widgetKey),
    };
};
export default connect(stateToProps)(Main);
