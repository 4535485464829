import axios from 'axios';
import { ApiPollService } from '../shared/helpers/apiPollService';
export default class OrdersService {
    constructor(config, apiEndpoint, delay) {
        this.model = {
            records: [],
            total: 0,
        };
        this.config = config;
        this.apiEndpoint = apiEndpoint;
        this.pollingService = new ApiPollService(delay || 10000);
    }
    getData() {
        return this.model;
    }
    handleResponse(response) {
        if (response.data) {
            this.model = response.data;
            return this.model;
        }
        return this.model;
    }
    makeRequest() {
        const { ErrorLogger } = window;
        const axiosRequest = axios(this.config);
        return axiosRequest
            .then(response => this.handleResponse(response))
            .catch((error) => {
            if (error.message === 'canceled') {
                return this.handleReject(error);
            }
            ErrorLogger.setErrors(error, new Date());
            return this.handleReject(error);
        });
    }
    makePollRequest(callback, delay) {
        if (delay) {
            this.pollingService.delay = delay;
        }
        if (this.config.url) {
            const callbackPayload = {
                url: this.config.url,
                action: callback,
            };
            this.pollingService.addCallback(callbackPayload);
        }
    }
    clearPollRequest(url) {
        this.pollingService.removeCallBack(url);
    }
    // eslint-disable-next-line class-methods-use-this
    handleReject(error) {
        throw error;
    }
}
