import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
const UpdatePermissionDialog = ({ toggle, open, permissionsArr, updateAllPermissions, loading, }) => {
    return (React.createElement(Dialog, { open: open, onClose: () => toggle(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Update permissions"),
        React.createElement(DialogContent, null,
            React.createElement("div", { className: "modal__footer" },
                React.createElement("div", { className: "loading_block_flex" },
                    React.createElement("div", { className: "cancel_all_orders_body" }, "Are you sure you want to update all checked permissions?")))),
        React.createElement(DialogActions, null,
            React.createElement(LoadingButton, { variant: "contained", color: "success", loading: loading, onClick: () => updateAllPermissions(permissionsArr) }, "Update"),
            React.createElement(Button, { variant: "outlined", color: "inherit", onClick: () => { toggle(); }, className: "custody_actions_button_margin" }, "Close"))));
};
export default UpdatePermissionDialog;
