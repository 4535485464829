import React from 'react';
import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => createStyles({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    }
}));
const DialogHeader = ({ closable = true, title, toggleFunc, }) => {
    const classes = useStyles();
    return (React.createElement(DialogTitle, null,
        title,
        !closable ? null :
            React.createElement(IconButton, { size: "small", "aria-label": "close", className: classes.closeButton, onClick: toggleFunc },
                React.createElement(CloseIcon, { fontSize: "small" }))));
};
export default DialogHeader;
