import { createSelector } from 'reselect';
const clientBalances = (state) => state.client.balances;
const clientInfoFunc = (state) => state.client.clientInfo;
const clientFunc = (state) => state.client;
const customerBalances = (state) => state.client.customerBalances;
const exchangesBalancesFunc = (state) => state.client.exchangesBalances;
export const getClientBalances = createSelector(clientBalances, balances => balances);
export const getClientInfo = createSelector(clientInfoFunc, clientInfo => clientInfo);
export const getClient = createSelector(clientFunc, client => client);
// TODO remove hard coded cryptoArr
export const getCustomerBalances = createSelector(customerBalances, (balances) => {
    const cryptoArr = ['USD', 'EUR', 'BTC', 'XRP', 'LTC', 'ETH', 'ETC', 'QTUM'];
    return balances.filter(balance => cryptoArr.includes(balance.code));
});
export const getExchangesBalances = createSelector(exchangesBalancesFunc, exchangesBalances => exchangesBalances.filter(balance => balance.balance.available !== '0'));
