import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { connect, useDispatch } from 'react-redux';
import ClientDetailsCompany from './ClientDetailsCompany';
import ClientDetailsContact from './ClientDetailsContact';
import ClientService from '../../../../../../services/clientService';
import Messages from '../../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import { Box, CircularProgress, Paper, TableContainer, Typography, Alert } from '@mui/material';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const ClientInfo = ({ clientInfo, selectedClient, masterTenant }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const [code, setCode] = useState('');
    const [client, setClient] = useState(null);
    const [clientDetails, setClientDetails] = useState(null);
    const [loading, setLoading] = useState();
    const handleSelect = (clientOption) => {
        setClient(clientOption);
    };
    const regularClient = masterTenant
        ? { label: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name, value: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code } : null;
    useEffect(() => {
        if (!masterTenant) {
            setClientDetails(clientInfo);
        }
        else if (code) {
            setLoading(true);
            const serviceInstance = new ClientService({
                url: `/user/clients/${code}`,
                method: 'get',
                baseURL: getEnvSettings().adminApiUrl,
            });
            serviceInstance.makeRequest()
                .then((response) => {
                setClientDetails(response);
            })
                .catch((e) => {
                const message = composeErrorMessage(e, Messages.CLIENT_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            }).finally(() => setLoading(false));
        }
    }, [masterTenant, code]);
    useEffect(() => {
        if (clientInfo) {
            setCode(clientInfo.code);
        }
    }, [clientInfo]);
    useEffect(() => {
        if (client) {
            setCode(client.value);
        }
        else if (selectedClient) {
            setCode(selectedClient.value);
        }
        else if (clientInfo) {
            setCode(clientInfo.code);
        }
        else {
            setCode('');
        }
    }, [client, selectedClient, clientInfo]);
    if (!code) {
        return (React.createElement(Alert, { severity: "info", variant: "outlined" }, "No clients found"));
    }
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center', flex: '100%' },
            React.createElement(CircularProgress, null)));
    }
    return (React.createElement("div", { className: "content-body" },
        React.createElement(Box, { p: 0.6 },
            React.createElement(Paper, null,
                React.createElement(Box, { p: 1 },
                    React.createElement(Typography, { variant: 'subtitle1' }, "Client details")),
                React.createElement(Box, { display: 'flex', p: 1 },
                    React.createElement(TableContainer, null,
                        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, null,
                                        React.createElement(Typography, { variant: 'subtitle2', style: { fontWeight: 'bold' } }, "Company")),
                                    React.createElement(TableCell, null))),
                            React.createElement(TableBody, null,
                                React.createElement(ClientDetailsCompany, { clientDetails: clientDetails })))),
                    React.createElement(TableContainer, null,
                        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    React.createElement(TableCell, null,
                                        React.createElement(Typography, { variant: 'subtitle2', style: { fontWeight: 'bold' } }, "Contact")),
                                    React.createElement(TableCell, null))),
                            React.createElement(TableBody, null,
                                React.createElement(ClientDetailsContact, { clientDetails: clientDetails })))))))));
};
const mapStateToProps = (state) => ({
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
});
export default connect(mapStateToProps)(ClientInfo);
