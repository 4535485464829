import { createSelector } from 'reselect';
const getCustomers = (state) => state.customersSearch.customers;
const getCustomersTotal = (state) => state.customersSearch.total;
/* eslint-disable import/prefer-default-export, no-nested-ternary, @typescript-eslint/restrict-template-expressions */
export const getCustomersSearch = createSelector(getCustomers, customers => customers.map((customer) => {
    const labelValue = customer.company_name || customer.code;
    return (Object.assign({}, customer, { label: labelValue }));
}));
export const getCustomersSearchTotal = createSelector(getCustomersTotal, total => total);
export const getCustomersSelectedInputInterface = createSelector(getCustomers, (customers) => {
    if (customers.length) {
        return customers.map(customer => Object.assign({}, {
            value: customer.code,
            label: customer.company_name || `${customer.code.slice(0, 12)}...`,
        }));
    }
    return [];
});
export const getAllCustomers = createSelector(getCustomers, customers => customers);
