import React, { useState, useEffect } from 'react';
import { Route, Switch, useRouteMatch, BrowserRouter as Router, } from 'react-router-dom';
import Deposits from './Deposits';
import Customers from './Customers';
import InfoWallets from './InfoWallets';
import BlockchainForm from './BlockchainForm';
const Main = ({ item, allDeposits, allWithdraws }) => {
    const { path } = useRouteMatch();
    const [fullItem, setFullItem] = useState(null);
    const [withdrawItem, setWithdrawItem] = useState(null);
    useEffect(() => {
        const deposit = allDeposits && allDeposits.find(d => d.code === item.code);
        if (deposit) {
            setFullItem(deposit);
        }
    }, [allDeposits]);
    useEffect(() => {
        const withdraw = allWithdraws && allWithdraws.find(w => w.code === item.code);
        if (withdraw) {
            setWithdrawItem(withdraw);
        }
    }, [allWithdraws]);
    return (React.createElement(Router, null,
        React.createElement("div", null,
            React.createElement(Switch, null,
                React.createElement(Route, { exact: true, path: path },
                    React.createElement(BlockchainForm, { item: fullItem || withdrawItem })),
                React.createElement(Route, { path: "/custody/wallets" },
                    React.createElement(InfoWallets, null)),
                React.createElement(Route, { path: "/custody/customers" },
                    React.createElement(Customers, null)),
                React.createElement(Route, { path: "/custody/deposits" },
                    React.createElement(Deposits, null))))));
};
export default Main;
