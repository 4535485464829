import React from 'react';
import Decimal from 'decimal.js';
const BidPrice = ({ price }) => (React.createElement("div", { className: "container_trade_color_green" }, price === ''
    ? React.createElement("span", null, "--")
    : React.createElement("span", null, new Decimal(price).toFixed(2))));
const Bid = ({ orderBookItem }) => {
    if (orderBookItem && orderBookItem.bid && Object.keys(orderBookItem.bid).length) {
        const { price } = orderBookItem.bid;
        return React.createElement(BidPrice, { price: price });
    }
    return (React.createElement("div", { className: "container_trade_color_green" },
        React.createElement("span", null, "--")));
};
export default React.memo(Bid);
