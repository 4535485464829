import React, { useState, useEffect, useCallback } from 'react';
import { Route, Switch, useParams, useRouteMatch, } from 'react-router-dom';
import buildUrl from 'build-url';
import { Box, CircularProgress } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { InfoBox, DetailsInfo, InfoModalTitle, } from '../../styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import CustomersService from '../../../../../services/customersService';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const Customer = ({ selectedClient, features, clientInfo }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const [loading, setLoading] = useState(false);
    const { customerCode } = useParams();
    const [allcustomers, setAllCustomers] = useState([]);
    const [customer, setCustomer] = useState(null);
    const getCustomer = (code, customers) => {
        if (customers.length) {
            return customers
                .find(c => c.code === code);
        }
        return undefined;
    };
    useEffect(() => {
        if (customerCode) {
            const c = getCustomer(customerCode, allcustomers);
            if (c) {
                setCustomer(c);
            }
        }
    }, [allcustomers]);
    const fetchCustomers = (url) => {
        setLoading(true);
        const service = new CustomersService({
            url,
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        service.makeRequest()
            .then((responseData) => {
            setLoading(false);
            const { records } = responseData;
            setAllCustomers(records);
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                sort_by: 'created',
                sort_direction: 'desc',
            },
            path: '/customers',
        };
        const canReadDetails = features && Array.isArray(features) && features.includes('customer_details.read');
        if (selectedClient) {
            urlOptions.queryParams.client_code = selectedClient.value;
        }
        else if (clientInfo) {
            urlOptions.queryParams.client_code = clientInfo.code;
        }
        if (canReadDetails) {
            urlOptions.queryParams.details = 'true';
            const endpointUrl = buildUrl('', urlOptions);
            fetchCustomers(endpointUrl);
        }
    }, [features]);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 1 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(InfoBox, null,
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", null,
                React.createElement(InfoModalTitle, null,
                    React.createElement("h4", null, "Customer details")))),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", null,
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Customer name:")),
                    React.createElement("div", null, customer === null || customer === void 0 ? void 0 : customer.company_name)),
                (customer === null || customer === void 0 ? void 0 : customer.ibans) ? customer === null || customer === void 0 ? void 0 : customer.ibans.map(ibanItem => (React.createElement(DetailsInfo, { key: ibanItem.iban, className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null,
                            "IBAN(",
                            ibanItem.currency,
                            ")")),
                    React.createElement("div", null,
                        ibanItem.iban,
                        " ",
                        React.createElement("span", null, ibanItem.is_enabled ? '' : ' (disabled)'))))) : null,
                (customer === null || customer === void 0 ? void 0 : customer.bfid)
                    && (React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                        React.createElement("div", null,
                            React.createElement("span", null, "BFID:")),
                        React.createElement("div", null, customer === null || customer === void 0 ? void 0 : customer.bfid))),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "VAT:")),
                    React.createElement("div", null, customer === null || customer === void 0 ? void 0 : customer.vat)),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Address:")),
                    React.createElement("div", null, customer === null || customer === void 0 ? void 0 : customer.address)),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Zip code:")),
                    React.createElement("div", null, customer === null || customer === void 0 ? void 0 : customer.zip_code)),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Country:")),
                    React.createElement("div", null, customer === null || customer === void 0 ? void 0 : customer.country)),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Name contact:")),
                    React.createElement("div", null, customer === null || customer === void 0 ? void 0 : customer.contact_person_name)),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Phone number:")),
                    React.createElement("div", null, customer === null || customer === void 0 ? void 0 : customer.phone_number))))));
};
const Customers = ({ selectedClient, features, clientInfo }) => {
    const { path } = useRouteMatch();
    return (React.createElement("div", null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: path },
                React.createElement("h3", null, "Related customers content")),
            React.createElement(Route, { path: `${path}/:customerCode` },
                React.createElement(Customer, { selectedClient: selectedClient, features: features, clientInfo: clientInfo })))));
};
const stateToPropsCustomers = (state) => ({
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    selectedClient: getSelectedClientInputValue(state),
});
export default connect(stateToPropsCustomers)(Customers);
