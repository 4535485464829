import React, { useState } from 'react';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import TableCell from '@mui/material/TableCell';
import { Chip, Icon, Tooltip } from '@mui/material';
import { TransfersCellItem } from '../../../Custody/styling/style';
import { statusBadgeData } from '../../../../../config/statusBadgeData';
import statusBadgeColor from '../../../../../shared/helpers/statusBadgeColor';
import getUserNameByCode from '../../../../../shared/helpers/getUserNameByCode';
import getClientsNameByCode from '../../../../../shared/helpers/getClientNameByCode';
import getCustomerNameByCode from '../../../../../shared/helpers/getCustomerNameByCode';
/* eslint-disable no-nested-ternary */
const TransfersCell = ({ value, keyOf, customers, users, clients, width, }) => {
    const [icon, setIcon] = useState('file_copy');
    const handleCopy = (id) => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(id);
    };
    if (keyOf === 'currency_code') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(TransfersCellItem, { style: { maxWidth: width } }, (value === null || value === void 0 ? void 0 : value.toLocaleUpperCase()) || 'N/A')));
    }
    if (keyOf === 'code' || keyOf === 'requisites_details') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(TransfersCellItem, { style: { maxWidth: width } },
                value ? (React.createElement(Icon, { fontSize: "small", onClick: () => handleCopy(`${value}`), style: { top: 0, marginRight: '5px' } }, icon)) : null,
                React.createElement(Tooltip, { title: value || '', placement: "bottom-start" },
                    React.createElement("span", null, value || 'N/A')))));
    }
    if (keyOf === 'client_code') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(TransfersCellItem, { style: { maxWidth: width } },
                value ? (React.createElement(Icon, { fontSize: "small", onClick: () => handleCopy(`${value}`), style: { top: 0, marginRight: '5px' } }, icon)) : null,
                React.createElement(Tooltip, { title: value || '', placement: "bottom-start" },
                    React.createElement("span", null, getClientsNameByCode(clients, value) || 'N/A')))));
    }
    if (keyOf === 'customer_code') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(TransfersCellItem, { style: { maxWidth: width } },
                value ? (React.createElement(Icon, { fontSize: "small", onClick: () => handleCopy(`${value}`), style: { top: 0, marginRight: '5px' } }, icon)) : null,
                React.createElement(Tooltip, { title: value || '', placement: "bottom-start" },
                    React.createElement("span", null, getCustomerNameByCode(customers, value) || 'N/A')))));
    }
    if (keyOf === 'creator_code') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(TransfersCellItem, { style: { maxWidth: width } },
                value ? (React.createElement(Icon, { fontSize: "small", onClick: () => handleCopy(`${value}`), style: { top: 0, marginRight: '5px' } }, icon)) : null,
                React.createElement(Tooltip, { title: value || '', placement: "bottom-start" },
                    React.createElement("span", null, getUserNameByCode(users, value) || 'N/A')))));
    }
    if (keyOf === 'state') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(Chip, { size: "small", label: value || 'N/A', style: statusBadgeColor(statusBadgeData.stateBackgroundsTradingTransfers[(value || '').replace(/-/g, '_')], statusBadgeData.stateBordersTradingTransfers[(value || '').replace(/-/g, '_')], statusBadgeData.stateColorsTradingTransfers[(value || '').replace(/-/g, '_')]).badge })));
    }
    if (keyOf === 'created_at') {
        return (React.createElement(TableCell, { align: "left" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a'));
    }
    if (keyOf === 'updated_at') {
        return (React.createElement(TableCell, { align: "left" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a'));
    }
    return (React.createElement(TableCell, { align: "left" },
        React.createElement(TransfersCellItem, { style: { maxWidth: width } }, value || 'N/A')));
};
export default TransfersCell;
