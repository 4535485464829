import React from 'react';
import Decimal from 'decimal.js';
import { Chip } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import TableCell from '@mui/material/TableCell';
import statusBadgeColor from '../../../../../../shared/helpers/statusBadgeColor';
import { statusBadgeData } from '../../../../../../config/statusBadgeData';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
const envSettings = getEnvSettings();
/* eslint-disable no-nested-ternary */
const OrdersTableCell = ({ value, columnKey, quote, base, }) => {
    if (columnKey === 'created') {
        return (React.createElement(TableCell, { align: "right" }, value ? `${new Date(value).toLocaleDateString()}, ${new Date(value).toLocaleTimeString()}` : 'n/a'));
    }
    if (columnKey === 'amount' || columnKey === 'executedPrice' || columnKey === 'executedValue') {
        const currency = columnKey === 'amount' ? base : quote;
        return (React.createElement(TableCell, { align: "right" },
            React.createElement(NumericFormat, { displayType: "text", value: new Decimal(value || 0).valueOf(), thousandSeparator: envSettings.thousandDivider }),
            "\u00A0",
            value ? (currency ? currency.toUpperCase() : null) : null));
    }
    if (columnKey === 'status') {
        return (React.createElement(TableCell, { align: "right" },
            React.createElement(Chip, { size: "small", label: value || 'N/A', style: statusBadgeColor(statusBadgeData.orderStatusBackgroundsRegular[value || ''], statusBadgeData.orderStatusBordersRegular[value || ''], statusBadgeData.orderStatusColorsRegular[value || '']).badge })));
    }
    if (columnKey === 'action_type') {
        return (React.createElement(TableCell, { align: "left" }, value || 'N/A'));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default OrdersTableCell;
