import * as types from '../actionTypes/customerSearchActionsTypes';
import * as clientTypes from '../actionTypes/clientSearchActionTypes';
const initialState = {
    customers: [],
    total: '0',
    workingCustomers: [],
    workingTotal: '0',
    loading: false,
    loaded: false,
    selectedCustomer: null,
    error: '',
    pageOfItemsCustomerSearch: 0,
    searchString: '',
    selectedClient: '',
};
/* eslint-disable @typescript-eslint/no-unsafe-assignment,
   @typescript-eslint/no-unsafe-member-access,
   @typescript-eslint/no-unsafe-return */
const customerSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CUSTOMER_SEARCH_FETCH_REQUEST: {
            return Object.assign(Object.assign({}, state), { loaded: false, loading: true });
        }
        case types.CUSTOMER_SEARCH_FETCH_SUCCESS: {
            return Object.assign(Object.assign({}, state), { customers: action.customers, total: action.total, workingCustomers: action.customers, workingTotal: action.total, loading: false, loaded: true });
        }
        case types.CUSTOMER_SEARCH_FETCH_FAIL: {
            return Object.assign(Object.assign({}, state), { error: action.message, loading: false, loaded: true });
        }
        case types.CUSTOMER_SELECT_SET_UPDATE: {
            return Object.assign(Object.assign({}, state), { selectedCustomer: action.customer });
        }
        case types.CUSTOMER_UPDATE: {
            const updatedCustomers = state.customers.map((c) => {
                if (c.code !== action.customer.code) {
                    return c;
                }
                return Object.assign(Object.assign({}, c), action.customer);
            });
            return Object.assign(Object.assign({}, state), { customers: updatedCustomers });
        }
        case clientTypes.SET_SELECTED_CLIENT: {
            return Object.assign(Object.assign({}, state), { selectedCustomer: null, loading: true, loaded: false });
        }
        case types.WORKING_CUSTOMER_SEARCH_SET_UPDATE: {
            return Object.assign(Object.assign({}, state), { workingCustomers: action.workingCustomers, workingTotal: action.workingTotal });
        }
        case types.CUSTOMER_SEARCH_PAGE_OF_ITEMS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { pageOfItemsCustomerSearch: action.pageOfItemsCustomerSearch });
        case types.CUSTOMER_WIDGET_SEARCH_STRING_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { searchString: action.searchString });
        default:
            return state;
    }
};
export default customerSearchReducer;
