import React, { useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import BalanceListItem from './BalanceListItem';
import getDecimalsByCurrencyCode from '../../config/currencyDecimal';
const BalancesList = (props) => {
    const { balances, currencies, pinned, loading } = props;
    const [searchString, setSerachString] = useState('');
    const [filteredBalances, setFilteredBalances] = useState([]);
    useEffect(() => {
        setFilteredBalances(balances);
    }, [balances]);
    useEffect(() => {
        if (searchString) {
            const newBalances = balances.filter((b) => b.code.includes(searchString.toUpperCase()));
            setFilteredBalances(newBalances);
        }
        else {
            setFilteredBalances(balances);
        }
    }, [searchString]);
    return (React.createElement(Box, { sx: { height: '100%', position: 'relative', display: pinned ? 'flex' : 'block', flex: pinned ? '1' : 'auto' } },
        pinned ? (React.createElement(Box, { mx: 2, sx: { display: 'flex', alignItems: 'center', height: '100%' } },
            React.createElement(TextField, { placeholder: 'Search', value: searchString, onChange: (e) => setSerachString(e.target.value), size: 'small', InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                        React.createElement(SearchIcon, null))),
                } }))) : (React.createElement(Grid, { container: true, pl: 0, mb: 1 },
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(TextField, { placeholder: 'Search', value: searchString, onChange: (e) => setSerachString(e.target.value), size: 'small', InputProps: {
                        endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                            React.createElement(SearchIcon, null))),
                    } })),
            React.createElement(Grid, { item: true, xs: 3, gap: 1, sx: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' } },
                React.createElement(Typography, { textAlign: 'right', variant: 'subtitle1' }, "Available")),
            React.createElement(Grid, { item: true, xs: 3, gap: 1, sx: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' } },
                React.createElement(Typography, { textAlign: 'right', variant: 'subtitle1' }, "Locked")),
            React.createElement(Grid, { item: true, xs: 3, gap: 1, pr: 1, sx: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' } },
                React.createElement(Typography, { textAlign: 'right', variant: 'subtitle1' }, "Total")))),
        React.createElement(Box, { sx: {
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: pinned ? '60px' : '400px',
                gap: pinned ? 0 : 2,
                flex: pinned ? 1 : 'auto',
            } }, filteredBalances.map((balance, index) => (React.createElement(BalanceListItem, { loading: loading, pinned: pinned, precision: getDecimalsByCurrencyCode(balance.code, currencies) || 2, key: index, item: balance }))))));
};
export default BalancesList;
