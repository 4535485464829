import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import OrdersService from '../../../../../services/ordersService';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
export const CancelOrder = ({ item, toggle }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    useEffect(() => {
        dispatch({ type: 'STOP_ADMIN_ORDERS_POLLING', error: '' });
        return () => {
            dispatch({ type: 'START_ADMIN_ORDERS_POLLING' });
        };
    }, []);
    const handleCancel = (code) => {
        const params = {
            url: `/orders/${code}/cancel`,
            method: 'put',
        };
        const service = new OrdersService(params);
        service.makeRequest()
            .then(() => {
            showNotification({
                message: 'Order successfully canceled',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.ORDER_CANCEL);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(Box, null,
            "Are you sure you want to cancel Order: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(Button, { color: "primary", variant: "contained", onClick: () => handleCancel(item.code) }, "Yes"),
            React.createElement(Button, { variant: "outlined", onClick: toggle }, "Close"))));
};
