import { Box, TextField } from '@mui/material';
import React from 'react';
const SuborderDetails = ({ amount, action, base, quote, remainder, }) => {
    const tradingPair = `${base}/${quote}`;
    return (React.createElement(Box, { style: { display: 'flex' }, gap: 1 },
        React.createElement(TextField, { disabled: true, variant: "filled", label: "Pair", size: "small", fullWidth: true, defaultValue: tradingPair }),
        React.createElement(TextField, { disabled: true, variant: "filled", label: "Direction", size: "small", fullWidth: true, defaultValue: action }),
        React.createElement(TextField, { disabled: true, variant: "filled", label: "Order total", size: "small", fullWidth: true, defaultValue: amount, InputProps: { endAdornment: base } }),
        React.createElement(TextField, { disabled: true, variant: "filled", label: "Remainder", size: "small", fullWidth: true, value: remainder, InputProps: { endAdornment: base } })));
};
export default SuborderDetails;
