import React, { useState, useCallback, useEffect } from 'react';
import { Box, Grid, Collapse, } from '@mui/material';
import { useDispatch } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Switch from '@mui/material/Switch';
import CollapsedItem from './CollapsedItem';
import ExchangeModal from './ExchangeModal';
import Messages from '../../../../../shared/helpers/errorMessages';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import ExchangesService from '../../../../../services/exchangesService';
import showNotification from '../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
const ExchangesItem = ({ item, markets, features, dispatchLoadExchanges, }) => {
    const { name, enabled } = item;
    const [status, setStatus] = useState('closed');
    const [icon, setIcon] = useState(React.createElement(ExpandMoreIcon, { fontSize: 'small' }));
    const [collapse, setCollapse] = useState(false);
    const [modal, setModal] = useState(false);
    const [loadingEnable, setLoadingEnable] = useState(false);
    const [loadingDisable, setLoadingDisable] = useState(false);
    const [enabledSwitch, setEnabledSwitch] = useState(true);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleChange = (event) => {
        toggleModal();
    };
    useEffect(() => {
        setEnabledSwitch(enabled);
    }, [enabled]);
    // TODO replace with one function
    const enableExchange = () => {
        const exchangesService = new ExchangesService({
            url: `/trade/exchanges/${item.code}/enable`,
            method: 'post',
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoadingEnable(true);
        exchangesService.makeRequest()
            .then(() => {
            dispatchLoadExchanges();
            showNotification({
                message: 'Exchange successfully enabled',
                color: 'success',
                dispatch: errorNotice,
            });
            setLoadingEnable(false);
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.EXCHANGES_ENABLE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoadingEnable(false);
            toggleModal();
        });
    };
    const disableExchange = () => {
        const exchangesService = new ExchangesService({
            url: `/trade/exchanges/${item.code}/disable`,
            method: 'post',
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoadingDisable(true);
        exchangesService.makeRequest()
            .then(() => {
            dispatchLoadExchanges();
            showNotification({
                message: 'Exchange successfully disabled',
                color: 'success',
                dispatch: errorNotice,
            });
            setLoadingDisable(false);
            toggleModal();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.EXCHANGES_DISABLE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoadingDisable(false);
            toggleModal();
        });
    };
    const onEntering = () => {
        setStatus('opening');
        setIcon(React.createElement(ExpandLessIcon, { fontSize: 'small' }));
    };
    const onExiting = () => {
        setStatus('closing');
        setIcon(React.createElement(ExpandMoreIcon, { fontSize: 'small' }));
    };
    const toggle = () => {
        setCollapse(!collapse);
    };
    const toggleModal = () => {
        setModal(!modal);
    };
    const filteredMarkets = markets.filter((m) => m.exchange_code === item.code);
    return (React.createElement("div", null,
        modal ? (React.createElement(ExchangeModal, { open: modal, enabled: enabledSwitch, exchangeName: item.name, toggleFunc: toggleModal, loadingEnable: loadingEnable, loadingDisable: loadingDisable, enableExchange: enableExchange, disableExchange: disableExchange })) : null,
        React.createElement(Box, { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', p: 2, width: '100%' },
            React.createElement(Box, { alignSelf: 'start', onClick: toggle },
                React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
                    React.createElement("div", { style: { 'lineHeight': 1 } }, icon),
                    React.createElement("div", null, name))),
            React.createElement(Box, null,
                React.createElement(Permissions, { features: features, permissions: ['exchange.update'] },
                    React.createElement(Grid, { component: "label", container: true, alignItems: "center", spacing: 1 },
                        React.createElement(Grid, { item: true }, "Disabled"),
                        React.createElement(Grid, { item: true },
                            React.createElement(Switch, { checked: enabledSwitch, onChange: handleChange, color: "primary", inputProps: { 'aria-label': 'primary checkbox' } })),
                        React.createElement(Grid, { item: true }, "Enabled"))))),
        React.createElement(Collapse, { in: collapse, className: "collapse__content", onEntering: onEntering, onExiting: onExiting },
            React.createElement(Box, { px: 2 },
                React.createElement(CollapsedItem, { markets: filteredMarkets })))));
};
export default ExchangesItem;
