import React from 'react';
import { TableRow, TableCell } from '@mui/material';
const ClientDetailsContact = ({ clientDetails }) => {
    if (!clientDetails)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Name contact:"),
            React.createElement(TableCell, null, clientDetails.contact_person_name)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Phone number:"),
            React.createElement(TableCell, null, clientDetails.phone_number)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Contact email address:"),
            React.createElement(TableCell, null, clientDetails.email)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Broker fee:"),
            React.createElement(TableCell, null, clientDetails.fee)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Preferred currency:"),
            React.createElement(TableCell, null, clientDetails.currency)),
        clientDetails.ibans && clientDetails.ibans.map(iban => (React.createElement(TableRow, { key: iban.iban },
            React.createElement(TableCell, null,
                "IBAN(",
                iban.currency,
                ")"),
            React.createElement(TableCell, null,
                iban.iban,
                " ",
                React.createElement("span", { className: "client_search_transform" }, iban.is_enabled ? '' : ' (disabled)'))))),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "BFID:"),
            React.createElement(TableCell, null, clientDetails.bfid)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Depot:"),
            React.createElement(TableCell, null, clientDetails.depot)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Kontokorrent:"),
            React.createElement(TableCell, null, clientDetails.kontokorrent)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Rubric:"),
            React.createElement(TableCell, null, clientDetails.rubric))));
};
export default ClientDetailsContact;
