import React, { useState } from 'react';
import { Button, TableRow, TableCell } from '@mui/material';
import TableCellContainer from './TableCellContainer';
import CurrencyConfigDialog from './CurrencyConfigDialog';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
const CurrencyConfigItem = ({ features, columnsToRender, item, keyIndex, newLoading, updateCurrency, }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleUpdateModal = () => {
        setModalOpen(prev => !prev);
    };
    return (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: keyIndex },
        columnsToRender.map((column, i) => {
            const key = column.key;
            const value = key ? item[key] : '';
            return (React.createElement(TableCellContainer, { key: i, item: item, value: value, columnKey: key }));
        }),
        React.createElement(TableCell, { align: "right" },
            React.createElement(Permissions, { features: features, permissions: ['custody_currency_configuration.update'] },
                React.createElement(Button, { size: "small", variant: "outlined", onClick: () => toggleUpdateModal() }, "update"))),
        modalOpen ? (React.createElement(CurrencyConfigDialog, { item: item, open: modalOpen, loading: newLoading, toggleFunc: toggleUpdateModal, updateCurrency: updateCurrency })) : null));
};
export default CurrencyConfigItem;
