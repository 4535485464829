import React, { useState, useEffect } from 'react';
import { Box, Chip, Button, MenuItem, Collapse, TextField, FormControlLabel, Checkbox as MuiCheck, } from '@mui/material';
import shortid from 'shortid';
import { parseISO, format } from 'date-fns';
import { connect } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MoreFilters, FiltersReset, SelectedFilters, CreateWithdrawal, FiltersContainer, CustodyFilterPanelContainer, } from '../../../Custody/styling/style';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import { getEnabledExchanges, getTradingBases, getTradingQuotes } from '../../../../../redux/selectors/selectors';
const options = [
    {
        label: 'Buy',
        value: 'Buy',
        type: 'select',
        name: 'action_type',
    },
    {
        label: 'Sell',
        value: 'Sell',
        type: 'select',
        name: 'action_type',
    },
    {
        value: '',
        label: 'All',
        type: 'select',
        name: 'action_type',
    },
];
const nameArr = ['amount_from', 'amount_to', 'from', 'to'];
const FilterButton = ({ item, onChange }) => {
    if (item.value === '')
        return null;
    const value = item.type === 'date' ? format(parseISO(item.value), 'dd/MM/yyyy') : item.value;
    return (React.createElement(Chip, { size: "small", color: "primary", onDelete: onChange, label: nameArr.includes(item.name) ? `${item.label}: ${value}` : item.label }));
};
const ManageFiltersContent = ({ state, features, exchanges, toggleModal, optionsBase, resetFilters, optionsQuotes, handleDateChange, addSelectedFilter, removeSelectedFilter, }) => {
    const [collapse, setCollapse] = useState(false);
    const [to, setTo] = useState(null);
    const [from, setFrom] = useState(null);
    const [amountTo, setAmountTo] = useState(null);
    const [amountFrom, setAmountFrom] = useState(null);
    const [actionType, setActionType] = useState(null);
    const renderBase = (arr) => {
        const mappedBase = [];
        for (let j = 0; j < arr.length; j += 10) {
            mappedBase.push(React.createElement(Box, { key: shortid.generate(), display: 'flex', flexDirection: 'column' },
                React.createElement("h5", { className: "filter_column_label" }, "Base"),
                arr.slice(j, j + 10).map(base => (React.createElement(FormControlLabel, { key: base.value, label: base.value, control: React.createElement(MuiCheck, { id: base.value, checked: isCheckboxSelected(base.value), onChange: (event) => handleCheckboxChange(event, { name: 'base', label: base.value }) }) })))));
        }
        return mappedBase;
    };
    const toggleCollapseFilter = () => {
        setCollapse(!collapse);
    };
    const onAmountChange = (evt) => {
        const { value, name } = evt.target;
        if (isNaN(Number(value)))
            return;
        addSelectedFilter({ value: value, name, type: 'number', label: name === 'amount_from' ? 'Amount From' : 'Amount To' });
    };
    useEffect(() => {
        const objIndexDateFrom = state.selectedFilters.findIndex((obj => obj.name === 'from'));
        const fromData = state.selectedFilters[objIndexDateFrom];
        setFrom(fromData);
        const objIndexDateTo = state.selectedFilters.findIndex((obj => obj.name === 'to'));
        const toData = state.selectedFilters[objIndexDateTo];
        setTo(toData);
        const objIndexAmountFrom = state.selectedFilters.findIndex((obj => obj.name === 'amount_from'));
        const amount_from = state.selectedFilters[objIndexAmountFrom];
        setAmountFrom(amount_from);
        const objIndexAmountTo = state.selectedFilters.findIndex((obj => obj.name === 'amount_to'));
        const amount_to = state.selectedFilters[objIndexAmountTo];
        setAmountTo(amount_to);
        const objIndexActionType = state.selectedFilters.findIndex((obj => obj.name === 'action_type'));
        const action_type = state.selectedFilters[objIndexActionType];
        setActionType(action_type || null);
    }, [state]);
    const handleCheckboxChange = (event, value) => {
        const filter = { value: value.label, type: 'checkbox', name: value.name, label: value.label };
        if (event.target.checked) {
            addSelectedFilter(filter);
        }
        else {
            removeSelectedFilter(filter);
        }
    };
    const isCheckboxSelected = (name) => {
        return !!state.selectedFilters.find(opt => opt.label === name);
    };
    return React.createElement(React.Fragment, null,
        React.createElement(CustodyFilterPanelContainer, null,
            React.createElement(FiltersContainer, null,
                React.createElement(MoreFilters, null,
                    React.createElement(Button, { size: "small", variant: "outlined", type: "button", color: "primary", onClick: toggleCollapseFilter },
                        collapse ? '-' : '+',
                        " Quick filters")),
                state.selectedFilters.length ? (React.createElement(FiltersReset, null,
                    React.createElement(Button, { variant: "outlined", size: "small", type: "button", color: "inherit", onClick: () => resetFilters() }, "Reset filters"))) : null,
                React.createElement(Permissions, { features: features, permissions: ['order_cancel_all.create'] },
                    React.createElement(CreateWithdrawal, null,
                        React.createElement(Button, { variant: "outlined", size: "small", onClick: toggleModal }, "Cancel all orders"))),
                React.createElement(SelectedFilters, null,
                    React.createElement(Box, { gap: 1 }, state.selectedFilters.map((filter) => (React.createElement(FilterButton, { item: filter, key: shortid.generate(), onChange: () => removeSelectedFilter(filter) })))))),
            React.createElement(Collapse, { in: collapse },
                React.createElement(Box, { display: 'flex', p: 2, gap: 2, justifyContent: 'space-between' },
                    React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                        React.createElement("h5", null, "Status"),
                        React.createElement(FormControlLabel, { label: "ToCancel", control: React.createElement(MuiCheck, { id: "toCancel", onChange: (event) => handleCheckboxChange(event, { name: 'status', label: 'ToCancel' }) }) }),
                        React.createElement(FormControlLabel, { label: "Open", control: React.createElement(MuiCheck, { id: "open", onChange: (event) => handleCheckboxChange(event, { name: 'status', label: 'Open' }) }) }),
                        React.createElement(FormControlLabel, { label: "Filled", control: React.createElement(MuiCheck, { id: "filled", onChange: (event) => handleCheckboxChange(event, { name: 'status', label: 'Filled' }) }) }),
                        React.createElement(FormControlLabel, { label: "Rejected", control: React.createElement(MuiCheck, { id: "rejected", onChange: (event) => handleCheckboxChange(event, { name: 'status', label: 'Rejected' }) }) }),
                        React.createElement(FormControlLabel, { label: "Canceled", control: React.createElement(MuiCheck, { id: "canceled", onChange: (event) => handleCheckboxChange(event, { name: 'status', label: 'Canceled' }) }) }),
                        React.createElement(FormControlLabel, { label: "CanceledPartiallyFilled", control: React.createElement(MuiCheck, { id: "canceledPartiallyFilled", onChange: (event) => handleCheckboxChange(event, { name: 'status', label: 'CanceledPartiallyFilled' }) }) })),
                    React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                        React.createElement("h5", null, "Exchanges"),
                        exchanges.map(e => (React.createElement(FormControlLabel, { key: e.code, label: e.code, control: React.createElement(MuiCheck, { id: "canceledPartiallyFilled", onChange: (event) => handleCheckboxChange(event, { name: 'exchange_code', label: e.code }) }) }))),
                        React.createElement("h5", { className: "filter_column_label" }, "Order type"),
                        React.createElement(FormControlLabel, { label: "Limit", control: React.createElement(MuiCheck, { checked: isCheckboxSelected('limit'), onChange: (event) => handleCheckboxChange(event, { name: 'order_type', label: 'limit' }) }) }),
                        React.createElement(FormControlLabel, { label: "Market", control: React.createElement(MuiCheck, { checked: isCheckboxSelected('market'), onChange: (event) => handleCheckboxChange(event, { name: 'order_type', label: 'market' }) }) }),
                        React.createElement(FormControlLabel, { label: "Rfq_market", control: React.createElement(MuiCheck, { checked: isCheckboxSelected('rfq_market'), onChange: (event) => handleCheckboxChange(event, { name: 'order_type', label: 'rfq_market' }) }) }),
                        React.createElement(FormControlLabel, { label: "Rfq_limit", control: React.createElement(MuiCheck, { checked: isCheckboxSelected('rfq_limit'), onChange: (event) => handleCheckboxChange(event, { name: 'order_type', label: 'rfq_limit' }) }) }),
                        React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                            React.createElement("h5", { className: "filter_column_label" }, "Quote"),
                            optionsQuotes.map(fiat => (React.createElement(FormControlLabel, { key: fiat.value, label: fiat.value, control: React.createElement(MuiCheck, { checked: isCheckboxSelected(fiat.value), onChange: (event) => handleCheckboxChange(event, { name: 'quote', label: fiat.value }) }) }))))),
                    React.createElement(Box, { display: 'flex' }, renderBase(optionsBase)),
                    React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                        React.createElement(TextField, { select: true, variant: "outlined", size: "small", fullWidth: true, label: "Type", value: (actionType === null || actionType === void 0 ? void 0 : actionType.value) || '', onChange: (event) => addSelectedFilter({ value: event.target.value, label: 'Type', name: 'action_type', type: 'select' }) }, options.map(option => React.createElement(MenuItem, { key: option.value, value: option.value }, option.label))),
                        React.createElement(DatePicker, { disableFuture: true, format: "dd/MM/yyyy", label: "Date From", value: (from === null || from === void 0 ? void 0 : from.value) ? parseISO(from.value) : null, slotProps: {
                                actionBar: {
                                    actions: ['clear', 'cancel', 'today']
                                },
                                textField: { size: 'small' }
                            }, onChange: (date) => { handleDateChange(date, 'Date From', 'from'); } }),
                        React.createElement(DatePicker, { disableFuture: true, format: "dd/MM/yyyy", label: "Date To", slotProps: {
                                actionBar: {
                                    actions: ['clear', 'cancel', 'today']
                                },
                                textField: { size: 'small' }
                            }, value: (to === null || to === void 0 ? void 0 : to.value) ? parseISO(to.value) : null, onChange: (date) => handleDateChange(date, 'Date To', 'to') }),
                        React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
                            React.createElement(TextField, { size: "small", variant: "outlined", name: "amount_from", label: "Amount From", onChange: onAmountChange, value: amountFrom ? amountFrom.value : '', inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' } }),
                            React.createElement(TextField, { size: "small", name: "amount_to", label: "Amount To", variant: "outlined", onChange: onAmountChange, value: amountTo ? amountTo.value : '', inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' } })))))));
};
const mapStateToProps = (state) => {
    const optionsBase = getTradingBases(state);
    const optionsQuotes = getTradingQuotes(state);
    const exchanges = getEnabledExchanges(state) || [];
    return {
        exchanges,
        optionsBase,
        optionsQuotes,
    };
};
export default connect(mapStateToProps)(ManageFiltersContent);
