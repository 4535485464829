import React, { useRef, useState, useEffect } from 'react';
import { Dialog, Button, Checkbox, TextField, FormGroup, DialogTitle, DialogContent, DialogActions, FormControlLabel, CircularProgress, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ModalLoadingContainer, ModalSpinnerContainer } from '../../../Custody/styling/style';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const CurrencyConfigDialog = ({ open, toggleFunc, updateCurrency, loading, item, }) => {
    const dirty = useRef(false);
    const [label, setLabel] = useState('');
    const [feeValue, setFeeValue] = useState(0);
    const [testNet, setTestNet] = useState(false);
    const helperTextStyles = useHelperTextStyles();
    const [precision, setPrecision] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [explorerUrl, setExplorerUrl] = useState('');
    const [currencyCode, setCurrencyCode] = useState('');
    const [contractAddress, setContractAddress] = useState('');
    const [blockchainNetwork, setBlockchainNetwork] = useState('');
    const [fireblocksAssetId, setFireblocksAssetId] = useState('');
    const [minimumDepositSize, setMinimumDepositSize] = useState('');
    const [invalidFields, setInvalidFields] = useState([]);
    const validateFields = (fieldsToValidate) => {
        const errors = [];
        for (const [key, value] of Object.entries(fieldsToValidate)) {
            if (`${value}`.trim() === '') {
                errors.push(key);
            }
        }
        return errors;
    };
    const addNewSetting = (itemCode) => {
        dirty.current = true;
        const errors = validateFields({ label, minimumDepositSize });
        if (errors.length) {
            setInvalidFields([...errors]);
            return;
        }
        const payload = {
            label,
            explorer_url: explorerUrl,
            blockchain_network: blockchainNetwork,
            minimum_deposit_size: minimumDepositSize,
        };
        updateCurrency(payload, itemCode);
        setLabel('');
        setFeeValue(0);
        setPrecision(0);
        setTestNet(false);
        setExplorerUrl('');
        setCurrencyCode('');
        setContractAddress('');
        setBlockchainNetwork('');
        setFireblocksAssetId('');
        setMinimumDepositSize('');
        toggleFunc();
    };
    const onFeeChange = (evt) => {
        const val = evt.target.value.trim();
        if (isNaN(Number(val))) {
            return;
        }
        setFeeValue(+val);
    };
    const onFieldsChange = (evt) => {
        const { name, value } = evt.target;
        if (name === 'label') {
            setLabel(value);
        }
        if (name === 'explorerUrl') {
            setExplorerUrl(value);
        }
        if (name === 'blockchainNetwork') {
            setBlockchainNetwork(value);
        }
        if (name === 'minimumDepositSize') {
            setMinimumDepositSize(value);
        }
    };
    const toggleTestNet = (evt) => {
        const { checked } = evt.target;
        setTestNet(checked);
    };
    useEffect(() => {
        if (item) {
            setLabel(item.label);
            setTestNet(item.test_net);
            setFeeValue(item.fee_value);
            setPrecision(item.precision);
            setExplorerUrl(item.explorer_url);
            setCurrencyCode(item.currency_code);
            setContractAddress(item.contract_address);
            setBlockchainNetwork(item.blockchain_network);
            setFireblocksAssetId(item.fireblocks_asset_id);
            setMinimumDepositSize(item.minimum_deposit_size);
        }
    }, [item]);
    useEffect(() => {
        if (dirty.current) {
            const errors = validateFields({ label, minimumDepositSize });
            setInvalidFields([...errors]);
        }
    }, [label, minimumDepositSize]);
    useEffect(() => {
        if (!label || !minimumDepositSize) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [label, minimumDepositSize]);
    if (loading) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement("h4", null, "Loading"),
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, { size: "33px" }))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Update currency"),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { disabled: true, fullWidth: true, label: "Fee", size: "small", margin: "normal", value: feeValue, variant: "outlined", onChange: onFeeChange, InputLabelProps: { shrink: true } }),
            React.createElement(TextField, { disabled: true, fullWidth: true, size: "small", margin: "normal", value: precision, label: "Precision", variant: "outlined", InputLabelProps: { shrink: true } }),
            React.createElement(TextField, { fullWidth: true, size: "small", name: "label", value: label, label: "Label", margin: "normal", variant: "outlined", onChange: onFieldsChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('label') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", variant: "outlined", name: "explorerUrl", value: explorerUrl, label: "Explorer Url", onChange: onFieldsChange, InputLabelProps: { shrink: true } }),
            React.createElement(TextField, { disabled: true, fullWidth: true, size: "small", margin: "normal", label: "Currency", variant: "outlined", value: currencyCode, InputLabelProps: { shrink: true } }),
            React.createElement(TextField, { disabled: true, fullWidth: true, size: "small", margin: "normal", variant: "outlined", value: contractAddress, label: "Contract Address", InputLabelProps: { shrink: true } }),
            React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", variant: "outlined", name: "blockchainNetwork", value: blockchainNetwork, onChange: onFieldsChange, label: "Blockchain Network", InputLabelProps: { shrink: true } }),
            React.createElement(TextField, { disabled: true, fullWidth: true, size: "small", margin: "normal", variant: "outlined", value: fireblocksAssetId, label: "Fireblocks Asset Id", InputLabelProps: { shrink: true } }),
            React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", variant: "outlined", name: "minimumDepositSize", onChange: onFieldsChange, value: minimumDepositSize, label: "Minimum Deposit Size", InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('minimumDepositSize') ? 'Field is required' : ''}` }),
            React.createElement(FormGroup, null,
                React.createElement(FormControlLabel, { label: "Test NET", control: React.createElement(Checkbox, { value: testNet, onChange: toggleTestNet, color: "primary" }) }))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { size: "small", color: "primary", variant: "contained", disabled: disabled, onClick: () => addNewSetting(item.code) }, "Update"),
            React.createElement(Button, { variant: "outlined", size: "small", onClick: toggleFunc }, "Cancel"))));
};
export default CurrencyConfigDialog;
