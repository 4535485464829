import React, { useMemo } from 'react';
import Decimal from 'decimal.js';
const Volume = ({ volume }) => (React.createElement("div", { className: "container_trade_color_grey" }, volume === ''
    ? React.createElement("span", null, "--")
    : React.createElement("span", null, volume)));
const calcTotal = (allAsks) => allAsks.reduce((acm, cur) => Decimal.add(acm, cur.volume), new Decimal(0));
const VolumeTotal = ({ orderBookItem }) => {
    if (orderBookItem && orderBookItem.allAsks && orderBookItem.allAsks.length) {
        const { allAsks } = orderBookItem;
        const totalVolume = useMemo(() => calcTotal(allAsks), [allAsks]);
        return React.createElement(Volume, { volume: totalVolume.toFixed(2).replace(/\.?0+$/, '') });
    }
    return (React.createElement("div", { className: "container_trade_color_grey" },
        React.createElement("span", null, "--")));
};
export default React.memo(VolumeTotal);
