import { Box } from '@mui/material';
import React from 'react';
import { DateCell, TimeCell } from '../styled/tableCells';
import { format, parseISO } from 'date-fns';
const DateTime = (props) => {
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
        React.createElement(DateCell, null, format(parseISO(props.date), 'dd-MM-yyyy')),
        React.createElement(TimeCell, null, format(parseISO(props.date), 'HH:mm:ss'))));
};
export default DateTime;
