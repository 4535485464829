import * as types from '../actionTypes/widgetSettingsActionTypes';
const initialState = {
    widgets: [],
    settings: {
        byId: {},
        allIds: [],
    },
    workingSettings: {
        byId: {},
        allIds: [],
    },
    settingsLoaded: false,
};
/* eslint-disable @typescript-eslint/no-unsafe-member-access,
  @typescript-eslint/no-unsafe-assignment, no-case-declarations,
  @typescript-eslint/no-unsafe-return, */
const widgetSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        //  Dynamic setitngs approach
        case types.SCHEMA_LOAD_SUCCESS:
            return Object.assign(Object.assign({}, state), { settings: action.settings, settingsLoaded: true });
        case types.SCHEMA_ADD_WORKING:
            return Object.assign(Object.assign({}, state), { workingSettings: action.settings });
        case types.SCHEMA_APPLY_ALL_SETTINGS:
            return Object.assign(Object.assign({}, state), { settings: Object.assign({}, state.workingSettings) });
        case types.SCHEMA_APPLY_WORKSPACE:
            return Object.assign(Object.assign({}, state), { widgets: action.workspaceRecord.widgets, settings: Object.assign({}, action.workspaceRecord.widgetSettings), workingSettings: Object.assign({}, action.workspaceRecord.widgetSettings) });
        case types.SCHEMA_WORKING_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { workingSettings: Object.assign(Object.assign({}, state.workingSettings), { byId: Object.assign(Object.assign({}, state.workingSettings.byId), { [action.settingId]: Object.assign(Object.assign({}, state.workingSettings.byId[action.settingId]), { value: action.settingValue }) }) }) });
        case types.SCHEMA_WORKING_RESET_APPLIED:
            return Object.assign(Object.assign({}, state), { workingSettings: Object.assign(Object.assign({}, state.workingSettings), { byId: Object.assign({}, state.workingSettings.byId, action.mergedWorking) }) });
        case types.SCHEMA_CLONE_WIDGET:
            return Object.assign(Object.assign({}, state), { widgets: [...state.widgets, action.widget], settings: Object.assign(Object.assign({}, state.settings), { byId: Object.assign({}, state.settings.byId, action.widgetSettings.byId), allIds: [...state.settings.allIds, ...action.widgetSettings.allIds] }), workingSettings: Object.assign(Object.assign({}, state.workingSettings), { byId: Object.assign({}, state.workingSettings.byId, action.widgetSettings.byId), allIds: [...state.workingSettings.allIds, ...action.widgetSettings.allIds] }) });
        case types.SCHEMA_REMOVE_WIDGET: {
            const index = state.widgets.findIndex(w => w.widgetKey === action.widgetKey);
            return Object.assign(Object.assign({}, state), { widgets: [...state.widgets.slice(0, index), ...state.widgets.slice(index + 1)] });
        }
        case types.SCHEMA_UPDATE_LAYOUT_PROPS:
            return Object.assign(Object.assign({}, state), { widgets: [...action.widgets] });
        default:
            return state;
    }
};
export default widgetSettingsReducer;
