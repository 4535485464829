import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { Alert, Box, LinearProgress } from '@mui/material';
import LineCharts from './LineCharts';
import { getLineChartData } from '../../../../../../redux/selectors/adminSelectors';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const WidgetTradingCharts = ({ chartData, selectedClient }) => {
    if (!selectedClient) {
        return (React.createElement(Box, { p: 2 },
            React.createElement(Alert, { severity: "info", variant: "outlined" }, "Select client to see the chart")));
    }
    if (!chartData) {
        return (React.createElement(Box, { p: 2 },
            React.createElement(Typography, { variant: "subtitle1" }, "Building charts..."),
            React.createElement(LinearProgress, null)));
    }
    if (chartData === 'n/a') {
        return (React.createElement(Box, { p: 2 },
            React.createElement(Alert, { severity: "warning", variant: "outlined" }, "Not enough data to build charts")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { alignContent: "flex-start", container: true, item: true, spacing: 1, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("div", null,
                            React.createElement(LineCharts, { chartData: chartData }))))))));
};
const mapStateToProps = (state) => ({
    chartData: getLineChartData(state),
    selectedClient: getSelectedClientInputValue(state),
});
export default connect(mapStateToProps)(WidgetTradingCharts);
