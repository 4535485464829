// eslint-disable-next-line import/prefer-default-export
export const generateLayout = (widgets) => {
    if (widgets.length === 0) {
        return [];
    }
    return widgets.map(w => Object
        .assign({}, w.layoutProps, {
        minW: w.layoutProps.minW,
        minH: w.layoutProps.minH,
        moved: false,
        static: false,
    }));
};
