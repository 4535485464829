import React, { useState, useEffect } from 'react';
import PermissionContainer from './PermissionContainer';
import { materialUiPaginator } from '../../../../../../shared/helpers/recordsDependsOnPage';
import { CHANGED_PERMISSIONS_SET_UPDATE } from '../../../../../../redux/actionTypes/adminActionTypes';
export const changedPermissionsUpdate = (changedPermission) => ({
    type: CHANGED_PERMISSIONS_SET_UPDATE,
    changedPermission,
});
/* eslint-disable no-nested-ternary */
const PermissionsContent = ({ role, columns, maxHeight, resources, updateRole, permissions, rowsPerPage, setRowsPerPage, permissionsArr, setPermissionsArr, }) => {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [checkedItems, setCheckedItems] = useState([]);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortBy, setSortBy] = useState('resource');
    const [resourcesToRender, setResourcesToRender] = useState([]);
    const [searchString, setSearchString] = useState('');
    const onSortHandle = (key) => {
        setCurrentPage(0);
        setSortBy(key);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const searchHandle = (value) => {
        setSearchString(value);
    };
    const sortStoredPermissions = (permissionsToSort, direction, by) => {
        const sorterNum = (dir, a, b) => dir === 'desc'
            ? (a - b) : (b - a);
        const sorter = (dir, a, b) => dir === 'desc'
            ? (a > b)
                ? 1 : -1
            : (a < b)
                ? 1 : -1;
        const sortFunc = (a, b, dir, key) => {
            const valueA = a[key];
            const valueB = b[key];
            if (valueA && valueB) {
                if (isNaN(+valueA) && isNaN(+valueB)) {
                    return sorter(dir, valueA, valueB);
                }
                return sorterNum(dir, +valueA, +valueB);
            }
            return 0;
        };
        return permissionsToSort.sort((a, b) => sortFunc(a, b, direction, by));
    };
    const handleFieldChange = (evt, permissionName) => {
        const { checked } = evt.target;
        const indexChecked = checkedItems.findIndex(c => c === permissionName);
        const index = permissionsArr.findIndex(p => `${p.resource_name}.${p.action_name}` === permissionName);
        const permissionsAction = permissionName.split('.');
        const item = {
            resource_name: permissionsAction[0],
            action_name: permissionsAction[1],
            enable: checked,
        };
        if (index === -1) {
            setPermissionsArr([...permissionsArr, item]);
        }
        else {
            setPermissionsArr([...permissionsArr.slice(0, index), ...permissionsArr.slice(index + 1)]);
        }
        if (indexChecked === -1) {
            // add check
            setCheckedItems([...checkedItems, permissionName]);
        }
        else {
            // uncheck, remove
            setCheckedItems([...checkedItems.slice(0, indexChecked), ...checkedItems.slice(indexChecked + 1)]);
        }
    };
    useEffect(() => {
        updateRole(role);
    }, [role]);
    useEffect(() => {
        const items = [];
        const rolePermissions = permissions.filter(p => p.role_name === role.name);
        for (const permission of rolePermissions) {
            const rName = permission.resource;
            permission.actions.forEach((a) => {
                items.push(`${rName}.${a}`);
            });
        }
        setCheckedItems([...items]);
    }, [permissions, role]);
    useEffect(() => {
        let sortedResources = [...resources];
        if (sortBy) {
            sortedResources = sortStoredPermissions(sortedResources, sortDirection, sortBy)
                .filter(r => { var _a; return r.resource.includes(searchString) || ((_a = r.category) === null || _a === void 0 ? void 0 : _a.includes(searchString)); });
        }
        const items = materialUiPaginator(sortedResources, currentPage, rowsPerPage);
        setResourcesToRender(items);
        setTotalItems(items.length);
    }, [resources, rowsPerPage, currentPage, sortDirection, sortBy, searchString]);
    return (resources.length ?
        React.createElement(PermissionContainer, { sortBy: sortBy, columns: columns, maxHeight: maxHeight, totalItems: totalItems, currentPage: currentPage, rowsPerPage: rowsPerPage, onSortHandle: onSortHandle, onSearch: searchHandle, checkedItems: checkedItems, sortDirection: sortDirection, setCurrentPage: setCurrentPage, setRowsPerPage: setRowsPerPage, resourcesToRender: resourcesToRender, handleFieldChange: handleFieldChange }) : null);
};
export default PermissionsContent;
