import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Chip, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import OrdersService from '../../../../../../../services/ordersService';
import Messages from '../../../../../../../shared/helpers/errorMessages';
import SettlementService from '../../../../../../../services/settlementService';
import showNotification from '../../../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { ModalLoadingContainer, ModalSpinnerContainer } from '../../../../../Custody/styling/style';
export const CancelOrder = ({ item, toggle, theme }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    useEffect(() => {
        dispatch({ type: 'STOP_ADMIN_ORDERS_POLLING', error: '' });
        return () => {
            dispatch({ type: 'START_ADMIN_ORDERS_POLLING' });
        };
    }, []);
    const handleCancel = (code) => {
        const params = {
            url: `/orders/${code}/cancel`,
            method: 'put',
        };
        const service = new OrdersService(params);
        service.makeRequest()
            .then(() => {
            showNotification({
                message: 'Order successfully canceled',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.ORDER_CANCEL);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', p: 2 },
        React.createElement(Box, null,
            "Are you sure you want to cancel Order: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(Box, { display: 'flex', justifyContent: 'end', width: '100%', p: 1 },
            React.createElement(Button, { color: "primary", variant: "contained", onClick: () => handleCancel(item.code) }, "Yes"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Close"))));
};
export const SettlementInfo = ({ item, toggle, theme }) => {
    var _a, _b, _c, _d;
    const [loadingSettlement, setLoadingSettlement] = useState(false);
    const [settlements, setSettlements] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleSettlementInfo = (code, cancelToken) => {
        const settlementService = new SettlementService({
            url: `/report/settlement_info/${code}`,
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoadingSettlement(true);
        settlementService.makeRequest()
            .then((responseData) => {
            setSettlements(responseData.settlements);
            setLoadingSettlement(false);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setSettlements([]);
            const message = composeErrorMessage(e, Messages.SETTLEMENT_INFO_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoadingSettlement(false);
        });
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        handleSettlementInfo(item.code, cancelTokenSource.token);
        dispatch({ type: 'STOP_ADMIN_ORDERS_POLLING', error: '' });
        return () => {
            cancelTokenSource.cancel('canceled');
            dispatch({ type: 'START_ADMIN_ORDERS_POLLING' });
        };
    }, []);
    if (loadingSettlement) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, { size: "33px" }))));
    }
    if (!settlements.length) {
        return (React.createElement(Box, { display: 'flex', flexDirection: 'column', p: 2 },
            React.createElement(Box, null,
                "No Settlement info for order: ",
                React.createElement("span", { className: "forms_uuid_container" }, item.code)),
            React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, p: 1 },
                React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Close"))));
    }
    return (React.createElement("div", { className: `select_box_container_index ${theme}` },
        React.createElement(Box, { padding: 10 },
            "Order: ",
            React.createElement("span", { className: "common_opacity0_6" }, item.code)),
        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table", className: "common_padding_15 settlement_text_color" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Failure reason"),
                    React.createElement(TableCell, null, "Bank done at"),
                    React.createElement(TableCell, null, "Sent at"),
                    React.createElement(TableCell, null, "Status"))),
            React.createElement(TableBody, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, ((_a = settlements[0]) === null || _a === void 0 ? void 0 : _a.failure_reason) || '-----'),
                    React.createElement(TableCell, null, moment((_b = settlements[0]) === null || _b === void 0 ? void 0 : _b.order_bank_settled_at).format('YYYY-MM-DD H:mm:ss')),
                    React.createElement(TableCell, null, moment((_c = settlements[0]) === null || _c === void 0 ? void 0 : _c.order_sent_at).format('YYYY-MM-DD H:mm:ss')),
                    React.createElement(TableCell, null,
                        React.createElement(Chip, { size: "small", color: "default", label: (_d = settlements[0]) === null || _d === void 0 ? void 0 : _d.order_state }))))),
        React.createElement("div", { style: { display: 'flex', justifyContent: 'end', width: '100%' } },
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Close"))));
};
