import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
const DLTAutocomplete = (props) => {
    const { control, name, label, options, fullWidth, disabled } = props;
    return (React.createElement(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => {
            const { onChange, value, ref } = field;
            return (React.createElement(React.Fragment, null,
                React.createElement(Autocomplete, { disabled: disabled, fullWidth: fullWidth, size: 'small', value: options.find((o) => o.value === value) || null, options: options, renderOption: (props, option) => {
                        return (React.createElement("li", Object.assign({}, props, { key: option.value }), option.label));
                    }, onChange: (e, newValue) => onChange(newValue ? newValue.value : null), getOptionLabel: (option) => option.label, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { inputRef: ref, size: 'small', error: !!error, label: label }))) })));
        } }));
};
export default DLTAutocomplete;
