import React from 'react';
import TableCell from '@mui/material/TableCell';
/* eslint-disable no-nested-ternary */
const TokenCell = ({ value, keyOf, }) => {
    if (keyOf === 'created_at') {
        const d = value ? new Date(value) : undefined;
        return (React.createElement(TableCell, { align: "right" }, d ? `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}` : 'N/A'));
    }
    return (React.createElement(TableCell, { align: keyOf === 'label' ? 'left' : 'right' }, value || 'N/A'));
};
export default TokenCell;
