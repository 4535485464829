import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Bid from './Bid';
import Ask from './Ask';
import AskSize from './AskSize';
import BidSize from './BidSize';
import LastTrade from './LastTrade';
import CmlBidSize from './CmlBidSize';
import CmlAskSize from './CmlAskSize';
import VolumeTotal from './VolumeTotal';
import ValueCmlBidSize from './ValueCmlBidSize';
import ValueCmlAskSize from './ValueCmlAskSize';
import { LiveIndicator } from '../styling/styledComponents';
const RowConsolidated = ({ lastTradeConsolidated, orderBookConsolidated }) => (React.createElement(TableRow, { className: "market_overview_body_weigth" },
    React.createElement(TableCell, null,
        React.createElement(LiveIndicator, null),
        React.createElement("span", null, "CONSOLIDATED")),
    React.createElement(TableCell, null,
        React.createElement(LastTrade, { lastTrade: lastTradeConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(VolumeTotal, { orderBookItem: orderBookConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(ValueCmlBidSize, { orderBookItem: orderBookConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(CmlBidSize, { orderBookItem: orderBookConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(BidSize, { orderBookItem: orderBookConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(Bid, { orderBookItem: orderBookConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(Ask, { orderBookItem: orderBookConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(AskSize, { orderBookItem: orderBookConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(CmlAskSize, { orderBookItem: orderBookConsolidated })),
    React.createElement(TableCell, null,
        React.createElement(ValueCmlAskSize, { orderBookItem: orderBookConsolidated }))));
export default React.memo(RowConsolidated);
