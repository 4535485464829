import React from 'react';
import * as Styled from '../../styling/styled';
const LadderHeading = () => (React.createElement(Styled.LadderHeading, null,
    React.createElement("div", null,
        React.createElement("span", null, "cml")),
    React.createElement("div", { className: "ladder-heading-level" },
        React.createElement("span", null, "price")),
    React.createElement("div", null,
        React.createElement("span", null, "cml"))));
export default LadderHeading;
