import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { WIDGET_HEIGHT_UPDATE } from '../../redux/actionTypes/widgetsActionTypes';
import { Paper } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => createStyles({
    paperVariant: {
        overflowY: 'auto',
        height: '100%',
    },
}));
const LayoutContainer = React.forwardRef((props, forwardRef) => {
    const { children, onMouseDown, onTouchStart, onMouseUp, onTouchEnd, style, widgetKey } = props;
    const { height, ref: resizeRef } = useResizeDetector();
    const dispatch = useDispatch();
    const classes = useStyles();
    useEffect(() => {
        dispatch({ type: WIDGET_HEIGHT_UPDATE, widgetKey, height });
    }, [widgetKey, height]);
    return (
    // jsx-a11y/no-static-element-interactions
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    React.createElement(Paper, { ref: resizeRef, className: classes.paperVariant, onMouseDown: onMouseDown, onTouchStart: onTouchStart, onMouseUp: onMouseUp, onTouchEnd: onTouchEnd, style: style, elevation: 0 }, children));
});
export default LayoutContainer;
