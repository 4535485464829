import React, { useState, useEffect, useCallback } from 'react';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import { CircularProgress, Container } from '@mui/material';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialPopoverWrap, WalletsClientContainer, } from '../../styling/style';
import { getWidgetColumns, getCustodyWallets, } from '../../../../../redux/selectors/custodySelectors';
import DepositFilters from './DepositFilters';
import DepositTableCell from './DepositTableCell';
import DepositsCellActions from './DepositsCellActions';
import { SpinnerContainer } from '../../../Admin/styling/style';
import { DepositReferenceId } from './components/DepositsActions';
import { DepositOverrideAML } from './components/DepositsActions';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import CustodyClientDepositsService from '../../../../../services/custodyDepositsService';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
import { getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
const getDepositsToRender = (deposits) => {
    if (deposits.length === 0) {
        return [];
    }
    return deposits.map((r) => {
        const convert = {
            label: '',
            code: r.code,
            state: r.state,
            wallet_code: r.wallet_code,
            client_uuid: r.client_uuid,
            reference_id: r.reference_id,
            currency_code: r.currency_code,
            deposit_address: r.deposit_address,
            compliance_amount: r.compliance_amount,
            compliance_tx_hash: r.compliance_tx_hash,
            compliance_amount_usd: r.compliance_amount_usd,
            compliance_received_at: r.compliance_received_at,
        };
        return convert;
    });
};
const Deposits = ({ selectedClient, columns, depositsWidgetHeight, currencies, allWallets, masterTenant, currenciesOptions, }) => {
    const [loading, setLoading] = useState(false);
    const [maxHeight, setMaxHeight] = useState(480);
    const [totalPages, setTotalPages] = useState(0);
    const [clientCode, setClientCode] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [urlDeposits, setUrlDeposits] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortDirection, setSortDirection] = useState('desc');
    const [sortBy, setSortBy] = useState('compliance_received_at');
    const [deposits, setDeposits] = useState([]);
    const [depositsToRender, setDepositsToRender] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const updateUrl = useCallback((url) => {
        setUrlDeposits(url);
    }, [urlDeposits]);
    const cellActions = {
        actions: {
            updateReferenceId: (item) => {
                const itemIndex = depositsToRender.findIndex(d => d.code === item.code);
                // do something if no index;
                const updatedDeposits = depositsToRender.map((depositItem, index) => {
                    if (index !== itemIndex) {
                        return depositItem;
                    }
                    return Object.assign(Object.assign({}, depositItem), item);
                });
                setDepositsToRender([...updatedDeposits]);
            },
        },
        actionsComponents: {
            updateReferenceId: DepositReferenceId,
            initiateAMLOverride: DepositOverrideAML,
        },
        actionsList: [
            { name: 'Update Reference ID', action: 'updateReferenceId' },
            { name: 'Override failed AML checks', action: 'initiateAMLOverride' },
        ],
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRender([...columnsToRender]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const onChangePage = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const onSortHandle = (key) => {
        setCurrentPage(0);
        setSortBy(key);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const fetchDeposits = (url, cancelToken) => {
        setLoading(true);
        const depositsService = new CustodyClientDepositsService({
            url: `/custody/deposits${url}`,
            method: 'get',
            cancelToken,
        });
        depositsService.makeRequest()
            .then((data) => {
            setLoading(false);
            setDeposits(data.records);
            setTotalPages(+data.total);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                // to avoid any state update
                // on unmounted component
                return;
            }
            setLoading(false);
            const message = composeErrorMessage(e, Messages.DEPOSIT_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        setClientCode((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || '');
    }, [selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (urlDeposits !== '') {
            fetchDeposits(urlDeposits, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [urlDeposits]);
    useEffect(() => {
        if (deposits.length) {
            const items = getDepositsToRender(deposits);
            const mapped = items.map((d) => {
                const wallet = allWallets.find(w => w.code === d.wallet_code);
                const labelData = wallet ? wallet.label : 'N/A';
                return Object.assign({}, d, { label: labelData });
            });
            setDepositsToRender(mapped);
        }
        else {
            setTotalPages(0);
            setDepositsToRender([]);
        }
    }, [deposits, allWallets]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(depositsWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [depositsWidgetHeight]);
    return (React.createElement("div", null,
        React.createElement(DepositFilters, { initialValue: "", sortBy: sortBy, page: currentPage, clientCode: clientCode, rowsPerPage: rowsPerPage, selectedClient: selectedClient, onFiltersChange: updateUrl, currenciesOptions: currenciesOptions, sortDirection: sortDirection, setCurrentPage: setCurrentPage }),
        loading ? (React.createElement(SpinnerContainer, null,
            React.createElement(CircularProgress, null))) : (React.createElement(React.Fragment, null,
            React.createElement(WalletsClientContainer, { className: "deposits_main_m" },
                React.createElement("div", null,
                    React.createElement("div", null,
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null))),
                    React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        }, transformOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                        } },
                        React.createElement(MaterialPopoverWrap, null,
                            React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                React.createElement(ListItemText, { primary: col.title }),
                                React.createElement(ListItemSecondaryAction, null,
                                    React.createElement(GreenSwitch, { edge: "end", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' }, color: "default", size: "small" })))))))))),
            depositsToRender.length === 0 ? (React.createElement(Container, null,
                React.createElement(Alert, { severity: "info", variant: "outlined" }, "No items found"))) : (React.createElement(React.Fragment, null,
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, align: 'left', style: { minWidth: column.minWidth } },
                                    React.createElement(TableSortLabel, { direction: column.key === sortBy ? sortDirection : 'asc', active: column.key === sortBy, onClick: () => onSortHandle(column.key) }, column.title)))),
                                React.createElement(TableCell, { key: "actions", align: "right" }, "Actions"))),
                        React.createElement(TableBody, null, depositsToRender.map(deposit => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: deposit.code },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? deposit[key] : undefined;
                                return (React.createElement(DepositTableCell, { value: value, item: deposit, columnKey: key, deposits: deposits, currencies: currencies, key: `${i}_depositItem`, currency: deposit.currency_code }));
                            }),
                            React.createElement(DepositsCellActions, { item: deposit, masterTenant: masterTenant, deposits: deposits, actions: cellActions, urlDeposits: urlDeposits, fetchDeposits: fetchDeposits }))))))),
                React.createElement(TablePagination, { component: "div", count: totalPages, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [maxHeight] })))))));
};
const mapStateToProps = (state, ownProps) => ({
    currenciesOptions: getCustodyCurrenciesOptions(state),
    currencies: state.currencies.custodyCurrencies,
    allWallets: getCustodyWallets(state),
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    depositsWidgetHeight: state.widgets[ownProps.widgetKey],
    masterTenant: state.client.masterTenant,
});
export default connect(mapStateToProps)(Deposits);
