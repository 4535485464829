import React from 'react';
import { Box, Button } from '@mui/material';
const ReSendInvite = ({ item, toggle, handleClick }) => (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
    React.createElement(Box, null,
        "Are you sure you want to re-send invite for email: ",
        React.createElement("span", null, item.email),
        "?"),
    React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
        React.createElement(Button, { color: "primary", variant: "contained", onClick: () => handleClick() }, "Resend"),
        React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Cancel"))));
export default ReSendInvite;
