/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Chip, Collapse, IconButton } from '@mui/material';
import { InfoItem, InfoColumn, InfoItemValue, InfoItemLabel, InfoContainer, CollapseContainer, } from './styling/styledComponent';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import filterByObjectValues from '../../../../../shared/helpers/filterByObjectValues';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
const SuborderCustomerInfo = ({ customers, customerCode, features }) => {
    const [isOpen, setOpen] = useState(false);
    const [customer, setCustomer] = useState(null);
    useEffect(() => {
        if (customers && customers.length) {
            if (!customerCode)
                return setCustomer(null);
            const result = filterByObjectValues(customers, customerCode);
            if (result[0]) {
                setCustomer(result[0]);
            }
            else {
                setCustomer(null);
            }
        }
        return undefined;
    }, [customers]);
    const toggleOpen = () => {
        setOpen(!isOpen);
    };
    return (React.createElement("div", null,
        React.createElement(InfoContainer, null,
            React.createElement(InfoColumn, null,
                React.createElement(InfoItem, { style: { display: 'flex', alignItems: 'center' } },
                    React.createElement(InfoItemLabel, null, "Customer code"),
                    React.createElement(InfoItemValue, null,
                        React.createElement(Chip, { size: "small", label: customerCode }),
                        customer && (React.createElement(Permissions, { features: features, permissions: ['customer_details.read'] },
                            React.createElement(IconButton, { onClick: toggleOpen, size: "small", style: { marginLeft: '8px' } }, isOpen ? React.createElement(KeyboardArrowUpIcon, null) : React.createElement(KeyboardArrowDownIcon, null)))))))),
        React.createElement(Collapse, { in: isOpen }, customer && (React.createElement(Permissions, { features: features, permissions: ['customer_details.read'] },
            React.createElement(CollapseContainer, null,
                React.createElement(InfoContainer, null,
                    React.createElement(InfoColumn, null,
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Customer name"),
                            React.createElement(InfoItemValue, null, customer === null || customer === void 0 ? void 0 : customer.company_name)),
                        customer.ibans ? customer.ibans.map(ibanItem => (React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null,
                                "IBAN(",
                                ibanItem.currency,
                                ")"),
                            React.createElement(InfoItemValue, null,
                                ibanItem.iban,
                                " ",
                                React.createElement("span", null, ibanItem.is_enabled ? '' : ' (disabled)'))))) : null,
                        customer.bfid
                            && (React.createElement(InfoItem, null,
                                React.createElement(InfoItemLabel, null, "BFID"),
                                React.createElement(InfoItemValue, null, customer.bfid))),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "VAT"),
                            React.createElement(InfoItemValue, null, customer.vat))),
                    React.createElement(InfoColumn, null,
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Address"),
                            React.createElement(InfoItemValue, null, customer.address)),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Zip Code"),
                            React.createElement(InfoItemValue, null, customer.zip_code)),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "City"),
                            React.createElement(InfoItemValue, null, customer.city)),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Country"),
                            React.createElement(InfoItemValue, null, customer.country)),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Name contact"),
                            React.createElement(InfoItemValue, null, customer.contact_person_name)),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Phone number"),
                            React.createElement(InfoItemValue, null, customer.phone_number))))))))));
};
const mapStateToProps = (state) => ({
    customers: state.customersSearch.customers,
    features: state.client.features,
});
export default connect(mapStateToProps)(SuborderCustomerInfo);
