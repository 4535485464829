const storage = window.localStorage;
/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-explicit-any */
class ErrorLogger {
    constructor() {
        this.errors = [];
        this.getErrors = () => storage.getItem('loggedErrors')
            ? JSON.parse(storage.getItem('loggedErrors') || '') : [];
        this.setErrors = (error, time) => {
            var _a, _b, _c;
            const errObj = {
                config: {
                    url: error.config.url,
                    data: error.config.data,
                    baseURL: error.config.baseURL,
                },
                message: error.message,
                responseData: {
                    code: (_a = error.response) === null || _a === void 0 ? void 0 : _a.data.code,
                    desc: (_b = error.response) === null || _b === void 0 ? void 0 : _b.data.desc,
                    value: (_c = error.response) === null || _c === void 0 ? void 0 : _c.data.value,
                },
                time,
            };
            storage.setItem('loggedErrors', JSON.stringify([...this.errors, errObj]));
            this.errors.push(errObj);
        };
    }
}
export default ErrorLogger;
