import { createSelector } from 'reselect';
const custodyCurrencies = (state) => state.currencies.custodyCurrencies;
const tradingCurrencies = (state) => state.currencies.tradingCurrencies;
export const getCustodyCurrencies = createSelector(custodyCurrencies, (currencies) => currencies);
export const getTradingCurrencies = createSelector(tradingCurrencies, (currencies) => currencies);
export const getCustodyCurrenciesOptions = createSelector(custodyCurrencies, (currencies) => {
    if (currencies.length === 0) {
        return [];
    }
    const result = currencies && currencies.length
        ? currencies.map((c) => ({
            value: c.code.toUpperCase(),
            label: c.ticker.toUpperCase() + ' (' + c.network.name.toLocaleUpperCase().replace(/_/g, ' ') + ')',
        }))
        : [];
    const btcIndex = result.findIndex((b) => b.value === 'BTC');
    const btc = result.splice(btcIndex, 1)[0];
    const ethIndex = result.findIndex((b) => b.value === 'ETH');
    const eth = result.splice(ethIndex, 1)[0];
    result.sort((a, b) => a.value.localeCompare(b.value));
    result.unshift(eth);
    result.unshift(btc);
    return result;
});
