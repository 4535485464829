import React, { useRef, useState, useEffect, } from 'react';
import shortid from 'shortid';
import * as Styled from '../../styling/styled';
const HitBid = ({ channel }) => {
    const [bidUpdate, setBidUpdate] = useState('');
    const prevChannel = useRef('');
    const refId = useRef(shortid.generate());
    const hitBidUpdate = (data) => {
        const { hit_bid } = data.payload;
        setBidUpdate(hit_bid);
    };
    useEffect(() => {
        const { OrderBookWS } = window;
        if (prevChannel.current !== '') {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        }
        OrderBookWS.addSubscription(channel, { callback: hitBidUpdate, componentId: refId.current });
        setBidUpdate('');
        prevChannel.current = channel;
        return () => {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        };
    }, [channel]);
    return (React.createElement(Styled.PanelBid, { className: "price_ladder_header_color" },
        bidUpdate === '' ? React.createElement("span", null, "--") : React.createElement("span", null, bidUpdate),
        React.createElement("div", { className: "price_ladder_header_text" }, "hit bid")));
};
export default HitBid;
