import { connect } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';
import ClientForm from './ClientForm';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const useStyles = makeStyles((theme) => createStyles({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    }
}));
const ClientModal = ({ open, user, clientInfo, clientFromCustomers, currentUser, selectedClient, createClient, updateClient, toggleFunc, }) => {
    const [clientCode, setClientCode] = useState('');
    const classes = useStyles();
    useEffect(() => {
        if (clientFromCustomers) {
            setClientCode(clientFromCustomers.value);
        }
        else if (selectedClient) {
            setClientCode(selectedClient.value);
        }
        else if (clientInfo) {
            setClientCode(clientInfo.code);
        }
    }, [clientInfo, clientFromCustomers, selectedClient]);
    const userName = user === 'client' ? 'Client' : 'Customer';
    return (React.createElement(Fragment, null,
        React.createElement(Dialog, { open: open, fullWidth: true, maxWidth: "md" },
            React.createElement(React.Fragment, null,
                React.createElement(DialogTitle, null,
                    currentUser ? 'Edit' : 'Create',
                    " ",
                    userName,
                    React.createElement(IconButton, { size: "small", "aria-label": "close", className: classes.closeButton, onClick: toggleFunc },
                        React.createElement(CloseIcon, { fontSize: "small" }))),
                React.createElement(Box, { p: 3 },
                    React.createElement(ClientForm, { user: user, clientDetails: currentUser, clientCode: clientCode, toggleFunc: toggleFunc, updateClient: updateClient, createClient: createClient }))))));
};
const mapStateToProps = (state) => ({
    clientInfo: state.client.clientInfo,
    selectedClient: getSelectedClientInputValue(state),
});
ClientModal.defaultProps = {
    clientFromCustomers: null,
};
export default connect(mapStateToProps)(ClientModal);
