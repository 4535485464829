import React, { useReducer, useEffect } from 'react';
import moment from 'moment';
import buildUrl from 'build-url';
import { connect } from 'react-redux';
import { MASTER_TENANT_ORDERS_URL_OPTIONS_SET_UPDATE, } from '../../../../../redux/actionTypes/masterTenantActionTypes';
import OrderFiltersContent from './OrderFiltersContent';
import { collectParamsFromFilters } from '../../../../../shared/helpers/createUrlFromParams';
import { FETCH_EXCHANGES_REQUEST, FETCH_MARKETS_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
/* eslint-disable prefer-destructuring, @typescript-eslint/prefer-regexp-exec,
  @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["ADD"] = "ADD";
    FilterActionType["RESET"] = "RESET";
    FilterActionType["DELETE"] = "DELETE";
})(FilterActionType || (FilterActionType = {}));
export const UrlOptionsUpdate = (urlOptionsHedgeOrders) => ({
    type: MASTER_TENANT_ORDERS_URL_OPTIONS_SET_UPDATE,
    urlOptionsHedgeOrders,
});
const init = () => ({
    selectedFilters: [],
});
const reducer = (state, action) => {
    switch (action.type) {
        case FilterActionType.ADD:
            return { selectedFilters: [...state.selectedFilters, action.item] };
        case FilterActionType.DELETE:
            return {
                selectedFilters: state.selectedFilters
                    .filter(f => f.label !== action.item.label),
            };
        case FilterActionType.RESET:
            return init();
        default:
            return state;
    }
};
const OrderFilters = ({ page, sortBy, setPage, clientData, rowsPerPage, sortDirection, initialValue = '', dispatchLoadMarkets, dispatchLoadExchanges, UrlOptionsUpdateDispatch, }) => {
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const buildUrlFromParams = (params, customer, pageVal = 1, isSuborder = 'false', selectedAdminClient = null, limit) => {
        const paramsNames = Object.keys(params);
        const urlOptions = {
            queryParams: {
                limit: `${limit}`,
                page: `${pageVal}`,
                is_suborder: `${isSuborder}`,
            },
        };
        let clientCode = '';
        if (selectedAdminClient && Object.prototype.hasOwnProperty.call(selectedAdminClient, 'code')) {
            clientCode = selectedAdminClient.code;
        }
        else if (selectedAdminClient) {
            clientCode = selectedAdminClient.value;
        }
        if ((selectedAdminClient) && (customer && customer.code)) {
            urlOptions.queryParams.client_code = clientCode;
            urlOptions.queryParams.customer_code = customer.code;
        }
        else if (selectedAdminClient) {
            urlOptions.queryParams.client_code = clientCode;
        }
        else if (customer && customer.code) {
            urlOptions.queryParams.customer_code = customer.code;
        }
        paramsNames.forEach((key) => {
            if (Array.isArray(params[key])) {
                if (params[key].length === 1) {
                    urlOptions.queryParams[key] = params[key].join('');
                }
                else {
                    urlOptions.queryParams[key] = params[key].join('|');
                }
            }
        });
        return buildUrl('', urlOptions);
    };
    const buildUrlOptions = (pageValue, by, direction, perPage) => {
        const collectedParams = collectParamsFromFilters(state.selectedFilters);
        const re = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
        if (collectedParams.amount_from && collectedParams.amount_to) {
            const splittedFromNumber = collectedParams.amount_from[0].split('.');
            const splittedToNumber = collectedParams.amount_to[0].split('.');
            if (splittedFromNumber.length > 1 && splittedFromNumber[1] === '') {
                const valueFrom = (splittedFromNumber[0].match(re) !== null ? splittedFromNumber[0].match(re) : '');
                collectedParams.amount_from[0] = valueFrom;
            }
            else {
                const valueFrom = (collectedParams.amount_from[0].match(re) !== null
                    ? collectedParams.amount_from[0].match(re) : '');
                collectedParams.amount_from[0] = valueFrom;
            }
            if (splittedToNumber.length > 1 && splittedToNumber[1] === '') {
                const valueTo = (splittedToNumber[0].match(re) !== null ? splittedToNumber[0].match(re) : '');
                collectedParams.amount_to[0] = valueTo;
            }
            else {
                const valueTo = (collectedParams.amount_to[0].match(re) !== null
                    ? collectedParams.amount_to[0].match(re) : '');
                collectedParams.amount_to[0] = valueTo;
            }
        }
        const defaultQueryParams = {
            sort_by: [by],
            sort_direction: [direction],
            timeoutTriggered: ['false'],
        };
        const mergedPArams = Object.assign(Object.assign({}, collectedParams), defaultQueryParams);
        const adminFilterUrl = buildUrlFromParams(mergedPArams, null, pageValue, 'false', clientData, perPage);
        return adminFilterUrl;
    };
    const addSelectedFilter = (val) => {
        const isFilter = state.selectedFilters.find(f => f.label === val.label);
        const isActionType = state.selectedFilters.find(f => f.name === 'action_type');
        if (isFilter || isActionType) {
            dispatch({ type: FilterActionType.DELETE, item: isFilter || isActionType });
        }
        dispatch({ type: FilterActionType.ADD, item: val });
    };
    const removeSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.DELETE, item: val });
    };
    const resetFilters = () => {
        dispatch({ type: FilterActionType.RESET });
    };
    const handleDateChange = (value, label, name) => {
        const item = {
            label,
            name,
            type: 'date',
            value: moment(value).isValid() ? moment(value).format() : '',
        };
        const isFilter = state.selectedFilters.find(f => f.name === name);
        if (isFilter) {
            removeSelectedFilter(item);
        }
        addSelectedFilter(item);
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            dispatchLoadMarkets();
            dispatchLoadExchanges();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        UrlOptionsUpdateDispatch(buildUrlOptions(1, sortBy, sortDirection, rowsPerPage));
        setPage(0);
    }, [state, clientData, sortBy, sortDirection, rowsPerPage]);
    useEffect(() => {
        UrlOptionsUpdateDispatch(buildUrlOptions(page + 1, sortBy, sortDirection, rowsPerPage));
    }, [page, sortBy, sortDirection, rowsPerPage]);
    return (React.createElement(OrderFiltersContent, { state: state, resetFilters: resetFilters, handleDateChange: handleDateChange, addSelectedFilter: addSelectedFilter, removeSelectedFilter: removeSelectedFilter }));
};
const mapDispatchToProps = dispatch => ({
    dispatchLoadMarkets: () => dispatch({ type: FETCH_MARKETS_REQUEST }),
    dispatchLoadExchanges: () => dispatch({ type: FETCH_EXCHANGES_REQUEST }),
    UrlOptionsUpdateDispatch: (url) => dispatch(UrlOptionsUpdate(url)),
    initialValue: '',
});
export default connect(null, mapDispatchToProps)(OrderFilters);
