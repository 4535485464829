import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import { Button, Chip, TableCell, TableRow } from '@mui/material';
import { useDispatch } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import { SUBORDERS_ORDER_REMOVE_REQUEST, SUBORDERS_ORDER_EXECUTE_REQUEST, } from '../../../../../redux/actionTypes/subOrdersActionTypes';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import { statusBadgeData } from '../../../../../config/statusBadgeData';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import statusBadgeColor from '../../../../../shared/helpers/statusBadgeColor';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { ORDERS_CANCEL_REQUEST } from '../../../../../redux/actionTypes/orderBookActionTypes';
const envSettings = getEnvSettings();
const getExchangeName = (exchanges, code) => {
    const option = exchanges.find(exchange => exchange.value === code);
    if (option) {
        return option.label;
    }
    return code;
};
const SubOrdersItem = ({ onError, features, exchanges, partialCode, subOrderItem, executingItems, onErrorMessage, calculateReminder, }) => {
    const [executing, setExecuting] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const tradingPair = `${subOrderItem.market_item.base}/${subOrderItem.market_item.quote}`;
    const requestDeleteSuborder = () => {
        dispatch({
            type: SUBORDERS_ORDER_REMOVE_REQUEST,
            uid: partialCode,
            code: subOrderItem.code,
            id: subOrderItem.id,
        });
        onError(false);
        onErrorMessage('');
    };
    const requestExecuteSuborder = () => {
        dispatch({
            type: SUBORDERS_ORDER_EXECUTE_REQUEST,
            uid: partialCode,
            code: subOrderItem.code,
        });
        onError(false);
        onErrorMessage('');
    };
    const handleCancelOrder = (code, dispatchCloseNotice) => {
        dispatch({ type: ORDERS_CANCEL_REQUEST, code, dispatchCloseNotice });
        calculateReminder();
    };
    useEffect(() => {
        if (Array.isArray(executingItems) && executingItems.length) {
            const executingItem = executingItems.findIndex(i => i === subOrderItem.code);
            if (executingItem !== -1) {
                setExecuting(true);
            }
        }
        else {
            setExecuting(false);
        }
    }, [executingItems]);
    /* eslint-disable no-nested-ternary, react/forbid-prop-types */
    return (React.createElement(TableRow, { key: subOrderItem.id },
        React.createElement(TableCell, null, tradingPair),
        React.createElement(TableCell, null,
            React.createElement(NumericFormat, { value: subOrderItem.amount, displayType: "text", thousandSeparator: envSettings.thousandDivider }),
            "\u00A0",
            subOrderItem.market_item && subOrderItem.market_item.base),
        React.createElement(TableCell, null, subOrderItem.type),
        React.createElement(TableCell, null, subOrderItem.action),
        React.createElement(TableCell, null, getExchangeName(exchanges, subOrderItem.market_item.exchange_code)),
        React.createElement(TableCell, null, subOrderItem.type === 'Limit'
            ? (React.createElement(React.Fragment, null,
                React.createElement(NumericFormat, { value: new Decimal(subOrderItem.limit_price).toFixed(2), displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                subOrderItem.market_item.quote))
            : React.createElement("span", null, "-")),
        React.createElement(TableCell, null,
            React.createElement(NumericFormat, { value: new Decimal(subOrderItem.executed.price).toFixed(2), displayType: "text", thousandSeparator: envSettings.thousandDivider })),
        React.createElement(TableCell, { style: { whiteSpace: 'nowrap' } }, subOrderItem.finished ? moment(subOrderItem.finished).format('YYYY-MM-DD') : '-----'),
        React.createElement(TableCell, null,
            React.createElement(Chip, { size: "small", label: subOrderItem.status === 'CanceledPartiallyFilled'
                    ? 'Canceled Partially Filled'
                    : subOrderItem.status, style: statusBadgeColor(statusBadgeData.orderStatusBackgroundsRegular[subOrderItem.status], statusBadgeData.orderStatusBordersRegular[subOrderItem.status], statusBadgeData.orderStatusColorsRegular[subOrderItem.status]).badge })),
        React.createElement(TableCell, null, subOrderItem.status === 'Prepared'
            ? (React.createElement(React.Fragment, null, !executing
                ? (React.createElement(React.Fragment, null,
                    React.createElement(Permissions, { features: features, permissions: ['order.update'] },
                        React.createElement(React.Fragment, null,
                            React.createElement(Button, { style: { marginBottom: '8px' }, fullWidth: true, color: "primary", variant: "contained", onClick: requestExecuteSuborder, size: "small" }, "Execute"),
                            React.createElement(Button, { fullWidth: true, variant: "contained", onClick: requestDeleteSuborder, size: "small" }, "Remove")))))
                : (React.createElement("span", null, "Executing..."))))
            : subOrderItem.status === 'Open' && subOrderItem.type !== 'Market'
                ? (React.createElement(Permissions, { features: features, permissions: ['order.update'] },
                    React.createElement(Button, { variant: "outlined", color: "error", size: "small", onClick: () => handleCancelOrder(subOrderItem.code, errorNotice) }, "cancel")))
                : null)));
};
export default SubOrdersItem;
