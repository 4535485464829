import { call, put, select, takeEvery, } from 'redux-saga/effects';
import { SUBORDERS_ORDER_REMOVE, SUBORDERS_ORDER_UPDATE, SUBORDERS_ORDER_POPULATE, SUBORDERS_ORDER_REMOVE_FAIL, SUBORDERS_ORDER_EXECUTE_FAIL, SUBORDERS_ORDER_REMOVE_REQUEST, SUBORDERS_ORDER_REMOVE_SUCCESS, SUBORDERS_ORDER_EXECUTE_REQUEST, SUBORDERS_ORDER_EXECUTE_SUCCESS, } from '../redux/actionTypes/subOrdersActionTypes';
import Messages from '../shared/helpers/errorMessages';
import showNotification from '../shared/helpers/notifications';
import * as types from '../redux/actionTypes/placeOrderActionTypes';
import { composeAnyErrorMessage } from '../shared/helpers/interceptors';
import * as placeOrderActions from '../redux/actions/placeOrderActions';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
import { apiRemoveSubOrder, apiExecuteSubOrder } from '../redux/actions/subOrdersActions';
/* eslint-disable @typescript-eslint/no-unsafe-call */
function* fetchMarkets() {
    try {
        const markets = yield call(placeOrderActions.fetchMarkets);
        const btcEur = markets.find(m => m.base === 'BTC' && m.quote === 'EUR');
        const btcUsd = markets.find(m => m.base === 'BTC' && m.quote === 'USD');
        if (btcEur) {
            yield put({ type: types.NEW_ORDER_BASE_SET_UPDATE, base: { value: 'BTC', label: 'BTC' } });
            yield put({ type: types.NEW_ORDER_QUOTE_SET_UPDATE, quote: { value: 'EUR', label: 'EUR' } });
        }
        else if (btcUsd) {
            yield put({ type: types.NEW_ORDER_BASE_SET_UPDATE, base: { value: 'BTC', label: 'BTC' } });
            yield put({ type: types.NEW_ORDER_QUOTE_SET_UPDATE, quote: { value: 'USD', label: 'USD' } });
        }
        else if (markets.length) {
            const { base } = markets[0];
            const { quote } = markets[0];
            yield put({ type: types.NEW_ORDER_BASE_SET_UPDATE, base: { value: base, label: base } });
            yield put({ type: types.NEW_ORDER_QUOTE_SET_UPDATE, quote: { value: quote, label: quote } });
        }
        yield put({ type: types.FETCH_MARKETS_SUCCESS, markets });
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.MARKETS_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.FETCH_MARKETS_FAIL, message });
    }
}
function* fetchExchanges() {
    try {
        const store = yield select();
        if (store.client.masterTenant) {
            const exchanges = yield call(placeOrderActions.fetchExchanges);
            yield put({ type: types.FETCH_EXCHANGES_SUCCESS, exchanges });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.EXCHANGES_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.FETCH_EXCHANGES_FAIL, message });
    }
}
function* postNewOrder(action) {
    try {
        if (action.type === types.NEW_ORDER_CREATE_REQUEST) {
            const data = yield call(placeOrderActions.postSubmitNewOrder, action.payload);
            yield put({ type: types.NEW_ORDER_CREATE_SUCCESS, data });
            showNotification({
                message: 'Order successfully created',
                color: 'success',
                dispatch: action.closeErrorNotice,
            });
            // todo: create logic based on order type and status
            // todo: re-implement orders update in cloned components;
        }
    }
    catch (error) {
        if (action.type === types.NEW_ORDER_CREATE_REQUEST) {
            const message = composeAnyErrorMessage(error, Messages.POST_NEW_ORDER);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: action.closeErrorNotice,
            });
            yield put({ type: OPEN_ERROR_NOTICE, message });
            yield put({ type: types.NEW_ORDER_CREATE_FAIL, message });
        }
    }
}
function* postNewPartialOrder(action) {
    try {
        if (action.type === types.NEW_PARTIAL_ORDER_CREATE_REQUEST) {
            const splitOrder = yield call(placeOrderActions.postSubmitNewPartialOrder, action.payload);
            action.callbackToggleModal();
            yield put({ type: types.NEW_PARTIAL_ORDER_CREATE_SUCCESS, splitOrder });
            yield put({ type: SUBORDERS_ORDER_POPULATE, subOrders: splitOrder.suborders });
            // todo: re-implement orders update in cloned components;
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.POST_NEW_PARTIAL_ORDER);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.NEW_PARTIAL_ORDER_CREATE_FAIL, message });
    }
}
function* closePartialOrder(action) {
    try {
        if (action.type === types.PARTIAL_ORDER_CLOSE_REQUEST) {
            const code = yield call(placeOrderActions.postClosePartialOrder, action.code);
            yield put({ type: types.PARTIAL_ORDER_CLOSE_SUCCESS, code });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.CLOSE_PARTIAL_ORDER);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.PARTIAL_ORDER_CLOSE_FAIL, message });
    }
}
function* removeSubOrder(action) {
    try {
        if (action.type === SUBORDERS_ORDER_REMOVE_REQUEST) {
            const response = yield call(apiRemoveSubOrder, action.uid, action.code);
            yield put({ type: SUBORDERS_ORDER_REMOVE_SUCCESS, response });
            yield put({ type: SUBORDERS_ORDER_REMOVE, id: action.id });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.REMOVE_SUB_ORDER);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: SUBORDERS_ORDER_REMOVE_FAIL, message });
    }
}
function* executeSubOrder(action) {
    try {
        if (action.type === SUBORDERS_ORDER_EXECUTE_REQUEST) {
            const response = yield call(apiExecuteSubOrder, action.uid, action.code);
            yield put({ type: SUBORDERS_ORDER_EXECUTE_SUCCESS, response });
            const { data } = response;
            yield put({ type: SUBORDERS_ORDER_UPDATE, code: data.code, subOrder: data });
        }
    }
    catch (error) {
        if (action.type === SUBORDERS_ORDER_EXECUTE_REQUEST) {
            const message = composeAnyErrorMessage(error, Messages.EXECUTE_SUB_ORDER);
            yield put({ type: OPEN_ERROR_NOTICE, message });
            yield put({ type: SUBORDERS_ORDER_EXECUTE_FAIL, code: action.code, message });
        }
    }
}
// eslint-disable-next-line import/prefer-default-export
export const placeOrderSagas = [
    takeEvery(types.FETCH_MARKETS_REQUEST, fetchMarkets),
    takeEvery(types.FETCH_EXCHANGES_REQUEST, fetchExchanges),
    takeEvery(types.NEW_ORDER_CREATE_REQUEST, postNewOrder),
    takeEvery(types.NEW_PARTIAL_ORDER_CREATE_REQUEST, postNewPartialOrder),
    takeEvery(types.PARTIAL_ORDER_CLOSE_REQUEST, closePartialOrder),
    takeEvery(SUBORDERS_ORDER_REMOVE_REQUEST, removeSubOrder),
    takeEvery(SUBORDERS_ORDER_EXECUTE_REQUEST, executeSubOrder),
];
