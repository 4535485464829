import React, { useState, useEffect } from 'react';
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions, FormHelperText, CircularProgress, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import Autocomplete from '@mui/lab/Autocomplete';
import TwoFAModal from '../../../../../shared/components/twoFAmodal/TwoFAModal';
import { ModalLoadingContainer, ModalSpinnerContainer } from '../../styling/style';
import { getCustodyWallets } from '../../../../../redux/selectors/custodySelectors';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const WithdrawalAddressModal = ({ open, handleNewWithdrawalAddress, toggleFunc, loading, role, loadingWallets, allWallets, allCurrencies, urlAddresses, customers, }) => {
    const [memo, setMemo] = useState('');
    const [label, setLabel] = useState('');
    const [address, setAddress] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [wallet, setWallet] = useState(null);
    const [modalTwoFAOpen, setModalTwoFAOpen] = useState(false);
    const [walletsOptions, setWalletsOptions] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [currency, setCurrency] = useState(allCurrencies.length ? allCurrencies[0] : null);
    const helperTextStyles = useHelperTextStyles();
    const fixLabel = (value) => {
        const parts = value.split('; ');
        let result = '';
        if (parts.length === 0) {
            return value;
        }
        parts.forEach((p, i) => {
            if (i === 0) {
                result += `${p.toUpperCase()} - `;
            }
            else if (i === 1) {
                result += `(${p.trim()}) `;
            }
            else if (i === 2) {
                result += `Total: ${p} `;
            }
            else if (p) {
                result += `(Available: ${p})`;
            }
        });
        return result;
    };
    const handleSave = (twoFA) => {
        const payload = {
            memo,
            label,
            address,
            totp_code: twoFA,
            currency: (currency === null || currency === void 0 ? void 0 : currency.value) || '',
            wallet_code: (wallet === null || wallet === void 0 ? void 0 : wallet.value) || '',
            customer_code: (customer === null || customer === void 0 ? void 0 : customer.value) || '',
        };
        handleNewWithdrawalAddress(payload, urlAddresses);
        setMemo('');
        setLabel('');
        setWallet(null);
        setAddress('');
        setCurrency(allCurrencies.length ? allCurrencies[0] : null);
        setCustomer(null);
        toggleTwoFAModal();
    };
    const toggleTwoFAModal = () => {
        setModalTwoFAOpen(prev => !prev);
    };
    const onFieldChange = (evt) => {
        const { value, name } = evt.target;
        if (name === 'label') {
            setLabel(value);
        }
        if (name === 'address') {
            setAddress(value);
        }
    };
    const onMemoChange = (evt) => {
        const { value } = evt.target;
        if (currency && currency.value.toLowerCase() === 'xrp') {
            // memo field should only allow uint32 values for xrp currency FFS
            if (isNaN(Number(value)) || (+value > 4294967295) || (+value < -4294967295)) {
                return;
            }
            setMemo(Math.abs(+value).toString());
        }
        else {
            setMemo(value);
        }
    };
    const handleCurrencyChange = (event, newValue) => {
        setCurrency(newValue);
    };
    const handleCustomerChange = (event, newValue) => {
        setCustomer(newValue);
    };
    const handleWalletChange = (event, newValue) => {
        setWallet(newValue);
    };
    useEffect(() => {
        if (role === 'customer') {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [role]);
    useEffect(() => {
        if (!address || !label || !currency || !customer) {
            setDisabled(true);
        }
        else {
            setDisabled(false);
        }
    }, [label, address, currency, customer]);
    useEffect(() => {
        const options = allWallets
            .map((w) => {
            var _a, _b, _c, _d, _e;
            const activeAddress = w.compliance_addresses.find(a => a.state === 'active');
            const addressText = (activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.address) || '';
            const legacyAddress = (activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.legacy_address) || '';
            const addressData = legacyAddress !== ''
                ? addressText.concat(', Legacy: ').concat(legacyAddress)
                : addressText;
            const balance = ((_a = w.balance) === null || _a === void 0 ? void 0 : _a.available) !== ((_b = w.balance) === null || _b === void 0 ? void 0 : _b.total)
                ? `${((_c = w.balance) === null || _c === void 0 ? void 0 : _c.total) || ''}; ${((_d = w.balance) === null || _d === void 0 ? void 0 : _d.available) || ''}`
                : ((_e = w.balance) === null || _e === void 0 ? void 0 : _e.total) || '';
            return {
                value: w.code,
                currency: w.currency_code || '',
                label: `${w.label}; ${addressData}; ${balance}`,
            };
        });
        setWallets(options);
    }, [allWallets]);
    useEffect(() => {
        setLoadingSpinner(true);
        const filteredWallets = wallets
            .filter((w) => w.currency.toLowerCase() === ((currency === null || currency === void 0 ? void 0 : currency.value) || '').toLowerCase())
            .map(w => (Object.assign(Object.assign({}, w), { label: fixLabel(w.label) })));
        setWalletsOptions(filteredWallets);
        setWallet(null);
        if (!loadingWallets) {
            setLoadingSpinner(false);
        }
    }, [currency, wallets, loadingWallets]);
    if (loadingSpinner || loading) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, { size: "33px" }))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
        modalTwoFAOpen ? (React.createElement(TwoFAModal, { open: modalTwoFAOpen, handleSave: handleSave, toggleFunc: toggleTwoFAModal, modalLabel: "Withdrawal address" })) : null,
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Create new Withdrawal address"),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { fullWidth: true, size: "small", name: "label", value: label, label: "Label", margin: "normal", variant: "outlined", onChange: onFieldChange, InputLabelProps: { shrink: true } }),
            React.createElement(Autocomplete, { id: "currency", size: "small", value: currency, options: allCurrencies, placeholder: "Select currency", style: { marginTop: '10px' }, onChange: handleCurrencyChange, className: "mui_autocomplete_wrap", getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Currency", variant: "outlined" }))) }),
            React.createElement(Autocomplete, { id: "wallet", size: "small", value: wallet, loading: loadingWallets, options: walletsOptions, style: { marginTop: '15px' }, onChange: handleWalletChange, className: "mui_autocomplete_wrap", getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Wallet", variant: "outlined" }))) }),
            wallet ? null : (React.createElement(FormHelperText, { className: helperTextStyles.root }, "Leave blank to create a global address")),
            (currency === null || currency === void 0 ? void 0 : currency.value) === 'XRP' || (currency === null || currency === void 0 ? void 0 : currency.value) === 'XLM' ? (React.createElement(TextField, { fullWidth: true, size: "small", name: "memo", value: memo, label: "Memo", margin: "normal", variant: "outlined", onChange: onMemoChange, style: { marginTop: '15px' }, InputLabelProps: { shrink: true } })) : null,
            React.createElement(TextField, { fullWidth: true, size: "small", name: "address", value: address, label: "Address", margin: "normal", variant: "outlined", onChange: onFieldChange, style: { marginTop: '15px' }, InputLabelProps: { shrink: true } }),
            React.createElement(Autocomplete, { id: "customer", size: "small", value: customer, options: customers, placeholder: "Select customer", style: { marginTop: '10px' }, onChange: handleCustomerChange, className: "mui_autocomplete_wrap", getOptionLabel: option => option.label, renderInput: params => (React.createElement(TextField, Object.assign({}, params, { label: "Customer", variant: "outlined" }))) })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", variant: "contained", disabled: disabled, onClick: toggleTwoFAModal }, "Create"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggleFunc() }, "Close"))));
};
const mapStateToProps = (state) => ({
    role: state.client.role,
    allWallets: getCustodyWallets(state),
    allCurrencies: getCustodyCurrenciesOptions(state),
    loadingWallets: state.custody.loadingWallets,
    customers: getCustomersSelectedInputInterface(state),
});
export default connect(mapStateToProps)(WithdrawalAddressModal);
