import { call, put, takeLatest, select, take, race, delay, } from 'redux-saga/effects';
import Messages from '../shared/helpers/errorMessages';
import * as clientActions from '../redux/actions/clientActions';
import * as types from '../redux/actionTypes/clientActionTypes';
import { composeAnyErrorMessage } from '../shared/helpers/interceptors';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
function* fetchClients(action) {
    try {
        if (action.type === types.CLIENTS_FETCH_REQUEST) {
            const clients = yield call(clientActions.fetchClients);
            yield put({ type: types.CLIENTS_FETCH_SUCCESS, clients });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.CLIENTS_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.CLIENTS_FETCH_FAIL, message });
    }
}
function* fetchClientBalances(action) {
    try {
        if (action.type === types.CLIENT_BALANCES_FETCH_REQUEST) {
            const balances = yield call(clientActions.fetchClientBalances, action.clientCode);
            yield put({ type: types.CLIENT_BALANCES_FETCH_SUCCESS, balances });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.CLIENT_BALANCES_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.CLIENT_BALANCES_FETCH_FAIL, message });
    }
}
function* fetchCustomer(action) {
    try {
        if (action.type === types.CUSTOMER_INFO_FETCH_REQUEST) {
            const store = yield select();
            const { features } = store.client;
            const canReadDetails = features.includes('customer_details.read');
            const customer = yield call(clientActions.fetchCustomerInfo, action.code, canReadDetails);
            yield put({ type: types.CUSTOMER_INFO_FETCH_SUCCESS, customer });
            yield put({ type: types.CUSTOMER_BALANCES_FETCH_REQUEST, customerCode: action.code });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.CUSTOMER_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.CUSTOMER_INFO_FETCH_FAIL, message });
    }
}
function* fetchCustomerBalances(action) {
    try {
        if (action.type === types.CUSTOMER_BALANCES_FETCH_REQUEST) {
            const balances = yield call(clientActions.fetchCustomerBalances, action.customerCode);
            yield put({ type: types.CUSTOMER_BALANCES_FETCH_SUCCESS, balances });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.CUSTOMER_BALANCES_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.CUSTOMER_BALANCES_FETCH_FAIL, message });
    }
}
function* fetchExchangesBalances() {
    try {
        const store = yield select();
        if (store.client.masterTenant) {
            const exchangesBalances = yield call(clientActions.fetchExchangesBalances);
            yield put({ type: types.EXCHANGES_BALANCES_FETCH_SUCCESS, exchangesBalances });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.EXCHANGE_BALANCES_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.EXCHANGES_BALANCES_FETCH_FAIL, message });
    }
}
function* pollExchangesBalances() {
    while (true) {
        try {
            const store = yield select();
            const { exchangesBalancesLoading, masterTenant } = store.client;
            if (masterTenant) {
                const exchangesBalances = yield call(clientActions.fetchExchangesBalances);
                yield put({
                    type: types.EXCHANGES_BALANCES_FETCH_SUCCESS,
                    exchangesBalances,
                });
            }
            if (!exchangesBalancesLoading) {
                yield delay(15000);
            }
        }
        catch (error) {
            const message = composeAnyErrorMessage(error, Messages.EXCHANGE_BALANCES_UPDATE);
            yield put({ type: OPEN_ERROR_NOTICE, message });
            yield put({
                type: types.EXCHANGES_BALANCES_FETCH_FAIL,
                message,
            });
            yield put({ type: 'STOP_EXCHANGES_BALANCES_POLLING', error });
        }
    }
}
function* pollTaskWatcher() {
    while (true) {
        yield take('START_EXCHANGES_BALANCES_POLLING');
        yield race([call(pollExchangesBalances), take('STOP_EXCHANGES_BALANCES_POLLING')]);
    }
}
export const clientSagas = [
    takeLatest(types.CLIENTS_FETCH_REQUEST, fetchClients),
    takeLatest(types.CLIENT_BALANCES_FETCH_REQUEST, fetchClientBalances),
    takeLatest(types.CUSTOMER_INFO_FETCH_REQUEST, fetchCustomer),
    takeLatest(types.CUSTOMER_BALANCES_FETCH_REQUEST, fetchCustomerBalances),
    takeLatest(types.EXCHANGES_BALANCES_FETCH_REQUEST, fetchExchangesBalances),
    pollTaskWatcher(),
];
