import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Messages from '../../../shared/helpers/errorMessages';
import showNotification from '../../../shared/helpers/notifications';
import RegisterForm from '../../../shared/components/login/RegisterForm';
import { composeErrorMessage } from '../../../shared/helpers/interceptors';
import { getEnvSettings } from '../../../config/environmentSettings';
import { FormLogo } from '../../../shared/components/login/styling/styledComponents';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { Box, Button, Link, Paper, Typography } from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
/* eslint-disable camelcase, no-restricted-syntax, @typescript-eslint/restrict-template-expressions */
const apiDltUrl = getEnvSettings().apiUrl;
const finoaEnv = getEnvSettings().env === 'finoa';
axios.defaults.baseURL = apiDltUrl;
const fields = {
    email: '',
    userName: '',
    password: '',
    twoFACode: '',
    confirmPassword: '',
};
const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateFields = (fieldsToValidate) => {
    const errors = [];
    for (const [key, value] of Object.entries(fieldsToValidate)) {
        if (String(value).trim() === '') {
            errors.push(key);
        }
        else if (key === 'email') {
            if (!regEx.test(String(value).toLowerCase())) {
                errors.push(key);
            }
        }
        else if (key === 'twoFACode' && String(value).length !== 6) {
            errors.push(key);
        }
    }
    return errors;
};
const RegisterComponent = () => {
    const { ErrorLogger } = window;
    const dirty = useRef(false);
    const [email, setEmail] = useState('');
    const [invite, setInvite] = useState('');
    const [qrData, setQrData] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [twoFACode, setTwoFACode] = useState('');
    const [totpSecret, setTotpSecret] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [validation, setValidation] = useState([]);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formFields, setFormFields] = useState([]);
    const [inviteExpired, setInviteExpired] = useState();
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleSubmit = (evt) => {
        evt.preventDefault();
        dirty.current = true;
        const errors = validateFields(formFields);
        if (errors.length) {
            setValidation(() => [...errors]);
            return;
        }
        const payload = {
            email,
            name: userName,
            invite_code: invite,
            password,
            totp_code: twoFACode,
        };
        const apipath = `${String(getEnvSettings().frontendApiUrl)}/registration`;
        axios
            .post(apipath, payload)
            .then(() => {
            window.location.href = '/login';
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
            const message = composeErrorMessage(e, Messages.REGISTER_USER);
            setErrorMsg(message);
        });
    };
    const getInviteCode = () => {
        const url = window.location.href;
        let inviteCode = '';
        if (url) {
            const index = url.indexOf('invite=');
            if (index === -1) {
                return '';
            }
            inviteCode = url.slice(index + 7, url.length);
        }
        return inviteCode;
    };
    const fetchInvite = (code) => {
        const apipath = `${String(getEnvSettings().frontendApiUrl)}/invites/${code}`;
        axios
            .get(apipath)
            .then((response) => {
            const { totp_secret } = response.data;
            setTotpSecret(totp_secret);
            setInviteExpired(false);
        })
            .catch((e) => {
            var _a;
            if (((_a = e.response) === null || _a === void 0 ? void 0 : _a.data.code) === 9016) {
                setInviteExpired(true);
            }
            ErrorLogger.setErrors(e, new Date());
            const message = composeErrorMessage(e, Messages.REGISTER_INVITE_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const onFieldChange = (evt) => {
        const { value, name } = evt.target;
        if (name === 'email') {
            setEmail(value);
        }
        if (name === 'userName') {
            setUserName(value);
        }
        if (name === 'password') {
            setPassword(value);
        }
        if (name === 'confirmPassword') {
            setConfirmPassword(value);
        }
        if (name === 'twoFACode' && Number.isNaN(Number(value))) {
            return;
        }
        if (name === 'twoFACode') {
            setTwoFACode(value);
        }
        fields[name] = value;
        setFormFields(Object.assign(Object.assign({}, formFields), fields));
    };
    const validateEmail = (str) => regEx.test(String(str).toLowerCase());
    useEffect(() => {
        if (dirty.current) {
            setValidation(() => [...validateFields(formFields)]);
        }
    }, [formFields]);
    useEffect(() => {
        const validEmail = validateEmail(email);
        if (validEmail &&
            userName &&
            password &&
            confirmPassword &&
            twoFACode.length === 6 &&
            password === confirmPassword) {
            setDisableButton(false);
        }
        else {
            setDisableButton(true);
        }
    }, [email, userName, password, confirmPassword, twoFACode]);
    useEffect(() => {
        if (password !== confirmPassword) {
            setErrorMessage('Password and Confirm password must match');
        }
        else {
            setErrorMessage('');
        }
    }, [password, confirmPassword]);
    useEffect(() => {
        const code = getInviteCode();
        setInvite(code);
        if (code) {
            fetchInvite(code);
        }
    }, []);
    useEffect(() => {
        const qrString = `otpauth://totp/DLTM:${email}?secret=${totpSecret}&issuer=DLTM`;
        setQrData(qrString);
    }, [email, totpSecret]);
    useEffect(() => {
        const validEmail = validateEmail(email);
        setIsValidEmail(validEmail);
    }, [email]);
    if (inviteExpired === undefined) {
        return;
    }
    return (React.createElement("div", { className: 'account' },
        React.createElement("div", { className: 'account__head' },
            React.createElement(FormLogo, { className: finoaEnv ? 'form_logo_finoa' : 'form_logo' })),
        !inviteExpired ? (React.createElement("div", { className: 'account__wrapper' },
            React.createElement(RegisterForm, { qrData: qrData, fields: fields, errorMsg: errorMsg, validation: validation, handleSubmit: handleSubmit, errorMessage: errorMessage, isValidEmail: isValidEmail, disableButton: disableButton, onFieldChange: onFieldChange }))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'account__wrapper' },
                React.createElement(Paper, null,
                    React.createElement(Box, { maxWidth: '620px', p: 2, gap: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' },
                        React.createElement(Box, null,
                            React.createElement(HourglassBottomIcon, { fontSize: 'large' })),
                        React.createElement(Typography, { textAlign: 'center', variant: 'h6' },
                            "We are sorry, your verification link has expired. ",
                            React.createElement("br", null),
                            "Please reach out to",
                            React.createElement(Link, { href: 'mailto:support@dlt-finance.com' }, "support@dlt-finance.com"),
                            " to request a new one."),
                        React.createElement(Button, { href: '/login', color: 'info' }, "To Login"))))))));
};
export default RegisterComponent;
