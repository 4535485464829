export const FETCH_MARKETS_REQUEST = 'FETCH_MARKETS_REQUEST';
export const FETCH_MARKETS_SUCCESS = 'FETCH_MARKETS_SUCCESS';
export const FETCH_MARKETS_FAIL = 'FETCH_MARKETS_FAIL';
export const FETCH_EXCHANGES_REQUEST = 'FETCH_EXCHANGES_REQUEST';
export const FETCH_EXCHANGES_SUCCESS = 'FETCH_EXCHANGES_SUCCESS';
export const FETCH_EXCHANGES_FAIL = 'FETCH_EXCHANGES_FAIL';
export const NEW_ORDER_BASE_SET_UPDATE = 'NEW_ORDER_BASE_SET_UPDATE';
export const NEW_ORDER_QUOTE_SET_UPDATE = 'NEW_ORDER_QUOTE_SET_UPDATE';
export const NEW_ORDER_EXCHANGE_SET_UPDATE = 'NEW_ORDER_EXCHANGE_SET_UPDATE';
export const NEW_ORDER_EXCHANGE_UNAVAILABLE = 'NEW_ORDER_EXCHANGE_UNAVAILABLE';
export const NEW_ORDER_TYPE_SET_UPDATE = 'NEW_ORDER_TYPE_SET_UPDATE';
export const NEW_ORDER_AMOUNT_SET_UPDATE = 'NEW_ORDER_AMOUNT_SET_UPDATE';
export const NEW_ORDER_REFERENCE_ID_SET_UPDATE = 'NEW_ORDER_REFERENCE_ID_SET_UPDATE';
export const NEW_ORDER_LIMIT_PRICE_UPDATE = 'NEW_ORDER_LIMIT_PRICE_UPDATE';
export const NEW_ORDER_TOTAL_PRICE_UPDATE = 'NEW_ORDER_TOTAL_PRICE_UPDATE';
export const SUBORDER_MODAL_TOGGLE = 'SUBORDER_MODAL_TOGGLE';
export const SET_UPDATE_PARTIAL_TYPE = 'SET_UPDATE_PARTIAL_TYPE';
export const NEW_ORDER_CREATE_REQUEST = 'NEW_ORDER_CREATE_REQUEST';
export const NEW_ORDER_CREATE_SUCCESS = 'NEW_ORDER_CREATE_SUCCES';
export const NEW_ORDER_CREATE_FAIL = 'NEW_ORDER_CREATE_FAIL';
export const NEW_PARTIAL_ORDER_CREATE_REQUEST = 'NEW_PARTIAL_ORDER_CREATE_REQUEST';
export const NEW_PARTIAL_ORDER_CREATE_SUCCESS = 'NEW_PARTIAL_ORDER_CREATE_SUCCESS';
export const NEW_PARTIAL_ORDER_CREATE_FAIL = 'NEW_PARTIAL_ORDER_CREATE_FAIL';
export const PARTIAL_ORDER_CLOSE_REQUEST = 'PARTIAL_ORDER_CLOSE_REQUEST';
export const PARTIAL_ORDER_CLOSE_SUCCESS = 'PARTIAL_ORDER_CLOSE_SUCCESS';
export const PARTIAL_ORDER_CLOSE_FAIL = 'PARTIAL_ORDER_CLOSE_FAIL';
export const TRADING_CURRENCIES_UPDATE = 'TRADING_CURRENCIES_UPDATE';
