import React, { useEffect, useRef, useState } from 'react';
import shortid from 'shortid';
import { compose } from 'redux';
import Decimal from 'decimal.js';
import { connect, useDispatch } from 'react-redux';
import { NEW_ORDER_AMOUNT_SET_UPDATE, NEW_ORDER_LIMIT_PRICE_UPDATE, NEW_ORDER_TOTAL_PRICE_UPDATE, NEW_ORDER_REFERENCE_ID_SET_UPDATE, } from '../../../../../../redux/actionTypes/placeOrderActionTypes';
import calculateMarketPriceFromAmount from './calculateMarketPriceFromAmount';
import calculateMarketAmountFromPrice from './calculateMarketAmountFromPrice';
import { TextField } from '@mui/material';
const SelectPricingAmount = ({ isPartial, totalPrice, action, amount, limitPrice, updateTooShallow, selectedPair, orderType, validation, referenceId, selectedExchange, }) => {
    const prevChannel = useRef('');
    const refId = useRef(shortid.generate());
    const [lastBids, setLastBids] = useState([]);
    const [lastAsks, setLastAsks] = useState([]);
    const [availableAmount, setAvailableAmount] = useState('0');
    const [availableEstPrice, setAvailableEstPrice] = useState('0');
    const [tooShallowAmount, setTooShallowAmount] = useState(false);
    const [tooShallowPrice, setTooShallowPrice] = useState(false);
    const dispatch = useDispatch();
    const resetAmountPrice = (bids, asks) => {
        if (bids.length === 0 && asks.length === 0) {
            return;
        }
        let sumAmount = new Decimal(0);
        let sumEstPrice = new Decimal(0);
        if (action === 'Sell') {
            for (const bid of bids) {
                sumAmount = sumAmount.add(bid.volume);
                sumEstPrice = sumEstPrice.add(Decimal.mul(bid.price, bid.volume));
            }
        }
        else {
            for (const ask of asks) {
                sumAmount = sumAmount.add(ask.volume);
                sumEstPrice = sumEstPrice.add(Decimal.mul(ask.price, ask.volume));
            }
        }
        setAvailableAmount(sumAmount.toFixed());
        setAvailableEstPrice(sumEstPrice.toFixed());
    };
    const placeOrderUpdate = (data) => {
        const { bids, asks } = data.payload;
        if (!isPartial && (orderType === null || orderType === void 0 ? void 0 : orderType.value) !== 'Limit') {
            resetAmountPrice(bids, asks);
        }
        setLastBids(bids);
        setLastAsks(asks);
    };
    useEffect(() => {
        const { OrderBookWS } = window;
        if (selectedPair && selectedExchange && (selectedExchange === null || selectedExchange === void 0 ? void 0 : selectedExchange.value) !== '') {
            const pair = `${selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base}/${selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote}`.toLowerCase();
            const channel = `orderbook-simple-${selectedExchange === null || selectedExchange === void 0 ? void 0 : selectedExchange.value}-${pair}`;
            if (prevChannel.current !== '') {
                OrderBookWS.removeSubscription(prevChannel.current, refId.current);
            }
            prevChannel.current = channel;
            setLastBids([]);
            setLastAsks([]);
            OrderBookWS.addSubscription(channel, { callback: placeOrderUpdate, componentId: refId.current });
        }
        return () => {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        };
    }, [selectedExchange, selectedPair, isPartial, orderType]);
    const handleAmountChange = (evt) => {
        const { value } = evt.target;
        // eslint-disable-next-line no-debugger
        if (value.trim() === '') {
            dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: '' });
            dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: '' });
            return;
        }
        if (isNaN(Number(value))) {
            return;
        }
        if (new Decimal(value).greaterThan(availableAmount) && !isPartial && (orderType === null || orderType === void 0 ? void 0 : orderType.value) !== 'Limit') {
            setTooShallowAmount(true);
            updateTooShallow(true);
            dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: value });
            dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: '' });
            validation(value, limitPrice, orderType === null || orderType === void 0 ? void 0 : orderType.value, isPartial);
        }
        else {
            setTooShallowAmount(false);
            updateTooShallow(false);
            const totalResult = calculateMarketPriceFromAmount(value, lastBids, lastAsks, action, dispatch);
            const re = /^-?\d+(?:\.\d{0,2})?/;
            const totalResultFormattedArr = re.exec(totalResult);
            const totalResultFormatted = totalResultFormattedArr ? totalResultFormattedArr[0] : '0';
            const finalTotal = totalResultFormatted === '0' ? '' : totalResultFormatted;
            validation(value, limitPrice, orderType === null || orderType === void 0 ? void 0 : orderType.value, isPartial);
            dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: value });
            dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: finalTotal });
        }
    };
    const handleReferenceIdChange = (evt) => {
        const { value } = evt.target;
        dispatch({ type: NEW_ORDER_REFERENCE_ID_SET_UPDATE, referenceId: value });
    };
    const handlePriceChange = (evt) => {
        const { value } = evt.target;
        if (value.trim() === '') {
            dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: '' });
            dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: '' });
            return;
        }
        if (isNaN(Number(value))) {
            return;
        }
        const amountTotal = calculateMarketAmountFromPrice(value, lastBids, lastAsks, action, dispatch);
        if (new Decimal(value).greaterThan(availableEstPrice) && !isPartial && (orderType === null || orderType === void 0 ? void 0 : orderType.value) !== 'Limit') {
            validation('', limitPrice, orderType === null || orderType === void 0 ? void 0 : orderType.value, isPartial);
            dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: '' });
            dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: value });
            setTooShallowPrice(true);
        }
        else if (new Decimal(amountTotal).isNaN()) {
            validation('', limitPrice, orderType === null || orderType === void 0 ? void 0 : orderType.value, isPartial);
            dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: '' });
            dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: '' });
        }
        else {
            setTooShallowPrice(false);
            validation(String(amountTotal), limitPrice, orderType === null || orderType === void 0 ? void 0 : orderType.value, isPartial);
            dispatch({ type: NEW_ORDER_TOTAL_PRICE_UPDATE, totalPrice: value });
            dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: new Decimal(amountTotal).toFixed(4) });
        }
    };
    const handleLimitPriceChange = (evt) => {
        const { value } = evt.target;
        if (isNaN(Number(value))) {
            return;
        }
        validation(amount, value, orderType === null || orderType === void 0 ? void 0 : orderType.value, isPartial);
        dispatch({ type: NEW_ORDER_LIMIT_PRICE_UPDATE, limitPrice: value });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement(TextField, { fullWidth: true, size: "small", variant: "outlined", required: true, inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' }, name: `${action}Amount`, value: amount, label: "Amount", onChange: handleAmountChange, placeholder: "0", error: tooShallowPrice, helperText: tooShallowPrice ? 'Orderbook is too shallow' : '', InputProps: { 'endAdornment': selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base } })))),
        !isPartial
            && (React.createElement(React.Fragment, null,
                (orderType === null || orderType === void 0 ? void 0 : orderType.value) === 'Market'
                    && (React.createElement("div", null,
                        React.createElement("div", null,
                            React.createElement("div", null,
                                React.createElement(TextField, { variant: "outlined", size: "small", label: "est. Total without fees", fullWidth: true, inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' }, name: `${action}Price`, value: totalPrice, onChange: handlePriceChange, placeholder: "0", error: tooShallowAmount, InputProps: { endAdornment: selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote }, helperText: tooShallowAmount ? 'Orderbook is too shallow' : '' }))))),
                (orderType === null || orderType === void 0 ? void 0 : orderType.value) === 'Limit'
                    && (React.createElement("div", null,
                        React.createElement("div", null,
                            React.createElement(TextField, { variant: "outlined", fullWidth: true, size: "small", label: `${action === 'Buy'
                                    ? 'Maximum'
                                    : 'Minimum'} price ${selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote} per ${selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base}`, inputProps: { pattern: '^(?:[1-9]\d*|0)?(?:\.\d+)?$' }, InputProps: { endAdornment: selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote }, name: `${action}Limit`, value: limitPrice, required: true, error: !limitPrice, onChange: handleLimitPriceChange, placeholder: "0" })))))),
        React.createElement("div", null,
            React.createElement(TextField, { fullWidth: true, variant: "outlined", size: "small", label: "Reference ID", name: "referenceId", value: referenceId, onChange: handleReferenceIdChange, placeholder: "" }))));
};
const stateToProps = (state) => {
    const { amount, totalPrice, selectedPair, selectedExchange, limitPrice, orderType, referenceId, } = state.placeOrder;
    return {
        amount,
        orderType,
        totalPrice,
        limitPrice,
        referenceId,
        selectedPair,
        selectedExchange,
    };
};
export default compose(connect(stateToProps))(SelectPricingAmount);
