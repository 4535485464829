import { Box } from '@mui/material';
import React from 'react';
import CopyValue from '../../../shared/components/CopyValueComponent';
const ProfileCode = ({ title, code }) => {
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column' },
        React.createElement("span", null, title),
        React.createElement(Box, { display: 'flex', style: { fontSize: '0.7rem' }, justifyContent: 'space-between', alignItems: 'center' },
            code,
            " ",
            React.createElement(CopyValue, { value: code }))));
};
export default ProfileCode;
