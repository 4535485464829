export var Status;
(function (Status) {
    Status["ASSIGNED"] = "assigned";
    Status["UNASIGNED"] = "unassigned";
})(Status || (Status = {}));
export var StatusActions;
(function (StatusActions) {
    StatusActions["ASSIGN"] = "assign";
    StatusActions["UNASIGN"] = "unassign";
})(StatusActions || (StatusActions = {}));
