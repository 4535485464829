import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ClientsContainer from '../../../Dashboards/Admin/components/Clients/main';
import Customers from '../../../Dashboards/Admin/components/Clients/Customers/main';
import ClientInfo from '../../../Dashboards/Admin/components/Clients/Details/main';
import WebhooksContainer from '../../../Dashboards/Admin/components/Webhooks/main';
import ClientDetailsContainer from '../../../Dashboards/Admin/components/Clients/ClientDetailsContainer';
import CustomerDetailsContainer from '../../../Dashboards/Admin/components/Clients/Customers/CustomerDetailsContainer';
import Dashboard from '../../../Dashboards/Dashboard';
import Balances from '../../../Dashboards/Crypto/components/Balances';
export default function ManageRoot(props) {
    const { features } = props;
    const ordersEnabled = features && (features === null || features === void 0 ? void 0 : features.length) > 0 && (features === null || features === void 0 ? void 0 : features.includes('order.read'));
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: '/manage' },
            React.createElement(Redirect, { exact: true, to: '/manage/clients' })),
        React.createElement(Route, { path: '/manage/logbook', component: Dashboard }),
        React.createElement(Route, { path: '/manage/webhooks', component: WebhooksContainer }),
        React.createElement(Route, { exact: true, path: '/manage/customers', component: Customers }),
        React.createElement(Route, { path: '/manage/customers/:id', component: CustomerDetailsContainer }),
        React.createElement(Route, { exact: true, path: '/manage/clients', component: ClientsContainer }),
        React.createElement(Route, { path: '/manage/clients/:id', component: ClientDetailsContainer }),
        React.createElement(Route, { path: '/manage/client-info', component: ClientInfo }),
        React.createElement(Route, { exact: true, path: '/manage/users', component: Dashboard }),
        React.createElement(Route, { path: '/manage/permissions', component: Dashboard }),
        React.createElement(Route, { path: '/manage/balances', component: Balances }),
        React.createElement(Route, { path: '*' },
            React.createElement(Redirect, { to: '/404' }))));
}
