import React, { useState } from 'react';
import Decimal from 'decimal.js';
import { Box, Chip } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { NumericFormat } from 'react-number-format';
import SuborderModal from '../../Suborders/main';
import { statusBadgeData } from '../../../../../../config/statusBadgeData';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import statusBadgeColor from '../../../../../../shared/helpers/statusBadgeColor';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const envSettings = getEnvSettings();
const PartialOrderItem = ({ orderItem }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const actionClass = orderItem.action === 'Buy' ? 'marketItem_bid_colors' : 'marketItem_ask_colors';
    const percentage = (new Decimal(orderItem.executed.amount)
        .mul(new Decimal(100))).div(new Decimal(orderItem.amount)).toFixed(2);
    const idColumn = orderItem.code;
    const idColumnSubstring = `${idColumn.substring(0, 7)}...`;
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, { onClick: toggleModal, className: "partial_order_item_wrap" },
            React.createElement(TableCell, null, idColumnSubstring),
            React.createElement(TableCell, { className: actionClass }, orderItem.action),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { value: new Decimal(orderItem.amount).valueOf(), displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                "\u00A0",
                orderItem.market_item.base),
            React.createElement(TableCell, null,
                React.createElement(Box, { display: 'flex' },
                    React.createElement(Chip, { label: orderItem.market_item.base, size: "small" }),
                    React.createElement(ChevronRightIcon, null),
                    React.createElement(Chip, { label: orderItem.market_item.quote, size: "small" }))),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { value: new Decimal(orderItem.executed.amount).valueOf(), displayType: "text", thousandSeparator: envSettings.thousandDivider })),
            React.createElement(TableCell, null, `${percentage} %`),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { value: new Decimal(orderItem.executed.price).valueOf(), displayType: "text", thousandSeparator: envSettings.thousandDivider })),
            React.createElement(TableCell, null,
                React.createElement(Chip, { size: "small", label: orderItem.status === 'CanceledPartiallyFilled' ? 'Canceled Partially Filled' : orderItem.status, style: statusBadgeColor(statusBadgeData.orderStatusBackgrounds[orderItem.status], statusBadgeData.orderStatusBorders[orderItem.status], statusBadgeData.orderStatusColors[orderItem.status]).badge }))),
        modalOpen && React.createElement(SuborderModal, { open: modalOpen, toggleFunc: toggleModal, splitCode: orderItem.code })));
};
export default PartialOrderItem;
