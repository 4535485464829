import React from 'react';
import { connect } from 'react-redux';
import WidgetStats from './Stats/WidgetStats';
import { PanelContainer } from '../../../Admin/styling/style';
/* eslint-disable no-nested-ternary, camelcase */
const Compliance = ({ clientInfo, masterTenant }) => {
    const regularClient = masterTenant
        ? { label: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name, value: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code } : null;
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" }, regularClient ? (React.createElement(WidgetStats, null)) : null)));
};
const mapStateToProps = (state) => ({
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
});
export default connect(mapStateToProps)(Compliance);
