import React, { createContext, useContext } from 'react';
import useCurrenciesService from './CurrenciesService';
const CurrecniesContext = createContext(undefined);
export const CurrenciesProvider = ({ children }) => {
    const currenciesService = useCurrenciesService();
    return React.createElement(CurrecniesContext.Provider, { value: currenciesService }, children);
};
export const useCurrencies = () => {
    const context = useContext(CurrecniesContext);
    if (context === undefined) {
        throw new Error('useCurrencies must be used within a CurrenciesProvider');
    }
    return context;
};
