/* eslint-disable no-return-assign, react/prefer-stateless-function */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import { Box } from '@mui/material';
import SideNavigationComponent, { sideMenuWidthCollapsed } from './SideNavigation/SideNavigationComponent';
const Layout = (props) => {
    const [collapsed, setCollapsed] = useState();
    const { children, withPadding } = props;
    const handleCollapse = (e) => {
        setCollapsed(e);
    };
    useEffect(() => {
    }, []);
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', height: '100%' } },
        React.createElement(TopbarWithNavigation, null),
        React.createElement(Box, { sx: { display: 'flex', paddingTop: withPadding ? '120px' : '60px', height: '100%' } },
            React.createElement(SideNavigationComponent, { onCollapse: handleCollapse }),
            React.createElement(Box, { sx: {
                    paddingLeft: !collapsed ? '200px' : sideMenuWidthCollapsed + 'px',
                    width: '100%',
                    height: '100%',
                } }, children))));
};
export default withRouter(Layout);
