import React, { useCallback, useState } from 'react';
import { Box, Button, TextField, CircularProgress, } from '@mui/material';
import { useDispatch } from 'react-redux';
import Messages from '../../../../../../shared/helpers/errorMessages';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import WithdrawalsAddressesService from '../../../../../../services/withdrawalsAddressesService';
export const AddressDelete = ({ item, toggle, cellAction, actionKey, }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleDelete = (code) => {
        const payload = {
            state: 'pending_admin_delete',
        };
        const params = {
            url: `/custody/withdrawals/addresses/${code}`,
            method: actionKey === 'deleteitem' ? 'post' : 'delete',
            data: actionKey === 'deleteitem' ? payload : null,
            baseURL: actionKey === 'deleteitem' ? getEnvSettings().apiUrl : getEnvSettings().adminApiUrl,
        };
        const addressesService = new WithdrawalsAddressesService(params);
        setLoading(true);
        addressesService.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction(Object.assign({}, item, {
                state: actionKey === 'confirm'
                    ? 'approve_delete'
                    : 'pending_admin_delete',
            }));
            showNotification({
                message: 'Address successfully removed',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_REMOVE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "Are you sure you want to delete Withdrawal address:\u00A0",
            React.createElement("span", { className: "forms_uuid_container" }, item.address)),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "primary", variant: "contained", onClick: () => handleDelete(item.code) }, "Yes"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Cancel"))));
};
export const AddressSign = ({ item, toggle, cellAction, actionKey, }) => {
    const dispatch = useDispatch();
    const [twoFA, setTwoFA] = useState('');
    const [loading, setLoading] = useState(false);
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onTwoFAChange = (evt) => {
        evt.preventDefault();
        const { value } = evt.target;
        if (isNaN(Number(value)) || value.length > 6) {
            return;
        }
        setTwoFA(value);
    };
    const handleSign = (totp, code) => {
        const states = {
            sign: 'pending_sign',
            mastersign: 'pending_admin_approve',
            approve: 'approved',
        };
        const payload = {
            code,
            totp_code: totp,
        };
        const service = new WithdrawalsAddressesService({
            url: actionKey === 'approve'
                ? `/custody/client/withdrawals/addresses/${actionKey}`
                : `/custody/withdrawals/addresses/${actionKey}`,
            method: 'post',
            data: payload,
            baseURL: actionKey === 'approve'
                ? getEnvSettings().adminApiUrl : getEnvSettings().apiUrl,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction(Object.assign({}, item, { state: states[actionKey] }));
            showNotification({
                message: 'Address successfully signed',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_SIGN);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "You're approving to withdrawal address:\u00A0",
            React.createElement("span", { className: "forms_uuid_container" }, item.address)),
        React.createElement(TextField, { fullWidth: true, size: "small", value: twoFA, label: "Two FA", margin: "normal", variant: "outlined", onChange: onTwoFAChange, InputLabelProps: { shrink: true } }),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "primary", variant: "contained", disabled: !twoFA || twoFA.length !== 6, onClick: () => handleSign(twoFA, item.code) }, "Approve"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Cancel"))));
};
export const ManualAmlCheck = ({ item, toggle }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleAml = (code) => {
        const service = new WithdrawalsAddressesService({
            url: `/custody/client/withdrawals/addresses/${code}/aml/manual`,
            method: 'post',
            baseURL: getEnvSettings().adminApiUrl,
        });
        service.makeRequest()
            .then(() => {
            showNotification({
                message: 'Address successfully added to manual AML check list',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_AML);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "You're adding to manual AML check list:\u00A0",
            React.createElement("span", { className: "forms_uuid_container" }, item.address)),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "primary", variant: "contained", onClick: () => handleAml(item.code) }, "Add to AML"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Cancel"))));
};
