import { call, put, select, takeLatest } from 'redux-saga/effects';
import Messages from '../shared/helpers/errorMessages';
import * as clientActions from '../redux/actions/clientActions';
import * as types from '../redux/actionTypes/userActionTypes';
import * as clientTypes from '../redux/actionTypes/clientActionTypes';
import * as customerTypes from '../redux/actionTypes/customerSearchActionsTypes';
import { composeAnyErrorMessage } from '../shared/helpers/interceptors';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
function* fetchUser() {
    var _a;
    try {
        const user = yield call(clientActions.fetchUserInfo);
        if (!user.client) {
            throw new Error('User info is not available');
        }
        const masterTenant = user.client.code === user.client.deposit_holder_code;
        yield put({
            type: types.USER_INFO_FETCH_SUCCESS,
            role: user.role,
            meCode: user.code,
            client: user.client,
            features: user.features,
            clientName: user.display_name,
            customer_code: user.customer_code || '',
            masterTenant,
        });
        const canReadCustomerDetails = ((_a = user.features) === null || _a === void 0 ? void 0 : _a.includes('customer_details.read')) || false;
        const store = yield select();
        const selectedClient = store.clientSearch.selectedClient;
        if (masterTenant) {
            yield put({ type: clientTypes.CLIENTS_FETCH_REQUEST });
            yield put({
                type: customerTypes.CUSTOMER_SEARCH_FETCH_REQUEST,
                clientCode: selectedClient || user.client.code,
                details: canReadCustomerDetails,
            });
        }
        else {
            yield put({
                type: customerTypes.CUSTOMER_SEARCH_FETCH_REQUEST,
                client_code: user.code,
                details: canReadCustomerDetails,
            });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.USER_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.USER_INFO_FETCH_FAIL, message });
    }
}
export const userSagas = [takeLatest(types.USER_INFO_FETCH_REQUEST, fetchUser)];
