import buildUrl from 'build-url';
// accepts Array: Settings
// returns Array: Params
export const collectParamsFromSettings = (widgetSettings, settingIdInclude) => {
    // remove empty or false values
    const itemsWithValues = widgetSettings.filter(s => s && s.id.includes(settingIdInclude))
        .filter((setting) => {
        if (setting.type === 'select') {
            return true;
        }
        if (setting.value) {
            return true;
        }
        return false;
    });
    // group by param name
    return itemsWithValues.reduce((res, current) => {
        if (current.name) {
            if (current.type === 'checkbox') {
                res[current.name] = [...(res[current.name] || []), current.label];
            }
            else {
                res[current.name] = [...(res[current.name] || []), current.value];
            }
        }
        return res;
    }, {});
};
export const collectParamsFromFilters = (filters) => filters
    .reduce((res, current) => {
    if (current.name) {
        if (current.type === 'checkbox') {
            res[current.name] = [...(res[current.name] || []), current.label];
        }
        else {
            res[current.name] = [...(res[current.name] || []), current.value];
        }
    }
    return res;
}, {});
// accepts Array: Params; Obj?: Customer; String: Current Page; Boolean: isSuborder
// return String: Url
export const buildUrlFromParams = (params, customer, page = 1, isSuborder = false, selectedAdminClient = null) => {
    const paramsNames = Object.keys(params);
    const urlOptions = {
        queryParams: {
            limit: '10',
            is_suborder: `${isSuborder}`,
            page: `${page}`,
        },
    };
    let clientCode = '';
    if (selectedAdminClient && Object.prototype.hasOwnProperty.call(selectedAdminClient, 'code')) {
        clientCode = selectedAdminClient.code;
    }
    else if (selectedAdminClient) {
        clientCode = selectedAdminClient.value;
    }
    if ((selectedAdminClient) && (customer && customer.code)) {
        urlOptions.queryParams.client_code = clientCode;
        urlOptions.queryParams.customer_code = customer.code;
    }
    else if (selectedAdminClient) {
        urlOptions.queryParams.client_code = clientCode;
    }
    else if (customer && customer.code) {
        urlOptions.queryParams.customer_code = customer.code;
    }
    paramsNames.forEach((key) => {
        if (Array.isArray(params[key])) {
            if (params[key].length === 1) {
                urlOptions.queryParams[key] = params[key].join('');
            }
            else {
                urlOptions.queryParams[key] = params[key].join('|');
            }
        }
    });
    return buildUrl('', urlOptions);
};
