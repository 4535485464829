import axios from 'axios';
import { getEnvSettings } from '../../config/environmentSettings';
/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-explicit-any */
export const fetchRegularOrders = (filterUrl) => axios
    .get(`/orders${filterUrl}`)
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const fetchPartialOrders = (partialFilterUrl) => axios
    .get(`/orders/partials${partialFilterUrl}`)
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const apiCancelAllOrders = () => axios
    .post('/orders/cancelAll', null, { baseURL: getEnvSettings().adminApiUrl })
    .then((response) => response)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const apiRemoveCancelAllOrders = (clientCode, payload) => axios
    .post(`/orders/cancel/client/${clientCode}`, payload)
    .then(response => response)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const cancelRegularOrder = (code) => axios
    .put(`/orders/${code}/cancel`)
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const abortRegularOrder = (code) => axios
    .put(`/orders/${code}/abort`, null, { baseURL: getEnvSettings().adminApiUrl })
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const fetchSubOrders = (code) => axios
    .get(`/orders/partials/${code}`)
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
