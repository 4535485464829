import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../../Custody/styling/style';
import fetchAllRisks from './data';
import { PanelContainer } from '../../../../Admin/styling/style';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
/* eslint-disable no-nested-ternary */
const WidgetRisks = ({ risksWidgetHeight, columns, selectedClient, }) => {
    const [maxHeight, setMaxHeight] = useState(480);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [risksToRender, setRisksToRender] = useState([]);
    const [sortDirection, setSortDirection] = useState('asc');
    const [client, setClient] = useState(null);
    const [sortBy, setSortBy] = useState('source');
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [totalPages] = useState(risksToRender.length);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRender([...columnsToRender]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('rfqRisks'));
    };
    const onPageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const onSortHandle = (key) => {
        setCurrentPage(0);
        setSortBy(key);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    useEffect(() => {
        const response = fetchAllRisks();
        setRisksToRender(response);
    }, []);
    useEffect(() => {
        if (selectedClient) {
            setClient(selectedClient);
        }
        else {
            setClient(null);
        }
    }, [selectedClient]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(risksWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [risksWidgetHeight]);
    return React.createElement(React.Fragment, null,
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement("div", null, client ? (React.createElement(Typography, { className: "admin_rfq_heading", variant: "subtitle1" }, client.label)) : null),
            client
                ? (React.createElement(React.Fragment, null,
                    React.createElement(WalletsClientContainer, null,
                        React.createElement("div", null,
                            React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                                React.createElement(ViewColumnIcon, null)),
                            React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                }, transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                } },
                                React.createElement(MaterialPopoverWrap, null,
                                    React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                        React.createElement(ListItemText, { primary: col.title }),
                                        React.createElement(ListItemSecondaryAction, null,
                                            React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
                    React.createElement(MaterialTableWrap, null,
                        React.createElement(Paper, { className: "mui_table_root" },
                            React.createElement(TableContainer, { style: { maxHeight } },
                                React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                    React.createElement(TableHead, null,
                                        React.createElement(TableRow, null, columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'ul' ? 'left' : 'right' },
                                            React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)))))),
                                    React.createElement(TableBody, null, risksToRender.length === 0
                                        ? (React.createElement(TableRow, null,
                                            React.createElement(TableCell, { colSpan: columnsToRender.length + 1 },
                                                React.createElement(Typography, { className: "transactions_typography_padded", variant: "subtitle1" }, "No items found"))))
                                        : risksToRender.map(risk => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: risk.source }, columnsToRender.map((column, i) => {
                                            const key = column.key;
                                            const value = key ? risk[key] : '';
                                            return (React.createElement(TableCell, { align: key === 'ul' ? 'left' : 'right', key: i }, value || 'N/A'));
                                        }))))))),
                            React.createElement(TablePagination, { component: "div", count: totalPages, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [maxHeight] }))))) : (React.createElement(Paper, { className: "wallets_empty_paper_root" },
                React.createElement(Typography, { variant: "subtitle1" }, "Select client for more details")))));
};
const mapStateToProps = (state, ownProps) => ({
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    risksWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(WidgetRisks);
