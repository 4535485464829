const resizeWidgetHeight = (height, setRowsPerPage, setMaxHeight) => {
    if (height < 750) {
        setRowsPerPage(10);
        setMaxHeight(480);
    }
    else if (height >= 750 && height < 850) {
        setRowsPerPage(10);
        setMaxHeight(620);
    }
    else {
        let rowPage = 13;
        let maxHeight = 720;
        for (let i = 850; i < 3050; i += 100) {
            if (height >= i && height < i + 100) {
                setRowsPerPage(rowPage);
                setMaxHeight(maxHeight);
            }
            rowPage += 3;
            maxHeight += 130;
        }
    }
};
export default resizeWidgetHeight;
