import React from 'react';
import { Box, Button } from '@mui/material';
export const RemoveToken = ({ item, toggle, cellAction }) => {
    const handleRemoveToken = () => {
        cellAction(item);
        toggle();
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(Box, null,
            "Confirm remove token: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.label)),
        React.createElement(Box, { display: 'flex', justifyContent: 'end', width: '100%', gap: 1 },
            React.createElement(Button, { color: "primary", variant: "contained", onClick: () => handleRemoveToken() }, "Remove token"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Cancel"))));
};
