var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Box, Dialog } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import MarketForm from './MarketForm';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { NEW_ORDER_CREATE_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
const dispatchOrder = (dispatch, payload, closeErrorNotice) => {
    dispatch({ type: NEW_ORDER_CREATE_REQUEST, payload, closeErrorNotice });
};
const MarketModal = ({ open, toggleFunc, customerCode, base, quote, bidUpdate, askUpdate, orderAmt, action, streamType, timestamp, reference_id, guaranteed, }) => {
    const [loading, setLoading] = useState(false);
    const [orderRfqPrice, setOrderRfqPrice] = useState(null);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleOrderCreate = () => {
        if (customerCode) {
            const payload = {
                base,
                quote,
                action,
                limit_price: '',
                reference_id,
                type: 'RFQ_Market',
                rfq_offered_price_timestamp: (orderRfqPrice === null || orderRfqPrice === void 0 ? void 0 : orderRfqPrice.timestamp) || '',
                customer_code: customerCode,
                rfq_offered_price: (orderRfqPrice === null || orderRfqPrice === void 0 ? void 0 : orderRfqPrice.offered_price) || '',
                rfq_quote_amount: (orderRfqPrice === null || orderRfqPrice === void 0 ? void 0 : orderRfqPrice.quote_amount_to_spend) || '',
                amount: streamType === 'standard' ? orderAmt : (orderRfqPrice === null || orderRfqPrice === void 0 ? void 0 : orderRfqPrice.offered_base_amount) || '',
            };
            dispatchOrder(dispatch, payload, errorNotice);
        }
        else {
            showNotification({
                message: 'Please, select a customer for creating orders',
                color: 'error',
                dispatch: errorNotice,
            });
        }
    };
    const placeRfqPrice = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!customerCode) {
            return;
        }
        ;
        const payload = {
            base,
            quote,
            action,
            amount: orderAmt,
            reference_id: '',
            type: 'RFQ_Market',
            customer_code: customerCode,
        };
        setLoading(true);
        try {
            const { data } = yield axios.post(`/orders/rfq/${streamType === 'standard' ? 'price' : 'amount'}`, payload);
            setOrderRfqPrice(data);
        }
        catch (e) {
            toggleFunc();
            const message = composeErrorMessage(e, Messages.POST_NEW_ORDER);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        }
        finally {
            setLoading(false);
        }
    });
    useEffect(() => {
        if (+orderAmt > +guaranteed && streamType === 'standard') {
            void placeRfqPrice();
        }
        if (+orderAmt != +guaranteed && streamType !== 'standard') {
            void placeRfqPrice();
        }
    }, []);
    useEffect(() => {
        let payload;
        if (streamType === 'standard') {
            payload = {
                base,
                quote,
                action,
                amount: orderAmt,
                timestamp,
                offered_price: action === 'Buy' ? askUpdate : bidUpdate,
            };
        }
        else {
            payload = {
                base,
                quote,
                action,
                amount: orderAmt,
                timestamp,
                offered_base_amount: action === 'Buy' ? askUpdate : bidUpdate,
                quote_amount_to_spend: orderAmt,
            };
        }
        setOrderRfqPrice(payload);
    }, []);
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: 'sm', fullWidth: true },
        React.createElement(DialogTitle, { id: 'form-dialog-title' }, "RFQ order"),
        loading ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: '33px' }))) : (React.createElement(MarketForm, { base: base, quote: quote, action: action, orderAmt: orderAmt, toggleFunc: toggleFunc, streamType: streamType, orderRfqPrice: orderRfqPrice, placeRfqPrice: placeRfqPrice, handleOrderCreate: handleOrderCreate }))));
};
export default MarketModal;
