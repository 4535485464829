import React, { useEffect, useState, useContext } from 'react';
import Decimal from 'decimal.js';
import Grid from '@mui/material/Grid';
import { Doughnut } from 'react-chartjs-2';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import { Alert, Box, CircularProgress, Link } from '@mui/material';
import WidgetCharts from '../Charts/WidgetCharts';
import { RatesContext } from '../../../../../../providers/RatesProvider';
import { getExplorerURL } from '../../../../../../shared/helpers/getExplorerURL';
import { getTransactions } from '../../../../../../redux/selectors/adminSelectors';
import { DatePicker } from '@mui/x-date-pickers';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const WidgetStats = ({ allTransactions, depositsLoading, withdrawalsLoading, selectedClient, currencies, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const ctx = useContext(RatesContext);
    const [isToOpen, setToOpen] = useState(false);
    const [maxTxUrl, setMaxTxUrl] = useState('');
    const [minTxUrl, setMinTxUrl] = useState('');
    const [minTxDate, setMinTxDate] = useState('');
    const [maxTxDate, setMaxTxDate] = useState('');
    const [isFromOpen, setFromOpen] = useState(false);
    const [dateTo, setDateTo] = useState(null);
    const [dateFrom, setDateFrom] = useState(null);
    const [filteredTrx, setFilteredTrx] = useState([]);
    const [clientStat, setClientStat] = useState(null);
    const [depositSeries, setDepositSeries] = useState([]);
    const [chartData, setChartData] = useState({ datasets: [], labels: [] });
    const [withdrawalSeries, setWithdrawalSeries] = useState([]);
    const dateOptions = {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
    };
    const handleDateFromChange = (date) => {
        const value = date ? new Date(date.setHours(0, 0, 0, 0)) : null;
        setDateFrom(value);
        setFromOpen(false);
    };
    const handleDateToChange = (date) => {
        const value = date ? new Date(date.setHours(23, 59, 59, 0)) : null;
        setDateTo(value);
        setToOpen(false);
    };
    const filterTransactions = (transactions, to, from) => {
        let result;
        result = [...transactions.filter(t => new Date(t.created_at) >= from)];
        result = [...result.filter(r => new Date(r.created_at) <= to)];
        return result || [];
    };
    const lineChartTrxData = (trxToData, service) => trxToData.map((trx) => {
        const amtTrx = service.convertInBase(trx.currency_code, trx.amount);
        const amtUsd = amtTrx === 'Not convertible'
            ? '0'
            : amtTrx;
        const d = new Date(trx.created_at);
        return { x: d, y: amtUsd };
    });
    const calculateStats = (transactions, service) => {
        let total = new Decimal(0);
        let totalDeposits = new Decimal(0);
        let depositsCount = new Decimal(0);
        let totalWithdrawals = new Decimal(0);
        let withdrawalsCount = new Decimal(0);
        let item = null;
        let minItem = null;
        let maxItem = null;
        let statItem = null;
        for (let i = 0; i < transactions.length; i++) {
            item = transactions[i];
            const amt = service.convertInBase(item.currency_code, item.amount);
            total = amt !== 'Not convertible' ? total.add(amt) : total.add(0);
            if (item.type === 'deposit') {
                totalDeposits = amt !== 'Not convertible' ? totalDeposits.add(amt) : totalDeposits.add(0);
                depositsCount = depositsCount.add(1);
            }
            if (item.type === 'withdrawal') {
                totalWithdrawals = amt !== 'Not convertible' ? totalWithdrawals.add(amt) : totalWithdrawals.add(0);
                withdrawalsCount = withdrawalsCount.add(1);
            }
            if (!minItem) {
                minItem = item;
            }
            else {
                const amtMin = service.convertInBase(minItem.currency_code, minItem.amount);
                const minUsd = amtMin !== 'Not convertible' ? amtMin : '0';
                const itemUsd = amt !== 'Not convertible' ? amt : '0';
                if (itemUsd !== '0' && minUsd !== '0') {
                    minItem = new Decimal(minUsd).greaterThan(itemUsd) ? item : minItem;
                }
            }
            if (!maxItem) {
                maxItem = item;
            }
            else {
                const amtMax = service.convertInBase(maxItem.currency_code, maxItem.amount);
                const maxUsd = amtMax !== 'Not convertible' ? amtMax : '0';
                const itemUsd = amt !== 'Not convertible' ? amt : '0';
                maxItem = new Decimal(maxUsd).lessThan(itemUsd) ? item : maxItem;
            }
        }
        if (minItem && maxItem) {
            const averageAmount = Decimal.div(total, transactions.length);
            const averageDeposits = Decimal.div(totalDeposits, depositsCount);
            const averageWithdrawals = Decimal.div(totalWithdrawals, withdrawalsCount);
            const amtMin = service.convertInBase(minItem.currency_code, minItem.amount);
            const amtMax = service.convertInBase(maxItem.currency_code, maxItem.amount);
            const maxTrUsdAmount = amtMax !== 'Not convertible' ? amtMax : '0';
            const minTrUsdAmount = amtMin !== 'Not convertible' ? amtMin : '0';
            statItem = {
                count: `${transactions.length}`,
                totalDeposits: transactions.filter(t => t.type === 'deposit').length,
                totalWithdrawals: transactions.filter(t => t.type === 'withdrawal').length,
                avg_amount: `${averageAmount.toFixed(2)}`,
                avg_deposit: `${averageDeposits.toFixed(2)}`,
                avg_withdrawal: `${averageWithdrawals.toFixed(2)}`,
                min_tx: Object.assign({}, minItem, { amount: minTrUsdAmount }),
                max_tx: Object.assign({}, maxItem, { amount: maxTrUsdAmount }),
            };
        }
        return statItem;
    };
    useEffect(() => {
        if (clientStat) {
            const trMaxCurrency = clientStat.max_tx && clientStat.max_tx.currency_code;
            const trMinCurrency = clientStat.min_tx && clientStat.min_tx.currency_code;
            const dateMin = clientStat.min_tx && clientStat.min_tx.created_at;
            const dateMax = clientStat.max_tx && clientStat.max_tx.created_at;
            const dMin = new Date(dateMin || '');
            const dMax = new Date(dateMax || '');
            setMinTxDate(`${dMin.toLocaleDateString(undefined, dateOptions)}, ${dMin.toLocaleTimeString()}`);
            setMaxTxDate(`${dMax.toLocaleDateString(undefined, dateOptions)}, ${dMax.toLocaleTimeString()}`);
            const explorerURLMax = getExplorerURL(trMaxCurrency || '', currencies);
            const explorerURLMin = getExplorerURL(trMinCurrency || '', currencies);
            setMaxTxUrl(explorerURLMax);
            setMinTxUrl(explorerURLMin);
            const labels = [`Deposits: ${clientStat.totalDeposits}`, `Withdrawals: ${clientStat.totalWithdrawals}`];
            const dataSetPayload = [{
                    data: [clientStat.totalDeposits, clientStat.totalWithdrawals],
                    backgroundColor: [
                        'rgb(76, 225, 182)',
                        'rgb(255, 72, 97)',
                    ],
                }];
            setChartData(Object.assign(Object.assign({}, chartData), { datasets: dataSetPayload, labels }));
        }
        else {
            setChartData(Object.assign(Object.assign({}, chartData), { datasets: [], labels: [] }));
            setMaxTxDate('');
            setMinTxDate('');
        }
    }, [clientStat]);
    useEffect(() => {
        if (allTransactions.length > 0 && ctx.service) {
            const allDeposits = allTransactions.filter(t => t.type === 'deposit');
            const allWithdrawals = allTransactions.filter(t => t.type === 'withdrawal');
            const stats = calculateStats(allTransactions, ctx.service);
            setClientStat(stats);
            const dSeries = lineChartTrxData(allDeposits, ctx.service);
            const wSeries = lineChartTrxData(allWithdrawals, ctx.service);
            setDepositSeries(dSeries);
            setWithdrawalSeries(wSeries);
        }
        else {
            setClientStat(null);
            setDepositSeries([]);
            setWithdrawalSeries([]);
        }
    }, [allTransactions, ctx.service]);
    useEffect(() => {
        if (dateFrom && dateTo) {
            const trx = filterTransactions(allTransactions, dateTo, dateFrom);
            setFilteredTrx([...trx]);
        }
    }, [dateFrom, dateTo]);
    useEffect(() => {
        if (filteredTrx.length && ctx.service) {
            const statForFiltered = calculateStats(filteredTrx, ctx.service);
            setClientStat(statForFiltered);
            const allDeposits = filteredTrx.filter(t => t.type === 'deposit');
            const allWithdrawals = filteredTrx.filter(t => t.type === 'withdrawal');
            const dSeries = lineChartTrxData(allDeposits, ctx.service);
            const wSeries = lineChartTrxData(allWithdrawals, ctx.service);
            setDepositSeries(dSeries);
            setWithdrawalSeries(wSeries);
        }
        else {
            setClientStat(null);
            setDepositSeries([]);
            setWithdrawalSeries([]);
        }
    }, [filteredTrx, ctx.service]);
    if (depositsLoading || withdrawalsLoading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { p: 2, display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement("div", null, selectedClient
            ? (null)
            : (React.createElement(Box, { p: 2 },
                React.createElement(Alert, { variant: "outlined", severity: "info" }, "Select client for more details")))),
        selectedClient
            ? (React.createElement(Grid, { container: true, spacing: 2 },
                React.createElement(Grid, { item: true, spacing: 2, xs: 6 },
                    React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                        React.createElement(Box, { gap: 1, display: 'flex' },
                            React.createElement("div", null,
                                React.createElement(DatePicker, { open: isFromOpen, onOpen: () => setFromOpen(true), onClose: () => setFromOpen(false), format: "dd/MM/yyyy", value: dateFrom, disableFuture: true, label: "Start date", slotProps: {
                                        textField: {
                                            size: 'small',
                                        }
                                    }, onChange: (date) => handleDateFromChange(date) })),
                            React.createElement("div", null,
                                React.createElement(DatePicker, { open: isToOpen, onOpen: () => setToOpen(true), onClose: () => setToOpen(false), format: "dd/MM/yyyy", value: dateTo, label: "End date", disableFuture: true, slotProps: {
                                        textField: {
                                            size: 'small'
                                        }
                                    }, onChange: (date) => handleDateToChange(date) }))),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                                React.createElement("div", null,
                                    React.createElement(Typography, { variant: "h6", color: "Highlight" }, "Max tx"),
                                    React.createElement("div", null,
                                        React.createElement(Link, { target: "_blank", rel: "noreferrer", href: `${maxTxUrl}${((_a = clientStat === null || clientStat === void 0 ? void 0 : clientStat.max_tx) === null || _a === void 0 ? void 0 : _a.tx_hash) || ''}` }, (_b = clientStat === null || clientStat === void 0 ? void 0 : clientStat.max_tx) === null || _b === void 0 ? void 0 : _b.tx_hash))),
                                React.createElement(Grid, { container: true, item: true, xs: 12 },
                                    React.createElement(Grid, { item: true, xs: 5 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Date created"),
                                        React.createElement(Typography, { sx: { fontWeight: 'bold' } }, maxTxDate)),
                                    React.createElement(Grid, { item: true, xs: 3 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Type"),
                                        React.createElement(Typography, { sx: { fontWeight: 'bold' } }, (_c = clientStat === null || clientStat === void 0 ? void 0 : clientStat.max_tx) === null || _c === void 0 ? void 0 : _c.type)),
                                    React.createElement(Grid, { item: true, xs: 4 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Amount"),
                                        React.createElement(Typography, { variant: "h6", sx: { fontWeight: 'bold' } },
                                            "$ ", (_d = clientStat === null || clientStat === void 0 ? void 0 : clientStat.max_tx) === null || _d === void 0 ? void 0 :
                                            _d.amount))))),
                        React.createElement(Divider, null),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                                React.createElement("div", null,
                                    React.createElement(Typography, { variant: "h6", color: "Highlight" }, "Min tx"),
                                    React.createElement("div", null,
                                        React.createElement(Link, { target: "_blank", href: `${minTxUrl}${((_e = clientStat === null || clientStat === void 0 ? void 0 : clientStat.min_tx) === null || _e === void 0 ? void 0 : _e.tx_hash) || ''}`, rel: "noreferrer" }, (_f = clientStat === null || clientStat === void 0 ? void 0 : clientStat.min_tx) === null || _f === void 0 ? void 0 : _f.tx_hash))),
                                React.createElement(Grid, { container: true, item: true, xs: 12 },
                                    React.createElement(Grid, { item: true, xs: 5 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Date created"),
                                        React.createElement(Typography, { sx: { fontWeight: 'bold' } }, minTxDate)),
                                    React.createElement(Grid, { item: true, xs: 3 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Type"),
                                        React.createElement(Typography, { sx: { fontWeight: 'bold' } }, (_g = clientStat === null || clientStat === void 0 ? void 0 : clientStat.min_tx) === null || _g === void 0 ? void 0 : _g.type)),
                                    React.createElement(Grid, { item: true, xs: 4 },
                                        React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Amount"),
                                        React.createElement(Typography, { variant: "h6", sx: { fontWeight: 'bold' } },
                                            "\u2248 $ ", (_h = clientStat === null || clientStat === void 0 ? void 0 : clientStat.min_tx) === null || _h === void 0 ? void 0 :
                                            _h.amount))))))),
                React.createElement(Grid, { container: true, item: true, spacing: 1, xs: 6 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("div", { className: "paperBox" },
                            React.createElement(Typography, { variant: "overline" }, "Deposits vs Withdrawals"),
                            React.createElement(WidgetCharts, { series: [
                                    { name: 'deposits', data: depositSeries, color: '#32C6A1' },
                                    { name: 'withdrawals', data: withdrawalSeries, color: '#ff4861' },
                                ] }))),
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement(Box, null,
                            React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Total transactions"),
                            React.createElement(Doughnut, { data: chartData, options: { responsive: false }, width: 150, height: 130 }))),
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'space-between' },
                            React.createElement("div", null,
                                React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Average total"),
                                React.createElement(Typography, { variant: "h6", sx: { fontWeight: 'bold' } },
                                    "$ ", clientStat === null || clientStat === void 0 ? void 0 :
                                    clientStat.avg_amount)),
                            React.createElement("div", null,
                                React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Average deposits"),
                                React.createElement(Typography, { variant: "h6", sx: { fontWeight: 'bold' } },
                                    "$ ", clientStat === null || clientStat === void 0 ? void 0 :
                                    clientStat.avg_deposit)),
                            React.createElement("div", null,
                                React.createElement(Typography, { variant: "overline", color: "Highlight" }, "Average withdrawals"),
                                React.createElement(Typography, { variant: "h6", sx: { fontWeight: 'bold' } },
                                    "$ ", clientStat === null || clientStat === void 0 ? void 0 :
                                    clientStat.avg_withdrawal))))))) : null));
};
const mapStateToProps = (state) => ({
    allTransactions: getTransactions(state),
    currencies: state.masterTenant.currencies,
    selectedClient: getSelectedClientInputValue(state),
    depositsLoading: state.admin.depositsLoading,
    withdrawalsLoading: state.admin.withdrawalsLoading,
});
export default connect(mapStateToProps)(WidgetStats);
