import React, { useState, useEffect, useCallback, useContext, } from 'react';
import { Box, List } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { PanelContainer, MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../../Custody/styling/style';
import WalletsFilters from './WalletsFilters';
import WalletTableCell from './WalletTableCell';
import Messages from '../../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import { RatesContext } from '../../../../../../providers/RatesProvider';
import showNotification from '../../../../../../shared/helpers/notifications';
import CustodyWalletService from '../../../../../../services/custodyWalletService';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { getWalletsToRender } from '../../../../../../redux/selectors/adminSelectors';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { materialUiPaginator } from '../../../../../../shared/helpers/recordsDependsOnPage';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import { COMPLIANCE_WALLETS_UPDATE } from '../../../../../../redux/actionTypes/adminActionTypes';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
/* eslint-disable no-nested-ternary, prefer-destructuring, @typescript-eslint/restrict-template-expressions */
const WidgetComplianceWallets = ({ clientInfo, allWallets, columns, walletsWidgetHeight, selectedClient, }) => {
    const [loading, setLoading] = useState(false);
    const [maxHeight, setMaxHeight] = useState(480);
    const [clientCode, setClientCode] = useState('');
    const [hideZero, setDisplayZero] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentWalletPage, setCurrentWalletPage] = useState(0);
    const [totalPages, setTotalPages] = useState(allWallets.length);
    const [filtersState, setFiltersState] = useState([]);
    const [sortDirection, setSortDirection] = useState('asc');
    const [walletsToRender, setWalletsToRender] = useState([]);
    const [walletsFiltered, setWalletsFiltered] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [sortBy, setSortBy] = useState('label');
    const [columnsToRender, setColumnsToRender] = useState([]);
    const ctx = useContext(RatesContext);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const toggleZeroBalances = (evt) => {
        const { checked } = evt.target;
        setDisplayZero(checked);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRender([...columnsToRender]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const fetchAllWallets = (code, cancelToken) => {
        const walletService = new CustodyWalletService({
            url: `/custody/${code}/wallets?limit=1000&state=all`,
            method: 'get',
            cancelToken,
        });
        setLoading(true);
        walletService.makeRequest()
            .then((data) => {
            dispatch({ type: COMPLIANCE_WALLETS_UPDATE, wallets: data.records });
            setLoading(false);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_FETCH);
            dispatch({ type: COMPLIANCE_WALLETS_UPDATE, wallets: [] });
            setLoading(false);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const onPageChange = (event, pageNumber) => {
        setCurrentWalletPage(pageNumber);
    };
    const onSortHandle = (key) => {
        setCurrentWalletPage(0);
        setSortBy(key);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const sortStoredWallets = (walletsToSort, direction, by) => {
        const sorterNum = (dir, a, b) => dir === 'desc'
            ? (a - b) : (b - a);
        const sorter = (dir, a, b) => dir === 'desc'
            ? (a > b)
                ? 1 : -1
            : (a < b)
                ? 1 : -1;
        const sortFunc = (a, b, dir, key) => {
            const valueA = a[key];
            const valueB = b[key];
            if (valueA && valueB) {
                if (isNaN(+valueA) && isNaN(+valueB)) {
                    return sorter(dir, valueA, valueB);
                }
                return sorterNum(dir, +valueA, +valueB);
            }
            // or maybe throw exception
            return 0;
        };
        return walletsToSort.sort((a, b) => sortFunc(a, b, direction, by));
    };
    const updateFilter = useCallback((filters) => {
        setFiltersState([...filters]);
    }, [filtersState]);
    useEffect(() => {
        let code = '';
        if (selectedClient) {
            code = selectedClient.value;
        }
        else if (clientInfo) {
            code = clientInfo.code;
        }
        setClientCode(code);
    }, [clientInfo, selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (clientCode) {
            fetchAllWallets(clientCode, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [clientCode]);
    useEffect(() => {
        const total = hideZero ? allWallets.filter(w => w.balance_total !== '0') : allWallets;
        const items = materialUiPaginator(total, currentWalletPage, rowsPerPage);
        setWalletsToRender([...items]);
        setTotalPages(total.length);
    }, [allWallets, hideZero]);
    useEffect(() => {
        const items = hideZero ? allWallets.filter(w => w.balance_total !== '0') : allWallets;
        let w = filtersState.length ? [...walletsFiltered] : [...items];
        if (sortBy) {
            w = sortStoredWallets(w, sortDirection, sortBy);
        }
        w = materialUiPaginator(w, currentWalletPage, rowsPerPage);
        setWalletsToRender([...w]);
        if (ctx.service && allWallets.length > 0) {
            const conversionService = ctx.service;
            const mapped = w.map((i) => Object.assign({}, i, {
                usd_total: conversionService.convertInBase(i.currency_code.toLowerCase(), i.balance_total),
                usd_available: conversionService.convertInBase(i.currency_code.toLowerCase(), i.balance_available),
                usd_locked: conversionService.convertInBase(i.currency_code.toLowerCase(), i.balance_locked),
            }));
            setWalletsToRender([...mapped]);
        }
    }, [sortBy, sortDirection, currentWalletPage, rowsPerPage, walletsFiltered, allWallets, ctx.service]);
    useEffect(() => {
        if (filtersState.length) {
            setCurrentWalletPage(0);
            const filtered = allWallets.filter(w => filtersState.every(f => w[f.name] === f.value));
            const filteredZeroWallets = hideZero ? filtered.filter(w => w.balance_total !== '0') : filtered;
            setWalletsFiltered([...filteredZeroWallets]);
            setTotalPages(filteredZeroWallets.length);
        }
        else {
            setWalletsFiltered([]);
            const total = hideZero ? allWallets.filter(w => w.balance_total !== '0').length : allWallets.length;
            setTotalPages(total);
        }
    }, [filtersState, hideZero, allWallets]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(walletsWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [walletsWidgetHeight]);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement("div", null,
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement("div", null, clientCode
                ? null : (React.createElement(Paper, { className: "wallets_empty_paper_root" },
                React.createElement(Typography, { variant: "subtitle1" }, "Select client for more details")))),
            selectedClient ? (React.createElement(React.Fragment, null,
                React.createElement(WalletsFilters, { hideZero: hideZero, onFiltersChange: updateFilter, toggleZeroBalances: toggleZeroBalances }),
                React.createElement(WalletsClientContainer, null,
                    React.createElement("div", null,
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            }, transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            } },
                            React.createElement(MaterialPopoverWrap, null,
                                React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                    React.createElement(ListItemText, { primary: col.title }),
                                    React.createElement(ListItemSecondaryAction, null,
                                        React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
                React.createElement(MaterialTableWrap, null,
                    React.createElement(Paper, { className: "mui_table_root" },
                        React.createElement(TableContainer, { style: { maxHeight } },
                            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                React.createElement(TableHead, null,
                                    React.createElement(TableRow, null, columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, align: column.key === 'currency_code' ? 'left' : 'right', style: { minWidth: column.minWidth } },
                                        React.createElement(TableSortLabel, { direction: column.key === sortBy ? sortDirection : 'asc', active: column.key === sortBy, onClick: () => onSortHandle(column.key) }, column.title)))))),
                                React.createElement(TableBody, null, walletsToRender.map(wallet => (React.createElement(TableRow, { hover: true, tabIndex: -1, role: "checkbox", key: wallet.code }, columnsToRender.map((column, i) => {
                                    const key = column.key;
                                    const value = key ? wallet[key] : undefined;
                                    return (React.createElement(WalletTableCell, { key: i, value: value, columnKey: key }));
                                }))))))),
                        React.createElement(TablePagination, { component: "div", count: totalPages, page: currentWalletPage, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [rowsPerPage] }))))) : null)));
};
const mapStateToProps = (state, ownProps) => ({
    clientInfo: state.client.clientInfo,
    allWallets: getWalletsToRender(state),
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    walletsWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(WidgetComplianceWallets);
