import React from 'react';
import { connect } from 'react-redux';
import SuborderModal from '../../Suborders/main';
/* eslint-disable react/jsx-indent */
const PlaceOrderModalContainer = ({ amount, createdSplitOrder, newPartialLoading, toggleModal, modalOpen, }) => (React.createElement("div", null, amount && createdSplitOrder && !newPartialLoading
    && (React.createElement(SuborderModal, { open: modalOpen, toggleFunc: toggleModal, splitCode: createdSplitOrder.code }))));
const stateToProps = (state) => {
    const { amount, createdSplitOrder, newPartialLoading, } = state.placeOrder;
    return {
        amount,
        newPartialLoading,
        createdSplitOrder,
    };
};
export default connect(stateToProps)(PlaceOrderModalContainer);
