const fetchAllClientProtocol = () => ([
    {
        id: '0',
        ul: 'BTCEUR',
        float_min: true,
        fix: false,
        min_spread: '0.30%',
        spread_widening_on: true,
        widening_factor: '1.5',
        used_spread: '0.45%',
        autoquoter_on_off: true,
        notional: '20000',
        add_client_shift: '0.04',
    }, {
        id: '1',
        ul: 'BTCUSD',
        float_min: true,
        fix: false,
        min_spread: '0.28%',
        spread_widening_on: false,
        widening_factor: '1.5',
        used_spread: '0.45%',
        autoquoter_on_off: true,
        notional: '20001',
        add_client_shift: '0.01',
    }, {
        id: '2',
        ul: 'XRPEUR',
        float_min: false,
        fix: true,
        min_spread: '0.30%',
        spread_widening_on: true,
        widening_factor: '1.5',
        used_spread: '0.45%',
        autoquoter_on_off: true,
        notional: '25000',
        add_client_shift: '-0.40',
    }, {
        id: '3',
        ul: 'ADAEUR',
        float_min: true,
        fix: false,
        min_spread: '0.30%',
        spread_widening_on: false,
        widening_factor: '2',
        used_spread: '0.60%',
        autoquoter_on_off: true,
        notional: '12500',
        add_client_shift: '0.00',
    },
]);
export default fetchAllClientProtocol;
