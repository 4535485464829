import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import OrderlistItem from './OrderlistItem';
import { getExchangesOptions } from '../../../../../../redux/selectors/selectors';
import { getAllCustomers } from '../../../../../../redux/selectors/customerSearchSelectors';
/* eslint-disable react/jsx-indent */
const RegularOrderListContent = ({ regularOrders, exchanges, features, customers, handleExchangeChange, meCode, masterTenant, }) => (React.createElement(Fragment, null, regularOrders.map((item) => (React.createElement(OrderlistItem, { item: item, meCode: meCode, key: item.created, features: features, exchanges: exchanges, customers: customers, masterTenant: masterTenant, handleExchangeChange: handleExchangeChange })))));
const mapStateToProps = (state) => ({
    meCode: state.client.meCode,
    features: state.client.features,
    customers: getAllCustomers(state),
    exchanges: getExchangesOptions(state),
    masterTenant: state.client.masterTenant,
});
export default connect(mapStateToProps)(RegularOrderListContent);
