import React, { useState, useEffect, useCallback } from 'react';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { Box, CircularProgress } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../Custody/styling/style';
import StrategiesItem from './StrategiesItem';
import StrategiesFilters from './StrategiesFilters';
import { PanelContainer } from '../../../Admin/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import StrategiesService from '../../../../../services/strategiesService';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { materialUiPaginator } from '../../../../../shared/helpers/recordsDependsOnPage';
import { getAllCustomers } from '../../../../../redux/selectors/customerSearchSelectors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const Strategies = ({ columns, clientInfo, customers, selectedClient, urlOptionsStrategies, strategiesWidgetHeight, }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [maxHeight, setMaxHeight] = useState(480);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalStrategies, setTotalStrategies] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');
    const [allStrategies, setAllStrategies] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [strategiesToRender, setStrategiesToRender] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onSortHandle = () => {
        setPage(0);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const columnsOpen = Boolean(anchorColumns);
    const onChangePage = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('strategies'));
    };
    const fetchStrategies = (url, cancelToken) => {
        setLoading(true);
        const service = new StrategiesService({
            url,
            method: 'get',
            cancelToken,
        });
        service.makeRequest()
            .then((responseData) => {
            setLoading(false);
            const { strategies } = responseData;
            setAllStrategies(strategies);
            setTotalStrategies(strategies.length);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (urlOptionsStrategies) {
            fetchStrategies(urlOptionsStrategies, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [urlOptionsStrategies]);
    useEffect(() => {
        const items = materialUiPaginator(allStrategies, page, rowsPerPage);
        setStrategiesToRender([...items]);
    }, [allStrategies, page, rowsPerPage]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(strategiesWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [strategiesWidgetHeight]);
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement(StrategiesFilters, { currentPage: page, clientInfo: clientInfo, setCurrentPage: setPage, selectedClient: selectedClient, rowsPerPage: rowsPerPage, sortDirection: sortDirection }),
            React.createElement("div", { className: "card-body" }, loading ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
                React.createElement(CircularProgress, { size: "33px" }))) : (React.createElement(React.Fragment, null,
                React.createElement(WalletsClientContainer, null,
                    React.createElement("div", null,
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                            React.createElement(MaterialPopoverWrap, null,
                                React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                    React.createElement(ListItemText, { primary: col.title }),
                                    React.createElement(ListItemSecondaryAction, null,
                                        React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
                React.createElement(MaterialTableWrap, null,
                    React.createElement(Paper, { className: "mui_table_root" },
                        React.createElement(TableContainer, { style: { maxHeight } },
                            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                React.createElement(TableHead, null,
                                    React.createElement(TableRow, null,
                                        columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, onClick: () => onSortHandle(), style: { minWidth: column.minWidth }, align: column.key === 'code' ? 'left' : 'right' }, column.title))),
                                        React.createElement(TableCell, { align: "right", key: "update", style: { minWidth: '100px' } }, "Action"))),
                                React.createElement(TableBody, null, strategiesToRender.map(s => (React.createElement(StrategiesItem, { item: s, customers: customers, columnsToRender: columnsToRender, strategiesToRender: strategiesToRender, setStrategiesToRender: setStrategiesToRender })))))),
                        React.createElement(TablePagination, { page: page, component: "div", count: totalStrategies, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [maxHeight] })))))))));
};
const mapStateToProps = (state, ownProps) => ({
    customers: getAllCustomers(state),
    clientInfo: state.client.clientInfo,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    strategiesWidgetHeight: state.widgets[ownProps.widgetKey],
    urlOptionsStrategies: state.masterTenant.urlOptionsStrategies,
});
export default connect(mapStateToProps)(Strategies);
