/* eslint-disable import/prefer-default-export */
export const CUSTODY_WALLETS_ADD = 'CUSTODY_WALLETS_ADD';
export const SORT_BY_WALLETS_UPDATE = 'SORT_BY_WALLETS_UPDATE';
export const CUSTODY_WALLETS_UPDATE = 'CUSTODY_WALLETS_UPDATE';
export const CLIENTS_WALLETS_UPDATE = 'CLIENTS_WALLETS_UPDATE';
export const LOADING_WALLETS_UPDATE = 'LOADING_WALLETS_UPDATE';
export const CUSTODY_DEPOSITS_UPDATE = 'CUSTODY_DEPOSITS_UPDATE';
export const LOADING_ADDRESSES_UPDATE = 'LOADING_ADDRESSES_UPDATE';
export const CUSTODY_CURRENCIES_UPDATE = 'CUSTODY_CURRENCIES_UPDATE';
export const SORT_DIRECTION_WALLETS_UPDATE = 'SORT_DIRECTION_WALLETS_UPDATE';
export const LOADING_CLIENTS_WALLETS_UPDATE = 'LOADING_CLIENTS_WALLETS_UPDATE';
export const CUSTODY_WITHDRAWALS_OPTIONS_UPDATE = 'CUSTODY_WITHDRAWALS_OPTIONS_UPDATE';
