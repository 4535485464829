import { CELL_TYPE } from "../../../../../shared/components/table/DLTTableModel";
export const balancesColumns = [
    {
        id: 'code',
        sortId: 'id',
        label: 'Currency',
        width: 20,
        sortable: true,
    },
    {
        id: 'available',
        label: 'Available',
        width: 20,
        type: CELL_TYPE.NUMERIC,
        sortable: true,
    },
    {
        id: 'locked',
        label: 'Locked',
        width: 20,
        type: CELL_TYPE.NUMERIC,
        sortable: true,
    },
    {
        id: 'total',
        label: 'Total',
        width: 20,
        type: CELL_TYPE.NUMERIC,
        sortable: true,
    },
    {
        id: 'actions',
        label: 'Actions',
        width: 20,
    },
];
export const accountsColumns = [
    {
        id: 'type',
        label: 'Account type',
        width: 20,
    },
    {
        id: 'available',
        label: 'Available',
        width: 20,
        type: CELL_TYPE.NUMERIC,
    },
    {
        id: 'locked',
        label: 'Locked',
        width: 20,
        type: CELL_TYPE.NUMERIC,
    },
    {
        id: 'total',
        label: 'Total',
        width: 20,
        type: CELL_TYPE.NUMERIC,
    },
    {
        id: 'actions',
        label: 'Actions',
        width: 20,
    },
];
