import { useEffect, useState } from 'react';
import axios from 'axios';
import { composeErrorMessage } from '../../shared/helpers/interceptors';
import showNotification from '../../shared/helpers/notifications';
const useBalancesService = () => {
    const [data, setData] = useState([]);
    const [intervalId, setIntervalId] = useState();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const fetchData = (promise) => {
        setError(false);
        setLoading(true);
        return promise
            .then((res) => {
            setData(res.data);
        })
            .catch((error) => {
            handleFetchError(error);
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const handleFetchError = (error) => {
        setError(true);
        clearInterval(intervalId);
        const message = composeErrorMessage(error, 'Failed to load balances');
        showNotification({
            message: `Error: ${message}`,
            color: 'error',
        });
    };
    const fetchBalances = (type, id) => {
        fetchData(axios.get(`trade/balances/${type}/${id}`));
    };
    const fetchWithInterval = (type, id, interval = 30000) => {
        stopPolling();
        fetchBalances(type, id);
        const newInterval = setInterval(() => fetchBalances(type, id), interval);
        setIntervalId(newInterval);
    };
    const stopPolling = () => {
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(undefined);
        }
    };
    useEffect(() => {
        if (error) {
            stopPolling();
        }
    }, [error]);
    useEffect(() => {
        return () => {
            stopPolling();
        };
    }, []);
    return {
        data,
        intervalId,
        loading,
        activeTab,
        setActiveTab,
        fetchWithInterval,
        stopPolling,
    };
};
export default useBalancesService;
