import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import DLTTable from '../../../../../../shared/components/table/DLTTableComponent';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
const statusIcon = (value) => {
    return value ? React.createElement(CheckIcon, { color: 'success' }) : React.createElement(ClearIcon, { color: 'error' });
};
const columns = [
    { id: 'code', label: 'Code', sortable: true },
    { id: 'name', label: 'Name', sortable: true },
    { id: 'is_fiat', label: 'Is Fiat', sortable: true },
];
function mapCurrencies(currencies, searchString) {
    return currencies
        .filter((p) => {
        var _a;
        let result = false;
        for (const prop in p) {
            const search = (_a = p[prop]) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().includes(searchString.toLowerCase());
            if (search) {
                result = true;
            }
        }
        return result;
    })
        .map((c) => (Object.assign(Object.assign({}, c), { code: c.code.toLocaleUpperCase(), id: c.code, is_fiat: statusIcon(c.is_fiat) })));
}
const TradingCurrenciesContainer = (props) => {
    const { currencies } = props;
    const [mappedCurrencies, setMappedCurrencies] = useState([]);
    const [page, setPage] = useState(0);
    const [searchString, setSearchString] = useState('');
    useEffect(() => {
        setMappedCurrencies(mapCurrencies(currencies, ''));
    }, [currencies]);
    useEffect(() => {
        setMappedCurrencies(mapCurrencies(currencies, searchString));
        setPage(0);
    }, [searchString]);
    const convertToCSV = (currencies) => {
        const header = Object.keys(currencies[0]).join(',');
        const rows = currencies.map((obj) => Object.values(obj).join(','));
        return [header, ...rows].join('\n');
    };
    const downloadCSV = (csvData, fileName) => {
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const handleDownload = () => {
        const csv = convertToCSV(currencies);
        const date = new Date().toISOString().split('T')[0];
        downloadCSV(csv, `DLT Trading Currencies ${date}.csv`);
    };
    return (React.createElement(Box, { sx: { display: 'flex', gap: 2, flexDirection: 'column', p: 2 } },
        React.createElement(Box, { sx: { display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between' } },
            React.createElement(Box, { sx: { display: 'flex', gap: 2, alignItems: 'center' } },
                React.createElement(Typography, { variant: 'h6' }, "Trading Currencies"),
                React.createElement(Box, null,
                    React.createElement(TextField, { size: 'small', value: searchString, onChange: (e) => setSearchString(e.target.value), InputProps: {
                            endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                                React.createElement(SearchIcon, null))),
                        } }))),
            React.createElement(Button, { variant: 'contained', size: 'small', disabled: !currencies, onClick: handleDownload }, "Export CSV")),
        React.createElement(DLTTable, { columns: columns, defaultOrder: 'asc', defaultOrderProp: 'code', total: mappedCurrencies.length, data: mappedCurrencies, pagination: true, page: page })));
};
const stateToProps = (state) => {
    const currencies = state.currencies.tradingCurrencies;
    return {
        currencies,
    };
};
export default connect(stateToProps)(TradingCurrenciesContainer);
