import React, { useState, useReducer, useEffect, useCallback, } from 'react';
import { Box, List, Table, Button, Popover, TableRow, Checkbox, ListItem, TableBody, TableCell, TableHead, IconButton, ListItemText, TableContainer, TableSortLabel, TablePagination, CircularProgress, ListItemSecondaryAction, Container, } from '@mui/material';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { PanelContainer, MaterialPopoverWrap, WalletsClientContainer, } from '../../styling/style';
import TwoFAModal from './TwoFAModal';
import AddressTableCell from './AddressTableCell';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import AddressesCellActions from '../Whitelist/AddressesCellActions';
import showNotification from '../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import WithdrawalsAddressesService from '../../../../../services/withdrawalsAddressesService';
const init = (initValue) => ({
    bulk: initValue,
});
var BulkActionType;
(function (BulkActionType) {
    BulkActionType["INIT"] = "INIT";
    BulkActionType["UPDATE"] = "UPDATE";
    BulkActionType["SET_ALL"] = "SET_ALL";
})(BulkActionType || (BulkActionType = {}));
const updateItemInArray = (items, item) => {
    const addressIndex = items.findIndex(a => a.code === item.code);
    return items.map((address, index) => {
        if (index !== addressIndex) {
            return address;
        }
        const mergedItem = Object.assign(Object.assign({}, address), { value: item.value });
        return Object.assign(Object.assign({}, address), mergedItem);
    });
};
const reducer = (state, action) => {
    switch (action.type) {
        case BulkActionType.SET_ALL:
            return {
                bulk: state.bulk.map(b => Object.assign({}, b, { value: true })),
            };
        case BulkActionType.UPDATE: {
            const newArr = updateItemInArray(state.bulk, action.item);
            return { bulk: newArr };
        }
        case BulkActionType.INIT:
            return init(action.payload);
        default:
            return state;
    }
};
const Addresses = ({ pageAddresses, onChangePageA, meCode, columns, addressesToRender, cellActions, features, initialValue, fetchAllAddresses, rowsPerPage, sortBy, sortDirection, onSortHandle, loading, admin, totalAddresses, selectedClient, handleColumnsClick, handleColumnsClose, anchorColumns, columnsToRender, hideShowColumn, maxHeight, clientInfo, columnsOpen, }) => {
    const reduxDispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [reduxDispatch]);
    const [clientCode, setClientCode] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [codes, setCodes] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const colsA = columns.filter(c => c.id.includes('addressesApproveColumns'));
    const adminBulkApprove = (payload) => {
        const url = '/custody/client/withdrawals/addresses/bulk_approves';
        const service = new WithdrawalsAddressesService({
            url,
            data: payload,
            method: 'post',
            baseURL: getEnvSettings().adminApiUrl,
        });
        service.makeRequest()
            .then(() => {
            toggleModal();
            fetchAllAddresses(clientCode);
            showNotification({
                message: 'All Withdrawal Addresses successfully approved',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            toggleModal();
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.ADDRESSES_BULK_APPROVE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const setAllBulk = () => {
        dispatch({ type: BulkActionType.SET_ALL });
    };
    const updateBulk = (val) => {
        dispatch({ type: BulkActionType.UPDATE, item: val });
    };
    const initBulk = (payload) => {
        dispatch({ type: BulkActionType.INIT, payload });
    };
    const handleBulkChange = (evt, code) => {
        const { checked } = evt.target;
        const item = {
            code,
            value: checked,
        };
        updateBulk(item);
    };
    const handleSelectAll = (evt, initial) => {
        const { checked } = evt.target;
        setSelectAll(checked);
        if (checked) {
            setAllBulk();
        }
        else {
            initBulk(initial);
        }
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    useEffect(() => {
        setClientCode((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || '');
    }, [selectedClient]);
    useEffect(() => {
        if (initialValue.length) {
            initBulk(initialValue);
        }
    }, [initialValue]);
    useEffect(() => {
        if (state.bulk.length) {
            const joined = state.bulk.filter(b => b.value).map(b => b.code);
            setCodes(joined);
            const isChecked = state.bulk.find(b => b.value);
            if (isChecked) {
                setDisableButton(true);
            }
            else {
                setDisableButton(false);
            }
        }
    }, [state]);
    return (React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" }, loading ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
        React.createElement(CircularProgress, { size: "33px" }))) : (React.createElement(React.Fragment, null,
        modalOpen ? (React.createElement(TwoFAModal, { codes: codes, loading: false, open: modalOpen, toggleFunc: toggleModal, handleFunction: adminBulkApprove })) : null,
        React.createElement(WalletsClientContainer, null,
            disableButton ? (React.createElement("span", { className: "custody_clients_add_new", style: { top: '0px' } },
                React.createElement(Button, { size: "small", color: "primary", variant: "outlined", onClick: toggleModal }, "Bulk approve"))) : null,
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, colsA.map(col => (React.createElement(ListItem, { key: col.id },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.id), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        addressesToRender.length === 0 ? (React.createElement(Container, null,
            React.createElement(Alert, { severity: "info", variant: "outlined" }, "No items found"))) : (React.createElement(React.Fragment, null,
            React.createElement(TableContainer, { style: { maxHeight } },
                React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            columnsToRender.map((column) => {
                                if (column.key === 'address') {
                                    return (React.createElement(TableCell, { align: "left", key: column.key, style: { minWidth: column.minWidth } },
                                        React.createElement(Box, { display: 'flex', flexDirection: 'row', gap: 1 },
                                            React.createElement(Box, null,
                                                React.createElement("label", { htmlFor: "selectAll", className: "checkbox-btn", style: { marginBottom: '5px' } },
                                                    React.createElement(Checkbox, { id: "selectAll", name: "selectAll", checked: selectAll, onChange: e => handleSelectAll(e, initialValue) }))),
                                            React.createElement(Box, { display: 'flex', flexDirection: 'row', gap: 2 },
                                                React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)))));
                                }
                                return (React.createElement(TableCell, { align: "left", key: column.key, style: { minWidth: column.minWidth } }, column.key !== 'creator' && column.key !== 'amount' ?
                                    React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title) : column.title));
                            }),
                            React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                    React.createElement(TableBody, null, addressesToRender.map(address => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: address.code },
                        columnsToRender.map((column, i) => {
                            const key = column.key;
                            const value = key ? address[key] : '';
                            return (React.createElement(AddressTableCell, { key: i, value: value, item: address, columnKey: key, bulk: state.bulk, handleBulkChange: handleBulkChange }));
                        }),
                        React.createElement(AddressesCellActions, { admin: admin, item: address, meCode: meCode, features: features, actions: cellActions, clientCode: clientCode, clientInfo: clientInfo }))))))),
            React.createElement(TablePagination, { component: "div", page: pageAddresses, count: totalAddresses, rowsPerPage: rowsPerPage, onPageChange: onChangePageA, rowsPerPageOptions: [maxHeight] })))))));
};
export default Addresses;
