import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Cancel from '@mui/icons-material/Cancel';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import CreateIcon from '@mui/icons-material/Create';
import CheckBox from '@mui/icons-material/CheckBox';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import SettingsBackupRestore from '@mui/icons-material/SettingsBackupRestore';
import { MaterialPopoverWrap } from '../../../Custody/styling/style';
const TransfersCellActions = ({ item, actions, masterTenant, features, meCode, clientInfo, }) => {
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [action, setAction] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionsToRender, setActionsToRender] = useState([]);
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (userAction) => {
        toggleOpenDialog(prev => !prev);
        setAction(userAction);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const renderActionsContent = (userAction) => {
        if (!userAction) {
            return (React.createElement("div", null, "something went wrong"));
        }
        return (React.createElement(actions.actionsComponents[userAction], {
            toggle: () => toggleDialog(),
            item,
            cellAction: actions.actions[userAction],
            actionKey: userAction.toLowerCase(),
        }));
    };
    useEffect(() => {
        let availableActions = [];
        if (masterTenant) {
            if (item.type === 'deposit' && item.state !== 'reverted'
                && (features && features.includes('trading_transfers.update'))) {
                availableActions = actions.actionsList.filter(a => a.name === 'Revert');
            }
            else if (item.type === 'withdrawal' && item.state !== 'done'
                && item.state !== 'declined' && item.state !== 'pending_sign') {
                availableActions = actions.actionsList.filter(a => a.name === 'Decline' || a.name === 'Approve');
            }
            else if (item.type === 'withdrawal' && item.state === 'pending_sign' && item.client_code === (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code)) {
                availableActions = actions.actionsList.filter(a => a.name === 'Sign');
            }
            else {
                availableActions = [];
            }
        }
        else if (item.type === 'withdrawal' && item.state === 'pending_sign' && item.creator_code !== meCode
            && (features && features.includes('trading_transfers_sign.create'))) {
            availableActions = actions.actionsList.filter(a => a.name === 'Sign');
        }
        else {
            availableActions = [];
        }
        setActionsToRender(availableActions);
    }, [item, features, clientInfo]);
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { size: "small", "aria-label": "more", onClick: handleActionsClick, disabled: actionsToRender.length === 0 },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { component: "nav", "aria-label": "more actions", dense: true }, actionsToRender.map(a => (React.createElement(ListItem, { key: a.name, onClick: () => toggleDialog(a.action), button: true },
                    React.createElement(ListItemIcon, null, a.action === 'approveWithdrawal' ? (React.createElement(CheckBox, { fontSize: "small" }))
                        : a.action === 'declineWithdrawal' ? (React.createElement(Cancel, { fontSize: "small" }))
                            : a.action === 'revertDeposit' ? (React.createElement(SettingsBackupRestore, { fontSize: "small" }))
                                : (React.createElement(CreateIcon, { fontSize: "small" }))),
                    React.createElement(ListItemText, { className: "trading_clients_th_name", primary: a.name }))))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Actions"),
            React.createElement(DialogContent, null, action ? renderActionsContent(action) : null))));
};
export default TransfersCellActions;
