import React from 'react';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import SubOrdersItem from './SubOrdersItem';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import { TableContainer } from '@mui/material';
const SubOrdersList = ({ subOrders, partialCode, executingItems, calculateReminder, amount, onError, onErrorMessage, exchanges, features, }) => {
    const handleCalculateReminder = () => {
        calculateReminder(subOrders, amount);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null, subOrders.length === 0
            ? (React.createElement(Alert, { severity: "info", variant: "outlined" }, "Use fields bellow to add new sub-order."))
            : (React.createElement(TableContainer, null,
                React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            React.createElement(TableCell, null, "Pair"),
                            React.createElement(TableCell, null, "Amount"),
                            React.createElement(TableCell, null, "Type"),
                            React.createElement(TableCell, null, "Action"),
                            React.createElement(TableCell, null, "Exchange"),
                            React.createElement(TableCell, null, "Limit"),
                            React.createElement(TableCell, null, "Execution Price"),
                            React.createElement(TableCell, null, "Finished"),
                            React.createElement(TableCell, null, "Status"),
                            React.createElement(Permissions, { features: features, permissions: ['order.update'] },
                                React.createElement(TableCell, null, "Actions")))),
                    React.createElement(TableBody, null, subOrders.map(subOrder => (React.createElement(SubOrdersItem, { key: subOrder.id, onError: onError, features: features, exchanges: exchanges, subOrderItem: subOrder, partialCode: partialCode, executingItems: executingItems, onErrorMessage: onErrorMessage, calculateReminder: handleCalculateReminder }))))))))));
};
export default SubOrdersList;
