import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import Body from './Body';
const getSettingById = (settings, settingId) => {
    if (!settings.length) {
        return null;
    }
    const find = settings.find(item => item && item.id.includes(settingId));
    return find === null || find === void 0 ? void 0 : find.value;
};
/* eslint-disable @typescript-eslint/restrict-template-expressions */
const LadderBody = ({ selectedPair, selectedExchange, partialOrder, step, settings, currencies, }) => {
    const [pair, setPair] = useState('');
    const [channel, setChannel] = useState('');
    const [localPair, setLocalPair] = useState('');
    const [channelFinal, setChannelFinal] = useState('');
    const [channelOrderbook, setChannelOrderbook] = useState('');
    const [channelBySettings, setChannelBySettings] = useState('');
    const [channelConsolidated, setChannelConsolidated] = useState('');
    const [channelOrderbookConsolidated, setChannelOrderbookConsolidated] = useState('');
    const [channelBySettingsConsolidated, setChannelBySettingsConsolidated] = useState('');
    useEffect(() => {
        if (settings.length) {
            const settingsBaseArr = getSettingById(settings, 'Base');
            const settingsQuoteArr = getSettingById(settings, 'Quote');
            if (settingsBaseArr && settingsQuoteArr) {
                const pairLocalVal = `${settingsBaseArr
                    .value}/${settingsQuoteArr.value}`.toLowerCase();
                setLocalPair(pairLocalVal);
            }
        }
    }, [settings]);
    useEffect(() => {
        if (selectedPair) {
            const pairVal = `${selectedPair.base}/${selectedPair.quote}`.toLowerCase();
            setPair(pairVal);
        }
    }, [selectedPair]);
    useEffect(() => {
        if (pair && step !== 0) {
            const channelOrderbookConsolidatedVal = `orderbook-simple-consolidated-aggregated-${step}-${pair}`;
            setChannelOrderbookConsolidated(channelOrderbookConsolidatedVal);
        }
    }, [step, pair]);
    useEffect(() => {
        if (localPair && step !== 0) {
            const channelBySettingsConsolidatedVal = `orderbook-simple-consolidated-aggregated-${step}-${localPair}`;
            setChannelBySettingsConsolidated(channelBySettingsConsolidatedVal);
        }
    }, [step, localPair]);
    useEffect(() => {
        if (selectedExchange && pair && step !== 0) {
            const channelOrderbookVal = `orderbook-simple-${selectedExchange.value}-aggregated-${step}-${pair}`;
            setChannelOrderbook(channelOrderbookVal);
        }
    }, [selectedExchange, step, pair]);
    useEffect(() => {
        if (settings.length) {
            const settingsExchangeArr = getSettingById(settings, 'Exchanges');
            if (settingsExchangeArr && localPair && step !== 0) {
                const channelBySettingsVal = `orderbook-simple-${settingsExchangeArr.value
                    .toLowerCase()}-aggregated-${step}-${localPair}`;
                setChannelBySettings(channelBySettingsVal);
            }
        }
    }, [settings, step, localPair]);
    useEffect(() => {
        const settingsGlobalIgnoreArr = getSettingById(settings, 'IgnoreGlobal');
        if (settingsGlobalIgnoreArr && channelBySettings) {
            setChannelFinal(channelBySettings);
        }
        else if (channelOrderbook) {
            setChannelFinal(channelOrderbook);
        }
    }, [channelBySettings, settings, channelOrderbook]);
    useEffect(() => {
        const settingsGlobalIgnoreArr = getSettingById(settings, 'IgnoreGlobal');
        if (settingsGlobalIgnoreArr && channelBySettingsConsolidated) {
            setChannelConsolidated(channelBySettingsConsolidated);
        }
        else if (channelOrderbookConsolidated) {
            setChannelConsolidated(channelOrderbookConsolidated);
        }
    }, [channelOrderbookConsolidated, settings, channelBySettingsConsolidated]);
    useEffect(() => {
        if (channelConsolidated || channelFinal) {
            const channelVal = partialOrder ? channelConsolidated : channelFinal;
            setChannel(channelVal);
        }
    }, [partialOrder, channelConsolidated, channelFinal]);
    if (!channel) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return React.createElement(Body, { channel: channel, selectedPair: selectedPair, currencies: currencies });
};
const stateToProps = (state) => {
    const { selectedPair, selectedExchange, partialOrder, currencies, } = state.placeOrder;
    return {
        currencies,
        selectedPair,
        partialOrder,
        selectedExchange,
    };
};
export default connect(stateToProps)(LadderBody);
