// eslint-disable-next-line
export const statusBadgeData = {
    orderStatusColors: {
        Open: '#fff',
        Filled: '#fff',
        Canceled: '#fff',
        Expired: '#fff',
        CanceledPartiallyFilled: '#fff',
        ToCancel: '#f5a623',
    },
    orderStatusBackgrounds: {
        Open: '#f5a623',
        Filled: '#32C6A1',
        Canceled: '#F06158',
        Expired: '#F06158',
        CanceledPartiallyFilled: '#F06158',
        ToCancel: '#fff',
    },
    orderStatusBorders: {
        Open: 'none',
        Filled: 'none',
        Canceled: 'none',
        Expired: 'none',
        CanceledPartiallyFilled: 'none',
        ToCancel: '1px solid #f5a623 !important',
    },
    orderStatusColorsRegular: {
        Open: '#fff',
        Filled: '#fff',
        Canceled: '#fff',
        Rejected: '#fff',
        CanceledPartiallyFilled: '#fff',
        ToCancel: '#f5a623',
        Prepared: '#9bcd42',
    },
    orderStatusBackgroundsRegular: {
        Open: '#f5a623',
        Filled: '#32C6A1',
        Canceled: '#F06158',
        Rejected: '#F06158',
        CanceledPartiallyFilled: '#F06158',
        ToCancel: '#fff',
        Prepared: '#fff',
    },
    orderStatusBordersRegular: {
        Open: 'none',
        Filled: 'none',
        Canceled: 'none',
        Rejected: 'none',
        CanceledPartiallyFilled: 'none',
        ToCancel: '1px solid #f5a623 !important',
        Prepared: '1px solid #9bcd42 !important',
    },
    stateColorsWithdrawals: {
        created: '#fff',
        failed: '#fff',
        done: '#fff',
        pending: '#f5a623',
        unknown_creation_error: '#9bcd42',
    },
    stateBackgroundsWithdrawals: {
        created: '#f5a623',
        failed: '#F06158',
        done: '#32C6A1',
        pending: '#fff',
        unknown_creation_error: '#fff',
    },
    stateBordersWithdrawals: {
        created: 'none',
        failed: 'none',
        done: 'none',
        pending: '1px solid #f5a623 !important',
        unknown_creation_error: '1px solid #9bcd42 !important',
    },
    stateColorsDeposits: {
        on_compliance: '#fff',
        aml_checked: '#fff',
        aml_failed: '#fff',
        skipped: '#f5a623',
        compliance_pending: '#f5a623',
        compliance_failed: '#fff',
        done: '#fff',
        failed: '#fff',
    },
    stateBackgroundsDeposits: {
        on_compliance: '#f5a623',
        aml_checked: '#32C6A1',
        aml_failed: '#F06158',
        skipped: '#fff',
        compliance_pending: '#fff',
        compliance_failed: '#F06158',
        done: '#32C6A1',
        failed: '#F06158',
    },
    stateBordersDeposits: {
        on_compliance: 'none',
        aml_checked: 'none',
        aml_failed: 'none',
        skipped: '1px solid #f5a623 !important',
        compliance_pending: '1px solid #f5a623 !important',
        compliance_failed: 'none',
        done: 'none',
        failed: 'none',
    },
    stateColorsWithdrawalsAddress: {
        approved: '#fff',
        aml_failed: '#fff',
        pending_sign: '#f5a623',
        pending_admin_delete: '#fff',
        pending_admin_approve: '#fff',
    },
    stateBackgroundsWithdrawalsAddress: {
        approved: '#32C6A1',
        pending_sign: '#fff',
        aml_failed: '#F06158',
        pending_admin_delete: '#F06158',
        pending_admin_approve: '#f5a623',
    },
    stateBordersWithdrawalsAddress: {
        approved: 'none',
        aml_failed: 'none',
        pending_admin_delete: 'none',
        pending_admin_approve: 'none',
        pending_sign: '1px solid #f5a623 !important',
    },
    stateColorsClearerWithdrawals: {
        filled: '#fff',
        failed: '#fff',
        created: '#fff',
    },
    stateBackgroundsClearerWithdrawals: {
        filled: '#32C6A1',
        failed: '#F06158',
        created: '#f5a623',
    },
    stateBordersClearerWithdrawals: {
        filled: 'none',
        failed: 'none',
        created: 'none',
    },
    stateColorsTradingTransfers: {
        done: '#fff',
        declined: '#fff',
        reverted: '#9bcd42',
        in_progress: '#fff',
        pending_sign: '#f5a623',
    },
    stateBackgroundsTradingTransfers: {
        done: '#4ce1b6',
        reverted: '#fff',
        declined: '#ff4861',
        pending_sign: '#fff',
        in_progress: '#f5a623',
    },
    stateBordersTradingTransfers: {
        done: 'none',
        declined: 'none',
        in_progress: 'none',
        reverted: '1px solid #9bcd42 !important',
        pending_sign: '1px solid #f5a623 !important',
    },
    stateColorsRFQManagement: {
        active: '#fff',
        deleted: '#fff',
        pendingDelete: '#fff',
    },
    stateBackgroundsRFQManagement: {
        active: '#4ce1b6',
        deleted: '#ff4861',
        pendingDelete: '#f5a623',
    },
    stateBordersRFQManagement: {
        active: 'none',
        deleted: 'none',
        pendingDelete: 'none',
    },
    stateColorsWallets: {
        unknown_transaction: '#fff',
        active: '#fff',
        inactive: '#fff',
    },
    stateBackgroundsWallets: {
        unknown_transaction: '#f5a623',
        active: '#4ce1b6',
        inactive: '#ff4861',
    },
    stateBordersWallets: {
        unknown_transaction: 'none',
        active: 'none',
        inactive: 'none',
    },
};
