import React, { useState, useEffect } from 'react';
import moment from 'moment';
import shortid from 'shortid';
import { Box, Checkbox } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { useResizeDetector } from 'react-resize-detector';
import { TransfersCellItem } from '../../styling/style';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
const AddressTableCell = ({ value, columnKey, handleBulkChange, bulk, item, }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (columnKey === 'address') {
        const bulkItem = bulk.find(b => b.code === item.code);
        const checked = (bulkItem && bulkItem.value) || false;
        const idKey = shortid.generate();
        return (React.createElement(TableCell, { align: "left", ref: ref },
            React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 },
                React.createElement(Box, null, idKey ? (React.createElement("label", { htmlFor: idKey, className: "checkbox-btn", style: { marginBottom: '5px' } },
                    React.createElement(Checkbox, { id: idKey, name: idKey, checked: checked, className: "checkbox-btn__checkbox", onChange: e => handleBulkChange(e, item.code) }))) : null),
                React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center' },
                    value ? (React.createElement(CopyValue, { value: value })) : null,
                    React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)))));
    }
    if (columnKey === 'created_at' || columnKey === 'updated_at') {
        return (React.createElement(TableCell, { align: "left" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'N/A'));
    }
    if (columnKey === 'currency') {
        return (React.createElement(TableCell, { align: "left" }, value ? value.toUpperCase() : 'N/A'));
    }
    return (React.createElement(TableCell, { align: "left" }, value || 'N/A'));
};
export default AddressTableCell;
