import React, { useState } from 'react';
import { CELL_TYPE } from './DLTTableModel';
import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel, useTheme } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
const DLTTableHeadComponent = (props) => {
    const { onSelectAllClick, order = 'desc', orderBy = 'id', bulkSelect = false, onRequestSort } = props;
    const theme = useTheme();
    const [columns, setColumns] = useState(props.columns);
    const createSortHandler = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        if (onRequestSort) {
            onRequestSort(isAsc ? 'desc' : 'asc', property);
        }
    };
    return (React.createElement(TableHead, null,
        React.createElement(TableRow, { sx: { backgroundColor: theme.palette.secondary.light } },
            !bulkSelect ? null : (React.createElement(TableCell, { padding: 'checkbox' },
                React.createElement(Checkbox, { color: 'primary', 
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    // checked={rowCount > 0 && numSelected === rowCount}
                    onChange: onSelectAllClick, inputProps: {
                        'aria-label': 'select all',
                    } }))),
            columns.map((headCell) => (React.createElement(TableCell, { sx: { width: headCell.width }, key: headCell.id, align: headCell.type === CELL_TYPE.NUMERIC || headCell.id === 'actions' ? 'right' : headCell.align || 'left', padding: headCell.disablePadding ? 'none' : 'normal', sortDirection: orderBy === headCell.id ? order : false }, headCell.sortable ? (React.createElement(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: createSortHandler(headCell.sortId ? headCell.sortId : headCell.id) },
                headCell.label,
                orderBy === headCell.id ? (React.createElement(Box, { component: 'span', sx: visuallyHidden }, order === 'desc' ? 'sorted descending' : 'sorted ascending')) : null)) : (headCell.label)))))));
};
export default DLTTableHeadComponent;
