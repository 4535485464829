import React, { Fragment } from 'react';
import { Box, Dialog, DialogContent } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { BalanceLabel, BalanceValue, Delimiter, InfoItem, InfoItemLabel, InfoItemValue, OrderBalances, OrderInfo, } from '../../MergedOrderList/styling/styledComponents';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import DialogHeader from '../../../../../../shared/components/DialogHeader';
const envSettings = getEnvSettings();
const SubOrderbookModal = ({ open, toggleFunc, item, total, theme, }) => {
    const toggleModal = () => {
        toggleFunc();
    };
    const statusesMap = {
        Open: 'Open',
        Filled: 'Filled',
        Canceled: 'Canceled',
        Rejected: 'Rejected',
        CanceledPartiallyFilled: 'CanceledPartiallyFilled',
        ToCancel: 'ToCancel',
        Prepared: 'Prepared',
    };
    const date = item.finished ? (new Date(item.finished)).toLocaleDateString() : null;
    const time = (new Date(item.finished)).toLocaleTimeString();
    return (React.createElement(Fragment, null,
        React.createElement(Dialog, { maxWidth: "sm", fullWidth: true, open: open },
            React.createElement(DialogHeader, { title: "Order detail", toggleFunc: toggleModal }),
            React.createElement(DialogContent, null,
                React.createElement(Box, null,
                    React.createElement(OrderInfo, null,
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Status"),
                            React.createElement(InfoItemValue, null, statusesMap[item.status])),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Internal ID"),
                            React.createElement(InfoItemValue, null, item.code)),
                        React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Exchange ID"),
                            React.createElement(InfoItemValue, null, item.external_code)),
                        date && (React.createElement(InfoItem, null,
                            React.createElement(InfoItemLabel, null, "Finished"),
                            React.createElement(InfoItemValue, null,
                                date,
                                ",",
                                React.createElement("span", { className: "info-time" }, time))))),
                    React.createElement(OrderBalances, null,
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, item.action === 'Buy' ? 'Bought' : 'Sold'),
                            React.createElement(BalanceValue, null, `${item.amount} ${item.market_item.base}`)),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Price per"),
                            React.createElement(BalanceValue, null, `${item.executed.price} ${item.market_item.quote}`)),
                        React.createElement(Delimiter, null),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Subtotal"),
                            React.createElement(BalanceValue, null, `${item.executed.value} ${item.market_item.quote}`)),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Bank fee"),
                            React.createElement(BalanceValue, null, `${item.executed.bank_fee} ${item.market_item.quote}`)),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Broker fee"),
                            React.createElement(BalanceValue, null, `${item.executed.broker_fee} ${item.market_item.quote}`)),
                        React.createElement(Delimiter, null),
                        React.createElement(InfoItem, null,
                            React.createElement(BalanceLabel, null, "Total"),
                            React.createElement(BalanceValue, null,
                                React.createElement(NumericFormat, { value: total, displayType: "text", thousandSeparator: envSettings.thousandDivider }),
                                "\u00A0",
                                item.market_item.quote))))))));
};
export default SubOrderbookModal;
