import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import * as Styled from '../../../Crypto/components/MarketOverview/styling/styledComponents';
const RowSingleMarket = ({ marketItem }) => {
    const { base, quote } = marketItem;
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null,
            React.createElement(Styled.TextUppercase, null, base)),
        React.createElement(TableCell, null, quote)));
};
export default RowSingleMarket;
