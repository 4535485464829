import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import WidgetTradingStats from './Stats/WidgetTradingStats';
import { PanelContainer } from '../../../Admin/styling/style';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
/* eslint-disable no-nested-ternary, camelcase */
const TradingCompliance = ({ clientInfo, selectedClient, masterTenant, }) => {
    const [activeClient, setActiveClient] = useState(null);
    const regularClient = masterTenant
        ? { label: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name, value: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code } : null;
    // Effects
    useEffect(() => {
        if (selectedClient) {
            setActiveClient(selectedClient);
        }
        else {
            setActiveClient(null);
        }
    }, [selectedClient]);
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, null, regularClient ? (React.createElement("div", null,
            React.createElement(WidgetTradingStats, { activeClient: activeClient }))) : null)));
};
const mapStateToProps = (state) => ({
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
});
export default connect(mapStateToProps)(TradingCompliance);
