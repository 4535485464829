export const ORDERS_CANCEL_REQUEST = 'ORDERS_CANCEL_REQUEST';
export const ORDERS_CANCEL_SUCCESS = 'ORDERS_CANCEL_SUCCESS';
export const ORDERS_CANCEL_FAIL = 'ORDERS_CANCEL_FAIL';
export const ORDERS_FETCH_REQUEST_SILENT = 'ORDERS_FETCH_REQUEST_SILENT';
export const ORDERS_CANCEL_ALL_REQUEST = 'ORDERS_CANCEL_ALL_REQUEST';
export const ADMIN_ORDERS_CANCEL_ALL_REQUEST = 'ADMIN_ORDERS_CANCEL_ALL_REQUEST';
export const ORDERS_CANCEL_ALL_SUCCESS = 'ORDERS_CANCEL_ALL_SUCCESS';
export const ORDERS_CANCEL_ALL_FAIL = 'ORDERS_CANCEL_ALL_FAIL';
export const ORDERS_TAB_SELECTED_SET_UPDATE = 'ORDERS_TAB_SELECTED_SET_UPDATE';
export const ORDERS_ABORT_REQUEST = 'ORDERS_ABORT_REQUEST';
export const ORDERS_ABORT_SUCCESS = 'ORDERS_ABORT_SUCCESS';
export const ORDERS_ABORT_FAIL = 'ORDERS_ABORT_FAIL';
