import React, { useEffect, useState } from 'react';
import { Box, Grid, useTheme } from '@mui/material';
import { Amount } from '../../../../../shared/components/styled/amount';
import { NumericFormat } from 'react-number-format';
import Decimal from 'decimal.js';
const convertAmountValue = (val, precision) => {
    if (!val) {
        return;
    }
    const number = new Decimal(val).toFixed(precision);
    const trimmed = parseFloat(number).toString();
    return trimmed;
};
const RfqOrderBook = (props) => {
    const { orderbook, onSelect, quantity } = props;
    const theme = useTheme();
    const [selectedQuantity, setSelectedQuantity] = useState(quantity);
    useEffect(() => {
        setSelectedQuantity(quantity);
    }, [quantity]);
    const onSpreadSelect = (spread) => {
        onSelect(spread);
    };
    const isRowSelected = (itemValue) => {
        return itemValue === selectedQuantity;
    };
    return (React.createElement(Box, { sx: { minWidth: 600 } }, orderbook.map((item, index) => (React.createElement(Grid, { container: true, key: index, onClick: () => onSpreadSelect(item.totalAsk), sx: {
            cursor: 'pointer',
            color: theme.palette.getContrastText(orderbook.length > 1 && isRowSelected(item.totalAsk)
                ? theme.palette.primary.light
                : theme.palette.background.default),
            borderBottom: orderbook.length > 1 && isRowSelected(item.totalAsk)
                ? 'none'
                : `0.5px solid ${theme.palette.divider}`,
            backgroundColor: orderbook.length > 1 && isRowSelected(item.totalAsk) ? theme.palette.primary.light : 'none',
        } },
        React.createElement(Grid, { item: true, xs: 3, sx: { position: 'relative', textAlign: 'right' } },
            React.createElement(Box, { sx: {
                    position: 'absolute',
                    display: isRowSelected(item.totalAsk) ? 'none' : 'block',
                    right: 0,
                    top: 0,
                    background: '#22822e87',
                    height: '100%',
                    width: (+item.totalBid * 100) / +orderbook[orderbook.length - 1].totalBid + '%',
                } }),
            React.createElement(Box, { sx: {
                    position: 'absolute',
                    right: 0,
                    top: 0,
                } },
                React.createElement(Amount, { size: '14' },
                    React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.totalBid, 2), thousandSeparator: "'" })))),
        React.createElement(Grid, { item: true, xs: 2, sx: { textAlign: 'right' } },
            React.createElement(Amount, { size: '14' },
                React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.hitBid, 2), thousandSeparator: "'" }))),
        React.createElement(Grid, { item: true, xs: 2, sx: { textAlign: 'center' } },
            React.createElement(Amount, { size: '10' },
                React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue((+item.liftAsk - +item.hitBid).toFixed(2), 2), thousandSeparator: "'" }))),
        React.createElement(Grid, { item: true, xs: 2 },
            React.createElement(Amount, { size: '14' },
                React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.liftAsk, 2), thousandSeparator: "'" }))),
        React.createElement(Grid, { item: true, xs: 3, sx: { position: 'relative' } },
            React.createElement(Box, { sx: {
                    position: 'absolute',
                    display: isRowSelected(item.totalAsk) ? 'none' : 'block',
                    left: 0,
                    background: '#833e3ede',
                    height: '100%',
                    width: (+item.totalAsk * 100) / +orderbook[orderbook.length - 1].totalAsk + '%',
                } }),
            React.createElement(Box, { sx: {
                    position: 'absolute',
                    left: 0,
                    top: 0,
                } },
                React.createElement(Amount, { size: '14' },
                    React.createElement(NumericFormat, { displayType: 'text', value: convertAmountValue(item.totalAsk, 2), thousandSeparator: "'" })))))))));
};
export default RfqOrderBook;
