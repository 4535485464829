import React, { useEffect, useState } from 'react';
import WebhooksTable from './WebhooksTable';
import { Alert, Box, Button, LinearProgress, Paper, Typography } from '@mui/material';
import useWebhooksService from './WebhooksService';
import AddWebhookDialog from './AddWebhookDialog';
const WebhooksContent = () => {
    const { getWebhooks, webhooks, addWebhook, deleteWebhook, loading } = useWebhooksService();
    const [webhookAddDialog, setWebhookAddDialog] = useState(false);
    useEffect(() => {
        getWebhooks();
    }, []);
    const onToggleAddWebhook = () => {
        setWebhookAddDialog(!webhookAddDialog);
    };
    const onAddWebhook = (url) => {
        addWebhook(url).then(() => {
            setWebhookAddDialog(false);
        });
    };
    const onDeleteWebhook = (code) => {
        deleteWebhook(code);
    };
    return (React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' }, py: 2 },
            React.createElement(Typography, { variant: 'h5' }, "Webhooks"),
            React.createElement(Button, { variant: 'contained', size: 'small', onClick: () => setWebhookAddDialog(true) }, "Add Webhook")),
        loading && React.createElement(LinearProgress, null),
        webhooks.length > 0 ? (React.createElement(Box, { sx: { overflowX: 'scroll' } },
            React.createElement(Paper, null,
                React.createElement(WebhooksTable, { webhooks: webhooks, onDeleteWebhook: onDeleteWebhook })))) : (!loading && (React.createElement(Alert, { variant: 'outlined', color: 'info' }, "No webhooks added"))),
        webhookAddDialog && (React.createElement(AddWebhookDialog, { open: webhookAddDialog, toggle: onToggleAddWebhook, onAdd: onAddWebhook }))));
};
export default WebhooksContent;
