/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import showNotification from '../../../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getWidgetsSettingsByKey } from '../../../../../redux/selectors/widgetSettingsSelector';
import { getAllCustomers, getCustomersSelectedInputInterface, } from '../../../../../redux/selectors/customerSearchSelectors';
import { Alert, Box, CircularProgress } from '@mui/material';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
import RfqSettingsService from '../../../../../services/rfqSettingsService';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import Messages from '../../../../../shared/helpers/errorMessages';
import RfQTrading from './RfqTrading';
import { LoaderWrapper } from '../../../../../shared/components/styled/loaderWrapper';
const WidgetRfqOrders = ({ settings, customersOptions, customers, clientInfo, features, selectedClient, selectedCustomer, customerCode, }) => {
    const [disableReverse, setDisableReverse] = useState(false);
    const [rfqSettings, setRfqSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    useEffect(() => {
        if (features && features.length && features.includes('restrict_order_size.read')) {
            setDisableReverse(true);
        }
        else {
            setDisableReverse(false);
        }
    }, [features]);
    useEffect(() => {
        if (selectedCustomer) {
            fetchRfqSettings(null, selectedCustomer.code);
        }
        else if (customerCode) {
            fetchRfqSettings(null, customerCode);
        }
        else {
            fetchRfqSettings((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code));
        }
    }, [selectedCustomer, selectedClient, customerCode]);
    const fetchRfqSettings = (codeClient, customerCode) => {
        const rfqService = new RfqSettingsService({
            url: `/trade/rfq/assigned`,
            method: 'get',
            params: { client_code: codeClient, customer_code: customerCode },
        });
        setLoading(true);
        rfqService
            .makeRequest()
            .then((data) => {
            setLoading(false);
            setRfqSettings(data.records);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RFQ_SETTINGS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoading(false);
        });
    };
    if (loading) {
        return (React.createElement(LoaderWrapper, null,
            React.createElement(CircularProgress, { size: '33px' })));
    }
    return (React.createElement(Box, null, rfqSettings.length ? (React.createElement(RfQTrading, { isReverseDisabled: disableReverse, features: features, settings: settings, customerCode: customerCode, rfqSettings: rfqSettings, customersOptions: customersOptions, customers: customers })) : (React.createElement(Box, { p: 2 },
        React.createElement(Alert, { color: 'info', variant: 'outlined', severity: 'info' }, "No streams available")))));
};
const stateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    return {
        // @ts-ignore
        features: state.client.features,
        clientInfo: state.client.clientInfo,
        selectedClient: getSelectedClientInputValue(state),
        selectedCustomer: state.customersSearch.selectedCustomer,
        customerCode: state.client.customer_code,
        settings: getWidgetsSettingsByKey(state, widgetKey),
        customersOptions: getCustomersSelectedInputInterface(state),
        customers: getAllCustomers(state),
        dashboard: state.workspace.dashboards.find((d) => d.name === 'tradingRfq'),
        masterTenant: state.client.masterTenant,
    };
};
export default connect(stateToProps)(WidgetRfqOrders);
