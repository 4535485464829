import React, { useState } from 'react';
import { Box, Grid, Alert, Button, TextField, IconButton, Typography, InputAdornment, CircularProgress, Link, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import { connect } from 'react-redux';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
const useHelperTextStyles = makeStyles(() => ({
    root: {},
}));
const LogInForm = ({ handleSubmit, errorMessage = '', errorMsg = '', fields, onFieldChange, validation, loading, theme, }) => {
    const [showPassword, setShowPassword] = useState(false);
    const checkValidation = (fieldName) => {
        const fieldError = validation.find(e => e.name === fieldName);
        return fieldError && fieldError.message;
    };
    const handleClickShowPassword = () => setShowPassword(prev => !prev);
    const helperTextStyles = useHelperTextStyles();
    const isEmptyFields = fields.password !== '' && fields.login !== '';
    return (React.createElement("form", { onSubmit: handleSubmit },
        React.createElement(Box, { display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2 },
            React.createElement(Typography, { variant: 'subtitle1', align: 'center' }, "Login to start"),
            (!!errorMessage || !!errorMsg) ?
                React.createElement(Alert, { severity: "warning", variant: "outlined" },
                    errorMessage,
                    errorMsg) : null,
            React.createElement(Grid, { spacing: 2, container: true, alignItems: "center", justifyContent: "center" },
                React.createElement(Grid, { container: true, item: true, spacing: 1, alignItems: "center", justifyContent: "flex-start" },
                    React.createElement(Grid, { item: true, xs: 1 },
                        React.createElement(AccountOutlineIcon, { fontSize: "small" })),
                    React.createElement(Grid, { item: true, xs: 11 },
                        React.createElement(TextField, { fullWidth: true, id: "login", size: "small", name: "login", variant: "outlined", placeholder: "Email", value: fields.login, onChange: onFieldChange, className: "mui_select_wrap", helperText: `${checkValidation('login') || ''}`, FormHelperTextProps: { classes: { root: helperTextStyles.root } } }))),
                React.createElement(Grid, { container: true, item: true, spacing: 1, alignItems: "center", justifyContent: "flex-start" },
                    React.createElement(Grid, { item: true, xs: 1 },
                        React.createElement(LockIcon, { fontSize: "small" })),
                    React.createElement(Grid, { item: true, xs: 11 },
                        React.createElement(TextField, { fullWidth: true, size: "small", id: "password", name: "password", variant: "outlined", placeholder: "Password", value: fields.password, onChange: onFieldChange, className: "mui_select_wrap", type: showPassword ? 'text' : 'password', InputLabelProps: { shrink: true }, InputProps: {
                                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { size: 'small', edge: "end", "aria-label": "toggle password visibility", onClick: () => handleClickShowPassword() }, showPassword ? React.createElement(VisibilityOff, { fontSize: "small" }) : React.createElement(Visibility, { fontSize: "small" })))),
                            }, helperText: `${checkValidation('password') || ''}`, FormHelperTextProps: { classes: { root: helperTextStyles.root } } })))),
            React.createElement(Box, { mt: 3 },
                React.createElement(Button, { type: "submit", variant: "contained", fullWidth: true, color: "primary", disabled: (validation.length > 0 || loading) || !isEmptyFields }, loading ? (React.createElement(CircularProgress, { size: "24.5px" })) : 'Sign In')),
            React.createElement("div", null,
                React.createElement(Link, { href: "mailto:contact@dlt-finance.com" }, "Not working? Please, contact us!")))));
    // }
};
const stateToProps = (state) => ({
    theme: state.theme.className,
});
export default connect(stateToProps)(LogInForm);
