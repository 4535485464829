import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import Schedule from '@mui/icons-material/Schedule';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import { MaterialPopoverWrap } from '../../../Custody/styling/style';
const SchedulersCellActions = ({ item, actions }) => {
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [action, setAction] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionsToRender, setActionsToRender] = useState([]);
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (userAction) => {
        toggleOpenDialog(prev => !prev);
        setAction(userAction);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const renderActionsContent = (userAction) => {
        if (!userAction) {
            return (React.createElement("div", null, "something went wrong"));
        }
        return (React.createElement(actions.actionsComponents[userAction], {
            toggle: () => toggleDialog(),
            item,
            cellAction: actions.actions[userAction],
            actionKey: userAction.toLowerCase(),
        }));
    };
    useEffect(() => {
        setActionsToRender([...actions.actionsList]);
    }, [item]);
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { size: "small", "aria-label": "more", onClick: handleActionsClick, disabled: actionsToRender.length === 0 },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions" }, actionsToRender.map(a => (React.createElement(ListItem, { key: a.name, onClick: () => toggleDialog(a.action), button: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(Schedule, { fontSize: "small" })),
                    React.createElement(ListItemText, { className: "trading_clients_th_name", primary: a.name }))))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Actions"),
            React.createElement(DialogContent, null, action ? renderActionsContent(action) : null))));
};
export default SchedulersCellActions;
