import React, { useRef, useState, useEffect } from 'react';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import * as Styled from '../../styling/styled';
import { useTheme } from '@mui/material';
const Spread = ({ selectedPair, channel, theme }) => {
    const [spreadUpdated, setSpreadUpdate] = useState('');
    const prevChannel = useRef('');
    const refId = useRef(shortid.generate());
    const spreadUpdate = (data) => {
        const { lift_ask, hit_bid } = data.payload;
        const bid = Number.parseFloat(hit_bid);
        const ask = Number.parseFloat(lift_ask);
        const spread = bid && ask ? Decimal.sub(bid, ask).toFixed() : '0';
        setSpreadUpdate(spread);
    };
    useEffect(() => {
        const { OrderBookWS } = window;
        if (prevChannel.current !== '') {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        }
        OrderBookWS.addSubscription(channel, { callback: spreadUpdate, componentId: refId.current });
        setSpreadUpdate('');
        prevChannel.current = channel;
        return () => {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        };
    }, [channel]);
    const muiTheme = useTheme();
    return (React.createElement(Styled.PanelSpread, { bordercolor: muiTheme.palette.divider },
        React.createElement("span", { className: "price_ladder_header_color price_ladder_spread" }, selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base),
        React.createElement("span", { className: "price_ladder_header_color price_ladder_spread" }, spreadUpdated === '' ? '--' : spreadUpdated)));
};
export default Spread;
