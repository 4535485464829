var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box, Chip, CircularProgress } from '@mui/material';
import buildUrl from 'build-url';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import LogCell from './LogCell';
import { PanelContainer } from '../../styling/style';
import LogsService from '../../../../../services/logsService';
import UsersService from '../../../../../services/usersService';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { MaterialPopoverWrap, MaterialTableWrap, WalletsClientContainer } from '../../../Custody/styling/style';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const LogBook = ({ columns, clientInfo, selectedClient, masterTenant, logsWidgetHeight, }) => {
    const [page, setPage] = useState(0);
    const [totalLogs, setTotalLogs] = useState(0);
    const [loading, setLoading] = useState(false);
    const [maxHeight, setMaxHeight] = useState(480);
    const [clientCode, setClientCode] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [logs, setLogs] = useState([]);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [client] = useState(null);
    const [users, setUsers] = useState([]);
    const [logsToRender, setLogsToRender] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const dispatch = useDispatch();
    const columnsOpen = Boolean(anchorColumns);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageLogbook'));
    };
    const fetchLogs = (codeClient, pageValue, perPage, cancelToken) => {
        setLoading(true);
        const urlOptions = {
            queryParams: {
                limit: `${perPage}`,
                page: `${pageValue + 1}`,
                client_code: codeClient,
            },
            path: '/audit/events',
        };
        const endpointUrl = buildUrl('', urlOptions);
        const logsService = new LogsService({
            url: endpointUrl,
            method: 'get',
            cancelToken,
        });
        logsService.makeRequest()
            .then((responseData) => {
            const { records, total } = responseData;
            setLogs(records);
            setTotalLogs(total);
            setLoading(false);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
        });
    };
    const fetchUsers = (code, cancelToken) => __awaiter(void 0, void 0, void 0, function* () {
        const options = {
            queryParams: {
                limit: '1000',
                search: '',
                client_code: code,
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
            path: '/users',
        };
        const url = buildUrl('', options);
        const usersService = new UsersService({
            url,
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        const usersByClient = yield usersService.makeRequest();
        return usersByClient;
    });
    const onChangePage = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const regularClient = masterTenant
        ? { label: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.company_name, value: clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code } : null;
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        setLoadingUsers(true);
        let uuid = '';
        if (client) {
            uuid = client.value;
        }
        else if (selectedClient) {
            uuid = selectedClient.value;
        }
        setClientCode(uuid || (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code) || '');
        if (!masterTenant) {
            setLoadingUsers(false);
            return () => cancelTokenSource.cancel('canceled');
        }
        const fetchClientUser = () => __awaiter(void 0, void 0, void 0, function* () {
            const clientUsers = yield fetchUsers(uuid, cancelTokenSource.token);
            setUsers(clientUsers.records);
            setLoadingUsers(false);
        });
        fetchClientUser();
        return () => cancelTokenSource.cancel('canceled');
    }, [clientInfo, client, selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (clientCode) {
            fetchLogs(clientCode, page, rowsPerPage, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [clientCode, page, rowsPerPage]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        if (logs.length) {
            const r = logs.map((l) => {
                const creatorUser = users ? users.find(user => user.code === l.user_code) : null;
                const creatorName = creatorUser
                    ? creatorUser.name ? `${creatorUser.name} (${creatorUser.email})` : creatorUser.email
                    : '';
                return {
                    code: l.code,
                    creator: creatorName,
                    api_source: l.api_source,
                    event_name: l.event_name,
                    service_name: l.service_name,
                    controller_name: l.controller_name,
                    request_code: l.request_code,
                    created_at: l.created_at,
                };
            });
            setLogsToRender([...r]);
        }
    }, [logs, users]);
    useEffect(() => {
        resizeWidgetHeight(logsWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [logsWidgetHeight]);
    if (loading || loadingUsers) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            regularClient ? (React.createElement(Box, { p: 1 },
                React.createElement(Chip, { size: "small", color: "primary", label: client ? client.label : selectedClient
                        ? selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.label : clientInfo && clientInfo.company_name }))) : null,
            React.createElement("div", null,
                React.createElement(WalletsClientContainer, null,
                    React.createElement("div", null,
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            }, transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            } },
                            React.createElement(MaterialPopoverWrap, null,
                                React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                    React.createElement(ListItemText, { primary: col.title }),
                                    React.createElement(ListItemSecondaryAction, null,
                                        React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
                React.createElement(MaterialTableWrap, null,
                    React.createElement(Paper, { className: "mui_table_root" },
                        React.createElement(TableContainer, { style: { maxHeight } },
                            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                React.createElement(TableHead, null,
                                    React.createElement(TableRow, null, columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, className: "mui_table_head_bg", style: { minWidth: column.minWidth }, align: column.key === 'code' ? 'left' : 'right' }, column.title))))),
                                React.createElement(TableBody, null, logsToRender.map(log => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() }, columnsToRender.map((column, i) => {
                                    const key = column.key;
                                    const value = key ? log[key] : undefined;
                                    return (React.createElement(LogCell, { key: i, keyOf: key, value: value }));
                                }))))))),
                        React.createElement(TablePagination, { page: page, component: "div", count: totalLogs, rowsPerPage: rowsPerPage, onPageChange: onChangePage, rowsPerPageOptions: [maxHeight] })))))));
};
const mapStateToProps = (state, ownProps) => ({
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    logsWidgetHeight: state.widgets[ownProps.widgetKey],
    columns: getWidgetColumns(state, ownProps.widgetKey),
});
export default connect(mapStateToProps)(LogBook);
