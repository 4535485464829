/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import { Dialog, Button, TextField, Typography, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import TableCell from '@mui/material/TableCell';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
const useStyles = makeStyles({
    tableCell: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: grey[400],
        },
    },
});
const InstrumentFields = ({ data, handleFieldChange, columnKey, columnName, item, }) => {
    const [fieldValue, setFieldValue] = useState(0);
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const toggleDialog = () => {
        toggleOpenDialog((prev) => !prev);
        if (dialogOpen) {
            setFieldValue(0);
        }
    };
    const classes = useStyles();
    const onFieldChange = (evt) => {
        const { value } = evt.target;
        if (columnKey === 'offer_precision') {
            setFieldValue(evt.target.valueAsNumber);
        }
        else {
            setFieldValue(value);
        }
    };
    useEffect(() => {
        if (data !== undefined) {
            setFieldValue(data);
        }
    }, [data]);
    const onReferenceIdChangeDone = () => {
        const payload = {
            label: item.label,
            base_code: item.base,
            quote_code: item.quote,
            calculation_mode: item.calculation_mode,
            spread_mode: item.spread_mode,
            stream_type: item.stream_type,
            ul_shift_bps: item.ul_shift_bps,
            exchange_code: item.exchange_code,
            min_spread_bps: item.min_spread_bps,
            guaranteed_qty: item.guaranteed_qty,
            market_fees_bps: item.market_fees_bps,
            extra_spread_bps: item.extra_spread_bps,
            widening_factor_bps: item.widening_factor_bps,
            offer_precision: item.offer_precision,
        };
        const updatedPayload = Object.assign(Object.assign({}, payload), { [columnKey]: fieldValue });
        handleFieldChange(item.settings_code, updatedPayload);
        setFieldValue('0');
        toggleDialog();
    };
    return (React.createElement(TableCell, { align: 'right', className: classes.tableCell },
        React.createElement("div", { role: 'button', tabIndex: -42, onKeyDown: () => undefined, onClick: () => toggleDialog() }, data === undefined ? 'N/A' : data),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: 'xs', fullWidth: true, keepMounted: false },
            React.createElement(DialogTitle, { id: `form-dialog-title${columnKey}` },
                React.createElement("div", null, "Update Field"),
                React.createElement(Typography, { variant: 'overline' }, columnName)),
            React.createElement(DialogContent, null, columnKey === 'calculation_mode' ? (React.createElement("div", null,
                React.createElement(TextField, { select: true, fullWidth: true, size: 'small', margin: 'normal', value: fieldValue, variant: 'outlined', onChange: onFieldChange, InputLabelProps: { shrink: true } },
                    React.createElement(MenuItem, { key: 'tob', value: 'tob' }, "TOB"),
                    React.createElement(MenuItem, { key: 'mid', value: 'mid' }, "MID")))) : (React.createElement("div", null,
                React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', value: fieldValue, type: columnKey === 'offer_precision' ? 'number' : 'text', variant: 'outlined', onChange: onFieldChange, InputLabelProps: { shrink: true } })))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { color: 'primary', variant: 'contained', onClick: onReferenceIdChangeDone }, "Save"),
                React.createElement(Button, { onClick: () => toggleDialog(), variant: 'outlined' }, "Cancel")))));
};
export default InstrumentFields;
