import * as types from '../actionTypes/orderBookActionTypes';
const initialState = {
    isLoading: false,
    error: null,
    ordersTabSelected: 'regular',
};
/* eslint-disable @typescript-eslint/explicit-module-boundary-types,
  @typescript-eslint/no-unsafe-member-access,
  @typescript-eslint/no-unsafe-assignment,
  @typescript-eslint/no-unsafe-return */
const orderBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ORDERS_CANCEL_ALL_REQUEST: {
            return Object.assign(Object.assign({}, state), { isLoading: true });
        }
        case types.ORDERS_CANCEL_REQUEST: {
            return Object.assign(Object.assign({}, state), { isLoading: true });
        }
        case types.ORDERS_CANCEL_ALL_SUCCESS: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        case types.ORDERS_CANCEL_SUCCESS: {
            return Object.assign(Object.assign({}, state), { isLoading: false });
        }
        case types.ORDERS_CANCEL_ALL_FAIL: {
            return Object.assign(Object.assign({}, state), { isLoading: false, error: action.message });
        }
        case types.ORDERS_CANCEL_FAIL: {
            return Object.assign(Object.assign({}, state), { isLoading: false, error: action.message });
        }
        case types.ORDERS_TAB_SELECTED_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { ordersTabSelected: action.ordersTabSelected });
        default:
            return state;
    }
};
export default orderBookReducer;
