import flattenObject from './flattenObject';
/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any */
const filterByObjectValues = (arr, searchKey) => arr.filter((obj) => {
    const clearKey = searchKey ? String(searchKey).trim() : '';
    if (clearKey !== '') {
        const flattedCustomer = flattenObject(obj);
        return Object.keys(flattedCustomer).some((key) => {
            if (flattedCustomer[key] !== null
                && flattedCustomer[key] !== undefined
                && typeof flattedCustomer[key] !== 'boolean') {
                const toLowerCaseStr = String(flattedCustomer[key]).toLowerCase();
                return toLowerCaseStr.includes(clearKey.toLowerCase());
            }
            return false;
        });
    }
    return arr;
});
export default filterByObjectValues;
