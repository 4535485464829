import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Collapse, Checkbox, FormControlLabel, Chip, TextField, MenuItem, Box, Typography, } from '@mui/material';
import { FiltersBox, MoreFilters, FiltersReset, SelectedFilters, CreateWithdrawal, FiltersContainer, CustodyFilterPanelContainer, } from '../../../Custody/styling/style';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import { getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
/* eslint-disable camelcase, no-nested-ternary, @typescript-eslint/restrict-template-expressions,
  @typescript-eslint/no-unsafe-return, no-unneeded-ternary */
const typeOptions = [
    {
        name: 'type',
        type: 'select',
        value: 'withdrawal',
        label: 'withdrawal',
    },
    {
        name: 'type',
        type: 'select',
        value: 'deposit',
        label: 'deposit',
    },
];
const TransfersFiltersContent = ({ state, users, clients, features, customers, toggleModal, currencies, resetFilters, masterTenant, clientFilters, setClientFilters, addSelectedFilter, removeSelectedFilter, }) => {
    const [collapse, setCollapse] = useState(false);
    const [type, setType] = useState(null);
    const [creator, setCreator] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [clientOptions, setClientOptions] = useState([]);
    const [creatorOptions, setCreatorOptions] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [currencyCode, setCurrencyCode] = useState(null);
    const toggleCollapseFilter = () => {
        setCollapse(!collapse);
    };
    useEffect(() => {
        const objIndexType = state.selectedFilters.findIndex((obj) => obj.name === 'type');
        const typeValue = state.selectedFilters[objIndexType];
        setType(typeValue || null);
        const objIndexCurrency = state.selectedFilters.findIndex((obj) => obj.name === 'currency_code');
        const currencyValue = state.selectedFilters[objIndexCurrency];
        setCurrencyCode(currencyValue || null);
        const objIndexCustomer = state.selectedFilters.findIndex((obj) => obj.name === 'customer_code');
        const customerValue = state.selectedFilters[objIndexCustomer];
        setCustomer(customerValue || null);
        const objIndexCreator = state.selectedFilters.findIndex((obj) => obj.name === 'creator_code');
        const creatorValue = state.selectedFilters[objIndexCreator];
        setCreator(creatorValue || null);
        const objIndexClient = state.selectedFilters.findIndex((obj) => obj.name === 'client_code');
        const clientValue = state.selectedFilters[objIndexClient];
        setClientFilters(clientValue || null);
    }, [state]);
    useEffect(() => {
        const objIndexCustomer = state.selectedFilters.findIndex((obj) => obj.name === 'customer_code');
        const customerValue = state.selectedFilters[objIndexCustomer];
        if (customerValue) {
            removeSelectedFilter(customerValue);
        }
        const objIndexCreator = state.selectedFilters.findIndex((obj) => obj.name === 'creator_code');
        const creatorValue = state.selectedFilters[objIndexCreator];
        if (creatorValue) {
            removeSelectedFilter(creatorValue);
        }
    }, [clientFilters]);
    useEffect(() => {
        let mappedCustomers = [];
        if (customers.length) {
            mappedCustomers = customers.map((c) => ({
                value: c.code,
                type: 'select',
                name: 'customer_code',
                label: c.company_name || c.email || c.code,
            }));
        }
        setCustomerOptions(mappedCustomers);
    }, [customers]);
    useEffect(() => {
        let mappedClients = [];
        if (clients.length) {
            mappedClients = clients
                .filter((value, index, self) => index === self.findIndex((c) => c.code === value.code))
                .map((t) => {
                const item = {
                    value: t.code,
                    type: 'select',
                    name: 'client_code',
                    label: t.company_name || t.email,
                };
                return item;
            });
        }
        setClientOptions(mappedClients);
    }, [clients]);
    useEffect(() => {
        let mappedCreators = [];
        if (users.length) {
            mappedCreators = users
                .filter((value, index, self) => index === self.findIndex((c) => c.code === value.code))
                .map((u) => {
                const item = {
                    value: u.code,
                    type: 'select',
                    name: 'creator_code',
                    label: u.name || u.email,
                };
                return item;
            });
        }
        setCreatorOptions(mappedCreators);
    }, [users]);
    useEffect(() => {
        if (currencies && currencies.length) {
            const mapped = currencies.map((c) => ({
                label: c.label,
                type: 'select',
                name: 'currency_code',
                value: c.value.toLowerCase(),
            }));
            setCurrencyOptions(mapped);
        }
    }, [currencies]);
    const handleCheckboxChange = (event, value) => {
        const filter = { value: value.label, type: 'checkbox', name: value.name, label: value.label };
        if (event.target.checked) {
            addSelectedFilter(filter);
        }
        else {
            removeSelectedFilter(filter);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CustodyFilterPanelContainer, null,
            React.createElement(FiltersContainer, null,
                React.createElement(MoreFilters, null,
                    React.createElement(Button, { size: 'small', variant: 'outlined', type: 'button', color: 'primary', onClick: toggleCollapseFilter },
                        collapse ? '-' : '+',
                        " Quick filters")),
                state.selectedFilters.length ? (React.createElement(FiltersReset, null,
                    React.createElement(Button, { size: 'small', variant: 'outlined', type: 'button', color: 'inherit', onClick: () => resetFilters() }, "Reset filters"))) : null,
                masterTenant ? (React.createElement(Permissions, { features: features, permissions: ['trading_transfers.create'] },
                    React.createElement(CreateWithdrawal, null,
                        React.createElement(Button, { size: 'small', color: 'primary', variant: 'outlined', onClick: () => toggleModal() }, "New Deposit")))) : null,
                React.createElement(SelectedFilters, null,
                    React.createElement("div", null,
                        React.createElement(FiltersBox, null, state.selectedFilters.map((filter) => (React.createElement(Chip, { size: 'small', style: { marginRight: '4px' }, key: filter.label, color: 'primary', label: filter.label, onDelete: () => {
                                removeSelectedFilter(filter);
                                if (filter.name === 'client_code') {
                                    const isCustomer = state.selectedFilters.find((f) => f.name === 'customer_code');
                                    const isCreator = state.selectedFilters.find((f) => f.name === 'creator_code');
                                    if (isCustomer)
                                        removeSelectedFilter(isCustomer);
                                    if (isCreator)
                                        removeSelectedFilter(isCreator);
                                }
                            } }))))))),
            React.createElement(Collapse, { in: collapse },
                React.createElement(Box, { p: 1 },
                    React.createElement("div", null,
                        React.createElement(Box, { display: 'flex', gap: 1, alignItems: 'center' },
                            React.createElement(Typography, { variant: 'subtitle1' }, "State:"),
                            React.createElement(FormControlLabel, { label: 'in_progress', control: React.createElement(Checkbox, { id: 'in_progress', checked: !!state.selectedFilters.find((opt) => opt.label === 'in_progress'), onChange: (event) => handleCheckboxChange(event, { name: 'state', label: 'in_progress' }) }) }),
                            React.createElement(FormControlLabel, { label: 'done', control: React.createElement(Checkbox, { id: 'done', checked: !!state.selectedFilters.find((opt) => opt.label === 'done'), onChange: (event) => handleCheckboxChange(event, { name: 'state', label: 'done' }) }) }),
                            React.createElement(FormControlLabel, { label: 'declined', control: React.createElement(Checkbox, { id: 'declined', checked: !!state.selectedFilters.find((opt) => opt.label === 'declined'), onChange: (event) => handleCheckboxChange(event, { name: 'state', label: 'declined' }) }) }),
                            React.createElement(FormControlLabel, { label: 'reverted', control: React.createElement(Checkbox, { id: 'reverted', checked: !!state.selectedFilters.find((opt) => opt.label === 'reverted'), onChange: (event) => handleCheckboxChange(event, { name: 'state', label: 'reverted' }) }) }),
                            React.createElement(FormControlLabel, { label: 'pending_sign', control: React.createElement(Checkbox, { id: 'pending_sign', checked: !!state.selectedFilters.find((opt) => opt.label === 'pending_sign'), onChange: (event) => handleCheckboxChange(event, { name: 'state', label: 'pending_sign' }) }) })),
                        React.createElement(Box, { display: 'flex', gap: 1, maxWidth: '840px' },
                            React.createElement(TextField, { select: true, variant: 'outlined', size: 'small', fullWidth: true, label: 'Currency code', value: (currencyCode === null || currencyCode === void 0 ? void 0 : currencyCode.value) || '', onChange: (event) => addSelectedFilter({
                                    value: event.target.value,
                                    label: event.target.value.toUpperCase(),
                                    name: 'currency_code',
                                    type: 'select',
                                }) }, currencyOptions.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label)))),
                            React.createElement(TextField, { select: true, variant: 'outlined', size: 'small', fullWidth: true, label: 'Type', value: (type === null || type === void 0 ? void 0 : type.value) || '', onChange: (event) => addSelectedFilter({ value: event.target.value, label: 'Type', name: 'type', type: 'select' }) }, typeOptions.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label)))),
                            masterTenant ? (React.createElement(React.Fragment, null,
                                React.createElement(TextField, { select: true, variant: 'outlined', size: 'small', fullWidth: true, label: 'Client', value: (clientFilters === null || clientFilters === void 0 ? void 0 : clientFilters.value) || '', onChange: (event) => {
                                        var _a;
                                        return addSelectedFilter({
                                            value: event.target.value,
                                            label: ((_a = clientOptions.find((c) => c.value === event.target.value)) === null || _a === void 0 ? void 0 : _a.label) || event.target.value,
                                            name: 'client_code',
                                            type: 'select',
                                        });
                                    } }, clientOptions.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label)))))) : null,
                            React.createElement(TextField, { select: true, variant: 'outlined', size: 'small', fullWidth: true, label: 'Customer', value: (customer === null || customer === void 0 ? void 0 : customer.value) || '', disabled: customerOptions.length === 0 || !clientFilters, onChange: (event) => {
                                    var _a;
                                    return addSelectedFilter({
                                        value: event.target.value,
                                        label: ((_a = customerOptions.find((c) => c.value === event.target.value)) === null || _a === void 0 ? void 0 : _a.label) || event.target.value,
                                        name: 'customer_code',
                                        type: 'select',
                                    });
                                } }, customerOptions.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label)))),
                            masterTenant ? (React.createElement(TextField, { select: true, variant: 'outlined', size: 'small', fullWidth: true, label: 'Creator', disabled: creatorOptions.length === 0 || !clientFilters, value: (creator === null || creator === void 0 ? void 0 : creator.value) || '', onChange: (event) => {
                                    var _a;
                                    return addSelectedFilter({
                                        value: event.target.value,
                                        label: ((_a = creatorOptions.find((c) => c.value === event.target.value)) === null || _a === void 0 ? void 0 : _a.label) || event.target.value,
                                        name: 'creator_code',
                                        type: 'select',
                                    });
                                } }, creatorOptions.map((option) => (React.createElement(MenuItem, { key: option.value, value: option.value }, option.label || option.value))))) : null)))))));
};
const mapStateToProps = (state) => {
    const currencies = getCustodyCurrenciesOptions(state);
    return {
        currencies,
    };
};
export default connect(mapStateToProps)(TransfersFiltersContent);
