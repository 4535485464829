import React from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import TableCell from '@mui/material/TableCell';
import { TransfersCellItem } from '../../styling/style';
const InternalTransfersListItem = ({ item, columns }) => {
    const renderCell = (key) => {
        return columns.findIndex((c) => c.key === key) !== -1;
    };
    const getColumnWidth = (key) => {
        var _a;
        return (_a = columns.find((c) => c.key === key)) === null || _a === void 0 ? void 0 : _a.minWidth;
    };
    return (React.createElement(React.Fragment, null,
        renderCell('code') && (React.createElement(TableCell, { align: 'left' },
            React.createElement(TransfersCellItem, { style: { maxWidth: getColumnWidth('code') } }, item.code))),
        renderCell('client_code') && (React.createElement(TableCell, { align: 'left' },
            React.createElement(TransfersCellItem, { style: { maxWidth: getColumnWidth('client_code') } }, item.client_code))),
        renderCell('currency_code') && React.createElement(TableCell, { align: 'left' }, item.currency_code),
        renderCell('destination_code') && (React.createElement(TableCell, { align: 'left' },
            React.createElement(TransfersCellItem, { style: { maxWidth: getColumnWidth('destination_code') } }, item.destination_code))),
        renderCell('source_code') && (React.createElement(TableCell, { align: 'left' },
            React.createElement(TransfersCellItem, { style: { maxWidth: getColumnWidth('source_code') } },
                item.source_code,
                " "))),
        renderCell('direction') && React.createElement(TableCell, { align: 'left' }, item.direction),
        renderCell('state') && React.createElement(TableCell, { align: 'left' }, item.state),
        renderCell('amount') && (React.createElement(TableCell, { align: 'left' },
            new Decimal(item.amount).toFixed(),
            " ",
            item.currency_code)),
        renderCell('created_at') && (React.createElement(TableCell, { align: 'left' }, moment(item.created_at).format('YYYY/MM/DD, H:mm:ss'))),
        renderCell('updated_at') && (React.createElement(TableCell, { align: 'left' }, moment(item.updated_at).format('YYYY/MM/DD, H:mm:ss')))));
};
export default InternalTransfersListItem;
