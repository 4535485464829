import React, { useState } from 'react';
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { connect } from 'react-redux';
const CreateLayoutModal = ({ open, theme, toggleFunc, handleCreateWorkSpace, }) => {
    const [name, setName] = useState('');
    const onNameChange = (evt) => {
        const { value } = evt.target;
        setName(value);
    };
    const addNewLayout = () => {
        handleCreateWorkSpace(name);
        setName('');
        toggleFunc();
    };
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true, className: theme.className },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Create new workspace"),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { fullWidth: true, name: "name", value: name, size: "small", margin: "normal", variant: "outlined", label: "Workspace name", onChange: onNameChange, InputLabelProps: { shrink: true } })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", disabled: !name, variant: "contained", onClick: addNewLayout }, "Add Workspace"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggleFunc() }, "Close"))));
};
const mapStateToProps = (state) => {
    const { theme } = state;
    return {
        theme,
    };
};
export default connect(mapStateToProps)(CreateLayoutModal);
