import React, { useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';
import { TransfersCellItem } from '../../../Custody/styling/style';
import { createStyles, makeStyles } from '@mui/styles';
import ConfirmationDialog from '../../../../../shared/components/confirmationDialog/ConfirmationDialog';
import { AmountCell } from '../../../../../shared/components/styled/tableCells';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
const headCells = [
    {
        id: 'customerName',
        numeric: false,
        disablePadding: true,
        label: 'Customer',
    },
    {
        id: 'clientName',
        numeric: false,
        disablePadding: true,
        label: 'Client',
    },
    {
        id: 'currency',
        numeric: true,
        disablePadding: true,
        label: 'Currency',
    },
    {
        id: 'amountToSettle',
        numeric: true,
        disablePadding: true,
        label: 'Amount to Settle',
    },
    {
        id: 'direction',
        numeric: false,
        disablePadding: true,
        label: 'Direction',
    },
    {
        id: 'settlementCode',
        numeric: true,
        disablePadding: true,
        label: 'Code',
    },
    {
        id: 'failedReason',
        numeric: false,
        disablePadding: true,
        label: 'Failed Reason',
    },
];
const useStyles = makeStyles(() => createStyles({
    customTableRow: {
        '& > tr:last-of-type > td': {
            borderBottom: 0,
        },
    },
}));
const SettlementsTableBody = ({ rows, type, executeSettlement, declineSettlement }) => {
    const [selecedSettlement, setSelectedSettlement] = useState('');
    const theme = useTheme();
    const classes = useStyles();
    const [confirmDialog, setConfirmDialog] = useState('');
    const onConfirm = (code, dialogName) => {
        setSelectedSettlement(code);
        setConfirmDialog(dialogName);
    };
    const onConfirmClose = () => {
        setSelectedSettlement('');
        setConfirmDialog('');
    };
    const onConfirmExecute = () => {
        if (executeSettlement) {
            executeSettlement(selecedSettlement);
        }
        setConfirmDialog('');
        setSelectedSettlement('');
    };
    const onConfirmDecline = () => {
        if (declineSettlement) {
            declineSettlement(selecedSettlement);
        }
        setConfirmDialog('');
        setSelectedSettlement('');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { sx: { minWidth: 750 }, "aria-labelledby": 'tableTitle', size: 'small' },
            React.createElement(TableHead, { sx: {
                    cursor: 'pointer',
                    backgroundColor: type !== 'created' ? `${theme.palette.secondary.light}` : 'inherit',
                } },
                React.createElement(TableRow, null,
                    headCells.map((headCell) => (React.createElement(React.Fragment, { key: headCell.id }, (type !== 'failed' && headCell.id === 'failedReason') ||
                        (type === 'created' && headCell.id === 'customerName') ||
                        (type === 'created' && headCell.id === 'clientName') ? null : (React.createElement(TableCell, { sx: {
                            whiteSpace: 'nowrap',
                            color: type === 'created' ? `${theme.palette.secondary.light}` : 'inherit',
                        } }, headCell.label))))),
                    type === 'created' ? (React.createElement(TableCell, { align: 'right', sx: {
                            whiteSpace: 'nowrap',
                            color: type === 'created' ? `${theme.palette.secondary.light}` : 'inherit',
                        } }, "Actions")) : null)),
            React.createElement(TableBody, { className: classes.customTableRow }, rows.map((row, index) => {
                const labelId = `settlement-${index}`;
                return (React.createElement(TableRow, { hover: true, tabIndex: -1, key: labelId },
                    type === 'created' ? null : (React.createElement(TableCell, { align: 'left' },
                        React.createElement(Box, { display: 'flex' },
                            React.createElement(TransfersCellItem, null, row.customerName)))),
                    type === 'created' ? null : (React.createElement(TableCell, { align: 'left' },
                        React.createElement(Box, { display: 'flex' },
                            React.createElement(TransfersCellItem, null, row.clientName)))),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.currency.toUpperCase())),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(AmountCell, null, row.amountToSettle)),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.direction)),
                    React.createElement(TableCell, { align: 'left' },
                        React.createElement(Box, { sx: { display: 'flex' }, gap: 1 },
                            React.createElement(AmountCell, null, row.settlementCode),
                            React.createElement(CopyValue, { value: row.settlementCode }))),
                    type === 'failed' ? (React.createElement(TableCell, { align: 'left' },
                        React.createElement(TransfersCellItem, null, row.failedReason))) : null,
                    type === 'created' ? (React.createElement(TableCell, { align: 'right' },
                        React.createElement(Box, { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 1 },
                            React.createElement(Button, { size: 'small', variant: 'outlined', onClick: () => onConfirm(row.settlementCode, 'execute') }, "Execute"),
                            React.createElement(Button, { size: 'small', variant: 'outlined', color: 'error', onClick: () => onConfirm(row.settlementCode, 'decline') }, "Decline")))) : null));
            }))),
        React.createElement(ConfirmationDialog, { title: 'Execute Settlement', content: `Are you sure to exucute settlement with code: ${selecedSettlement}?`, open: confirmDialog === 'execute', onClose: onConfirmClose, onConfirm: onConfirmExecute }),
        React.createElement(ConfirmationDialog, { title: 'Decline Settlement', content: `Are you sure to decline settlement with code: ${selecedSettlement}?`, open: confirmDialog === 'decline', onClose: onConfirmClose, onConfirm: onConfirmDecline })));
};
export default SettlementsTableBody;
