import React, { useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        padding: theme.spacing(2),
        '& a:last-of-type': {
            paddingBottom: 0,
        }
    },
    title: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
const SidebarCategory = ({ title, icon, isNew, children }) => {
    const [collapse, setCollapse] = useState(false);
    const classes = useStyles();
    const toggle = () => {
        setCollapse(!collapse);
    };
    return (React.createElement(Box, { className: classes.root },
        React.createElement("div", { className: classes.title, onClick: toggle, style: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' } },
            icon,
            title,
            isNew && React.createElement("span", { className: "sidebar__category-new" }),
            collapse ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null)),
        React.createElement(Collapse, { in: collapse },
            React.createElement(Box, { pt: 1 }, children))));
};
export default SidebarCategory;
