import React, { useState, useEffect, useCallback } from 'react';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import { withStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';
import Popover from '@mui/material/Popover';
import { green } from '@mui/material/colors';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { Box, CircularProgress } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialPopoverWrap, WalletsClientContainer, } from '../../../Custody/styling/style';
import CurrencyConfigItem from './WidgetCurrencyConfigItem';
import { PanelContainer } from '../../../Admin/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { materialUiPaginator } from '../../../../../shared/helpers/recordsDependsOnPage';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import CustodyCurrencyConfigService from '../../../../../services/custodyCurrencyConfigService';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
const GreenSwitch = withStyles({
    switchBase: {
        color: green.A100,
        '&$checked': {
            color: green.A200,
        },
        '&$checked + $track': {
            backgroundColor: green.A200,
        },
    },
    checked: {},
    track: {},
})(Switch);
const CurrencyConfig = ({ currencyConfigWidgetHeight, columns, features }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [maxHeight, setMaxHeight] = useState(480);
    console.log(maxHeight, 'maxHeight000');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [newLoading, setNewLoading] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [currenciesToRender, setCurrenciesToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('currencyConfig'));
    };
    const onPageChange = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const fetchCurrenciesConfig = () => {
        const rfqService = new CustodyCurrencyConfigService({
            url: '/custody/currency',
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        rfqService.makeRequest()
            .then((data) => {
            setLoading(false);
            setCurrencies(data.records);
            setTotalPages(data.records.length);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CURRENCY_CONFIG_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoading(false);
        });
    };
    const updateCurrency = (payload, code) => {
        const service = new CustodyCurrencyConfigService({
            url: `/custody/currency/${code}`,
            method: 'put',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        setNewLoading(true);
        service.makeRequest()
            .then(() => {
            setNewLoading(false);
            showNotification({
                message: 'Currency successfully updated',
                color: 'success',
                dispatch: errorNotice,
            });
            fetchCurrenciesConfig();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CURRENCY_CONFIG_UPDATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setNewLoading(false);
        });
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            fetchCurrenciesConfig();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (currencies.length) {
            const items = materialUiPaginator(currencies, page, rowsPerPage);
            setCurrenciesToRender([...items]);
        }
    }, [currencies, page, rowsPerPage]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(currencyConfigWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [currencyConfigWidgetHeight]);
    if (loading || newLoading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return React.createElement(React.Fragment, null,
        React.createElement(PanelContainer, null,
            React.createElement(WalletsClientContainer, null,
                React.createElement("div", null,
                    React.createElement("span", null,
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                            React.createElement(MaterialPopoverWrap, null,
                                React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                    React.createElement(ListItemText, { primary: col.title }),
                                    React.createElement(ListItemSecondaryAction, null,
                                        React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } }))))))))))),
            React.createElement(React.Fragment, null,
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'code' ? 'left' : 'right' },
                                    React.createElement(TableSortLabel, { direction: "asc" }, column.title)))),
                                React.createElement(TableCell, { align: "right", key: "update", style: { minWidth: '100px' } }, "Action"))),
                        React.createElement(TableBody, null, currenciesToRender.length === 0
                            ? (React.createElement(TableRow, null,
                                React.createElement(TableCell, { colSpan: columnsToRender.length + 1 },
                                    React.createElement(Typography, { className: "transactions_typography_padded", variant: "subtitle1" }, "No items found"))))
                            : currenciesToRender.map((c, index) => (React.createElement(CurrencyConfigItem, { item: c, keyIndex: index, features: features, newLoading: newLoading, updateCurrency: updateCurrency, columnsToRender: columnsToRender })))))),
                React.createElement(TablePagination, { page: page, component: "div", count: totalPages, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [maxHeight] }))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    columns: getWidgetColumns(state, ownProps.widgetKey),
    currencyConfigWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(CurrencyConfig);
