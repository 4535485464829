export const SUBORDERS_ORDER_ADD = 'SUBORDERS_ORDER_ADD';
export const SUBORDERS_ORDER_UPDATE = 'SUBORDERS_ORDER_UPDATE';
export const SUBORDERS_ORDER_REMOVE = 'SUBORDERS_ORDER_REMOVE';
export const SUBORDERS_ORDER_REMOVE_REQUEST = 'SUBORDERS_ORDER_REMOVE_REQUEST';
export const SUBORDERS_ORDER_REMOVE_SUCCESS = 'SUBORDERS_ORDER_REMOVE_SUCCESS';
export const SUBORDERS_ORDER_REMOVE_FAIL = 'SUBORDERS_ORDER_REMOVE_FAIL';
export const SUBORDERS_ORDER_POPULATE = 'SUBORDERS_ORDER_POPULATE';
export const SUBORDERS_ORDER_CLEAN = 'SUBORDERS_ORDER_CLEAN';
export const SUBORDERS_ORDER_EXECUTE_REQUEST = 'SUBORDERS_ORDER_EXECUTE_REQUEST';
export const SUBORDERS_ORDER_EXECUTE_SUCCESS = 'SUBORDERS_ORDER_EXECUTE_SUCCESS';
export const SUBORDERS_ORDER_EXECUTE_FAIL = 'SUBORDERS_ORDER_EXECUTE_FAIL';
export const SUBORDERS_CLEAN_ERRORS = 'SUBORDERS_CLEAN_ERRORS';
