import React, { useState, useEffect } from 'react';
import { Chip, TableCell } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import { TransfersCellItem } from '../../../../Custody/styling/style';
import WalletNote from '../Transactions/components/TransactionsActions';
import { statusBadgeData } from '../../../../../../config/statusBadgeData';
import CopyValue from '../../../../../../shared/components/CopyValueComponent';
import statusBadgeColor from '../../../../../../shared/helpers/statusBadgeColor';
const WalletTableCell = ({ value, columnKey }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (columnKey === 'note') {
        return React.createElement(WalletNote, { entityCode: value });
    }
    if (columnKey === 'currency_code') {
        return (React.createElement(TableCell, { align: "left" }, value ? value.toUpperCase() : 'n/a'));
    }
    if (columnKey === 'deposit_address') {
        return (React.createElement(TableCell, { align: "right", ref: ref },
            value ? (React.createElement(CopyValue, { value: value })) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value || 'N/A')));
    }
    if (columnKey === 'created_at') {
        return (React.createElement(TableCell, { align: "right" }, value
            ? `${new Date(value).toLocaleDateString()}, ${new Date(value).toLocaleTimeString()}`
            : 'n/a'));
    }
    if (columnKey === 'label') {
        return (React.createElement(TableCell, { align: "right", ref: ref },
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value || 'N/A')));
    }
    if (columnKey === 'customer_code') {
        return (React.createElement(TableCell, { align: "right", ref: ref }, value === 'n/a' ? null : (React.createElement(Chip, { color: "info", label: React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value), size: "small" }))));
    }
    if (columnKey === 'state') {
        return (React.createElement(TableCell, { align: "right" },
            React.createElement(Chip, { size: "small", label: value || 'N/A', style: statusBadgeColor(statusBadgeData
                    .stateBackgroundsWallets[(value || '').replace(/-/g, '_')], statusBadgeData
                    .stateBordersWallets[(value || '').replace(/-/g, '_')], statusBadgeData
                    .stateColorsWallets[(value || '').replace(/-/g, '_')]).badge })));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default WalletTableCell;
