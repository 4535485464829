import * as types from '../actionTypes/custodyActionTypes';
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
export const initialState = {
    errors: '',
    wallets: [],
    deposits: [],
    currencies: [],
    clientsWallets: [],
    loadingWallets: false,
    loadingAddresses: false,
    sortByWallets: 'created',
    urlOptionsWithdrawals: '',
    sortDirectionWallets: 'desc',
    loadingClientsWallets: false,
};
const custodyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CUSTODY_WALLETS_UPDATE:
            return Object.assign(Object.assign({}, state), { wallets: action.wallets });
        case types.CLIENTS_WALLETS_UPDATE:
            return Object.assign(Object.assign({}, state), { clientsWallets: action.clientsWallets });
        case types.CUSTODY_WALLETS_ADD:
            return Object.assign(Object.assign({}, state), { wallets: [...state.wallets, ...action.wallets] });
        case types.CUSTODY_DEPOSITS_UPDATE:
            return Object.assign(Object.assign({}, state), { deposits: action.deposits });
        case types.CUSTODY_CURRENCIES_UPDATE:
            return Object.assign(Object.assign({}, state), { currencies: action.currencies });
        case types.CUSTODY_WITHDRAWALS_OPTIONS_UPDATE:
            return Object.assign(Object.assign({}, state), { urlOptionsWithdrawals: action.urlOptionsWithdrawals });
        case types.SORT_BY_WALLETS_UPDATE:
            return Object.assign(Object.assign({}, state), { sortByWallets: action.sortByWallets });
        case types.SORT_DIRECTION_WALLETS_UPDATE:
            return Object.assign(Object.assign({}, state), { sortDirectionWallets: action.sortDirectionWallets });
        case types.LOADING_WALLETS_UPDATE:
            return Object.assign(Object.assign({}, state), { loadingWallets: action.loadingWallets });
        case types.LOADING_CLIENTS_WALLETS_UPDATE:
            return Object.assign(Object.assign({}, state), { loadingClientsWallets: action.loadingClientsWallets });
        case types.LOADING_ADDRESSES_UPDATE:
            return Object.assign(Object.assign({}, state), { loadingAddresses: action.loadingAddresses });
        default:
            return state;
    }
};
export default custodyReducer;
