import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import SubOrderbookContent from './SubOrderbookContent';
import { TableContainer } from '@mui/material';
const SubOrderbookComponent = ({ subOrders, total, pageSuborder, onPageChange, }) => {
    const [rowsPerPage] = useState(10);
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Action"),
                    React.createElement(TableCell, null, "Type"),
                    React.createElement(TableCell, null, "Size"),
                    React.createElement(TableCell, { className: "order_list_pair_width" }, "Pair"),
                    React.createElement(TableCell, null, "Exchange"),
                    React.createElement(TableCell, { className: "order_list_price_width" }, "Price Per"),
                    React.createElement(TableCell, null, "Total"),
                    React.createElement(TableCell, null, "Created"),
                    React.createElement(TableCell, null, "Status"),
                    React.createElement(TableCell, null, "Actions"))),
            React.createElement(TableBody, null,
                React.createElement(SubOrderbookContent, { subOrders: subOrders }))),
        React.createElement(TablePagination, { component: "div", count: +total, page: pageSuborder, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [rowsPerPage] })));
};
export default SubOrderbookComponent;
