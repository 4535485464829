import React, { useReducer, useEffect } from 'react';
import buildUrl from 'build-url';
import { connect } from 'react-redux';
import { MASTER_TENANT_STRATEGIES_URL_OPTIONS_SET_UPDATE } from '../../../../../redux/actionTypes/masterTenantActionTypes';
import StrategiesFiltersContent from './StrategiesFiltersContent';
import { collectParamsFromFilters } from '../../../../../shared/helpers/createUrlFromParams';
/* eslint-disable prefer-destructuring, @typescript-eslint/prefer-regexp-exec,
  @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["ADD"] = "ADD";
    FilterActionType["RESET"] = "RESET";
    FilterActionType["DELETE"] = "DELETE";
})(FilterActionType || (FilterActionType = {}));
export const UrlOptionsUpdate = (url) => ({
    type: MASTER_TENANT_STRATEGIES_URL_OPTIONS_SET_UPDATE,
    urlOptionsStrategies: url,
});
const init = () => ({
    selectedFilters: [],
});
const reducer = (state, action) => {
    switch (action.type) {
        case FilterActionType.ADD:
            return { selectedFilters: [...state.selectedFilters, action.item] };
        case FilterActionType.DELETE:
            return {
                selectedFilters: state.selectedFilters
                    .filter(f => f.label !== action.item.label),
            };
        case FilterActionType.RESET:
            return init();
        default:
            return state;
    }
};
const StrategiesFilters = ({ clientInfo, rowsPerPage, currentPage, selectedClient, sortDirection, setCurrentPage, initialValue = '', UrlOptionsUpdateDispatch, }) => {
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const buildUrlFromParams = (params, dir) => {
        const paramsNames = Object.keys(params);
        const urlOptions = {
            queryParams: {
                sort_by: 'created',
                sort_direction: dir,
            },
            path: '/defi/strategies',
        };
        if (selectedClient) {
            urlOptions.queryParams.client_code = selectedClient.value;
        }
        else if (clientInfo) {
            urlOptions.queryParams.client_code = clientInfo.code;
        }
        paramsNames.forEach((key) => {
            if (Array.isArray(params[key])) {
                if (params[key].length === 1) {
                    urlOptions.queryParams[key] = params[key].join('');
                }
                else {
                    urlOptions.queryParams[key] = params[key].join('|');
                }
            }
        });
        return buildUrl('', urlOptions);
    };
    const buildUrlOptions = (dir) => {
        const collectedParams = collectParamsFromFilters(state.selectedFilters);
        const adminFilterUrl = buildUrlFromParams(collectedParams, dir);
        return adminFilterUrl;
    };
    const handleStateChange = (option, reason, details) => {
        if (reason === 'selectOption') {
            const isStatus = state.selectedFilters.find(f => f.name === 'status');
            if (isStatus) {
                dispatch({ type: FilterActionType.DELETE, item: isStatus });
            }
            const val = {
                name: 'status',
                type: 'status',
                label: (option === null || option === void 0 ? void 0 : option.value) || '',
                value: (option === null || option === void 0 ? void 0 : option.value) || '',
            };
            dispatch({ type: FilterActionType.ADD, item: val });
        }
        else if (reason === 'removeOption') {
            const item = {
                name: 'state',
                type: 'state',
                label: (details === null || details === void 0 ? void 0 : details.option.label) || '',
                value: (details === null || details === void 0 ? void 0 : details.option.value) || '',
            };
            removeSelectedFilter(item);
        }
        else if (reason === 'clear') {
            const isStatus = state.selectedFilters.filter(f => f.name === 'status');
            if (isStatus.length > 0) {
                isStatus.forEach((f) => {
                    removeSelectedFilter(f);
                });
            }
        }
    };
    const handleStrategyChange = (option, reason, details) => {
        if (reason === 'selectOption') {
            const isCode = state.selectedFilters.find(f => f.name === 'code');
            if (isCode) {
                dispatch({ type: FilterActionType.DELETE, item: isCode });
            }
            const val = {
                name: 'code',
                type: 'code',
                label: (option === null || option === void 0 ? void 0 : option.value) || '',
                value: (option === null || option === void 0 ? void 0 : option.value) || '',
            };
            dispatch({ type: FilterActionType.ADD, item: val });
        }
        else if (reason === 'removeOption') {
            const item = {
                name: 'state',
                type: 'state',
                label: (details === null || details === void 0 ? void 0 : details.option.label) || '',
                value: (details === null || details === void 0 ? void 0 : details.option.value) || '',
            };
            removeSelectedFilter(item);
        }
        else if (reason === 'clear') {
            const isCode = state.selectedFilters.filter(f => f.name === 'code');
            if (isCode.length > 0) {
                isCode.forEach((f) => {
                    removeSelectedFilter(f);
                });
            }
        }
    };
    const removeSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.DELETE, item: val });
    };
    const resetFilters = () => {
        dispatch({ type: FilterActionType.RESET });
    };
    useEffect(() => {
        UrlOptionsUpdateDispatch(buildUrlOptions(sortDirection));
        setCurrentPage(0);
    }, [state, rowsPerPage, sortDirection]);
    useEffect(() => {
        UrlOptionsUpdateDispatch(buildUrlOptions(sortDirection));
    }, [currentPage, rowsPerPage, sortDirection]);
    return (React.createElement(StrategiesFiltersContent, { state: state, resetFilters: resetFilters, handleStateChange: handleStateChange, handleStrategyChange: handleStrategyChange, removeSelectedFilter: removeSelectedFilter }));
};
const mapDispatchToProps = dispatch => ({
    initialValue: '',
    UrlOptionsUpdateDispatch: (url) => dispatch(UrlOptionsUpdate(url)),
});
export default connect(null, mapDispatchToProps)(StrategiesFilters);
