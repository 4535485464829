export const DASHBOARD_CREATE = 'DASHBOARD_CREATE';
export const DASHBOARD_UPDATE = 'DASHBOARD_UPDATE';
export const DASHBOARD_DELETE = 'DASHBOARD_DELETE';
export const DASHBOARD_POPULATE = 'DASHBOARD_POPULATE';
export const REMOVE_WORKSPACE = 'REMOVE_WORKSPACE';
export const ADD_NEW_WORKSPACE = 'ADD_NEW_WORKSPACE';
export const APPLY_WORKSPACE = 'APPLY_WORKSPACE';
export const APPLY_SETTINGS_TO_WORKSPACE = 'APPLY_SETTINGS_TO_WORKSPACE';
export const WORKSPACE_FETCH_REQUEST = 'WORKSPACE_FETCH_REQUEST';
export const WORKSPACE_FETCH_SUCCESS = 'WORKSPACE_FETCH_SUCCESS';
export const WORKSPACE_FETCH_FAIL = 'WORKSPACE_FETCH_FAIL';
export const WORKSPACE_UPDATE_REQUEST = 'WORKSPACE_UPDATE_REQUEST';
export const WORKSPACE_UPDATE_SUCCESS = 'WORKSPACE_UPDATE_SUCCESS';
export const WORKSPACE_UPDATE_FAIL = 'WORKSPACE_UPDATE_FAIL';
export const DEFAULT_WORKSPACE_UPDATE = 'DEFAULT_WORKSPACE_UPDATE';
export const SIDE_MENU_UPDATE = 'SIDE_MENU_UPDATE';
export const BALANCES_STATE_UPDATE = 'BALANCES_STATE_UPDATE';
export const UPDATE_LAST_SEEN_VERSION = 'UPDATE_LAST_SEEN_VERSION';
