import Decimal from 'decimal.js';
export default class CurrencyConversionService {
    constructor(baseFiat, rates) {
        this.rates = [];
        this.historicRatesRanges = null;
        this.decimalOne = new Decimal(1);
        this.setRates = (rates) => {
            this.rates = rates;
        };
        this.setHistoricRatesRanges = (ranges) => {
            this.historicRatesRanges = ranges;
        };
        this.getRatesRanges = () => this.historicRatesRanges;
        this.getRateForBase = (currency) => {
            var _a;
            return this.rates ? (_a = this.rates
                .find(r => r.base_currency === this.base && r.currency === currency)) === null || _a === void 0 ? void 0 : _a.rate : undefined;
        };
        this.getRateForDate = (currency, date) => {
            let rate;
            const ranges = this.historicRatesRanges && this.historicRatesRanges[currency]
                ? this.historicRatesRanges[currency] : undefined;
            if (ranges) {
                const rateRange = ranges.find((r) => {
                    const millies = new Date(date).getTime();
                    return r.start < millies && r.end > millies;
                });
                rate = rateRange ? rateRange.rate : undefined;
            }
            return rate || 'Rate not found';
        };
        this.convertInBase = (currency, amount) => {
            const rate = currency ? this.getRateForBase(currency.toLowerCase()) : undefined;
            if (!rate || !amount) {
                return 'Not convertible';
            }
            const perBase = this.decimalOne.div(rate);
            return Decimal.mul(perBase, amount).toFixed(2);
        };
        this.getCurrentPrice = (currency) => {
            const rate = currency ? this.getRateForBase(currency.toLowerCase()) : undefined;
            if (!rate) {
                return 'Not convertible';
            }
            const perBase = this.decimalOne.div(rate);
            return perBase.toFixed();
        };
        this.convertHistoricInBase = (currency, date, amount) => {
            const rate = this.getRateForDate(currency, date);
            if (rate === 'Rate not found' || rate === '0' || !amount) {
                return 'Not convertible';
            }
            const perBase = this.decimalOne.div(rate);
            return Decimal.mul(perBase, amount).toFixed(2);
        };
        this.base = baseFiat || 'eur';
        this.rates = rates || [];
    }
}
