import React from 'react';
import PlaceOrderForm from './PlaceOrderForm';
import { PanelContainer } from '../../../Admin/styling/style';
const PlaceOrderContainer = () => (React.createElement("div", { className: "content-body" },
    React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
        React.createElement("div", { className: "card-body" },
            React.createElement("div", { className: "tabs tabs--bordered-bottom" },
                React.createElement("div", { className: "tabs__wrap" },
                    React.createElement(PlaceOrderForm, null)))))));
export default PlaceOrderContainer;
