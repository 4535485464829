import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
const AppTabs = (props) => {
    const [activeTab, setActiveTab] = useState(0);
    useEffect(() => {
        props.onTabChange(activeTab);
    }, [activeTab]);
    return React.createElement(Tabs, { indicatorColor: "primary", value: activeTab }, props.tabs.map((t, i) => React.createElement(Tab, { key: i, label: t, onClick: () => setActiveTab(i) })));
};
export default AppTabs;
