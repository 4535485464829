import React from 'react';
import { connect } from 'react-redux';
import SettingsContent from './SettingsContent';
import { getTradingBases, getTradingQuotes } from '../../../../../../redux/selectors/selectors';
import { getWidgetsWorkingSettingsByKey } from '../../../../../../redux/selectors/widgetSettingsSelector';
const OrderListSettings = ({ settings, ordersTabSelected, exchanges, widgetKey, toggleFunc, optionsBase, optionsQuote, setSubPage, setSplitPage, setRegularPage, }) => {
    const renderSetting = {
        regular: 'Regular',
        split: 'PartialOrderbook',
        suborder: 'Suborders',
    };
    const workingSettings = settings.filter(s => s && s.id.includes(renderSetting[ordersTabSelected]));
    if (workingSettings.length === 0) {
        return null;
    }
    return (React.createElement(SettingsContent, { widgetKey: widgetKey, exchanges: exchanges, setSubPage: setSubPage, toggleFunc: toggleFunc, optionsBase: optionsBase, settings: workingSettings, optionsQuote: optionsQuote, setSplitPage: setSplitPage, setRegularPage: setRegularPage, ordersTabSelected: ordersTabSelected }));
};
const stateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    return {
        /* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment */
        // @ts-ignore
        settings: getWidgetsWorkingSettingsByKey(state, widgetKey),
        ordersTabSelected: state.orderBook.ordersTabSelected,
        exchanges: state.placeOrder.exchanges,
        optionsBase: getTradingBases(state),
        optionsQuote: getTradingQuotes(state),
    };
};
export default connect(stateToProps)(OrderListSettings);
