const fetchAllRisks = () => ([
    {
        id: '0',
        ul: 'BTC/EUR',
        source: 'Kraken0',
        realtime_ui_price: '40000',
        position: '1',
        pos_notional: '95.553',
        av_open_price: '95.800',
        realtime_p_l_day: '812',
        realtime_p_l_month: '600',
        realtime_p_l_year: '7054',
    }, {
        id: '1',
        ul: 'BTC/EUR',
        source: 'Kraken',
        realtime_ui_price: '40000',
        position: '1',
        pos_notional: '95.553',
        av_open_price: '95.800',
        realtime_p_l_day: '812',
        realtime_p_l_month: '600',
        realtime_p_l_year: '7054',
    }, {
        id: '2',
        ul: 'BTC/EUR',
        source: 'Kraken1',
        realtime_ui_price: '40000',
        position: '1',
        pos_notional: '95.553',
        av_open_price: '95.800',
        realtime_p_l_day: '812',
        realtime_p_l_month: '600',
        realtime_p_l_year: '7054',
    }, {
        id: '3',
        ul: 'BTC/EUR',
        source: 'Kraken2',
        realtime_ui_price: '40000',
        position: '1',
        pos_notional: '95.553',
        av_open_price: '95.800',
        realtime_p_l_day: '812',
        realtime_p_l_month: '600',
        realtime_p_l_year: '7054',
    }, {
        id: '4',
        ul: 'BTC/EUR',
        source: 'Kraken3',
        realtime_ui_price: '40000',
        position: '1',
        pos_notional: '95.553',
        av_open_price: '95.800',
        realtime_p_l_day: '812',
        realtime_p_l_month: '600',
        realtime_p_l_year: '7054',
    },
]);
export default fetchAllRisks;
