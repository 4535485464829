import React from 'react';
import { getEnvSettings } from '../../../config/environmentSettings';
import ResetPasswordForm from '../../../shared/components/login/ResetPasswordForm';
import { FormLogo } from '../../../shared/components/login/styling/styledComponents';
const finoaEnv = getEnvSettings().env === 'finoa';
const Reset = () => (React.createElement("div", { className: "account" },
    React.createElement("div", { className: "account__head" },
        React.createElement(FormLogo, { className: finoaEnv ? 'form_logo_finoa' : 'form_logo' })),
    React.createElement("div", { className: "account__wrapper" },
        React.createElement(ResetPasswordForm, null))));
export default Reset;
