import React, { useEffect, useState } from 'react';
import { Box, Chip, Button, Checkbox, Collapse, TextField, FormControlLabel, } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { createStyles, makeStyles } from '@mui/styles';
import { FiltersContainer, CreateWithdrawal, CustodyFilterPanelContainer, } from '../../styling/style';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
const useStyles = makeStyles(() => createStyles({
    formGroup: { minWidth: 200 },
}));
const stateOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Creation pending', value: 'creation_pending' },
    { label: 'Unknown transaction', value: 'unknown_transaction' },
];
const WalletsFiltersContent = ({ state, features, hideZero, toggleModal, resetFilters, withMigrated, allCurrencies, setWithMigrated, handleStateChange, toggleZeroBalances, removeSelectedFilter, handleCurrencyChange, }) => {
    var _a;
    const classes = useStyles();
    const [collapse, setCollapse] = useState(false);
    const [status, setStatus] = useState(null);
    const [currency, setCurrency] = useState(null);
    useEffect(() => {
        const objIndexCurrency = state.filters.findIndex((obj => obj.name === 'currency_code'));
        const currencyData = state.filters[objIndexCurrency];
        setCurrency(currencyData || null);
        const objIndexStatus = state.filters.findIndex((obj => obj.name === 'state'));
        const stateData = state.filters[objIndexStatus];
        setStatus(stateData || null);
    }, [state]);
    const toggleCollapseFilter = () => {
        setCollapse(!collapse);
    };
    const toggleWithMigrated = () => {
        setWithMigrated(!withMigrated);
    };
    return (React.createElement(CustodyFilterPanelContainer, null,
        React.createElement(FiltersContainer, null,
            React.createElement(Box, { display: 'flex', justifyContent: 'space-between' },
                React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 },
                    React.createElement(Box, null,
                        React.createElement(Button, { size: "small", variant: "outlined", color: "primary", onClick: toggleCollapseFilter },
                            collapse ? '-' : '+',
                            " Quick filters")),
                    state.filters.length ? (React.createElement(Box, null,
                        React.createElement(Button, { onClick: resetFilters, variant: "outlined", color: "inherit", size: "small" }, "Reset filters"))) : null,
                    React.createElement(Box, null,
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { name: "hideZero", checked: hideZero, onChange: toggleZeroBalances }), label: "Hide zero balances" }))),
                React.createElement(Box, null,
                    React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet.create'] },
                        React.createElement(CreateWithdrawal, null,
                            React.createElement(Button, { size: "small", color: "primary", variant: "outlined", onClick: toggleModal }, "New wallet"))))),
            ((_a = state.filters) === null || _a === void 0 ? void 0 : _a.length) ?
                React.createElement(Box, { display: 'flex', flexWrap: 'wrap', gap: 1, pt: 1 }, state.filters.map((filter) => (React.createElement(Chip, { size: "small", color: "primary", key: filter.label, label: filter.label, style: { marginRight: '4px' }, onDelete: () => removeSelectedFilter(filter) })))) : null),
        React.createElement(Collapse, { in: collapse },
            React.createElement("div", null,
                React.createElement(Box, { style: { display: 'flex' }, p: 1 },
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(Autocomplete, { loading: false, disableClearable: true, options: stateOptions, getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleStateChange(newValue), renderInput: (params) => React.createElement(TextField, Object.assign({ name: "state" }, params, { label: "State", variant: "outlined", value: {
                                    value: status,
                                    label: 'State',
                                }, size: "small" })) })),
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(Autocomplete, { loading: false, disableClearable: true, options: allCurrencies, getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleCurrencyChange(newValue), renderInput: (params) => React.createElement(TextField, Object.assign({ name: "currency_code" }, params, { label: "Currency", variant: "outlined", value: {
                                    value: currency,
                                    label: 'Currency',
                                }, size: "small" })) })),
                    React.createElement(Box, null,
                        React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { name: "withMigrated", checked: withMigrated, onChange: toggleWithMigrated }), label: "With migrated" })))))));
};
export default WalletsFiltersContent;
