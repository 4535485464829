import React, { createContext, useContext } from 'react';
import useBalancesService from './BalancesService';
const BalancesContext = createContext(undefined);
export const BalancesProvider = ({ children }) => {
    const balancesService = useBalancesService();
    return (React.createElement(BalancesContext.Provider, { value: balancesService }, children));
};
export const useBalances = () => {
    const context = useContext(BalancesContext);
    if (context === undefined) {
        throw new Error('useBalances must be used within a BalancesProvider');
    }
    return context;
};
