import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, List } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../Custody/styling/style';
import AutohedgerModal from './AutohedgerModal';
import TableCellContainer from './TableCellContainer';
import { PanelContainer } from '../../../Admin/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import AutohedgerService from '../../../../../services/autohedgerService';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { materialUiPaginator } from '../../../../../shared/helpers/recordsDependsOnPage';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
const Autohedger = ({ quoteWidgetHeight, columns, features }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [maxHeight, setMaxHeight] = useState(480);
    const [modalOpen, setModalOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [newLoading, setNewLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [autohedgers, setAutohedgers] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [autohedgersToRender, setAutohedgersToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('autohedger'));
    };
    const onPageChange = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const fetchAutohedger = () => {
        const rfqService = new AutohedgerService({
            url: '/trade/autohedger',
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        rfqService.makeRequest()
            .then((data) => {
            setLoading(false);
            setAutohedgers(data.records);
            setTotalPages(data.records.length);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.AUTOHEDGER_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoading(false);
        });
    };
    const updateAutohedger = (payload, mark = 'created') => {
        const service = new AutohedgerService({
            url: '/trade/autohedger',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        setNewLoading(true);
        service.makeRequest()
            .then(() => {
            setNewLoading(false);
            showNotification({
                message: `Autohedger successfully ${mark}`,
                color: 'success',
                dispatch: errorNotice,
            });
            fetchAutohedger();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.AUTOHEDGER_CREATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setNewLoading(false);
        });
    };
    const deleteAutohedger = (base, quote) => {
        const service = new AutohedgerService({
            url: `/trade/autohedger/base/${base}/quote/${quote}`,
            method: 'delete',
            baseURL: getEnvSettings().adminApiUrl,
        });
        setDeleteLoading(true);
        service.makeRequest()
            .then(() => {
            setDeleteLoading(false);
            showNotification({
                message: 'Autohedger successfully deleted',
                color: 'success',
                dispatch: errorNotice,
            });
            fetchAutohedger();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.AUTOHEDGER_DELETE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setDeleteLoading(false);
        });
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            fetchAutohedger();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (autohedgers.length) {
            const items = materialUiPaginator(autohedgers, page, rowsPerPage);
            setAutohedgersToRender([...items]);
        }
    }, [autohedgers, page, rowsPerPage]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(quoteWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [quoteWidgetHeight]);
    if (loading || newLoading || deleteLoading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return React.createElement(React.Fragment, null,
        modalOpen ? (React.createElement(AutohedgerModal, { open: modalOpen, loading: newLoading, toggleFunc: toggleModal, updateAutohedger: updateAutohedger })) : null,
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement(WalletsClientContainer, null,
                React.createElement("div", null,
                    React.createElement(Permissions, { features: features, permissions: ['auto_hedger.update'] },
                        React.createElement("span", { className: "custody_clients_add_new" },
                            React.createElement(Button, { color: "primary", variant: "outlined", onClick: toggleModal, style: { minWidth: '175px' } }, "Add new"))),
                    React.createElement("span", { className: "custody_clients_table_settings" },
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                            React.createElement(MaterialPopoverWrap, null,
                                React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                    React.createElement(ListItemText, { primary: col.title }),
                                    React.createElement(ListItemSecondaryAction, null,
                                        React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } }))))))))))),
            React.createElement(MaterialTableWrap, null,
                React.createElement(Paper, { className: "mui_table_root" },
                    React.createElement(TableContainer, { style: { maxHeight } },
                        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'auto_hedging' ? 'center' : 'right' },
                                        React.createElement(TableSortLabel, { direction: "asc" }, column.title)))),
                                    React.createElement(TableCell, { align: "right", key: "delete", style: { minWidth: '100px' } }, "Action"))),
                            React.createElement(TableBody, null, autohedgersToRender.length === 0
                                ? (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { colSpan: columnsToRender.length + 1 },
                                        React.createElement(Typography, { className: "transactions_typography_padded", variant: "subtitle1" }, "No items found"))))
                                : autohedgersToRender.map((a, index) => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: index },
                                    columnsToRender.map((column, i) => {
                                        const key = column.key;
                                        const value = key ? a[key] : '';
                                        return (React.createElement(TableCellContainer, { key: i, item: a, value: value, columnKey: key, autohedgers: autohedgers, setAutohedgers: setAutohedgers, updateAutohedger: updateAutohedger }));
                                    }),
                                    React.createElement(TableCell, { align: "right" },
                                        React.createElement(Permissions, { features: features, permissions: ['auto_hedger.delete'] },
                                            React.createElement(Button, { variant: "outlined", style: { height: '25px' }, onClick: () => deleteAutohedger(a.base, a.quote) }, "delete"))))))))),
                    React.createElement(TablePagination, { page: page, component: "div", count: totalPages, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [maxHeight] })))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    columns: getWidgetColumns(state, ownProps.widgetKey),
    quoteWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(Autohedger);
