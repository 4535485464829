import React from 'react';
import { connect } from 'react-redux';
import ExchangTradeHistory from './ExchangTradeHistory';
import { getWidgetsSettingsByKey } from '../../../../../redux/selectors/widgetSettingsSelector';
const Main = ({ settings, widgetKey }) => (React.createElement("div", { className: "some_class_for_unit_testing" },
    React.createElement(ExchangTradeHistory, { settings: settings, widgetKey: widgetKey })));
const stateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    return {
        /* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment */
        // @ts-ignore
        settings: getWidgetsSettingsByKey(state, widgetKey),
        widgetKey,
    };
};
export default connect(stateToProps)(Main);
