import React from 'react';
import shortid from 'shortid';
import { Checkbox, TableCell } from '@mui/material';
/* eslint-disable no-nested-ternary, no-unneeded-ternary */
const PermissionsCell = ({ value, keyOf, item, checkedItems, handleFieldChange, }) => {
    if (keyOf === 'create' || keyOf === 'read' || keyOf === 'update' || keyOf === 'delete') {
        const idKey = shortid.generate();
        return (React.createElement(TableCell, { align: "center", size: "medium" },
            React.createElement(Checkbox, { id: idKey, name: idKey, disabled: !value, checked: checkedItems.includes(`${item.resource}.${keyOf}`), onChange: evt => handleFieldChange(evt, `${item.resource}.${keyOf}`) })));
    }
    if (keyOf === 'resource') {
        return (React.createElement(TableCell, { align: "left" }, value || 'N/A'));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default PermissionsCell;
