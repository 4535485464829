import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, } from 'react-redux';
import shortid from 'shortid';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { Box, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import buildUrl from 'build-url';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, } from '../../../../Custody/styling/style';
import { INVITES_FETCH_REQUEST, ADMIN_SELECT_CLIENT_FROM_USERS, ADMIN_USERS_PAGE_OF_ITEMS_SET_UPDATE, } from '../../../../../../redux/actionTypes/adminActionTypes';
import InviteDialog from './InviteDialog';
import UsersTableCell from './UsersTableCell';
import UsersCellActions from './UsersCellActions';
import { PanelContainer } from '../../../styling/style';
import UsersService from '../../../../../../services/usersService';
import Messages from '../../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import InvitesService from '../../../../../../services/invitesService';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
import { DisableEnableUser, Reset2FA, ResetPassword, Unban } from './components/UsersActions';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { getAllCustomers } from '../../../../../../redux/selectors/customerSearchSelectors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const adminClientFromUsersUpdate = (clientFromUsers) => ({
    type: ADMIN_SELECT_CLIENT_FROM_USERS,
    clientFromUsers,
});
export const PageOfItemsUpdate = (pageOfItemsAdminUsers) => ({
    type: ADMIN_USERS_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsAdminUsers,
});
const Users = ({ columns, features, customers, clientInfo, selectedClient, masterTenant, customerCode, clientFromUsers, usersWidgetHeight, rowsPerPageInvites, pageOfItemsAdminUsers, pageOfItemsAdminInvites, PageOfItemsUpdateDispatch, adminClientFromUsersUpdateDispatch, }) => {
    const [urlUsers, setUrlUsers] = useState('');
    const [maxHeight, setMaxHeight] = useState(480);
    const [totalUsers, setTotalUsers] = useState(0);
    const [urlInvites, setUrlInvites] = useState('');
    const [clientCode, setClientCode] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [modalOpen, setModalOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [client, setClient] = useState(clientFromUsers);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const columnsOpen = Boolean(anchorColumns);
    const onChangePage = (event, pageNumber) => {
        PageOfItemsUpdateDispatch(pageNumber);
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageUsers'));
    };
    const cellActions = {
        actions: {
            unban: () => undefined,
            resetPassword: () => undefined,
            disableEnableUser: () => undefined,
            reset2FA: () => undefined,
        },
        actionsComponents: {
            unban: Unban,
            resetPassword: ResetPassword,
            disableEnableUser: DisableEnableUser,
            reset2FA: Reset2FA,
        },
        actionsList: [
            { name: 'Unban', action: 'unban' },
            { name: 'Reset Password', action: 'resetPassword' },
            { name: 'Reset 2FA', action: 'reset2FA' },
            { name: 'Disable/Enable', action: 'disableEnableUser' },
        ],
    };
    const fetchUsers = (url, cancelToken) => {
        const usersService = new UsersService({
            url,
            method: 'get',
            cancelToken,
            baseURL: masterTenant ? getEnvSettings().adminApiUrl : getEnvSettings().apiUrl,
        });
        usersService.makeRequest()
            .then((responseData) => {
            const { records, total } = responseData;
            setUsers(records);
            setTotalUsers(+total);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.USERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleSendInvite = (payload) => {
        if (!masterTenant) {
            delete payload.tenant_uuid;
        }
        const invitesService = new InvitesService({
            url: '/invites',
            method: 'post',
            data: payload,
            baseURL: masterTenant ? getEnvSettings().adminApiUrl : getEnvSettings().apiUrl,
        });
        invitesService.makeRequest()
            .then(() => {
            dispatch({ type: INVITES_FETCH_REQUEST, urlOptions: urlInvites });
            showNotification({
                message: 'Invite successfully sent',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.SEND_INVITE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        return () => {
            PageOfItemsUpdateDispatch(0);
        };
    }, []);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        if (client) {
            setClientCode(client.value);
        }
        else if (selectedClient) {
            setClientCode(selectedClient.value);
        }
        else if (clientInfo) {
            setClientCode(clientInfo.code);
        }
    }, [client, selectedClient, clientInfo]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        const urlOptions = {
            queryParams: {
                limit: `${rowsPerPage}`,
                page: `${pageOfItemsAdminUsers + 1}`,
                search: '',
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
            path: '/users',
        };
        if (client) {
            urlOptions.queryParams.client_code = client.value;
        }
        else if (selectedClient) {
            urlOptions.queryParams.client_code = selectedClient.value;
        }
        else if (clientInfo) {
            urlOptions.queryParams.client_code = clientInfo.code;
        }
        const endpointUrl = buildUrl('', urlOptions);
        setUrlUsers(endpointUrl);
        fetchUsers(endpointUrl, cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, [clientInfo, selectedClient, client, pageOfItemsAdminUsers, rowsPerPage]);
    useEffect(() => {
        const urlOptionsForInvites = {
            queryParams: {
                limit: `${rowsPerPageInvites}`,
                page: `${pageOfItemsAdminInvites + 1}`,
                search: '',
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
        };
        if (clientFromUsers) {
            urlOptionsForInvites.queryParams.client_code = clientFromUsers.value;
        }
        else if (selectedClient) {
            urlOptionsForInvites.queryParams.client_code = selectedClient.value;
        }
        else if (clientInfo) {
            urlOptionsForInvites.queryParams.client_code = clientInfo.code;
        }
        const endpointUrlForInvites = buildUrl('', urlOptionsForInvites);
        setUrlInvites(endpointUrlForInvites);
    }, [clientInfo, selectedClient, clientFromUsers, pageOfItemsAdminInvites, rowsPerPageInvites]);
    useEffect(() => {
        resizeWidgetHeight(usersWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [usersWidgetHeight]);
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, null,
            modalOpen ? (React.createElement(InviteDialog, { open: modalOpen, features: features, clientCode: clientCode, toggleFunc: toggleModal, handleSendInvite: handleSendInvite })) : null,
            React.createElement("div", { className: "card-body" },
                React.createElement(Box, { display: 'flex', justifyContent: 'space-between' },
                    (customerCode || !masterTenant) ? null : (React.createElement(Permissions, { features: features, permissions: ['invites.create'] },
                        React.createElement(Box, { p: 1 },
                            React.createElement(Button, { variant: "outlined", color: "primary", onClick: toggleModal, size: "small" }, "Invite user")))),
                    React.createElement("div", null,
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                            React.createElement(Permissions, { features: features, permissions: ['users.read'] },
                                React.createElement(MaterialPopoverWrap, null,
                                    React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                        React.createElement(ListItemText, { primary: col.title }),
                                        React.createElement(ListItemSecondaryAction, null,
                                            React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } }))))))))))),
                React.createElement(MaterialTableWrap, null,
                    React.createElement(Paper, null,
                        React.createElement(TableContainer, { style: { maxHeight } },
                            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                React.createElement(Permissions, { features: features, permissions: ['users.read'] },
                                    React.createElement(TableHead, null,
                                        React.createElement(TableRow, null,
                                            columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'code' ? 'left' : 'right' }, column.title))),
                                            React.createElement(TableCell, { key: "actions", align: "right" }, "Actions")))),
                                React.createElement(Permissions, { features: features, permissions: ['users.read'] },
                                    React.createElement(TableBody, null, users.map(user => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
                                        columnsToRender.map((column, i) => {
                                            const key = column.key;
                                            const value = key ? user[key] : '';
                                            return React.createElement(UsersTableCell, { key: i, columnKey: key, value: value, customers: customers });
                                        }),
                                        React.createElement(UsersCellActions, { item: user, urlUsers: urlUsers, features: features, actions: cellActions, urlInvites: urlInvites, fetchUsers: fetchUsers, masterTenant: masterTenant, customerCode: customerCode })))))))),
                        React.createElement(TablePagination, { component: "div", count: totalUsers, rowsPerPage: rowsPerPage, onPageChange: onChangePage, page: pageOfItemsAdminUsers, rowsPerPageOptions: [maxHeight] })))))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    customers: getAllCustomers(state),
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    customerCode: state.client.customer_code,
    selectedClient: getSelectedClientInputValue(state),
    clientFromUsers: state.admin.clientFromUsers,
    rowsPerPageInvites: state.admin.rowsPerPageInvites,
    usersWidgetHeight: state.widgets[ownProps.widgetKey],
    columns: getWidgetColumns(state, ownProps.widgetKey),
    pageOfItemsAdminUsers: state.admin.pageOfItemsAdminUsers,
    pageOfItemsAdminInvites: state.admin.pageOfItemsAdminInvites,
});
const mapDispatchToProps = dispatch => ({
    adminClientFromUsersUpdateDispatch: (client) => dispatch(adminClientFromUsersUpdate(client)),
    PageOfItemsUpdateDispatch: (page) => dispatch(PageOfItemsUpdate(page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);
