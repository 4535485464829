import React, { useEffect, useState } from 'react';
import { useCurrencies } from '../CurrenciesProvider';
import DLTFTable from '../../../../../../shared/components/table/DLTTableComponent';
import { CELL_TYPE } from '../../../../../../shared/components/table/DLTTableModel';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import NetworkCreate from './NetworkCreate';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import NetworkUpdate from './NetworkUpdate';
const columns = [
    { id: 'name', label: 'Name', sortable: true },
    { id: 'fees_type', label: 'Fees Type', sortable: true },
    { id: 'can_have_child_wallets', label: 'Child Wallets', sortable: true },
    { id: 'is_multi_source', label: 'Multi Source', sortable: true, type: CELL_TYPE.TEXT },
    { id: 'is_boostable', label: 'Boostable', sortable: true, type: CELL_TYPE.TEXT },
    { id: 'is_utxo', label: 'UTXO', sortable: true, type: CELL_TYPE.TEXT },
    { id: 'elliptic_network_code', label: 'Elliptic Code', sortable: true, type: CELL_TYPE.TEXT },
    { id: 'trmlabs_network_code', label: 'TRMLabs Code', sortable: true, type: CELL_TYPE.TEXT },
    { id: 'explorer_url', label: 'Explorer URL', sortable: true, type: CELL_TYPE.TEXT },
    { id: 'created_at', label: 'Created At', sortable: true, type: CELL_TYPE.DATE_TIME },
    { id: 'updated_at', label: 'Updated At', sortable: true, type: CELL_TYPE.DATE_TIME },
];
const statusIcon = (value) => {
    return value ? React.createElement(CheckIcon, { color: 'success' }) : React.createElement(ClearIcon, { color: 'error' });
};
function mapNetworks(currencies, searchString) {
    return currencies
        .filter((p) => {
        var _a;
        let result = false;
        for (const prop in p) {
            const search = (_a = p[prop]) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().includes(searchString.toLowerCase());
            if (search) {
                result = true;
            }
        }
        return result;
    })
        .map((c) => (Object.assign(Object.assign({}, c), { id: c.uuid, can_have_child_wallets: statusIcon(c.can_have_child_wallets), is_multi_source: statusIcon(c.is_multi_source), is_boostable: statusIcon(c.is_boostable), is_utxo: statusIcon(c.is_utxo), trmlabs_network_code: c.trmlabs_network_code ? c.trmlabs_network_code : '-' })));
}
const CurrencyNetworksContainer = (props) => {
    const { addNew, searchString, onAddClose } = props;
    const { networks, getNetworks, updateNetwork, addNetwork } = useCurrencies();
    const [mappedNetworks, setMappedNetworks] = useState([]);
    const [addDialog, setAddDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState();
    const [updateLoading, setUpdateLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    useEffect(() => {
        setMappedNetworks(mapNetworks(networks, ''));
    }, [networks]);
    useEffect(() => {
        setMappedNetworks(mapNetworks(networks, searchString));
    }, [searchString]);
    useEffect(() => {
        getNetworks();
    }, []);
    useEffect(() => {
        if (addNew) {
            setAddDialog(true);
        }
    }, [addNew]);
    const handleAdd = (network) => {
        setCreateLoading(true);
        addNetwork(network).finally(() => {
            setCreateLoading(false);
            setAddDialog(false);
        });
    };
    const handleEditClose = () => {
        setEditDialog(false);
        setSelectedNetwork(undefined);
    };
    const handleRowClick = (id) => {
        const network = networks.find((network) => network.uuid === id);
        setSelectedNetwork(network);
        setEditDialog(true);
    };
    const handleUpdate = (data) => {
        if (selectedNetwork) {
            setUpdateLoading(true);
            updateNetwork(data, selectedNetwork === null || selectedNetwork === void 0 ? void 0 : selectedNetwork.uuid).finally(() => {
                setEditDialog(false);
                setSelectedNetwork(undefined);
                setUpdateLoading(false);
            });
        }
    };
    if (!networks)
        return;
    return (React.createElement("div", null,
        React.createElement(DLTFTable, { columns: columns, defaultOrder: 'asc', defaultOrderProp: 'type', total: mappedNetworks.length, data: mappedNetworks, pagination: true, onRowClick: handleRowClick }),
        React.createElement(Dialog, { open: addDialog, maxWidth: 'md', fullWidth: true },
            React.createElement(DialogTitle, null, "Add Network"),
            React.createElement(DialogContent, null,
                React.createElement(NetworkCreate, { loading: createLoading, onClose: () => {
                        setAddDialog(false);
                        onAddClose();
                    }, onSave: handleAdd }))),
        React.createElement(Dialog, { open: editDialog, maxWidth: 'md', fullWidth: true },
            React.createElement(DialogTitle, null, "Update Network"),
            React.createElement(DialogContent, null,
                React.createElement(NetworkUpdate, { loading: updateLoading, network: selectedNetwork, onClose: handleEditClose, onSave: handleUpdate })))));
};
export default CurrencyNetworksContainer;
