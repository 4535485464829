import React, { useEffect, useState } from 'react';
import ShuffleIcon from '@mui/icons-material/Shuffle';
const blockStyle = (status) => {
    const statuses = {
        Connecting: '#f6da6e',
        Open: '#32C6A1',
        Closing: '#f6da6e',
        Closed: '#F06158',
        NotOperational: '#f6da6e',
    };
    return {
        faStyles: {
            color: `${statuses[status]}`,
        },
        textColor: {
            color: `${statuses[status]}`,
        },
        container: {
            lineHeight: '58px',
            marginLeft: '25px',
        },
    };
};
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-template-expressions,
  jsx-a11y/alt-text */
const TopBarConnection = () => {
    const [connectionStatus, setConnectionStatus] = useState('Connecting');
    const [operational, setOperationalStatus] = useState(true);
    let unmount = false;
    const connectionStatusUpdate = (status) => {
        if (unmount) {
            return;
        }
        setConnectionStatus(status);
    };
    const operationalStatusUpdate = (status) => {
        if (unmount) {
            return;
        }
        setOperationalStatus(status);
    };
    useEffect(() => {
        const { OrderBookWS } = window;
        const OPManager = OrderBookWS.getOperationalStatusManager();
        OrderBookWS.registerConnectionStatusListener(connectionStatusUpdate);
        OPManager.registerStatusUpdateListener(operationalStatusUpdate);
        return () => {
            unmount = true;
        };
    }, []);
    if (!operational) {
        return (React.createElement("div", null,
            React.createElement(ShuffleIcon, { style: blockStyle('NotOperational').textColor })));
    }
    return (React.createElement("div", { style: { width: '20px' } },
        React.createElement(ShuffleIcon, { style: blockStyle(connectionStatus).textColor })));
};
export default TopBarConnection;
