export var AmlCheckTypes;
(function (AmlCheckTypes) {
    AmlCheckTypes[AmlCheckTypes["address"] = 0] = "address";
    AmlCheckTypes[AmlCheckTypes["transaction"] = 1] = "transaction";
})(AmlCheckTypes || (AmlCheckTypes = {}));
export var AmlCheckStates;
(function (AmlCheckStates) {
    AmlCheckStates[AmlCheckStates["created"] = 0] = "created";
    AmlCheckStates[AmlCheckStates["not_configured"] = 1] = "not_configured";
    AmlCheckStates[AmlCheckStates["done"] = 2] = "done";
})(AmlCheckStates || (AmlCheckStates = {}));
export var AmlCheckPasses;
(function (AmlCheckPasses) {
    AmlCheckPasses["passed"] = "passed";
    AmlCheckPasses["not_passed"] = "not_passed";
    AmlCheckPasses["undefined"] = "undefined";
})(AmlCheckPasses || (AmlCheckPasses = {}));
