import React, { Fragment, PureComponent } from 'react';
import shortid from 'shortid';
import CollapseMarketOverview from './CollapseMarketOverview';
/* eslint-disable @typescript-eslint/no-unused-vars, no-param-reassign */
class MarketOverviewComponent extends PureComponent {
    constructor() {
        super(...arguments);
        this.generateMarkets = (marketsKeys, markets) => {
            // construct flat exchanges list in markets
            const eMapKeys = Object.keys(markets.exchangesByBase);
            const exchangesMap = eMapKeys.map(aKey => Object.entries(markets.exchangesByBase[aKey])).flat();
            const exchanges = [];
            for (const [key, value] of exchangesMap) {
                exchanges.push(...value);
            }
            // construct flat bases list in markets
            const bMapKeys = Object.keys(markets.pairsByBase);
            const basesMap = bMapKeys.map(bKey => Object.entries(markets.pairsByBase[bKey])).flat();
            const bases = [];
            for (const [key, value] of basesMap) {
                bases.push(value);
            }
            bases.sort((a, b) => marketsKeys.indexOf(a.base) - marketsKeys.indexOf(b.base));
            // include in each base/quote pair all exchanges with given base/quote pair
            bases.forEach((mItem) => {
                mItem.exchanges = exchanges
                    .filter(eItem => eItem.base === mItem.base && eItem.quote === mItem.quote);
            });
            return bases.filter(base => { var _a; return (_a = base.exchanges) === null || _a === void 0 ? void 0 : _a.length; });
        };
    }
    render() {
        const { markets, } = this.props;
        let marketsKeys = [];
        let marketItems = [];
        if (markets && markets.pairsByBase) {
            marketsKeys = Object.keys(markets.pairsByBase)
                .sort();
            marketItems = this.generateMarkets(marketsKeys, markets);
        }
        return (React.createElement("div", null, marketItems.map(item => (React.createElement(Fragment, { key: shortid.generate() },
            React.createElement(CollapseMarketOverview, { pairItem: item, key: shortid.generate(), exchanges: item.exchanges }))))));
    }
}
export default React.memo(MarketOverviewComponent);
