/* eslint-disable no-unused-vars, @typescript-eslint/no-unsafe-call, import/prefer-default-export */
import { put, takeLatest, select } from 'redux-saga/effects';
import * as types from '../redux/actionTypes/widgetSettingsActionTypes';
import { schemaUpdateSettingItem } from '../redux/actions/widgetSettingsActions';
import { APPLY_SETTINGS_TO_WORKSPACE } from '../redux/actionTypes/workspaceActions';
function* applySettings(action) {
    if (action.type === types.SCHEMA_APPLY_ALL_SETTINGS) {
        const store = yield select();
        const { workingSettings } = store.widgetSettings;
        const { workspace } = store;
        const { workspaces, dashboards } = workspace;
        const activeDashboard = dashboards.find((d) => d.name === action.dashboard);
        if (activeDashboard) {
            const index = workspaces.findIndex((w) => w.id === activeDashboard.activeWorkspace);
            yield put({ type: APPLY_SETTINGS_TO_WORKSPACE, index, workingSettings });
        }
        // SZ: uncomment if we want to save workspace
        // on Apply settings click
        // const payload = {
        //   settings: JSON.stringify(workspace),
        // };
        // yield put({ type: WORKSPACE_UPDATE_REQUEST, payload });
    }
}
function* applyWorkingSettingItemUpdate(action) {
    try {
        if (action.type === types.SCHEMA_WORKING_REQUEST) {
            const { payload } = action;
            yield put(schemaUpdateSettingItem(payload));
        }
    }
    catch (e) {
        yield put({ type: types.SCHEMA_WORKING_SET_UPDATE_FAIL, message: e });
    }
}
function* resetSettings(action) {
    if (action.type === types.SCHEMA_WORKING_RESET) {
        const store = yield select();
        const { widgets, workingSettings } = store.widgetSettings;
        const { mappedDefaultWorkspaceRecord } = store.workspace;
        const widget = widgets
            .find((w) => w.widgetKey === action.widgetKey);
        const widgetResetSettings = {
            byId: null,
        };
        if (widget) {
            widget.settings.forEach((settingId) => {
                const subStrKey = settingId.replace(action.widgetKey, '');
                // match order list settings per active Tab e.g. Regular, Partial or Suborders
                if (action.listSettingTab && settingId.includes(action.listSettingTab)) {
                    widgetResetSettings[settingId] = Object.assign({}, workingSettings.byId[settingId], {
                        value: mappedDefaultWorkspaceRecord
                            && mappedDefaultWorkspaceRecord.widgetSettings.byId[`ordersList${subStrKey}`].value,
                    });
                }
                // the rest of the widgets
                if (widget.type !== 'orderList') {
                    widgetResetSettings[settingId] = Object.assign({}, workingSettings.byId[settingId], {
                        value: mappedDefaultWorkspaceRecord
                            && mappedDefaultWorkspaceRecord.widgetSettings.byId[`${widget.type}${subStrKey}`].value,
                    });
                }
            });
        }
        yield put({ type: types.SCHEMA_WORKING_RESET_APPLIED, mergedWorking: widgetResetSettings });
    }
}
export const widgetSettingsSaga = [
    takeLatest(types.SCHEMA_WORKING_RESET, resetSettings),
    takeLatest(types.SCHEMA_APPLY_ALL_SETTINGS, applySettings),
    takeLatest(types.SCHEMA_WORKING_REQUEST, applyWorkingSettingItemUpdate),
];
