import React from 'react';
import Decimal from 'decimal.js';
const AskSizePrice = ({ price }) => (React.createElement("div", { className: "container_trade_color_red" }, price === ''
    ? React.createElement("span", null, "--")
    : React.createElement("span", null, price)));
/* eslint-disable camelcase */
const AskSize = ({ orderBookItem }) => {
    if (orderBookItem && orderBookItem.ask && Object.keys(orderBookItem.ask).length) {
        const { volume, volume_split } = orderBookItem.ask;
        const splitVolumeArr = volume_split ? Object.values(volume_split).map(v => +v) : [];
        const minVolume = Math.min(...splitVolumeArr);
        const price = new Decimal(volume_split ? minVolume : volume)
            .toFixed(4)
            .replace(/\.?0+$/, '');
        return React.createElement(AskSizePrice, { price: price });
    }
    return (React.createElement("div", { className: "container_trade_color_red" },
        React.createElement("span", null, "--")));
};
export default React.memo(AskSize);
