import Decimal from 'decimal.js';
/* eslint-disable no-plusplus */
const calculateStats = (orders, service) => {
    let total = new Decimal(0);
    let item = null;
    let minItem = null;
    let maxItem = null;
    let statItem = null;
    if (orders.length === 0) {
        return {
            count: '0',
            avgAmount: '0',
            minOrder: null,
            maxOrder: null,
        };
    }
    for (let i = 0; i < orders.length; i++) {
        item = orders[i];
        const amt = service.convertInBase(item.market_item.base.toLowerCase(), item.executed.amount);
        total = amt !== 'Not convertible' ? total.add(amt) : total.add(0);
        if (!minItem) {
            minItem = item;
        }
        else {
            const amtMin = service.convertInBase(minItem.market_item.base.toLowerCase(), minItem.executed.amount);
            const minUsd = amtMin !== 'Not convertible' ? amtMin : '0';
            const itemUsd = amt !== 'Not convertible' ? amt : '0';
            if (itemUsd !== '0' && minUsd !== '0') {
                minItem = new Decimal(minUsd).greaterThan(itemUsd) ? item : minItem;
            }
        }
        if (!maxItem) {
            maxItem = item;
        }
        else {
            const amtMax = service.convertInBase(maxItem.market_item.base.toLowerCase(), maxItem.executed.amount);
            const maxUsd = amtMax !== 'Not convertible' ? amtMax : '0';
            const itemUsd = amt !== 'Not convertible' ? amt : '0';
            maxItem = new Decimal(maxUsd).lessThan(itemUsd) ? item : maxItem;
        }
    }
    if (minItem && maxItem) {
        const averageAmount = Decimal.div(total, orders.length);
        const amtMin = service.convertInBase(minItem.market_item.base.toLowerCase(), minItem.executed.amount);
        const amtMax = service.convertInBase(maxItem.market_item.base.toLowerCase(), maxItem.executed.amount);
        const maxTrUsdAmount = amtMax !== 'Not convertible' ? amtMax : '0';
        const minTrUsdAmount = amtMin !== 'Not convertible' ? amtMin : '0';
        statItem = {
            count: `${orders.length}`,
            avgAmount: `${averageAmount.toFixed(2)}`,
            minOrder: Object.assign({}, minItem, { amount: minTrUsdAmount }),
            maxOrder: Object.assign({}, maxItem, { amount: maxTrUsdAmount }),
        };
    }
    return statItem;
};
export default calculateStats;
