import React from 'react';
import { TableRow, TableCell } from '@mui/material';
const ClientDetailsCompany = ({ clientDetails }) => {
    if (!clientDetails)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Company name:"),
            React.createElement(TableCell, null, clientDetails.company_name)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "VAT:"),
            React.createElement(TableCell, null, clientDetails.vat)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Address:"),
            React.createElement(TableCell, null, clientDetails.address)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Zipcode:"),
            React.createElement(TableCell, null, clientDetails.zip_code)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "City:"),
            React.createElement(TableCell, null, clientDetails.city)),
        React.createElement(TableRow, null,
            React.createElement(TableCell, null, "Country:"),
            React.createElement(TableCell, null, clientDetails.country))));
};
export default ClientDetailsCompany;
