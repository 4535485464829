import axios from 'axios';
import { ApiPollService } from '../shared/helpers/apiPollService';
/* eslint-disable @typescript-eslint/no-unsafe-return, class-methods-use-this */
export default class ClientsService {
    constructor(config, delay) {
        this.model = {
            records: [],
            total: '0',
            role: '',
            name: '',
            code: '',
            client: null,
            features: [],
            display_name: '',
            customer_code: '',
            registration_date: '',
            masterTenant: false,
        };
        this.config = config;
        this.pollingService = new ApiPollService(delay || 10000);
    }
    getData() {
        return this.model;
    }
    handleResponse(data) {
        if (data.data.records) {
            this.model.records = data.data.records;
        }
        if (data.data.total) {
            this.model.total = data.data.total;
        }
        if (data.data.client) {
            this.model.client = data.data.client;
            this.model.masterTenant = data.data.client.code === data.data.client.deposit_holder_code;
        }
        if (Array.isArray(data.data.features)) {
            this.model.features = data.data.features;
        }
        if (data.data.role) {
            this.model.role = data.data.role;
        }
        if (data.data.name) {
            this.model.name = data.data.name;
        }
        if (data.data.code) {
            this.model.code = data.data.code;
        }
        if (data.data.display_name) {
            this.model.display_name = data.data.display_name;
        }
        if (data.data.registration_date) {
            this.model.registration_date = data.data.registration_date;
        }
        if (data.data.customer_code) {
            this.model.customer_code = data.data.customer_code;
        }
        return this.model;
    }
    makeRequest() {
        const { ErrorLogger } = window;
        const axiosRequest = axios(this.config);
        return axiosRequest
            .then(response => this.handleResponse(response))
            .catch((error) => {
            if (error.message === 'canceled') {
                return this.handleReject(error);
            }
            ErrorLogger.setErrors(error, new Date());
            return this.handleReject(error);
        });
    }
    makePollRequest(callback, delay) {
        if (delay) {
            this.pollingService.delay = delay;
        }
        if (this.config.url) {
            const callbackPayload = {
                url: this.config.url,
                action: callback,
            };
            this.pollingService.addCallback(callbackPayload);
        }
    }
    clearPollRequest(url) {
        this.pollingService.removeCallBack(url);
    }
    handleReject(error) {
        throw error;
    }
}
