import * as types from '../actionTypes/widgetsActionTypes';
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return */
export const initialState = {};
const widgetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.WIDGET_HEIGHT_UPDATE:
            return Object.assign(Object.assign({}, state), { [action.widgetKey]: action.height });
        default:
            return state;
    }
};
export default widgetsReducer;
