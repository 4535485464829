/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Button, Dialog, Checkbox, TextField, TableCell, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import shortId from 'shortid';
const ProtocolFields = ({ data, handleFieldChange }) => {
    const [fieldValue, setFieldValue] = useState(data);
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const toggleDialog = () => {
        toggleOpenDialog(prev => !prev);
    };
    const onFieldChange = (evt) => {
        const { value } = evt.target;
        setFieldValue(value);
    };
    const onReferenceIdChangeDone = () => {
        handleFieldChange(fieldValue);
        toggleDialog();
    };
    return (React.createElement(TableCell, { align: "right", style: { backgroundColor: '#393f45' } },
        React.createElement("div", { role: "button", tabIndex: -42, onKeyDown: () => undefined, onClick: () => toggleDialog() }, data || 'N/A'),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "md", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Update field"),
            React.createElement(DialogContent, null,
                React.createElement("div", null,
                    React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", value: fieldValue, variant: "outlined", onChange: onFieldChange, InputLabelProps: { shrink: true } })),
                React.createElement("div", null,
                    React.createElement(Button, { color: "primary", variant: "contained", onClick: onReferenceIdChangeDone }, "UPDATE"))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => toggleDialog(), variant: "outlined" }, "Cancel")))));
};
const TableCellContainer = ({ value, columnKey, item }) => {
    const checkboxFields = ['float_min', 'fix', 'spread_widening_on', 'autoquoter_on_off'];
    const inputFields = ['min_spread', 'widening_factor', 'notional', 'add_client_shift'];
    const handleCheckboxChange = (evt) => undefined;
    const handleFieldChange = (fieldValue) => undefined;
    if (inputFields.includes(`${columnKey}`)) {
        return (React.createElement(ProtocolFields, { data: value, handleFieldChange: handleFieldChange }));
    }
    if (checkboxFields.includes(`${columnKey}`)) {
        const idKey = shortId.generate();
        return (React.createElement(TableCell, { align: "center" },
            React.createElement("div", { className: "form__form-group" },
                React.createElement("label", { htmlFor: idKey, className: "checkbox-btn", style: { marginBottom: '5px' } },
                    React.createElement(Checkbox, { id: idKey, name: columnKey, checked: value, onChange: handleCheckboxChange, className: "checkbox-btn__checkbox" })))));
    }
    return (React.createElement(TableCell, { align: columnKey === 'ul' ? 'left' : 'right' }, value || 'N/A'));
};
export default TableCellContainer;
