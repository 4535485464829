import React, { useState } from 'react';
import Decimal from 'decimal.js';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import { Box, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});
/* eslint-disable no-nested-ternary */
const HoldingsItem = ({ item }) => {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const classes = useRowStyles();
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, { style: { width: '20%' } },
                React.createElement(Box, { display: 'flex', alignItems: 'center' },
                    React.createElement(IconButton, { size: "small", onClick: handleExpandClick, "aria-expanded": expanded, "aria-label": "show more", className: `holdings_button_expand ${expanded ? '' : 'holdings_button_expand_open'}` }, expanded ? React.createElement(RemoveIcon, null) : React.createElement(AddIcon, null)),
                    React.createElement("span", { className: `${item.asset}__icon` }),
                    React.createElement("span", { className: "holdings_item_asset_text" }, item.asset ? item.asset.toUpperCase() : ''))),
            React.createElement(TableCell, { style: { width: '20%' } },
                React.createElement("div", null, Decimal.add(item.balanceCustody || 0, item.balanceTrading || 0).toFixed() || '-----')),
            React.createElement(TableCell, { style: { width: '20%' } },
                React.createElement("div", null, item.costTrading ? new Decimal(item.costTrading).toFixed(2) : '-----')),
            React.createElement(TableCell, { style: { width: '20%' } },
                React.createElement("div", null, item.marketValueTrading ? new Decimal(item.marketValueTrading || 0).toFixed(2) : '-----')),
            React.createElement(TableCell, { style: { width: '20%' } },
                React.createElement("div", { style: { color: item.roiTrading.includes('-') ? '#F06158' : '#32C6A1' } }, item.roiTrading ? item.roiTrading === 'Infinity' ? 'loading...' : `${item.roiTrading} %` : '0 %'))),
        React.createElement(TableRow, { className: classes.root },
            React.createElement(TableCell, { style: { padding: 0 }, colSpan: 5 },
                React.createElement(Collapse, { in: expanded, timeout: "auto", unmountOnExit: true },
                    React.createElement(Table, null,
                        React.createElement(TableBody, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box" }, "Trading")),
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box" }, item.balanceTrading || '-----')),
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box" }, item.costTrading ? new Decimal(item.costTrading).toFixed(2) : '-----')),
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box" }, item.marketValueTrading ? new Decimal(item.marketValueTrading || 0).toFixed(2) : '-----')),
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box", style: { color: item.roiTrading.includes('-') ? '#F06158' : '#32C6A1' } }, item.roiTrading ? item.roiTrading === 'Infinity' ? 'loading...' : `${item.roiTrading} %` : '0 %'))),
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", null, "Custody")),
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box" }, item.balanceCustody || '-----')),
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box" }, item.costCustody || '-----')),
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box" }, item.marketValueCustody ? new Decimal(item.marketValueCustody || 0).toFixed(2) : '-----')),
                                React.createElement(TableCell, { style: { width: '20%' } },
                                    React.createElement("div", { className: "date-box", style: { color: item.roiCustody.includes('-') ? '#F06158' : '#32C6A1' } }, item.roiCustody ? item.roiCustody === 'Infinity' ? 'loading...' : `${item.roiCustody} %` : '0 %'))))))))));
};
export default HoldingsItem;
