import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import { useResizeDetector } from 'react-resize-detector';
import { TransfersCellItem } from '../../../Custody/styling/style';
/* eslint-disable no-nested-ternary */
const LogCell = ({ value, keyOf, }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (keyOf === 'code' || keyOf === 'request_code') {
        return (React.createElement(TableCell, { align: keyOf === 'code' ? 'left' : 'right', ref: ref },
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value || 'N/A')));
    }
    if (keyOf === 'created_at') {
        return (React.createElement(TableCell, { align: "left" }, value ? moment(value).format('YYYY-MM-DD H:mm:ss') : 'n/a'));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default LogCell;
