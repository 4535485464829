import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
export const Layout = styled(Box)(({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    `);
export const Row = styled(Box)(({ theme }) => `
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(1)};
`);
export const RowTitle = styled(Box)(({ theme }) => `
  background-color: ${theme.palette.secondary.light};
  max-width: 170px;
  min-width: 100px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: ${theme.spacing(1)};
  font-weight: ${theme.typography.overline.fontWeight};
  font-size: ${theme.typography.overline.fontSize};
  line-height: ${theme.typography.overline.lineHeight};
`);
export const RowContent = styled(Box)(({ theme }) => `
  padding: ${theme.spacing(2)};
  gap: ${theme.spacing(2)};
  display: flex;
  flex-wrap: wrap;
  flex: auto;
  background-color: ${theme.palette.background.paper};
  border-left: 3px solid ${theme.palette.secondary.light}
`);
export const RowItem = styled(Box)(({ theme }) => `
  min-width: 200px;
  display: flex;
  flex-direction: column;
`);
export const ItemLabel = styled('span')(({ theme }) => `
  font-weight: ${theme.typography.subtitle2.fontWeight};
  font-size: ${theme.typography.subtitle2.fontSize};
  line-height: ${theme.typography.subtitle2.lineHeight};
  color: ${theme.palette.text.secondary};
`);
export const NumberValue = styled('span')(({ theme }) => `
  white-space: nowrap;
  font-family: Martian Mono, monospace;
`);
