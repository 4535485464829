import { call, put, takeLatest } from 'redux-saga/effects';
import Messages from '../shared/helpers/errorMessages';
import * as currenciesActions from '../redux/actions/currenciesActions';
import * as types from '../redux/actionTypes/currenciesActionTypes';
import { composeAnyErrorMessage } from '../shared/helpers/interceptors';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
function* fetchCurrencies() {
    try {
        const currencies = yield call(currenciesActions.fetchCurrencies);
        yield put({
            type: types.FETCH_CURRENCIES_SUCCESS,
            currencies: { custody: currencies.custody, trading: currencies.trading }
        });
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.USER_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.FETCH_CURRENCIES_FAIL, message });
    }
}
export const currenciesSaga = [takeLatest(types.FETCH_CURRENCIES_INIT, fetchCurrencies)];
