import React from 'react';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Container } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { PanelContainer, MaterialPopoverWrap, WalletsClientContainer } from '../../styling/style';
import { SpinnerContainer } from '../../../Admin/styling/style';
import WithdrawalTableCell from '../Wallets/WithdrawalTableCell';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import WithdrawalsCellActions from '../Wallets/WithdrawalsCellActions';
/* eslint-disable react/jsx-indent */
const Withdrawals = ({ totalWithdrawals, loading, admin, pageWithdrawals, onChangePageW, sortBy, columns, features, currencies, handleColumnsClick, columnsOpen, anchorColumns, handleColumnsClose, columnsToRender, hideShowColumn, withdrawals, maxHeight, withdrawalsToRender, rowsPerPage, sortDirection, onSortHandle, fetchAllWithDrawals, walletsLoading, clientInfo, }) => {
    const colsW = columns.filter((c) => c.id.includes('withdrawalsApproveColumns'));
    return (React.createElement(PanelContainer, { className: 'blocks_wrapp_body_style' }, loading ? (React.createElement(SpinnerContainer, null,
        React.createElement(CircularProgress, { color: 'primary' }))) : (React.createElement(React.Fragment, null,
        React.createElement(WalletsClientContainer, null,
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: handleColumnsClick, size: 'large' },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    }, transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: 'nav', "aria-label": 'more actions', className: 'mui_table_settings_wrap' }, colsW.map((col) => (React.createElement(ListItem, { key: `withdrawals_approve_${col.id}` },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: 'end', size: 'small', color: 'default', onChange: () => hideShowColumn(col.id), checked: !!columnsToRender.find((c) => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        withdrawalsToRender.length === 0 ? (React.createElement(Container, null,
            React.createElement(Alert, { severity: 'info', variant: 'outlined' }, "No items found"))) : (React.createElement(React.Fragment, null,
            React.createElement(TableContainer, { style: { maxHeight } },
                React.createElement(Table, { size: 'small', stickyHeader: true, "aria-label": 'sticky table' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null,
                            columnsToRender.map((column) => (React.createElement(TableCell, { align: 'right', key: column.key, style: { minWidth: column.minWidth } },
                                React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)))),
                            React.createElement(TableCell, { key: 'actions', align: 'right', className: 'tokens_table_cell_width' }, "Actions"))),
                    React.createElement(TableBody, null, withdrawalsToRender.map((w) => (React.createElement(TableRow, { hover: true, role: 'checkbox', tabIndex: -1, key: w.code },
                        columnsToRender.map((column, i) => {
                            const key = column.key;
                            const value = key ? w[key] : undefined;
                            return (React.createElement(WithdrawalTableCell, { key: i, item: w, value: value, columnKey: key, currencies: currencies, withdrawals: withdrawals, currency: w.currency_code, walletsLoading: walletsLoading, currencyFee: w.fee_currency_code }));
                        }),
                        React.createElement(WithdrawalsCellActions, { item: w, admin: admin, features: features, currencies: currencies, clientInfo: clientInfo, fetchAllWithDrawals: fetchAllWithDrawals }))))))),
            React.createElement(TablePagination, { component: 'div', page: pageWithdrawals, count: totalWithdrawals, rowsPerPage: rowsPerPage, onPageChange: onChangePageW, rowsPerPageOptions: [maxHeight] })))))));
};
export default Withdrawals;
