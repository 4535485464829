var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import CustomersService from '../../services/customersService';
import { CUSTOMER_SELECT_SET_UPDATE } from '../actionTypes/customerSearchActionsTypes';
import buildUrl from 'build-url';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const fetchCustomers = (clientCode, details) => __awaiter(void 0, void 0, void 0, function* () {
    const params = buildUrl('', {
        queryParams: {
            page: '1',
            search: '',
            client_code: clientCode ? clientCode : '',
            limit: '1000',
            status: 'enabled',
            sort_by: 'created',
            details: details ? 'true' : 'false',
        },
    });
    const { ErrorLogger } = window;
    const customersService = new CustomersService({ url: `/customers${params}`, method: 'get' });
    return customersService.makeRequest()
        .then((responseData) => responseData)
        .catch((e) => {
        ErrorLogger.setErrors(e, new Date());
        throw new Error(`Something went wrong, error: ${e}`);
    });
});
export const selectCustomer = (customer) => ({
    type: CUSTOMER_SELECT_SET_UPDATE,
    customer,
});
