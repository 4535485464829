import React, { useCallback, useEffect, useState, useRef, } from 'react';
import { useDispatch } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, Dialog, TextField } from '@mui/material';
import RolesService from '../../../../../../services/rolesService';
import showNotification from '../../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
const AddRoleDialog = ({ toggle, open, code, fetchAll, }) => {
    const dirty = useRef(false);
    const [roleName, setRoleName] = useState('');
    const [invalidFields, setInvalidFields] = useState([]);
    const dispatch = useDispatch();
    const notice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onRoleNameChange = (evt) => {
        const { value } = evt.target;
        setRoleName(value);
    };
    const validateFields = (fieldsToValidate) => {
        const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const errors = [];
        for (const [key, value] of Object.entries(fieldsToValidate)) {
            if (`${value}`.trim() === '') {
                errors.push(key);
            }
            else if (key === 'email') {
                if (!regEx.test(`${value}`.toLowerCase())) {
                    errors.push(key);
                }
            }
        }
        return errors;
    };
    const onAddNewRole = () => {
        dirty.current = true;
        const errors = validateFields({ roleName });
        if (errors.length) {
            setInvalidFields([...errors]);
            return;
        }
        const rolesService = new RolesService({
            url: `clients/${code}/roles`,
            method: 'post',
            data: { role_name: roleName },
            baseURL: getEnvSettings().adminApiUrl,
        });
        rolesService.makeRequest()
            .then(() => {
            showNotification({
                message: 'Role added',
                color: 'success',
                dispatch: notice,
            });
            fetchAll(code);
            toggle();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, 'Failed add new role: because of: ');
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: notice,
            });
        });
    };
    useEffect(() => {
        if (dirty.current) {
            const errors = validateFields({ roleName });
            setInvalidFields([...errors]);
        }
    }, [roleName]);
    return (React.createElement(Dialog, { open: open, onClose: () => toggle(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Add new role"),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", value: roleName, variant: "outlined", onChange: onRoleNameChange, label: "Role name", helperText: `${invalidFields.includes('roleName') ? ' - This field is required' : ''}` })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "success", variant: "contained", onClick: () => { onAddNewRole(); }, disabled: invalidFields.includes('roleName') }, "Add role"),
            React.createElement(Button, { variant: "outlined", color: "inherit", onClick: toggle }, "Close"))));
};
export default AddRoleDialog;
