import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import SubOrderHead from '../SubOrdersHead';
import SubOrdersList from '../SubOrdersList';
import SuborderDetails from '../SuborderDetails';
import SuborderCustomerInfo from '../SuborderCustomerInfo';
import { getAvailableExchanges } from '../../../../../../redux/selectors/selectors';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
const executingMemoized = (items) => items;
const ModalBodyContainer = ({ splitOrderItem, suborderItems, subOrdersExecuting, exchanges, features, setShowAlert, setAlertMessage, handleUpdateRemainder, remainder, }) => {
    const executingItems = useMemo(() => executingMemoized(subOrdersExecuting), [subOrdersExecuting]);
    useEffect(() => {
        if (splitOrderItem) {
            const { amount } = splitOrderItem;
            handleUpdateRemainder(suborderItems, amount);
        }
    }, [suborderItems]);
    return React.createElement(React.Fragment, null, splitOrderItem === null
        ? React.createElement(Alert, { severity: "error" }, "Something went wrong")
        :
            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
                React.createElement(SubOrderHead, { color: "primary", orderItem: splitOrderItem }),
                React.createElement("hr", { style: { width: '100%' } }),
                React.createElement(SuborderCustomerInfo, { customerCode: splitOrderItem === null || splitOrderItem === void 0 ? void 0 : splitOrderItem.customer_code }),
                React.createElement("hr", { style: { width: '100%' } }),
                React.createElement(SuborderDetails, { remainder: remainder, action: splitOrderItem.action, amount: splitOrderItem.amount, base: splitOrderItem.market_item.base, quote: splitOrderItem.market_item.quote }),
                React.createElement(SubOrdersList, { features: features, exchanges: exchanges, onError: setShowAlert, subOrders: suborderItems, amount: splitOrderItem.amount, executingItems: executingItems, onErrorMessage: setAlertMessage, partialCode: splitOrderItem.code, calculateReminder: handleUpdateRemainder })));
};
const mapStateToProps = (state) => {
    const { features } = state.client;
    const { suborderItems, subOrdersExecuting } = state.subOrders;
    const exchanges = getAvailableExchanges(state);
    return {
        features,
        exchanges,
        suborderItems,
        subOrdersExecuting,
    };
};
export default connect(mapStateToProps)(ModalBodyContainer);
