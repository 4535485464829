import { styled } from "styled-components";
export const Label = styled.span `
  font-size: 0.75rem;
  line-height: 166%;
  color: var(--text-secondary, rgba(255, 255, 255, 0.70));
`;
export const SecondaryText = styled.span `
  white-space: nowrap;
  font-size: 0.75rem;
  color: var(--text-secondary, rgba(255, 255, 255, 0.70));
`;
