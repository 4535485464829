import React, { useState, useEffect } from 'react';
import { Box, Table } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import RenderHistoryRow from './RenderHistoryRow';
import { getExchangesOptions } from '../../../../../redux/selectors/selectors';
import renderFilterParams from '../../../../../shared/helpers/renderFilterParams';
import { collectParamsFromSettings } from '../../../../../shared/helpers/createUrlFromParams';
const getSettingByd = (settings, settingId) => {
    if (settings && settings.length === 0) {
        return null;
    }
    const find = settings.find(item => item && item.id.includes(settingId));
    return find && find.value;
};
const ExchangeTradeHistory = ({ selectedPair, partialOrder, selectedExchange, settings, exchanges, widgetKey, }) => {
    const [channel, setChannel] = useState('');
    const [localPair, setLocalPair] = useState('');
    const [globalPair, setGlobalPair] = useState('');
    const [globalChannel, setGlobalChannel] = useState('');
    const [paramKeys, setParamsKeys] = useState([]);
    const [channelBySettings, setChannelBySettings] = useState('');
    const [ignoreGlobal, setIgnoreGlobal] = useState(false);
    const [filterParams, setFilterParams] = useState(null);
    const isIgnoreGlobalChecked = (set) => {
        const ignore = set.find(s => s && s.id.includes('IgnoreGlobal'));
        return ignore === null || ignore === void 0 ? void 0 : ignore.value;
    };
    useEffect(() => {
        const isIgnoreGlobal = isIgnoreGlobalChecked(settings);
        setIgnoreGlobal(!!isIgnoreGlobal);
        const params = collectParamsFromSettings(settings, widgetKey);
        const keys = Object.keys(params);
        setParamsKeys(keys);
        setFilterParams(params);
    }, [settings]);
    useEffect(() => {
        if (selectedPair) {
            setGlobalPair(`${selectedPair.base}/${selectedPair.quote}`.toLowerCase());
        }
    }, [selectedPair]);
    useEffect(() => {
        if (settings.length) {
            const settingsBaseArr = getSettingByd(settings, 'Base');
            const settingsQuoteArr = getSettingByd(settings, 'Quote');
            if (settingsBaseArr && settingsQuoteArr) {
                const localPairVal = `${settingsBaseArr
                    .value}/${settingsQuoteArr.value}`.toLowerCase();
                setLocalPair(localPairVal);
            }
        }
    }, [settings]);
    useEffect(() => {
        if (selectedExchange && globalPair) {
            const globalChannelVal = partialOrder ? `trade-consolidated-${globalPair}`
                : `trade-${selectedExchange.value.toLowerCase()}-${globalPair}`;
            setGlobalChannel(globalChannelVal);
        }
    }, [partialOrder, globalPair, selectedExchange]);
    useEffect(() => {
        if (settings.length) {
            const settingsExchangeArr = getSettingByd(settings, 'Exchanges');
            if (settingsExchangeArr && localPair) {
                const channelBySettingsVal = partialOrder ? `trade-consolidated-${localPair}`
                    : `trade-${settingsExchangeArr.value.toLowerCase()}-${localPair}`;
                setChannelBySettings(channelBySettingsVal);
            }
        }
    }, [settings, localPair, partialOrder]);
    useEffect(() => {
        if (ignoreGlobal && channelBySettings) {
            setChannel(channelBySettings);
        }
        else if (globalChannel) {
            setChannel(globalChannel);
        }
    }, [ignoreGlobal, channelBySettings, globalChannel]);
    if (!channel) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement("div", { role: "main" },
        ignoreGlobal ? renderFilterParams(filterParams, paramKeys) : null,
        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, { className: "trade_history_title" },
                    React.createElement(TableCell, null, "price"),
                    React.createElement(TableCell, null, "amount"),
                    React.createElement(TableCell, null, "exchange"),
                    React.createElement(TableCell, null, "created"))),
            React.createElement(RenderHistoryRow, { channel: channel, exchanges: exchanges }))));
};
const stateToProps = (state) => {
    const { selectedPair, selectedExchange, partialOrder } = state.placeOrder;
    return Object.assign(Object.assign({}, state), { selectedPair,
        partialOrder,
        selectedExchange, exchanges: getExchangesOptions(state) });
};
export default connect(stateToProps)(ExchangeTradeHistory);
