import React, { useState } from 'react';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import { MaterialPopoverWrap } from '../../../../Custody/styling/style';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import { WithdrawalDelete, WithdrawalMasterSign } from './WithdrawalsActions';
const WithdrawalsCellActions = ({ item, clientCode, admin, features, fetchAllWithDrawals, }) => {
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [action, setAction] = useState(undefined);
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (userAction) => {
        toggleOpenDialog(prev => !prev);
        setAction(userAction);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const states = ['pending_admin_approve', 'pending_sign', 'failed', 'unknown_creation_error'];
    const renderActionsContent = (userAction, toggle) => {
        if (!userAction) {
            return (React.createElement("div", null, "something went wrong"));
        }
        if (userAction === 'masterSign') {
            return (React.createElement(WithdrawalMasterSign, { item: item, toggleDialog: toggle, actionKey: "mastersign", clientCode: clientCode, fetchAllWithDrawals: fetchAllWithDrawals }));
        }
        if (userAction === 'sign') {
            return (React.createElement(WithdrawalMasterSign, { item: item, actionKey: "sign", toggleDialog: toggle, clientCode: clientCode, fetchAllWithDrawals: fetchAllWithDrawals }));
        }
        return (React.createElement(WithdrawalDelete, { item: item, toggleDialog: toggle, clientCode: clientCode, fetchAllWithDrawals: fetchAllWithDrawals }));
    };
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { size: "small", "aria-label": "more", onClick: handleActionsClick, disabled: !states.includes(item.state) && !(item.state === 'pending_sign' && admin) },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { component: "nav", "aria-label": "more actions", dense: true },
                    states.includes(item.state) && (React.createElement(Permissions, { features: features, permissions: ['client_custody_transaction.delete'] },
                        React.createElement(ListItem, { onClick: () => toggleDialog('delete'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(DeleteIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { style: { fontSize: '12px' }, primary: "Delete" })))),
                    (item.state === 'pending_sign' && !admin) && (React.createElement(Permissions, { features: features, permissions: ['client_custody_withdrawal_master_sign.create'] },
                        React.createElement(ListItem, { onClick: () => toggleDialog('masterSign'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(CreateIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { style: { fontSize: '12px' }, primary: "Master sign" })))),
                    (item.state === 'pending_sign' && !admin) && (React.createElement(Permissions, { features: features, permissions: ['client_custody_transaction.update'] },
                        React.createElement(ListItem, { onClick: () => toggleDialog('sign'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(CreateIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { style: { fontSize: '12px' }, primary: "Sign" }))))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Actions"),
            React.createElement(DialogContent, null, action ? renderActionsContent(action, toggleDialog) : null))));
};
export default WithdrawalsCellActions;
