/* eslint-disable camelcase, @typescript-eslint/no-explicit-any,
  @typescript-eslint/no-floating-promises, no-useless-return, react/jsx-indent */
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import { Box, Button, Alert } from '@mui/material';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import buildUrl from 'build-url';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import TokenCell from './TokenCell';
import AddNewModal from './AddNewModal';
import { RemoveToken } from './TokensActions';
import TokenCellActions from './TokenCellActions';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import { BodyContainer, PanelContainer } from '../../styling/style';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import ApiTokenService from '../../../../../services/apiTokenService';
import showNotification from '../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { MaterialPopoverWrap, MaterialTableWrap } from '../../../Custody/styling/style';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
const ApiTokensContainer = ({ features, columns, tokensWidgetHeight }) => {
    const [status, setStatus] = useState('init');
    const [maxHeight, setMaxHeight] = useState(480);
    const [totalItems, setTotalItems] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [tokensToRender, setTokensToRender] = useState([]);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const columnsOpen = Boolean(anchorColumns);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageTokens'));
    };
    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const fetchTokens = (page, cancelToken) => {
        setStatus('fetching');
        const urlOptions = {
            queryParams: {
                limit: `${rowsPerPage}`,
                page: `${page + 1}`,
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
            path: '/user/api_tokens',
        };
        const endpointUrl = buildUrl('', urlOptions);
        const apiTokenService = new ApiTokenService({
            url: endpointUrl,
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().frontendApiUrl,
        });
        apiTokenService.makeRequest()
            .then((responseData) => {
            if (responseData) {
                const { records, total } = responseData;
                setTokensToRender([...records]);
                setStatus('fetched');
                setTotalItems(+total);
            }
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.API_TOKEN_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const cellActions = {
        actions: {
            removeToken: (item) => {
                const apiTokenService = new ApiTokenService({
                    url: `/user/api_tokens/${item.public_key}`,
                    method: 'delete',
                    baseURL: getEnvSettings().frontendApiUrl,
                });
                apiTokenService.makeRequest()
                    .then(() => {
                    fetchTokens(currentPage);
                    showNotification({
                        message: 'API Token successfully deleted',
                        color: 'success',
                        dispatch: errorNotice,
                    });
                })
                    .catch((e) => {
                    const message = composeErrorMessage(e, Messages.API_TOKEN_DELETE);
                    showNotification({
                        message,
                        color: 'error',
                        dispatch: errorNotice,
                    });
                });
            },
        },
        actionsComponents: {
            removeToken: RemoveToken,
        },
        actionsList: [
            { name: 'Remove', action: 'removeToken' },
        ],
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        fetchTokens(currentPage, cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, [currentPage, rowsPerPage]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(tokensWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [tokensWidgetHeight]);
    if (status === 'init' || status === 'fetching') {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement("div", null,
        React.createElement(PanelContainer, null,
            React.createElement("div", null,
                React.createElement(BodyContainer, null,
                    modalOpen ? (React.createElement(AddNewModal, { open: modalOpen, page: currentPage, toggleFunc: toggleModal, updateList: fetchTokens, errorNotice: errorNotice })) : null,
                    React.createElement(Permissions, { features: features, permissions: ['api_tokens.create'] },
                        React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: 1 },
                            React.createElement(Button, { size: "small", color: "primary", variant: "outlined", onClick: toggleModal }, "Add New"))),
                    !tokensToRender.length ? (React.createElement(Box, { p: 1 },
                        React.createElement(Alert, { severity: "info", variant: "outlined" }, "No items found"))) : (React.createElement(React.Fragment, null,
                        React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', alignItems: 'center', p: 1 },
                            React.createElement("div", null,
                                React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                                    React.createElement(ViewColumnIcon, null)),
                                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }, transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                    } },
                                    React.createElement(MaterialPopoverWrap, null,
                                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                            React.createElement(ListItemText, { primary: col.title }),
                                            React.createElement(ListItemSecondaryAction, null,
                                                React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
                        React.createElement(MaterialTableWrap, null,
                            React.createElement(Paper, { className: "mui_table_root" },
                                React.createElement(TableContainer, { style: { maxHeight } },
                                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                        React.createElement(TableHead, null,
                                            React.createElement(TableRow, null,
                                                columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'label' ? 'left' : 'right' }, column.title))),
                                                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                                        React.createElement(TableBody, null, tokensToRender.map(token => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
                                            columnsToRender.map((column, i) => {
                                                const key = column.key;
                                                const value = key ? token[key] : undefined;
                                                return React.createElement(TokenCell, { key: i, value: value, keyOf: key });
                                            }),
                                            React.createElement(TokenCellActions, { actions: cellActions, item: token }))))))),
                                React.createElement(TablePagination, { component: "div", count: totalItems, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: handlePageChange, rowsPerPageOptions: [maxHeight] }))))))))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    tokensWidgetHeight: state.widgets[ownProps.widgetKey],
    columns: getWidgetColumns(state, ownProps.widgetKey),
});
export default connect(mapStateToProps)(ApiTokensContainer);
