import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NEW_ORDER_TYPE_SET_UPDATE } from '../../../../../../redux/actionTypes/placeOrderActionTypes';
import { MenuItem, TextField } from '@mui/material';
const SelectOrderType = ({ orderType, validation, amount, limitPrice, partial, selectedExchange, features, }) => {
    const dispatch = useDispatch();
    const [options, setOptions] = useState([
        { value: 'Market', label: 'Market' },
        { value: 'Limit', label: 'Limit' },
    ]);
    const handleTypeChange = (event) => {
        const newValue = event.target.value;
        if (newValue) {
            dispatch({ type: NEW_ORDER_TYPE_SET_UPDATE, orderType: { value: newValue, label: newValue } });
        }
        // if type market recalculate total price
        // if type limit clean limit field
        const val = newValue || '';
        validation(amount, limitPrice, val, partial);
    };
    useEffect(() => {
        if (features === null || features === void 0 ? void 0 : features.includes('restrict_order_size.read')) {
            const filtered = options.filter(o => o.value !== 'Market');
            setOptions(filtered);
        }
    }, [features]);
    useEffect(() => {
        if (selectedExchange && selectedExchange.value === 'cex') {
            dispatch({ type: NEW_ORDER_TYPE_SET_UPDATE, orderType: { value: 'Limit', label: 'Limit' } });
            const filtered = options.filter(o => o.value !== 'Market');
            setOptions(filtered);
        }
    }, [selectedExchange]);
    useEffect(() => {
        dispatch({ type: NEW_ORDER_TYPE_SET_UPDATE, orderType: { value: 'Market', label: 'Market' } });
    }, []);
    return (React.createElement(React.Fragment, null, orderType ?
        React.createElement(TextField, { select: true, fullWidth: true, size: "small", variant: "outlined", value: orderType === null || orderType === void 0 ? void 0 : orderType.value, label: "Order type", onChange: handleTypeChange }, options.map((option, index) => (React.createElement(MenuItem, { key: index, value: option.value }, option.label)))) : null));
};
const stateToProps = (state) => {
    const { features } = state.client;
    const { orderType, amount, limitPrice, selectedExchange, } = state.placeOrder;
    return {
        amount,
        features,
        orderType,
        limitPrice,
        selectedExchange,
    };
};
export default connect(stateToProps)(SelectOrderType);
