import { TableRow, TableCell } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import shortid from 'shortid';
const PnLTableRow = ({ data, streamUpdate }) => {
    const prevChannel = useRef('');
    const refId = useRef(shortid.generate());
    const [rowValues, setRowValues] = useState({
        positionValue: 'N/A',
        unrealizedTotal: 'N/A',
        realtimePrice: 'N/A',
    });
    useEffect(() => {
        const { OrderBookWS } = window;
        const availableChannels = OrderBookWS.getAvailableChannels();
        const firstPattern = `\\borderbook-simple-consolidated\\b.*\\b${data.trading_pair}\\b`;
        const secondPattern = `\\brfq-orderbook\\b.*\\b${data.trading_pair}\\b`;
        const firstRegex = new RegExp(firstPattern);
        const secondRegex = new RegExp(secondPattern);
        let channel = availableChannels.find((a) => {
            const m = a.match(firstRegex);
            return !!m;
        });
        if (!channel) {
            channel = availableChannels.find((a) => {
                const m = a.match(secondRegex);
                return !!m;
            });
        }
        if (prevChannel.current !== '') {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        }
        if (channel) {
            OrderBookWS.addSubscription(channel, { callback: handlePriceUpdate, componentId: refId.current });
            prevChannel.current = channel;
        }
        return () => {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        };
    }, [data]);
    const handlePriceUpdate = (stream) => {
        const ask = +stream.payload.asks[0].price;
        const bid = +stream.payload.bids[0].price;
        const currentPrice = (ask + bid) / 2;
        const unrealizedTotal = (+data.open_exposure * currentPrice - +data.average_exposure_value).toFixed(2);
        const positionValue = (+data.open_exposure * currentPrice).toFixed(2);
        streamUpdate({ pair: data.trading_pair, unrealizedValue: unrealizedTotal, positionValue });
        setRowValues({
            positionValue,
            unrealizedTotal,
            realtimePrice: currentPrice.toFixed(2),
        });
    };
    return (React.createElement(TableRow, null,
        React.createElement(TableCell, null, data.trading_pair.toUpperCase()),
        React.createElement(TableCell, null, rowValues === null || rowValues === void 0 ? void 0 : rowValues.realtimePrice),
        React.createElement(TableCell, null, Number(data.open_exposure).toFixed(5)),
        React.createElement(TableCell, null, Number(data.average_price).toFixed(2)),
        React.createElement(TableCell, null, Number(data.average_exposure_value).toFixed(2)),
        React.createElement(TableCell, null, rowValues === null || rowValues === void 0 ? void 0 : rowValues.positionValue),
        React.createElement(TableCell, null, Number(data.realized_pnl).toFixed(2)),
        React.createElement(TableCell, null, rowValues === null || rowValues === void 0 ? void 0 : rowValues.unrealizedTotal)));
};
export default PnLTableRow;
