import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { ORDERS_CANCEL_ALL_REQUEST, ORDERS_TAB_SELECTED_SET_UPDATE, } from '../../../../../redux/actionTypes/orderBookActionTypes';
import OrderBooks from '../OrderBooks/index';
import SubOrderbook from '../SubOrderbook/index';
import PartialOrderBooks from '../PartialOrderBooks/index';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import CancelOrdersModal from '../OrderBooks/components/CancelOrdersModal';
import CancelSeveralModal from '../OrderBooks/components/CancelSeveralModal';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getWidgetsSettingsByKey } from '../../../../../redux/selectors/widgetSettingsSelector';
import { Box, Button } from '@mui/material';
import AppTabs from '../../../../../shared/components/tabs';
const initPayload = {
    base: [],
    quote: [],
    action_type: '',
    exchange_code: [],
    customer_code: '',
    partial_order_code: [],
};
const MergedOrderList = ({ settings, widgetKey, clientInfo, features, regularPage, subPage, splitPage, setRegularPage, setSubPage, setSplitPage, }) => {
    const [cancellAllModal, setCancellAllModal] = useState(false);
    const [partialCancelModal, setPartialCancelModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const actionButton = (text, callback) => {
        return (React.createElement(Button, { variant: 'outlined', onClick: callback }, text));
    };
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            dispatch({ type: ORDERS_TAB_SELECTED_SET_UPDATE, ordersTabSelected: tab });
        }
    };
    const toggleModal = () => {
        setCancellAllModal(!cancellAllModal);
    };
    const togglePartialCancelModal = () => {
        setPartialCancelModal(!partialCancelModal);
    };
    const cancelAllOrders = (payload = initPayload) => {
        const dispatchCloseNotice = () => dispatch({ type: CLOSE_ERROR_NOTICE });
        dispatch({
            payload,
            dispatchCloseNotice,
            type: ORDERS_CANCEL_ALL_REQUEST,
            clientCode: clientInfo ? clientInfo.code : '',
        });
    };
    useEffect(() => {
        return () => {
            dispatch({ type: ORDERS_TAB_SELECTED_SET_UPDATE, ordersTabSelected: 'regular' });
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', gap: 1 },
            React.createElement(AppTabs, { tabs: ['ORDERS', 'SPLIT ORDERS', 'SUBORDERS'], onTabChange: toggleTab }),
            React.createElement(Box, { style: { display: 'flex', justifyContent: 'flex-end' }, gap: 1, px: 1 },
                React.createElement(Permissions, { features: features, permissions: ['order_cancel_all.create'] }, actionButton('Cancel all', toggleModal)),
                React.createElement(Permissions, { features: features, permissions: ['order_cancel_bulk.create'] }, actionButton('Cancel several', togglePartialCancelModal)))),
        activeTab === 0 ? (React.createElement(OrderBooks, { settings: settings, widgetKey: widgetKey, regularPage: regularPage, setRegularPage: setRegularPage })) : null,
        activeTab === 1 ? (React.createElement(PartialOrderBooks, { settings: settings, splitPage: splitPage, widgetKey: widgetKey, setSplitPage: setSplitPage })) : null,
        activeTab === 2 ? (React.createElement(SubOrderbook, { subPage: subPage, settings: settings, widgetKey: widgetKey, setSubPage: setSubPage })) : null,
        cancellAllModal ? (React.createElement(CancelOrdersModal, { open: cancellAllModal, toggleFunc: toggleModal, cancelAllOrders: cancelAllOrders })) : null,
        partialCancelModal ? (React.createElement(CancelSeveralModal, { open: partialCancelModal, toggleFunc: togglePartialCancelModal, cancelSeveralOrders: cancelAllOrders })) : null));
};
const mapStateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    return {
        widgetKey,
        features: state.client.features,
        clientInfo: state.client.clientInfo,
        settings: getWidgetsSettingsByKey(state, widgetKey),
        ordersTabSelected: state.orderBook.ordersTabSelected,
    };
};
export default connect(mapStateToProps)(MergedOrderList);
