import axios from 'axios';
import { SET_SELECTED_CLIENT, } from '../actionTypes/clientSearchActionTypes';
export const selectClient = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
export const fetchUnusedInvites = (filterUrl) => axios
    .get(`/invites${filterUrl}`)
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
