import React, { useCallback, useEffect, useState, useRef, } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import { Button, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import OrdersService from '../../../../../../services/ordersService';
import Messages from '../../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const SignDialog = ({ toggle, open, item }) => {
    const [totp, setTotp] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [invalidFields, setInvalidFields] = useState([]);
    const dirty = useRef(false);
    const dispatch = useDispatch();
    const helperTextStyles = useHelperTextStyles();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onTotpChange = (evt) => {
        const { value } = evt.target;
        const val = value.trim();
        if (isNaN(Number(val))) {
            return;
        }
        setTotp(value);
    };
    const validateFields = (fieldsToValidate) => {
        const errors = [];
        for (const [key, value] of Object.entries(fieldsToValidate)) {
            if (`${value}`.trim() === '') {
                errors.push(key);
            }
        }
        return errors;
    };
    const handleUpdateOrder = () => {
        dirty.current = true;
        const errors = validateFields({ totp });
        if (errors.length) {
            setInvalidFields([...errors]);
            return;
        }
        setDisabled(true);
        const payload = {
            code: item.code,
            totp_code: totp,
        };
        const params = {
            url: '/trade/orders/sign',
            method: 'post',
            data: payload,
        };
        const service = new OrdersService(params);
        service.makeRequest()
            .then(() => {
            setDisabled(false);
            showNotification({
                message: 'Order successfully signed',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            setDisabled(false);
            const message = composeErrorMessage(e, Messages.ORDER_SIGN);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    useEffect(() => {
        if (dirty.current) {
            const errors = validateFields({ totp });
            setInvalidFields([...errors]);
        }
    }, [totp]);
    return (React.createElement(Dialog, { open: open, onClose: () => toggle(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Signing order"),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { fullWidth: true, size: "small", value: totp, label: "totp", margin: "normal", variant: "outlined", onChange: onTotpChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('totp') ? 'Field is required' : ''}` })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { size: "small", color: "primary", onClick: handleUpdateOrder, disabled: disabled || invalidFields.includes('totp'), className: "btn-cancel-order square permissions_close_button" }, "Sign"),
            React.createElement(Button, { size: "small", color: "inherit", onClick: toggle, className: "btn-cancel-order square permissions_close_button" }, "Close"))));
};
export default SignDialog;
