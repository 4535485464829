import axios from 'axios';
import { ApiPollService } from '../shared/helpers/apiPollService';
/* eslint-disable class-methods-use-this */
export default class ApiTokenService {
    constructor(config, delay) {
        this.model = {
            records: [],
            total: '0',
        };
        this.config = config;
        this.pollingService = new ApiPollService(delay || 10000);
    }
    getData() {
        return this.model;
    }
    handleResponse(data) {
        var _a, _b;
        this.model.records = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.records;
        this.model.total = (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.total;
        return this.model;
    }
    makeRequest() {
        const { ErrorLogger } = window;
        const axiosRequest = axios(this.config);
        return axiosRequest
            .then(response => this.handleResponse(response))
            .catch((error) => {
            if (error.message === 'canceled') {
                return this.handleReject(error);
            }
            ErrorLogger.setErrors(error, new Date());
            return this.handleReject(error);
        });
    }
    makePollRequest(callback, delay) {
        if (delay) {
            this.pollingService.delay = delay;
        }
        if (this.config.url) {
            const callbackPayload = {
                url: this.config.url,
                action: callback,
            };
            this.pollingService.addCallback(callbackPayload);
        }
    }
    clearPollRequest(url) {
        this.pollingService.removeCallBack(url);
    }
    handleReject(e) {
        throw e;
    }
}
