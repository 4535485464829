import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Chip, Container, IconButton, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import EditIcon from '@mui/icons-material/Edit';
import { CLIENT_UPDATE, SET_SELECTED_CLIENT, } from '../../../../../redux/actionTypes/clientSearchActionTypes';
import CustomersTable from './Customers/CustomersTable';
import ClientInfoComponent from './ClientInfoComponent';
import ClientModal from '../../../Crypto/components/ClientSearch/ClientModal';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import showNotification from '../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import Messages from '../../../../../shared/helpers/errorMessages';
const adminClientUpdate = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
const clientUpdate = (client) => ({
    type: CLIENT_UPDATE,
    client,
});
const ClientDetailsContainer = (props) => {
    const [client, setClient] = useState();
    const [editClient, setEditClient] = useState(false);
    const { selectedClient, dispatchClientSelect, dispatchClientUpdate } = props;
    const history = useHistory();
    const isMountRef = useRef(true);
    const params = useParams();
    const getClient = (id) => {
        return axios
            .get(`user/clients/${id}`, { baseURL: getEnvSettings().adminApiUrl })
            .then((res) => {
            if (res) {
                setClient(res.data);
            }
        });
    };
    useEffect(() => {
        dispatchClientSelect(params.id);
        getClient(params.id).then(() => {
            isMountRef.current = false;
        });
    }, []);
    useEffect(() => {
        if (!isMountRef.current && selectedClient) {
            history.push(`./${selectedClient}`);
        }
    }, [selectedClient]);
    useEffect(() => {
        if (!isMountRef.current) {
            dispatchClientSelect(params.id);
            getClient(params.id);
        }
    }, [params.id]);
    const handleNavigate = () => {
        history.goBack();
    };
    const handleUpdateClient = (payload) => {
        if (!client) {
            return;
        }
        axios
            .patch(`user/clients/${client === null || client === void 0 ? void 0 : client.code}`, payload, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .then((response) => {
            showNotification({
                color: 'success',
                message: 'Client successfully updated',
            });
            setEditClient(false);
            if (response) {
                dispatchClientUpdate(response.data);
                setClient(response.data);
            }
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CLIENT_UPDATE);
            showNotification({
                color: 'error',
                message,
            });
        });
    };
    return (React.createElement(Container, { maxWidth: 'lg' },
        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' }, gap: 2 },
            React.createElement(Box, { py: 2, gap: 2, sx: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 1 },
                    React.createElement(IconButton, { size: 'small', onClick: () => handleNavigate() },
                        React.createElement(ArrowBackIosNewIcon, null)),
                    React.createElement(AccountCircleIcon, { fontSize: 'large', color: 'secondary' }),
                    React.createElement(Typography, { variant: 'h6' }, client === null || client === void 0 ? void 0 : client.company_name),
                    React.createElement(IconButton, { size: 'small', onClick: () => setEditClient(!editClient) },
                        React.createElement(EditIcon, { fontSize: 'small' })),
                    client && (React.createElement(ClientModal, { user: 'client', currentUser: client, open: editClient, toggleFunc: () => {
                            setEditClient(false);
                        }, updateClient: handleUpdateClient, createClient: () => { } }))),
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 1 },
                    React.createElement(Chip, { label: client === null || client === void 0 ? void 0 : client.code, color: 'secondary' }),
                    React.createElement(CopyValue, { value: (client === null || client === void 0 ? void 0 : client.code) || '' }))),
            React.createElement(ClientInfoComponent, { details: client }),
            React.createElement(CustomersTable, null))));
};
const mapDispatchToProps = (dispatch) => ({
    dispatchClientSelect: (client) => dispatch(adminClientUpdate(client)),
    dispatchClientUpdate: (client) => dispatch(clientUpdate(client)),
});
const stateToProps = (state) => {
    const { selectedClient } = state.clientSearch;
    const customers = state.customersSearch.customers;
    return {
        selectedClient,
        customers,
    };
};
export default connect(stateToProps, mapDispatchToProps)(ClientDetailsContainer);
