import React from 'react';
import { Box, List } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { PanelContainer, MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../../Custody/styling/style';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import AddressTableCell from '../../../../Custody/components/Whitelist/AddressTableCell';
import AddressesCellActions from '../../../../Custody/components/Whitelist/AddressesCellActions';
const Addresses = ({ pageAddresses, onChangePageA, meCode, columns, addressesToRender, cellActions, features, admin, maxHeight, rowsPerPage, sortBy, sortDirection, onSortHandle, columnsOpen, loading, clientCode, clientInfo, handleColumnsClick, handleColumnsClose, anchorColumns, columnsToRender, hideShowColumn, totalAddresses, }) => {
    const colsA = columns.filter(c => c.id.includes('addressesApproveColumns'));
    return (React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" }, loading ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
        React.createElement(CircularProgress, { size: "33px" }))) : (React.createElement(React.Fragment, null,
        React.createElement(WalletsClientContainer, null,
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, colsA.map(col => (React.createElement(ListItem, { key: col.id },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.id), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        React.createElement(MaterialTableWrap, null,
            React.createElement(Paper, { className: "mui_table_root" },
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'address' ? 'left' : 'right' },
                                    React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)))),
                                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                        React.createElement(TableBody, null, addressesToRender.map(address => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: address.code },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? address[key] : undefined;
                                return (React.createElement(AddressTableCell, { key: i, value: value, columnKey: key }));
                            }),
                            React.createElement(AddressesCellActions, { admin: admin, item: address, meCode: meCode, features: features, actions: cellActions, clientCode: clientCode, clientInfo: clientInfo }))))))),
                React.createElement(TablePagination, { component: "div", page: pageAddresses, count: totalAddresses, rowsPerPage: rowsPerPage, onPageChange: onChangePageA, rowsPerPageOptions: [maxHeight] })))))));
};
export default Addresses;
