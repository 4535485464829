import { TableBody, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DLTTableRowComponent from './DLTTableRowComponent';
const DLTTableBodyComponent = (props) => {
    const { bulkSelect = false, columns, onCellEdit, onRowClick } = props;
    const [rows, setRows] = useState([]);
    useEffect(() => {
        setRows(props.rows);
    }, [props.rows]);
    const handleRowClick = (id) => {
        if (onRowClick) {
            onRowClick(id);
        }
    };
    const handleCellEdit = (id, entity, value) => {
        if (onCellEdit) {
            onCellEdit(id, entity, value);
        }
    };
    return (React.createElement(TableBody, null, rows === null || rows === void 0 ? void 0 : rows.map((row) => {
        return (React.createElement(DLTTableRowComponent, { bulkSelect: bulkSelect, key: row.id, row: row, columns: columns, onCellEdit: handleCellEdit, onRowClick: handleRowClick }));
    })));
};
export default DLTTableBodyComponent;
