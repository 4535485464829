import React, { useMemo, useEffect } from 'react';
import { ResponsiveContainer } from 'recharts';
import { connect, useDispatch } from 'react-redux';
import ExchangesBalancesComponent from './components/ExchangesBalancesComponent';
import { getExchangesBalances } from '../../../../../redux/selectors/clientSelectors';
const memBalances = (balances) => balances;
const memoOpenItems = (opentItems) => opentItems;
const ExchangesBalances = ({ openItems, exchangesBalances }) => {
    const balances = useMemo(() => memBalances(exchangesBalances), [exchangesBalances]);
    const items = useMemo(() => memoOpenItems(openItems), [openItems]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: 'START_EXCHANGES_BALANCES_POLLING' });
        return () => {
            dispatch({ type: 'STOP_EXCHANGES_BALANCES_POLLING' });
        };
    }, []);
    return (React.createElement(ResponsiveContainer, { height: 300, className: "dashboard__area" },
        React.createElement(ExchangesBalancesComponent, { exchangesBalancesArray: balances, openItems: items })));
};
const mapStateToProps = (state) => {
    const { openItems, exchangesBalancesLoading, } = state.client;
    let exchangesBalances = [];
    if (!exchangesBalancesLoading) {
        exchangesBalances = getExchangesBalances(state);
    }
    return {
        openItems,
        exchangesBalances,
    };
};
export default connect(mapStateToProps)(ExchangesBalances);
