import React from 'react';
import Decimal from 'decimal.js';
const CmlBidSizePrice = ({ price }) => (React.createElement("div", { className: "container_trade_color_green" }, price === ''
    ? React.createElement("span", null, "--")
    : React.createElement("span", null, price)));
const CmlBidSize = ({ orderBookItem }) => {
    /* eslint-disable camelcase */
    if (orderBookItem && orderBookItem.bid && Object.keys(orderBookItem.bid).length) {
        const { volume, volume_split } = orderBookItem.bid;
        const splitVolumeArr = volume_split ? Object.values(volume_split) : [];
        const sumVolume = splitVolumeArr.reduce((acm, item) => Decimal.add(acm, item).toFixed(), '0');
        const price = new Decimal(volume_split ? sumVolume : volume)
            .toFixed(4)
            .replace(/\.?0+$/, '');
        return React.createElement(CmlBidSizePrice, { price: price });
    }
    return (React.createElement("div", { className: "container_trade_color_green" },
        React.createElement("span", null, "--")));
};
export default React.memo(CmlBidSize);
