import React, { useState, useEffect, useCallback } from 'react';
import { Box, Chip, Button, Collapse, TextField, } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '@mui/lab';
import axios from 'axios';
import { createStyles, makeStyles } from '@mui/styles';
import { FiltersBox, FiltersBody, MoreFilters, FiltersReset, SelectedFilters, FiltersContainer, FiltersSelectBox, CustodyFilterPanelContainer, } from '../../../Custody/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import StrategiesService from '../../../../../services/strategiesService';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
const useStyles = makeStyles(() => createStyles({
    formGroup: {
        minWidth: 200,
    },
}));
const FilterButton = ({ item, onChange }) => {
    if (item.value === '')
        return null;
    return React.createElement(Chip, { size: "small", color: "primary", onDelete: onChange, label: item.label });
};
const statusOptions = [
    {
        value: 'wait_approval',
        type: 'select',
        name: 'status',
        label: 'wait approval',
    }, {
        value: 'ready',
        type: 'select',
        name: 'status',
        label: 'ready',
    },
];
const StrategiesFiltersContent = ({ state, resetFilters, removeSelectedFilter, handleStateChange, handleStrategyChange, }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [collapse, setCollapse] = useState(false);
    const [code, setCode] = useState(null);
    const [status, setStatus] = useState(null);
    const [strategiesOptions, setStrategiesOptions] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const toggleCollapseFilter = () => {
        setCollapse(!collapse);
    };
    const fetchStrategies = (cancelToken) => {
        setLoading(true);
        const service = new StrategiesService({
            url: '/defi/strategies',
            method: 'get',
            cancelToken,
        });
        service.makeRequest()
            .then((responseData) => {
            setLoading(false);
            const { strategies } = responseData;
            if (strategies.length) {
                let mappedStrategies = [];
                mappedStrategies = strategies
                    .map((s) => {
                    const item = {
                        value: s.code,
                        type: 'select',
                        name: 'code',
                        label: s.name,
                    };
                    return item;
                });
                setStrategiesOptions(mappedStrategies);
            }
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        const objIndexCode = state.selectedFilters.findIndex((obj => obj.name === 'code'));
        const codeValue = state.selectedFilters[objIndexCode];
        setCode(codeValue || null);
        const objIndexStatus = state.selectedFilters.findIndex((obj => obj.name === 'status'));
        const statusValue = state.selectedFilters[objIndexStatus];
        setStatus(statusValue || null);
    }, [state]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        fetchStrategies(cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(CustodyFilterPanelContainer, null,
            React.createElement(FiltersContainer, null,
                React.createElement(MoreFilters, null,
                    React.createElement(Button, { size: "small", color: "primary", variant: "outlined", onClick: toggleCollapseFilter },
                        collapse ? '-' : '+',
                        " Quick filters")),
                state.selectedFilters.length ? (React.createElement(FiltersReset, null,
                    React.createElement(Button, { size: "small", color: "inherit", variant: "outlined", onClick: () => resetFilters() }, "Reset filters"))) : null,
                React.createElement(SelectedFilters, null,
                    React.createElement("div", null,
                        React.createElement(FiltersBox, null, state.selectedFilters.map((filter) => (React.createElement(FilterButton, { item: filter, key: filter.name, onChange: () => {
                                if (filter.name === 'code') {
                                    setCode(null);
                                }
                                if (filter.name === 'status') {
                                    setStatus(null);
                                }
                                removeSelectedFilter(filter);
                            } }))))))),
            React.createElement(Collapse, { in: collapse },
                React.createElement(FiltersBody, null,
                    React.createElement("div", null,
                        React.createElement(FiltersSelectBox, { className: "custody_filte_box", style: { zIndex: 9923 } },
                            React.createElement(Box, { style: { display: 'flex' } },
                                React.createElement(Box, { className: classes.formGroup, mr: 1 },
                                    React.createElement(Autocomplete, { loading: loading, options: statusOptions, getOptionLabel: (option) => option.label, onChange: (e, newValue, reason, details) => handleStateChange(newValue, reason, details), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { size: "small", name: "status", label: "Status", value: status, variant: "outlined" })) })),
                                React.createElement(Box, { className: classes.formGroup, mr: 1 },
                                    React.createElement(Autocomplete, { loading: false, options: strategiesOptions, getOptionLabel: (option) => option.label, onChange: (e, newValue, reason, details) => handleStrategyChange(newValue, reason, details), renderInput: (params) => React.createElement(TextField, Object.assign({ name: "code" }, params, { size: "small", value: code, variant: "outlined", label: "Strategy code" })) }))))))))));
};
export default StrategiesFiltersContent;
