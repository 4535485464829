/* eslint-disable no-unused-vars, @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect, useRef, useCallback, } from 'react';
import { useDispatch } from 'react-redux';
import TotpForm from './TotpForm';
import LogInForm from './LogInForm';
import showNotification from '../../helpers/notifications';
import LoginService from '../../../services/loginService';
import { getEnvSettings } from '../../../config/environmentSettings';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { Box, Container, Paper, Typography } from '@mui/material';
const loginFields = {
    login: '',
    password: '',
};
const otpFields = {
    totp_code: '',
};
const finoaEnv = getEnvSettings().env === 'finoa';
const validateFields = (fieldsToValidate) => {
    const errors = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fieldsToValidate)) {
        // validate value not empty
        if (value.trim() === '') {
            errors.push({ name: key, message: 'Field is required' });
        }
        else if (key === 'login') {
            // validate email
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(value).toLowerCase())) {
                errors.push({ name: key, message: 'Must be valid email address' });
            }
        }
        else if (key === 'totp_code' && String(value).length !== 6) {
            errors.push({ name: key, message: '2fa code should be at least 6 chars long' });
        }
    }
    return errors;
};
const LoginCard = () => {
    const { ErrorLogger } = window;
    const dirty = useRef(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loginFormFields, setLoginFormFields] = useState(null);
    const [otpFormFields, setOtpFormFields] = useState(null);
    const [service, setService] = useState(null);
    const [validation, setValidation] = useState([]);
    const [isLoginSuccess, setIsLoginSuccess] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    useEffect(() => {
        const loginService = new LoginService();
        setService(loginService);
    }, []);
    useEffect(() => {
        if (dirty.current && loginFields) {
            setValidation(() => [...validateFields(loginFields)]);
        }
    }, [loginFormFields]);
    useEffect(() => {
        if (dirty.current && otpFields) {
            setValidation(() => [...validateFields(otpFields)]);
        }
    }, [otpFormFields]);
    const loginFieldChange = (e) => {
        const fieldName = e.target.name;
        loginFields[fieldName] = e.target.value;
        setLoginFormFields(Object.assign(Object.assign({}, loginFormFields), loginFields));
    };
    const otpFieldChange = (e) => {
        if (isNaN(Number(e.target.value)) || e.target.value.length > 6) {
            return;
        }
        const fieldName = e.target.name;
        otpFields[fieldName] = e.target.value;
        setOtpFormFields(Object.assign(Object.assign({}, otpFormFields), otpFields));
    };
    const sendOtpCode = (e) => {
        e.preventDefault();
        let errors = [];
        if (loginFormFields) {
            errors = validateFields(loginFormFields);
        }
        if (errors.length) {
            setValidation(() => [...errors]);
            return;
        }
        setLoadingSubmit(true);
        if (service && otpFormFields) {
            service.sendTotp(otpFormFields).then(() => {
                setLoadingSubmit(false);
                window.location.href = '/';
            }).catch((error) => {
                ErrorLogger.setErrors(error, new Date());
                setLoadingSubmit(false);
                if (error.response && error.response.data && error.response.status) {
                    const { status } = error.response;
                    const { code } = error.response.data;
                    switch (status) {
                        case 422:
                            switch (code) {
                                case 604:
                                    setValidation([{ name: 'totp_code', message: '2fa code should be at least 6 chars long' }]);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case 400:
                            switch (code) {
                                case 9199:
                                    setValidation([{ name: 'totp_code', message: 'incorrect 2fa code' }]);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case 401:
                            switch (code) {
                                case 7101:
                                    setIsLoginSuccess(false);
                                    showNotification({
                                        message: `Error: ${'session expired, try new login'}`,
                                        color: 'error',
                                        dispatch: errorNotice,
                                    });
                                    otpFields.totp_code = '';
                                    break;
                                default:
                                    break;
                            }
                            break;
                        case 403:
                            switch (code) {
                                case 9000:
                                    setIsLoginSuccess(false);
                                    showNotification({
                                        message: `Error: ${'session expired, try new login'}`,
                                        color: 'error',
                                        dispatch: errorNotice,
                                    });
                                    otpFields.totp_code = '';
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
            });
        }
    };
    const login = (e) => {
        e.preventDefault();
        if (!dirty.current) {
            dirty.current = true;
        }
        let errors = [];
        if (loginFormFields) {
            errors = validateFields(loginFormFields);
        }
        if (errors.length) {
            // add errors
            setValidation(() => [...errors]);
            return;
        }
        setLoadingSubmit(true);
        // ready to submit
        if (service && loginFormFields) {
            service.login(loginFormFields, errorNotice)
                .then(() => {
                setLoadingSubmit(false);
                setIsLoginSuccess(true);
            })
                .catch((error) => {
                ErrorLogger.setErrors(error, new Date());
                setLoadingSubmit(false);
                if (error.response && error.response.data && error.response.status) {
                    const { status } = error.response;
                    const { code } = error.response.data;
                    switch (status) {
                        case 401:
                            switch (code) {
                                case 7000:
                                    setValidation([
                                        { name: 'password', message: 'incorrect login or password' },
                                        { name: 'login', message: 'incorrect login or password' },
                                    ]);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
            });
        }
    };
    return React.createElement(React.Fragment, null, !isLoginSuccess ? (React.createElement(Container, { maxWidth: "xs" },
        React.createElement(Paper, null,
            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2, p: 4 },
                React.createElement(Typography, { variant: 'h5', align: 'center' }, "Welcome to DLT Finance"),
                React.createElement(LogInForm, { fields: loginFields, validation: validation, loading: loadingSubmit, handleSubmit: login, onFieldChange: loginFieldChange }))))) : (React.createElement(Container, { maxWidth: "xs" },
        React.createElement(Paper, null,
            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2, p: 4 },
                React.createElement(Typography, { variant: 'h5', align: 'center' }, "Welcome to DLT Finance"),
                React.createElement(TotpForm, { fields: otpFields, validation: validation, loading: loadingSubmit, handleSubmit: sendOtpCode, onFieldChange: otpFieldChange }))))));
};
export default LoginCard;
