import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Box, Icon, Button } from '@mui/material';
const ErrorsForm = ({ logs, handleFunction, toggleFunc }) => {
    const [icon, setIcon] = useState('file_copy');
    const handleCopyLogs = (id) => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(id);
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(Box, null, "Are you sure about sending error logs to the development team?"),
        React.createElement("span", null,
            "Copy all logs --",
            '>  ',
            React.createElement(Icon, { onClick: () => handleCopyLogs(JSON.stringify(logs)), fontSize: "small" }, icon)),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(Button, { size: "small", color: "success", variant: "contained", onClick: () => handleFunction(logs) }, "Copy"),
            React.createElement(Button, { size: "small", variant: "outlined", onClick: () => toggleFunc() }, "Close"))));
};
export default ErrorsForm;
