import React, { useState, useEffect } from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Bid from './Bid';
import Ask from './Ask';
import AskSize from './AskSize';
import BidSize from './BidSize';
import LastTrade from './LastTrade';
import CmlBidSize from './CmlBidSize';
import CmlAskSize from './CmlAskSize';
import VolumeTotal from './VolumeTotal';
import ValueCmlBidSize from './ValueCmlBidSize';
import ValueCmlAskSize from './ValueCmlAskSize';
import * as Styled from '../styling/styledComponents';
import { LiveIndicator } from '../styling/styledComponents';
// eslint-disable-next-line no-unused-vars
const getExchangeOption = (exchanges, code) => {
    const option = exchanges.find(exchange => exchange.value === code);
    if (option) {
        return option.label;
    }
    return code;
};
const RowSingleExchange = ({ marketItem, orderBookSingle, lastTradesSingle, pair, exchangesOptions, }) => {
    const [exchangeName, setExchangeName] = useState('');
    const [tradeChannel, setTradeChannel] = useState('');
    const [orderBookChannel, setOrderBookChannel] = useState('');
    const [lastTrade, setLastTrade] = useState(null);
    // const tradeChannel = `trade-${marketItem.exchange_code}-${pair.toLowerCase()}`;
    // const orderBookChannel = `orderbook-simple-${marketItem.exchange_code}-${pair.toLowerCase()}`;
    // const exchangeName = getExchangeOption(exchangesOptions, marketItem.exchange_code);
    // const lastTrade = lastTradesSingle ? lastTradesSingle[tradeChannel] : null;
    useEffect(() => {
        if (marketItem.exchange_code && pair) {
            setTradeChannel(`trade-${marketItem.exchange_code}-${pair.toLowerCase()}`);
            setOrderBookChannel(`orderbook-simple-${marketItem.exchange_code}-${pair.toLowerCase()}`);
        }
    }, [marketItem.exchange_code, pair]);
    useEffect(() => {
        if (marketItem.exchange_code && exchangesOptions.length) {
            setExchangeName(getExchangeOption(exchangesOptions, marketItem.exchange_code));
        }
    }, [exchangesOptions, marketItem.exchange_code]);
    useEffect(() => {
        if (lastTradesSingle && tradeChannel) {
            setLastTrade(lastTradesSingle[tradeChannel]);
        }
    }, [lastTradesSingle, tradeChannel]);
    if (!orderBookSingle) {
        return null;
    }
    return (React.createElement(TableRow, { className: "market_overview_body_weigth" },
        React.createElement(TableCell, null,
            React.createElement(LiveIndicator, null),
            React.createElement(Styled.TextUppercase, null, exchangeName)),
        React.createElement(TableCell, null,
            React.createElement(LastTrade, { lastTrade: lastTrade })),
        React.createElement(TableCell, null,
            React.createElement(VolumeTotal, { orderBookItem: orderBookSingle[orderBookChannel] })),
        React.createElement(TableCell, null,
            React.createElement(ValueCmlBidSize, { orderBookItem: orderBookSingle[orderBookChannel] })),
        React.createElement(TableCell, null,
            React.createElement(CmlBidSize, { orderBookItem: orderBookSingle[orderBookChannel] })),
        React.createElement(TableCell, null,
            React.createElement(BidSize, { orderBookItem: orderBookSingle[orderBookChannel] })),
        React.createElement(TableCell, null,
            React.createElement(Bid, { orderBookItem: orderBookSingle[orderBookChannel] })),
        React.createElement(TableCell, null,
            React.createElement(Ask, { orderBookItem: orderBookSingle[orderBookChannel] })),
        React.createElement(TableCell, null,
            React.createElement(AskSize, { orderBookItem: orderBookSingle[orderBookChannel] })),
        React.createElement(TableCell, null,
            React.createElement(CmlAskSize, { orderBookItem: orderBookSingle[orderBookChannel] })),
        React.createElement(TableCell, null,
            React.createElement(ValueCmlAskSize, { orderBookItem: orderBookSingle[orderBookChannel] }))));
};
export default React.memo(RowSingleExchange);
