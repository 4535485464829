import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { connect } from 'react-redux';
import HeadDetails from './HeadDetails';
const PlaceOrderHead = ({ selectedCustomer, clientInfo }) => {
    const [customerInfo, setUpdateCustomer] = useState(null);
    useEffect(() => {
        setUpdateCustomer(selectedCustomer);
    }, [selectedCustomer]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null, !customerInfo
            ? (React.createElement(Alert, { severity: "warning", variant: "outlined" }, "Select customer to make orders"))
            : React.createElement(HeadDetails, { clientInfo: clientInfo, selectedCustomer: customerInfo }))));
};
const stateToProps = (state) => {
    const { selectedCustomer } = state.customersSearch;
    const { clientInfo } = state.client;
    return {
        clientInfo,
        selectedCustomer,
    };
};
export default connect(stateToProps)(PlaceOrderHead);
