/* eslint-disable camelcase, @typescript-eslint/no-unsafe-return, no-nested-ternary, no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import { PanelTitle, PanelContainer, } from './styling/style';
import { ADMIN_ORDERS_FETCH_REQUEST, ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE, } from '../../../redux/actionTypes/adminActionTypes';
import ManageFilters from './components/Manage/ManageFilters';
import Permissions from '../../Layout/AdminSidebar/Permissions';
import ClientsOrdersList from './components/Clients/Orders/main';
import { getWidgetColumns } from '../../../redux/selectors/custodySelectors';
import CancelOrdersDialog from './components/Clients/Orders/CancelOrdersDialog';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { CLIENT_INFO_FETCH_REQUEST } from '../../../redux/actionTypes/clientActionTypes';
import { ADMIN_ORDERS_CANCEL_ALL_REQUEST } from '../../../redux/actionTypes/orderBookActionTypes';
import { getSelectedClientInputValue } from '../../../redux/selectors/clientSearchSelectors';
// to do: move to actions/actions types
const pageOfItemsUpdate = (pageOfItemsAdminOrders) => ({
    type: ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsAdminOrders,
});
export const ordersFetch = (urlOptionsAdminOrders) => ({
    type: ADMIN_ORDERS_FETCH_REQUEST,
    urlOptionsAdminOrders,
});
const MainContainer = ({ columns, features, widgetKey, clientInfo, selectedClient, masterTenant, dispatchCancelAll, ordersFetchDispatch, dispatchPageOfItems, dispatchCloseNotice, urlOptionsAdminOrders, pageOfItemsAdminOrders, }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [client, setClient] = useState(null);
    const [activeClient, setActiveClient] = useState(null);
    const sortDirection = 'desc';
    const sortBy = 'created';
    const handleSelect = (clientOption) => {
        setClient(clientOption);
        dispatchPageOfItems(0);
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleCancelAllOrders = () => {
        dispatchCancelAll(dispatchCloseNotice);
    };
    useEffect(() => {
        if (client) {
            setActiveClient(client);
        }
        else if (selectedClient) {
            setActiveClient(selectedClient);
        }
        else {
            setActiveClient(null);
        }
    }, [selectedClient, client]);
    useEffect(() => {
        if (urlOptionsAdminOrders) {
            ordersFetchDispatch(urlOptionsAdminOrders);
        }
    }, [urlOptionsAdminOrders, pageOfItemsAdminOrders, selectedClient]);
    if (!clientInfo) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement("div", { className: "content-body", role: "main" },
        React.createElement(Permissions, { features: features, permissions: ['order.read'] },
            React.createElement(PanelContainer, null,
                React.createElement(PanelTitle, null,
                    React.createElement(ManageFilters, { sortBy: sortBy, features: features, clientData: activeClient, rowsPerPage: rowsPerPage, toggleModal: toggleModal, sortDirection: sortDirection })),
                React.createElement("div", { role: "table", className: "card-body" },
                    React.createElement(ClientsOrdersList, { columns: columns, admin: masterTenant, widgetKey: widgetKey, rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage, dispatchPageOfItems: dispatchPageOfItems })),
                modalOpen ? (React.createElement(CancelOrdersDialog, { open: modalOpen, toggleFunc: toggleModal, cancelAllOrders: handleCancelAllOrders })) : null))));
};
const mapStateToProps = (state, ownProps) => ({
    widgetKey: ownProps.widgetKey,
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    urlOptionsAdminOrders: state.admin.urlOptionsAdminOrders,
    pageOfItemsAdminOrders: state.admin.pageOfItemsAdminOrders,
});
const mapDispatchToProps = dispatch => ({
    ordersFetchDispatch: (url) => dispatch(ordersFetch(url)),
    dispatchCloseNotice: () => dispatch({ type: CLOSE_ERROR_NOTICE }),
    dispatchPageOfItems: (page) => dispatch(pageOfItemsUpdate(page)),
    dispatchLoadClientInfo: () => dispatch({ type: CLIENT_INFO_FETCH_REQUEST }),
    dispatchCancelAll: dispatchCloseNotice => dispatch({ type: ADMIN_ORDERS_CANCEL_ALL_REQUEST, dispatchCloseNotice }),
});
export default connect(mapStateToProps, mapDispatchToProps)(MainContainer);
