/* eslint-disable camelcase, class-methods-use-this, @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getEnvSettings } from '../config/environmentSettings';
export default class LogoutService {
    logout() {
        const apipath = `${String(getEnvSettings().frontendApiUrl)}/logout`;
        return axios.post(apipath)
            .then()
            .finally(() => {
            window.sessionStorage.clear();
            window.location.href = '/login';
            window.localStorage.removeItem('wsToken');
            window.localStorage.removeItem('csrfToken');
            window.localStorage.removeItem('selectedClient');
        });
    }
}
