/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, } from 'react-redux';
import { List, Paper, Popover, ListItem, IconButton, ListItemText, ListItemSecondaryAction, } from '@mui/material';
import Alert from '@mui/material/Alert';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import ExchangesItem from './ExchangesItem';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import { BodyContainer, PanelContainer } from '../../../Admin/styling/style';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { MaterialPopoverWrap, MaterialTableWrap, WalletsClientContainer } from '../../../Custody/styling/style';
import { FETCH_EXCHANGES_REQUEST, FETCH_MARKETS_REQUEST } from '../../../../../redux/actionTypes/placeOrderActionTypes';
const Exchanges = ({ columns, markets, features, exchanges, dispatchLoadMarkets, dispatchLoadExchanges, }) => {
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageSchedulers'));
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            dispatchLoadMarkets();
            dispatchLoadExchanges();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    if (exchanges.length === 0) {
        return (React.createElement(Alert, { severity: "info", variant: "outlined" }, "No items found"));
    }
    return (React.createElement("div", { className: "content-body" },
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement("div", null,
                React.createElement(BodyContainer, null,
                    React.createElement(WalletsClientContainer, null,
                        React.createElement("div", null,
                            React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                                React.createElement(ViewColumnIcon, null)),
                            React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                                React.createElement(MaterialPopoverWrap, null,
                                    React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                        React.createElement(ListItemText, { primary: col.title }),
                                        React.createElement(ListItemSecondaryAction, null,
                                            React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
                    React.createElement(MaterialTableWrap, null,
                        React.createElement(Paper, { className: "mui_table_root" }, exchanges.map((item) => (React.createElement(ExchangesItem, { item: item, key: item.code, markets: markets, features: features, dispatchLoadExchanges: dispatchLoadExchanges }))))))))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    markets: state.placeOrder.markets,
    exchanges: state.placeOrder.exchanges,
    columns: getWidgetColumns(state, ownProps.widgetKey),
});
const dispatchToProps = dispatch => ({
    dispatchLoadMarkets: () => dispatch({ type: FETCH_MARKETS_REQUEST }),
    dispatchLoadExchanges: () => dispatch({ type: FETCH_EXCHANGES_REQUEST }),
});
export default connect(mapStateToProps, dispatchToProps)(Exchanges);
