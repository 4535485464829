/* eslint-disable no-nested-ternary, @typescript-eslint/restrict-template-expressions */
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';
import { useDispatch } from 'react-redux';
import { Box, Card } from '@mui/material';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import NoteIcon from '@mui/icons-material/Note';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Messages from '../../../../../../../shared/helpers/errorMessages';
import { MaterialPopoverWrap } from '../../../../../Custody/styling/style';
import { getEnvSettings } from '../../../../../../../config/environmentSettings';
import showNotification from '../../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../../shared/helpers/interceptors';
import TransactionsNotesService from '../../../../../../../services/transactionsNotesService';
import { CLOSE_ERROR_NOTICE } from '../../../../../../../redux/actionTypes/apiErrorsActionTypes';
const AddEditNote = ({ parentCallback, code, noteText = '' }) => {
    const [editNote, setEditNote] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const updateNoteToRender = () => {
        setLoading(true);
        const notesService = new TransactionsNotesService({
            url: '/user/notes',
            method: 'post',
            data: {
                entity_id: code,
                text: editNote,
            },
            baseURL: getEnvSettings().adminApiUrl,
        });
        notesService.makeRequest()
            .then((d) => {
            setLoading(false);
            showNotification({
                message: 'Transaction Note has been successfully updated',
                color: 'success',
                dispatch: errorNotice,
            });
            parentCallback(d.notes[0]);
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.TRANSACTION_NOTES_CREATE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const handleNoteChange = (event) => {
        setEditNote(event.target.value);
    };
    useEffect(() => {
        if (noteText) {
            setEditNote(noteText);
        }
    }, []);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(TextField, { rows: 4, multiline: true, fullWidth: true, autoFocus: true, value: editNote, variant: "outlined", onChange: handleNoteChange, id: "outlined-multiline-static", className: "transactions_add_new_text_area" })),
        React.createElement(Button, { onClick: updateNoteToRender }, "Done"));
};
const NoteCard = ({ note }) => {
    const currentUpload = shortid.generate();
    const [noteToRender, setNoteToRender] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const options = {
        weekday: 'short', year: 'numeric', month: 'long', day: 'numeric',
    };
    const noteDate = new Date(note.created_at);
    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMoreClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    useEffect(() => {
        setNoteToRender(note.text);
    }, []);
    return (React.createElement(Card, { className: "transactions_note_card_root" },
        React.createElement(CardHeader, { avatar: (React.createElement(Avatar, { "aria-label": "recipe", className: "transactions_card_avatar" }, note.creator.name.charAt(0))), action: (React.createElement("div", null,
                React.createElement(IconButton, { "aria-label": "more options", onClick: handleMoreClick, disabled: true, size: "large" },
                    React.createElement(MoreVertIcon, null)),
                React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleMoreClose, anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    }, transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions" },
                            React.createElement(ListItem, { button: true },
                                React.createElement(ListItemIcon, null,
                                    React.createElement(CloudUploadIcon, { fontSize: "small" })),
                                React.createElement(ListItemText, { className: "trading_clients_th_name", primary: "Upload file" }))))))), title: note.creator.name, subheader: noteDate.toLocaleDateString(undefined, options) }),
        React.createElement(CardContent, null,
            React.createElement(Typography, { variant: "body2", component: "p" }, noteToRender)),
        React.createElement(CardActions, { disableSpacing: true },
            React.createElement("input", { multiple: true, type: "file", accept: "image/*", id: currentUpload, className: "transactions_card_file_upload" }),
            React.createElement("label", { htmlFor: currentUpload },
                React.createElement(Button, { size: "small", component: "span", "aria-label": "upload picture", startIcon: React.createElement(CloudUploadIcon, null) }, "upload file")))));
};
const WalletNote = ({ entityCode }) => {
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [stateNotes, setStateNotes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [displayAddNote, setDisplayAddNote] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fetchNotes = (code) => {
        setLoading(true);
        const notesService = new TransactionsNotesService({
            url: `/notes?entity_id=${code || ''}`,
            method: 'get',
            baseURL: getEnvSettings().apiUrl,
        });
        notesService.makeRequest()
            .then((data) => {
            setLoading(false);
            setStateNotes(data.notes);
        })
            .catch((e) => {
            setLoading(false);
            showNotification({
                message: `Failed to load Notes ${e.message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const addNote = (addedNote) => {
        if (addedNote) {
            setStateNotes([...stateNotes, addedNote]);
            toggleAddNote();
        }
    };
    const toggleDialog = () => {
        toggleOpenDialog(prev => !prev);
    };
    const toggleAddNote = () => {
        setDisplayAddNote(!displayAddNote);
    };
    if (entityCode === undefined) {
        return null;
    }
    useEffect(() => {
        if (dialogOpen) {
            fetchNotes(entityCode);
        }
    }, [dialogOpen]);
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { onClick: toggleDialog, "aria-label": "Wallet note", className: "transactions_button_style", size: "large" },
            React.createElement(NoteIcon, { className: "transactions_wallet_note_fs" })),
        React.createElement(Dialog, { open: dialogOpen, onClose: toggleDialog, maxWidth: "md", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Notes modal"),
            React.createElement(DialogContent, { className: "transactions_card_dialog_content" },
                loading
                    ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
                        React.createElement(CircularProgress, { size: "33px" })))
                    : stateNotes.length
                        ? stateNotes.map(n => (React.createElement(NoteCard, { note: n })))
                        : (React.createElement(Paper, { hidden: displayAddNote, className: "transactions_card_alert_box", square: true },
                            React.createElement(Typography, { variant: "caption", component: "p" }, "Click Add New Note button to add note to this record"))),
                displayAddNote && (React.createElement(AddEditNote, { parentCallback: addNote, code: entityCode }))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { startIcon: React.createElement(AddIcon, null), onClick: toggleAddNote, "aria-label": "add new note", className: "transactions_add_new_note_button" }, "add new note"),
                React.createElement(Button, { onClick: toggleDialog }, "Close")))));
};
export default WalletNote;
