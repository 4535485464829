import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import Wallet from './Wallet';
import { getCustodyWallets } from '../../../../../redux/selectors/custodySelectors';
const Wallets = ({ wallets }) => {
    const { path } = useRouteMatch();
    return (React.createElement("div", null,
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: path },
                React.createElement("h3", null, "Related wallet content")),
            React.createElement(Route, { path: `${path}/:walletId` },
                React.createElement(Wallet, { wallets: wallets })))));
};
const stateToPropsWallets = (state) => ({
    wallets: getCustodyWallets(state),
});
export default connect(stateToPropsWallets)(Wallets);
