import React, { useState, useEffect } from 'react';
import { Box, Chip, Button, Collapse, TextField, } from '@mui/material';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles } from '@mui/styles';
import { Autocomplete } from '@mui/lab';
import { FiltersBox, FiltersBody, MoreFilters, FiltersReset, SelectedFilters, FiltersContainer, CreateWithdrawal, CustodyFilterPanelContainer, } from '../../styling/style';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
const useStyles = makeStyles(() => createStyles({
    formGroup: {
        minWidth: 200,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
}));
const defaultStates = [
    { label: 'created', value: 'created' },
    { label: 'pending', value: 'pending' },
    { label: 'failed', value: 'failed' },
    { label: 'done', value: 'done' },
    { label: 'pending_admin_approve', value: 'pending_admin_approve' },
    { label: 'pending_sign', value: 'pending_sign' },
    { label: 'signed', value: 'signed' },
    { label: 'unknown_creation_error', value: 'unknown_creation_error' },
];
const nameArr = ['from', 'to'];
const FilterButton = ({ item, onChange }) => {
    if (item.value === '')
        return null;
    return (React.createElement(Chip, { size: "small", color: "primary", label: (React.createElement(Box, { gap: 3, display: 'flex' },
            React.createElement(Box, null, nameArr.includes(item.name) ? `${item.label}: ${item.value}` : item.label),
            React.createElement(Box, null,
                React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: onChange })))) }));
};
const WithdrawalFiltersContent = ({ state, features, toggleModal, resetFilters, allCurrencies, handleDateChange, handleStateChange, removeSelectedFilter, handleCurrencyChange, }) => {
    const classes = useStyles();
    const [collapse, setCollapse] = useState(false);
    const [to, setTo] = useState(null);
    const [status, setStatus] = useState([]);
    const [from, setFrom] = useState(null);
    const [currency, setCurrency] = useState(null);
    useEffect(() => {
        const objIndexDateFrom = state.selectedFilters.findIndex((obj => obj.name === 'from'));
        const fromData = state.selectedFilters[objIndexDateFrom];
        setFrom(fromData);
        const objIndexDateTo = state.selectedFilters.findIndex((obj => obj.name === 'to'));
        const toData = state.selectedFilters[objIndexDateTo];
        setTo(toData);
        const objIndexCurrency = state.selectedFilters.findIndex((obj => obj.name === 'currency_code'));
        const currencyData = state.selectedFilters[objIndexCurrency];
        setCurrency(currencyData || null);
        const statusData = state.selectedFilters.filter(f => f.name === 'state');
        setStatus(statusData || []);
    }, [state]);
    const toggleCollapseFilter = () => {
        setCollapse(!collapse);
    };
    return (React.createElement(CustodyFilterPanelContainer, null,
        React.createElement(FiltersContainer, null,
            React.createElement(MoreFilters, null,
                React.createElement(Button, { size: "small", variant: "outlined", color: "primary", onClick: toggleCollapseFilter },
                    collapse ? '-' : '+',
                    " Quick filters")),
            state.selectedFilters.length ? (React.createElement(FiltersReset, null,
                React.createElement(Button, { variant: "outlined", size: "small", type: "button", color: "inherit", onClick: () => { resetFilters(); setStatus([]); } }, "Reset filters"))) : null,
            React.createElement(Permissions, { features: features, permissions: ['client_custody_transaction.create'] },
                React.createElement(CreateWithdrawal, null,
                    React.createElement(Button, { size: "small", color: "primary", variant: "outlined", onClick: toggleModal }, "Create withdrawal"))),
            React.createElement(SelectedFilters, null,
                React.createElement("div", null,
                    React.createElement(FiltersBox, null, state.selectedFilters.map((filter, i) => (React.createElement(FilterButton, { key: `${filter.label}_${i}`, item: filter, onChange: () => removeSelectedFilter(filter) }))))))),
        React.createElement(Collapse, { in: collapse },
            React.createElement(FiltersBody, null,
                React.createElement(Box, { style: { display: 'flex' }, gap: 1, marginBottom: 4 },
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(TextField, { id: "from", type: "date", size: "small", label: "Date From", className: classes.formGroup, InputLabelProps: { shrink: true }, defaultValue: new Date(from ? from.value : ''), onChange: (event) => handleDateChange(event, 'Date From', 'from') })),
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(TextField, { id: "to", type: "date", size: "small", label: "Date To", className: classes.formGroup, InputLabelProps: { shrink: true }, defaultValue: new Date(to ? to.value : ''), onChange: (event) => handleDateChange(event, 'Date To', 'to') }))),
                React.createElement(Box, { style: { display: 'flex' }, gap: 1 },
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(Autocomplete, { loading: false, options: allCurrencies, getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleCurrencyChange(newValue), renderInput: (params) => React.createElement(TextField, Object.assign({ label: "Currency", name: "currency_code" }, params, { variant: "outlined", value: {
                                    value: currency,
                                    label: 'Currency',
                                }, size: "small" })) })),
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(Autocomplete, { multiple: true, loading: false, limitTags: 1, options: defaultStates, getOptionLabel: (option) => option.label, onChange: (e, newValue, reason, detail) => handleStateChange(newValue, reason, detail), renderInput: (params) => React.createElement(TextField, Object.assign({ label: "State", name: "state" }, params, { variant: "outlined", value: {
                                    value: status,
                                    label: 'State',
                                }, size: "small" })) })))))));
};
export default WithdrawalFiltersContent;
