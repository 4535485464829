/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, useTheme, CircularProgress } from '@mui/material';
import HitBid from './HitBid';
import Spread from './Spread';
import LiftAsk from './LiftAsk';
import * as Styled from '../../styling/styled';
import renderFilterParams from '../../../../../../../shared/helpers/renderFilterParams';
import { collectParamsFromSettings } from '../../../../../../../shared/helpers/createUrlFromParams';
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/restrict-template-expressions */
const getSettingById = (settings, settingId) => {
    if (settings && settings.length === 0) {
        return null;
    }
    const find = settings.find(item => item && item.id.includes(settingId));
    return find && find.value;
};
const LadderHead = ({ selectedPair, selectedExchange, partialOrder, step, settings, widgetKey, theme, }) => {
    const [pair, setPair] = useState('');
    const [channel, setChannel] = useState('');
    const [localPair, setLocalPair] = useState('');
    const [channelFinal, setChannelFinal] = useState('');
    const [paramKeys, setParamsKeys] = useState([]);
    const [channelOrderbook, setChannelOrderbook] = useState('');
    const [channelBySettings, setChannelBySettings] = useState('');
    const [ignoreGlobal, setIgnoreGlobal] = useState(false);
    const [channelConsolidated, setChannelConsolidated] = useState('');
    const [spreadPair, setSpreadPair] = useState(null);
    const [channelOrderbookConsolidated, setChannelOrderbookConsolidated] = useState('');
    const [channelBySettingsConsolidated, setChannelBySettingsConsolidated] = useState('');
    const [filterParams, setFilterParams] = useState(null);
    const isIgnoreGlobalChecked = (set) => {
        const ignore = set.find(s => s && s.id.includes('IgnoreGlobal'));
        return ignore ? ignore.value : false;
    };
    const muiTheme = useTheme();
    useEffect(() => {
        if (selectedPair) {
            const pairVal = `${selectedPair.base}/${selectedPair.quote}`.toLowerCase();
            setPair(pairVal);
        }
    }, [selectedPair]);
    useEffect(() => {
        if (settings.length) {
            const isIgnoreGlobal = isIgnoreGlobalChecked(settings);
            setIgnoreGlobal(isIgnoreGlobal);
            const params = collectParamsFromSettings(settings, widgetKey);
            const keys = Object.keys(params);
            setParamsKeys(keys);
            setFilterParams(params);
            const settingsBaseArr = getSettingById(settings, 'Base');
            const settingsQuoteArr = getSettingById(settings, 'Quote');
            if (settingsBaseArr && settingsQuoteArr) {
                const pairLocalVal = `${settingsBaseArr
                    .value}/${settingsQuoteArr.value}`.toLowerCase();
                setLocalPair(pairLocalVal);
            }
        }
    }, [settings]);
    useEffect(() => {
        if (pair && step !== 0) {
            const channelOrderbookConsolidatedVal = `orderbook-simple-consolidated-aggregated-${step}-${pair}`;
            setChannelOrderbookConsolidated(channelOrderbookConsolidatedVal);
        }
    }, [step, pair]);
    useEffect(() => {
        if (localPair && step !== 0) {
            const channelBySettingsConsolidatedVal = `orderbook-simple-consolidated-aggregated-${step}-${localPair}`;
            setChannelBySettingsConsolidated(channelBySettingsConsolidatedVal);
        }
    }, [step, localPair]);
    useEffect(() => {
        if (selectedExchange && pair && step !== 0) {
            const channelOrderbookVal = `orderbook-simple-${selectedExchange.value}-aggregated-${step}-${pair}`;
            setChannelOrderbook(channelOrderbookVal);
        }
    }, [selectedExchange, step, pair]);
    useEffect(() => {
        if (settings.length) {
            const settingsExchangeArr = getSettingById(settings, 'Exchanges');
            if (settingsExchangeArr && localPair && step !== 0) {
                const channelBySettingsVal = `orderbook-simple-${settingsExchangeArr.value
                    .toLowerCase()}-aggregated-${step}-${localPair}`;
                setChannelBySettings(channelBySettingsVal);
            }
        }
    }, [settings, step, localPair]);
    useEffect(() => {
        if (ignoreGlobal && channelBySettings) {
            setChannelFinal(channelBySettings);
        }
        else if (channelOrderbook) {
            setChannelFinal(channelOrderbook);
        }
    }, [ignoreGlobal, channelBySettings, channelOrderbook]);
    useEffect(() => {
        if (ignoreGlobal && channelBySettingsConsolidated) {
            setChannelConsolidated(channelBySettingsConsolidated);
        }
        else if (channelOrderbookConsolidated) {
            setChannelConsolidated(channelOrderbookConsolidated);
        }
    }, [ignoreGlobal, channelBySettingsConsolidated, channelOrderbookConsolidated]);
    useEffect(() => {
        if (partialOrder && channelConsolidated) {
            setChannel(channelConsolidated);
        }
        else if (channelFinal) {
            setChannel(channelFinal);
        }
    }, [partialOrder, channelConsolidated, channelFinal]);
    useEffect(() => {
        if (settings.length) {
            const settingsBaseArr = getSettingById(settings, 'Base');
            const settingsQuoteArr = getSettingById(settings, 'Quote');
            if (ignoreGlobal) {
                const globalPair = {
                    base: settingsBaseArr.value,
                    quote: settingsQuoteArr.value,
                };
                setSpreadPair(globalPair);
            }
            else {
                setSpreadPair(selectedPair);
            }
        }
    }, [ignoreGlobal, settings, selectedPair]);
    if (!channel) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(React.Fragment, null,
        ignoreGlobal ? renderFilterParams(filterParams, paramKeys) : null,
        React.createElement(Styled.HeadPanel, { bordercolor: muiTheme.palette.divider },
            React.createElement(HitBid, { channel: channel }),
            React.createElement(Spread, { selectedPair: spreadPair, channel: channel, theme: theme }),
            React.createElement(LiftAsk, { channel: channel }))));
};
const stateToProps = (state) => {
    const { selectedPair, selectedExchange, partialOrder } = state.placeOrder;
    return {
        selectedPair,
        partialOrder,
        selectedExchange,
        theme: state.theme.className,
    };
};
export default connect(stateToProps)(LadderHead);
