import Switch from '@mui/material/Switch';
import { green } from '@mui/material/colors';
import { withStyles } from '@mui/styles';
const GreenSwitch = withStyles({
    switchBase: {
        color: green.A100,
        '&$checked': {
            color: green.A200,
        },
        '&$checked + $track': {
            backgroundColor: green.A200,
        },
    },
    checked: {},
    track: {},
})(Switch);
export default GreenSwitch;
