import React from 'react';
import * as Styled from '../../styling/styled';
import LadderHeading from './LadderHeading';
import LadderBody from './LadderBody';
import { useTheme } from '@mui/material';
const BodyContainer = ({ step, settings }) => {
    const theme = useTheme();
    return (React.createElement(Styled.BodyContainer, { dividercolor: theme.palette.divider },
        React.createElement(LadderHeading, null),
        React.createElement(Styled.BodyWrap, null,
            React.createElement(LadderBody, { step: step, settings: settings }))));
};
export default BodyContainer;
