var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { IconButton, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableSortLabel, TableBody, Typography, Box, Tooltip, Checkbox, FormControlLabel, } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { connect } from 'react-redux';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import PnLTableRow from './PnLTableRow';
const AutohedgerPnL = ({ columns }) => {
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [itmemsToRender, setItmemsToRender] = useState([]);
    const [streamsValues, setStreamValues] = useState({});
    const [dymamicTotals, setDynamicTotals] = useState();
    const [staticTotals, setStaticTotals] = useState();
    const [aggregateInEur, setAggregateInEur] = useState(true);
    useEffect(() => {
        const totalsArr = Object.values(streamsValues) || [];
        let unrealized = 0;
        let positionValue = 0;
        if (totalsArr.length) {
            totalsArr &&
                totalsArr.forEach((item) => {
                    unrealized += item.unrealizedValue;
                    positionValue += item.positionValue;
                });
            setDynamicTotals({
                positionValue: positionValue.toFixed(2),
                unrealizedValue: unrealized.toFixed(2),
            });
        }
    }, [streamsValues]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter((c) => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        fetchData();
    }, [aggregateInEur]);
    const handleAggregation = (event, checked) => {
        setAggregateInEur(checked);
    };
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        const apipath = `${String(getEnvSettings().adminApiUrl)}/risk-management/pnl`;
        yield axios.get(apipath, { params: { aggregate_in_eur: aggregateInEur } }).then((res) => {
            let average = 0;
            let realized = 0;
            res.data.rows.forEach((item) => {
                average += Number(item.average_exposure_value);
                realized += Number(item.realized_pnl);
            });
            setStaticTotals({ averageValue: average.toFixed(2), realizedValue: realized.toFixed(2) });
            setItmemsToRender(res.data.rows);
        });
    });
    const onStreamUpdate = (data) => {
        const newTotals = streamsValues;
        newTotals[data.pair] = { positionValue: Number(data.positionValue), unrealizedValue: Number(data.unrealizedValue) };
        setStreamValues(Object.assign({}, newTotals));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Paper, null,
            React.createElement(Box, { pl: 1 },
                React.createElement(FormControlLabel, { onChange: handleAggregation, checked: aggregateInEur, control: React.createElement(Checkbox, null), label: 'Aggregate in EUR' })),
            React.createElement(TableContainer, null,
                React.createElement(Table, { size: 'small', stickyHeader: true, "aria-label": 'sticky table' },
                    React.createElement(TableHead, null,
                        React.createElement(TableRow, null, columnsToRender.map((column) => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: 'left' },
                            React.createElement(Box, { sx: { whiteSpace: 'nowrap' } },
                                React.createElement(TableSortLabel, { direction: 'asc' }, column.title),
                                column.description && (React.createElement(Tooltip, { title: column.description },
                                    React.createElement(IconButton, { size: 'small' },
                                        React.createElement(InfoIcon, null)))))))))),
                    React.createElement(TableBody, null,
                        itmemsToRender.length === 0 ? (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: columnsToRender.length + 1 },
                                React.createElement(Typography, { variant: 'subtitle1' }, "No data available")))) : (itmemsToRender.map((a) => (React.createElement(PnLTableRow, { data: a, key: a.trading_pair, streamUpdate: onStreamUpdate })))),
                        itmemsToRender.length > 0 ? (React.createElement(TableRow, null,
                            React.createElement(TableCell, { colSpan: 5 },
                                React.createElement(Typography, { textTransform: 'uppercase' },
                                    React.createElement("b", null, "Total:"))),
                            React.createElement(TableCell, null,
                                React.createElement("b", null, dymamicTotals === null || dymamicTotals === void 0 ? void 0 : dymamicTotals.positionValue)),
                            React.createElement(TableCell, null,
                                React.createElement("b", null, staticTotals === null || staticTotals === void 0 ? void 0 : staticTotals.realizedValue)),
                            React.createElement(TableCell, null,
                                React.createElement("b", null, dymamicTotals === null || dymamicTotals === void 0 ? void 0 : dymamicTotals.unrealizedValue)))) : null))))));
};
const mapStateToProps = (state, ownProps) => ({
    columns: getWidgetColumns(state, ownProps.widgetKey),
    widgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(AutohedgerPnL);
