function descendingComparator(a, b, orderBy) {
    const aVal = parseFloat(a[orderBy])
        ? parseFloat(a[orderBy])
        : a[orderBy] ? a[orderBy].toLowerCase() : '';
    const bVal = parseFloat(b[orderBy])
        ? parseFloat(b[orderBy])
        : b[orderBy] ? b[orderBy].toLowerCase() : '';
    if (bVal < aVal) {
        return -1;
    }
    if (bVal > aVal) {
        return 1;
    }
    return 0;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
export { descendingComparator, getComparator, stableSort };
