import React from 'react';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
const useStyles = makeStyles((theme) => createStyles({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    }
}));
const SettingsModal = ({ open, toggleFunc, header = true, colored = false, color = 'primary', theme, children, }) => {
    const classes = useStyles();
    const backdropTrue = true;
    const dialogSize = children.props && children.props.widgetKey === 'details' ? 'lg' : 'sm';
    return (React.createElement(Dialog, { fullWidth: true, maxWidth: dialogSize, open: open, onClose: toggleFunc },
        React.createElement(DialogTitle, null,
            "Settings",
            React.createElement(IconButton, { size: "small", "aria-label": "close", className: classes.closeButton, onClick: toggleFunc },
                React.createElement(CloseIcon, { fontSize: "small" }))),
        React.createElement(Box, { p: 2 }, children)));
};
const mapStateToProps = (state) => {
    const { theme } = state;
    return {
        theme,
    };
};
export default connect(mapStateToProps)(SettingsModal);
