import React, { useState, useEffect, useContext, useCallback } from 'react';
import Decimal from 'decimal.js';
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import buildUrl from 'build-url';
import Autocomplete from '@mui/lab/Autocomplete';
import { connect, useDispatch } from 'react-redux';
import FeeService from '../../../../../services/feeService';
import Messages from '../../../../../shared/helpers/errorMessages';
import { RatesContext } from '../../../../../providers/RatesProvider';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { ModalSpinnerContainer, ModalLoadingContainer } from '../../styling/style';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import WithdrawalsAddressesService from '../../../../../services/withdrawalsAddressesService';
import { getCustodyWallets } from '../../../../../redux/selectors/custodySelectors';
import { getCustodyCurrencies } from '../../../../../redux/selectors/currenciesSelectors';
const feeLevelArr = [
    { label: 'low', value: 'low' },
    { label: 'high', value: 'high' },
    { label: 'medium', value: 'medium' },
    { label: 'custom', value: 'custom' },
];
const WithdrawalModal = ({ open, handleSendWithdraw, toggleFunc, loading, currencies, loadingWallets, allWallets, selectedClient, }) => {
    const [amount, setAmount] = useState('');
    const [precision, setPrecision] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [customFee, setCustomFee] = useState('');
    const [referenceId, setReferenceId] = useState('');
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const [wallets, setWallets] = useState([]);
    const [loadingAddresses, setLoadingAddresses] = useState(false);
    const [allAddresses, setAllAddresses] = useState([]);
    const [walletsOptions, setWalletsOptions] = useState([]);
    const [feeLevel, setFeeLevel] = useState(null);
    const [addressesOptions, setAddressesOptions] = useState([]);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [suggestedFees, setSuggestedFees] = useState(null);
    const [feeLevelArrToRender, setFeeLevelArrToRender] = useState([]);
    const [currency, setCurrency] = useState(currencies.length ? currencies[0] : null);
    const ctx = useContext(RatesContext);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fixLabel = (label) => {
        const parts = label.split('; ');
        let result = '';
        if (parts.length === 0) {
            return label;
        }
        parts.forEach((p, i) => {
            if (i === 0) {
                result += `${p.toUpperCase()} - `;
            }
            else if (i === 1) {
                result += `(${p.trim()}) `;
            }
            else if (i === 2) {
                result += `Total: ${p} `;
            }
            else if (p) {
                result += `(Available: ${p})`;
            }
        });
        return result;
    };
    const fetchAddresses = (currencyCode, client) => {
        const urlOptions = {
            queryParams: {
                limit: '1000',
                state: 'approved',
                sort_by: 'created',
                sort_direction: 'desc',
                currency_code: currencyCode,
            },
            path: '/custody/withdrawals/addresses',
        };
        if (client) {
            urlOptions.queryParams.clientCode = client.value;
        }
        const endpointUrl = buildUrl('', urlOptions);
        const depositAddressesService = new WithdrawalsAddressesService({
            url: endpointUrl,
            method: 'get',
        });
        setLoadingAddresses(true);
        depositAddressesService
            .makeRequest()
            .then((response) => {
            setLoadingAddresses(false);
            const options = response.records.map((r) => ({
                label: `${r.label}; ${r.address}`,
                value: r.code,
                currency: r.currency,
                wallet_code: r.wallet_code,
            }));
            setAllAddresses(options);
        })
            .catch((e) => {
            setLoadingAddresses(false);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchSuggestedFees = (currencyCode) => {
        const urlOptions = {
            queryParams: {
                currency_code: currencyCode,
            },
            path: '/custody/fees',
        };
        const endpointUrl = buildUrl('', urlOptions);
        const service = new FeeService({
            url: endpointUrl,
            method: 'get',
        });
        service
            .makeRequest()
            .then((response) => {
            setSuggestedFees(response.fees);
        })
            .catch((e) => {
            setSuggestedFees(null);
            setFeeLevelArrToRender(feeLevelArr);
            setFeeLevel(feeLevelArr[0]);
            const message = composeErrorMessage(e, Messages.CUSTODY_FEES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const handleCreateWithdrawal = () => {
        try {
            const isFeeGreaterThanOne = customFee ? new Decimal(customFee).greaterThan(1) : false;
            const fee = isFeeGreaterThanOne
                ? Decimal.mul(customFee, Decimal.pow(10, Decimal.mul(-1, precision))).toFixed()
                : customFee;
            const payload = {
                amount,
                reference_id: referenceId,
                custom_fee_size: `${fee}`,
                currency: (currency === null || currency === void 0 ? void 0 : currency.code) || '',
                fee_level: (feeLevel === null || feeLevel === void 0 ? void 0 : feeLevel.value) || '',
                wallet_code: (selectedWallet === null || selectedWallet === void 0 ? void 0 : selectedWallet.value) || '',
                address_code: (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.value) || '',
            };
            handleSendWithdraw(payload);
        }
        catch (e) {
            showNotification({
                message: `Something went wrong, error: ${e}`,
                color: 'error',
                dispatch: errorNotice,
            });
        }
        setAmount('');
        setCustomFee('');
        setReferenceId('');
        setFeeLevel(feeLevelArr[0]);
        setCurrency(currencies.length ? currencies[0] : null);
    };
    const onAmountChange = (evt) => {
        const val = evt.target.value.trim();
        if (isNaN(Number(val))) {
            return;
        }
        setAmount(val);
    };
    const handleReferenceChange = (evt) => {
        const regExp = /^[a-zA-Z0-9_\-]*$/;
        const { value } = evt.target;
        if (regExp.test(value)) {
            setReferenceId(value);
        }
    };
    const handleCustomFeeChange = (evt) => {
        const val = evt.target.value.trim();
        if (isNaN(Number(val))) {
            return;
        }
        setCustomFee(val);
    };
    const handleCurrencyChange = (event, newValue) => {
        setCurrency(newValue);
        setSuggestedFees(null);
    };
    const handleLevelChange = (event, newValue) => {
        setFeeLevel(newValue);
    };
    const handleSelectAddress = (event, newValue) => {
        setSelectedAddress(newValue);
    };
    const handleSelectWallet = (event, newValue) => {
        setSelectedWallet(newValue);
    };
    useEffect(() => {
        setFeeLevel(null);
        if (currency) {
            fetchSuggestedFees(currency.code);
        }
        setSelectedAddress(null);
        const currencyItem = currency;
        if (currencyItem) {
            setPrecision(currencyItem.decimals);
        }
        else {
            showNotification({
                message: 'Currency not configured',
                color: 'error',
                dispatch: errorNotice,
            });
        }
    }, [currency]);
    useEffect(() => {
        if (currency) {
            fetchAddresses(currency.code, selectedClient);
        }
    }, [currency, selectedClient]);
    useEffect(() => {
        if (suggestedFees && currency && currency.fee_value) {
            const filtered = feeLevelArr.map((f) => {
                if (f.label === 'custom')
                    return f;
                const feeValue = suggestedFees[f.label];
                const currentPrice = ctx.service && ctx.service.getCurrentPrice(currency.code.toLowerCase());
                const priceValue = currentPrice === 'Not convertible' || !currentPrice ? '0' : currentPrice;
                const curencyFee = Decimal.mul(feeValue, currency.fee_value).toFixed();
                let fee;
                if ((currency === null || currency === void 0 ? void 0 : currency.parent_code) === '') {
                    fee = Decimal.mul(curencyFee, priceValue);
                }
                else {
                    const parentPrice = ctx.service && ctx.service.getCurrentPrice(currency.parent_code);
                    const priceValue = parentPrice === 'Not convertible' || !parentPrice ? '0' : parentPrice;
                    fee = Decimal.mul(curencyFee, priceValue);
                }
                return Object.assign({}, f, {
                    label: `${f.label} (${curencyFee} ${currency.parent_code.toUpperCase() || currency.code.toUpperCase()} ≈ ${fee} USD)`,
                });
            });
            setFeeLevelArrToRender(feeLevelArr);
            if (filtered.length > 0) {
                setFeeLevel(feeLevelArr[0]);
            }
        }
    }, [suggestedFees, currency]);
    useEffect(() => {
        const options = allWallets
            .filter((w) => w.state === 'active' && ['regular', 'manual', 'pool'].includes(w.type))
            .map((w) => {
            var _a, _b, _c, _d, _e;
            const activeAddress = w.compliance_addresses.find((a) => a.state === 'active');
            const address = (activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.address) || '';
            const legacyAddress = (activeAddress === null || activeAddress === void 0 ? void 0 : activeAddress.legacy_address) || '';
            const addressData = legacyAddress !== '' ? address.concat(', Legacy: ').concat(legacyAddress) : address;
            const balance = ((_a = w.balance) === null || _a === void 0 ? void 0 : _a.available) !== ((_b = w.balance) === null || _b === void 0 ? void 0 : _b.total)
                ? `${((_c = w.balance) === null || _c === void 0 ? void 0 : _c.total) || ''}; ${((_d = w.balance) === null || _d === void 0 ? void 0 : _d.available) || ''}`
                : ((_e = w.balance) === null || _e === void 0 ? void 0 : _e.total) || '';
            return {
                value: w.code,
                currency: w.currency_code || '',
                label: `${w.label}; ${addressData}; ${balance}`,
            };
        });
        setWallets(options);
    }, [allWallets]);
    useEffect(() => {
        setLoadingSpinner(true);
        const filteredWallets = wallets
            .filter((w) => w.currency.toLowerCase() === ((currency === null || currency === void 0 ? void 0 : currency.code) || '').toLowerCase())
            .map((w) => (Object.assign(Object.assign({}, w), { label: fixLabel(w.label) })));
        setWalletsOptions(filteredWallets);
        setSelectedWallet(null);
        if (!loadingWallets) {
            setLoadingSpinner(false);
        }
    }, [currency, wallets, loadingWallets]);
    useEffect(() => {
        if (selectedWallet) {
            const filtered = allAddresses
                .filter((a) => a.wallet_code === null || a.wallet_code === selectedWallet.value)
                .map((a) => (Object.assign(Object.assign({}, a), { label: fixLabel(a.label) })));
            setAddressesOptions(filtered);
        }
    }, [selectedWallet, allAddresses]);
    useEffect(() => {
        if (selectedWallet !== null &&
            amount !== '' &&
            feeLevel !== null &&
            selectedAddress !== null &&
            currency !== null) {
            setDisabled(false);
        }
        else {
            setDisabled(true);
        }
    }, [selectedWallet, amount, feeLevel, selectedAddress, currency]);
    if (loadingSpinner || loading) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, null))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: 'sm', fullWidth: true },
        React.createElement(DialogTitle, { id: 'form-dialog-title' }, "Create new Withdrawal"),
        React.createElement(DialogContent, null,
            React.createElement(Autocomplete, { id: 'currency', size: 'small', value: currency, options: currencies, loading: loadingAddresses, placeholder: 'Select currency', style: { marginTop: '10px' }, onChange: handleCurrencyChange, className: 'mui_autocomplete_wrap', getOptionLabel: (option) => `${option.code.toUpperCase()} - ${option.label}`, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Currency', variant: 'outlined' })) }),
            React.createElement(Autocomplete, { id: 'wallet', size: 'small', value: selectedWallet, options: walletsOptions, placeholder: 'Select wallet', style: { marginTop: '10px' }, onChange: handleSelectWallet, className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Wallet', variant: 'outlined' })) }),
            React.createElement(TextField, { fullWidth: true, size: 'small', name: 'amount', value: amount, label: 'Amount', margin: 'normal', variant: 'outlined', onChange: onAmountChange, InputLabelProps: { shrink: true } }),
            React.createElement(Autocomplete, { size: 'small', id: 'destination', value: selectedAddress, options: addressesOptions, disabled: !selectedWallet, placeholder: 'Select address', style: { marginTop: '10px' }, onChange: handleSelectAddress, className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Destination', variant: 'outlined' })) }),
            React.createElement(Autocomplete, { size: 'small', id: 'feeLevel', value: feeLevel, onChange: handleLevelChange, options: feeLevelArrToRender, style: { marginTop: '10px' }, className: 'mui_autocomplete_wrap', getOptionLabel: (option) => option.label, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: 'Blockchain Fee level', variant: 'outlined' })) }),
            (feeLevel === null || feeLevel === void 0 ? void 0 : feeLevel.value) === 'custom' ? (React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', name: 'customFee', value: customFee, label: 'Custom Fee', variant: 'outlined', onChange: handleCustomFeeChange, InputLabelProps: { shrink: true } })) : null,
            React.createElement(TextField, { fullWidth: true, size: 'small', margin: 'normal', variant: 'outlined', name: 'referenceId', value: referenceId, label: 'Reference Id', onChange: handleReferenceChange, InputLabelProps: { shrink: true } })),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: 'primary', variant: 'contained', disabled: disabled, onClick: handleCreateWithdrawal }, "Create"),
            React.createElement(Button, { variant: 'outlined', onClick: () => toggleFunc() }, "Cancel"))));
};
const mapStateToProps = (state) => ({
    currencies: getCustodyCurrencies(state),
    allWallets: getCustodyWallets(state),
    loadingWallets: state.custody.loadingWallets,
});
export default connect(mapStateToProps)(WithdrawalModal);
