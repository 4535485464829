import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import LinkIcon from '@mui/icons-material/Link';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import CreateIcon from '@mui/icons-material/Create';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import BlockchainInfo from '../MegaModal/main';
import { MaterialPopoverWrap } from '../../styling/style';
const DepositsCellActions = ({ item, actions, deposits, fetchDeposits, urlDeposits, masterTenant, }) => {
    const history = useHistory();
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [action, setAction] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [availableActions, setAvailableActions] = useState();
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (userAction) => {
        toggleOpenDialog((prev) => !prev);
        setAction(userAction);
        setAnchorEl(null);
        history.push('/custody');
    };
    useEffect(() => {
        let r = actions.actionsList;
        if (masterTenant && item.state !== 'aml-failed') {
            r = [...actions.actionsList.filter((a) => a.action !== 'initiateAMLOverride')];
        }
        if (!masterTenant) {
            r = [...actions.actionsList.filter((a) => a.action !== 'initiateAMLOverride')];
        }
        setAvailableActions([...r]);
    }, [item, masterTenant]);
    const open = Boolean(anchorEl);
    const renderActionsContent = (userAction, itemDeposit) => {
        if (!userAction) {
            return React.createElement("div", null, "something went wrong");
        }
        if (userAction === 'megaModal') {
            return React.createElement(BlockchainInfo, { item: itemDeposit, allDeposits: deposits });
        }
        return React.createElement(actions.actionsComponents[userAction], {
            urlDeposits,
            fetchDeposits,
            item: itemDeposit,
            toggle: () => toggleDialog(),
            cellAction: actions.actions[userAction],
        });
    };
    return (React.createElement(TableCell, { align: 'right' },
        React.createElement(IconButton, { size: 'small', "aria-label": 'more', onClick: handleActionsClick },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { component: 'nav', "aria-label": 'more actions', dense: true }, availableActions === null || availableActions === void 0 ? void 0 :
                    availableActions.map((a) => (React.createElement(ListItem, { key: a.action, onClick: () => toggleDialog(a.action), button: true },
                        React.createElement(ListItemIcon, null,
                            React.createElement(CreateIcon, { fontSize: 'small' })),
                        React.createElement(ListItemText, { className: 'trading_clients_th_name', primary: a.name })))),
                    React.createElement(ListItem, { onClick: () => toggleDialog('megaModal'), button: true },
                        React.createElement(ListItemIcon, null,
                            React.createElement(LinkIcon, { fontSize: 'small' })),
                        React.createElement(ListItemText, { className: 'trading_clients_th_name', primary: 'Blockchain Info' }))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: 'sm', fullWidth: true },
            React.createElement(DialogTitle, { id: 'form-dialog-title' }, "Actions"),
            React.createElement(DialogContent, null, action ? renderActionsContent(action, item) : null))));
};
export default DepositsCellActions;
