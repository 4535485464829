import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FETCH_MARKETS_REQUEST, NEW_ORDER_BASE_SET_UPDATE, NEW_ORDER_QUOTE_SET_UPDATE, NEW_ORDER_EXCHANGE_SET_UPDATE, NEW_ORDER_EXCHANGE_UNAVAILABLE, } from '../../../../../../redux/actionTypes/placeOrderActionTypes';
import { getAvailableExchanges, getTradingBases, getTradingQuotes } from '../../../../../../redux/selectors/selectors';
import { Box, CircularProgress, MenuItem, TextField, Typography } from '@mui/material';
const TradingToolPair = ({ dispatch, loadingMarkets, optionsBase, optionsQuotes, selectedBase, selectedQuote, exchanges, selectedPair, }) => {
    useEffect(() => {
        dispatch({ type: FETCH_MARKETS_REQUEST });
    }, []);
    useEffect(() => {
        if (exchanges.length === 0) {
            dispatch({ type: NEW_ORDER_EXCHANGE_UNAVAILABLE });
            dispatch({ type: NEW_ORDER_EXCHANGE_SET_UPDATE, exchange: { value: '', label: '' } });
            return;
        }
        const exchange = exchanges[0];
        dispatch({ type: NEW_ORDER_EXCHANGE_SET_UPDATE, exchange });
    }, [selectedPair]);
    const handleChangeBase = (event) => {
        const base = optionsBase.find(b => b.value === event.target.value);
        if (base) {
            dispatch({ type: NEW_ORDER_BASE_SET_UPDATE, base });
        }
    };
    const handleChangeQuote = (event) => {
        const quote = optionsQuotes.find(q => q.value === event.target.value);
        if (quote) {
            dispatch({ type: NEW_ORDER_QUOTE_SET_UPDATE, quote });
        }
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Typography, { variant: 'subtitle1' }, "Trading pair"),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: loadingMarkets ? 'center' : 'default' }, loadingMarkets ? React.createElement(CircularProgress, null) : React.createElement(React.Fragment, null,
            React.createElement(TextField, { fullWidth: true, select: true, size: "small", variant: "outlined", label: "Base", value: selectedBase === null || selectedBase === void 0 ? void 0 : selectedBase.value, onChange: handleChangeBase }, optionsBase.map((option, i) => (React.createElement(MenuItem, { key: i, value: option.value }, option.label)))),
            React.createElement(TextField, { select: true, fullWidth: true, size: "small", variant: "outlined", label: "Quote", value: selectedQuote === null || selectedQuote === void 0 ? void 0 : selectedQuote.value, onChange: handleChangeQuote }, optionsQuotes.map((option, i) => (React.createElement(MenuItem, { key: i, value: option.value }, option.label))))))));
};
const stateToProps = (state) => {
    const { loadingMarkets, selectedBase, selectedQuote, selectedPair, } = state.placeOrder;
    const optionsBase = getTradingBases(state);
    const optionsQuotes = getTradingQuotes(state);
    const exchanges = getAvailableExchanges(state);
    return {
        exchanges,
        optionsBase,
        selectedBase,
        selectedPair,
        optionsQuotes,
        selectedQuote,
        loadingMarkets,
    };
};
export default connect(stateToProps)(TradingToolPair);
