import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dialog } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Messages from '../../../../../shared/helpers/errorMessages';
import StrategiesService from '../../../../../services/strategiesService';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
const updateItemInArray = (items, item) => {
    const itemIndex = items.findIndex(a => a.code === item.code);
    return items.map((address, index) => {
        if (index !== itemIndex) {
            return address;
        }
        const mergedItem = Object.assign(Object.assign({}, address), { status: 'ready' });
        return Object.assign(Object.assign({}, address), mergedItem);
    });
};
const ApproveDialog = ({ toggle, open, item, strategiesToRender, setStrategiesToRender, }) => {
    const [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleApprove = (code) => {
        setDisabled(true);
        const params = {
            url: `/defi/strategy/${code}/mark_as_ready`,
            method: 'patch',
            baseURL: getEnvSettings().adminApiUrl,
        };
        const service = new StrategiesService(params);
        service.makeRequest()
            .then(() => {
            setDisabled(false);
            showNotification({
                message: 'Strategy successfully approved',
                color: 'success',
                dispatch: errorNotice,
            });
            const updated = updateItemInArray(strategiesToRender, item);
            setStrategiesToRender(updated);
            toggle();
        })
            .catch((e) => {
            setDisabled(false);
            const message = composeErrorMessage(e, Messages.STRATEGY_APPROVE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    return (React.createElement(Dialog, { open: open, onClose: () => toggle(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Approving strategies"),
        React.createElement(DialogContent, null,
            React.createElement("div", null,
                "Are you sure you want to approve strategy: ",
                React.createElement("br", null),
                React.createElement("span", { className: "forms_uuid_container" }, item.code))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", variant: "contained", disabled: disabled, onClick: () => handleApprove(item.code) }, "Approve"),
            React.createElement(Button, { variant: "outlined", onClick: toggle }, "Close"))));
};
export default ApproveDialog;
