import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Checkbox } from '@mui/material';
/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-return */
const updateItemInArray = (items, item) => {
    const addressIndex = items.findIndex(a => a.base === item.base && a.quote === item.quote);
    return items.map((a, index) => {
        if (index !== addressIndex) {
            return a;
        }
        return Object.assign(Object.assign({}, a), item);
    });
};
const AutohedgerCheckbox = ({ setAutohedgers, columnKey, item, autohedgers, value, updateAutohedger, }) => {
    const handleCheckboxChange = (evt) => {
        const { checked, name } = evt.target;
        const mergedItem = Object.assign(Object.assign({}, item), { [name]: checked });
        const updated = updateItemInArray(autohedgers, mergedItem);
        setAutohedgers(updated);
        const payload = {
            base_code: item.base,
            auto_hedging: checked,
            quote_code: item.quote,
            threshold: item.threshold,
            min_amount: item.min_amount,
            daily_limit: item.daily_limit,
        };
        updateAutohedger(payload, 'updated');
    };
    return (React.createElement(TableCell, { align: "center" },
        React.createElement("div", null,
            React.createElement(Checkbox, { checked: value, name: columnKey, onChange: handleCheckboxChange, id: `${item.base} ${item.quote}` }))));
};
export default AutohedgerCheckbox;
