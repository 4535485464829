var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, Typography } from '@mui/material';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../shared/helpers/errorMessages';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import InitiateSettlements from './CreateSettlementLine';
import DLTFTable from '../../../../../shared/components/table/DLTTableComponent';
import { CELL_TYPE } from '../../../../../shared/components/table/DLTTableModel';
import { useHistory } from 'react-router-dom';
import { getAllClients } from '../../../../../redux/selectors/clientSearchSelectors';
import DLTFTableFilters from '../../../../../shared/components/filters/DLTFTableFilters';
import { FIAT_CURRENCIES } from '../../../../../shared/constants/FiatOptions';
import { SecondaryText } from '../../../../../shared/components/styled/labels';
import { IdCell } from '../../../../../shared/components/styled/tableCells';
function mapSettlements(settlements, customers, clients) {
    return settlements.map((item) => {
        var _a, _b, _c, _d;
        const customerName = (_a = customers.find((c) => c.code === item.customer_code)) === null || _a === void 0 ? void 0 : _a.company_name;
        return {
            id: item.code,
            customerName: (React.createElement(Box, null,
                customerName ? (React.createElement(Typography, { variant: 'body2' }, customerName)) : (React.createElement(Typography, null,
                    React.createElement(IdCell, null, item.customer_code))),
                React.createElement(SecondaryText, null, ((_b = clients.find((c) => c.code === item.client_code)) === null || _b === void 0 ? void 0 : _b.company_name) || item.client_code))),
            customer_code: item.customer_code,
            client_code: item.client_code,
            clientName: ((_c = clients.find((c) => c.code === item.client_code)) === null || _c === void 0 ? void 0 : _c.company_name) || item.client_code,
            currency: (_d = item.currency) === null || _d === void 0 ? void 0 : _d.toUpperCase(),
            capacity: item.capacity,
            created: item.created_at,
            updated: item.updated_at,
        };
    });
}
function getFilters(allCustomers) {
    const customers = {
        name: 'customer_codes',
        label: 'Customer',
        values: allCustomers.map((c) => ({ value: c.code, label: c.company_name || c.code })),
    };
    const currencies = {
        name: 'currency_code',
        label: 'Currency',
        values: FIAT_CURRENCIES,
    };
    const from = {
        name: 'created_from',
        label: 'From',
        type: 'dateFrom',
    };
    const to = {
        name: 'created_to',
        label: 'To',
        type: 'dateTo',
    };
    return [customers, currencies, from, to];
}
const tableColumns = [
    { id: 'customerName', label: 'Customer', width: 20, sortable: false },
    { id: 'currency', label: 'Currency', width: 10, sortable: false },
    {
        id: 'capacity',
        label: 'Capacity',
        editable: true,
        width: 20,
        sortable: false,
        type: CELL_TYPE.NUMERIC
    },
    { id: 'created', label: 'Created', width: 20, type: CELL_TYPE.DATE_TIME, sortable: true },
    { id: 'updated', label: 'Updated', width: 20, type: CELL_TYPE.DATE_TIME, sortable: false },
];
const SettlementLinesTable = (props) => {
    const [createDialog, setCreateDialog] = useState(false);
    const [queryParams, setQueryParams] = useState({
        rowsPerPage: 10,
        page: 0,
        orderBy: 'created',
        order: 'desc',
    });
    const [filterParams, setFilterParams] = useState({
        customer_codes: undefined,
        currency_code: undefined,
        created_from: undefined,
    });
    const [totalItems, setTotalItems] = useState(0);
    const [settlements, setSettlements] = useState([]);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [filters, setFilters] = useState([]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const history = useHistory();
    const prevFilters = useRef(filterParams).current;
    useEffect(() => {
        history.listen((entry) => {
            setShouldUpdate(false);
            if (typeof entry.state === 'object' && entry.state !== null && 'shouldUpdate' in entry.state) {
                const stateWithShouldUpdate = entry.state;
                if (stateWithShouldUpdate) {
                    setShouldUpdate(true);
                }
            }
        });
    }, []);
    useEffect(() => {
        if (shouldUpdate) {
            getData();
        }
    }, [shouldUpdate]);
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        return yield axios
            .get('settlement_line', {
            baseURL: getEnvSettings().adminApiUrl,
            params: {
                customer_codes: filterParams.customer_codes,
                currency_code: filterParams.currency_code,
                client_codes: props.selectedClient,
                created_from: (_a = filterParams.created_from) === null || _a === void 0 ? void 0 : _a.toISOString(),
                created_to: (_b = filterParams.created_to) === null || _b === void 0 ? void 0 : _b.toISOString(),
                limit: queryParams.rowsPerPage,
                page: queryParams.page + 1,
                sort_by: queryParams.orderBy,
                sort_direction: queryParams.order,
            },
        })
            .then((response) => {
            setTotalItems(response.data.total || 0);
            return response.data.records;
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const updtateSettlement = (id, params) => {
        return axios
            .patch(`/settlement_line/${id}`, params, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .then((response) => response.data)
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENT_LINE_UPDATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const getData = () => __awaiter(void 0, void 0, void 0, function* () {
        let newSettlements = [];
        yield fetchData().then((res) => {
            if (!res)
                return;
            newSettlements = res;
        });
        setSettlements(mapSettlements(newSettlements, props.customers, props.clients));
    });
    useEffect(() => {
        if (prevFilters !== filterParams) {
            getData();
        }
    }, [filterParams]);
    useEffect(() => {
        if (props.customersLoaded && props.clients.length) {
            getData();
            setFilters(getFilters(props.customers));
        }
    }, [queryParams, props.selectedClient, props.customersLoaded, props.clients]);
    const handleParamsChange = (params) => {
        setQueryParams(params);
    };
    const handleToggleDialog = (result) => {
        setCreateDialog(false);
        if (result) {
            getData();
        }
    };
    const handleRowClick = (id) => {
        history.push(`./settlement-lines/${id}`);
    };
    const handleCellEdit = (id, entity, value) => {
        updtateSettlement(id, { capacity: value }).then((result) => {
            if (!result)
                return;
            const updatedSettlement = mapSettlements([result], props.customers, props.clients)[0];
            const updatedSettlements = settlements.map((s) => {
                if (s.id === updatedSettlement.id) {
                    return updatedSettlement;
                }
                else {
                    return s;
                }
            });
            showNotification({
                message: 'Settlement line has been updated',
                color: 'success',
            });
            setSettlements(updatedSettlements);
        });
    };
    const handleFiltersChange = (e) => {
        setFilterParams(e);
    };
    const handleFiltersReset = () => {
        setFilterParams({});
    };
    return (React.createElement(Box, null,
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } },
            React.createElement(Typography, { variant: 'h6', p: 2 }, "Settlement Lines"),
            React.createElement(Button, { size: 'small', variant: 'contained', onClick: () => setCreateDialog(true) }, "Create")),
        React.createElement(Box, null, filters.length ? (React.createElement(DLTFTableFilters, { fields: filters, onChange: (e) => handleFiltersChange(e), onReset: handleFiltersReset })) : null),
        React.createElement(DLTFTable, { defaultOrderProp: 'created', pagination: true, data: settlements, columns: tableColumns, total: totalItems, internalSorting: false, onCellEdit: handleCellEdit, onRowClick: handleRowClick, onQueryParamsChange: handleParamsChange }),
        React.createElement(Dialog, { open: createDialog, onClose: () => setCreateDialog(false), fullWidth: true },
            React.createElement(InitiateSettlements, { toggleDialog: (e) => handleToggleDialog(e) }))));
};
const stateToProps = (state) => {
    const { selectedClient } = state.clientSearch;
    const { customers, loaded } = state.customersSearch;
    const clients = getAllClients(state);
    return {
        selectedClient,
        customers,
        customersLoaded: loaded,
        clients,
    };
};
export default connect(stateToProps)(SettlementLinesTable);
