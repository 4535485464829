import React from 'react';
import LoginCard from '../../../shared/components/login/LoginCard';
import { getEnvSettings } from '../../../config/environmentSettings';
import { FormLogo } from '../../../shared/components/login/styling/styledComponents';
const finoaEnv = getEnvSettings().env === 'finoa';
const LogIn = () => {
    return (React.createElement("div", { className: 'account' },
        React.createElement("div", { className: 'account__head' },
            React.createElement(FormLogo, { className: finoaEnv ? 'form_logo_finoa' : 'form_logo' })),
        React.createElement("div", { className: 'account__wrapper' },
            React.createElement(LoginCard, null))));
};
export default LogIn;
