import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box, Paper, Button, TextField, Container, Typography, IconButton, InputAdornment, CircularProgress, } from '@mui/material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import LockIcon from 'mdi-react/LockIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Messages from '../../helpers/errorMessages';
import showNotification from '../../helpers/notifications';
import { composeErrorMessage } from '../../helpers/interceptors';
import { getEnvSettings } from '../../../config/environmentSettings';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
const resetPasswordFields = {
    email: '',
    password: '',
    confirmPassword: '',
    twoFa: '',
};
const validateFields = (fieldsToValidate) => {
    const errors = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(fieldsToValidate)) {
        // validate value not empty
        if (value.trim() === '') {
            errors.push({ name: key, message: 'Field is required' });
        }
        else if (key === 'email') {
            // validate email
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(value).toLowerCase())) {
                errors.push({ name: key, message: 'Must be valid email address' });
            }
        }
        else if (key === 'twoFa' && value.length !== 6) {
            errors.push({ name: key, message: '2fa code should be at least 6 chars long' });
        }
    }
    // check passwords
    const passError = fieldsToValidate.password === fieldsToValidate.confirmPassword
        ? null : { name: 'passNotSame', message: 'Passwords must be equal' };
    if (passError === null) {
        return errors;
    }
    return [...errors, passError];
};
const ResetPasswordForm = () => {
    const dirty = useRef(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [formFields, setFormFields] = useState(null);
    const [validation, setValidation] = useState([]);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onFieldChange = (event) => {
        const fieldName = event.target.name;
        resetPasswordFields[fieldName] = event.target.value;
        setFormFields(Object.assign(Object.assign({}, formFields), resetPasswordFields));
    };
    const handleClickShowPassword = () => setShowPassword(prev => !prev);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(prev => !prev);
    const checkValidation = (fieldName) => {
        const fieldError = validation.find(e => e.name === fieldName);
        return fieldError && fieldError.message;
    };
    const resetSubmit = (evt) => {
        evt.preventDefault();
        if (!dirty.current) {
            dirty.current = true;
        }
        let errors = [];
        if (formFields) {
            errors = validateFields(formFields);
        }
        if (errors.length) {
            // add errors
            setValidation(() => [...errors]);
            return undefined;
        }
        setLoading(true);
        if (formFields && token) {
            const data = {
                email: formFields.email,
                new_password: formFields.password,
                totp_code: formFields.twoFa,
                token,
            };
            axios({
                method: 'post',
                url: '/password_reset',
                data,
                baseURL: getEnvSettings().frontendApiUrl,
            }).then(() => {
                window.location.href = '/';
            }).catch((e) => {
                const { ErrorLogger } = window;
                ErrorLogger.setErrors(e, new Date());
                const message = composeErrorMessage(e, Messages.TOKEN_RESET);
                showNotification({
                    message: `Error: ${message}`,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
            setLoading(false);
        }
        setLoading(false);
        return undefined;
    };
    useEffect(() => {
        setFormFields(resetPasswordFields);
        const parsedUrl = new URL(window.location.href);
        setToken(parsedUrl.searchParams.get('token'));
    }, []);
    useEffect(() => {
        if (dirty.current && formFields) {
            // validate fields
            setValidation(() => [...validateFields(formFields)]);
        }
    }, [formFields]);
    return (React.createElement(Container, { maxWidth: "xs" },
        React.createElement(Paper, null,
            React.createElement("form", { onSubmit: resetSubmit },
                React.createElement(Box, { p: 4, gap: 2 },
                    React.createElement(Typography, { variant: 'h5', align: 'center' }, "Reset Password"),
                    React.createElement(Box, null,
                        React.createElement(TextField, { fullWidth: true, id: "email", size: "small", type: "email", name: "email", margin: "normal", label: "User email", variant: "outlined", onChange: onFieldChange, className: "mui_select_wrap", InputLabelProps: { shrink: true }, value: formFields ? formFields.email : '', InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(EmailIcon, null))),
                            } }),
                        checkValidation('email') ? (React.createElement("span", null, checkValidation('email'))) : null),
                    React.createElement(Box, null,
                        React.createElement(TextField, { fullWidth: true, size: "small", id: "password", name: "password", margin: "normal", label: "Password", variant: "outlined", placeholder: "Password", onChange: onFieldChange, className: "mui_select_wrap", InputLabelProps: { shrink: true }, type: showPassword ? 'text' : 'password', value: formFields ? formFields.password : '', InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(KeyVariantIcon, null))),
                                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { edge: "end", "aria-label": "toggle password visibility", onClick: () => handleClickShowPassword(), size: "large" }, showPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                            } }),
                        checkValidation('password') ? (React.createElement("span", null, checkValidation('password'))) : null,
                        checkValidation('passNotSame') ? (React.createElement("span", null, checkValidation('passNotSame'))) : null),
                    React.createElement(Box, null,
                        React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", variant: "outlined", id: "confirmPassword", name: "confirmPassword", label: "Confirm password", onChange: onFieldChange, className: "mui_select_wrap", placeholder: "Confirm password", InputLabelProps: { shrink: true }, type: showConfirmPassword ? 'text' : 'password', value: formFields ? formFields.confirmPassword : '', InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(KeyVariantIcon, null))),
                                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { edge: "end", "aria-label": "toggle password visibility", onClick: () => handleClickShowConfirmPassword(), size: "large" }, showConfirmPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                            } }),
                        checkValidation('confirmPassword') ? (React.createElement("span", null, checkValidation('password'))) : null,
                        checkValidation('passNotSame') ? (React.createElement("span", null, checkValidation('passNotSame'))) : null),
                    React.createElement(Box, null,
                        React.createElement(TextField, { fullWidth: true, id: "twoFa", size: "small", name: "twoFa", margin: "normal", label: "TOTP code", variant: "outlined", onChange: onFieldChange, className: "mui_select_wrap", InputLabelProps: { shrink: true }, value: formFields ? formFields.twoFa : '', inputProps: { maxLength: 6 }, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(LockIcon, null))),
                            } }),
                        checkValidation('twoFa') ? (React.createElement("span", null, checkValidation('twoFa'))) : null),
                    React.createElement(Box, null,
                        React.createElement(Button, { type: "submit", variant: "contained", style: { width: '100%' }, disabled: (validation.length > 0 || loading) }, loading ? (React.createElement(CircularProgress, { size: "24.5px" })) : 'Reset')))))));
};
export default ResetPasswordForm;
