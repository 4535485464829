import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import Table from '@mui/material/Table';
import { Box, Chip, Icon } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { connect } from 'react-redux';
import TableContainer from '@mui/material/TableContainer';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import RenderBlockchainTableBody from './RenderBlockchainTable';
import RenderWithdrawalTableBody from './RenderWithdrawalTable';
import { statusBadgeData } from '../../../../../config/statusBadgeData';
import statusBadgeColor from '../../../../../shared/helpers/statusBadgeColor';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
/* eslint-disable camelcase, no-prototype-builtins, no-nested-ternary, react/jsx-indent */
const BlockchainForm = ({ item, currencies, masterTenant, clients }) => {
    var _a;
    const { url } = useRouteMatch();
    const [iconID, setIconID] = useState('file_copy');
    const location = useLocation();
    const [isDeposit, setIsDeposit] = useState(false);
    const [iconAddress, setIconAddress] = useState('file_copy');
    const [iconStore, setIconStore] = useState('file_copy');
    const [iconReceived, setIconReceived] = useState('file_copy');
    const handleCopyReceivedHash = (hash) => {
        setIconReceived('done');
        setTimeout(() => {
            setIconReceived('file_copy');
        }, 3000);
        copy(hash);
    };
    const handleCopyStoreHash = (hash) => {
        setIconStore('done');
        setTimeout(() => {
            setIconStore('file_copy');
        }, 3000);
        copy(hash);
    };
    const handleCopyID = (hash) => {
        setIconID('done');
        setTimeout(() => {
            setIconID('file_copy');
        }, 3000);
        copy(hash);
    };
    const handleCopyAddress = (hash) => {
        setIconAddress('done');
        setTimeout(() => {
            setIconAddress('file_copy');
        }, 3000);
        copy(hash);
    };
    const entity = (location.state && location.state.depositItem) || item;
    useEffect(() => {
        if (entity) {
            const hasProperty = entity.hasOwnProperty('compliance_to_custody_fee_currency_code');
            setIsDeposit(hasProperty);
        }
    }, [entity]);
    if (!entity)
        return React.createElement("div", null,
            "No info for ",
            isDeposit ? 'deposit' : 'withdrawal');
    const address = isDeposit ? entity.deposit_address
        : entity.destination_address
            ? ((_a = entity.destination_address) === null || _a === void 0 ? void 0 : _a.address) || '' : '';
    const getClientName = (id) => {
        var _a;
        return ((_a = clients.find(c => c.code === id)) === null || _a === void 0 ? void 0 : _a.company_name) || '';
    };
    return (React.createElement(Box, null,
        React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, null, "Client ID:"),
            React.createElement(Box, { margin: '0 7' }, entity.client_uuid),
            React.createElement(Box, null,
                React.createElement(CopyValue, { value: entity.client_uuid }))),
        React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, null, "Client Name:"),
            React.createElement(Box, { margin: '0 7' }, getClientName(entity.client_uuid))),
        React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, null,
                isDeposit ? 'Deposit' : 'Withdrawal',
                " ID:"),
            React.createElement(Box, { margin: '0 7' }, entity.code),
            React.createElement(Box, null,
                React.createElement(Icon, { onClick: () => handleCopyID(`${entity.code}`), fontSize: "small" }, iconID))),
        React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, { gap: 1 }, "Address:"),
            React.createElement(Box, { margin: '0 7' },
                React.createElement(Link, { style: { color: 'inherit' }, to: {
                        pathname: `${url}/wallets/${entity.wallet_code}`,
                        state: { clientCode: entity.client_uuid },
                    } }, address)),
            React.createElement(Box, null,
                React.createElement(Icon, { onClick: () => handleCopyAddress(`${address}`), fontSize: "small" }, iconAddress))),
        isDeposit && entity.deposit_sources.length ? (entity.deposit_sources.map((e, i) => (React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, null, entity.deposit_sources.length > 1 ? `Source ${i + 1}: ` : 'Sources: '),
            React.createElement(Box, { margin: '0 7' }, e.address || 'N/A'))))) : null,
        React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, null, "Reference ID:"),
            React.createElement(Box, { marginLeft: '7px' }, entity.reference_id || '-----')),
        React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, { paddingRight: '7px' }, "State:"),
            React.createElement(Box, null, isDeposit ? (React.createElement(Chip, { size: "small", label: entity.state, style: statusBadgeColor(statusBadgeData.stateBackgroundsDeposits[entity.state.replace(/-/g, '_')], statusBadgeData.stateBordersDeposits[entity.state.replace(/-/g, '_')], statusBadgeData.stateColorsDeposits[entity.state.replace(/-/g, '_')]).badge })) : (React.createElement(Chip, { size: "small", label: entity.state, style: statusBadgeColor(statusBadgeData.stateBackgroundsWithdrawals[entity.state.replace(/-/g, '_')], statusBadgeData.stateBordersWithdrawals[entity.state.replace(/-/g, '_')], statusBadgeData.stateColorsWithdrawals[entity.state.replace(/-/g, '_')]).badge })))),
        !isDeposit && masterTenant ? (React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, null, "Failure reason:"),
            React.createElement(Box, { marginLeft: '7px' }, entity.failure_reason || '-----'))) : null,
        !isDeposit && masterTenant ? (React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
            React.createElement(Box, null, "Failure raw error:"),
            React.createElement(Box, { marginLeft: '7px' }, entity.failure_raw_error || '-----'))) : null,
        React.createElement(TableContainer, null,
            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null),
                        React.createElement(TableCell, { align: "right" }, "Amount"),
                        React.createElement(TableCell, { align: "right" }, "Fee"),
                        React.createElement(TableCell, { align: "right" }, "TX hash"),
                        React.createElement(TableCell, { align: "right" }, "Received at"))),
                isDeposit ? (React.createElement(RenderBlockchainTableBody, { iconStore: iconStore, currencies: currencies, iconReceived: iconReceived, handleCopyStoreHash: handleCopyStoreHash, handleCopyReceivedHash: handleCopyReceivedHash, item: entity })) : (React.createElement(RenderWithdrawalTableBody, { currencies: currencies, iconReceived: iconReceived, handleCopyReceivedHash: handleCopyReceivedHash, item: entity }))))));
};
const mapStateToProps = (state) => ({
    currencies: state.currencies.custodyCurrencies,
    masterTenant: state.client.masterTenant,
    clients: state.clientSearch.allClients,
});
export default connect(mapStateToProps)(BlockchainForm);
