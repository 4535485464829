import React from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { Box, Button, Checkbox, FormControlLabel, TextField, MenuItem } from '@mui/material';
import { SCHEMA_REMOVE_WIDGET, SCHEMA_WORKING_RESET, SCHEMA_WORKING_REQUEST, } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
import InputNumber from '../../../../../shared/components/InputNumber';
import DateComponent from '../../../../../shared/components/DateComponent';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { getWidgetsWorkingSettingsByKey } from '../../../../../redux/selectors/widgetSettingsSelector';
export const forbidRemovingList = [
    'clients',
    'wallets',
    'portfolio',
    'strategies',
    'placeOrder',
    'autohedger',
    'placeOrders',
    'hedgeOrders',
    'manageUsers',
    'manageTokens',
    'manageOrders',
    'clientSearch',
    'quoteMonitor',
    'adminExchange',
    'manageApprove',
    'manageInvites',
    'manageLogbook',
    'currencyConfig',
    'customerSearch',
    'complianceStats',
    'manageCustomers',
    'manageSchedulers',
    'manageCompliance',
    'tradingTransfers',
    'clientsManagement',
    'exchangesBalances',
    'autohedgerBalance',
    'complianceWallets',
    'complianceTransactions',
];
/* eslint-disable no-unused-vars, no-param-reassign, @typescript-eslint/restrict-template-expressions */
const Settings = ({ toggleFunc, settings, widgetKey, }) => {
    const dispatch = useDispatch();
    const handleFieldChange = (evt) => {
        const { value, id, checked, type, } = evt.target;
        const settingValue = type === 'checkbox' ? checked : value;
        const payload = {
            settingId: id,
            settingValue,
        };
        dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
    };
    const handleNumberFieldChange = (evt) => {
        const re = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
        const { value, id } = evt.target;
        const payload = {
            settingId: id,
            settingValue: re.exec(value),
        };
        dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
    };
    const handleSelectChange = (event, id) => {
        const payload = {
            settingId: id,
            settingValue: { label: event.target.value, value: event.target.value },
        };
        dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
    };
    const handleDateChange = (date, id) => {
        const payload = {
            settingId: id,
            settingValue: moment(date).format() !== 'Invalid date' ? moment(date).format() : '',
        };
        dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
    };
    const applySettings = () => {
        // TO DO: merge schemas
        dispatch(applyAllSettingsChanges('trading'));
        toggleFunc();
    };
    const removeWidget = (key) => {
        // TO DO: merge schemas
        dispatch({ type: SCHEMA_REMOVE_WIDGET, widgetKey: key });
        toggleFunc();
    };
    const resetSettings = (key) => {
        dispatch({ type: SCHEMA_WORKING_RESET, widgetKey: key });
    };
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement(Box, { mb: 2, display: 'flex', flexDirection: 'column', gap: 2 }, settings.map((setting) => {
                        if (setting) {
                            switch (setting.type) {
                                case 'checkbox':
                                    return (React.createElement(FormControlLabel, { key: setting.id, control: React.createElement(Checkbox, { id: setting.id, value: setting.value, onChange: handleFieldChange, checked: setting.value, name: setting.id }), label: setting.title }));
                                case 'text':
                                    return (React.createElement(TextField, { key: setting.id, id: setting.id, name: setting.id, variant: "outlined", size: "small", onChange: handleFieldChange, label: setting.title, value: setting.value }));
                                case 'select':
                                    return (React.createElement(TextField, { select: true, fullWidth: true, variant: "outlined", size: "small", id: setting.id, key: setting.id, name: setting.id, onChange: (event) => handleSelectChange(event, setting.id), label: setting.title, value: setting.value.value }, setting.options.map(option => React.createElement(MenuItem, { value: option.value }, option.label))));
                                case 'date':
                                    return (React.createElement(DateComponent, { key: setting.id, label: setting.title, value: new Date(setting.value), onChange: date => handleDateChange(date, setting.id) }));
                                case 'number':
                                    return (React.createElement(InputNumber, { key: setting.id, name: setting.id, onChange: handleNumberFieldChange, label: setting.title, value: setting.value }));
                                default:
                                    return null;
                            }
                        }
                        else {
                            return null;
                        }
                    })),
                    React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', gap: 1 },
                        widgetKey === 'rfqOrders' || forbidRemovingList.includes(widgetKey) ? null : (React.createElement(React.Fragment, null,
                            React.createElement(Button, { size: "small", variant: "contained", color: "error", onClick: () => removeWidget(widgetKey) }, "Remove widget"))),
                        widgetKey === 'rfqOrders' || settings.length === 0 ? null : (React.createElement(Button, { size: "small", variant: "outlined", color: "inherit", onClick: () => resetSettings(widgetKey) }, "Reset")),
                        React.createElement(Button, { size: "small", variant: "contained", color: "success", onClick: applySettings }, "Apply")))))));
};
const stateToProps = (state, ownProps) => {
    const { widgetKey } = ownProps;
    return {
        /* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/ban-ts-comment */
        // @ts-ignore
        settings: getWidgetsWorkingSettingsByKey(state, widgetKey),
    };
};
export default connect(stateToProps)(Settings);
