import { createSelector } from 'reselect';
const clients = (state) => state.clientSearch.allClients;
const selectedClient = (state) => state.clientSearch.selectedClient;
export const getSelectedClient = createSelector(clients, selectedClient, (clients, selectClient) => {
    return clients.find((c) => c.code === selectClient) || null;
});
export const getSelectedClientInputValue = createSelector(clients, selectedClient, (clients, selectClient) => {
    const client = clients.find((c) => c.code === selectClient);
    if (!client)
        return null;
    return { value: client.code, label: client.company_name || client.code };
});
export const getAllClients = createSelector(clients, (clients) => clients);
