import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RFQManagement from '../../../Dashboards/MasterTenant/components/RFQ/Streams/main';
import SettlementLines from '../../../Dashboards/MasterTenant/components/SettlementLines/main';
import WebhooksContainer from '../../../Dashboards/Admin/components/Webhooks/main';
import FundingInfo from '../../../Dashboards/Admin/components/FundingInfo/main';
import SettlementLineDetails from '../../../Dashboards/MasterTenant/components/SettlementLines/SettlementLineDetails';
import Dashboard from '../../../Dashboards/Dashboard';
export default function SettingsRoot(props) {
    const { features } = props;
    const ordersEnabled = features && (features === null || features === void 0 ? void 0 : features.length) > 0 && (features === null || features === void 0 ? void 0 : features.includes('order.read'));
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: '/settings' },
            React.createElement(Redirect, { exact: true, to: 'settings/rfq' })),
        React.createElement(Route, { path: '/settings/rfq' },
            React.createElement(RFQManagement, null)),
        React.createElement(Route, { path: '/settings/settlement-lines' },
            React.createElement(SettlementLines, null),
            React.createElement(Route, { exact: true, path: '/settings/settlement-lines/:id' },
                React.createElement(SettlementLineDetails, null))),
        React.createElement(Route, { path: '/settings/api-tokens' },
            React.createElement(Dashboard, null)),
        React.createElement(Route, { path: '/settings/webhooks' },
            React.createElement(WebhooksContainer, null)),
        React.createElement(Route, { path: '/settings/funding-info' },
            React.createElement(FundingInfo, null)),
        React.createElement(Route, { path: '*' },
            React.createElement(Redirect, { to: '/404' }))));
}
