import React, { useState } from 'react';
import { TableHead, TableRow, TableCell, TableBody, Table, Button, Box, Tooltip, useTheme, TablePagination, TableSortLabel, } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ConfirmationDialog from '../../../../../shared/components/confirmationDialog/ConfirmationDialog';
import DateFormat from '../../../../../shared/components/dateFormat/DateFormat';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import { TruncateLeft } from '../../../Crypto/components/MergedOrderList/styling/styledComponents';
import { getComparator, stableSort } from '../../../../../shared/helpers/tables';
const sortableCols = ['url', 'created_at'];
const headCells = [
    {
        id: 'url',
        numeric: false,
        disablePadding: false,
        label: 'Url',
    },
    {
        id: 'code',
        numeric: false,
        disablePadding: false,
        label: 'Code',
    },
    {
        id: 'public_key',
        numeric: false,
        disablePadding: false,
        label: 'Public Key',
    },
    {
        id: 'created_at',
        numeric: false,
        disablePadding: false,
        label: 'Created At',
    },
];
const WebhooksTable = (props) => {
    const [state, setState] = useState({
        confirmDelete: false,
        webhookToDelete: '',
    });
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('created_at');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const theme = useTheme();
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.webhooks.length) : 0;
    const visibleRows = React.useMemo(() => stableSort(props.webhooks, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), [order, orderBy, page, rowsPerPage]);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleDeleteWebhook = (code) => {
        setState({
            confirmDelete: true,
            webhookToDelete: code,
        });
    };
    const onConfirmDelete = () => {
        props.onDeleteWebhook(state.webhookToDelete);
        resetState();
    };
    const resetState = () => {
        setState({ confirmDelete: false, webhookToDelete: '' });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, { size: 'small' },
            React.createElement(TableHead, { style: { cursor: 'pointer', backgroundColor: theme.palette.secondary.dark } },
                React.createElement(TableRow, null,
                    headCells.map((headCell) => !sortableCols.includes(headCell.id) ? (React.createElement(TableCell, { key: headCell.id }, headCell.label)) : (React.createElement(TableCell, { key: headCell.id, align: headCell.numeric ? 'right' : 'left', padding: headCell.disablePadding ? 'none' : 'normal', sortDirection: orderBy === headCell.id ? order : false },
                        React.createElement(TableSortLabel, { active: orderBy === headCell.id, direction: orderBy === headCell.id ? order : 'asc', onClick: event => handleRequestSort(event, headCell.id) },
                            headCell.label,
                            orderBy === headCell.id ? (React.createElement(Box, { component: 'span', sx: visuallyHidden }, order === 'desc' ? 'sorted descending' : 'sorted ascending')) : null)))),
                    React.createElement(TableCell, null))),
            React.createElement(TableBody, null,
                visibleRows.map((item) => (React.createElement(TableRow, { key: item.code, sx: { height: '50px' } },
                    React.createElement(TableCell, null, item.url),
                    React.createElement(TableCell, null, item.code),
                    React.createElement(TableCell, null,
                        React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', cursor: 'pointer' }, gap: 1 },
                            React.createElement(CopyValue, { value: item.public_key }),
                            React.createElement(Tooltip, { title: item.public_key },
                                React.createElement(TruncateLeft, null, item.public_key)))),
                    React.createElement(TableCell, null,
                        React.createElement(DateFormat, { date: item.created_at })),
                    React.createElement(TableCell, null,
                        React.createElement(Button, { color: 'error', onClick: () => handleDeleteWebhook(item.code) }, "Delete"))))),
                emptyRows > 0 && (React.createElement(TableRow, { style: {
                        height: 50 * emptyRows,
                    } },
                    React.createElement(TableCell, { colSpan: 5 }))))),
        React.createElement(TablePagination, { rowsPerPageOptions: [5, 10, 25], component: 'div', count: props.webhooks.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage }),
        React.createElement(ConfirmationDialog, { open: state.confirmDelete, title: 'Delete webhook', content: 'Are you sure you want to delete webhook?', onClose: () => resetState(), onConfirm: () => onConfirmDelete() })));
};
export default WebhooksTable;
