import React, { Fragment, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import CancelSeveralForm from './CancelSeveralForm';
import { Dialog, DialogContent } from '@mui/material';
import DialogHeader from '../../../../../../shared/components/DialogHeader';
const CancelSeveralModal = ({ open, theme, toggleFunc, cancelSeveralOrders, }) => {
    const [colored] = useState(false);
    const [header] = useState(true);
    const [color] = useState('primary');
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });
    const backdropTrue = true;
    return (React.createElement(Fragment, null,
        React.createElement(Dialog, { fullWidth: true, maxWidth: 'md', open: open },
            React.createElement(DialogHeader, { title: 'Please, specify orders you want to cancel!', toggleFunc: toggleFunc }),
            React.createElement(DialogContent, null,
                React.createElement(CancelSeveralForm, { cancelSeveralOrders: cancelSeveralOrders, toggleFunc: toggleFunc })))));
};
const mapStateToProps = (state) => ({
    theme: state.theme,
});
export default connect(mapStateToProps)(CancelSeveralModal);
