import styled from 'styled-components';
// eslint-disable-next-line import/prefer-default-export
export const CollapsibleButton = styled.button `
  padding-bottom: 10px;
  padding-top: 12px;
  margin: 0;
  cursor: pointer;
  position: relative;
  background: transparent;
  display: block;
  width: 100%;
  border: none;
  
  > svg {
    position: initial;
    margin-top: -2px;
  }
  > span {
    margin-left: 10px;
  }
`;
export const TextUppercase = styled.span `
  text-transform: uppercase;
`;
export const ThThinner = styled.th `
  font-weight: 400;
`;
export const LiveIndicator = styled.div `
  /* Oval: */
  background: #6DE4BB;
  width: 6px;
  height: 6px;
  border-radius: 32px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: -1px; 
`;
