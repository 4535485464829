import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import Messages from '../../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import CustodyClientWithdrawalsService from '../../../../../../services/custodyWithdrawalsService';
export const WithdrawalDelete = ({ item, clientCode, fetchAllWithDrawals, toggleDialog, }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleDelete = (code) => {
        const service = new CustodyClientWithdrawalsService({
            url: `/custody/withdrawals/${code}`, method: 'delete',
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            fetchAllWithDrawals(clientCode);
            showNotification({
                message: 'Address successfully removed',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleDialog();
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_REMOVE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggleDialog();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(Box, null,
            "Are you sure you want to delete Withdrawal:\u00A0",
            React.createElement("span", { className: "forms_uuid_container" }, item.address)),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(Button, { color: "primary", variant: "contained", onClick: () => handleDelete(item.code) }, "Yes"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggleDialog() }, "No"))));
};
export const WithdrawalMasterSign = ({ item, clientCode, fetchAllWithDrawals, toggleDialog, actionKey, }) => {
    const [twoFA, setTwoFA] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onTwoFAChange = (evt) => {
        const { value } = evt.target;
        setTwoFA(value);
    };
    const handleMasterSign = (totp, code) => {
        const payload = {
            code,
            totp_code: totp,
        };
        const service = new CustodyClientWithdrawalsService({
            url: `/custody/withdrawals/${actionKey}`, method: 'post', data: payload,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            fetchAllWithDrawals(clientCode);
            showNotification({
                message: 'Withdrawal successfully signed',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleDialog();
        })
            .catch((err) => {
            setLoading(false);
            const error = actionKey === 'sign'
                ? Messages.WITHDRAWAL_ADDRESSES_SIGN : Messages.WITHDRAWAL_ADDRESSES_MASTER_SIGN;
            const message = composeErrorMessage(err, error);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggleDialog();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(Box, null,
            "You're approving to withdrawal: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.address)),
        React.createElement(TextField, { name: "Two FA", value: twoFA, label: "Two FA", onChange: onTwoFAChange }),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(Button, { color: "primary", variant: "contained", disabled: !twoFA || twoFA.length !== 6, onClick: () => handleMasterSign(twoFA, item.code) }, "Send"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggleDialog() }, "Close"))));
};
