import React, { useReducer, useEffect } from 'react';
import buildUrl from 'build-url';
import { connect } from 'react-redux';
import { ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE, TRADING_TRANSFERS_URL_OPTIONS_SET_UPDATE, } from '../../../../../redux/actionTypes/adminActionTypes';
import ManageFiltersContent from './TransfersFiltersContent';
import { collectParamsFromFilters } from '../../../../../shared/helpers/createUrlFromParams';
/* eslint-disable prefer-destructuring, @typescript-eslint/prefer-regexp-exec,
  @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["ADD"] = "ADD";
    FilterActionType["RESET"] = "RESET";
    FilterActionType["DELETE"] = "DELETE";
})(FilterActionType || (FilterActionType = {}));
export const UrlOptionsUpdate = (url) => ({
    type: TRADING_TRANSFERS_URL_OPTIONS_SET_UPDATE,
    urlOptionsTradingTransfers: url,
});
export const pageUpdate = (page) => ({
    type: ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsAdminOrders: page,
});
const init = () => ({
    selectedFilters: [],
});
const reducer = (state, action) => {
    switch (action.type) {
        case FilterActionType.ADD:
            return { selectedFilters: [...state.selectedFilters, action.item] };
        case FilterActionType.DELETE:
            return {
                selectedFilters: state.selectedFilters
                    .filter(f => f.label !== action.item.label),
            };
        case FilterActionType.RESET:
            return init();
        default:
            return state;
    }
};
const TransfersFilters = ({ users, clients, features, customers, clientData, toggleModal, rowsPerPage, currentPage, masterTenant, clientFilters, sortDirection, setCurrentPage, initialValue = '', setClientFilters, UrlOptionsUpdateDispatch, }) => {
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const buildUrlFromParams = (params, page = 1, selectedAdminClient = null, limit, dir) => {
        const paramsNames = Object.keys(params);
        const urlOptions = {
            queryParams: {
                page: `${page}`,
                limit: `${limit}`,
                sort_by: 'created',
                sort_direction: dir,
            },
        };
        if (selectedAdminClient) {
            urlOptions.queryParams.client_code = selectedAdminClient.value;
        }
        paramsNames.forEach((key) => {
            if (Array.isArray(params[key])) {
                if (params[key].length === 1) {
                    urlOptions.queryParams[key] = params[key].join('');
                }
                else {
                    urlOptions.queryParams[key] = params[key].join('|');
                }
            }
        });
        return buildUrl('', urlOptions);
    };
    const buildUrlOptions = (page, perPage, dir) => {
        const collectedParams = collectParamsFromFilters(state.selectedFilters);
        const adminFilterUrl = buildUrlFromParams(collectedParams, page, clientData, perPage, dir);
        return adminFilterUrl;
    };
    const addSelectedFilter = (val) => {
        const isFilter = state.selectedFilters.find(f => f.label === val.label);
        const isType = state.selectedFilters.find(f => f.name === 'type');
        const isClient = state.selectedFilters.find(f => f.name === 'client_code');
        const isCreator = state.selectedFilters.find(f => f.name === 'creator_code');
        const isCurrency = state.selectedFilters.find(f => f.name === 'currency_code');
        const isCustomer = state.selectedFilters.find(f => f.name === 'customer_code');
        if (isFilter) {
            dispatch({ type: FilterActionType.DELETE, item: isFilter });
        }
        if (isType && val.name === 'type') {
            dispatch({ type: FilterActionType.DELETE, item: isType });
        }
        if (isCurrency && val.name === 'currency_code') {
            dispatch({ type: FilterActionType.DELETE, item: isCurrency });
        }
        if (isCustomer && val.name === 'customer_code') {
            dispatch({ type: FilterActionType.DELETE, item: isCustomer });
        }
        if (isCreator && val.name === 'creator_code') {
            dispatch({ type: FilterActionType.DELETE, item: isCreator });
        }
        if (isClient && val.name === 'client_code') {
            dispatch({ type: FilterActionType.DELETE, item: isClient });
        }
        dispatch({ type: FilterActionType.ADD, item: val });
    };
    const removeSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.DELETE, item: val });
    };
    const resetFilters = () => {
        dispatch({ type: FilterActionType.RESET });
    };
    useEffect(() => {
        UrlOptionsUpdateDispatch(buildUrlOptions(1, rowsPerPage, sortDirection));
        setCurrentPage(0);
    }, [state, clientData, rowsPerPage, sortDirection]);
    useEffect(() => {
        UrlOptionsUpdateDispatch(buildUrlOptions(currentPage + 1, rowsPerPage, sortDirection));
    }, [currentPage, rowsPerPage, sortDirection]);
    return (React.createElement(ManageFiltersContent, { users: users, state: state, clients: clients, features: features, customers: customers, toggleModal: toggleModal, masterTenant: masterTenant, resetFilters: resetFilters, clientFilters: clientFilters, setClientFilters: setClientFilters, addSelectedFilter: addSelectedFilter, removeSelectedFilter: removeSelectedFilter }));
};
const mapDispatchToProps = dispatch => ({
    initialValue: '',
    UrlOptionsUpdateDispatch: (url) => dispatch(UrlOptionsUpdate(url)),
});
export default connect(null, mapDispatchToProps)(TransfersFilters);
