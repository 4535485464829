import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import copy from 'copy-to-clipboard';
import { Box, Checkbox, Icon } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { useResizeDetector } from 'react-resize-detector';
import { TransfersCellItem } from '../../../Custody/styling/style';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
const Main = ({ value, columnKey, item }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    const [icon, setIcon] = useState('file_copy');
    const handleCopy = (id) => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(id);
    };
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (columnKey === 'code') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            React.createElement(Box, { display: 'flex', flexDirection: 'row', gap: 1 },
                React.createElement(Box, null,
                    React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)),
                React.createElement(Box, null, value ? (React.createElement(Icon, { onClick: () => handleCopy(`${value}`), fontSize: "small" }, icon)) : null))));
    }
    if (columnKey === 'test_net') {
        const idKey = shortid.generate();
        return (React.createElement(TableCell, { align: "center" },
            React.createElement("div", { className: "form__form-group" },
                React.createElement("label", { htmlFor: idKey, className: "checkbox-btn", style: { marginBottom: '15px' } },
                    React.createElement(Checkbox, { disabled: true, id: idKey, name: columnKey, checked: value, className: "checkbox-btn__checkbox" })))));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default Main;
