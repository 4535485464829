import React from 'react';
import { parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';
import DateFnsAdapter from '@date-io/date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { Checkbox, FormControlLabel, Radio, TextField } from '@mui/material';
import InputNumber from '../../../../../../shared/components/InputNumber';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
const SettingItem = ({ setting }) => {
    var _a;
    const dispatch = useDispatch();
    const dateFns = new DateFnsAdapter();
    const handleFieldChange = (evt) => {
        const { value, id, checked, type, } = evt.target;
        const settingValue = type === 'checkbox' ? checked : value;
        const payload = {
            settingId: id,
            settingValue,
        };
        dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
    };
    const handleRadioChange = (evt, id) => {
        const payload = {
            settingId: id,
            settingValue: evt.target.value === 'All' ? '' : evt.target.value,
        };
        dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
    };
    const handleDateChange = (date, id) => {
        let payload;
        if (date && dateFns.isValid(date)) {
            payload = {
                settingId: id,
                settingValue: new Date(date).toISOString(),
            };
        }
        else {
            payload = {
                settingId: id,
                settingValue: null,
            };
        }
        dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
    };
    const handleNumberFieldChange = (evt) => {
        const re = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
        const { value, id } = evt.target;
        if (isNaN(Number(evt.target.value))) {
            return;
        }
        const payload = {
            settingId: id,
            settingValue: re.exec(value),
        };
        dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
    };
    switch (setting.type) {
        case 'checkbox':
            return (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { key: setting.id, id: setting.id, value: setting.value, onChange: handleFieldChange, checked: setting.value, name: setting.id }), label: setting.title }));
        case 'radio':
            return (React.createElement(React.Fragment, null, (_a = setting.options) === null || _a === void 0 ? void 0 : _a.map((o, i) => (React.createElement(FormControlLabel, { key: i, label: o.label, control: React.createElement(Radio, { checked: o.value === setting.value, onChange: event => handleRadioChange(event, setting.id), value: o.value, name: setting.id }) })))));
        case 'text':
            return (React.createElement(TextField, { key: setting.id, name: setting.id, onChange: handleFieldChange, label: setting.title, value: setting.value }));
        case 'number':
            return (React.createElement(InputNumber, { key: setting.id, name: setting.id, onChange: handleNumberFieldChange, label: setting.title, value: setting.value }));
        case 'date':
            return (React.createElement("div", { key: setting.id },
                React.createElement(DatePicker, { slotProps: {
                        actionBar: {
                            actions: ['clear', 'cancel', 'today']
                        },
                        textField: { size: 'small' }
                    }, format: "dd/MM/yyyy", label: setting.title, value: setting.value ? parseISO(setting.value) : null, onChange: date => handleDateChange(date, setting.id) })));
        default:
            return null;
    }
};
export default SettingItem;
