import * as yup from 'yup';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputAdornment, TextField, Tooltip, Typography, useTheme, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import showNotification from '../../../../../shared/helpers/notifications';
import Messages from '../../../../../shared/helpers/errorMessages';
import { connect } from 'react-redux';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { CUSTOMER_SEARCH_SET_CLIENT, } from '../../../../../redux/actionTypes/customerSearchActionsTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import { Label } from '../../../../../shared/components/styled/labels';
import { IdCell } from '../../../../../shared/components/styled/tableCells';
import Code from '../../../../../shared/components/ui/Code';
import DateTime from '../../../../../shared/components/ui/DateTime';
import { DECIMAL_REGEX } from '../../../../../shared/regex/decimal';
const setCustomersClient = (client) => ({
    type: CUSTOMER_SEARCH_SET_CLIENT,
    selectedClient: client,
});
const SettlementLineDetails = (props) => {
    const formSchema = yup.object({
        capacity: yup
            .string()
            .matches(DECIMAL_REGEX)
            .required(),
    });
    const { handleSubmit, register, trigger, setValue, formState: { isValid, isDirty, errors }, } = useForm({
        resolver: yupResolver(formSchema),
    });
    const theme = useTheme();
    const history = useHistory();
    const params = useParams();
    const [settlementLine, setSettlementLine] = useState();
    const [clientName, setClientName] = useState();
    const [customerName, setCustomerName] = useState();
    const getCustomer = (id) => {
        return axios
            .get(`/customers/${id}`, { params: { details: true } })
            .then((c) => setCustomerName(c.data.company_name))
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.SETTLEMENT_LINE_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
            });
            return e;
        });
    };
    const getSettlmentLine = (id) => {
        return axios
            .get(`/settlement_line/${id}`, { baseURL: getEnvSettings().adminApiUrl })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.SETTLEMENT_LINE_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
            });
            return e;
        });
    };
    const updtateSettlement = (id, params) => {
        return axios
            .patch(`/settlement_line/${id}`, params, {
            baseURL: getEnvSettings().adminApiUrl,
        })
            .then((response) => response.data)
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.SETTLEMENT_LINE_UPDATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
            });
        });
    };
    const handleClose = () => {
        history.push('/settings/settlement-lines', { shouldUpdate: true });
    };
    useEffect(() => {
        getSettlmentLine(params.id).then((res) => {
            setSettlementLine(res.data);
            setValue('capacity', res.data.capacity);
            trigger('capacity');
        });
    }, []);
    const onCapacityUpdate = (newValue) => {
        if (!settlementLine)
            return;
        updtateSettlement(settlementLine.code, { capacity: newValue }).then((res) => {
            if (res) {
                setSettlementLine(res);
                showNotification({
                    message: 'Settlement line has been updated',
                    color: 'success'
                });
            }
        });
    };
    useEffect(() => {
        var _a;
        if (!settlementLine || props.clients.length === 0)
            return;
        getCustomer(settlementLine.customer_code);
        const client = (_a = props.clients.find((c) => c.value === (settlementLine === null || settlementLine === void 0 ? void 0 : settlementLine.client_code))) === null || _a === void 0 ? void 0 : _a.label;
        setClientName(client);
    }, [settlementLine, props.clients]);
    return (React.createElement(Dialog, { open: true, maxWidth: 'md', fullWidth: true }, settlementLine && (React.createElement(React.Fragment, null,
        React.createElement(DialogTitle, { sx: { backgroundColor: theme.palette.secondary.light } },
            React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between' } },
                "Settlement Line",
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 1 },
                    React.createElement(Chip, { color: 'secondary', label: React.createElement(IdCell, null, settlementLine.code) }),
                    React.createElement(CopyValue, { value: settlementLine.code })))),
        React.createElement(DialogContent, null,
            React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' }, gap: 4, p: 3 },
                React.createElement(Box, { sx: { display: 'flex' }, gap: 2 },
                    React.createElement(Box, { sx: {
                            width: '100%',
                            maxWidth: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        } },
                        React.createElement(Typography, null, "Details")),
                    React.createElement(Box, null,
                        React.createElement(Divider, { orientation: 'vertical' })),
                    React.createElement(Box, { sx: { display: 'flex', justifyContent: 'space-between', flex: 'auto' } },
                        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', maxWidth: 160 } },
                            React.createElement(Label, null, "Client"),
                            React.createElement(Box, { sx: { height: theme.spacing(3) } },
                                React.createElement(Tooltip, { title: clientName },
                                    React.createElement(Typography, { noWrap: true }, clientName))),
                            React.createElement(Code, { code: settlementLine.client_code })),
                        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column', maxWidth: 160 } },
                            React.createElement(Label, null, "Customer"),
                            React.createElement(Box, { sx: { height: theme.spacing(3) } },
                                React.createElement(Tooltip, { title: customerName },
                                    React.createElement(Typography, { noWrap: true }, customerName))),
                            React.createElement(Code, { code: settlementLine.client_code })),
                        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(Label, null, "Created"),
                            React.createElement(DateTime, { date: settlementLine.created_at })),
                        React.createElement(Box, { sx: { display: 'flex', flexDirection: 'column' } },
                            React.createElement(Label, null, "Updated"),
                            React.createElement(DateTime, { date: settlementLine.updated_at })))),
                React.createElement(Box, { sx: { display: 'flex' }, gap: 2 },
                    React.createElement(Box, { sx: {
                            width: '100%',
                            maxWidth: 60,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                        } },
                        React.createElement(Typography, null, "Actions")),
                    React.createElement(Box, null,
                        React.createElement(Divider, { orientation: 'vertical' })),
                    React.createElement(Box, { component: 'form', onSubmit: handleSubmit((data) => onCapacityUpdate(data.capacity)), sx: { display: 'flex' }, gap: 2 },
                        React.createElement(Box, { sx: { display: 'flex', flex: 'auto', maxWidth: 160 } },
                            React.createElement(TextField, Object.assign({ InputLabelProps: { shrink: true }, error: !!errors.capacity, size: 'small', label: 'Capacity', placeholder: 'Amount', InputProps: {
                                    endAdornment: (React.createElement(InputAdornment, { position: 'end' }, settlementLine.currency.toUpperCase())),
                                } }, register('capacity', { valueAsNumber: true }), { onChange: (e) => {
                                    setValue('capacity', e.target.value, { shouldDirty: true });
                                    trigger('capacity');
                                } }))),
                        React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 2 },
                            React.createElement(Button, { disabled: !(isDirty && isValid), type: 'submit', variant: 'contained', size: 'small' }, "Update"),
                            React.createElement(Button, { disabled: !isDirty, color: 'inherit', variant: 'text', size: 'small', onClick: () => {
                                    setValue('capacity', settlementLine.capacity);
                                    trigger('capacity');
                                } }, "Cancel")))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: 'inherit', onClick: () => handleClose() }, "Close"))))));
};
const mapStateToProps = (state) => {
    const mappedCustomers = getCustomersSelectedInputInterface(state).map((c) => ({
        value: c.value,
        label: c.label,
    }));
    const mappedClients = state.clientSearch.allClients.map((c) => ({
        value: c.code,
        label: c.company_name,
    }));
    return {
        customers: mappedCustomers,
        clients: mappedClients,
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchClientSelect: (client) => dispatch(setCustomersClient(client)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SettlementLineDetails);
