import React, { useEffect, useState, useRef } from 'react';
import shortid from 'shortid';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { connect } from 'react-redux';
import RowConsolidated from './RowConsolidated';
import RowSingleExchange from './RowSingleExchange';
import Subscriber from '../../../../../../shared/helpers/subscriber';
import { getExchangesOptions } from '../../../../../../redux/selectors/selectors';
import { TableContainer } from '@mui/material';
const getLastTrade = (data, channel) => {
    const { payload } = data;
    // Narrow down to LastTradeData type
    // or return undefined
    if (Array.isArray(payload)) {
        if (channel) {
            return {
                [channel]: payload[0],
            };
        }
        return payload[0];
    }
    return undefined;
};
function getOrderBook(data, channel) {
    const { payload } = data;
    // Narrow down to OrderBookData type
    // or return undefined
    if ('total_ask' in payload) {
        if (channel) {
            return {
                [channel]: {
                    bid: payload.bids[0],
                    ask: payload.asks[0],
                    allAsks: payload.asks,
                    totalAsk: payload.total_ask,
                },
            };
        }
        return {
            bid: payload.bids[0],
            ask: payload.asks[0],
            allAsks: payload.asks,
            totalAsk: payload.total_ask,
        };
    }
    return undefined;
}
const sortExchangesPerCode = (arr) => {
    const sorter = (a, b) => (a > b) ? 1 : -1;
    const sortFunc = (a, b, key) => {
        const k = key;
        let valueA;
        let valueB;
        if (k) {
            valueA = a[k];
            valueB = b[k];
            if (valueA && valueB) {
                // value is of a string type
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return sorter(valueA, valueB);
                }
            }
        }
        // or maybe throw exception
        return 0;
    };
    return arr.sort((a, b) => sortFunc(a, b, 'exchange_code'));
};
const MarketItem = ({ pairItem, exchanges, exchangesOptions, }) => {
    const refId = useRef(shortid.generate());
    const pair = `${pairItem.base}/${pairItem.quote}`;
    let sortedExchanges = [];
    // trades
    const [lastTradesSingle, setLastTradesSingle] = useState(null);
    const [lastTradeConsolidated, setLastTradeConsolidated] = useState(null);
    // order books
    const [orderBookSingle, setOrderBookSingle] = useState(null);
    const [orderBookConsolidated, setOrderBookConsolidated] = useState(null);
    const setUpdatesLastTradesSingle = (data) => {
        const trade = getLastTrade(data, data.channel);
        if (typeof trade !== 'undefined') {
            setLastTradesSingle(prevItems => (Object.assign(Object.assign({}, prevItems), trade)));
        }
    };
    const setUpdatesLastTradesConsolidated = (data) => {
        const trade = getLastTrade(data);
        setLastTradeConsolidated(trade);
    };
    const setUpdatesOrderBookSingle = (data) => {
        const orderBook = getOrderBook(data, data.channel);
        setOrderBookSingle(prevItems => (Object.assign(Object.assign({}, prevItems), orderBook)));
    };
    const setUpdatesConsolidatedOrderBook = (data) => {
        const orderBook = getOrderBook(data);
        setOrderBookConsolidated(orderBook);
    };
    useEffect(() => {
        const { OrderBookWS } = window;
        const pairData = `${pairItem.base}/${pairItem.quote}`;
        const tradeChannel = `trade-consolidated-${pairData.toLowerCase()}`;
        const channel = `orderbook-simple-consolidated-${pairData.toLowerCase()}`;
        const consolidatedOrderBook = new Subscriber(setUpdatesConsolidatedOrderBook, refId.current, 200);
        const consolidatedLastTrade = new Subscriber(setUpdatesLastTradesConsolidated, refId.current, 150);
        const singleOrderBook = new Subscriber(setUpdatesOrderBookSingle, refId.current, 200);
        const singleLastTrade = new Subscriber(setUpdatesLastTradesSingle, refId.current, 150);
        OrderBookWS.addSubscription(channel, consolidatedOrderBook);
        OrderBookWS.addSubscription(tradeChannel, consolidatedLastTrade);
        if (exchanges) {
            exchanges.forEach((marketItem) => {
                const singleExchangeTrade = `trade-${marketItem.exchange_code}-${pairData.toLowerCase()}`;
                const singleExchangeOrderbook = `orderbook-simple-${marketItem.exchange_code}-${pairData.toLowerCase()}`;
                OrderBookWS.addSubscription(singleExchangeTrade, singleLastTrade);
                OrderBookWS.addSubscription(singleExchangeOrderbook, singleOrderBook);
            });
        }
        // Cleanup all subscriptions
        return () => {
            OrderBookWS.removeSubscription(channel, refId.current);
            OrderBookWS.removeSubscription(tradeChannel, refId.current);
            if (exchanges) {
                exchanges.forEach((marketItem) => {
                    const singleExchangeTrade = `trade-${marketItem.exchange_code}-${pairData.toLowerCase()}`;
                    const singleExchangeOrderbook = `orderbook-simple-${marketItem.exchange_code}-${pairData.toLowerCase()}`;
                    OrderBookWS.removeSubscription(singleExchangeTrade, refId.current);
                    OrderBookWS.removeSubscription(singleExchangeOrderbook, refId.current);
                });
            }
        };
    }, [pairItem]);
    if (lastTradesSingle && exchanges && exchanges.length) {
        sortedExchanges = sortExchangesPerCode(exchanges);
    }
    return (React.createElement(TableContainer, null,
        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table", className: "market_overview_bg_color" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { className: "th_thinner" }, "Exchange"),
                    React.createElement(TableCell, { className: "th_thinner" }, "Last trade"),
                    React.createElement(TableCell, { className: "th_thinner" }, "Total"),
                    React.createElement(TableCell, { className: "th_thinner" }, "Value Cml. BidSize"),
                    React.createElement(TableCell, { className: "th_thinner" }, "Cml. BidSize"),
                    React.createElement(TableCell, { className: "th_thinner" }, "BidSize"),
                    React.createElement(TableCell, { className: "th_thinner" }, "Bid"),
                    React.createElement(TableCell, { className: "th_thinner" }, "Ask"),
                    React.createElement(TableCell, { className: "th_thinner" }, "AskSize"),
                    React.createElement(TableCell, { className: "th_thinner" }, "Cml. AskSize"),
                    React.createElement(TableCell, { className: "th_thinner" }, "Value Cml. AskSize"))),
            React.createElement(TableBody, null,
                orderBookConsolidated && lastTradeConsolidated
                    ? (React.createElement(RowConsolidated, { orderBookConsolidated: orderBookConsolidated, lastTradeConsolidated: lastTradeConsolidated }))
                    : null,
                sortedExchanges.map((marketItem, i) => (React.createElement(RowSingleExchange, { key: i, pair: pair, marketItem: marketItem, orderBookSingle: orderBookSingle, lastTradesSingle: lastTradesSingle, exchangesOptions: exchangesOptions })))))));
};
const stateToProps = (state) => ({
    exchangesOptions: getExchangesOptions(state),
});
export default connect(stateToProps)(MarketItem);
