export const SCHEMA_WORKING_RESET = 'SCHEMA_WORKING_RESET';
export const SCHEMA_WORKING_RESET_APPLIED = 'SCHEMA_WORKING_RESET_APPLIED';
export const SCHEMA_APPLY_ALL_SETTINGS = 'SCHEMA_APPLY_ALL_SETTINGS';
export const SCHEMA_WORKING_SET_UPDATE = 'SCHEMA_WORKING_SET_UPDATE';
export const SCHEMA_WORKING_SET_UPDATE_SUCCESS = 'SCHEMA_WORKING_SET_UPDATE_SUCCESS';
export const SCHEMA_WORKING_SET_UPDATE_FAIL = 'SCHEMA_WORKING_SET_UPDATE_FAIL';
export const SCHEMA_WORKING_REQUEST = 'SCHEMA_WORKING_REQUEST';
export const SCHEMA_ADD_WORKING = 'SCHEMA_ADD_WORKING';
export const SCHEMA_LOAD_SUCCESS = 'SCHEMA_LOAD_SUCCESS';
export const SCHEMA_SETTINGS_APPLIED = 'SCHEMA_SETTINGS_APPLIED';
export const SCHEMA_APPLY_WORKSPACE = 'SCHEMA_APPLY_WORKSPACE';
export const SCHEMA_APPLY_WORKSPACE_SETTINGS = 'SCHEMA_APPLY_WORKSPACE_SETTINGS';
export const SCHEMA_CLONE_WIDGET = 'SCHEMA_CLONE_WIDGET';
export const SCHEMA_REMOVE_WIDGET = 'SCHEMA_REMOVE_WIDGET';
export const SCHEMA_UPDATE_LAYOUT_PROPS = 'SCHEMA_UPDATE_LAYOUT_PROPS';
