/* eslint-disable import/prefer-default-export */
export const ADMIN_ORDERS_FETCH_REQUEST = 'ADMIN_ORDERS_FETCH_REQUEST';
export const ADMIN_ORDERS_FETCH_SUCCESS = 'ADMIN_ORDERS_FETCH_SUCCESS';
export const ADMIN_ORDERS_FETCH_FAIL = 'ADMIN_ORDERS_FETCH_FAIL';
export const INVITES_FETCH_REQUEST = 'INVITES_FETCH_REQUEST';
export const INVITES_FETCH_SUCCESS = 'INVITES_FETCH_SUCCESS';
export const INVITES_FETCH_FAIL = 'INVITES_FETCH_FAIL';
export const ROWS_PER_PAGE__INVITES_SET_UPDATE = 'ROWS_PER_PAGE__INVITES_SET_UPDATE';
export const CHANGED_PERMISSIONS_SET_UPDATE = 'CHANGED_PERMISSIONS_SET_UPDATE';
export const ADMIN_SELECT_CLIENT_FROM_USERS = 'ADMIN_SELECT_CLIENT_FROM_USERS';
export const ADMIN_ORDERS_URL_OPTIONS_SET_UPDATE = 'ADMIN_ORDERS_URL_OPTIONS_SET_UPDATE';
export const ADMIN_USERS_PAGE_OF_ITEMS_SET_UPDATE = 'ADMIN_USERS_PAGE_OF_ITEMS_SET_UPDATE';
export const ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE = 'ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE';
export const ADMIN_INVITES_PAGE_OF_ITEMS_SET_UPDATE = 'ADMIN_INVITES_PAGE_OF_ITEMS_SET_UPDATE';
export const ADMIN_DEPOSITS_UPDATE = 'ADMIN_DEPOSITS_UPDATE';
export const ADMIN_WITHDRAWALS_UPDATE = 'ADMIN_WITHDRAWALS_UPDATE';
export const ADMIN_DEPOSITS_LOADING_UPDATE = 'ADMIN_DEPOSITS_LOADING_UPDATE';
export const ADMIN_WITHDRAWALS_LOADING_UPDATE = 'ADMIN_WITHDRAWALS_LOADING_UPDATE';
export const ADMIN_APPROVALS_WITHDRAWALS_UPDATE = 'ADMIN_APPROVALS_WITHDRAWALS_UPDATE';
export const LOADING_ADDRESSES_UPDATE = 'LOADING_ADDRESSES_UPDATE';
export const ADMIN_WITHDRAWAL_ADDRESSES_UPDATE = 'ADMIN_WITHDRAWAL_ADDRESSES_UPDATE';
export const COMPLIANCE_WALLETS_UPDATE = 'COMPLIANCE_WALLETS_UPDATE';
export const ADMIN_SUB_ORDERS_UPDATE = 'ADMIN_SUB_ORDERS_UPDATE';
export const ADMIN_REGULAR_ORDERS_UPDATE = 'ADMIN_REGULAR_ORDERS_UPDATE';
export const ADMIN_LOADING_SUB_ORDERS_UPDATE = 'ADMIN_LOADING_SUB_ORDERS_UPDATE';
export const ADMIN_LINE_CHART_DATA = 'ADMIN_LINE_CHART_DATA';
export const ADMIN_LOADING_REGULAR_ORDERS_UPDATE = 'ADMIN_LOADING_REGULAR_ORDERS_UPDATE';
export const PERMISSIONS_ACTIVETAB_UPDATE = 'PERMISSIONS_ACTIVETAB_UPDATE';
export const ADMIN_CURRENCIES_UPDATE = 'ADMIN_CURRENCIES_UPDATE';
export const TRADING_TRANSFERS_URL_OPTIONS_SET_UPDATE = 'TRADING_TRANSFERS_URL_OPTIONS_SET_UPDATE';
