/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/restrict-template-expressions,
  no-useless-return */
import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import { useDispatch } from 'react-redux';
import List from '@mui/material/List';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box, CircularProgress } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { RevertDeposit, SignWithdrawal, DeclineWithdrawal, ApproveWithdrawal, } from './TransferActions';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../Custody/styling/style';
import TransfersCell from './TransfersCell';
import { BodyContainer } from '../../styling/style';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import TransfersCellActions from './TransfersCellActions';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
const TradingTransfersContent = ({ masterTenant, columns, transfersWidgetHeight, features, meCode, setRowsPerPage, onSortHandle, currentPage, setCurrentPage, users, clients, urlOptionsTradingTransfers, status, sortDirection, totalItems, fetchTransfers, transfersToRender, rowsPerPage, customers, clientInfo, sortBy, }) => {
    const [maxHeight, setMaxHeight] = useState(480);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const dispatch = useDispatch();
    const columnsOpen = Boolean(anchorColumns);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('tradingWithdrawals'));
    };
    const handlePageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const cellActions = {
        actions: {
            declineWithdrawal: () => {
                fetchTransfers(urlOptionsTradingTransfers);
            },
            approveWithdrawal: () => {
                fetchTransfers(urlOptionsTradingTransfers);
            },
            revertDeposit: () => {
                fetchTransfers(urlOptionsTradingTransfers);
            },
            signWithdrawal: () => {
                fetchTransfers(urlOptionsTradingTransfers);
            },
        },
        actionsComponents: {
            declineWithdrawal: DeclineWithdrawal,
            approveWithdrawal: ApproveWithdrawal,
            revertDeposit: RevertDeposit,
            signWithdrawal: SignWithdrawal,
        },
        actionsList: [
            { name: 'Decline', action: 'declineWithdrawal' },
            { name: 'Approve', action: 'approveWithdrawal' },
            { name: 'Revert', action: 'revertDeposit' },
            { name: 'Sign', action: 'signWithdrawal' },
        ],
    };
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(transfersWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [transfersWidgetHeight]);
    if (status === 'init' || status === 'fetching') {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(BodyContainer, null,
        React.createElement(WalletsClientContainer, null,
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        React.createElement(MaterialTableWrap, null,
            React.createElement(Paper, { className: "mui_table_root" },
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map((column) => {
                                    if (column.key === 'updated_at') {
                                        return (React.createElement(TableCell, { align: "left", key: column.key, style: { minWidth: column.minWidth } },
                                            React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)));
                                    }
                                    return (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: "left" }, column.title));
                                }),
                                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                        React.createElement(TableBody, null, transfersToRender.map(w => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? w[key] : undefined;
                                return (React.createElement(TransfersCell, { width: column.minWidth, key: i, keyOf: key, value: value, users: users, clients: clients, customers: customers }));
                            }),
                            React.createElement(TransfersCellActions, { item: w, meCode: meCode, features: features, actions: cellActions, clientInfo: clientInfo, masterTenant: masterTenant }))))))),
                React.createElement(TablePagination, { component: "div", count: totalItems, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: handlePageChange, rowsPerPageOptions: [maxHeight] })))));
};
export default TradingTransfersContent;
