import Decimal from 'decimal.js';
import { NEW_ORDER_AMOUNT_SET_UPDATE } from '../../../../../../redux/actionTypes/placeOrderActionTypes';
const calculateMarketPriceFromAmount = (amount, bids, asks, action, dispatch) => {
    let orders = [];
    if (action === 'Sell') {
        orders = bids;
    }
    else if (action === 'Buy') {
        orders = asks;
    }
    let calculatedAmount = new Decimal(0);
    let resTotal = new Decimal(0);
    for (const order of orders) {
        if (calculatedAmount.greaterThanOrEqualTo(amount)) {
            break;
        }
        const growth = calculatedAmount.plus(order.volume).lessThan(amount)
            ? new Decimal(order.volume)
            : new Decimal(amount).minus(calculatedAmount);
        resTotal = resTotal.plus(growth.mul(order.price));
        calculatedAmount = calculatedAmount.plus(growth);
    }
    if (calculatedAmount.lessThan(amount)) {
        dispatch({ type: NEW_ORDER_AMOUNT_SET_UPDATE, amount: calculatedAmount.toFixed() });
    }
    return resTotal.toFixed();
};
export default calculateMarketPriceFromAmount;
