import React, { useState } from 'react';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import MarketItem from './MarketItem';
import { Button, Collapse } from '@mui/material';
const CollapseMarketOverview = ({ className = '', pairItem, exchanges, }) => {
    const [collapse, setCollapse] = useState(false);
    const [status, setStatus] = useState('closed');
    const [icon, setIcon] = useState(React.createElement(PlusIcon, null));
    const onEntering = () => {
        setStatus('opening');
        setIcon(React.createElement(MinusIcon, null));
    };
    const onExiting = () => {
        setStatus('closing');
        setIcon(React.createElement(PlusIcon, null));
    };
    const toggle = () => {
        setCollapse(!collapse);
    };
    return (React.createElement("div", { onClick: toggle },
        React.createElement(Button, { type: "button" },
            icon,
            " ",
            React.createElement("span", { className: "market_overview_text_color" }, `${pairItem.base}/${pairItem.quote}`)),
        React.createElement(Collapse, { in: collapse, onExiting: onExiting, onEntering: onEntering, className: "collapse__content" },
            React.createElement("div", null, collapse ? React.createElement(MarketItem, { pairItem: pairItem, exchanges: exchanges }) : null))));
};
export default React.memo(CollapseMarketOverview);
