import React, { useCallback, useEffect, useMemo, useState, useContext, } from 'react';
import Decimal from 'decimal.js';
import Paper from '@mui/material/Paper';
import { Box, CircularProgress, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import buildUrl from 'build-url';
import { connect, useDispatch } from 'react-redux';
import HoldingsItem from './HoldingsItem';
import OrdersService from '../../../../../services/ordersService';
import groupByKey from '../../../../../shared/helpers/groupByKey';
import Messages from '../../../../../shared/helpers/errorMessages';
import { RatesContext } from '../../../../../providers/RatesProvider';
import showNotification from '../../../../../shared/helpers/notifications';
import CustodyWalletService from '../../../../../services/custodyWalletService';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import ClientBalancesService from '../../../../../services/clientBalancesService';
import { useServiceFetchApis } from '../../../../../shared/hooks/useServiceFetchApis';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
/* eslint-disable no-await-in-loop, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call */
const WidgetHoldings = ({ clientInfo }) => {
    const [total, setTotal] = useState(0);
    const [totalSub] = useState(0);
    const [clientCode, setClientCode] = useState('');
    const [wallets, setWallets] = useState([]);
    const [loadingAllOrders, setLoadingAllOrders] = useState(false);
    const [balances, setBalances] = useState([]);
    const [regularOrders, setRegularOrders] = useState([]);
    const [serviceBalance, setServiceBalance] = useState(null);
    const [holdingsDataToRender, setHoldingsDataToRender] = useState([]);
    const ctx = useContext(RatesContext);
    const serviceData = useServiceFetchApis(serviceBalance, clientCode, 50000);
    const memoBalances = useMemo(() => serviceData.data ? serviceData.data.balances : [], [serviceData]);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const groupByObjectKey = (objectArray, property) => objectArray
        .reduce((acc, obj) => {
        const key = obj.market_item && obj.market_item[property].toLowerCase();
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
    }, {});
    const getOrderExecutionPrice = (order, service) => {
        if (order.type === 'Limit' && order.executed.price === '0') {
            return order.market_item.quote === 'USD'
                ? order.limit_price : service.convertInBase('eurx', order.limit_price);
        }
        return order.market_item.quote === 'USD'
            ? order.executed.price : service.convertInBase('eurx', order.executed.price);
    };
    const getTradingRoi = (arr, serv) => arr
        .reduce((acc, order) => {
        const base = order.market_item.base.toLowerCase();
        const history = serv.convertHistoricInBase(base, order.created, '1') === 'Not convertible'
            ? '0' : serv.convertHistoricInBase(base, order.created, '1');
        const historyRate = new Decimal(history);
        const currentRate = new Decimal(serv.convertInBase(base, 1));
        const hundredPersent = new Decimal(100);
        if (order.action === 'Buy') {
            const buy = Decimal.add(acc, hundredPersent.mul(new Decimal(currentRate.minus(historyRate)).div(historyRate)));
            return new Decimal(buy).div(arr.length).toFixed(2);
        }
        const sell = Decimal.add(acc, hundredPersent.mul(new Decimal(historyRate.minus(currentRate)).div(currentRate)));
        return new Decimal(sell).div(arr.length).toFixed(2);
    }, '0');
    const getHoldingsData = (allOrders, allBalances, allWallets, serv) => {
        const holdingsData = [];
        if (allWallets && Object.keys(allWallets).length !== 0 && allWallets.constructor === Object) {
            for (const w in allWallets) {
                if (w) {
                    const availableCustodyBalances = allWallets[w].reduce((acm, item) => {
                        var _a;
                        return Decimal
                            .add(acm, ((_a = item.balance) === null || _a === void 0 ? void 0 : _a.available) || 0);
                    }, new Decimal(0));
                    holdingsData.push({
                        asset: w,
                        roiTrading: '',
                        roiCustody: '',
                        costTrading: '',
                        costCustody: '',
                        balanceTrading: '',
                        marketValueCustody: '',
                        balanceCustody: availableCustodyBalances.toFixed(),
                        marketValueTrading: serv.convertInBase(w, 1) === 'Not convertible'
                            ? '' : serv.convertInBase(w, 1),
                    });
                }
            }
        }
        if (allOrders && Object.keys(allOrders).length !== 0 && allOrders.constructor === Object) {
            for (const o in allOrders) {
                if (o) {
                    const tradingBalances = allBalances.find(b => b.code.toUpperCase() === o.toUpperCase());
                    const tradingPrice = allOrders[o]
                        .reduce((acm, item) => Decimal.add(acm, getOrderExecutionPrice(item, serv)), new Decimal(0));
                    const tradingCost = new Decimal(tradingPrice).div(allOrders[o].length);
                    const hasItem = holdingsData.find(h => h.asset.toUpperCase() === o.toUpperCase());
                    const hasItemIndex = holdingsData.findIndex(h => h.asset.toUpperCase() === o.toUpperCase());
                    hasItem ? holdingsData[hasItemIndex] = Object.assign({}, hasItem, {
                        assetTrading: o,
                        costTrading: tradingCost.toFixed(),
                        marketValueTrading: serv.convertInBase(o, 1),
                        roiTrading: getTradingRoi(allOrders[o], serv),
                        balanceTrading: tradingBalances ? tradingBalances.available : '',
                    }) : holdingsData.push({
                        asset: o,
                        roiCustody: '',
                        costCustody: '',
                        balanceCustody: '',
                        marketValueCustody: '',
                        costTrading: tradingCost.toFixed(),
                        marketValueTrading: serv.convertInBase(o, 1),
                        roiTrading: getTradingRoi(allOrders[o], serv),
                        balanceTrading: tradingBalances ? tradingBalances.available : '',
                    });
                }
            }
        }
        return holdingsData;
    };
    const requestFetchOrders = (page, client, isSuborder) => {
        const urlOptions = {
            queryParams: {
                page: `${page}`,
                limit: '1000',
                is_suborder: isSuborder.toString(),
                client_code: client ? client.code : '',
            },
        };
        const url = `/orders${buildUrl('', urlOptions)}`;
        const serviceInstance = new OrdersService({ url, method: 'get' });
        return serviceInstance.makeRequest();
    };
    const fetchAllWallets = (code) => {
        const walletService = new CustodyWalletService({
            url: `/custody/${code}/wallets?limit=1000`,
            method: 'get',
        });
        walletService.makeRequest()
            .then((data) => {
            setWallets(data.records);
        })
            .catch((e) => {
            setWallets([]);
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchHistoryRates = (records) => {
        const payload = records
            .reduce((res, o) => (Object.assign(Object.assign({}, res), { [o.market_item.base]: (res[o.market_item.base] || []).concat(o.created) })), {});
        // Get historic rates
        ctx.getHistoricRates(payload);
    };
    useEffect(() => {
        const serviceInstance = new ClientBalancesService({ url: '', method: 'get' }, '/trade/balances/clients/');
        setServiceBalance(serviceInstance);
    }, []);
    useEffect(() => {
        if (clientInfo) {
            fetchAllWallets(clientInfo.code);
            setClientCode(clientInfo.code);
            requestFetchOrders(1, clientInfo, false)
                .then((data) => {
                setTotal(data.total / 1000);
            })
                .catch((e) => {
                setRegularOrders([]);
                const message = composeErrorMessage(e, Messages.ORDERS_FETCH);
                showNotification({
                    message,
                    color: 'error',
                    dispatch: errorNotice,
                });
            });
        }
    }, [clientInfo]);
    useEffect(() => {
        if (clientInfo) {
            const allRequests = [];
            if (total) {
                let n = 0;
                while (n < total) {
                    n += 1;
                    allRequests.push(requestFetchOrders(n, clientInfo, false));
                }
            }
            if (totalSub) {
                let i = 0;
                while (i < totalSub) {
                    i += 1;
                    allRequests.push(requestFetchOrders(i, clientInfo, true));
                }
            }
            if (allRequests.length > 0) {
                setLoadingAllOrders(true);
                Promise.all(allRequests)
                    .then((results) => {
                    setLoadingAllOrders(false);
                    const ordersArr = [];
                    results.forEach((result) => ordersArr
                        .push(...result.records.filter(o => o.status === 'Filled' || o.status === 'CanceledPartiallyFilled')));
                    setRegularOrders(ordersArr);
                })
                    .catch((e) => {
                    setLoadingAllOrders(false);
                    setRegularOrders([]);
                    showNotification({
                        message: `error ${Messages.ORDERS_FETCH} ${e.message}`,
                        color: 'error',
                        dispatch: errorNotice,
                    });
                });
            }
        }
    }, [total, totalSub]);
    useEffect(() => {
        if ((balances === null || balances === void 0 ? void 0 : balances.length) && regularOrders.length > 0) {
            const filteredByDates = regularOrders.filter(r => new Date(r.created) > new Date('2020-12-28T16:03:06.247Z'));
            fetchHistoryRates(filteredByDates);
        }
    }, [balances, regularOrders]);
    useEffect(() => {
        if (memoBalances.length) {
            setBalances(memoBalances);
        }
    }, [memoBalances]);
    useEffect(() => {
        if (ctx.rates && ctx.historicRates && ctx.service) {
            const filteredByDates = regularOrders.filter(r => new Date(r.created) > new Date('2020-12-28T16:03:06.247Z'));
            const mappedWallets = groupByKey(wallets, 'currency_code');
            const mappedOrders = groupByObjectKey(filteredByDates, 'base');
            const holdingsData = getHoldingsData(mappedOrders, balances, mappedWallets, ctx.service);
            setHoldingsDataToRender(holdingsData);
        }
    }, [ctx.historicRates, ctx.rates, wallets, regularOrders, balances]);
    if (loadingAllOrders) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    if (wallets.length === 0 && regularOrders.length === 0) {
        return React.createElement("h5", null, "No items found");
    }
    return !loadingAllOrders && (React.createElement(Container, null,
        React.createElement(Box, { pt: 2 },
            React.createElement(Paper, null,
                React.createElement(TableContainer, { style: { maxHeight: 'calc(100vh - 80px)' } },
                    React.createElement(Table, { stickyHeader: true },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                React.createElement(TableCell, null, "Asset"),
                                React.createElement(TableCell, null, "Balance"),
                                React.createElement(TableCell, null, "Cost (USD)"),
                                React.createElement(TableCell, null, "Market value"),
                                React.createElement(TableCell, null, "ROI"))),
                        React.createElement(TableBody, null, holdingsDataToRender.map((h, i) => React.createElement(HoldingsItem, { key: i, item: h })))))))));
};
const mapStateToProps = (state) => ({
    clientInfo: state.client.clientInfo,
});
export default connect(mapStateToProps)(WidgetHoldings);
