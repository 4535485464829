import { Box, Button, Grid, MenuItem, TextField } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
const formSchema = yup
    .object({
    name: yup.string().required().default(''),
    type: yup
        .string()
        .required()
        .default('no_rate')
        .oneOf([
        'chain_link_ethereum',
        'chain_link_linea',
        'chain_link_polygon',
        'chain_link_optimism',
        'chain_link_arbitrum',
        'chain_link_palm',
        'chain_link_avalanche',
        'chain_link_starknet',
        'chain_link_celo',
        'chain_link_binance',
        'cryptocompare',
        'virtual_exchange',
        'no_rate',
    ]),
    contract_address: yup.string().default(''),
})
    .required();
const formConfig = {
    name: { label: 'Name', type: 'text', required: true },
    type: {
        label: 'Type',
        type: 'select',
        required: true,
        options: [
            'chain_link_ethereum',
            'chain_link_linea',
            'chain_link_polygon',
            'chain_link_optimism',
            'chain_link_arbitrum',
            'chain_link_palm',
            'chain_link_avalanche',
            'chain_link_starknet',
            'chain_link_celo',
            'chain_link_binance',
            'cryptocompare',
            'virtual_exchange',
            'no_rate',
        ],
    },
    contract_address: { label: 'Contract Address', type: 'text', required: false },
};
const PriceFeedCreate = (props) => {
    const { onClose, onSave, loading } = props;
    const { handleSubmit, control, register, trigger, setValue, formState: { errors }, } = useForm({
        defaultValues: formSchema.cast(formSchema.getDefault()),
        resolver: yupResolver(formSchema),
    });
    return (React.createElement(Box, { component: 'form', onSubmit: handleSubmit(onSave) },
        React.createElement(Grid, { container: true, spacing: 2, py: 2 }, Object.keys(formConfig).map((s, i) => {
            const key = s;
            const field = formConfig[key];
            return (React.createElement(Grid, { item: true, xs: 6, key: i },
                field.type === 'text' && (React.createElement(TextField, Object.assign({ fullWidth: true, InputLabelProps: { shrink: true }, error: !!errors[key], size: 'small', required: field.required, label: field.label, placeholder: field.label }, register(key), { onChange: (e) => {
                        setValue(key, e.target.value);
                        trigger(key);
                    } }))),
                field.type === 'select' && (React.createElement(Controller, { name: key, control: control, render: ({ field }) => {
                        var _a;
                        return (React.createElement(TextField, Object.assign({ fullWidth: true, select: true }, field, { size: 'small', error: !!errors[key], InputLabelProps: { shrink: true }, required: formConfig[key].required, label: formConfig[key].label, onChange: (e) => {
                                field.onChange(e.target.value);
                                setValue(key, e.target.value);
                                trigger(key);
                            } }), (_a = formConfig[key].options) === null || _a === void 0 ? void 0 : _a.map((o, i) => (React.createElement(MenuItem, { key: i, value: o }, o)))));
                    } }))));
        })),
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'flex-end', gap: 1 } },
            React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained' }, "Add"),
            React.createElement(Button, { onClick: onClose }, "Cancel"))));
};
export default PriceFeedCreate;
