import React, { useEffect, useState } from 'react';
import { Box, Chip, Button, Checkbox, Collapse, TextField, Autocomplete, FormControlLabel, } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { FiltersContainer, CustodyFilterPanelContainer, } from '../../../../Custody/styling/style';
const useStyles = makeStyles(() => createStyles({
    formGroup: { minWidth: 200 },
}));
const stateOptions = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
    { label: 'Unknown transaction', value: 'unknown_transaction' },
];
const WalletsFiltersContent = ({ state, hideZero, resetFilters, allCurrencies, handleStateChange, toggleZeroBalances, removeSelectedFilter, handleCurrencyChange, }) => {
    var _a;
    const classes = useStyles();
    const [collapse, setCollapse] = useState(false);
    const [status, setStatus] = useState(null);
    const [currency, setCurrency] = useState(null);
    const toggleCollapseFilter = () => {
        setCollapse(!collapse);
    };
    useEffect(() => {
        const objIndexCurrency = state.filters.findIndex((obj => obj.name === 'currency_code'));
        const currencyData = state.filters[objIndexCurrency];
        setCurrency(currencyData || null);
        const objIndexStatus = state.filters.findIndex((obj => obj.name === 'state'));
        const stateData = state.filters[objIndexStatus];
        setStatus(stateData || null);
    }, [state]);
    return (React.createElement(CustodyFilterPanelContainer, null,
        React.createElement(FiltersContainer, null,
            React.createElement(Box, { display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 },
                React.createElement(Box, null,
                    React.createElement(Button, { variant: "outlined", size: "small", color: "primary", onClick: toggleCollapseFilter, className: "btn-cancel-order square" },
                        collapse ? '-' : '+',
                        " Quick filters")),
                React.createElement(Box, null, state.filters.length ? (React.createElement(Button, { variant: "outlined", size: "small", type: "button", color: "inherit", onClick: resetFilters }, "Reset filters")) : null),
                React.createElement(Box, null,
                    React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { name: "hideZero", checked: hideZero, onChange: toggleZeroBalances }), label: "Hide zero balances" }))),
            ((_a = state.filters) === null || _a === void 0 ? void 0 : _a.length) ? (React.createElement(Box, { display: 'flex', flexWrap: 'wrap', gap: 1, pt: 1 }, state.filters.map((filter) => (React.createElement(Chip, { size: "small", color: "primary", key: filter.label, label: filter.label, style: { marginRight: '4px' }, onDelete: () => {
                    removeSelectedFilter(filter);
                    if (filter.name === 'state') {
                        setStatus(null);
                    }
                    else if (filter.name === 'currency_code') {
                        setCurrency(null);
                    }
                } }))))) : null),
        React.createElement(Collapse, { in: collapse },
            React.createElement("div", null,
                React.createElement(Box, { style: { display: 'flex' }, p: 1 },
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(Autocomplete, { loading: false, disableClearable: true, options: stateOptions, getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleStateChange(newValue), renderInput: (params) => React.createElement(TextField, Object.assign({ name: "state" }, params, { label: "State", variant: "outlined", value: {
                                    value: (status === null || status === void 0 ? void 0 : status.value) || '',
                                    label: 'State',
                                }, size: "small" })) })),
                    React.createElement(Box, { className: classes.formGroup, mr: 1 },
                        React.createElement(Autocomplete, { loading: false, disableClearable: true, options: allCurrencies, getOptionLabel: (option) => option.label, onChange: (event, newValue) => handleCurrencyChange(newValue), renderInput: (params) => React.createElement(TextField, Object.assign({ name: "currency_code" }, params, { label: "Currency", variant: "outlined", 
                                // value={currency?.value || ''}
                                value: {
                                    value: currency,
                                    label: 'Currency',
                                }, size: "small" })) })))))));
};
export default WalletsFiltersContent;
