import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import CustodyRoot from './WrappedRoutes/CustodyRoot';
import NotFound404 from '../../DefaultPage/404/index';
import TerminalRoot from './WrappedRoutes/TerminalRoot';
import { RatesProvider } from '../../../providers/RatesProvider';
import showNotification from '../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import { WORKSPACE_FETCH_REQUEST } from '../../../redux/actionTypes/workspaceActions';
import { USER_INFO_FETCH_REQUEST } from '../../../redux/actionTypes/userActionTypes';
import { getClientInfo } from '../../../redux/selectors/clientSelectors';
import { getEnvSettings } from '../../../config/environmentSettings';
import { usePrevious } from '../../../shared/hooks/usePrevious';
import OperationsRoot from './WrappedRoutes/OperationsRoot';
import SettingsRoot from './WrappedRoutes/SettingsRoot';
import AdminRoot from './WrappedRoutes/AdminRoot';
import ManageRoot from './WrappedRoutes/ManageRoot';
import Layout from '../../Layout';
import ReleaseNotes from '../../ReleaseNotes';
import { BalancesProvider } from '../../Balances/BalancesProvider';
const finoaEnv = getEnvSettings().env === 'finoa';
const Router = ({ features, customersLoading, clientsLoading, balancesWidget, masterTenant, dispatchFetchUserInfo, dispatchFetchAllWorkspaces, }) => {
    const location = useLocation();
    const prevLocation = usePrevious(location);
    const dispatch = useDispatch();
    const { OrderBookWS } = window;
    const clientInfo = useSelector(getClientInfo);
    useEffect(() => {
        if (!clientInfo && !customersLoading) {
            return;
        }
        loadWorkspace();
    }, [location, features, clientInfo]);
    useEffect(() => {
        if (!clientInfo) {
            dispatchFetchUserInfo();
        }
        OrderBookWS.authenticateWS();
    }, []);
    const loadWorkspace = () => {
        if (!features || features.length === 0) {
            return;
        }
        const { pathname } = window.location;
        if (pathname === '/dma') {
            if (features.includes('order.read')) {
                dispatchFetchAllWorkspaces('trading');
            }
            else {
                dispatchFetchAllWorkspaces('custody');
                showNotification({
                    message: 'Error: Not enough permissions',
                    color: 'error',
                    dispatch: () => dispatch({ type: CLOSE_ERROR_NOTICE }),
                });
            }
        }
        else if (pathname.includes('/custody')) {
            if ((prevLocation === null || prevLocation === void 0 ? void 0 : prevLocation.key) === (location === null || location === void 0 ? void 0 : location.key)) {
                dispatchFetchAllWorkspaces('custody');
            }
        }
        else if (pathname === '/rfq') {
            dispatchFetchAllWorkspaces('tradingRfq');
        }
        else if (pathname === '/operations/orders') {
            dispatchFetchAllWorkspaces('manageOrders');
        }
        else if (pathname === '/manage/customers') {
            dispatchFetchAllWorkspaces('manageCustomers');
        }
        else if (pathname === '/manage/users') {
            dispatchFetchAllWorkspaces('manageUsers');
        }
        else if (pathname === '/manage/logbook') {
            dispatchFetchAllWorkspaces('manageLogbook');
        }
        else if (pathname === '/settings/api-tokens') {
            dispatchFetchAllWorkspaces('manageTokens');
        }
        else if (pathname === '/operations/transfers') {
            dispatchFetchAllWorkspaces('tradingTransfers');
        }
        else if (pathname === '/operations/schedulers') {
            dispatchFetchAllWorkspaces('manageSchedulers');
        }
        else if (pathname === '/settings/rfq') {
            dispatchFetchAllWorkspaces('quoteMonitor');
        }
        else if (pathname === '/mastertenant/autohedger') {
            dispatchFetchAllWorkspaces('autohedger');
        }
        else if (pathname === '/admin/currency-config') {
            dispatchFetchAllWorkspaces('currencyConfig');
        }
        else if (pathname === '/admin/risk-manager') {
            dispatchFetchAllWorkspaces('autohedgerBalance');
        }
        else if (pathname === '/admin/compliance') {
            dispatchFetchAllWorkspaces('manageCompliance');
        }
        else if (pathname === '/mastertenant/compliance-trading') {
            dispatchFetchAllWorkspaces('manageComplianceTrading');
        }
        else if (pathname === '/portfolio') {
            dispatchFetchAllWorkspaces('portfolio');
        }
        else if (pathname === '/mastertenant') {
            dispatchFetchAllWorkspaces('manageSchedulers');
        }
        else if (pathname === '/admin/autohedger-order') {
            dispatchFetchAllWorkspaces('placeOrders');
        }
        else if (pathname === '/manage/whitelisting') {
            dispatchFetchAllWorkspaces('manageWhitelist');
        }
        else if (pathname === '/manage/permissions') {
            dispatchFetchAllWorkspaces('permissions');
        }
        else if (pathname === '/admin/defi-strategies') {
            dispatchFetchAllWorkspaces('strategies');
        }
        else if (pathname === '/admin/exchanges') {
            dispatchFetchAllWorkspaces('adminExchange');
        }
    };
    const hideTradingSection = features && (features === null || features === void 0 ? void 0 : features.length) > 0 && !features.includes('order.read');
    const rfqAvailable = features && (features === null || features === void 0 ? void 0 : features.length) > 0 && features.includes('client_rfq_settings.read');
    if (clientsLoading) {
        return;
    }
    return (React.createElement(React.Fragment, null, features && (features === null || features === void 0 ? void 0 : features.length) > 0 && clientInfo ? (React.createElement(RatesProvider, null,
        React.createElement(BalancesProvider, null,
            React.createElement(Layout, { withPadding: balancesWidget === null || balancesWidget === void 0 ? void 0 : balancesWidget.pinned },
                React.createElement(Switch, null,
                    React.createElement(Redirect, { exact: true, from: '/', to: rfqAvailable ? '/rfq' : '/dma' }),
                    masterTenant ? (React.createElement(Route, { exact: true, path: '/dma', component: TerminalRoot }, finoaEnv ? (React.createElement(Redirect, { exact: true, from: '/dma', to: '/rfq' })) : (hideTradingSection && React.createElement(Redirect, { exact: true, from: '/dma', to: '/custody' })))) : null,
                    React.createElement(Route, { exact: true, path: '/rfq', component: TerminalRoot }, hideTradingSection && React.createElement(Redirect, { exact: true, from: '/rfq', to: '/custody' })),
                    React.createElement(Route, { path: '/admin' },
                        React.createElement(AdminRoot, { features: features })),
                    React.createElement(Route, { path: '/custody', component: CustodyRoot }),
                    React.createElement(Route, { path: '/404', component: NotFound404 }),
                    React.createElement(Route, { path: '/manage', component: ManageRoot }),
                    React.createElement(Route, { path: '/operations' },
                        React.createElement(OperationsRoot, { features: features })),
                    React.createElement(Route, { path: '/settings' },
                        React.createElement(SettingsRoot, { features: features })),
                    React.createElement(Route, { path: '/release-notes', component: ReleaseNotes }),
                    React.createElement(Route, { path: '*' },
                        React.createElement(Redirect, { to: '/404' }))))))) : null));
};
const stateToProps = (state) => ({
    features: state.client.features,
    customersLoading: state.customersSearch.loading,
    balancesWidget: state.workspace.balances,
    masterTenant: state.client.masterTenant,
    clientsLoading: state.clientSearch.loading,
});
const dispatchToProps = (dispatch) => ({
    dispatchFetchUserInfo: () => dispatch({ type: USER_INFO_FETCH_REQUEST }),
    dispatchFetchAllWorkspaces: (dashboard) => dispatch({ type: WORKSPACE_FETCH_REQUEST, dashboard }),
});
export default connect(stateToProps, dispatchToProps)(Router);
