import * as types from '../actionTypes/subOrdersActionTypes';
const initialState = {
    suborderItems: [],
    subOrdersFailed: [],
    subOrdersExecuting: [],
    loadingExecute: false,
};
/* eslint-disable @typescript-eslint/restrict-plus-operands,
   @typescript-eslint/no-unsafe-return,
   @typescript-eslint/no-unsafe-member-access,
   @typescript-eslint/no-unsafe-assignment,
   @typescript-eslint/no-non-null-assertion */
const getId = (state) => state.suborderItems
    .reduce((maxId, subOrder) => Math.max(subOrder.id, maxId), -1) + 1;
const populatedItems = (subOrderItems) => subOrderItems
    .map((item, index) => Object.assign({}, item, { id: index }));
const subOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SUBORDERS_ORDER_ADD:
            return Object.assign({}, state, {
                suborderItems: [Object.assign(Object.assign({}, action.subOrder), { id: getId(state) }), ...state.suborderItems],
            });
        case types.SUBORDERS_ORDER_UPDATE:
            return Object.assign({}, state, {
                suborderItems: state.suborderItems.map((subOrder) => (subOrder.code === action.code
                    ? Object.assign({}, subOrder, Object.assign({}, action.subOrder)) : subOrder)),
            });
        case types.SUBORDERS_ORDER_REMOVE:
            return Object.assign({}, state, {
                suborderItems: state.suborderItems.filter(subOrder => subOrder.id !== action.id),
            });
        case types.SUBORDERS_ORDER_POPULATE:
            return Object.assign({}, state, {
                suborderItems: populatedItems(action.subOrders),
            });
        case types.SUBORDERS_ORDER_CLEAN:
            return Object.assign({}, state, {
                suborderItems: [],
            });
        case types.SUBORDERS_ORDER_EXECUTE_REQUEST:
            return Object.assign(Object.assign({}, state), { subOrdersFailed: [], subOrdersExecuting: [
                    ...state.subOrdersExecuting,
                    action.code,
                ], loadingExecute: true });
        case types.SUBORDERS_ORDER_EXECUTE_SUCCESS:
            return Object.assign(Object.assign({}, state), { subOrdersExecuting: [], loadingExecute: false });
        case types.SUBORDERS_ORDER_EXECUTE_FAIL:
            return Object.assign(Object.assign({}, state), { loadingExecute: false, subOrdersExecuting: [], subOrdersFailed: [
                    ...state.subOrdersFailed,
                    Object.assign({}, { subOrder: action.code, message: action.message }),
                ] });
        case types.SUBORDERS_CLEAN_ERRORS:
            return Object.assign(Object.assign({}, state), { subOrdersFailed: [] });
        default:
            return state;
    }
};
export default subOrdersReducer;
