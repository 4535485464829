import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { Icon } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles(() => createStyles({
    root: {
        fontSize: '20px',
        height: '24px',
        '&:hover': {
            cursor: 'pointer',
        }
    }
}));
const CopyValue = ({ value, }) => {
    const [icon, setIcon] = useState('file_copy');
    const classes = useStyles();
    const handleCopyValue = () => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(value);
    };
    return (React.createElement(Icon, { className: classes.root, onClick: handleCopyValue }, icon));
};
export default CopyValue;
