import styled from 'styled-components';
export const FormLogo = styled.div `
  width: auto;
  height: 60px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;
`;
export const FormBox = styled.div `
  padding: 10px;
`;
