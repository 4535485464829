import * as types from '../actionTypes/clientSearchActionTypes';
import * as clientTypes from '../actionTypes/clientActionTypes';
const selectedClient = localStorage.getItem('selectedClient');
const initialState = {
    selectedClient: selectedClient ? JSON.parse(selectedClient) : null,
    searchString: '',
    clientsTotal: '0',
    workingClients: [],
    allClients: [],
    pageOfItemsClientSearch: 0,
    loading: false,
};
/* eslint-disable @typescript-eslint/no-unsafe-return */
const clientSearchReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CLIENT_WIDGET_SEARCH_STRING_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { searchString: action.searchString });
        case types.WORKING_CLIENTS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { workingClients: action.workingClients, clientsTotal: action.clientsTotal });
        case types.CLIENTS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { allClients: action.clients });
        case types.CLIENT_UPDATE: {
            const updatedClients = state.allClients.map((c) => {
                if (c.code !== action.client.code) {
                    return c;
                }
                return Object.assign(Object.assign({}, c), action.client);
            });
            return Object.assign(Object.assign({}, state), { allClients: updatedClients });
        }
        case clientTypes.CLIENTS_FETCH_REQUEST:
            return Object.assign(Object.assign({}, state), { loading: true });
        case clientTypes.CLIENTS_FETCH_SUCCESS:
            return Object.assign(Object.assign({}, state), { loading: false, allClients: action.clients, workingClients: action.clients, clientsTotal: action.clients.length.toString() });
        case types.ADMIN_CLIENTS_PAGE_OF_ITEMS_SET_UPDATE:
            return Object.assign(Object.assign({}, state), { pageOfItemsClientSearch: action.pageOfItemsClientSearch });
        case types.SET_SELECTED_CLIENT:
            localStorage.setItem('selectedClient', JSON.stringify(action.selectedClient));
            return Object.assign(Object.assign({}, state), { selectedClient: action.selectedClient });
        default:
            return state;
    }
};
export default clientSearchReducer;
