import React from 'react';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import getCustomerNameByCode from '../../../../../../shared/helpers/getCustomerNameByCode';
const InvitesTableCell = ({ value, columnKey, customers }) => {
    if (columnKey === 'created_at') {
        return (React.createElement(TableCell, { align: "left" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a'));
    }
    if (columnKey === 'customer_code') {
        return (React.createElement(TableCell, { align: "right" }, getCustomerNameByCode(customers, value) || 'N/A'));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default InvitesTableCell;
