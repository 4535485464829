import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import { SCHEMA_WORKING_RESET, SCHEMA_REMOVE_WIDGET, } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import SettingItem from './SettingItem';
import { groupByObjectKey } from '../../../../../../redux/selectors/placeOrderSelector';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
const SettingsContent = ({ settings, exchanges, widgetKey, ordersTabSelected, toggleFunc, optionsBase, optionsQuote, setSubPage, setSplitPage, setRegularPage, }) => {
    const dispatch = useDispatch();
    const groupedSettings = useMemo(() => groupByObjectKey(settings, 'name'), [settings]);
    const renderSetting = {
        regular: 'Regular',
        split: 'PartialOrderbook',
        suborder: 'Suborders',
    };
    if (groupedSettings.exchange_code) {
        groupedSettings.exchange_code = groupedSettings.exchange_code
            .filter((s) => exchanges
            .find(e => e.code === s.label));
    }
    if (groupedSettings.quote) {
        groupedSettings.quote = groupedSettings.quote.filter((s) => optionsQuote
            .find(o => o.label.toLowerCase() === s.label));
    }
    if (groupedSettings.base) {
        groupedSettings.base = groupedSettings.base.filter((s) => optionsBase
            .find(b => b.label.toLowerCase() === s.label));
    }
    const groups = Object.keys(groupedSettings);
    const col1 = groups.slice(0, 4);
    const col2 = groups.slice(4);
    const applySettings = () => {
        // TO DO: merge schemas
        dispatch(applyAllSettingsChanges('trading'));
        if (ordersTabSelected === 'regular') {
            setRegularPage(0);
        }
        if (ordersTabSelected === 'split') {
            setSplitPage(0);
        }
        if (ordersTabSelected === 'suborder') {
            setSubPage(0);
        }
        toggleFunc();
    };
    const removeWidget = (key) => {
        // TO DO: merge schemas
        dispatch({ type: SCHEMA_REMOVE_WIDGET, widgetKey: key });
        toggleFunc();
    };
    const resetSettings = (key, tabSelected) => {
        dispatch({ type: SCHEMA_WORKING_RESET, widgetKey: key, listSettingTab: tabSelected });
    };
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("div", null,
                    React.createElement(Box, { display: 'flex', gap: 1 },
                        React.createElement(Box, { flex: '50%', display: 'flex', flexDirection: 'column', gap: 1 }, col1.map((group, i) => (React.createElement(Box, { key: `cl1-${i}`, display: 'flex', flexDirection: 'column' }, groupedSettings[group].map((setting, k) => (React.createElement(SettingItem, { key: `cl1setting-${k}`, setting: setting }))))))),
                        React.createElement(Box, { flex: '50%', display: 'flex', flexDirection: 'column', gap: 1 }, col2.map((group, i) => (React.createElement(Box, { key: `cl2-${i}`, display: 'flex', flexDirection: 'column' }, groupedSettings[group].map((setting, j) => (React.createElement(SettingItem, { key: `cl2setting-${j}`, setting: setting })))))))),
                    React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', gap: 1 },
                        React.createElement(Button, { variant: "outlined", color: "success", onClick: applySettings }, "Apply settings"),
                        React.createElement(Button, { variant: "outlined", color: "error", onClick: () => removeWidget(widgetKey) }, "Remove widget"),
                        React.createElement(Button, { variant: "outlined", color: "inherit", onClick: () => resetSettings(widgetKey, renderSetting[ordersTabSelected]) }, "Reset settings")))))));
};
export default SettingsContent;
