import { Box, Button, Container, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import PriceFeedsContainer from './PriceFeedsContainer';
const PriceFeeds = () => {
    const [searchString, setSearchString] = useState('');
    const [addNew, setAddNew] = useState(false);
    const handleSearch = (string) => {
        setSearchString(string.target.value);
    };
    const handleAdd = () => {
        setAddNew(true);
    };
    return (React.createElement(Container, { maxWidth: 'xl' },
        React.createElement(Box, { p: 2, sx: { display: 'flex', flexDirection: 'column', gap: 2 } },
            React.createElement(Box, { sx: { display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between' } },
                React.createElement(Box, { sx: { display: 'flex', gap: 2, alignItems: 'center' } },
                    React.createElement(Typography, { variant: 'h6' }, "Price Feeds"),
                    React.createElement(Box, null,
                        React.createElement(TextField, { size: 'small', value: searchString, onChange: (e) => handleSearch(e), InputProps: {
                                endAdornment: (React.createElement(InputAdornment, { position: 'end' },
                                    React.createElement(SearchIcon, null))),
                            } }))),
                React.createElement(Button, { variant: 'contained', size: 'small', onClick: handleAdd }, "Add Feed")),
            React.createElement(PriceFeedsContainer, { searchString: searchString, addNew: addNew, onAddClose: () => setAddNew(false) }))));
};
export default PriceFeeds;
