import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Messages from '../../../shared/helpers/errorMessages';
import showNotification from '../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../shared/helpers/interceptors';
import { getEnvSettings } from '../../../config/environmentSettings';
import { FormLogo } from '../../../shared/components/login/styling/styledComponents';
import { CLOSE_ERROR_NOTICE } from '../../../redux/actionTypes/apiErrorsActionTypes';
import RegisterTOTPForm from '../../../shared/components/login/ResetTOTPForm';
import { Button } from '@mui/material';
/* eslint-disable camelcase, no-restricted-syntax, @typescript-eslint/restrict-template-expressions */
const apiDltUrl = getEnvSettings().apiUrl;
const finoaEnv = getEnvSettings().env === 'finoa';
axios.defaults.baseURL = apiDltUrl;
const ResetTOTP = () => {
    const { ErrorLogger } = window;
    const [qrData, setQrData] = useState('');
    const [totpSecret, setTotpSecret] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleSubmit = (data) => {
        setLoading(true);
        const payload = {
            email: data.email,
            password: data.password,
            token: getInviteCode(),
            totpCode: data.twoFACode,
        };
        const apipath = `${String(getEnvSettings().frontendApiUrl)}/reset2fa`;
        axios
            .post(apipath, payload)
            .then(() => {
            window.location.href = '/login';
        })
            .catch((e) => {
            ErrorLogger.setErrors(e, new Date());
            showNotification({
                message: `Failed to change TOTP`,
                color: 'warning',
                dispatch: () => errorNotice,
            });
        })
            .finally(() => {
            setLoading(false);
        });
    };
    const getInviteCode = () => {
        const url = window.location.href;
        let inviteCode = '';
        if (url) {
            const index = url.indexOf('token=');
            if (index === -1) {
                return '';
            }
            inviteCode = url.slice(index + 6, url.length);
        }
        return inviteCode;
    };
    const fetchTOTPSecret = (code) => {
        setLoading(true);
        const apipath = `${getEnvSettings().frontendApiUrl}/system_token/${code}`;
        axios
            .get(apipath)
            .then((response) => {
            const { totp_secret } = response.data.settings;
            setTotpSecret(totp_secret);
        })
            .catch((e) => {
            var _a;
            ErrorLogger.setErrors(e, new Date());
            const message = composeErrorMessage(e, Messages.REGISTER_INVITE_FETCH);
            const errorCode = ((_a = e.response) === null || _a === void 0 ? void 0 : _a.data).code;
            if (errorCode == 9011) {
                showNotification({
                    message: `The reset link is not valid`,
                    color: 'warning',
                    options: {
                        action: (React.createElement(Button, { style: { marginTop: '-4px' }, variant: 'outlined', size: 'small', color: 'inherit', onClick: () => (window.location.href = '/login') }, "To Login")),
                    },
                    dispatch: () => errorNotice,
                });
            }
            else {
                showNotification({
                    message: `Error: ${message}`,
                    color: 'error',
                    dispatch: errorNotice,
                });
            }
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        const code = getInviteCode();
        if (code) {
            fetchTOTPSecret(code);
        }
    }, []);
    useEffect(() => {
        if (totpSecret && email) {
            const qrString = `otpauth://totp/DLTM:${email}?secret=${totpSecret}&issuer=DLTM`;
            setQrData(qrString);
        }
    }, [email, totpSecret]);
    return (React.createElement("div", { className: 'account' },
        React.createElement("div", { className: 'account__head' },
            React.createElement(FormLogo, { className: finoaEnv ? 'form_logo_finoa' : 'form_logo' })),
        React.createElement("div", { className: 'account__wrapper' },
            React.createElement(RegisterTOTPForm, { qrData: qrData, submit: handleSubmit, email: setEmail, loading: loading }))));
};
export default ResetTOTP;
