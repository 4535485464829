import { CHANGE_THEME_TO_DARK, CHANGE_THEME_TO_LIGHT, } from '../actionTypes/themeActionTypes';
export const initialState = {
    className: localStorage.getItem('theme') || 'theme-dark',
};
/* eslint-disable @typescript-eslint/no-unsafe-return,
   @typescript-eslint/no-unsafe-member-access,
   @typescript-eslint/no-unsafe-assignment */
const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_THEME_TO_DARK:
            localStorage.setItem('theme', 'theme-dark');
            return { className: 'theme-dark' };
        case CHANGE_THEME_TO_LIGHT:
            localStorage.setItem('theme', 'theme-light');
            return { className: 'theme-light' };
        default:
            return state;
    }
};
export default themeReducer;
