import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { isMobileOnly } from 'react-device-detect';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePickerContainer, FieldLabel, PickerContainer } from '../../containers/Dashboards/Admin/styling/style';
const DateComponent = ({ label, onChange, value = '', showTime = true, }) => {
    const handleDateChange = (date) => {
        onChange(date);
    };
    return (React.createElement("div", { className: "form" },
        React.createElement("div", null,
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement("div", null,
                    React.createElement(PickerContainer, null,
                        React.createElement(DatePickerContainer, null,
                            React.createElement("div", { className: "form__form-group" },
                                React.createElement(FieldLabel, { className: "form__form-group-label" }, label),
                                React.createElement("div", { className: "form__form-group-input-wrap form__form-group-input-wrap--error-above" },
                                    React.createElement("div", { className: "form__form-group-field" },
                                        React.createElement("div", { className: "date-picker-dark" },
                                            React.createElement(DatePicker, { timeFormat: "HH:mm", shouldCloseOnSelect: true, maxDate: new Date(), dropdownMode: "select", showTimeSelect: showTime, withPortal: isMobileOnly, onChange: handleDateChange, dateFormat: "yyyy-MM-dd H:mm:ss", popperPlacement: "bottom-start", className: "form__form-group-datepicker admin_date_filter_wrap", selected: moment(value).isValid() ? moment(value).toDate() : null })),
                                        React.createElement("div", { className: "form__form-group-icon" },
                                            React.createElement(FontAwesomeIcon, { icon: faCalendar }))))))))))));
};
export default DateComponent;
