import axios from 'axios';
import { getEnvSettings } from '../../config/environmentSettings';
/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any */
export const fetchWorkspaces = () => axios
    .get('/settings', { baseURL: getEnvSettings().frontendApiUrl })
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error('Unknown, error');
    }
});
export const updateWorkspace = (payload) => axios
    .put('/settings', payload, { baseURL: getEnvSettings().frontendApiUrl })
    .then((response) => response.data)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error('Unknown, error');
    }
});
