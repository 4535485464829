import React from 'react';
import Decimal from 'decimal.js';
const BidSizePrice = ({ price }) => (React.createElement("div", { className: "container_trade_color_green" }, price === ''
    ? React.createElement("span", null, "--")
    : React.createElement("span", null, price)));
const BidSize = ({ orderBookItem }) => {
    /* eslint-disable camelcase */
    if (orderBookItem && orderBookItem.bid && Object.keys(orderBookItem.bid).length) {
        const { volume, volume_split } = orderBookItem.bid;
        const splitVolumeArr = volume_split ? Object.values(volume_split).map(v => +v) : [];
        const minVolume = Math.min(...splitVolumeArr);
        const price = new Decimal(volume_split ? minVolume : volume)
            .toFixed(4)
            .replace(/\.?0+$/, '');
        return React.createElement(BidSizePrice, { price: price });
    }
    return (React.createElement("div", { className: "container_trade_color_green" },
        React.createElement("span", null, "--")));
};
export default React.memo(BidSize);
