import styled from 'styled-components';
export const Bolder = styled.span `
  font-weight: bolder;
`;
export const MainContainer = styled.section `
  padding: 0;
`;
export const HeadPanel = styled.div `
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${props => props.bordercolor};
  > div {
    padding: 10px 0;
  }
`;
export const PanelBid = styled.div `
  flex: 1;
  text-align: center;
  > span {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
  > div {
    text-transform: uppercase;
  }
`;
export const PanelAsk = styled.div `
  flex: 1;
  text-align: center;
  > span {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
  > div {
    text-transform: uppercase;
  }
`;
export const PanelSpread = styled.div `
  display: flex;
  flex-direction: column;
  flex: none;
  width: 70px;
  text-align: center;
  > span {
    flex: 1;
  }
  span:first-child {
    border-bottom: 1px solid ${props => props.bordercolor};
  }
  span:last-child {
    padding-top: 10px;
  }
`;
export const BodyContainer = styled.div `
  min-height: 500px;
  border-bottom: 1px solid ${props => props.dividercolor};
`;
export const BodyWrap = styled.div `
  
`;
export const InnerWrap = styled.div `
  transform: translate(0, ${props => props.moveY ? `${props.moveY}px` : '0'}); 
`;
export const LadderHeading = styled.div `
  display: flex;
  border-bottom: 0;
  border-top: none;
  padding: 0 10px;
  > div {
    flex: 1;
    display: flex;
    padding: 5px;
    > span {
      text-transform: uppercase;
    }
  }
  > div:last-child {
    justify-content: flex-end;
  }
  > .ladder-heading-level {
    flex: none;
    width: 70px;
    justify-content: center;
  }
  > .ladder-heading-acm {
    flex: none;
    width: 70px;
    justify-content: center;
  }
`;
export const LadderPrice = styled.div `
  display: flex;
  flex: none;
  width: 70px;
  justify-content: center;
  padding: 5px;
  background-color: ${props => props.$intersect ? '#37383e40' : 'none'};
`;
export const LadderBody = styled(LadderHeading) `
  > div {
    flex-direction: column;
    position: relative;
    padding: 1px;
    flex: none;
    width: 70px;
    > span {
      text-transform: none;
      text-align: center;
    }
  }
  > div:first-child {
    flex: 1;
    > span {
      color: #32C6A1;
      text-align: right;
    }
    
  }
  > div:last-child {
    flex: 1;
    > span {
      color: #F06158;
      text-align: left;
    }
  }
`;
export const ItemBid = styled.div `
  position: relative;
  > i {
    position: absolute;
    z-index: 3;
    color: #000000;
    top: 22px;
    left: 0;
    background-color: #32C6A1;
    display: inline-block;
    padding: 0 2px;
    font-style: normal;
    font-size: 10px;
    font-weight: 600;
  }
  > .acmVolBid {
    position: absolute;
    top: 0;
    left: 5px;
  }
`;
export const ItemAsk = styled(ItemBid) `
   > i {
    background-color: #F06158;
    left: auto;
    right: 0;
  }
  > .acmVolAsk {
    position: absolute;
    top: 0;
    right: 5px;
  }
`;
export const LadderFooter = styled.div `
  display: flex;
  justify-content: space-between;
  > div:first-child {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
`;
export const AggregationCount = styled.div `
  display: flex;
  flex-direction: column;
  > span:first-child {
    display: inline-block;
    min-width: 90px;
    text-align: center;
    padding: 5px 5px 0px 5px;
  }
  > div {
    flex: 1;
    display: flex;
    padding-right: 5px;
    padding-bottom: 5px;
    > .pagination__link {
      margin: 0;
      top: 6px;
    }
    > .agg-number {
      flex: 1;
      text-align: center;
      margin-top: 6px;
    }
  }
`;
export const LevelFill = styled.div `
  display: block;
  width: ${props => props.percent ? props.percent : '0'}%;
  height: 22px;
  background-color: ${props => props.$ask ? '#F0615847' : '#32C6A126'};
  position: absolute;
  right: ${props => props.$bid ? '0' : 'initial'};
  left: ${props => props.$ask ? '0' : 'initial'};
  top: 0;
`;
