export function readFromLocalStorage(key) {
    try {
        const serializedData = localStorage.getItem(key);
        if (serializedData === null) {
            return null;
        }
        return JSON.parse(serializedData);
    }
    catch (error) {
        console.error('Error reading from localStorage:', error);
        return null;
    }
}
export function writeToLocalStorage(key, data) {
    try {
        const serializedData = JSON.stringify(data);
        localStorage.setItem(key, serializedData);
    }
    catch (error) {
        console.error('Error writing to localStorage:', error);
    }
}
