export const accessMap = (access, customers, clients, selectedClient) => {
    const accessClients = [...access]
        .filter((a) => a.type === 'client')
        .map((item) => {
        var _a;
        return ({
            name: ((_a = clients === null || clients === void 0 ? void 0 : clients.find((c) => c.code === item.clientCode)) === null || _a === void 0 ? void 0 : _a.company_name) || item.clientCode,
            type: item.type,
            code: item.clientCode,
            assigned: true,
            customersCount: access.filter((a) => a.type === 'customer' && a.clientCode === item.clientCode).length,
        });
    });
    const accessCustomers = [...access]
        .filter((a) => a.type === 'customer' && a.clientCode === (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value))
        .map((item) => {
        var _a;
        return ({
            name: ((_a = customers === null || customers === void 0 ? void 0 : customers.find((c) => c.code === item.customerCode)) === null || _a === void 0 ? void 0 : _a.company_name) ||
                item.customerCode,
            type: item.type,
            code: item.customerCode,
            clientCode: item.clientCode,
            assigned: true,
        });
    });
    const otherCustomers = [...access]
        .filter((a) => a.type === 'customer' && access.findIndex((c) => c.type === 'client' && c.clientCode === a.clientCode) === -1)
        .map((item) => {
        var _a;
        return ({
            name: ((_a = customers === null || customers === void 0 ? void 0 : customers.find((c) => c.code === item.customerCode)) === null || _a === void 0 ? void 0 : _a.company_name) ||
                item.customerCode,
            type: item.type,
            code: item.customerCode,
            clientCode: item.clientCode,
            assigned: true,
        });
    });
    const allClients = (clients === null || clients === void 0 ? void 0 : clients.map((item) => ({
        name: item.company_name || item.code,
        type: 'client',
        code: item.code,
        assigned: accessClients.findIndex((a) => a.code === item.code) > -1,
    }))) || [];
    const allCustomers = (customers === null || customers === void 0 ? void 0 : customers.filter((c) => c.client_code === (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value)).map((item) => ({
        name: item.company_name || item.code,
        type: 'customer',
        code: item.code,
        clientCode: item.client_code,
        assigned: accessCustomers.findIndex((a) => a.code === item.code) > -1,
    }))) || [];
    return {
        clients: accessClients,
        customers: accessCustomers,
        otherCustomers,
        allCustomers,
        allClients,
    };
};
export const getClientsTree = (access, customers, clients) => {
    const tree = {
        id: 'root',
        name: 'Root',
        expanded: true,
        type: 'root',
        children: [],
    };
    clients === null || clients === void 0 ? void 0 : clients.forEach((client) => {
        var _a;
        const node = {
            name: client.company_name || client.code,
            id: client.code,
            expanded: false,
            type: 'client',
            checked: access.findIndex((a) => a.clientCode === client.code) > -1,
            children: [
                ...customers
                    .filter(c => c.client_code === client.code)
                    .map(customer => ({
                    name: customer.company_name || customer.code,
                    id: customer.code,
                    expanded: false,
                    type: 'customer',
                    clientCode: customer.client_code,
                    checked: access.findIndex((a) => a.customerCode === customer.code) > -1,
                }))
            ],
        };
        (_a = tree.children) === null || _a === void 0 ? void 0 : _a.push(node);
    });
    return tree;
};
