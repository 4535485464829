/* eslint-disable camelcase, @typescript-eslint/no-unsafe-return, no-nested-ternary, no-unneeded-ternary */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Box, CircularProgress } from '@mui/material';
import OrderFilters from './OrderFilters';
import PlaceOrdersList from './PlaceOrdersList';
import OrdersService from '../../../../../services/ordersService';
import Permissions from '../../../../Layout/AdminSidebar/Permissions';
import { PanelTitle, PanelContainer } from '../../../Admin/styling/style';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import { useServiceFetchApis } from '../../../../../shared/hooks/useServiceFetchApis';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const MainContainer = ({ columns, features, widgetKey, clientInfo, selectedClient, urlOptionsHedgeOrders, }) => {
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [orders, setOrders] = useState([]);
    const [service, setService] = useState(null);
    const [activeClient, setActiveClient] = useState(null);
    const sortDirection = 'desc';
    const sortBy = 'created';
    const { data } = useServiceFetchApis(service, urlOptionsHedgeOrders, 10000);
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            const serviceInstance = new OrdersService({
                baseURL: getEnvSettings().adminApiUrl,
                url: '/trade/autohedger/orders',
                method: 'get',
            }, '/trade/autohedger/orders');
            setService(serviceInstance);
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (data) {
            setOrders(data.records);
            setTotal(data.total);
        }
    }, [data]);
    useEffect(() => {
        if (selectedClient) {
            setActiveClient(selectedClient);
        }
        else {
            setActiveClient(null);
        }
    }, [selectedClient]);
    if (!clientInfo) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4, role: "slider" },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement("div", { className: "content-body", role: "main" },
        React.createElement(Permissions, { features: features, permissions: ['order.read'] },
            React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
                React.createElement(PanelTitle, { className: "panel__title" },
                    React.createElement(OrderFilters, { page: page, sortBy: sortBy, setPage: setPage, clientData: activeClient, rowsPerPage: rowsPerPage, sortDirection: sortDirection })),
                React.createElement("div", { role: "table", className: "card-body" },
                    React.createElement(PlaceOrdersList, { page: page, total: total, orders: orders, setPage: setPage, columns: columns, widgetKey: widgetKey, rowsPerPage: rowsPerPage, setRowsPerPage: setRowsPerPage }))))));
};
const mapStateToProps = (state, ownProps) => ({
    widgetKey: ownProps.widgetKey,
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    urlOptionsHedgeOrders: state.masterTenant.urlOptionsHedgeOrders,
});
export default connect(mapStateToProps)(MainContainer);
