import styled from 'styled-components';
export const InfoContainer = styled.div `
  display: flex;
  margin-bottom: 5px;
`;
export const CollapseContainer = styled.div `
  padding: 10px;
`;
export const InfoColumn = styled.div `
  flex: ${props => (props.flexNumber ? props.flexNumber : '1')};
`;
export const InfoItem = styled.div `
  display: flex;
  padding-bottom: 5px;
`;
export const InfoItemLabel = styled.div `
  flex: none;
  width: 130px;
  font-weight: bold;
`;
export const InfoItemValue = styled.div `
  flex: 1;
  font-weight: 300;
`;
export const Clickable = styled.span `
  cursor: pointer;
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 2px 10px;
  border-radius: 20px;
`;
