import React from 'react';
import { Box, Container, Divider, MenuItem, TextField, Typography } from '@mui/material';
import { connect } from 'react-redux';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
class FundingInfo extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            customerCode: '',
        };
    }
    handleCustomerSelect(event) {
        this.setState({ customerCode: event.target.value });
    }
    render() {
        const { customers } = this.props;
        const bankDetails = {
            company: 'DLT Securities GmbH',
            companyAddress: 'Tiroler Straße 36, 60596 Frankfurt am Main',
            bankName: 'Bank Frick & Co. AG',
            bicCode: 'BFRILI22',
            bankAddress: 'Landstrasse 14, 9496 Balzers, Leichtenstein',
            iban: 'LI12 0881 1010 3504 Y000 E',
        };
        return (React.createElement(Box, { p: 1, height: '100%' },
            React.createElement(Container, { maxWidth: 'lg' },
                React.createElement(Box, { p: 1 },
                    React.createElement(Typography, { variant: 'subtitle1', textAlign: 'center', style: { textTransform: 'uppercase' } }, "Funding Info")),
                React.createElement(Box, { display: 'flex', justifyContent: 'center', alignItems: 'center' },
                    React.createElement(Box, { p: 1, gap: 1, display: 'flex', flexDirection: 'column', maxWidth: '500px' },
                        React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                            React.createElement(TextField, { size: 'small', focused: true, color: 'warning', select: true, label: 'Customer', defaultValue: '', fullWidth: true, onChange: (customer) => this.handleCustomerSelect(customer) }, customers.map((option) => (React.createElement(MenuItem, { key: option.code, value: option.code }, option.company_name || option.code)))),
                            React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                                React.createElement(TextField, { disabled: true, label: 'Name', value: bankDetails.company, fullWidth: true, size: 'small' }),
                                React.createElement(CopyValue, { value: bankDetails.company })),
                            React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                                React.createElement(TextField, { disabled: true, label: 'Address', value: bankDetails.companyAddress, fullWidth: true, size: 'small' }),
                                React.createElement(CopyValue, { value: bankDetails.companyAddress })),
                            React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                                React.createElement(TextField, { disabled: true, label: 'IBAN', value: bankDetails.iban, fullWidth: true, size: 'small' }),
                                React.createElement(CopyValue, { value: bankDetails.iban })),
                            React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                                React.createElement(Box, { flex: 'auto', display: 'flex', alignItems: 'center', gap: 1 },
                                    React.createElement(TextField, { disabled: true, label: 'Bank name', value: bankDetails.bankName, fullWidth: true, size: 'small' }),
                                    React.createElement(CopyValue, { value: bankDetails.bankName })),
                                React.createElement(Box, { flex: 'auto', display: 'flex', alignItems: 'center', gap: 1 },
                                    React.createElement(TextField, { disabled: true, label: 'BIC', value: bankDetails.bicCode, fullWidth: true, size: 'small' }),
                                    React.createElement(CopyValue, { value: bankDetails.bicCode }))),
                            React.createElement(Box, { flex: 'auto', display: 'flex', alignItems: 'center', gap: 1 },
                                React.createElement(TextField, { disabled: true, label: 'Bank address', value: bankDetails.bankAddress, fullWidth: true, size: 'small' }),
                                React.createElement(CopyValue, { value: bankDetails.bankAddress })),
                            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
                                React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1 },
                                    React.createElement(TextField, { disabled: true, label: 'Reference', value: this.state.customerCode || '', fullWidth: true, size: 'small' }),
                                    this.state.customerCode && React.createElement(CopyValue, { value: this.state.customerCode })),
                                React.createElement(Typography, { variant: 'subtitle2' },
                                    React.createElement("b", null, "IMPORTANT!"),
                                    " This reference identifies the deposit to the customer account. Provide this code when creating the transfer."),
                                React.createElement(Box, { mt: 3 },
                                    React.createElement(Divider, null,
                                        React.createElement(Box, { display: 'flex', alignItems: 'center', gap: 1, mb: 1 },
                                            React.createElement(InfoOutlinedIcon, { fontSize: 'small' }),
                                            React.createElement(Typography, { variant: 'subtitle2' }, "ADDITIONAL INFORMATION"))),
                                    React.createElement(Typography, { variant: 'subtitle2' },
                                        React.createElement(Box, null,
                                            "Multiple deposits of the same amounts within a few days of each other may be delayed. If you must make multiple deposits, consider sending different amounts. ",
                                            React.createElement("br", null),
                                            React.createElement("br", null),
                                            "Deposits sent in any currency other than EUR will be converted to EUR, and are subject to additional banking fees. ",
                                            React.createElement("br", null),
                                            React.createElement("br", null),
                                            "The name on the bank account you are depositing from must match the name entered for verification on the DLT account you are depositing into."))))))))));
    }
}
const stateToProps = (state) => {
    const customers = state.customersSearch.customers;
    return {
        customers,
    };
};
export default connect(stateToProps)(FundingInfo);
