import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import LinkIcon from '@mui/icons-material/Link';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import CreateIcon from '@mui/icons-material/Create';
import TableCell from '@mui/material/TableCell';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import Main from '../MegaModal/main';
import { MaterialPopoverWrap } from '../../styling/style';
const WalletsCellActions = ({ item, actions, admin, features, theme, }) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const [textColor, setTextColor] = useState('');
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [action, setAction] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionsToRender, setActionsToRender] = useState([]);
    const open = Boolean(anchorEl);
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (userAction) => {
        toggleOpenDialog(prev => !prev);
        setAction(userAction);
        setAnchorEl(null);
        if (userAction === 'megaModal') {
            history.push('/custody');
        }
    };
    const renderActionsContent = (userAction) => {
        if (!userAction) {
            return React.createElement("div", null, "something went wrong");
        }
        if (userAction === 'megaModal') {
            return React.createElement(Main, { item: item });
        }
        return (React.createElement(actions.actionsComponents[userAction], {
            item,
            admin,
            toggle: () => toggleDialog(),
            cellAction: actions.actions[userAction],
        }));
    };
    useEffect(() => {
        if (theme === 'theme-dark') {
            setTextColor('#FFFFFF');
        }
        else {
            setTextColor('#000000');
        }
    }, [theme]);
    useEffect(() => {
        let availableActions;
        if (admin) {
            availableActions = actions.actionsList
                .filter(a => ((item.customer_code !== 'n/a' && item.accounting_type !== 'trading' && a.action === 'adminUnAssign') || a.action === 'updateBalance'));
        }
        else {
            availableActions = actions.actionsList
                .filter(a => (item.customer_code === 'n/a' && (a.action === 'selectCustomer')));
        }
        if (features === null || features === void 0 ? void 0 : features.includes('client_custody_wallet.update')) {
            const labelUpdateAction = actions.actionsList.find(a => a.action === 'updateLabel');
            if (labelUpdateAction) {
                availableActions.push(labelUpdateAction);
            }
        }
        if (!item.is_migrated && !item.is_new_provider) {
            const markAsMigratedAction = actions.actionsList.find(a => a.action === 'markAsMigrated');
            if (markAsMigratedAction) {
                availableActions.push(markAsMigratedAction);
            }
        }
        if (admin && (features === null || features === void 0 ? void 0 : features.includes('client_custody_wallet.update'))) {
            const activateAction = actions.actionsList.find(a => a.action === 'activateWallet');
            if (activateAction) {
                availableActions.push(activateAction);
            }
        }
        setActionsToRender([...availableActions]);
    }, [item, features, admin, actions]);
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { size: "small", "aria-label": "more", onClick: handleActionsClick },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions" },
                    actionsToRender.map(a => (React.createElement(ListItem, { key: `${a.name}_wallets`, onClick: () => toggleDialog(a.action), button: true },
                        React.createElement(ListItemIcon, null, a.action === 'updateBalance' ? React.createElement(RefreshIcon, { fontSize: "small" }) : React.createElement(CreateIcon, { fontSize: "small" })),
                        React.createElement(ListItemText, { className: "trading_clients_th_name", primary: a.action === 'activateWallet'
                                ? item.state === 'active' ? 'Deactivate' : 'Activate'
                                : a.name })))),
                    React.createElement(Link, { to: {
                            pathname: `${url}/wallets/${item.code}`,
                            state: { clientCode: item.client_code },
                        } },
                        React.createElement(ListItem, { onClick: () => toggleDialog('megaModal'), button: true },
                            React.createElement(ListItemIcon, null,
                                React.createElement(LinkIcon, { fontSize: "small" })),
                            React.createElement(ListItemText, { style: { color: textColor }, primary: "Wallet Info" })))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Actions"),
            React.createElement(DialogContent, null, action ? renderActionsContent(action) : null))));
};
export default WalletsCellActions;
