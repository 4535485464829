import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { Box } from '@mui/material';
const options = {
    scales: {
        y: {
            ticks: {
                callback: (value) => `$ ${value}`,
            },
        },
    },
};
Chart.register(...registerables);
const WidgetCharts = ({ chartData }) => {
    const memoData = useMemo(() => chartData.data.map(d => +d), [chartData]);
    return (React.createElement(Box, { p: 2 },
        React.createElement(Line, { options: options, data: {
                labels: chartData.labels,
                datasets: [{
                        label: 'Orders',
                        data: memoData,
                        fill: false,
                        backgroundColor: 'rgb(45,101,198)',
                        borderColor: 'rgb(96,130,193)',
                    }],
            } })));
};
export default WidgetCharts;
