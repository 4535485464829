import React from 'react';
import TableCell from '@mui/material/TableCell';
/* eslint-disable no-nested-ternary */
const SchedulerdCell = ({ value, keyOf }) => {
    if (keyOf === 'last_run') {
        const d = value ? new Date(value) : undefined;
        return (React.createElement(TableCell, { align: "right" }, d ? `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}` : 'N/A'));
    }
    return (React.createElement(TableCell, { align: keyOf === 'name' ? 'left' : 'right' }, value || 'N/A'));
};
export default SchedulerdCell;
