import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import DialogHeader from '../../../../../../shared/components/DialogHeader';
const initPayload = {
    base: [],
    quote: [],
    action_type: '',
    exchange_code: [],
    customer_code: '',
    partial_order_code: [],
};
const CancelOrdersModal = ({ open, toggleFunc, header = true, colored = false, color = 'primary', classProps = '', cancelAllOrders, payload = initPayload, toggleSeveral = () => undefined, }) => {
    const modalClass = classNames({
        'modal-dialog--colored': colored,
        'modal-dialog--header': header,
    });
    const backdropTrue = true;
    return (React.createElement(Fragment, null,
        React.createElement(Dialog, { open: open, maxWidth: "sm", fullWidth: true },
            React.createElement(React.Fragment, null,
                React.createElement(DialogHeader, { title: 'Confirmation', toggleFunc: toggleFunc }),
                React.createElement(DialogContent, null,
                    React.createElement("div", { className: "loading_block_flex" },
                        React.createElement("div", { className: "cancel_all_orders_body" }, "Are you sure you want to cancel all orders?"),
                        payload !== null && payload.quote.length > 0 ? (React.createElement("div", { className: "cancel_all_orders_body" },
                            "Fiat: ",
                            JSON.stringify(payload.quote))) : null,
                        payload !== null && payload.base.length > 0 ? (React.createElement("div", { className: "cancel_all_orders_body" },
                            "Crypto: ",
                            JSON.stringify(payload.base))) : null,
                        payload !== null && payload.exchange_code.length > 0 ? (React.createElement("div", { className: "cancel_all_orders_body" },
                            "Exchange: ",
                            JSON.stringify(payload.exchange_code))) : null,
                        payload !== null && payload.action_type.length > 0 ? (React.createElement("div", { className: "cancel_all_orders_body" },
                            "Type: ",
                            payload.action_type)) : null)),
                React.createElement(DialogActions, null,
                    React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', gap: 1 },
                        React.createElement(Button, { color: "error", variant: 'contained', type: "button", onClick: () => { cancelAllOrders(payload); toggleFunc(); toggleSeveral(); }, size: "small" }, "Yes"),
                        React.createElement(Button, { size: 'small', variant: 'outlined', onClick: toggleFunc }, "No")))))));
};
export default CancelOrdersModal;
