import React from 'react';
import { connect } from 'react-redux';
const FeatureAccess = (props) => {
    const { children, masterTenant, isCustomer, hiddenFor } = props;
    const isClient = !masterTenant && !isCustomer;
    const shouldRender = !hiddenFor || hiddenFor.length === 0 ||
        !((masterTenant && hiddenFor.includes('mastertenant')) ||
            (isCustomer && hiddenFor.includes('customer')) ||
            (isClient && hiddenFor.includes('client')));
    return shouldRender ? React.createElement(React.Fragment, null, children) : null;
};
const stateToProps = (state) => {
    const { features, role, masterTenant, customer_code } = state.client;
    return {
        role,
        features,
        masterTenant,
        isCustomer: !!customer_code,
    };
};
export default connect(stateToProps)(FeatureAccess);
