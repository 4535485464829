import React, { useEffect, useRef, useState } from 'react';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import PriceLevels from './PriceLevel';
import * as Styled from '../../styling/styled';
import getDecimalsByCurrencyCode from '../../../../../../../config/currencyDecimal';
/* eslint-disable no-plusplus */
const getPercentageWidth = (volume, levels) => {
    const asks = levels
        .filter(level => level.asksVolume !== 0)
        .map(level => level.asksVolume);
    const bids = levels
        .filter(level => level.bidsVolume !== 0)
        .map(level => level.bidsVolume);
    const asksAndBids = asks.concat(bids);
    let countOfRowsWithBiggerVolume = new Decimal(0);
    asksAndBids.forEach((item) => {
        if (new Decimal(item).lessThanOrEqualTo(volume)) {
            countOfRowsWithBiggerVolume = countOfRowsWithBiggerVolume.plus(1);
        }
    });
    // this is a percentile
    return countOfRowsWithBiggerVolume.div(asksAndBids.length).mul(100).toNumber();
};
const formatPriceLevels = (bids, asks, currencies) => {
    const asksPrices = asks.map(ask => new Decimal(ask.price));
    const bidPrices = bids.map(bid => new Decimal(bid.price));
    const unionPrices = [...new Set([...bidPrices, ...asksPrices])];
    unionPrices.sort((a, b) => b.minus(a).toNumber());
    const decimals = getDecimalsByCurrencyCode('USD', currencies) || 2;
    let bidAcmValue = new Decimal(0);
    const bidsAcm = bids.reverse().map((elem) => {
        bidAcmValue = bidAcmValue.add(new Decimal(elem.volume));
        const bidItem = Object.assign({}, elem, { acmVol: bidAcmValue.toFixed(decimals) });
        return bidItem;
    });
    let askAcmValue = new Decimal(0);
    const asksAcm = asks.map((elem) => {
        askAcmValue = askAcmValue.add(new Decimal(elem.volume));
        const askItem = Object.assign({}, elem, { acmVol: askAcmValue.toFixed(decimals) });
        return askItem;
    });
    return unionPrices.map((price) => {
        const level = {
            price: price.toString(),
            bidsVolume: 0,
            asksVolume: 0,
            bidsAcmVolume: 0,
            asksAcmVolume: 0,
        };
        const a = asksAcm.find(ask => ask.price === price.toString());
        const b = bidsAcm.find(bid => bid.price === price.toString());
        if (a) {
            level.asksVolume = +a.volume;
            level.asksAcmVolume = a.acmVol ? +a.acmVol : 0;
        }
        else {
            level.asksVolume = 0;
        }
        if (b) {
            level.bidsVolume = +b.volume;
            level.bidsAcmVolume = b.acmVol ? +b.acmVol : 0;
        }
        else {
            level.bidsVolume = 0;
        }
        return level;
    });
};
const calculateVolMillionMarks = (levels) => {
    const result = {
        mark500K: null,
        mark1M: null,
        mark2M: null,
    };
    if (!Array.isArray(levels) || levels.length === 0) {
        return result;
    }
    let mark = new Decimal(0);
    const marks = {
        '500k': new Decimal(500000),
        '1m': new Decimal(1000000),
        '2m': new Decimal(2000000),
    };
    for (let i = 0; i < levels.length; i++) {
        const totalLevel = new Decimal(levels[i].volume).mul(new Decimal(levels[i].price));
        mark = mark.add(totalLevel);
        if (mark.greaterThanOrEqualTo(marks['500k']) && result.mark500K === null) {
            result.mark500K = { price: levels[i].price, total: String(mark) };
        }
        if (mark.greaterThanOrEqualTo(marks['1m']) && result.mark1M === null) {
            result.mark1M = { price: levels[i].price, total: String(mark) };
        }
        if (mark.greaterThanOrEqualTo(marks['2m']) && result.mark2M === null) {
            result.mark2M = { price: levels[i].price, total: String(mark) };
        }
    }
    if (result.mark2M) { // if we have >2M in levels, hide 500k, we got 1M set by design
        result.mark500K = null;
    }
    if (result.mark500K && result.mark1M) { // if 500k and 1M on same level then cancel 500k one
        if (result.mark500K.price === result.mark1M.price) {
            result.mark500K = null;
        }
    }
    if (result.mark1M && result.mark2M) { // if 1M and 2M on same level then cancel 1M
        if (result.mark1M.price === result.mark2M.price) {
            result.mark1M = null;
        }
    }
    return result;
};
const Body = ({ channel, selectedPair, currencies }) => {
    const prevChannel = useRef('');
    const refId = useRef(shortid.generate());
    const [levels, setLevels] = useState([]);
    const [bidsMark, setBidsMark] = useState({});
    const [asksMark, setAsksMark] = useState({});
    const aggregatedOrderBookUpdate = (data) => {
        const { bids, asks } = data.payload;
        const bidsCopy = bids.slice(0, 10).reverse();
        const asksCopy = asks.slice(0, 10);
        const priceLevels = formatPriceLevels(bidsCopy, asksCopy, currencies);
        const bm = calculateVolMillionMarks(bids.slice(0, 10));
        const am = calculateVolMillionMarks(asksCopy);
        setLevels(priceLevels);
        setBidsMark(bm);
        setAsksMark(am);
    };
    useEffect(() => {
        const { OrderBookWS } = window;
        if (prevChannel.current !== '') {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        }
        OrderBookWS.addSubscription(channel, { callback: aggregatedOrderBookUpdate, componentId: refId.current });
        setLevels([]);
        prevChannel.current = channel;
        return () => {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        };
    }, [channel]);
    if (levels.length === 0) {
        return React.createElement("div", null, "Receiving data..");
    }
    const quoteSymbol = (selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote) === 'USD' ? '$' : '€';
    const trueVal = true;
    return (React.createElement(React.Fragment, null, levels.map((level) => {
        var _a, _b, _c, _d, _e, _f;
        const b500k = (_a = bidsMark.mark500K) === null || _a === void 0 ? void 0 : _a.price;
        const b1m = (_b = bidsMark.mark1M) === null || _b === void 0 ? void 0 : _b.price;
        const b2m = (_c = bidsMark.mark2M) === null || _c === void 0 ? void 0 : _c.price;
        const a500k = (_d = asksMark.mark500K) === null || _d === void 0 ? void 0 : _d.price;
        const a1m = (_e = asksMark.mark1M) === null || _e === void 0 ? void 0 : _e.price;
        const a2m = (_f = asksMark.mark2M) === null || _f === void 0 ? void 0 : _f.price;
        const bmark1 = b500k === level.price;
        const bmark2 = b1m === level.price;
        const bmark3 = b2m === level.price;
        const amark1 = a500k === level.price;
        const amark2 = a1m === level.price;
        const amark3 = a2m === level.price;
        return (React.createElement(Styled.LadderBody, { key: shortid.generate() },
            React.createElement(Styled.ItemBid, { "$mark": bmark1 || bmark2 || bmark3 },
                React.createElement(Styled.LevelFill, { "$bid": trueVal, percent: getPercentageWidth(level.bidsVolume, levels) }),
                React.createElement("span", { className: "acmVolBid price_ladder_header_text" }, level.bidsAcmVolume ? level.bidsAcmVolume : ''),
                React.createElement("span", null, level.bidsVolume ? level.bidsVolume : ''),
                bmark1 && bmark1 !== bmark2 && React.createElement("i", null,
                    quoteSymbol,
                    "500k"),
                bmark2 && bmark2 !== bmark3 && React.createElement("i", null,
                    quoteSymbol,
                    "1m"),
                bmark3 && React.createElement("i", null,
                    quoteSymbol,
                    "2m")),
            React.createElement(PriceLevels, { intersect: !!level.asksVolume && !!level.bidsVolume, level: `${level.price}` }),
            React.createElement(Styled.ItemAsk, { "$mark": amark1 || amark2 || amark3 },
                amark3 && React.createElement("i", null,
                    quoteSymbol,
                    "2m"),
                amark2 && amark2 !== amark3 && React.createElement("i", null,
                    quoteSymbol,
                    "1m"),
                amark1 && amark1 !== amark2 && React.createElement("i", null,
                    quoteSymbol,
                    "500k"),
                React.createElement("span", null, level.asksVolume ? level.asksVolume : ''),
                React.createElement("span", { className: "acmVolAsk price_ladder_header_text" }, level.asksAcmVolume ? level.asksAcmVolume : ''),
                React.createElement(Styled.LevelFill, { "$ask": trueVal, percent: getPercentageWidth(level.asksVolume, levels) }))));
    })));
};
export default Body;
