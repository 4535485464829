import React from 'react';
import Alert from '@mui/material/Alert';
import { connect } from 'react-redux';
import SubOrderbookItem from './SubOrderbookItem';
import { getExchangesOptions } from '../../../../../../redux/selectors/selectors';
const SubOrderbookContent = ({ subOrders, exchanges, theme }) => (React.createElement(React.Fragment, null, subOrders && subOrders.length
    ? subOrders.map((item, i) => (React.createElement(SubOrderbookItem, { key: i, item: item, theme: theme, exchanges: exchanges })))
    : (React.createElement("tr", null,
        React.createElement("td", { colSpan: 7 },
            React.createElement(Alert, { severity: "info", variant: "outlined" },
                React.createElement("p", null,
                    React.createElement("span", { className: "bold-text" }, "Items not found"))))))));
const mapStateToProps = (state) => ({
    theme: state.theme,
    exchanges: getExchangesOptions(state),
});
export default connect(mapStateToProps)(SubOrderbookContent);
