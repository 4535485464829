/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any, no-underscore-dangle */
export const getEnvSettings = () => {
    const env = window.__env || {};
    return {
        wsUri: env.WSS_URL || 'wss://api.stage.dltm.quanttradingfactory.com/v1/trade/ws',
        apiUrl: (env.API && `${env.API}/v1.1`) || 'https://stage.dlt-finance.com/api/v1.1',
        frontendApiUrl: (env.API && `${env.API}/f`) || 'https://stage.dlt-finance.com/api/f',
        adminApiUrl: (env.API && `${env.API}/admin`) || 'https://stage.dlt-finance.com/api/admin',
        env: env.ENVIRONMENT || 'dlt',
        thousandDivider: env.THOUSAND_DIVIDER || '\'',
    };
};
