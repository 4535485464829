import React, { useCallback, useEffect, useState, useContext, } from 'react';
import { Alert, Box, List } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../../Custody/styling/style';
import { ADMIN_DEPOSITS_UPDATE, ADMIN_WITHDRAWALS_UPDATE, ADMIN_DEPOSITS_LOADING_UPDATE, ADMIN_WITHDRAWALS_LOADING_UPDATE, } from '../../../../../../redux/actionTypes/adminActionTypes';
import AmlCheck from './AmlCheck';
import TableCellActions from './TableCellActions';
import TableCellContainer from './TableCellContainer';
import Messages from '../../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import { RatesContext } from '../../../../../../providers/RatesProvider';
import showNotification from '../../../../../../shared/helpers/notifications';
import { getTransactions } from '../../../../../../redux/selectors/adminSelectors';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { materialUiPaginator } from '../../../../../../shared/helpers/recordsDependsOnPage';
import CustodyClientDepositsService from '../../../../../../services/custodyDepositsService';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import CustodyClientWithdrawalsService from '../../../../../../services/custodyWithdrawalsService';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
const WidgetTransactions = ({ columns, complianceWidgetHeight, transactions, selectedClient, currencies, }) => {
    const [maxHeight, setMaxHeight] = useState(480);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loadingDeposits, setLoadingDeposits] = useState(false);
    const [loadingWithdrawals, setLoadingWithdrawals] = useState(false);
    const [sortDirection, setSortDirection] = useState('asc');
    const [client, setClient] = useState(null);
    const [allTransactions, setAllTransactions] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [sortBy, setSortBy] = useState('created_at');
    const [transactionsToRender, setTransactionsToRender] = useState([]);
    const [totalPages, setTotalPages] = useState(allTransactions.length);
    const ctx = useContext(RatesContext);
    const cellActions = {
        actions: {
            viewRisks: () => undefined,
        },
        actionsComponents: {
            viewRisks: AmlCheck,
        },
        actionsList: [
            { name: 'View risks', action: 'viewRisks' },
        ],
    };
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            // hide column: remove
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            // show column: insert
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            // default: do nothing
            setColumnsToRender([...columnsToRender]);
        }
        // give all animations a bit of a space to finish
        dispatch(applyAllSettingsChanges('custody'));
    };
    const fetchTransactions = (code) => {
        const urlDeposits = `/custody/deposits?limit=1000&include_aml_check=true&clientCode=${code}`;
        const urlWithdrawals = `/custody/withdrawals?limit=1000&clientCode=${code}`;
        const depositsService = new CustodyClientDepositsService({ url: urlDeposits, method: 'get' });
        const withdrawalService = new CustodyClientWithdrawalsService({ url: urlWithdrawals, method: 'get' });
        setLoadingDeposits(true);
        setLoadingWithdrawals(true);
        dispatch({ type: ADMIN_DEPOSITS_LOADING_UPDATE, depositsLoading: true });
        dispatch({ type: ADMIN_WITHDRAWALS_LOADING_UPDATE, withdrawalsLoading: true });
        depositsService.makeRequest()
            .then((data) => {
            setLoadingDeposits(false);
            dispatch({ type: ADMIN_DEPOSITS_LOADING_UPDATE, depositsLoading: false });
            // dispatch deposits to admin reducer
            dispatch({ type: ADMIN_DEPOSITS_UPDATE, deposits: data.records });
        })
            .catch((e) => {
            setLoadingDeposits(false);
            dispatch({ type: ADMIN_DEPOSITS_LOADING_UPDATE, depositsLoading: false });
            const message = composeErrorMessage(e, Messages.DEPOSIT_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
        withdrawalService.makeRequest()
            .then((data) => {
            setLoadingWithdrawals(false);
            dispatch({ type: ADMIN_WITHDRAWALS_LOADING_UPDATE, withdrawalsLoading: false });
            // dispatch deposits to admin reducer
            dispatch({ type: ADMIN_WITHDRAWALS_UPDATE, withdrawals: data.records });
        })
            .catch((e) => {
            setLoadingWithdrawals(false);
            dispatch({ type: ADMIN_WITHDRAWALS_LOADING_UPDATE, withdrawalsLoading: false });
            const message = composeErrorMessage(e, Messages.CUSTODY_WITHDRAWALS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchHistoricRates = (records) => {
        const payload = records
            .reduce((res, t) => (Object.assign(Object.assign({}, res), { [t.currency_code]: (res[t.currency_code] || []).concat(t.created_at) })), {});
        ctx.getHistoricRates(payload);
    };
    const onPageChange = (event, pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const onSortHandle = (key) => {
        setCurrentPage(0);
        setSortBy(key);
        setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc');
    };
    const sortStoredWallets = (transactionsToSort, direction, by) => {
        const sorterNum = (dir, a, b) => dir === 'desc'
            ? (a - b) : (b - a);
        const sorter = (dir, a, b) => dir === 'desc'
            ? (a > b)
                ? 1 : -1
            : (a < b)
                ? 1 : -1;
        const sortFunc = (a, b, dir, key) => {
            const valueA = a[key];
            const valueB = b[key];
            if (Array.isArray(valueA) || Array.isArray(valueB)) {
                return 0;
            }
            if (valueA && valueB) {
                if (isNaN(+valueA) && isNaN(+valueB)) {
                    return sorter(dir, valueA, valueB);
                }
                return sorterNum(dir, +valueA, +valueB);
            }
            // or maybe throw exception
            return 0;
        };
        return transactionsToSort.sort((a, b) => sortFunc(a, b, direction, by));
    };
    //  Step 1: Fetch transactions
    useEffect(() => {
        if (selectedClient) {
            fetchTransactions(selectedClient.value);
            setClient(selectedClient);
        }
    }, [selectedClient]);
    // Step 2: Set display table and fetch historic rates for the transactions
    useEffect(() => {
        if (transactions.length === 0) {
            setAllTransactions([]);
            setTotalPages(0);
        }
        else {
            fetchHistoricRates(transactions);
            setAllTransactions([...transactions]);
            setTotalPages(transactions.length);
        }
    }, [transactions]);
    // Step 3: Update transactions with calculated USD amounts based on historic rates
    useEffect(() => {
        if (ctx.service && ctx.service.historicRatesRanges && transactions.length) {
            const conversionService = ctx.service;
            const trxs = transactions.map((t) => Object.assign({}, t, {
                amount_usd: conversionService.convertHistoricInBase(t.currency_code, t.created_at, t.amount),
            }));
            setAllTransactions([...trxs]);
        }
    }, [ctx.historicRates, transactions]);
    // Step 4: Update transactions items we want to render
    useEffect(() => {
        const items = materialUiPaginator(allTransactions, currentPage, rowsPerPage);
        setTransactionsToRender([...items]);
        setTotalPages(allTransactions.length);
    }, [allTransactions]);
    // Applies filters and updates transactions we want to render
    useEffect(() => {
        let w = [...allTransactions];
        if (sortBy) {
            w = sortStoredWallets(w, sortDirection, sortBy);
        }
        w = materialUiPaginator(w, currentPage, rowsPerPage);
        setTransactionsToRender([...w]);
    }, [sortBy, sortDirection, currentPage, rowsPerPage, allTransactions]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(complianceWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [complianceWidgetHeight]);
    if (loadingDeposits || loadingWithdrawals) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement("div", null, !client
                ? (React.createElement(Box, { p: 2 },
                    React.createElement(Alert, { variant: "outlined", severity: "info" }, "Select client for more details"))) : null),
            client
                ? (React.createElement(React.Fragment, null,
                    React.createElement(WalletsClientContainer, null,
                        React.createElement("div", null,
                            React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                                React.createElement(ViewColumnIcon, null)),
                            React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                }, transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'center',
                                } },
                                React.createElement(MaterialPopoverWrap, null,
                                    React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                        React.createElement(ListItemText, { primary: col.title }),
                                        React.createElement(ListItemSecondaryAction, null,
                                            React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
                    React.createElement(MaterialTableWrap, null,
                        React.createElement(Paper, { className: "mui_table_root" },
                            React.createElement(TableContainer, { style: { maxHeight } },
                                React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                    React.createElement(TableHead, null,
                                        React.createElement(TableRow, null,
                                            columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, align: column.key === 'code' ? 'left' : 'right', style: { minWidth: column.minWidth } },
                                                React.createElement(TableSortLabel, { direction: column.key === sortBy ? sortDirection : 'asc', active: column.key === sortBy, onClick: () => onSortHandle(column.key) }, column.title)))),
                                            React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                                    React.createElement(TableBody, null, transactionsToRender.length === 0
                                        ? (React.createElement(TableRow, null,
                                            React.createElement(TableCell, { colSpan: columnsToRender.length + 1 },
                                                React.createElement(Typography, { className: "transactions_typography_padded", variant: "subtitle1" }, "No items found"))))
                                        : transactionsToRender.map(transaction => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: transaction.code },
                                            columnsToRender.map((column, i) => {
                                                const key = column.key;
                                                const value = key ? transaction[key] : undefined;
                                                return (React.createElement(TableCellContainer, { key: i, value: value, columnKey: key, item: transaction, loading: ctx.loading, currencies: currencies }));
                                            }),
                                            React.createElement(TableCellActions, { item: transaction, actions: cellActions }))))))),
                            React.createElement(TablePagination, { component: "div", count: totalPages, page: currentPage, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [maxHeight] }))))) : null));
};
const mapStateToProps = (state, ownProps) => ({
    transactions: getTransactions(state),
    currencies: state.masterTenant.currencies,
    selectedClient: getSelectedClientInputValue(state),
    columns: getWidgetColumns(state, ownProps.widgetKey),
    complianceWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(WidgetTransactions);
