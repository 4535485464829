import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import Table from '@mui/material/Table';
import { Box, Paper } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import axios from 'axios';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { Link, useLocation, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import { InfoBox, DetailsInfo, InfoModalTitle, PanelContainer, MaterialTableWrap, } from '../../styling/style';
import WalletTableCell from './WalletTableCell';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import CustodyWalletService from '../../../../../services/custodyWalletService';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import { materialUiPaginator } from '../../../../../shared/helpers/recordsDependsOnPage';
import { CUSTODY_WALLETS_ADD } from '../../../../../redux/actionTypes/custodyActionTypes';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import CustodyClientDepositsService from '../../../../../services/custodyDepositsService';
import CustodyClientWithdrawalsService from '../../../../../services/custodyWithdrawalsService';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const WalletsLoader = ({ code }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fetchWallets = (clientCode) => {
        const walletService = new CustodyWalletService({
            url: `/custody/${clientCode}/wallets?limit=1000`,
            method: 'get',
        });
        setLoading(true);
        walletService.makeRequest()
            .then((data) => {
            dispatch({ type: CUSTODY_WALLETS_ADD, wallets: data.records });
            setLoading(false);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_FETCH);
            setLoading(false);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    // initialise service
    useEffect(() => {
        if (code) {
            fetchWallets(code);
        }
    }, [code]);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', flexDirection: 'column', p: 4, gap: 2 },
            React.createElement(Box, { display: 'flex', justifyContent: 'center' },
                React.createElement(CircularProgress, { size: "33px" })),
            React.createElement(Box, { display: 'flex', justifyContent: 'center' },
                "Loading wallets for client : ",
                code)));
    }
    return null;
};
const getAllTransactions = (deposits, withdrawals) => {
    if (deposits.length === 0 || withdrawals.length === 0) {
        return [];
    }
    withdrawals.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    deposits.sort((a, b) => new Date(b.compliance_received_at).getTime() - new Date(a.compliance_received_at).getTime());
    const dTransactions = deposits.map(d => ({
        code: d.code,
        type: 'deposit',
        wallet_code: d.wallet_code,
        amount: d.compliance_amount,
        fee: d.compliance_to_custody_fee,
        created_at: d.compliance_received_at,
    }));
    const wTransactions = withdrawals.map(w => ({
        fee: w.fee,
        code: w.code,
        type: 'withdrawal',
        created_at: w.created_at,
        amount: w.received_amount,
        wallet_code: w.wallet_code,
    }));
    return [...dTransactions, ...wTransactions];
};
const Wallet = ({ wallets, selectedClient }) => {
    var _a, _b, _c;
    const loadsCount = useRef(0);
    const [page, setPage] = useState(0);
    const location = useLocation();
    const [totalPages, setTotalPages] = useState(0);
    const [codeClient, setCodeClient] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { walletId } = useParams();
    const [loadingDeposits, setLoadingDeposits] = useState(false);
    const [loadingWithdrawals, setLoadingWithdrawals] = useState(false);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [wallet, setWallet] = useState(undefined);
    const [allTransactions, setAllTransactions] = useState([]);
    const [allDeposits, setAllDeposits] = useState([]);
    const [allWithdrawals, setAllWithdrawals] = useState([]);
    const [transactionsToRender, setTransactionsToRender] = useState([]);
    const legacyAddress = wallet && wallet.compliance_addresses.length > 0
        ? wallet.compliance_addresses[0].legacy_address : '';
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fetchAllWithDrawals = (code, cancelToken) => {
        setLoadingWithdrawals(true);
        const url = `/custody/withdrawals?limit=1000&clientCode=${code}`;
        const service = new CustodyClientWithdrawalsService({ url, method: 'get', cancelToken });
        service.makeRequest()
            .then((data) => {
            setLoadingWithdrawals(false);
            setAllWithdrawals(data.records);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            setLoadingWithdrawals(false);
            const message = composeErrorMessage(e, Messages.CUSTODY_WITHDRAWALS_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const fetchAllDeposits = (code, cancelToken) => {
        setLoadingDeposits(true);
        const urlDeposits = `/custody/deposits?limit=1000&clientCode=${code}`;
        const depositsService = new CustodyClientDepositsService({ url: urlDeposits, method: 'get', cancelToken });
        depositsService.makeRequest()
            .then((data) => {
            setLoadingDeposits(false);
            setAllDeposits(data.records);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                // to avoid any state update
                // on unmounted component
                return;
            }
            setLoadingDeposits(false);
            const message = composeErrorMessage(e, Messages.DEPOSIT_ADDRESSES_FETCH);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    const Columns = useMemo(() => [
        { title: 'Code', name: 'code', key: 'code', minWidth: '160px' },
        { title: 'Amount', name: 'amount', key: 'amount', minWidth: '110px' },
        { title: 'Fee', name: 'fee', key: 'fee', minWidth: '120px' },
        { title: 'Type', name: 'type', key: 'type', minWidth: '100px' },
        { title: 'Created at', name: 'created', key: 'created_at', minWidth: '150px' },
    ], []);
    const onPageChange = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        let code = '';
        if (selectedClient) {
            code = selectedClient.value;
        }
        setCodeClient(code);
    }, [selectedClient]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        fetchAllWithDrawals(codeClient, cancelTokenSource.token);
        fetchAllDeposits(codeClient, cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, [codeClient]);
    useEffect(() => {
        const transactionsArr = getAllTransactions(allDeposits, allWithdrawals);
        setAllTransactions(transactionsArr);
    }, [allDeposits, allWithdrawals]);
    useEffect(() => {
        const res = wallets.filter(w => w.code === walletId);
        if (res.length) {
            setWallet(res[0]);
        }
        else {
            loadsCount.current += 1;
            setWallet(null);
        }
    }, [wallets, walletId]);
    useEffect(() => {
        setColumnsToRender([...Columns]);
    }, []);
    useEffect(() => {
        if (wallet && allTransactions.length) {
            const filtered = allTransactions.filter(t => t.wallet_code === wallet.code) || [];
            const items = materialUiPaginator(filtered, page, rowsPerPage);
            setTransactionsToRender([...items]);
            setTotalPages(filtered.length);
        }
    }, [allTransactions, wallet, page, rowsPerPage]);
    if (wallet === undefined) {
        return null;
    }
    if (wallet === null) {
        if (loadsCount.current < 3) {
            const { clientCode } = location.state;
            return (React.createElement(InfoBox, { className: "megamodal_block_container" },
                React.createElement(WalletsLoader, { code: clientCode })));
        }
        return (React.createElement(InfoBox, { className: "megamodal_block_container" },
            React.createElement("p", null,
                "Failed to find Wallet for ",
                walletId)));
    }
    if (loadingDeposits && loadingWithdrawals) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(InfoBox, null,
        React.createElement(Box, { display: 'flex' },
            React.createElement(InfoModalTitle, null,
                React.createElement("h4", null, "Wallet balance"))),
        React.createElement(Box, { display: 'flex', p: 2, gap: 4 },
            React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                React.createElement(Box, { fontWeight: 'bold', marginBottom: '10px' }, "Available"),
                React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
                    React.createElement(Box, { style: { textTransform: 'uppercase' } }, wallet.currency_code),
                    React.createElement(Box, null, (_a = wallet.balance) === null || _a === void 0 ? void 0 : _a.available))),
            React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                React.createElement(Box, { fontWeight: 'bold', marginBottom: '10px' }, "Total"),
                React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
                    React.createElement(Box, { style: { textTransform: 'uppercase' } }, wallet.currency_code),
                    React.createElement(Box, null, (_b = wallet.balance) === null || _b === void 0 ? void 0 : _b.total))),
            React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                React.createElement(Box, { fontWeight: 'bold', marginBottom: '10px' }, "Locked"),
                React.createElement(Box, { display: 'flex', flexDirection: 'row', p: 1, gap: 1 },
                    React.createElement(Box, { style: { textTransform: 'uppercase' } }, wallet.currency_code),
                    React.createElement(Box, null, (_c = wallet.balance) === null || _c === void 0 ? void 0 : _c.locked)))),
        React.createElement("hr", null),
        React.createElement(Box, { display: 'flex' },
            React.createElement("div", null,
                React.createElement(InfoModalTitle, null,
                    React.createElement("h4", null, "Wallet details")))),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement("div", null,
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Customer:")),
                    React.createElement("div", null, wallet.customer_code
                        ? (React.createElement(Link, { style: { color: 'inherit' }, to: `/custody/customers/${wallet.customer_code}` }, wallet.customer_code))
                        : React.createElement("span", null, "not available"))),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Code:")),
                    React.createElement("div", null, wallet.code)),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Label:")),
                    React.createElement("div", null, wallet.label)),
                React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Wallet address:")),
                    React.createElement("div", null, wallet.compliance_addresses.length > 0 ? wallet.compliance_addresses[0].address : '-----')),
                legacyAddress ? (React.createElement(DetailsInfo, { className: "dashboard__total dashboard__total--area" },
                    React.createElement("div", null,
                        React.createElement("span", null, "Legacy address:")),
                    React.createElement("div", null, legacyAddress))) : null)),
        React.createElement("hr", null),
        React.createElement("div", { style: { display: 'flex' } },
            React.createElement(PanelContainer, null,
                React.createElement(MaterialTableWrap, null,
                    React.createElement(Paper, { className: "mui_table_root" },
                        React.createElement(TableContainer, null,
                            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                                React.createElement(TableHead, null,
                                    React.createElement(TableRow, null, columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'code' ? 'left' : 'right' }, column.title))))),
                                React.createElement(TableBody, null, transactionsToRender.map(transaction => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: transaction.code }, columnsToRender.map((column, i) => {
                                    const key = column.key;
                                    const value = key ? transaction[key] : undefined;
                                    return (React.createElement(WalletTableCell, { key: i, value: value, columnKey: key, item: transaction, allDeposits: allDeposits }));
                                }))))))),
                        React.createElement(TablePagination, { rowsPerPageOptions: [10, 25, 100], count: totalPages, rowsPerPage: rowsPerPage, page: page, onPageChange: onPageChange, onRowsPerPageChange: handleChangeRowsPerPage })))))));
};
const mapStateToProps = (state) => ({
    selectedClient: getSelectedClientInputValue(state),
});
export default connect(mapStateToProps)(Wallet);
