import React, { useCallback, useState } from 'react';
import { Box, Button, TextField, CircularProgress, } from '@mui/material';
import { useDispatch } from 'react-redux';
import Messages from '../../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import CustodyClientWithdrawalsService from '../../../../../../services/custodyWithdrawalsService';
export const WithdrawalDelete = ({ item, fetchAllWithDrawals, toggleDialog, }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleDelete = (code) => {
        const service = new CustodyClientWithdrawalsService({
            url: `/custody/withdrawals/${code}`, method: 'delete',
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            fetchAllWithDrawals();
            showNotification({
                message: 'Address successfully removed',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleDialog();
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_REMOVE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggleDialog();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "Are you sure you want to delete Withdrawal:\u00A0",
            React.createElement("span", { className: "forms_uuid_container" }, item.address)),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", size: "small", variant: "outlined", onClick: () => handleDelete(item.code) }, "Yes"),
            React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: () => toggleDialog() }, "Close"))));
};
export const WithdrawalMasterSign = ({ item, fetchAllWithDrawals, toggleDialog, actionKey, }) => {
    const [twoFA, setTwoFA] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onTwoFAChange = (evt) => {
        const { value } = evt.target;
        setTwoFA(value);
    };
    const handleMasterSign = (totp, code) => {
        const payload = {
            code,
            totp_code: totp,
        };
        const service = new CustodyClientWithdrawalsService({
            url: `/custody/withdrawals/${actionKey}`, method: 'post', data: payload,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            fetchAllWithDrawals();
            showNotification({
                message: 'Withdrawal successfully signed',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleDialog();
        })
            .catch((e) => {
            setLoading(false);
            const err = actionKey === 'sign'
                ? Messages.WITHDRAWAL_ADDRESSES_SIGN : Messages.WITHDRAWAL_ADDRESSES_MASTER_SIGN;
            const message = composeErrorMessage(e, err);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggleDialog();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "You're approving to withdrawal:",
            React.createElement("span", { className: "forms_uuid_container" }, item.address)),
        React.createElement(TextField, { fullWidth: true, size: "small", value: twoFA, label: "Two FA", margin: "normal", variant: "outlined", onChange: onTwoFAChange, InputLabelProps: { shrink: true } }),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", size: "small", variant: "contained", disabled: !twoFA || twoFA.length !== 6, onClick: () => handleMasterSign(twoFA, item.code) }, "Send"),
            React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: () => toggleDialog() }, "Close"))));
};
export const WithdrawalBoost = ({ item, fetchAllWithDrawals, toggleDialog, }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleBoost = (code) => {
        const service = new CustodyClientWithdrawalsService({
            url: `/custody/withdrawals/${code}/boost`, method: 'post',
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            fetchAllWithDrawals();
            showNotification({
                message: 'Withdrawal successfully boosted',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleDialog();
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ADDRESSES_BOOST);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggleDialog();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "Are you sure you want to boost Withdrawal:\u00A0",
            React.createElement("span", null, item.code)),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", variant: "outlined", size: "small", onClick: () => handleBoost(item.code) }, "Boost"),
            React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: () => toggleDialog() }, "Close"))));
};
export const WithdrawalAbort = ({ item, fetchAllWithDrawals, toggleDialog }) => {
    const [reason, setReason] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onReasonChange = (evt) => {
        const { value } = evt.target;
        setReason(value);
    };
    const handleAbort = (reasonData, code) => {
        const payload = {
            failure_reason: reasonData,
        };
        const service = new CustodyClientWithdrawalsService({
            url: `/custody/client/withdrawals/${code}/abort`,
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            fetchAllWithDrawals();
            showNotification({
                message: 'Withdrawal successfully aborted',
                color: 'success',
                dispatch: errorNotice,
            });
            toggleDialog();
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.WITHDRAWAL_ABORT);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            toggleDialog();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "You're aborting to withdrawal: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.address)),
        React.createElement(TextField, { fullWidth: true, size: "small", value: reason, margin: "normal", variant: "outlined", label: "Failure reason", onChange: onReasonChange, InputLabelProps: { shrink: true } }),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "error", size: "small", variant: "contained", onClick: () => handleAbort(reason, item.code) }, "Abort"),
            React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: () => toggleDialog() }, "Close"))));
};
