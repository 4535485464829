import { createSelector } from 'reselect';
/* eslint-disable @typescript-eslint/no-unsafe-return, import/prefer-default-export,
  no-nested-ternary, max-len, @typescript-eslint/restrict-template-expressions,
  @typescript-eslint/no-unsafe-call */
const custodyWallets = (state) => state.custody.wallets;
const clientWallets = (state) => state.custody.clientsWallets;
const custodyCustomers = (state) => state.customersSearch.customers;
const custodyWidgetByKey = (state, key) => state.widgetSettings.widgets.find(w => w.widgetKey === key);
const custodyWidgetsSettings = (state) => state.widgetSettings.settings;
export const getCustodyWallets = createSelector(custodyWallets, wallets => wallets.length ? wallets : []);
export const getClientsWallets = createSelector(clientWallets, clientsWallets => clientsWallets.length ? clientsWallets : []);
export const getWalletsToRender = createSelector(custodyWallets, custodyCustomers, (wallets, customers) => {
    if (wallets.length === 0) {
        return [];
    }
    return wallets.map((r) => {
        var _a, _b, _c;
        const customer = r.customer_code ? customers.find(c => c.code === r.customer_code) : null;
        const convert = {
            code: r.code,
            type: r.type,
            usd_total: '',
            label: r.label,
            state: r.state,
            usd_locked: '',
            usd_available: '',
            external_id: r.external_id,
            client_code: r.client_code,
            is_migrated: r.is_migrated,
            currency_code: r.currency_code,
            currency_ticker: r.currency_ticker,
            balance_total: (_a = r.balance) === null || _a === void 0 ? void 0 : _a.total,
            balance_locked: (_b = r.balance) === null || _b === void 0 ? void 0 : _b.locked,
            accounting_type: r.accounting_type,
            created_at: r.created_at,
            is_new_provider: r.is_new_provider,
            balance_available: (_c = r.balance) === null || _c === void 0 ? void 0 : _c.available,
            customer_code: customer ? customer.company_name : r.customer_code ? r.customer_code : 'n/a',
            deposit_address: r.compliance_addresses.length
                ? r.compliance_addresses[0].state === 'active'
                    ? r.compliance_addresses[0].address
                    : 'not active'
                : 'n/a',
        };
        return convert;
    });
});
export const getWidgetColumns = createSelector(custodyWidgetByKey, custodyWidgetsSettings, (widget, settings) => {
    const r = widget ? widget.settings.map(settingStr => settings.byId[settingStr]) : [];
    return r && r.length ? r.filter(s => s && s.id.includes('Columns')) : [];
});
export const getCustodyCustomers = createSelector(custodyCustomers, customers => customers && customers.length ? customers : []);
export const getClientsWalletsToRender = createSelector(clientWallets, custodyCustomers, (clientsWallets, customers) => {
    if (clientsWallets.length === 0) {
        return [];
    }
    return clientsWallets.map((r) => {
        var _a, _b, _c;
        const customer = r.customer_code ? customers.find(c => c.code === r.customer_code) : null;
        const convert = {
            code: r.code,
            type: r.type,
            usd_total: '',
            usd_locked: '',
            label: r.label,
            state: r.state,
            usd_available: '',
            external_id: r.external_id,
            client_code: r.client_code,
            is_migrated: r.is_migrated,
            currency_code: r.currency_code,
            currency_ticker: r.currency_ticker,
            balance_total: (_a = r.balance) === null || _a === void 0 ? void 0 : _a.total,
            balance_locked: (_b = r.balance) === null || _b === void 0 ? void 0 : _b.locked,
            accounting_type: r.accounting_type,
            created_at: r.created_at,
            is_new_provider: r.is_new_provider,
            balance_available: (_c = r.balance) === null || _c === void 0 ? void 0 : _c.available,
            customer_code: customer ? customer.company_name : r.customer_code ? r.customer_code : 'n/a',
            deposit_address: r.compliance_addresses.length
                ? r.compliance_addresses[0].state === 'active'
                    ? r.compliance_addresses[0].address
                    : 'not active'
                : 'n/a',
        };
        return convert;
    });
});
