import * as types from '../actionTypes/widgetSettingsActionTypes';
export const onSchemaLoaded = (settings) => ({
    type: types.SCHEMA_LOAD_SUCCESS,
    settings,
});
export const schemaUpdateSettingItem = (payload) => (Object.assign({ type: types.SCHEMA_WORKING_SET_UPDATE }, payload));
export const schemaAddToWorking = (settings) => ({
    type: types.SCHEMA_ADD_WORKING,
    settings,
});
export const applyAllSettingsChanges = (dashboard) => ({
    type: types.SCHEMA_APPLY_ALL_SETTINGS,
    dashboard,
});
