import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Dialog from '@mui/material/Dialog';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import CreateIcon from '@mui/icons-material/Create';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogTitle from '@mui/material/DialogTitle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DialogContent from '@mui/material/DialogContent';
import { MaterialPopoverWrap } from '../../styling/style';
const AddressesCellActions = ({ item, actions, admin, features, meCode, clientCode, clientInfo, }) => {
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const [action, setAction] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [actionsToRender, setActionsToRender] = useState([]);
    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleActionsClose = () => {
        setAnchorEl(null);
    };
    const toggleDialog = (userAction) => {
        toggleOpenDialog(prev => !prev);
        setAction(userAction);
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const renderActionsContent = (userAction) => {
        if (!userAction) {
            return (React.createElement("div", null, "something went wrong"));
        }
        return (React.createElement(actions.actionsComponents[userAction], {
            toggle: () => toggleDialog(),
            item,
            cellAction: actions.actions[userAction],
            actionKey: userAction.toLowerCase(),
        }));
    };
    useEffect(() => {
        let r = [];
        if (admin) {
            if ((features === null || features === void 0 ? void 0 : features.includes('client_custody_withdrawal_address.delete'))
                && item.state === 'pending_admin_delete') {
                r = [...r, ...actions.actionsList.filter(a => a.action === 'confirm')];
            }
            if ((features === null || features === void 0 ? void 0 : features.includes('client_custody_withdrawal_address.update'))
                && item.state === 'pending_admin_approve') {
                r = [...r, ...actions.actionsList.filter(a => a.action === 'approve')];
            }
            if (features === null || features === void 0 ? void 0 : features.includes('client_custody_manual_aml.create')) {
                r = [...r, ...actions.actionsList.filter(a => a.action === 'aml')];
            }
            if (item.state === 'pending_sign' && (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code) === item.client_code
                && (features === null || features === void 0 ? void 0 : features.includes('client_custody_withdrawal_address.update'))) {
                r = [...r, ...actions.actionsList.filter(a => a.action === 'sign')];
            }
            if (item.state === 'pending_sign' && (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.deposit_holder_code) === (clientCode || (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code))
                && (features === null || features === void 0 ? void 0 : features.includes('client_custody_address_master_sign.create'))) {
                r = [...r, ...actions.actionsList.filter(a => a.action === 'masterSign')];
            }
            if (item.state !== 'pending_admin_delete' && item.state !== 'aml_failed'
                && (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.deposit_holder_code) === (clientCode || (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code))
                && (features === null || features === void 0 ? void 0 : features.includes('client_custody_withdrawal_address.update'))) {
                r = [...r, ...actions.actionsList.filter(a => a.action === 'deleteItem')];
            }
        }
        else {
            if (item.state === 'pending_sign' && meCode !== item.creator_code) {
                if (features === null || features === void 0 ? void 0 : features.includes('client_custody_withdrawal_address.update')) {
                    r = [...r, ...actions.actionsList.filter(a => a.action === 'sign')];
                }
            }
            if (item.state === 'pending_sign' && (features === null || features === void 0 ? void 0 : features.includes('client_custody_address_master_sign.create'))) {
                r = [...r, ...actions.actionsList.filter(a => a.action === 'masterSign')];
            }
            if (item.state !== 'pending_admin_delete' && item.state !== 'aml_failed'
                && (features === null || features === void 0 ? void 0 : features.includes('client_custody_withdrawal_address.update'))) {
                r = [...r, ...actions.actionsList.filter(a => a.action === 'deleteItem')];
            }
        }
        setActionsToRender([...r]);
    }, [item]);
    return (React.createElement(TableCell, { align: "right" },
        React.createElement(IconButton, { size: "small", "aria-label": "more", onClick: handleActionsClick, disabled: actionsToRender.length === 0 },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleActionsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
            React.createElement(MaterialPopoverWrap, null,
                React.createElement(List, { component: "nav", "aria-label": "more actions", dense: true }, actionsToRender.map((a, i) => (React.createElement(ListItem, { key: `${i}_address_action_item`, onClick: () => toggleDialog(a.action), button: true },
                    React.createElement(ListItemIcon, null,
                        React.createElement(CreateIcon, { fontSize: "small" })),
                    React.createElement(ListItemText, { className: "trading_clients_th_name", primary: a.name }))))))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, { id: "form-dialog-title" }, "Actions"),
            React.createElement(DialogContent, null, action ? renderActionsContent(action) : null))));
};
export default AddressesCellActions;
