var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable camelcase */
import { useCallback, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
const useWebhooksService = () => {
    const [state, setState] = useState({
        loading: true,
        webhooks: [],
        total: '0',
    });
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const getWebhooks = () => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'get',
            url: '/webhooks',
        })
            .then((response) => {
            setState({
                loading: false,
                webhooks: response.data.records,
                total: response.data.total,
            });
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.WEBHOOK_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const addWebhook = (url) => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'post',
            url: '/webhooks',
            data: { url }
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, webhooks: [...state.webhooks, response.data], total: state.total + 1 })));
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.WEBHOOK_ADD);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    const deleteWebhook = (code) => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'delete',
            url: `/webhooks/${code}`,
        })
            .then(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, webhooks: [...state.webhooks.filter(w => w.code !== code)], total: (+state.total - 1).toString() })));
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.WEBHOOK_DELETE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    });
    return {
        getWebhooks,
        addWebhook,
        deleteWebhook,
        webhooks: state.webhooks,
        loading: state.loading,
    };
};
export default useWebhooksService;
