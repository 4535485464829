/* eslint-disable camelcase, import/no-cycle, no-plusplus */
import React, { useState, useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTradingBases, getTradingQuotes, getAvailableExchanges, } from '../../../../../../redux/selectors/selectors';
import CancelOrdersModal from './CancelOrdersModal';
import { Box, Checkbox, FormControlLabel, MenuItem, TextField, Typography } from '@mui/material';
import { Button } from '@mui/material';
const options = [
    {
        label: 'Buy',
        value: 'Buy',
        type: 'select',
        name: 'action_type',
    },
    {
        label: 'Sell',
        value: 'Sell',
        type: 'select',
        name: 'action_type',
    },
];
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["ADD"] = "ADD";
    FilterActionType["RESET"] = "RESET";
    FilterActionType["DELETE"] = "DELETE";
    FilterActionType["UPDATE"] = "UPDATE";
})(FilterActionType || (FilterActionType = {}));
const initPayload = {
    base: [],
    quote: [],
    action_type: '',
    exchange_code: [],
    customer_code: '',
    partial_order_code: [],
};
const init = (initialValue) => ({
    selectedFilters: [
        {
            label: '', name: 'action_type', type: 'select', value: initialValue,
        },
    ],
});
const reducer = (state, action) => {
    switch (action.type) {
        case FilterActionType.ADD:
            return { selectedFilters: [...state.selectedFilters, action.item] };
        case FilterActionType.DELETE:
            return {
                selectedFilters: state.selectedFilters
                    .filter(f => f.label !== action.item.label),
            };
        case FilterActionType.UPDATE: {
            const objIndex = state.selectedFilters
                .findIndex((obj => obj.name === action.item.name));
            const newArr = state.selectedFilters;
            newArr[objIndex].value = action.item.value;
            newArr[objIndex].label = action.item.label;
            return { selectedFilters: newArr };
        }
        case FilterActionType.RESET:
            return init('');
        default:
            return state;
    }
};
const CancelSeveralForm = ({ exchanges, toggleFunc, optionsBase, optionsQuotes, initialValue = '', cancelSeveralOrders, }) => {
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const [modalOpen, setModalOpen] = useState(false);
    const [payload, setPayload] = useState(initPayload);
    const objIndexActionType = state.selectedFilters.findIndex((obj => obj.name === 'action_type'));
    const action_type = state.selectedFilters[objIndexActionType];
    const addSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.ADD, item: val });
    };
    const removeSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.DELETE, item: val });
    };
    const updateSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.UPDATE, item: val });
    };
    const resetFilters = () => {
        dispatch({ type: FilterActionType.RESET });
    };
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    const handleCheckboxChange = (item, event) => {
        if (event.target.checked) {
            dispatch({ type: FilterActionType.ADD, item });
        }
        else {
            dispatch({ type: FilterActionType.DELETE, item });
        }
    };
    const renderCheckbox = (name, label) => (React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { key: label, name: name, id: label, onChange: (event) => handleCheckboxChange({ type: 'checkbox', name, label, value: label }, event), checked: !!state.selectedFilters.find(f => f.value === label) }), label: label }));
    const renderBase = (arr) => {
        const mappedBase = [];
        for (let j = 0; j < arr.length; j += 10) {
            mappedBase.push(React.createElement(Box, { display: 'flex', flexDirection: 'column' },
                React.createElement(Typography, { variant: 'subtitle1' }, "Base"),
                arr.slice(j, j + 10).map(item => renderCheckbox('base', item.value))));
        }
        return mappedBase;
    };
    useEffect(() => {
        const allFiat = state.selectedFilters.filter(i => i.name === 'quote').map(f => f.value);
        const allCrypto = state.selectedFilters.filter(i => i.name === 'base').map(f => f.value);
        const allTypes = state.selectedFilters.filter(i => i.name === 'action_type').map(f => f.value);
        const allExchanges = state.selectedFilters.filter(i => i.name === 'exchange_code').map(f => f.value);
        const data = initPayload;
        data.quote = allFiat;
        data.base = allCrypto;
        data.exchange_code = allExchanges;
        data.action_type = allTypes.length > 0 ? allTypes[0] : '';
        setPayload(data);
    }, [state]);
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(Box, null,
            React.createElement(Box, { display: 'flex', justifyContent: 'space-between' },
                renderBase(optionsBase),
                React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
                    React.createElement(Typography, { variant: 'subtitle1' }, "Quote"),
                    optionsQuotes.map(i => renderCheckbox('quote', i.value)),
                    React.createElement(Typography, { variant: 'subtitle1' }, "Exchange"),
                    exchanges.map(ex => renderCheckbox('exchange_code', ex.value))),
                React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1, minWidth: '160px' },
                    React.createElement(Typography, { variant: 'subtitle1' }, "Action type"),
                    React.createElement(TextField, { select: true, size: "small", label: "Type", variant: "outlined", value: action_type.value, onChange: (event) => updateSelectedFilter({ value: event.target.value, label: 'Type', name: 'action_type', type: 'select' }) },
                        React.createElement(MenuItem, { value: "" }, "All"),
                        options.map(option => React.createElement(MenuItem, { value: option.value }, option.label)))))),
        React.createElement(Box, { display: 'flex', justifyContent: 'flex-end', gap: 1 },
            React.createElement(Button, { size: "small", type: "button", color: "error", variant: "contained", onClick: toggleModal }, "Cancel orders"),
            React.createElement(Button, { size: "small", type: "button", color: "inherit", variant: "outlined", onClick: resetFilters }, "Reset filters")),
        modalOpen ? (React.createElement(CancelOrdersModal, { open: modalOpen, payload: payload, toggleFunc: toggleModal, toggleSeveral: toggleFunc, cancelAllOrders: cancelSeveralOrders })) : null));
};
const mapStateToProps = (state) => {
    const { loadingExchanges, loadingMarkets } = state.placeOrder;
    let exchanges = [];
    if (!loadingExchanges && !loadingMarkets) {
        exchanges = getAvailableExchanges(state);
    }
    return {
        exchanges,
        optionsBase: getTradingBases(state),
        optionsQuotes: getTradingQuotes(state),
    };
};
const mapDispatchToProps = () => ({
    initialValue: '',
});
export default connect(mapStateToProps, mapDispatchToProps)(CancelSeveralForm);
