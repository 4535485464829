import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import Messages from '../../../../../../../shared/helpers/errorMessages';
import ClientsService from '../../../../../../../services/clientsService';
import showNotification from '../../../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../../../shared/helpers/interceptors';
import { INVITES_FETCH_REQUEST } from '../../../../../../../redux/actionTypes/adminActionTypes';
import { CLOSE_ERROR_NOTICE } from '../../../../../../../redux/actionTypes/apiErrorsActionTypes';
export const DisableEnableUser = ({ item, toggle, fetchUsers }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleDisableEnable = (code, is_enabled) => {
        const payload = {
            is_enabled: !is_enabled,
        };
        const clientsService = new ClientsService({
            url: `/${code}/restrictions`,
            method: 'patch',
            data: payload,
            baseURL: getEnvSettings().frontendApiUrl,
        });
        clientsService.makeRequest()
            .then(() => {
            fetchUsers();
            showNotification({
                message: `User successfully ${is_enabled ? 'disabled' : 'enabled'}`,
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.USERS_ENABLE_DISABLE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement("div", { style: { width: '100%' } },
            "Are you sure you want to ",
            item.is_enabled ? 'Disable ' : 'Enable ',
            "User: ",
            React.createElement("span", null, item.code),
            "?"),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(Button, { variant: "contained", color: "primary", onClick: () => handleDisableEnable(item.code, item.is_enabled) }, "Confirm"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Cancel"))));
};
export const ResetPassword = ({ item, toggle, urlInvites }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleResetPassword = (code) => {
        const clientsService = new ClientsService({
            url: `/${code}/password_reset_request`,
            method: 'post',
            baseURL: getEnvSettings().frontendApiUrl,
        });
        clientsService.makeRequest()
            .then(() => {
            dispatch({ type: INVITES_FETCH_REQUEST, urlOptions: urlInvites });
            showNotification({
                message: 'Password successfully reseted',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RESET_PASSWORD);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement("div", { style: { width: '100%' } },
            "Are you sure you want to reset password for User: ",
            React.createElement("span", null, item.code),
            "?"),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(Button, { variant: "contained", color: "error", onClick: () => handleResetPassword(item.code) }, "Reset"),
            React.createElement(Button, { variant: "outlined", color: "inherit", onClick: () => toggle() }, "Cancel"))));
};
export const Reset2FA = ({ item, toggle }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleReset2FA = (code) => {
        const clientsService = new ClientsService({
            url: `user/${code}/reset2fa_request`,
            method: 'post',
            baseURL: getEnvSettings().adminApiUrl,
        });
        clientsService.makeRequest()
            .then(() => {
            showNotification({
                message: 'You have succesfully requested for the new two factor code',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RESET_PASSWORD);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement("div", { style: { width: '100%' } },
            "Are you sure you want to reset two factor token for User: ",
            React.createElement("span", null, item.code),
            "?"),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(Button, { variant: "contained", color: "error", onClick: () => handleReset2FA(item.code) }, "Reset"),
            React.createElement(Button, { variant: "outlined", color: "inherit", onClick: () => toggle() }, "Cancel"))));
};
export const Unban = ({ item, toggle, fetchUsers }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleUnban = (code) => {
        const clientsService = new ClientsService({
            url: `/users/${code}/unban`,
            method: 'post',
            baseURL: getEnvSettings().frontendApiUrl,
        });
        clientsService.makeRequest()
            .then(() => {
            fetchUsers();
            showNotification({
                message: 'User successfully unbanned',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.UNBAN_USER);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement("div", { style: { width: '100%' } },
            "Are you sure you want to unban User: ",
            React.createElement("span", null, item.code),
            "?"),
        React.createElement(Box, { style: { display: 'flex', justifyContent: 'end', width: '100%' }, gap: 1 },
            React.createElement(Button, { variant: "contained", color: "primary", onClick: () => handleUnban(item.code) }, "Unban"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Cancel"))));
};
