import { Box, Button, Checkbox, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
export const formConfig = {
    checkbox: {
        enabled: { label: 'Enabled', inputType: 'checkbox' },
        elliptic_support_tx: { label: 'Elliptic Support TX', inputType: 'checkbox' },
        elliptic_support_address: { label: 'Elliptic Support Address', inputType: 'checkbox' },
        trmlabs_support_tx: { label: 'TRMLabs Support TX', inputType: 'checkbox' },
        trmlabs_support_address: { label: 'TRMLabs Support Address', inputType: 'checkbox' },
    },
    text: {
        label: { label: 'Label', inputType: 'text' },
        price_feed_uuid: { label: 'Price Feed UUID', inputType: 'text' },
    },
};
const formSchema = yup.object({
    enabled: yup.boolean().required(),
    elliptic_support_tx: yup.boolean().required(),
    elliptic_support_address: yup.boolean().required(),
    trmlabs_support_tx: yup.boolean().required(),
    trmlabs_support_address: yup.boolean().required(),
    label: yup.string().required(),
    price_feed_uuid: yup.string().required(),
});
const CustodyCurrencyDetails = (props) => {
    const { currencyObj, onCancel, onSave, loading } = props;
    const { handleSubmit, control, register, trigger, setValue, getValues, formState: { errors }, } = useForm({
        defaultValues: currencyObj
            ? formSchema.cast({
                enabled: !!currencyObj.enabled,
                elliptic_support_tx: !!currencyObj.elliptic_support_tx,
                elliptic_support_address: !!currencyObj.elliptic_support_address,
                trmlabs_support_tx: !!currencyObj.trmlabs_support_tx,
                trmlabs_support_address: !!currencyObj.trmlabs_support_address,
                label: currencyObj.label,
                price_feed_uuid: currencyObj.price_feed.uuid,
            })
            : undefined,
        resolver: yupResolver(formSchema),
    });
    return (React.createElement(Box, null,
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 6 }, Object.keys(formConfig.checkbox).map((s, i) => {
                const key = s;
                const field = formConfig.checkbox[key];
                return (React.createElement(Box, { key: i, sx: { display: 'flex', alignItems: 'center' } },
                    React.createElement(Controller, { name: key, control: control, render: ({ field }) => (React.createElement(Checkbox, Object.assign({}, field, { checked: !!getValues(key), onChange: (e, checked) => {
                                field.onChange(checked);
                                setValue(key, checked);
                                trigger(key);
                            } }))) }),
                    React.createElement(Typography, { variant: 'body1' }, field.label)));
            })),
            React.createElement(Grid, { item: true, xs: 6, sx: { display: 'flex', flexDirection: 'column' } }, Object.keys(formConfig.text).map((s, i) => {
                const key = s;
                const field = formConfig.text[key];
                return (React.createElement(Box, { key: i, pt: 2 },
                    React.createElement(TextField, Object.assign({ fullWidth: true, InputLabelProps: { shrink: true }, error: !!errors[key], size: 'small', label: field.label, placeholder: field.label }, register(key), { onChange: (e) => {
                            setValue(key, e.target.value);
                            trigger(key);
                        } }))));
            }))),
        React.createElement(Box, { sx: { display: 'flex', gap: 1, justifyContent: 'flex-end' } },
            React.createElement(LoadingButton, { loading: loading, variant: 'contained', onClick: handleSubmit((data) => onSave(data)) }, "Save"),
            React.createElement(Button, { variant: 'outlined', onClick: () => onCancel() }, "Cancel"))));
};
export default CustodyCurrencyDetails;
