import { Box } from '@mui/material';
import React, { useEffect, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import PlaceOrderBody from './components/PlaceOrderBody';
import PlaceOrderHead from './components/PlaceOrderHead';
import TradingToolPair from './components/TradingToolPair';
import CustomerSearch from './components/CustomerSearch/index';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import showNotification from '../../../../../shared/helpers/notifications';
const PlaceOrder = ({ open, error, customer_code }) => {
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    useEffect(() => {
        if (open) {
            showNotification({
                message: `Error: ${error}`,
                color: 'error',
                dispatch: errorNotice,
            });
        }
    }, [open]);
    return (React.createElement(Box, { p: 1, display: 'flex', flexDirection: 'column', gap: 1 },
        customer_code ? null : React.createElement(CustomerSearch, null),
        React.createElement(PlaceOrderHead, null),
        React.createElement(TradingToolPair, null),
        React.createElement(PlaceOrderBody, null)));
};
const stateToProps = (state) => {
    const { customer_code } = state.client;
    const { open, error } = state.apiErrors;
    return {
        open,
        error,
        customer_code,
    };
};
export default connect(stateToProps)(PlaceOrder);
