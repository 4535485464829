import React, { useMemo, useState, useEffect } from 'react';
import { Chip, Box } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { useResizeDetector } from 'react-resize-detector';
import InstrumentFields from './InstrumentFields';
import { TransfersCellItem } from '../../../../Custody/styling/style';
import { statusBadgeData } from '../../../../../../config/statusBadgeData';
import statusBadgeColor from '../../../../../../shared/helpers/statusBadgeColor';
const memoItem = (item) => item;
const realFields = [
    'min_spread_bps',
    'widening_factor_bps',
    'extra_spread_bps',
    'ul_shift_bps',
    'market_fees_bps',
    'label',
    'calculation_mode',
    'offer_precision',
];
const Main = ({ value, columnKey, item, updateRfqSetting, notional, refPrice, usedPrice, features, columnName, }) => {
    const mItem = useMemo(() => memoItem(item), [item]);
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    const [canUpdate, setCanUpdate] = useState(false);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    useEffect(() => {
        if (features) {
            setCanUpdate(features.includes('client_rfq_settings.update'));
        }
    }, [features]);
    if (realFields.includes(`${columnKey}`) && canUpdate) {
        return (React.createElement(InstrumentFields, { item: mItem, columnKey: columnKey, columnName: columnName, data: value, handleFieldChange: updateRfqSetting }));
    }
    if (columnKey === 'label') {
        return (React.createElement(TableCell, { align: 'left', ref: ref },
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)));
    }
    if (columnKey === 'used_ref_price') {
        return React.createElement(TableCell, { align: 'right' }, React.createElement(Box, { color: '#32C6A1' }, usedPrice) || 'N/A');
    }
    if (columnKey === 'ref_price') {
        return React.createElement(TableCell, { align: 'right' }, React.createElement(Box, { color: '#32C6A1' }, refPrice) || 'N/A');
    }
    if (columnKey === 'notional') {
        return React.createElement(TableCell, { align: 'right' }, React.createElement(Box, { color: '#32C6A1' }, notional) || 'N/A');
    }
    if (columnKey === 'status') {
        return (React.createElement(TableCell, { align: 'right' },
            React.createElement(Chip, { size: 'small', label: value || 'N/A', style: statusBadgeColor(statusBadgeData.stateBackgroundsRFQManagement[(value || '').replace(/-/g, '_')], statusBadgeData.stateBordersRFQManagement[(value || '').replace(/-/g, '_')], statusBadgeData.stateColorsRFQManagement[(value || '').replace(/-/g, '_')]).badge })));
    }
    return React.createElement(TableCell, { align: columnKey === 'ul' ? 'left' : 'right' }, value || 'N/A');
};
export default Main;
