import React from 'react';
import { Box, Chip } from '@mui/material';
import { FilterKey, FilterGroup, } from '../../containers/Dashboards/Crypto/components/MergedOrderList/styling/styledComponents';
/* eslint-disable @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any */
const renderFilterParams = (filterParams, paramKeys) => {
    if (filterParams === null) {
        return null;
    }
    const isObj = (obj) => typeof obj === 'object' && obj !== null;
    return (React.createElement(Box, { p: 1 }, paramKeys.map((key, paramIndex) => (React.createElement(FilterGroup, { key: paramIndex },
        React.createElement(FilterKey, null, key === 'exchange_code' ? 'exchange' : key),
        key === 'Ignore global' ? null : ': ',
        filterParams[key]
            .map((param, badgeIndex) => (param.value ?
            React.createElement(Chip, { size: "small", color: "default", key: badgeIndex, label: isObj(param) ? param.value.toString() : param.toString() }) : null)))))));
};
export default renderFilterParams;
