import React from 'react';
// import PlusIcon from 'mdi-react/PlusIcon';
// import MinusIcon from 'mdi-react/MinusIcon';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import * as Styled from '../../styling/styled';
import { IconButton } from '@mui/material';
const onStepChange = (steps, currentStep, action, callback) => {
    const stepsNum = steps.length;
    const curStepIndex = steps.indexOf(currentStep);
    switch (action) {
        case 'up':
            return ((curStepIndex + 1 < stepsNum)
                ? callback(steps[curStepIndex + 1])
                : false);
        case 'down':
            return ((curStepIndex - 1 >= 0)
                ? callback(steps[curStepIndex - 1])
                : false);
        default:
            throw new Error('Wrong action');
    }
};
const LadderFooter = ({ steps, currentStep, changeStep }) => (React.createElement(Styled.LadderFooter, { className: "price_ladder_border" },
    React.createElement("div", null,
        React.createElement("span", null)),
    React.createElement(Styled.AggregationCount, null,
        React.createElement("span", { className: "price_ladder_header_text price_ladder_aggregation" }, "Aggregation"),
        React.createElement("div", null,
            React.createElement(IconButton, { size: "small", onClick: () => onStepChange(steps, currentStep, 'down', changeStep) },
                React.createElement(RemoveIcon, null)),
            React.createElement("span", { className: "agg-number price_ladder_aggregation_step" }, currentStep),
            React.createElement(IconButton, { size: "small", onClick: () => onStepChange(steps, currentStep, 'up', changeStep) },
                React.createElement(AddIcon, null))))));
export default LadderFooter;
