import React from 'react';
import { Container } from '@mui/material';
import SettlementLinesTable from './SettlementLinesTable';
/* eslint-disable react/no-redundant-should-component-update, no-param-reassign */
class SettlementLines extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            layouts: [],
        };
    }
    render() {
        return (React.createElement("div", { className: 'content-body' },
            React.createElement(Container, { maxWidth: "xl" },
                React.createElement(SettlementLinesTable, null))));
    }
}
export default SettlementLines;
