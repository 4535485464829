import React, { useState, useEffect } from 'react';
import Decimal from 'decimal.js';
import { Box } from '@mui/material';
import Alert from '@mui/material/Alert';
import { connect, useDispatch } from 'react-redux';
import { NEW_ORDER_CREATE_REQUEST, NEW_PARTIAL_ORDER_CREATE_REQUEST, } from '../../../../../../redux/actionTypes/placeOrderActionTypes';
import PlaceOrderButton from './PlaceOrderButton';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
const dispatchPlacerOrder = (dispatch, payload, closeErrorNotice) => {
    dispatch({ type: NEW_ORDER_CREATE_REQUEST, payload, closeErrorNotice });
};
const dispatchPlacePartialOrder = (dispatch, payload, callbackToggleModal) => {
    dispatch({ type: NEW_PARTIAL_ORDER_CREATE_REQUEST, payload, callbackToggleModal });
};
const PlacerOrderFooter = ({ action, selectedExchange, orderType, selectedBase, selectedQuote, amount, features, limitPrice, isPartial, newOrderLoading, newPartialLoading, toggleModal, referenceId, tooShallowAmount, selectedCustomer, splitStrategy, validation, isValid, validationMessage, modalOpen, masterTenant, }) => {
    const [showValidationAlert, setShowValidationAlert] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!isValid) {
            setShowValidationAlert(true);
        }
        else {
            setShowValidationAlert(false);
        }
    }, [isValid, selectedCustomer]);
    const closeErrorNotice = () => {
        dispatch({ type: CLOSE_ERROR_NOTICE });
    };
    const handleOrderCreate = () => {
        if (!validation(amount, limitPrice, orderType === null || orderType === void 0 ? void 0 : orderType.value, isPartial)) {
            setShowValidationAlert(true);
            return;
        }
        setShowValidationAlert(false);
        const globalSelectedCustomer = selectedCustomer && selectedCustomer.code;
        if (globalSelectedCustomer) {
            const payload = {
                action,
                limit_price: limitPrice,
                reference_id: referenceId,
                execution_strategy: 'Manual',
                type: (orderType === null || orderType === void 0 ? void 0 : orderType.value) || '',
                base: (selectedBase === null || selectedBase === void 0 ? void 0 : selectedBase.value) || '',
                quote: (selectedQuote === null || selectedQuote === void 0 ? void 0 : selectedQuote.value) || '',
                amount: new Decimal(amount).toString(),
                exchange_code: (selectedExchange === null || selectedExchange === void 0 ? void 0 : selectedExchange.value) || '',
                customer_code: selectedCustomer ? selectedCustomer.code : '',
                split_strategy: splitStrategy.label,
            };
            if (isPartial) {
                dispatchPlacePartialOrder(dispatch, payload, toggleModal);
            }
            else {
                dispatchPlacerOrder(dispatch, payload, closeErrorNotice);
            }
        }
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        isPartial
            ? (React.createElement(PlaceOrderButton, { action: action, features: features, modalOpen: modalOpen, loading: newPartialLoading, handleClick: handleOrderCreate, tooShallowAmount: tooShallowAmount, locked: !selectedCustomer || !amount }))
            : (React.createElement(PlaceOrderButton, { action: action, features: features, modalOpen: modalOpen, loading: newOrderLoading, handleClick: handleOrderCreate, tooShallowAmount: tooShallowAmount, locked: !selectedCustomer || !amount })),
        !showValidationAlert ? null :
            React.createElement(Alert, { severity: "error", variant: "filled" }, validationMessage)));
};
const stateToProps = (state) => {
    const { amount, referenceId, limitPrice, selectedExchange, orderType, selectedQuote, newPartialLoading, newOrderLoading, selectedBase, } = state.placeOrder;
    const { features, masterTenant } = state.client;
    const { selectedCustomer } = state.customersSearch;
    return {
        amount,
        features,
        orderType,
        limitPrice,
        referenceId,
        masterTenant,
        selectedBase,
        selectedQuote,
        newOrderLoading,
        selectedCustomer,
        selectedExchange,
        newPartialLoading,
    };
};
export default connect(stateToProps)(PlacerOrderFooter);
