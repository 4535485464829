import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Box, Button, TextField, CircularProgress } from '@mui/material';
import Messages from '../../../../../shared/helpers/errorMessages';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import TradingTransfersService from '../../../../../services/tradingTransfersService';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
export const DeclineWithdrawal = ({ item, toggle, cellAction }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleDeclineWithdrawal = (code) => {
        const service = new TradingTransfersService({
            url: `/trade/trading_transfer/withdrawal/${code}/decline`,
            method: 'patch',
            data: {},
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction();
            showNotification({
                message: 'Withdrawal successfully declined',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            setLoading(false);
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.TRADING_WITHDRAWAL_DECLINE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, null,
            "Are you sure you want to decline withdrawal: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(Box, { display: 'flex', justifyContent: 'end', width: '100%', gap: 1 },
            React.createElement(Button, { color: "primary", variant: "contained", onClick: () => handleDeclineWithdrawal(item.code) }, "Decline"),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Cancel"))));
};
export const ApproveWithdrawal = ({ item, toggle, cellAction }) => {
    const [loading, setLoading] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState('');
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onDetailsChange = (evt) => {
        const { value } = evt.target;
        setTransactionDetails(value);
    };
    const handleApproveWithdrawal = (code) => {
        const payload = {
            transaction_details: transactionDetails,
        };
        const service = new TradingTransfersService({
            url: `/trade/trading_transfer/withdrawal/${code}/done`,
            method: 'patch',
            data: payload,
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction();
            showNotification({
                message: 'Withdrawal successfully approved',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            setLoading(false);
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.TRADING_WITHDRAWAL_APPROVE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, null,
            "You are approving withdrawal: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", variant: "outlined", label: "Transaction id", value: transactionDetails, onChange: onDetailsChange, InputLabelProps: { shrink: true }, placeholder: "Type transaction details" }),
        React.createElement(Box, { display: 'flex', justifyContent: 'end', width: '100%', gap: 1 },
            React.createElement(Button, { variant: "contained", color: "primary", disabled: !transactionDetails, onClick: () => handleApproveWithdrawal(item.code) }, "Approve"),
            React.createElement("span", { style: { width: '15px', display: 'inline-block' } }),
            React.createElement(Button, { variant: "outlined", onClick: () => toggle() }, "Close"))));
};
export const RevertDeposit = ({ item, toggle, cellAction }) => {
    const [totp, setTotp] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const onTotpChange = (evt) => {
        const { value } = evt.target;
        const val = value.trim();
        if (isNaN(Number(val)) || value.length > 6) {
            return;
        }
        setTotp(value);
    };
    const handleRevertDeposit = (code) => {
        const service = new TradingTransfersService({
            url: `/trade/trading_transfer/deposit/${code}/revert`,
            method: 'post',
            data: { totp_code: totp },
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction();
            showNotification({
                message: 'Deposit successfully reverted',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            setLoading(false);
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.TRADING_DEPOSIT_REVERT);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, null,
            "Reverting deposit: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(TextField, { fullWidth: true, size: "small", value: totp, label: "Totp", margin: "normal", variant: "outlined", onChange: onTotpChange, InputLabelProps: { shrink: true } }),
        React.createElement(Box, { display: 'flex', justifyContent: 'end', width: '100%', gap: 1 },
            React.createElement(Button, { color: "primary", variant: "contained", disabled: totp.length !== 6, onClick: () => handleRevertDeposit(item.code) }, "Revert"),
            React.createElement(Button, { variant: "outlined", onClick: toggle }, "Close"))));
};
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
export const SignWithdrawal = ({ item, toggle, cellAction }) => {
    const [totp, setTotp] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const helperTextStyles = useHelperTextStyles();
    const onTotpChange = (evt) => {
        const { value } = evt.target;
        const val = value.trim();
        if (isNaN(Number(val)) || val.length > 6) {
            return;
        }
        setTotp(value);
    };
    const handleSignWithdrawal = (code) => {
        const payload = {
            code,
            totp_code: totp,
        };
        const service = new TradingTransfersService({
            url: '/trading_transfer/withdrawal/sign',
            method: 'post',
            data: payload,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction();
            showNotification({
                message: 'Withdrawal successfully signed',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            setLoading(false);
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.TRADING_WITHDRAWAL_SIGN);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, null,
            "Signing withdrawal: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(TextField, { fullWidth: true, size: "small", value: totp, label: "Totp", margin: "normal", variant: "outlined", onChange: onTotpChange, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${totp.length !== 6 ? '2fa code should be at least 6 chars long' : ''}` }),
        React.createElement(Box, { display: 'flex', justifyContent: 'end', width: '100%', gap: 1 },
            React.createElement(Button, { color: "primary", variant: "outlined", disabled: totp.length !== 6, onClick: () => handleSignWithdrawal(item.code) }, "Sign"),
            React.createElement(Button, { variant: "outlined", onClick: toggle }, "Close"))));
};
