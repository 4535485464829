import React from 'react';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import getCustomerNameByCode from '../../../../../shared/helpers/getCustomerNameByCode';
const StrategiesTableCell = ({ value, columnKey, customers }) => {
    if (columnKey === 'code') {
        return (React.createElement(TableCell, { align: "left" }, value || 'N/A'));
    }
    if (columnKey === 'customer_code') {
        return (React.createElement(TableCell, { align: "right" },
            React.createElement("span", null, getCustomerNameByCode(customers, value) || 'N/A')));
    }
    if (columnKey === 'created_at') {
        return (React.createElement(TableCell, { align: "right" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a'));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default StrategiesTableCell;
