import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Chip, Container, IconButton, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EditIcon from '@mui/icons-material/Edit';
import ClientInfoComponent from '../ClientInfoComponent';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import ClientModal from '../../../../Crypto/components/ClientSearch/ClientModal';
import Messages from '../../../../../../shared/helpers/errorMessages';
import CopyValue from '../../../../../../shared/components/CopyValueComponent';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { connect } from 'react-redux';
import { CUSTOMER_UPDATE } from '../../../../../../redux/actionTypes/customerSearchActionsTypes';
import { Amount } from '../../../../../../shared/components/styled/amount';
const customerUpdate = (customer) => ({
    type: CUSTOMER_UPDATE,
    customer,
});
const CustomerDetailsContainer = (props) => {
    const { dispatchCustomerUpdate, clients } = props;
    const history = useHistory();
    const params = useParams();
    const [customer, setCustomer] = useState();
    const [editCustomer, setEditCustomer] = useState(false);
    useEffect(() => {
        getCustomer();
    }, []);
    const handleNavigate = () => {
        history.goBack();
    };
    const getCustomer = () => {
        const requestConfig = {
            url: `/customers/${params.id}?details=true`,
            method: 'get',
        };
        axios
            .request(requestConfig)
            .then((response) => {
            setCustomer(response.data);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTOMER_FETCH);
            showNotification({ message, color: 'error' });
        });
    };
    const handleUpdateCustomer = (payload) => {
        if (!customer) {
            return;
        }
        const requestConfig = {
            url: `/customers/${customer === null || customer === void 0 ? void 0 : customer.code}`,
            method: 'patch',
            data: payload,
            baseURL: getEnvSettings().apiUrl,
        };
        axios
            .request(requestConfig)
            .then((response) => {
            setCustomer(response.data);
            dispatchCustomerUpdate(response.data);
            setEditCustomer(false);
            showNotification({
                message: 'Customer successfully updated',
                color: 'success',
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTOMER_UPDATE);
            showNotification({
                message,
                color: 'error',
            });
        });
    };
    return (React.createElement(Container, null,
        React.createElement(Box, null,
            React.createElement(Box, { py: 2, gap: 2, sx: { display: 'flex', justifyContent: 'space-between' } },
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 1 },
                    React.createElement(IconButton, { size: 'small', onClick: () => handleNavigate() },
                        React.createElement(ArrowBackIosNewIcon, null)),
                    React.createElement(AccountCircleIcon, { fontSize: 'large', color: 'secondary' }),
                    React.createElement(Typography, { variant: 'h6' }, customer === null || customer === void 0 ? void 0 : customer.company_name),
                    React.createElement(IconButton, { size: 'small', onClick: () => setEditCustomer(!editCustomer) },
                        React.createElement(EditIcon, { fontSize: 'small' })),
                    customer && (React.createElement(ClientModal, { user: 'customer', currentUser: customer, open: editCustomer, toggleFunc: () => {
                            setEditCustomer(false);
                        }, updateClient: handleUpdateCustomer, createClient: () => { } }))),
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center', flexDirection: 'column' }, gap: 1 },
                    React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 1 },
                        React.createElement(Typography, { variant: "subtitle2" }, "Customer code"),
                        React.createElement(Chip, { label: React.createElement(Amount, null, customer === null || customer === void 0 ? void 0 : customer.code), color: 'secondary' }),
                        React.createElement(CopyValue, { value: (customer === null || customer === void 0 ? void 0 : customer.code) || '' })))),
            React.createElement(ClientInfoComponent, { details: customer, clients: clients }))));
};
const mapDispatchToProps = (dispatch) => ({
    dispatchCustomerUpdate: (customer) => dispatch(customerUpdate(customer)),
});
const stateToProps = (state) => {
    const clients = state.clientSearch.allClients;
    return {
        clients,
    };
};
export default connect(stateToProps, mapDispatchToProps)(CustomerDetailsContainer);
