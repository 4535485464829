import React, { useState, useEffect, useCallback } from 'react';
import { Box, List } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import ListItem from '@mui/material/ListItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../Custody/styling/style';
import TableCellContainer from './TableCellContainer';
import { PanelContainer } from '../../../Admin/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import showNotification from '../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../redux/selectors/custodySelectors';
import AutohedgerBalanceService from '../../../../../services/autohedgerBalanceService';
import { materialUiPaginator } from '../../../../../shared/helpers/recordsDependsOnPage';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../redux/actionTypes/widgetSettingsActionTypes';
const AutohedgerBalance = ({ quoteWidgetHeight, columns }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const [maxHeight, setMaxHeight] = useState(480);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [balances, setBalances] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const [autohedgersToRender, setAutohedgersToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('autohedgerBalance'));
    };
    const onPageChange = (event, pageNumber) => {
        setPage(pageNumber);
    };
    const fetchAutohedgerBalance = () => {
        const rfqService = new AutohedgerBalanceService({
            url: '/trade/autohedger/balances',
            method: 'get',
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        rfqService.makeRequest()
            .then((data) => {
            setLoading(false);
            const arr = [];
            if (data.autoHedgingCustomer) {
                const autoArr = data.autoHedgingCustomer.map(a => (Object.assign(Object.assign({}, a), { type: 'autoHedgin' })));
                arr.push(...autoArr);
            }
            if (data.delta) {
                const deltaArr = data.delta.map(a => (Object.assign(Object.assign({}, a), { type: 'delta' })));
                arr.push(...deltaArr);
            }
            if (data.dltVirtualExchange) {
                const virtualArr = data.dltVirtualExchange.map(a => (Object.assign(Object.assign({}, a), { type: 'dltVirtualExchange' })));
                arr.push(...virtualArr);
            }
            setBalances(arr);
            setTotalPages(arr.length);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.AUTOHEDGER_BALANCE_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            setLoading(false);
        });
    };
    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            fetchAutohedgerBalance();
        }
        return () => {
            isCancelled = true;
        };
    }, []);
    useEffect(() => {
        if (balances.length) {
            const items = materialUiPaginator(balances, page, rowsPerPage);
            setAutohedgersToRender([...items]);
        }
    }, [balances, page, rowsPerPage]);
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(quoteWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [quoteWidgetHeight]);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return React.createElement(React.Fragment, null,
        React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" },
            React.createElement(WalletsClientContainer, null,
                React.createElement("div", null,
                    React.createElement("span", { className: "custody_clients_table_settings" },
                        React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                            React.createElement(ViewColumnIcon, null)),
                        React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                            React.createElement(MaterialPopoverWrap, null,
                                React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                    React.createElement(ListItemText, { primary: col.title }),
                                    React.createElement(ListItemSecondaryAction, null,
                                        React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } }))))))))))),
            React.createElement(MaterialTableWrap, null,
                React.createElement(Paper, { className: "mui_table_root" },
                    React.createElement(TableContainer, { style: { maxHeight } },
                        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null, columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'base' ? 'left' : 'right' },
                                    React.createElement(TableSortLabel, { direction: "asc" }, column.title)))))),
                            React.createElement(TableBody, null, autohedgersToRender.length === 0
                                ? (React.createElement(TableRow, null,
                                    React.createElement(TableCell, { colSpan: columnsToRender.length + 1 },
                                        React.createElement(Typography, { className: "transactions_typography_padded", variant: "subtitle1" }, "No items found"))))
                                : autohedgersToRender.map((a, index) => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: index }, columnsToRender.map((column, i) => {
                                    const key = column.key;
                                    const value = key ? a[key] : '';
                                    return React.createElement(TableCellContainer, { key: i, value: value, columnKey: key });
                                }))))))),
                    React.createElement(TablePagination, { page: page, component: "div", count: totalPages, rowsPerPage: rowsPerPage, onPageChange: onPageChange, rowsPerPageOptions: [maxHeight] })))));
};
const mapStateToProps = (state, ownProps) => ({
    columns: getWidgetColumns(state, ownProps.widgetKey),
    quoteWidgetHeight: state.widgets[ownProps.widgetKey],
});
export default connect(mapStateToProps)(AutohedgerBalance);
