/* eslint-disable @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-call */
import React, { useEffect, useState, useRef } from 'react';
import { Button, Dialog, TextField, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { decodeUTF8 } from 'tweetnacl-util';
import nacl from 'tweetnacl';
import { makeStyles } from '@mui/styles';
import { CreateWithdrawal } from '../../../Custody/styling/style';
import Messages from '../../../../../shared/helpers/errorMessages';
import ApiTokenService from '../../../../../services/apiTokenService';
import showNotification from '../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
const useHelperTextStyles = makeStyles(() => ({
    root: { color: '#ff4861' },
}));
const AddNewModal = ({ open, toggleFunc, updateList, errorNotice, page, }) => {
    const dirty = useRef(false);
    const [label, setLabel] = useState('');
    const [publicKey, setPublicKey] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [stateKp, setKp] = useState(null);
    const [invalidFields, setInvalidFields] = useState([]);
    const helperTextStyles = useHelperTextStyles();
    const buf2hex = (buffer) => Array
        .prototype
        .map
        .call(new Uint8Array(buffer), x => (`00${x.toString(16)}`).slice(-2)).join('');
    useEffect(() => {
        const kp = nacl.sign.keyPair();
        const pKey = buf2hex(kp.publicKey);
        const sKey = buf2hex(kp.secretKey);
        setLabel('');
        setPublicKey(pKey);
        setSecretKey(sKey);
        setKp(kp);
    }, [open]);
    const handleUpdateLabel = (evt) => {
        const { value } = evt.target;
        setLabel(value);
    };
    const validateFields = (fieldsToValidate) => {
        const errors = [];
        for (const [key, value] of Object.entries(fieldsToValidate)) {
            if (`${value}`.trim() === '') {
                errors.push(key);
            }
        }
        return errors;
    };
    const handleAddToken = () => {
        dirty.current = true;
        if (!stateKp) {
            return;
        }
        const errors = validateFields({ label });
        if (errors.length) {
            setInvalidFields([...errors]);
            return;
        }
        const payload = {
            label,
            public_key: publicKey,
            signature: buf2hex(nacl.sign.detached(decodeUTF8(publicKey + label), stateKp.secretKey)),
        };
        const apiTokenService = new ApiTokenService({
            url: '/user/api_tokens',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().frontendApiUrl,
        });
        apiTokenService.makeRequest()
            .then(() => {
            showNotification({
                message: 'API Token successfully added',
                color: 'success',
                dispatch: errorNotice,
            });
            updateList(page);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.API_TOKEN_ADD);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
        toggleFunc();
    };
    useEffect(() => {
        if (dirty.current) {
            const errors = validateFields({ label });
            setInvalidFields([...errors]);
        }
    }, [label]);
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Add new token"),
        React.createElement(DialogContent, null,
            React.createElement(TextField, { fullWidth: true, size: "small", value: label, label: "Label", margin: "normal", variant: "outlined", onChange: handleUpdateLabel, InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: `${invalidFields.includes('label') ? 'Field is required' : ''}` }),
            React.createElement(TextField, { disabled: true, fullWidth: true, size: "small", margin: "normal", value: publicKey, variant: "outlined", label: "Public key", InputLabelProps: { shrink: true } }),
            React.createElement(TextField, { disabled: true, fullWidth: true, size: "small", margin: "normal", value: secretKey, variant: "outlined", label: "Secret key", InputLabelProps: { shrink: true }, FormHelperTextProps: { classes: { root: helperTextStyles.root } }, helperText: "Please save, this information will no longer be displayed" })),
        React.createElement(DialogActions, null,
            React.createElement(CreateWithdrawal, null,
                React.createElement(Button, { color: "primary", variant: "contained", onClick: handleAddToken, disabled: invalidFields.includes('label') }, "Add")),
            React.createElement(Button, { variant: "outlined", onClick: () => { toggleFunc(); } }, "Cancel"))));
};
export default AddNewModal;
