import { Box, Button, Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DialogHeader from '../../../../../shared/components/DialogHeader';
const formSchema = yup.object({
    url: yup.string().required('Field is required').min(1),
});
const AddWebhookDialog = (props) => {
    var _a;
    const { formState: { errors, isValid, isDirty }, register, handleSubmit, trigger, setValue, } = useForm({
        resolver: yupResolver(formSchema),
    });
    const onInputChange = (fieldName, value) => {
        setValue(fieldName, value);
        trigger(fieldName);
    };
    return (React.createElement(Dialog, { open: props.open, fullWidth: true, maxWidth: 'sm' },
        React.createElement(DialogHeader, { title: 'Add new webhook', toggleFunc: props.toggle }),
        React.createElement(Box, { component: 'form', onSubmit: handleSubmit((data) => props.onAdd(data.url)), pt: 1 },
            React.createElement(DialogContent, null,
                React.createElement(TextField, Object.assign({ error: !!errors.url }, register('url'), { helperText: ((_a = errors.url) === null || _a === void 0 ? void 0 : _a.message) || ' ', label: 'Url', size: 'small', fullWidth: true, onChange: (event) => onInputChange('url', event.target.value) }))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { size: 'small', disabled: isDirty && !isValid, variant: 'contained', type: 'submit' }, "Add"),
                React.createElement(Button, { size: 'small', onClick: props.toggle }, "Cancel")))));
};
export default AddWebhookDialog;
