/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, TableCell, TextField, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
const AutohedgerFields = ({ data, handleFieldChange, columnKey, item, }) => {
    const [fieldValue, setFieldValue] = useState('0');
    const [dialogOpen, toggleOpenDialog] = useState(false);
    const toggleDialog = () => {
        toggleOpenDialog(prev => !prev);
    };
    const onFieldChange = (evt) => {
        const { value } = evt.target;
        setFieldValue(value);
    };
    useEffect(() => {
        setFieldValue(data);
    }, [data]);
    const onReferenceIdChangeDone = () => {
        const payload = {
            base_code: item.base,
            quote_code: item.quote,
            threshold: item.threshold,
            min_amount: item.min_amount,
            daily_limit: item.daily_limit,
            auto_hedging: item.auto_hedging,
        };
        const updatedPayload = Object.assign(Object.assign({}, payload), { [columnKey]: fieldValue });
        handleFieldChange(updatedPayload, 'updated');
        toggleDialog();
    };
    return (React.createElement(TableCell, { align: "right", style: { cursor: 'pointer' } },
        React.createElement(Box, { display: 'flex', flexDirection: 'row', justifyContent: 'end', p: 1 },
            React.createElement(Box, { margin: 'auto 0' }, data || 'N/A'),
            React.createElement(Box, null,
                React.createElement(Button, { color: "primary", variant: "text", onClick: toggleDialog, style: { minWidth: '40px' }, startIcon: React.createElement(EditIcon, { fontSize: "small" }) }))),
        React.createElement(Dialog, { open: dialogOpen, onClose: () => toggleDialog(), maxWidth: "sm", fullWidth: true },
            React.createElement(DialogTitle, { id: `form-dialog-title${columnKey}` }, "Update field"),
            React.createElement(DialogContent, null,
                React.createElement("div", null,
                    React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", value: fieldValue, variant: "outlined", onChange: onFieldChange, InputLabelProps: { shrink: true } })),
                React.createElement("div", null,
                    React.createElement(Button, { color: "primary", variant: "contained", onClick: onReferenceIdChangeDone, className: "custody_actions_button_margin" }, "UPDATE"))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: () => toggleDialog(), variant: "outlined" }, "Cancel")))));
};
export default AutohedgerFields;
