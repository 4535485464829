import styled from 'styled-components';
export const TopbarLinkContainer = styled.div `
  display: flex;
  > a {
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
`;
export const LinkTitle = styled.span `
  text-transform: uppercase;
`;
export const LogoImg = styled.img `
  width: auto;
  height: 48px;
  margin-top: 6px;
  margin-left: 10px;
`;
