import React, { useReducer, useEffect } from 'react';
import moment from 'moment';
import buildUrl from 'build-url';
import { connect } from 'react-redux';
import WithdrawalFiltersContent from './WithdrawalFiltersContent';
import { collectParamsFromFilters } from '../../../../../shared/helpers/createUrlFromParams';
import { CUSTODY_WITHDRAWALS_OPTIONS_UPDATE } from '../../../../../redux/actionTypes/custodyActionTypes';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
import { getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["ADD"] = "ADD";
    FilterActionType["RESET"] = "RESET";
    FilterActionType["UPDATE"] = "UPDATE";
    FilterActionType["DELETE"] = "DELETE";
})(FilterActionType || (FilterActionType = {}));
export const UrlOptionsUpdate = (urlOptionsWithdrawals) => ({
    type: CUSTODY_WITHDRAWALS_OPTIONS_UPDATE,
    urlOptionsWithdrawals,
});
const init = () => ({
    selectedFilters: [],
});
const reducer = (state, action) => {
    switch (action.type) {
        case FilterActionType.ADD:
            return {
                selectedFilters: [...state.selectedFilters, action.item],
            };
        case FilterActionType.DELETE:
            return {
                selectedFilters: state.selectedFilters
                    .filter(f => f.label !== action.item.label),
            };
        case FilterActionType.UPDATE: {
            const objIndex = state.selectedFilters
                .findIndex((obj => obj.name === action.item.name));
            const newArr = state.selectedFilters;
            if (newArr.length) {
                newArr[objIndex].value = action.item.value;
                newArr[objIndex].label = action.item.label;
            }
            return { selectedFilters: newArr };
        }
        case FilterActionType.RESET:
            return init();
        default:
            return state;
    }
};
const WithdrawalFilters = ({ sortBy, features, clientCode, selectedClient, toggleModal, currentPage, rowsPerPage, allCurrencies, sortDirection, setCurrentPage, onFiltersChange, initialValue = '', UrlOptionsUpdateDispatch, }) => {
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const addSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.ADD, item: val });
    };
    const removeSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.DELETE, item: val });
    };
    const updateSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.UPDATE, item: val });
    };
    const resetFilters = () => {
        dispatch({ type: FilterActionType.RESET });
    };
    const handleDateChange = (evt, label, name) => {
        const arr = evt.target.value.split('-');
        const yy = arr[0];
        const mm = arr[1];
        const dd = arr[2];
        const date = new Date();
        date.setFullYear(+yy);
        date.setMonth(+mm - 1);
        date.setDate(+dd);
        date.setHours(0, 0, 0, 0);
        const item = {
            label,
            name,
            type: 'date',
            value: moment(date).isValid() ? moment(date).format() : '',
        };
        const isFilter = state.selectedFilters.find(f => f.name === name);
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleCurrencyChange = (option) => {
        const item = {
            name: 'currency_code',
            type: 'currency',
            label: option ? option.value : '',
            value: option ? option.value.toLowerCase() : '',
        };
        const isFilter = state.selectedFilters.find(f => f.name === 'currency_code');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleStateChange = (option, reason, details) => {
        if (reason === 'selectOption') {
            const last = option && option[option.length - 1];
            const item = {
                name: 'state',
                type: 'state',
                label: (last === null || last === void 0 ? void 0 : last.value) || '',
                value: (last === null || last === void 0 ? void 0 : last.value) || '',
            };
            addSelectedFilter(item);
        }
        else if (reason === 'removeOption') {
            const item = {
                name: 'state',
                type: 'state',
                label: (details === null || details === void 0 ? void 0 : details.option.label) || '',
                value: (details === null || details === void 0 ? void 0 : details.option.value) || '',
            };
            removeSelectedFilter(item);
        }
        else if (reason === 'clear') {
            const isStates = state.selectedFilters.filter(f => f.name === 'state');
            if (isStates.length > 0) {
                isStates.forEach((f) => {
                    removeSelectedFilter(f);
                });
            }
        }
    };
    const buildUrlParams = (params, by, page, direction, limit, code) => {
        const paramsNames = Object.keys(params);
        const urlOptions = {
            queryParams: {
                sort_by: by,
                clientCode: code,
                limit: `${limit}`,
                page: `${page + 1}`,
                sort_direction: direction,
            },
        };
        paramsNames.forEach((key) => {
            if (Array.isArray(params[key])) {
                if (params[key].length === 1) {
                    urlOptions.queryParams[key] = params[key].join('');
                }
                else {
                    urlOptions.queryParams[key] = params[key].join('|');
                }
            }
        });
        const url = buildUrl('', urlOptions) === '?' ? '' : buildUrl('', urlOptions);
        return url;
    };
    useEffect(() => {
        setCurrentPage(0);
    }, [state]);
    useEffect(() => {
        resetFilters();
    }, [selectedClient]);
    useEffect(() => {
        const buildUrlOptions = () => {
            const collectedParams = collectParamsFromFilters(state.selectedFilters);
            const adminFilterUrl = buildUrlParams(collectedParams, sortBy, currentPage, sortDirection, rowsPerPage, clientCode);
            return adminFilterUrl;
        };
        const url = buildUrlOptions();
        onFiltersChange(url);
        UrlOptionsUpdateDispatch(url);
    }, [state, selectedClient, sortBy, currentPage, sortDirection, rowsPerPage, clientCode]);
    return (React.createElement(WithdrawalFiltersContent, { state: state, features: features, toggleModal: toggleModal, resetFilters: resetFilters, allCurrencies: allCurrencies, handleDateChange: handleDateChange, handleStateChange: handleStateChange, removeSelectedFilter: removeSelectedFilter, handleCurrencyChange: handleCurrencyChange }));
};
const mapStateToProps = (state) => ({
    allCurrencies: getCustodyCurrenciesOptions(state),
    selectedClient: getSelectedClientInputValue(state),
});
const mapDispatchToProps = dispatch => ({
    UrlOptionsUpdateDispatch: (urlOptions) => dispatch(UrlOptionsUpdate(urlOptions)),
    initialValue: '',
});
export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalFilters);
