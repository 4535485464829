import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';
import { ModalLoadingContainer, ModalSpinnerContainer } from '../../../Custody/styling/style';
const ExchangeModal = ({ open, enableExchange, disableExchange, toggleFunc, loadingEnable, loadingDisable, exchangeName, enabled, }) => {
    const loading = enabled ? loadingEnable : loadingDisable;
    const handleButton = () => {
        enabled ? disableExchange() : enableExchange();
    };
    if (loading) {
        return (React.createElement(ModalLoadingContainer, null,
            React.createElement("h4", null, "Loading"),
            React.createElement(ModalSpinnerContainer, null,
                React.createElement(CircularProgress, { size: "33px" }))));
    }
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" },
            enabled ? 'Disable' : 'Enable',
            " Exchange"),
        React.createElement(DialogContent, null,
            React.createElement("div", { className: "form_container_padding_flex" },
                "Are you sure want to ",
                enabled ? 'disable' : 'enable',
                " trading on ",
                exchangeName,
                "?")),
        React.createElement(DialogActions, null,
            React.createElement(Button, { variant: "contained", size: "small", onClick: handleButton, color: "primary" }, enabled ? 'Disable' : 'Enable'),
            React.createElement(Button, { variant: "outlined", color: "inherit", size: "small", onClick: () => { toggleFunc(); } }, "Close"))));
};
export default ExchangeModal;
