import React from 'react';
import Decimal from 'decimal.js';
const AskSize = ({ size }) => (React.createElement("div", { className: "container_trade_color_red" }, size === ''
    ? React.createElement("span", null, "--")
    : React.createElement("span", null, size)));
/* eslint-disable camelcase */
const CmlAskSize = ({ orderBookItem }) => {
    if (orderBookItem && orderBookItem.ask && Object.keys(orderBookItem.ask).length) {
        const { volume, volume_split } = orderBookItem.ask;
        const splitVolumeArr = volume_split ? Object.values(volume_split) : [];
        const sumVolume = splitVolumeArr.reduce((acm, item) => Decimal.add(acm, item).toFixed(), '0');
        const size = new Decimal(volume_split ? sumVolume : volume)
            .toFixed(4)
            .replace(/\.?0+$/, '');
        return React.createElement(AskSize, { size: size });
    }
    return (React.createElement("div", { className: "container_trade_color_red" },
        React.createElement("span", null, "--")));
};
export default React.memo(CmlAskSize);
