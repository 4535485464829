import React, { useState, useCallback } from 'react';
import Decimal from 'decimal.js';
import { Box } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import SelectExchange from './SelectExchange';
import SelectOrderType from './SelectOrderType';
import PlacerOrderFooter from './PlacerOrderFooter';
import PartialOrderCheckbox from './PartialOrderCheck';
import SelectPricingAmount from './SelectPricingAmount';
import SelectExecutionStyle from './SelectExecutionStyle';
import PlaceOrderModalContainer from './PlaceOrderModalContainer';
import { getAvailableExchanges } from '../../../../../../redux/selectors/selectors';
import { SUBORDERS_ORDER_CLEAN } from '../../../../../../redux/actionTypes/subOrdersActionTypes';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
const OrderFormContainer = ({ action, partialOrder, exchanges, selectedPair, }) => {
    const [isValid, setIsValid] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [validationMessage, setMessage] = useState('');
    const [tooShallowAmount, setTooShallowAmount] = useState(false);
    const [splitStrategy, setSplitStrategy] = useState({ value: 'manual', label: 'Manual' });
    const updateTooShallow = useCallback((value) => {
        setTooShallowAmount(value);
    }, [tooShallowAmount]);
    const dispatch = useDispatch();
    const toggleSubOrderModal = () => {
        setModalOpen((prevState) => {
            if (prevState) {
                // On modal close - clean stored suborders
                dispatch({ type: SUBORDERS_ORDER_CLEAN });
                dispatch({ type: 'STOP_SUBORDERS_STATUS_POLLING' });
            }
            return !modalOpen;
        });
    };
    const validateFields = (amount, limit, type, partial) => {
        if (!amount) {
            setMessage('Amount field is invalid');
            setIsValid(false);
            return false;
        }
        if (new Decimal(amount).isZero()) {
            setMessage('Order amount can not equal 0');
            setIsValid(false);
            return false;
        }
        if (type === 'Limit' && !partial) {
            if (!limit || new Decimal(limit).isZero()) {
                setMessage('Limit price is required for Limit order type');
                setIsValid(false);
                return false;
            }
        }
        if (exchanges.length === 0) {
            setMessage(`Not available for selected ${selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.base}/${selectedPair === null || selectedPair === void 0 ? void 0 : selectedPair.quote}`);
            setIsValid(false);
            return false;
        }
        setIsValid(true);
        return true;
    };
    const onPartialCheckboxChange = (value) => {
        dispatch({ type: 'SET_UPDATE_PARTIAL_TYPE', value });
    };
    const onChangeExecutionStyle = (value) => {
        setSplitStrategy(value);
    };
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
        React.createElement(PartialOrderCheckbox, { label: "Split order", check: partialOrder, validation: validateFields, name: `splitOrder${action}`, onChange: onPartialCheckboxChange }),
        !partialOrder
            && (React.createElement(Box, { display: 'flex', gap: 1 },
                React.createElement(Box, { flex: "50%" },
                    React.createElement(SelectExchange, null)),
                React.createElement(Box, { flex: "50%" },
                    React.createElement(SelectOrderType, { partial: partialOrder, validation: validateFields })))),
        partialOrder && React.createElement(SelectExecutionStyle, { value: splitStrategy, onChange: onChangeExecutionStyle }),
        React.createElement(SelectPricingAmount, { action: action, isPartial: partialOrder, validation: validateFields, updateTooShallow: updateTooShallow }),
        React.createElement(PlacerOrderFooter, { action: action, isValid: isValid, modalOpen: modalOpen, isPartial: partialOrder, validation: validateFields, splitStrategy: splitStrategy, toggleModal: toggleSubOrderModal, tooShallowAmount: tooShallowAmount, validationMessage: validationMessage }),
        partialOrder && React.createElement(PlaceOrderModalContainer, { modalOpen: modalOpen, toggleModal: toggleSubOrderModal })));
};
const stateToProps = (state) => {
    const { partialOrder, selectedPair } = state.placeOrder;
    return {
        partialOrder,
        selectedPair,
        exchanges: getAvailableExchanges(state),
    };
};
export default connect(stateToProps)(OrderFormContainer);
