import React, { useState } from 'react';
import { Box, Button, TextField, IconButton, InputAdornment, Container, Paper, Typography, Link, } from '@mui/material';
import QRCode from 'qrcode.react';
import Alert from '@mui/material/Alert';
import LockIcon from 'mdi-react/LockIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import Visibility from '@mui/icons-material/Visibility';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
const blockStyle = {
    qrLabel: {
        margin: '15px 0 10px 0',
        textAlign: 'center',
    },
};
const RegisterForm = ({ handleSubmit, errorMessage = '', errorMsg, fields, validation, onFieldChange, disableButton, isValidEmail, qrData, }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(prev => !prev);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword(prev => !prev);
    return (React.createElement(Container, { maxWidth: "xs" },
        React.createElement(Paper, null,
            React.createElement("form", { onSubmit: e => handleSubmit(e) },
                React.createElement(Box, { p: 4 },
                    React.createElement(Typography, { variant: 'h5', align: 'center' }, "Registration"),
                    (!!errorMessage || !!errorMsg) ?
                        React.createElement(Alert, { severity: "warning", variant: "outlined" },
                            React.createElement(React.Fragment, null,
                                errorMessage,
                                errorMsg)) : null,
                    React.createElement("div", null,
                        React.createElement(TextField, { fullWidth: true, id: "email", size: "small", type: "email", name: "email", margin: "normal", label: "User email", variant: "outlined", value: fields.email, onChange: onFieldChange, className: "mui_select_wrap", InputLabelProps: { shrink: true }, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(EmailIcon, null))),
                            } }),
                        validation.includes('email') && (React.createElement("span", null, "Field is required and must be valid email address"))),
                    React.createElement("div", null,
                        React.createElement(TextField, { fullWidth: true, id: "userName", size: "small", name: "userName", margin: "normal", label: "User name", variant: "outlined", value: fields.userName, onChange: onFieldChange, className: "mui_select_wrap", InputLabelProps: { shrink: true }, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(AccountOutlineIcon, null))),
                            } }),
                        validation.includes('userName') && (React.createElement("span", null, "User name is required"))),
                    React.createElement("div", null,
                        React.createElement(TextField, { fullWidth: true, size: "small", id: "password", name: "password", margin: "normal", label: "Password", variant: "outlined", placeholder: "Password", value: fields.password, onChange: onFieldChange, className: "mui_select_wrap", InputLabelProps: { shrink: true }, type: showPassword ? 'text' : 'password', InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(KeyVariantIcon, null))),
                                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { edge: "end", "aria-label": "toggle password visibility", onClick: () => handleClickShowPassword(), size: "large" }, showPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                            } }),
                        validation.includes('password') && (React.createElement("span", null, "Password is required"))),
                    React.createElement("div", null,
                        React.createElement(TextField, { fullWidth: true, size: "small", margin: "normal", variant: "outlined", id: "confirmPassword", name: "confirmPassword", label: "Confirm password", onChange: onFieldChange, className: "mui_select_wrap", value: fields.confirmPassword, InputLabelProps: { shrink: true }, type: showConfirmPassword ? 'text' : 'password', InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(KeyVariantIcon, null))),
                                endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { edge: "end", "aria-label": "toggle password visibility", onClick: () => handleClickShowConfirmPassword(), size: "large" }, showConfirmPassword ? React.createElement(VisibilityOff, null) : React.createElement(Visibility, null)))),
                            } }),
                        validation.includes('confirmPassword') && (React.createElement("span", null, "The value should be equal to Password"))),
                    isValidEmail ? (React.createElement("span", null,
                        React.createElement("div", { style: blockStyle.qrLabel }, "Scan QR-code with Google Authenticator"),
                        React.createElement("div", { style: { width: "100%", textAlign: "center" } },
                            React.createElement(QRCode, { value: qrData })))) : null,
                    React.createElement("div", null,
                        React.createElement(TextField, { fullWidth: true, id: "twoFACode", size: "small", name: "twoFACode", margin: "normal", label: "TOTP code", variant: "outlined", value: fields.twoFACode, onChange: onFieldChange, className: "mui_select_wrap", InputLabelProps: { shrink: true }, inputProps: { maxLength: 6 }, InputProps: {
                                startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(LockIcon, null))),
                            } }),
                        validation.includes('twoFACode') && (React.createElement("span", null, "Incorrect 2FA code"))),
                    React.createElement("div", { className: "form__form-group" },
                        React.createElement(Button, { type: "submit", variant: "contained", disabled: disableButton, style: { width: '100%' } }, "Register"),
                        React.createElement("div", { className: "register_form_contact_us" },
                            React.createElement(Link, { href: "mailto:contact@dlt-finance.com" }, "Not working? Please, contact us!"))))))));
};
export default RegisterForm;
