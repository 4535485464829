import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Decimal from 'decimal.js';
import copy from 'copy-to-clipboard';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { Icon, TableCell } from '@mui/material';
import { WalletCell } from '../../styling/style';
import { getEnvSettings } from '../../../../../config/environmentSettings';
const envSettings = getEnvSettings();
const WalletTableCell = ({ value, columnKey, item, allDeposits, }) => {
    const [fullItem, setFullItem] = useState(null);
    const [icon, setIcon] = useState('file_copy');
    const handleCopy = (id) => {
        setIcon('done');
        setTimeout(() => {
            setIcon('file_copy');
        }, 3000);
        copy(id);
    };
    useEffect(() => {
        const deposit = allDeposits.find(d => d.code === item.code);
        if (deposit) {
            setFullItem(deposit);
        }
    }, [allDeposits, item]);
    if (columnKey === 'amount') {
        return (React.createElement(TableCell, { align: "right" },
            React.createElement(NumericFormat, { displayType: "text", value: new Decimal(value || 0).valueOf(), thousandSeparator: envSettings.thousandDivider })));
    }
    if (columnKey === 'code') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(Link, { style: { color: 'inherit' }, to: { pathname: '/custody', state: { depositItem: fullItem } } },
                React.createElement(WalletCell, null, value)),
            value ? (React.createElement(Icon, { onClick: () => handleCopy(`${value}`), fontSize: "small" }, icon)) : null));
    }
    if (columnKey === 'created_at') {
        return (React.createElement(TableCell, { align: "right" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a'));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default WalletTableCell;
