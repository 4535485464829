export const permissionsCategories = {
    'client_rfq_settings': 'Admin',
    'exchange': 'Admin',
    'invites': 'Admin',
    'customer_invites': 'Admin',
    'client_custody_manual_aml': 'Admin - Custody',
    'client_custody_withdrawal_problem': 'Admin - Custody',
    'custody_access_rules': 'Admin - Custody',
    'custody_currency_configuration': 'Admin - Custody',
    'archive_settlement_info': 'Admin - Ops',
    'roles_permissions': 'Admin - Ops',
    'settlement': 'Admin - Ops',
    'tenants_restrictions': 'Admin - Ops',
    'exchange_withdrawals': 'Admin - Trading',
    'order': 'Admin - Trading',
    'order_cancel_all': 'Admin - Trading',
    'order_cancel_bulk': 'Admin - Trading',
    'order_problem': 'Admin - Trading',
    'order_sign': 'Admin - Trading',
    'reserves': 'Admin - Trading',
    'restrict_order_size': 'Admin - Trading',
    'trades_volume_fee_settings': 'Admin - Trading',
    'internal_transfers': 'Admin - Services',
    'admin_hedge_order': 'Admin - Trading',
    'auto_hedger': 'Admin - Trading',
    'client_custody_address_master_sign': 'Custody',
    'client_custody_balance': 'Custody',
    'client_custody_transaction': 'Custody',
    'client_custody_wallet': 'Custody',
    'client_custody_wallet_customer': 'Custody',
    'client_custody_withdrawal_address': 'Custody',
    'client_custody_withdrawal_master_sign': 'Custody',
    'custody_report': 'Custody',
    'custody_transaction': 'Custody',
    'fee_report': 'Custody',
    'api_tokens': 'Misc',
    'api_totp_check_bypass': 'Misc',
    'audit_event': 'Misc',
    'customer': 'Misc',
    'customer_activity': 'Misc',
    'customer_details': 'Misc',
    'report': 'Misc',
    'report_trading': 'Misc',
    'tenants': 'Misc',
    'users': 'Misc',
    'users_restrictions': 'Misc',
    'trading_transfers': 'Settlement',
    'trading_transfers_sign': 'Settlement',
    'balance': 'Trading',
    'history': 'Trading',
    'history_trading': 'Trading',
    'instruments': 'Trading',
    'trader': 'Trading',
    'trader_activity': 'Trading',
    'trader_details': 'Trading',
};
