import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import themeReducer from '../../redux/reducers/themeReducer';
import ResetTOTP from '../Account/ResetTOTP';
const rootReducer = combineReducers({
    theme: themeReducer,
});
const store = createStore(rootReducer);
const Container = () => (React.createElement(Provider, { store: store },
    React.createElement(BrowserRouter, { basename: '/' },
        React.createElement(ResetTOTP, null))));
export default Container;
