/* eslint-disable camelcase, @typescript-eslint/restrict-template-expressions */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Chip from '@mui/material/Chip';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableContainer from '@mui/material/TableContainer';
import { ContentBox, EntityBadge, FlexieContainer, MaterialTableWrap, } from '../../../../Custody/styling/style';
import showNotification from '../../../../../../shared/helpers/notifications';
import { AmlCheckPasses } from '../../../../../../services/types';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
const ContributionContent = ({ contributions }) => (React.createElement(MaterialTableWrap, null,
    React.createElement(TableContainer, { className: "aml_check_container_contributions" },
        React.createElement(Table, { size: "small", "aria-label": "a dense table" },
            React.createElement(TableHead, null,
                React.createElement(TableRow, null,
                    React.createElement(TableCell, null, "Percentage"),
                    React.createElement(TableCell, { align: "left" }, "Value USD"),
                    React.createElement(TableCell, { width: 120, align: "right" }, "Entities"))),
            React.createElement(TableBody, null, contributions.map((ic, i) => (React.createElement(TableRow, { key: `${i}_contrib` },
                React.createElement(TableCell, { align: "left" }, `${ic.contribution_percentage.toFixed(0)} %`),
                React.createElement(TableCell, { align: "left" },
                    "$ ",
                    ic.contribution_value.usd.toFixed(2)),
                React.createElement(TableCell, { align: "left" }, ic.entities.map((e, index) => (React.createElement(EntityBadge, { key: index },
                    e.category,
                    ": ",
                    e.name))))))))))));
const AmlCheckItem = ({ aml }) => {
    const [testnet, setTestnet] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [itemsSource, setSource] = useState(null);
    const [itemsDestination, setDestination] = useState(null);
    const [itemContributions, setContibutions] = useState(null);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const dateOpions = {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
    };
    const parseAmlResponse = (amlToParse) => {
        let res = null;
        try {
            res = JSON.parse(amlToParse.response);
        }
        catch (e) {
            showNotification({
                message: `Something went wrong, error: ${e}`,
                color: 'error',
                dispatch: errorNotice,
            });
        }
        return res;
    };
    useEffect(() => {
        // amlResponse structure potentially may vary hence the logic bellow
        const amlResponse = parseAmlResponse(aml);
        if (amlResponse && amlResponse.raw_response) {
            if ('testnet' in amlResponse.raw_response) {
                setTestnet(true);
                return;
            }
            const oResponse = Array.isArray(amlResponse.raw_response) && amlResponse.raw_response.length
                ? amlResponse.raw_response[0].original_response : null;
            const contributions = oResponse ? oResponse.contributions : undefined;
            if (contributions && Array.isArray(contributions)) {
                setContibutions(contributions);
            }
            else if (contributions) {
                const { destination, source } = contributions;
                setSource(source);
                setDestination(destination);
            }
        }
        else if (amlResponse && amlResponse.address
            && typeof amlResponse.address !== 'string' && 'raw_response' in amlResponse.address) {
            const raw = amlResponse.address.raw_response;
            const oResponse = raw && Array.isArray(raw) && raw.length ? raw[0].original_response : null;
            const contribs = oResponse ? oResponse.contributions : undefined;
            if (contribs && Array.isArray(contribs)) {
                setContibutions(contribs);
            }
            else if (contribs && 'destination' in contribs && 'source' in contribs) {
                const { destination, source } = contribs;
                setSource(source);
                setDestination(destination);
            }
        }
    }, [aml]);
    return (React.createElement(Paper, { className: "common_paper_variant aml_check_paper_variant" },
        React.createElement(FlexieContainer, null,
            React.createElement("div", { className: "flx-item" },
                React.createElement(Typography, { className: "aml_check_aml_text", variant: "body2" }, "Date created"),
                React.createElement("div", { className: "date-box" }, (new Date(aml.created_at).toLocaleDateString(undefined, dateOpions)))),
            React.createElement(Divider, { orientation: "vertical", className: "divider_vertical_styles", flexItem: true }),
            React.createElement("div", { className: "flx-item" },
                React.createElement(Typography, { className: "aml_check_aml_text", variant: "body2" },
                    "Type ",
                    testnet ? ': Testnet' : ''),
                React.createElement("div", { className: "date-box __upper" }, aml.type)),
            React.createElement(Divider, { orientation: "vertical", className: "divider_vertical_styles", flexItem: true }),
            React.createElement("div", { className: "flx-item" },
                React.createElement(Typography, { className: "aml_check_aml_text", variant: "body2" },
                    "Status ",
                    testnet ? ': Testnet' : ''),
                React.createElement("div", null,
                    React.createElement(Chip, { label: aml.passed, className: `
                aml_check_chip 
                ${aml.passed === AmlCheckPasses.passed ? 'aml_check_chip_passed' : ''}
                ${aml.passed === AmlCheckPasses.not_passed ? 'aml_check_chip_not_passed' : ''}
              ` }))),
            React.createElement("div", { className: "flx-item flx__btn" },
                React.createElement(IconButton, { onClick: handleExpandClick, "aria-expanded": expanded, "aria-label": "show more", disabled: !itemsDestination && !itemsSource && !itemContributions, className: `holdings_button_expand ${expanded ? '' : 'holdings_button_expand_open'}`, size: "large" },
                    React.createElement(ExpandMoreIcon, null)))),
        React.createElement(Collapse, { in: expanded, timeout: "auto", unmountOnExit: true },
            React.createElement(ContentBox, null,
                itemContributions && itemContributions.length
                    && (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "subtitle1" }, "Contributions"),
                        React.createElement(ContributionContent, { contributions: itemContributions }))),
                itemsSource && itemsSource.length
                    && (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "subtitle1" }, "Source"),
                        React.createElement(ContributionContent, { contributions: itemsSource }))),
                itemsDestination && itemsDestination.length
                    && (React.createElement(React.Fragment, null,
                        React.createElement(Typography, { variant: "subtitle1" }, "Destination"),
                        React.createElement(ContributionContent, { contributions: itemsDestination })))))));
};
const AmlCheck = ({ item }) => {
    if (!item.aml_checks) {
        return (React.createElement(Paper, { className: "common_paper_variant aml_check_paper_variant" },
            React.createElement(FlexieContainer, null,
                React.createElement("div", { className: "flx-item" },
                    React.createElement(Typography, { className: "aml_check_aml_text", variant: "body2" }, "Data not available")))));
    }
    return (React.createElement(React.Fragment, null, item.aml_checks.map((aml, i) => (React.createElement(AmlCheckItem, { key: i, aml: aml })))));
};
export default AmlCheck;
