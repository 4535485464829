import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { PanelContainer, MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../Custody/styling/style';
import WithdrawalsCellActions from './WithdrawalsCellActions';
import GreenSwitch from '../../../../../shared/helpers/greenSwitch';
import WithdrawalTableCell from '../../../Custody/components/Wallets/WithdrawalTableCell';
import { Box } from '@mui/material';
/* eslint-disable react/jsx-indent */
const Withdrawals = ({ clientCode, totalWithdrawals, loading, admin, pageWithdrawals, onChangePageW, sortBy, columns, features, currencies, handleColumnsClick, columnsOpen, anchorColumns, handleColumnsClose, columnsToRender, hideShowColumn, withdrawals, clientInfo, maxHeight, withdrawalsToRender, rowsPerPage, sortDirection, onSortHandle, fetchAllWithDrawals, walletsLoading, }) => {
    const colsW = columns.filter(c => c.id.includes('withdrawalsApproveColumns'));
    const [regularClient, setRegularClient] = useState(null);
    useEffect(() => {
        if (!admin && clientInfo) {
            setRegularClient({ label: clientInfo.company_name, value: clientInfo.code });
        }
    }, [clientInfo]);
    return (React.createElement(PanelContainer, { className: "blocks_wrapp_body_style" }, loading ? (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
        React.createElement(CircularProgress, { size: "33px" }))) : (React.createElement(React.Fragment, null,
        React.createElement(WalletsClientContainer, null,
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    }, transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, colsW.map((col, i) => (React.createElement(ListItem, { key: `withdrawals_cols_Admin_switches_${i}` },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.id), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        React.createElement(MaterialTableWrap, null,
            React.createElement(Paper, { className: "mui_table_root" },
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, style: { minWidth: column.minWidth }, align: column.key === 'address' ? 'left' : 'right' },
                                    React.createElement(TableSortLabel, { active: column.key === sortBy, direction: column.key === sortBy ? sortDirection : 'asc', onClick: () => onSortHandle(column.key) }, column.title)))),
                                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width" }, "Actions"))),
                        React.createElement(TableBody, null, withdrawalsToRender.map(w => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: w.code },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? w[key] : undefined;
                                return (React.createElement(WithdrawalTableCell, { key: i, item: w, value: value, columnKey: key, currencies: currencies, withdrawals: withdrawals, currency: w.currency_code, walletsLoading: walletsLoading, currencyFee: w.fee_currency_code }));
                            }),
                            React.createElement(WithdrawalsCellActions, { item: w, admin: admin, features: features, clientCode: clientCode, fetchAllWithDrawals: fetchAllWithDrawals }))))))),
                React.createElement(TablePagination, { component: "div", page: pageWithdrawals, count: totalWithdrawals, rowsPerPage: rowsPerPage, onPageChange: onChangePageW, rowsPerPageOptions: [maxHeight] })))))));
};
export default Withdrawals;
