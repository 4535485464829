import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FETCH_EXCHANGES_REQUEST, NEW_ORDER_EXCHANGE_SET_UPDATE, } from '../../../../../../redux/actionTypes/placeOrderActionTypes';
import { getAvailableExchanges } from '../../../../../../redux/selectors/selectors';
import { CircularProgress, MenuItem, TextField } from '@mui/material';
const SelectExchange = ({ loadingMarkets, exchanges, selectedExchange, }) => {
    const dispatch = useDispatch();
    const handleSelectChange = (event) => {
        if (event.target.value) {
            const exchange = { value: event.target.value, label: exchanges.find(e => e.value === event.target.value) };
            dispatch({ type: NEW_ORDER_EXCHANGE_SET_UPDATE, exchange });
        }
    };
    useEffect(() => {
        dispatch({ type: FETCH_EXCHANGES_REQUEST });
    }, []);
    const isSearchable = false;
    return (React.createElement("div", null, loadingMarkets ? React.createElement(CircularProgress, null) :
        React.createElement(TextField, { select: true, fullWidth: true, label: "Exchange", variant: "outlined", size: "small", value: selectedExchange === null || selectedExchange === void 0 ? void 0 : selectedExchange.value, onChange: handleSelectChange }, exchanges.map((option, i) => (React.createElement(MenuItem, { key: i, value: option.value }, option.label))))));
};
const stateToProps = (state) => {
    const { selectedExchange, loadingMarkets } = state.placeOrder;
    let exchanges = [];
    if (!loadingMarkets) {
        exchanges = getAvailableExchanges(state);
    }
    return {
        exchanges,
        loadingMarkets,
        selectedExchange,
    };
};
export default connect(stateToProps)(SelectExchange);
