import Decimal from 'decimal.js';
/* eslint-disable no-plusplus */
// TODO: move to separate class and add unit test
const chartLineData = (orders, service) => {
    if (orders.length === 0)
        return undefined;
    orders.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime());
    const result = {};
    let sum = new Decimal(0);
    for (let i = 0; i < orders.length; i++) {
        const timestamp = new Date(orders[i].created).setHours(0, 0, 0, 0);
        const next = orders[i + 1] ? new Date(orders[i + 1].created).setHours(0, 0, 0, 0) : null;
        const key = new Date(timestamp).toLocaleDateString();
        // work out last item
        if (!next && i === orders.length - 1) {
            const last = Object.keys(result).pop();
            if (last && last === key) {
                let amtUsd = service.convertInBase(orders[i].market_item.base, orders[i].amount);
                if (amtUsd === 'Not convertible') {
                    // todo: question how to treat currency with no rate
                    amtUsd = '0';
                }
                sum = new Decimal(amtUsd).add(sum);
                result[key] = sum.valueOf();
            }
            else {
                const amtUsd = service.convertInBase(orders[i].market_item.base, orders[i].amount);
                result[key] = amtUsd === 'Not convertible' ? '0' : amtUsd;
            }
            // eslint-disable-next-line no-continue
            continue;
        }
        if (next && next === timestamp) {
            let amtUsd = service.convertInBase(orders[i].market_item.base, orders[i].amount);
            if (amtUsd === 'Not convertible') {
                // todo: question how to treat currency with no rate
                amtUsd = '0';
            }
            sum = new Decimal(amtUsd).add(sum);
            result[key] = sum.toFixed();
        }
        else {
            let amtUsd = service.convertInBase(orders[i].market_item.base, orders[i].amount);
            if (amtUsd === 'Not convertible') {
                // todo: question how to treat currency with no rate
                amtUsd = '0';
            }
            result[key] = new Decimal(amtUsd).add(sum).toFixed();
            // reset sum
            sum = new Decimal(0);
        }
    }
    const labels = Object.keys(result);
    return {
        labels,
        data: labels.map(l => result[l]),
    };
};
export default chartLineData;
