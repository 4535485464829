import React from 'react';
import Decimal from 'decimal.js';
const TradePrice = ({ price, tradeAction }) => {
    let classTrade = '';
    if (tradeAction !== '' && tradeAction === 'Sell') {
        classTrade = 'container_trade_color_red';
    }
    else if (tradeAction !== '' && tradeAction === 'Buy') {
        classTrade = 'container_trade_color_green';
    }
    else {
        classTrade = 'container_trade_color_white';
    }
    return (React.createElement("div", { className: classTrade }, price === ''
        ? React.createElement("span", null, "--")
        : React.createElement("span", null, new Decimal(price).toFixed(2).replace(/\.?0+$/, ''))));
};
const LastTrade = ({ lastTrade }) => {
    if (lastTrade && Object.keys(lastTrade).length) {
        return React.createElement(TradePrice, { price: lastTrade.price, tradeAction: lastTrade.action });
    }
    return (React.createElement("span", null, "--"));
};
export default React.memo(LastTrade);
