var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Chip, Button, TextField, CircularProgress, } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { connect, useDispatch } from 'react-redux';
import Messages from '../../../../../../shared/helpers/errorMessages';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import showNotification from '../../../../../../shared/helpers/notifications';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import CustodyWalletService from '../../../../../../services/custodyWalletService';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getCustomersSelectedInputInterface } from '../../../../../../redux/selectors/customerSearchSelectors';
export const AddNote = ({ item, toggle, cellAction }) => {
    const [cNote, setNote] = useState('');
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleNoteChange = (evt) => {
        const { value } = evt.target;
        setNote(value);
        cellAction(Object.assign({}, item, { note: value }));
    };
    const onNoteSubmit = () => {
        // Use this to update Note
        // same service as for editing label
        showNotification({
            color: 'success',
            dispatch: errorNotice,
            message: 'Note successfully changed',
        });
        toggle();
    };
    useEffect(() => {
        if (item) {
            setNote(item.label);
        }
    }, []);
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "Are you sure you want to cancel Order: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(TextField, { fullWidth: true, size: "small", value: cNote, margin: "normal", variant: "outlined", onChange: handleNoteChange, helperText: "Add Note to wallet", InputLabelProps: { shrink: true } }),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { size: "small", color: "success", disabled: !cNote, variant: "outlined", onClick: onNoteSubmit }, "Update"),
            React.createElement(Button, { size: "small", color: "inherit", variant: "outlined", onClick: () => toggle() }, "Close"))));
};
export const WalletLabel = ({ item, toggle, cellAction }) => {
    const [cLabel, setLabel] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleLabelChange = (evt) => {
        const { value } = evt.target;
        setLabel(value);
    };
    const onLabelChangeDone = () => __awaiter(void 0, void 0, void 0, function* () {
        const walletService = new CustodyWalletService({
            url: `/custody/${item.client_code ? item.client_code : ''}/wallets/${item.code}`,
            method: 'POST',
            data: {
                label: cLabel,
            },
        });
        setLoading(true);
        yield walletService.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction(Object.assign({}, item, { label: cLabel }));
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: 'Label successfully changed',
            });
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_UPDATE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
            setLabel('');
        });
        toggle();
    });
    useEffect(() => {
        if (item) {
            setLabel(item.label);
        }
    }, []);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(TextField, { fullWidth: true, size: "small", value: cLabel, margin: "normal", variant: "outlined", onChange: handleLabelChange, helperText: "Add wallet label", InputLabelProps: { shrink: true } }),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", variant: "outlined", disabled: !cLabel, onClick: onLabelChangeDone }, "Update"),
            React.createElement(Button, { color: "inherit", variant: "outlined", onClick: () => toggle() }, "Close"))));
};
const SelectCustomers = ({ onSelectChange, customers }) => {
    const [custodyCustomer, setCustomer] = useState('');
    const [options, setOptions] = useState([]);
    const handleChange = (event) => {
        const code = event.target.value;
        setCustomer(code);
    };
    const onConfirm = () => {
        const customer = customers.find(c => c.value === custodyCustomer);
        onSelectChange(customer);
    };
    useEffect(() => {
        if (customers.length) {
            setOptions(customers);
        }
        else {
            setOptions([]);
        }
    }, []);
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(FormControl, { variant: "outlined" },
            React.createElement(TextField, { fullWidth: true, select: true, value: custodyCustomer, size: "small", variant: "outlined", label: 'Select customer', onChange: handleChange }, options.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label))))),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", size: "small", variant: "contained", disabled: !custodyCustomer, onClick: onConfirm }, "Confirm"),
            React.createElement(Button, { size: "small", color: "inherit", variant: "outlined", onClick: () => onSelectChange() }, "Cancel"))));
};
const WalletCustomer = ({ item, customers, admin = false, features, toggle, cellAction, }) => {
    const [cCustomer, setCustomer] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const notice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handlesSelectCustomer = (customer) => __awaiter(void 0, void 0, void 0, function* () {
        if (!customer) {
            toggle();
            return;
        }
        const walletService = new CustodyWalletService({
            url: `/custody/${item.client_code ? item.client_code : ''}/wallets/${item.code}/customer_assign`,
            method: 'POST',
            data: { customer_code: customer.value },
        });
        yield walletService.makeRequest()
            .then(() => {
            showNotification({
                color: 'success',
                dispatch: notice,
                message: 'Customer successfully assigned',
            });
            setCustomer(customer.label);
            const customerLabel = admin ? customer.value : customer.label;
            const updatedItem = Object.assign({}, item, { customer_code: customerLabel });
            cellAction(updatedItem);
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_UPDATE);
            showNotification({
                message,
                color: 'error',
                dispatch: notice,
            });
        });
        toggle();
    });
    const handleUnassignCustomer = () => __awaiter(void 0, void 0, void 0, function* () {
        const walletService = new CustodyWalletService({
            url: `/custody/${item.client_code}/wallets/${item.code}/customer_unassign`,
            method: 'POST',
        });
        setCustomer('');
        setLoading(true);
        yield walletService.makeRequest()
            .then(() => {
            setLoading(false);
            showNotification({
                color: 'success',
                dispatch: notice,
                message: 'Customer has been unassigned',
            });
            const updatedItem = Object.assign({}, item, { customer_code: 'n/a' });
            cellAction(updatedItem);
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_UPDATE);
            showNotification({
                message,
                color: 'error',
                dispatch: notice,
            });
        });
        toggle();
    });
    useEffect(() => {
        if (admin && item.customer_code) {
            setCustomer(item.customer_code);
            return;
        }
        if (item.customer_code) {
            const customer = customers.find(c => c.value === item.customer_code);
            setCustomer(customer ? customer.label : '');
        }
    }, []);
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    if (admin) {
        if (cCustomer) {
            return (React.createElement("div", null,
                React.createElement("h5", { style: { width: '100%' } }, item.customer_code),
                React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet_customer.delete'] },
                    React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
                        React.createElement(Button, { color: "error", variant: "outlined", size: "small", onClick: handleUnassignCustomer }, "UNASSIGN CUSTOMER"),
                        React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: () => toggle() }, "Close")))));
        }
        return null;
    }
    if (!cCustomer) {
        return React.createElement(SelectCustomers, { customers: customers, onSelectChange: handlesSelectCustomer });
    }
    return (React.createElement("div", null,
        React.createElement("h4", null,
            "Assigned customer:",
            React.createElement(Chip, { size: "small", color: "default", label: cCustomer, className: "wallets_assign_customer_ml" }))));
};
const customersSelector = getCustomersSelectedInputInterface;
const mapStateToPropsWalletCustomer = (state) => ({
    customers: customersSelector(state),
    features: state.client.features,
});
export const ConnectedWalletCustomer = connect(mapStateToPropsWalletCustomer)(WalletCustomer);
export const WalletMigrate = ({ item, toggle, cellAction }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const walletService = new CustodyWalletService({
            url: `/custody/${item.client_code ? item.client_code : ''}/wallets/${item.code}/mark_as_migrated`,
            method: 'POST',
            data: {
                client_code: item.client_code,
                wallet_code: item.code,
            },
        });
        setLoading(true);
        yield walletService.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction(Object.assign({}, item, { is_migrated: true }));
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: 'Wallet successfully migrated',
            });
        })
            .catch((e) => {
            setLoading(false);
            const message = composeErrorMessage(e, Messages.CUSTODY_WALLETS_UPDATE);
            showNotification({
                message,
                color: 'error',
                dispatch: errorNotice,
            });
        });
        toggle();
    });
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "Are you sure you want to migrate Wallet\u00A0",
            React.createElement("span", { className: "forms_uuid_container" }, item.code),
            " to Fireblocks?"),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", variant: "outlined", size: "small", onClick: () => handleClick() }, "Yes"),
            React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: () => toggle() }, "Close"))));
};
export const UpdateBalance = ({ item, toggle, cellAction }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleRevertDeposit = (code) => {
        const service = new CustodyWalletService({
            url: `/custody/wallets/${code}/balance`,
            method: 'post',
            data: {},
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        service.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction(item);
            showNotification({
                message: 'Wallet balance will soon updated',
                color: 'success',
                dispatch: errorNotice,
            });
            toggle();
        })
            .catch((e) => {
            setLoading(false);
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.WALLET_BALANCE_UPDATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            toggle();
        });
    };
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "Updating wallet balance: ",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", size: "small", variant: "outlined", onClick: () => handleRevertDeposit(item.code) }, "Update"),
            React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: () => toggle() }, "Close"))));
};
export const WalletEnableDisable = ({ item, toggle, cellAction }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const walletService = new CustodyWalletService({
            url: `report/custody/wallets/${item.code}/restrictions`,
            method: 'PATCH',
            data: { is_enabled: item.state === 'inactive' },
            baseURL: getEnvSettings().adminApiUrl,
        });
        setLoading(true);
        yield walletService.makeRequest()
            .then(() => {
            setLoading(false);
            cellAction(Object.assign({}, item, { state: item.state === 'inactive' ? 'active' : 'inactive' }));
            showNotification({
                color: 'success',
                dispatch: errorNotice,
                message: `Wallet successfully ${item.state === 'inactive' ? 'activated' : 'deactivated'}`,
            });
        })
            .catch(() => {
            setLoading(false);
            showNotification({
                color: 'error',
                dispatch: errorNotice,
                message: `Error: Failed to ${item.state === 'inactive' ? 'deactivate' : 'activate'} Wallet`,
            });
        });
        toggle();
    });
    if (loading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    return (React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 1 },
        React.createElement(Box, { p: 2 },
            "Are you sure you want to ",
            item.state === 'inactive' ? 'activate' : 'deactivate',
            " Wallet\u00A0",
            React.createElement("span", { className: "forms_uuid_container" }, item.code)),
        React.createElement(Box, { display: 'flex', gap: 1, justifyContent: 'flex-end' },
            React.createElement(Button, { color: "success", variant: "outlined", size: "small", onClick: () => handleClick() }, "Yes"),
            React.createElement(Button, { color: "inherit", size: "small", variant: "outlined", onClick: () => toggle() }, "Close"))));
};
