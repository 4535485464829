import React, { useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { Box, Button, Chip, Drawer, IconButton, List, ListItem } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import TopbarProfile from './TopbarProfile';
import TopBarConnection from './TopBarConnection';
import Permissions from '../AdminSidebar/Permissions';
import { getEnvSettings } from '../../../config/environmentSettings';
import { TopbarLinkContainer } from '../styling/styledComponents';
import { BALANCES_STATE_UPDATE, WORKSPACE_FETCH_REQUEST } from '../../../redux/actionTypes/workspaceActions';
import { createStyles, makeStyles } from '@mui/styles';
import ClientSearchSelect from '../AdminSidebar/components/ClientSearch';
import { SET_SELECTED_CLIENT } from '../../../redux/actionTypes/clientSearchActionTypes';
import { ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE } from '../../../redux/actionTypes/adminActionTypes';
import { getSelectedClient } from '../../../redux/selectors/clientSearchSelectors';
import { selectCustomer } from '../../../redux/actions/customersSearchActions';
import BalancesWidget from '../../Balances/BalancesWidget';
import { writeToLocalStorage } from '../../../shared/helpers/localStorageInterface';
const okcoinUsers = [
    'tobias.rethmeyer@steubing.com',
    'adrian.hurler@steubing.com',
    'paul.huelsmann@finexity.com',
    'patrick.grewe@plutos.de',
    'andreas.othmer@plutos.de',
];
const adminClientUpdate = (selectedClient) => ({
    type: SET_SELECTED_CLIENT,
    selectedClient,
});
const pageOfItemsUpdate = (pageOfItemsAdminOrders) => ({
    type: ADMIN_ORDERS_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsAdminOrders,
});
const finoaEnv = getEnvSettings().env === 'finoa';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        background: theme.palette.background.paper,
        borderBottom: `1px solid #464646`,
        position: 'fixed',
        height: '60px',
        width: '100%',
        top: 0,
        zIndex: 10,
        fontSize: '0.875rem',
        '& .logo': {
            maxWidth: '270px',
            minWidth: '125px',
            width: '100%',
            height: '24px',
            display: 'inline-block',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            '&.dlt': {
                backgroundImage: theme.palette.mode === 'dark' ? `url(/img/logo/dlt_logo_light.svg)` : 'url(/img/logo/dlt_logo_dark.svg)',
            },
            '&.okcoin': {
                backgroundImage: `url(/img/logo/steubing_logo.png)`,
            },
            '&.finoa': {
                backgroundImage: theme.palette.mode === 'dark' ? `url(/img/logo/logo_finoa.svg)` : 'url(/img/logo/logo_finoa_l.svg)',
            },
        },
        '& .menu-item': {
            height: '60px',
            transition: 'color 0.2s',
            color: theme.palette.getContrastText(theme.palette.background.paper),
            '&-active': {
                color: theme.palette.primary.main,
                paddingTop: '3px',
                borderBottom: `3px solid ${theme.palette.primary.main}`,
            },
            '&:hover': {
                color: theme.palette.primary.dark,
            },
        },
    },
    drawer: {
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        '& a': {
            color: theme.palette.text.primary,
        },
        '& a.drawer__link-active': {
            color: theme.palette.primary.main,
        },
    },
}));
/* eslint-disable camelcase */
const TopbarWithNavigation = ({ features, masterTenant, clientInfo, selectedClient, selectedCustomer, clients, customers, isCustomer, balancesWidget, dispatchClientSelect, dispatchCustomerSelect, }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isCustomerSelectable, setIsCustomerSelectable] = useState(false);
    const [logoLink, setLogoLink] = useState('trading');
    const [logoClass, setLogoClass] = useState('dlt');
    const [drawerOpened, setDrawerOpened] = useState(false);
    const [clientSelectValue, setClientSelectValue] = useState(null);
    const [customerSelectValue, setCustomerSelectValue] = useState(null);
    const [selectClient, setSelectClient] = useState(false);
    const [selectCustomer, setSelectCustomer] = useState(false);
    const [balances, setBalancesOpended] = useState(balancesWidget === null || balancesWidget === void 0 ? void 0 : balancesWidget.pinned);
    const handleLinkClick = (dashboard) => {
        dispatch({ type: WORKSPACE_FETCH_REQUEST, dashboard });
        setDrawerOpened(false);
    };
    const handleNavigateToClient = () => {
        if (clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.value) {
            history.push(`/manage/clients/${clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.value}`);
        }
    };
    const handleNavigateToCustomer = () => {
        if (customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.value) {
            history.push(`/manage/customers/${customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.value}`);
        }
    };
    useEffect(() => {
        if (!isCustomer) {
            const regex = /dma|custody|rfq|balances/g;
            setIsCustomerSelectable(!!history.location.pathname.match(regex));
        }
    }, []);
    useEffect(() => {
        if (features && (features === null || features === void 0 ? void 0 : features.length) > 0 && !features.includes('order.read')) {
            setLogoLink('custody');
        }
    }, [features]);
    useEffect(() => {
        if (clientInfo && okcoinUsers.includes(clientInfo.email)) {
            setLogoClass('okcoin');
        }
        else if (clientInfo && finoaEnv) {
            setLogoClass('finoa');
        }
    }, [clientInfo]);
    useEffect(() => {
        const value = selectedClient
            ? {
                value: selectedClient.code,
                label: selectedClient.company_name || selectedClient.code,
                enabled: selectedClient.is_enabled,
            }
            : null;
        setClientSelectValue(value);
    }, [selectedClient]);
    useEffect(() => {
        const value = selectedCustomer
            ? {
                value: selectedCustomer.code,
                label: selectedCustomer.company_name || selectedCustomer.code,
                enabled: selectedCustomer.is_enabled,
            }
            : null;
        setCustomerSelectValue(value);
    }, [selectedCustomer]);
    const mapClients = (clients) => {
        return clients === null || clients === void 0 ? void 0 : clients.map((c) => ({
            value: c.code,
            label: c.company_name || c.code,
            enabled: c.is_enabled,
        }));
    };
    const handleClientSelect = (selectedClient) => {
        dispatchClientSelect((selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.value) || null);
    };
    const handleCustomerSelect = (selectedCustomer) => {
        const customer = customers.find((c) => c.code === (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.value)) || null;
        dispatchCustomerSelect(customer);
    };
    const handleOpenBalances = () => {
        setBalancesOpended(!balances);
        if (balances) {
            dispatch({ type: BALANCES_STATE_UPDATE, pinned: false });
            writeToLocalStorage('balancesWidget', { pinned: false });
        }
    };
    return (React.createElement("div", { className: classes.root },
        React.createElement(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: 2 },
            React.createElement(Box, { flex: 'auto' },
                React.createElement(Box, { sx: { display: { xs: 'none', md: 'flex' } } },
                    React.createElement(Link, { className: `logo ${logoClass}`, to: '/', onClick: () => handleLinkClick(logoLink) })),
                React.createElement(Box, { sx: { display: { xs: 'flex', md: 'none' } } },
                    React.createElement(IconButton, { edge: 'start', color: 'inherit', "aria-label": 'menu', onClick: () => setDrawerOpened(!drawerOpened), size: 'large' },
                        React.createElement(MenuIcon, null)))),
            React.createElement(Drawer, { anchor: 'left', open: drawerOpened, onClose: () => setDrawerOpened(false), classes: { paper: classes.drawer } },
                React.createElement("div", null,
                    React.createElement(List, { component: 'nav' },
                        React.createElement(Permissions, { features: features, permissions: ['order.read', 'client_rfq_settings.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('tradingRfq') },
                                React.createElement(NavLink, { className: 'drawer__link', activeClassName: 'drawer__link-active', exact: true, to: '/rfq' }, "RFQ Trading"))),
                        masterTenant && !finoaEnv ? (React.createElement(Permissions, { features: features, permissions: ['order.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('trading') },
                                React.createElement(NavLink, { exact: true, to: '/dma', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('trading') }, "DMA Trading")))) : null,
                        React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet.read'] },
                            React.createElement(ListItem, { button: true, onClick: () => handleLinkClick('custody') },
                                React.createElement(NavLink, { to: '/custody', className: 'drawer__link', activeClassName: 'drawer__link-active', onClick: () => handleLinkClick('custody') }, "Custody")))))),
            React.createElement(Box, { sx: { display: { xs: 'none', md: 'flex' }, height: '100%' } },
                React.createElement(Permissions, { features: features, permissions: ['order.read', 'client_rfq_settings.read'] },
                    React.createElement(TopbarLinkContainer, null,
                        React.createElement(NavLink, { exact: true, to: '/rfq', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('tradingRfq') }, "RFQ Trading"))),
                masterTenant && !finoaEnv ? (React.createElement(Permissions, { features: features, permissions: ['order.read'] },
                    React.createElement(TopbarLinkContainer, null,
                        React.createElement(NavLink, { exact: true, to: '/dma', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('trading') }, "DMA Trading")))) : null,
                React.createElement(Permissions, { features: features, permissions: ['client_custody_wallet.read'] },
                    React.createElement(TopbarLinkContainer, null,
                        React.createElement(NavLink, { to: '/custody', className: 'menu-item', activeClassName: 'menu-item-active', onClick: () => handleLinkClick('custody') },
                            React.createElement("span", { className: 'topbar_link_title' }, "Custody")))),
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, mx: 2 },
                    React.createElement(Button, { variant: 'contained', color: 'secondary', onClick: handleOpenBalances, sx: { textTransform: 'none' } }, "Balances"))),
            React.createElement(BalancesWidget, { open: !!balances, onClose: () => handleOpenBalances() }),
            React.createElement("div", { className: 'topbar__profile_wrap' },
                React.createElement(TopBarConnection, null),
                React.createElement(Box, { sx: { display: 'flex', alignItems: 'center' }, gap: 2 },
                    masterTenant ? (React.createElement(Box, { flex: 'auto', sx: { minWidth: '180px' }, display: 'flex', justifyContent: 'flex-end' }, !selectedClient || selectClient ? (React.createElement(ClientSearchSelect, { label: 'Client', placeholder: 'Select client', selectedValue: clientSelectValue, clients: mapClients(clients), onSelect: (client) => {
                            handleClientSelect(client);
                            setSelectClient(false);
                        } })) : (React.createElement(Chip, { sx: {
                            textOverflow: 'ellipsis',
                            maxWidth: '200px',
                        }, color: 'primary', label: clientSelectValue === null || clientSelectValue === void 0 ? void 0 : clientSelectValue.label, onClick: () => handleNavigateToClient(), onDelete: () => setSelectClient(true), deleteIcon: React.createElement(EditIcon, null) })))) : null,
                    isCustomerSelectable ? (React.createElement(Box, { flex: 'auto', sx: { minWidth: '180px' }, display: 'flex', justifyContent: 'flex-start' }, !selectedCustomer || selectCustomer ? (React.createElement(ClientSearchSelect, { label: 'Customer', placeholder: 'Select customer', selectedValue: customerSelectValue, clients: mapClients(customers), onSelect: (customer) => {
                            handleCustomerSelect(customer);
                            setSelectCustomer(false);
                        } })) : (React.createElement(Chip, { sx: {
                            textOverflow: 'ellipsis',
                            maxWidth: '200px',
                        }, color: 'primary', label: customerSelectValue === null || customerSelectValue === void 0 ? void 0 : customerSelectValue.label, onClick: () => handleNavigateToCustomer(), onDelete: () => setSelectCustomer(true), deleteIcon: React.createElement(EditIcon, null) })))) : null),
                React.createElement("div", { className: 'topbar__profile_divider' }),
                React.createElement(TopbarProfile, null)))));
};
const stateToProps = (state) => ({
    features: state.client.features,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    clients: state.clientSearch.allClients,
    customers: state.customersSearch.customers,
    selectedClient: getSelectedClient(state),
    selectedCustomer: state.customersSearch.selectedCustomer,
    isCustomer: !!state.client.customer_code,
    balancesWidget: state.workspace.balances,
});
const mapDispatchToProps = (dispatch) => ({
    dispatchpageOfItemsAdminOrders: (page) => dispatch(pageOfItemsUpdate(page)),
    dispatchClientSelect: (client) => dispatch(adminClientUpdate(client)),
    dispatchCustomerSelect: (customer) => dispatch(selectCustomer(customer)),
});
export default connect(stateToProps, mapDispatchToProps)(TopbarWithNavigation);
