import { useMemo } from 'react';
const checkAllPermissionsFor = (userFeatures, permissionsRequired) => {
    for (const permission of permissionsRequired) {
        if (!userFeatures.includes(permission)) {
            return false;
        }
    }
    return true;
};
const checkSomePermissions = (userFeatures, permissionsRequired) => {
    if (!permissionsRequired.some(p => userFeatures.includes(p))) {
        return false;
    }
    return true;
};
const Permissions = ({ features, permissions, children, oneOf, showIfNoPermission, }) => {
    if (!features) {
        return null;
    }
    const permission = useMemo(() => oneOf ? checkSomePermissions(features, permissions) : checkAllPermissionsFor(features, permissions), [features]);
    if (permission && !showIfNoPermission) {
        return (children);
    }
    if (!permission && showIfNoPermission) {
        return (children);
    }
    return null;
};
Permissions.defaultProps = {
    oneOf: false,
    showIfNoPermission: false,
};
export default Permissions;
