/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export class ApiPollService {
    constructor(delay) {
        this.intervals = [];
        this.callBacks = [];
        this.addCallback = (callback) => {
            const id = setInterval(() => callback.action(), this.delay);
            this.intervals.push(id);
            const c = Object.assign({}, callback, { intervalId: id });
            this.callBacks.push(c);
        };
        this.removeCallBack = (url) => {
            const index = this.callBacks.findIndex(c => c.url === url);
            if (this.callBacks[index]) {
                const { intervalId } = this.callBacks[index];
                this.callBacks.splice(index, 1);
                clearInterval(intervalId);
            }
        };
        this.clearAll = () => {
            this.callBacks = [];
            this.intervals.forEach((i) => {
                clearInterval(i);
            });
        };
        this.delay = delay;
    }
}
