import React, { useEffect, useState } from 'react';
import shortid from 'shortid';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import { Box, CircularProgress } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { connect, useDispatch } from 'react-redux';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../../Custody/styling/style';
import OrdersTableCell from './OrdersTableCell';
import OrdersCellActions from './OrdersCellActions';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import { CancelOrder, SettlementInfo } from './components/OrdersActions';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { getOrdersToRender } from '../../../../../../redux/selectors/adminSelectors';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
const ClientsOrdersList = ({ admin, total, theme, orders, columns, features, isLoading, rowsPerPage, ordersToRender, setRowsPerPage, ordersWidgetHeight, dispatchPageOfItems, pageOfItemsAdminOrders, }) => {
    const [maxHeight, setMaxHeight] = useState(480);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const dispatch = useDispatch();
    const columnsOpen = Boolean(anchorColumns);
    const onChangePage = (event, pageNumber) => {
        dispatchPageOfItems(pageNumber);
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageOrders'));
    };
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        resizeWidgetHeight(ordersWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [ordersWidgetHeight]);
    const cellActions = {
        actions: {
            cancelItem: () => undefined,
            settlementInfo: () => undefined,
        },
        actionsComponents: {
            cancelItem: CancelOrder,
            settlementInfo: SettlementInfo,
        },
        actionsList: [
            { name: 'Cancel', action: 'cancelItem' },
            { name: 'Settlement info', action: 'settlementInfo' },
        ],
    };
    if (isLoading) {
        return (React.createElement(Box, { display: 'flex', justifyContent: 'center', p: 4 },
            React.createElement(CircularProgress, { size: "33px" })));
    }
    if (orders.length === 0) {
        return (React.createElement(Alert, { severity: "info", variant: "outlined" }, "No data found for selected Client"));
    }
    return React.createElement(React.Fragment, null,
        React.createElement(WalletsClientContainer, null,
            React.createElement("div", null,
                React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                    React.createElement(ViewColumnIcon, null)),
                React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                    React.createElement(MaterialPopoverWrap, null,
                        React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                            React.createElement(ListItemText, { primary: col.title }),
                            React.createElement(ListItemSecondaryAction, null,
                                React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
        React.createElement(MaterialTableWrap, null,
            React.createElement(Paper, { className: "mui_table_root" },
                React.createElement(TableContainer, { style: { maxHeight } },
                    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                        React.createElement(TableHead, null,
                            React.createElement(TableRow, null,
                                columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, className: "mui_table_head_bg", style: { minWidth: column.minWidth }, align: column.key === 'action_type' ? 'left' : 'right' }, column.title))),
                                React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width mui_table_head_bg" }, "Actions"))),
                        React.createElement(TableBody, null, ordersToRender.map(order => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
                            columnsToRender.map((column, i) => {
                                const key = column.key;
                                const value = key ? order[key] : undefined;
                                return (React.createElement(OrdersTableCell, { key: i, value: value, columnKey: key, base: order.base, quote: order.quote }));
                            }),
                            React.createElement(OrdersCellActions, { item: order, theme: theme, admin: admin, features: features, actions: cellActions }))))))),
                React.createElement(TablePagination, { component: "div", count: total, rowsPerPage: rowsPerPage, onPageChange: onChangePage, page: pageOfItemsAdminOrders, rowsPerPageOptions: [maxHeight] }))));
};
const mapStateToProps = (state, ownProps) => ({
    theme: state.theme.className,
    total: state.admin.total,
    orders: state.admin.orders,
    features: state.client.features,
    isLoading: state.admin.isLoading,
    ordersToRender: getOrdersToRender(state),
    ordersWidgetHeight: state.widgets[ownProps.widgetKey],
    pageOfItemsAdminOrders: state.admin.pageOfItemsAdminOrders,
});
export default connect(mapStateToProps)(ClientsOrdersList);
