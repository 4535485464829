import { call, put, takeLatest, select, delay, take, race, } from 'redux-saga/effects';
import Messages from '../shared/helpers/errorMessages';
import * as adminActions from '../redux/actions/adminActions';
import * as types from '../redux/actionTypes/adminActionTypes';
import * as orderActions from '../redux/actions/orderBookActions';
import { composeAnyErrorMessage } from '../shared/helpers/interceptors';
import { OPEN_ERROR_NOTICE } from '../redux/actionTypes/apiErrorsActionTypes';
function* fetchAdminOrders(action) {
    try {
        if (action.type === types.ADMIN_ORDERS_FETCH_REQUEST) {
            const response = yield call(orderActions.fetchRegularOrders, action.urlOptionsAdminOrders);
            yield put({ type: types.ADMIN_ORDERS_FETCH_SUCCESS, orders: response.records, total: response.total });
            yield put({ type: 'START_ADMIN_ORDERS_POLLING' });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.ORDERS_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.ADMIN_ORDERS_FETCH_FAIL, message });
    }
}
function* fetchAdminInvites(action) {
    try {
        if (action.type === types.INVITES_FETCH_REQUEST) {
            const response = yield call(adminActions.fetchUnusedInvites, action.urlOptions);
            yield put({
                type: types.INVITES_FETCH_SUCCESS,
                unusedInvites: response.records,
                totalInvites: response.total,
            });
        }
    }
    catch (error) {
        const message = composeAnyErrorMessage(error, Messages.INVITES_FETCH);
        yield put({ type: OPEN_ERROR_NOTICE, message });
        yield put({ type: types.INVITES_FETCH_FAIL, message });
    }
}
function* pollAdminOrders() {
    while (true) {
        try {
            const store = yield select();
            const { urlOptionsAdminOrders, isLoading } = store.admin;
            const response = yield call(orderActions.fetchRegularOrders, urlOptionsAdminOrders);
            yield put({ type: types.ADMIN_ORDERS_FETCH_SUCCESS, orders: response.records, total: response.total });
            if (!isLoading) {
                yield delay(20000);
            }
        }
        catch (error) {
            const message = composeAnyErrorMessage(error, Messages.ORDERS_FETCH);
            yield put({ type: OPEN_ERROR_NOTICE, message });
            yield put({ type: types.ADMIN_ORDERS_FETCH_FAIL, message });
            yield put({ type: 'STOP_ADMIN_ORDERS_POLLING', error });
        }
    }
}
function* pollTaskWatcher() {
    while (true) {
        yield take('START_ADMIN_ORDERS_POLLING');
        yield race([call(pollAdminOrders), take('STOP_ADMIN_ORDERS_POLLING')]);
    }
}
// eslint-disable-next-line import/prefer-default-export
export const adminSagas = [
    takeLatest(types.ADMIN_ORDERS_FETCH_REQUEST, fetchAdminOrders),
    takeLatest(types.INVITES_FETCH_REQUEST, fetchAdminInvites),
    pollTaskWatcher(),
];
