import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import buildUrl from 'build-url';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { Box, Button, TextField } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormHelperText from '@mui/material/FormHelperText';
import RolesService from '../../../../../../services/rolesService';
import Messages from '../../../../../../shared/helpers/errorMessages';
import CustomersService from '../../../../../../services/customersService';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
/* eslint-disable no-useless-escape, @typescript-eslint/no-non-null-assertion */
const InviteDialog = ({ open, clientCode, handleSendInvite, toggleFunc, features, }) => {
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState('');
    const [roles, setRoles] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const dispatch = useDispatch();
    const notice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const fetchRoles = (code, cancelToken) => {
        const rolesService = new RolesService({
            url: `/roles?client_code=${code}`,
            method: 'get',
            cancelToken,
            baseURL: getEnvSettings().adminApiUrl,
        });
        rolesService.makeRequest().then((dataRoles) => {
            const rolesInputValue = dataRoles.map(r => ({ label: r.name, value: r.name }));
            setRoles(rolesInputValue);
            if (rolesInputValue.length > 0) {
                setRole(rolesInputValue[0].value);
            }
        }).catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = `Failed fetch roles ${e.message}`;
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: notice,
            });
        });
    };
    const sendNewInvite = () => {
        const payload = {
            email,
            role,
            tenant_uuid: clientCode,
            customer_code: customer,
        };
        if (validateEmail(email)) {
            handleSendInvite(payload);
            setEmail('');
            if (roles) {
                setRole(roles[0].label);
            }
            toggleFunc();
        }
        else {
            showNotification({
                message: 'Must be valid email address',
                color: 'error',
                dispatch: notice,
            });
        }
    };
    const fetchCustomers = (url, cancelToken) => {
        const service = new CustomersService({ url, method: 'get', cancelToken });
        service.makeRequest()
            .then((responseData) => {
            const { records } = responseData;
            const mapped = records.map((i) => ({
                value: i.code,
                label: i.company_name || i.code,
            }));
            setCustomerOptions(mapped);
        })
            .catch((e) => {
            if (e.message === 'canceled') {
                return;
            }
            const message = composeErrorMessage(e, Messages.CUSTOMERS_FETCH);
            showNotification({
                color: 'error',
                dispatch: notice,
                message: `Error: ${message}`,
            });
        });
    };
    const onCustomerChange = (event) => {
        const code = event.target.value;
        setCustomer(code);
    };
    const onEmailChange = (evt) => {
        const { value } = evt.target;
        setEmail(value);
    };
    const onRoleChange = (event) => {
        const code = event.target.value;
        setRole(code);
    };
    const validateEmail = (str) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(str).toLowerCase());
    };
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        fetchRoles(clientCode, cancelTokenSource.token);
        return () => cancelTokenSource.cancel('canceled');
    }, [clientCode]);
    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (clientCode) {
            const urlOptions = {
                queryParams: {
                    limit: '1000',
                    sort_by: 'created',
                    sort_direction: 'desc',
                    client_code: clientCode,
                },
                path: '/customers',
            };
            const canReadDetails = features && Array.isArray(features) && features.includes('customer_details.read');
            if (canReadDetails) {
                urlOptions.queryParams.details = 'true';
            }
            const endpointUrl = buildUrl('', urlOptions);
            fetchCustomers(endpointUrl, cancelTokenSource.token);
        }
        return () => cancelTokenSource.cancel('canceled');
    }, [features, clientCode]);
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, null, "Create new Invite"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                React.createElement(TextField, { fullWidth: true, size: "small", value: email, label: "Email", margin: "normal", variant: "outlined", onChange: onEmailChange, InputLabelProps: { shrink: true }, helperText: `${validateEmail(email) ? '' : 'Must be valid email address'}` }),
                React.createElement(FormControl, { variant: "outlined", size: "small" },
                    React.createElement(InputLabel, { id: "role-label" }, "Role"),
                    React.createElement(Select, { displayEmpty: true, value: role, label: "Role", onChange: onRoleChange, labelId: "role-label" }, roles.map(r => (React.createElement(MenuItem, { key: r.value, value: r.value }, r.label)))),
                    !roles.length ? (React.createElement(FormHelperText, null, "You should create role")) : null),
                clientCode ? (React.createElement(FormControl, { size: "small", variant: "outlined" },
                    React.createElement(InputLabel, { id: "customer-label" }, "Customer"),
                    React.createElement(Select, { displayEmpty: true, value: customer, label: "Customer", disabled: !clientCode, onChange: onCustomerChange, className: "mui_select_wrap", labelId: "customer-label" }, customerOptions.map(c => (React.createElement(MenuItem, { key: c.value, value: c.value }, c.label)))),
                    !customerOptions.length ? (React.createElement(FormHelperText, null, "You should create customer")) : null)) : null)),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", variant: "contained", onClick: sendNewInvite, disabled: role === '' || email === '' }, "Create"),
            React.createElement(Button, { variant: "outlined", onClick: toggleFunc }, "Close"))));
};
export default InviteDialog;
