import React from 'react';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import ExchangesBalancesContent from './ExchangesBalancesContent';
const ExchangesBalancesComponent = ({ exchangesBalancesArray, openItems }) => {
    const arrOfArrays = exchangesBalancesArray
        .reduce((hash, obj) => (Object.assign(Object.assign({}, hash), { [obj.balance.code]: (hash[obj.balance.code] || []).concat(obj) })), {});
    const totalValues = [];
    for (const item in arrOfArrays) {
        if (item) {
            const val = arrOfArrays[item]
                .reduce((acm, i) => Decimal.add(acm, i.balance.available).toFixed(), '0');
            totalValues.push({ label: item, value: val });
        }
    }
    return (React.createElement("div", { className: "order-book__partial" }, exchangesBalancesArray && exchangesBalancesArray.length === 0
        ? (React.createElement(Alert, { severity: "info", variant: "outlined" },
            React.createElement("p", null,
                React.createElement("span", { className: "bold-text" }, "Items not found"))))
        : (React.createElement(React.Fragment, null,
            React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, "Currency"),
                        React.createElement(TableCell, null, "Exchange"),
                        React.createElement(TableCell, null, "Total"))),
                React.createElement(TableBody, null, totalValues.map(v => (React.createElement(ExchangesBalancesContent, { key: shortid.generate(), exchangesBalancesArray: [{ label: v.label, value: v.value, id: shortid.generate() }], collapsedExchangesBalancesArray: arrOfArrays[v.label], openItems: openItems })))))))));
};
export default ExchangesBalancesComponent;
