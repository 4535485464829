import * as yup from 'yup';
import { Box, IconButton, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CELL_TYPE } from './DLTTableModel';
const DLTEditableCell = (props) => {
    const { type = 'text' } = props;
    const formSchema = yup.object({
        [props.name]: type === CELL_TYPE.NUMERIC ? yup.number().min(0).required() : yup.string().required(),
    });
    const { handleSubmit, register, formState: { errors, isValid }, setValue, getValues, trigger, } = useForm({
        defaultValues: { [props.name]: props.value },
        resolver: yupResolver(formSchema),
    });
    const handleCancel = () => {
        setValue(props.name, props.value);
        trigger(props.name);
    };
    const handleChange = (event) => {
        setValue(props.name, event.target.value, { shouldDirty: true });
        trigger(props.name);
    };
    useEffect(() => {
        setValue(props.name, props.value);
        trigger(props.name);
    }, [props.value]);
    return (React.createElement(React.Fragment, null,
        React.createElement("form", { noValidate: true, onSubmit: handleSubmit((data) => props.onSave(data[props.name].toString())) },
            React.createElement(Box, { sx: { display: 'flex' } },
                React.createElement(Box, { flexGrow: 0 },
                    React.createElement(TextField, Object.assign({ onClick: e => { e.stopPropagation(); }, fullWidth: false }, register(props.name), { name: props.name, error: !!errors[props.name], onChange: handleChange, size: 'small' }))),
                React.createElement(Box, { flexShrink: 0 },
                    React.createElement("input", { type: "submit", hidden: true }),
                    props.value !== getValues(props.name) ? (React.createElement(React.Fragment, null,
                        React.createElement(IconButton, { onClick: (e) => e.stopPropagation(), type: "submit", disabled: !isValid, size: 'small' },
                            React.createElement(DoneIcon, null)),
                        React.createElement(IconButton, { onClick: (e) => { e.stopPropagation(); handleCancel(); }, size: 'small' },
                            React.createElement(CloseIcon, null)))) : null)))));
};
export default DLTEditableCell;
