import React from 'react';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';
const AskPrice = ({ price }) => (React.createElement("div", { className: "container_trade_color_red" }, price === ''
    ? React.createElement("span", null, "--")
    : React.createElement("span", null, new Decimal(price).toFixed(2))));
AskPrice.propTypes = {
    price: PropTypes.string.isRequired,
};
// eslint-disable-next-line no-unused-vars
const Ask = ({ orderBookItem }) => {
    if (orderBookItem && orderBookItem.ask && Object.keys(orderBookItem.ask).length) {
        const { price } = orderBookItem.ask;
        return React.createElement(AskPrice, { price: price });
    }
    return (React.createElement("div", { className: "container_trade_color_red" },
        React.createElement("span", null, "--")));
};
export default React.memo(Ask);
