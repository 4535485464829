import React, { useState } from 'react';
import shortid from 'shortid';
import { Button, TableRow, TableCell } from '@mui/material';
import ApproveDialog from './ApproveDialog';
import StrategiesTableCell from './StrategiesTableCell';
const StrategiesItem = ({ item, customers, columnsToRender, strategiesToRender, setStrategiesToRender, }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };
    return (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
        columnsToRender.map((column, i) => {
            const key = column.key;
            const value = key ? item[key] : undefined;
            return (React.createElement(StrategiesTableCell, { key: i, columnKey: key, customers: customers, value: value }));
        }),
        React.createElement(TableCell, { align: "right" }, item.status === 'wait_approval' ? (React.createElement(Button, { size: "small", variant: "outlined", onClick: () => toggleModal() }, "approve")) : null),
        modalOpen ? (React.createElement(ApproveDialog, { item: item, open: modalOpen, toggle: toggleModal, strategiesToRender: strategiesToRender, setStrategiesToRender: setStrategiesToRender })) : null));
};
export default StrategiesItem;
