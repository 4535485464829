import { useEffect, useReducer, useRef } from 'react';
/* eslint-disable import/prefer-default-export, @typescript-eslint/no-unused-vars, no-void, consistent-return */
export const useServiceFetchApis = (service, settingsUrl, delay) => {
    const settingsUrlRef = useRef('');
    let cancelRequest = false;
    const initialState = {
        status: 'init',
        error: undefined,
        data: undefined,
    };
    const fetchReducer = (state, action) => {
        switch (action.type) {
            case 'FETCH_INIT':
                return Object.assign(Object.assign({}, initialState), { status: 'fetching' });
            case 'FETCH_SUCCESS':
                return Object.assign(Object.assign({}, initialState), { status: 'fetched', data: action.payload });
            case 'FETCH_FAILURE':
                return Object.assign(Object.assign({}, initialState), { status: 'error', error: action.payload });
            default:
                return state;
        }
    };
    const [state, dispatch] = useReducer(fetchReducer, initialState);
    useEffect(() => {
        if (!service)
            return;
        const fetchApiData = () => {
            service.makeRequest()
                .then((response) => {
                dispatch({ type: 'FETCH_SUCCESS', payload: response });
            })
                .catch((e) => {
                dispatch({ type: 'FETCH_FAILURE', payload: e });
            });
        };
        // If hook is provided with GET url params
        // we have to make sure we unsubscirbe from
        // previous polling, and update current ref
        // otherwise just skip
        if (settingsUrl !== undefined && settingsUrl) {
            if (settingsUrlRef.current !== settingsUrl) {
                if (service.apiEndpoint) {
                    // eslint-disable-next-line no-param-reassign
                    service.config.url = `${service.apiEndpoint}${settingsUrl}`;
                    // unsubscribe previous
                    if (settingsUrlRef.current) {
                        service.clearPollRequest(`${service.apiEndpoint}${settingsUrlRef.current}`);
                    }
                }
                settingsUrlRef.current = settingsUrl || '';
            }
        }
        // start fetching
        if (service.config.url) {
            dispatch({ type: 'FETCH_INIT' });
            fetchApiData();
        }
        // if hook is provided with delay param,
        // invoke url polling and pass on fetch func callback
        // otherwise skip
        if (delay) {
            service.makePollRequest(fetchApiData, delay);
        }
        return () => {
            cancelRequest = true;
            // if delay was provided - clear all existing pollings
            // on component unmount
            if (delay) {
                if (settingsUrl && service.apiEndpoint) {
                    service.clearPollRequest(`${service.apiEndpoint}${settingsUrlRef.current}`);
                }
                else if (service.config.url) {
                    service.clearPollRequest(service.config.url);
                }
            }
        };
    }, [service, settingsUrl]);
    return state;
};
