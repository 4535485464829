var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable camelcase */
import { useCallback, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import Messages from '../../../../../shared/helpers/errorMessages';
import showNotification from '../../../../../shared/helpers/notifications';
import { CLOSE_ERROR_NOTICE } from '../../../../../redux/actionTypes/apiErrorsActionTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
const useCurrenciesService = () => {
    const [state, setState] = useState({
        loading: true,
        currencies: [],
        priceFeeds: [],
        networks: [],
    });
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const showSuccessMessage = (message) => {
        showNotification({
            message: message,
            color: 'success',
        });
    };
    const getCurrencies = () => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'get',
            url: '/custody/currency',
            baseURL: getEnvSettings().adminApiUrl,
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { currencies: response.data.records })));
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.CURRENCY_CONFIG_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    const updateCurrency = (currency, data) => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'patch',
            url: `/custody/currency/${currency}`,
            baseURL: getEnvSettings().adminApiUrl,
            data,
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, currencies: prevState.currencies.map((currency) => {
                    return currency.code === response.data.code ? response.data : currency;
                }) })));
            showSuccessMessage('Currency was successfully updated');
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.CURRENCY_CONFIG_UPDATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    const addCurrency = (currency) => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'post',
            url: `/custody/currency/`,
            baseURL: getEnvSettings().adminApiUrl,
            data: currency,
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, currencies: [...prevState.currencies, response.data] })));
            showSuccessMessage('Currency was successfully added');
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.CUSTODY_CURRENCY_ADD);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
            throw message;
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    const getPriceFeeds = () => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'get',
            url: '/custody/currency/price_feed',
            baseURL: getEnvSettings().adminApiUrl,
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, priceFeeds: response.data.records })));
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.PRICE_FEEDS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    const addPriceFeed = (feed) => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'post',
            url: `/custody/currency/price_feed`,
            baseURL: getEnvSettings().adminApiUrl,
            data: feed,
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, priceFeeds: [...prevState.priceFeeds, response.data] })));
            showSuccessMessage('Price feed was successfully added');
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.PRICE_FEED_ADD);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    const updatePriceFeed = (data, id) => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'patch',
            url: `/custody/currency/price_feed/${id}`,
            baseURL: getEnvSettings().adminApiUrl,
            data: { name: data.name },
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, priceFeeds: prevState.priceFeeds.map((feed) => {
                    return feed.uuid === response.data.uuid ? response.data : feed;
                }) })));
            showSuccessMessage('Price Feed was successfully updated');
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.PRICE_FEED_UPDATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    const getNetworks = () => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'get',
            url: '/custody/currency/network',
            baseURL: getEnvSettings().adminApiUrl,
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, networks: response.data.records })));
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.NETWORKS_FETCH);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    const addNetwork = (network) => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'post',
            url: `/custody/currency/network`,
            baseURL: getEnvSettings().adminApiUrl,
            data: network,
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, networks: [...prevState.networks, response.data] })));
            showSuccessMessage('Network was successfully added');
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.NETWORK_ADD);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    const updateNetwork = (data, id) => __awaiter(void 0, void 0, void 0, function* () {
        setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: true })));
        yield axios
            .request({
            method: 'patch',
            url: `/custody/currency/network/${id}`,
            baseURL: getEnvSettings().adminApiUrl,
            data,
        })
            .then((response) => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false, networks: prevState.networks.map((network) => {
                    return network.uuid === response.data.uuid ? response.data : network;
                }) })));
            showSuccessMessage('Network was successfully updated');
        })
            .catch((error) => {
            const message = composeErrorMessage(error, Messages.NETWORK_UPDATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        })
            .finally(() => {
            setState((prevState) => (Object.assign(Object.assign({}, prevState), { loading: false })));
        });
    });
    return {
        getCurrencies,
        updateCurrency,
        addCurrency,
        getPriceFeeds,
        addPriceFeed,
        updatePriceFeed,
        getNetworks,
        updateNetwork,
        addNetwork,
        currencies: state.currencies,
        priceFeeds: state.priceFeeds,
        networks: state.networks,
        loading: state.loading,
    };
};
export default useCurrenciesService;
