import React, { useReducer, useEffect } from 'react';
import { connect } from 'react-redux';
import WalletsFiltersContent from './StreamsFiltersContent';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
/* eslint-disable @typescript-eslint/no-unsafe-call, prefer-destructuring, no-plusplus,
  @typescript-eslint/prefer-regexp-exec, @typescript-eslint/no-unsafe-return */
var FilterActionType;
(function (FilterActionType) {
    FilterActionType["ADD"] = "ADD";
    FilterActionType["RESET"] = "RESET";
    FilterActionType["UPDATE"] = "UPDATE";
    FilterActionType["DELETE"] = "DELETE";
})(FilterActionType || (FilterActionType = {}));
const init = (args) => args;
const reducer = (state, action) => {
    switch (action.type) {
        case FilterActionType.ADD:
            return { filters: [...state.filters, action.item] };
        case FilterActionType.DELETE:
            return {
                filters: state.filters
                    .filter(f => f.label !== action.item.label),
            };
        case FilterActionType.UPDATE: {
            const objIndex = state.filters
                .findIndex((obj => obj.name === action.item.name));
            const newArr = state.filters;
            newArr[objIndex].value = action.item.value;
            newArr[objIndex].label = action.item.label;
            return { filters: newArr };
        }
        case FilterActionType.RESET:
            return { filters: [] };
        default:
            return state;
    }
};
const StreamsFilters = ({ selectedClient, onFiltersChange, initialValue, toggleModal, baseOptions, quoteOptions, exchangeOptions, features, }) => {
    const [state, dispatch] = useReducer(reducer, initialValue, init);
    const addSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.ADD, item: val });
    };
    const removeSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.DELETE, item: val });
    };
    const updateSelectedFilter = (val) => {
        dispatch({ type: FilterActionType.UPDATE, item: val });
    };
    const resetFilters = () => {
        dispatch({ type: FilterActionType.RESET });
    };
    const handleExhangeChange = (option) => {
        const item = {
            type: 'exchange',
            name: 'exchange_code',
            label: option ? option.value : '',
            value: option ? option.value.toLowerCase() : '',
        };
        const isFilter = state.filters.find(f => f.name === 'exchange_code');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleStreamTypeChange = (option) => {
        const item = {
            name: 'stream_type',
            type: 'stream_type',
            label: option ? option.value : '',
            value: option ? option.value.toLowerCase() : '',
        };
        const isFilter = state.filters.find(f => f.name === 'stream_type');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleBaseChange = (option) => {
        const item = {
            name: 'base',
            type: 'base',
            label: option ? option.value : '',
            value: option ? option.value : '',
        };
        const isFilter = state.filters.find(f => f.name === 'base');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleQuoteChange = (option) => {
        const item = {
            name: 'quote',
            type: 'quote',
            label: option ? option.value : '',
            value: option ? option.value : '',
        };
        const isFilter = state.filters.find(f => f.name === 'quote');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    const handleAssignedChange = (option) => {
        const item = {
            name: 'assigned',
            type: 'assigned',
            label: option ? option.label.toLowerCase() : '',
            value: option ? option.value : '',
        };
        const isFilter = state.filters.find(f => f.name === 'assigned');
        isFilter ? updateSelectedFilter(item) : addSelectedFilter(item);
    };
    useEffect(() => {
        const filters = state.filters.filter(f => f.name !== 'assigned');
        localStorage.setItem('rfqStreamFilters', JSON.stringify(filters));
        onFiltersChange(state.filters);
    }, [state]);
    return (React.createElement(WalletsFiltersContent, { state: state, features: features, toggleModal: toggleModal, baseOptions: baseOptions, quoteOptions: quoteOptions, resetFilters: resetFilters, exchangeOptions: exchangeOptions, selectedClient: selectedClient, handleBaseChange: handleBaseChange, handleQuoteChange: handleQuoteChange, handleExhangeChange: handleExhangeChange, removeSelectedFilter: removeSelectedFilter, handleStreamTypeChange: handleStreamTypeChange, handleAssignedChange: handleAssignedChange }));
};
const mapStateToProps = (state) => ({
    selectedClient: getSelectedClientInputValue(state),
});
const mapDispatchToProps = () => ({
    initialValue: {
        filters: localStorage.getItem('rfqStreamFilters')
            ? JSON.parse(localStorage.getItem('rfqStreamFilters') || '') : [],
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(StreamsFilters);
