import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { connect } from 'react-redux';
import { selectCustomer } from '../../../../../../../redux/actions/customersSearchActions';
import { getCustomersSearch } from '../../../../../../../redux/selectors/customerSearchSelectors';
import { CUSTOMER_INFO_FETCH_REQUEST } from '../../../../../../../redux/actionTypes/clientActionTypes';
const SearchInput = ({ customers, selectedCustomer = null, loading, features, }) => {
    const [customer, setCustomer] = useState(null);
    const dispatch = useDispatch();
    const handleCustomerSelect = (selectedCustomer) => {
        const customer = customers.find(c => c.code === (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer.value));
        if (customer) {
            dispatch(selectCustomer(customer));
            dispatch({ type: CUSTOMER_INFO_FETCH_REQUEST, code: customer.code });
        }
        if (!selectedCustomer) {
            dispatch(selectCustomer(null));
        }
    };
    const flattedCustomers = customers.map(item => ({ value: item.code, label: item.label }));
    useEffect(() => {
        if (features) {
            const canReadDetails = features.includes('customer_details.read');
            const customerPersisted = canReadDetails ? selectedCustomer : null;
            dispatch(selectCustomer(customerPersisted));
        }
    }, [features]);
    useEffect(() => {
        if (selectedCustomer) {
            setCustomer({ value: selectedCustomer.code || '', label: selectedCustomer.company_name || selectedCustomer.code || '' });
        }
        else {
            setCustomer(null);
        }
    }, [selectedCustomer]);
    return (React.createElement(Autocomplete, { value: customer, loading: loading, options: flattedCustomers, getOptionDisabled: () => !!customer, getOptionLabel: (option) => option.label, onChange: (event, value) => handleCustomerSelect(value), isOptionEqualToValue: (option, selected) => option.value === selected.value, renderOption: (props, option) => (React.createElement(Box, Object.assign({ component: "li" }, props, { key: option.value }), option.label)), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { label: "Select customer", variant: "outlined", size: "small" })) }));
};
const stateToProps = (state) => {
    const { loading, selectedCustomer } = state.customersSearch;
    const { features } = state.client;
    let customers = [];
    if (!loading) {
        customers = getCustomersSearch(state);
    }
    return {
        loading,
        features,
        customers,
        selectedCustomer,
    };
};
export default connect(stateToProps)(SearchInput);
