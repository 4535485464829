import React, { useEffect, useMemo } from 'react';
import shortid from 'shortid';
import { useDispatch, connect } from 'react-redux';
import TopBarMenuItem from './TopBarMenuItem';
import { getWorkspace } from '../../../redux/selectors/workspaceSelector';
import { getSettings } from '../../../redux/selectors/widgetSettingsSelector';
import { SCHEMA_CLONE_WIDGET } from '../../../redux/actionTypes/widgetSettingsActionTypes';
const mappedWidgetsForHide = {
    hideOrders: '',
    hideWallets: '',
    hideDeposits: '',
    hideApprovals: '',
    hideAddresses: '',
    hideWithdrawals: '',
};
const approveArr = [
    'client_custody_transaction.update',
    'client_custody_address_master_sign.create',
    'client_custody_withdrawal_address.update',
    'client_custody_withdrawal_master_sign.create',
];
export const forbidForCloning = [
    'clients',
    'wallets',
    'balances',
    'portfolio',
    'strategies',
    'placeOrder',
    'autohedger',
    'placeOrders',
    'hedgeOrders',
    'manageUsers',
    'manageTokens',
    'manageOrders',
    'clientSearch',
    'quoteMonitor',
    'adminExchange',
    'manageApprove',
    'manageInvites',
    'manageLogbook',
    'currencyConfig',
    'marketOverview',
    'customerSearch',
    'manageWhitelist',
    'complianceStats',
    'manageCustomers',
    'manageSchedulers',
    'manageCompliance',
    'tradingTransfers',
    'clientsManagement',
    'exchangesBalances',
    'autohedgerBalance',
    'complianceWallets',
    'complianceTransactions',
];
const TopBarWidgets = ({ features, settings, widgets, mappedWorkSpace, toggleMenu, }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (features && features.length > 0) {
            if (!approveArr.every(i => features.includes(i))) {
                mappedWidgetsForHide.hideApprovals = 'approve';
            }
            if (!features.includes('client_custody_wallet.read')) {
                mappedWidgetsForHide.hideWallets = 'wallets';
            }
            if (!features.includes('client_custody_withdrawal_address.read')) {
                mappedWidgetsForHide.hideAddresses = 'addresses';
            }
            if (!features.includes('client_custody_transaction.read')) {
                mappedWidgetsForHide.hideDeposits = 'deposits';
                mappedWidgetsForHide.hideWithdrawals = 'withdrawals';
            }
            if (!features.includes('order.read')) {
                mappedWidgetsForHide.hideOrders = 'ordersList';
            }
        }
    }, [features]);
    const addWidgetCopy = (widget) => {
        if (!widget)
            return;
        const isCopy = (widgets === null || widgets === void 0 ? void 0 : widgets.findIndex(w => w.name === widget.name)) > -1;
        const clonedSettings = {
            byId: {},
            allIds: [],
        };
        const wKey = widget.widgetKey;
        const wSettings = widget.settings;
        let widgetBody = widget;
        if (isCopy) {
            const keyId = shortid.generate();
            const key = `${widget.type}${keyId}`;
            widgetBody = {
                name: widget.name,
                widgetKey: key,
                type: widget.type,
                settings: wSettings.map(s => `${key}${s.replace(wKey, '')}`),
                layoutProps: {
                    x: 0,
                    y: 0,
                    h: widget.layoutProps.h,
                    w: widget.layoutProps.w,
                    i: key,
                    minH: widget.layoutProps.minH,
                    minW: widget.layoutProps.minW,
                    moved: false,
                    static: false,
                },
            };
        }
        const widgetSettings = wSettings.map(ws => settings.filter(s => s.id === ws)).flat();
        widgetSettings.forEach((s) => {
            clonedSettings
                .byId[`${widgetBody.widgetKey}${s.id.replace(wKey, '')}`] = Object
                .assign({}, s, { id: `${widgetBody.widgetKey}${s.id.replace(wKey, '')}` });
            clonedSettings
                .allIds
                .push(`${widgetBody.widgetKey}${s.id.replace(wKey, '')}`);
        });
        dispatch({
            type: SCHEMA_CLONE_WIDGET,
            widget: widgetBody,
            widgetSettings: clonedSettings,
        });
        toggleMenu();
    };
    for (const w in mappedWidgetsForHide) {
        if (mappedWidgetsForHide[w]) {
            forbidForCloning.push(mappedWidgetsForHide[w]);
        }
    }
    const filteredComponents = useMemo(() => mappedWorkSpace === null || mappedWorkSpace === void 0 ? void 0 : mappedWorkSpace.widgets.filter(c => !forbidForCloning.includes(c.type)), [mappedWorkSpace]);
    return (React.createElement("div", null,
        filteredComponents && filteredComponents.map((component, i) => (React.createElement(TopBarMenuItem, { key: `${component.widgetKey}${i}`, title: component.name, onClick: () => addWidgetCopy(component) }))),
        " ",
        (filteredComponents === null || filteredComponents === void 0 ? void 0 : filteredComponents.length) === 0 ? React.createElement("p", null, "No widgets available") : ''));
};
const stateToProps = (state) => ({
    settings: getSettings(state),
    features: state.client.features,
    mappedWorkSpace: getWorkspace(state),
    widgets: state.widgetSettings.widgets,
});
export default connect(stateToProps)(TopBarWidgets);
