import { useState } from 'react';
const useConfirmationDialog = () => {
    const [isConfirmOpen, setIsOpen] = useState(false);
    const openConfirmDialog = () => {
        setIsOpen(true);
    };
    const closeConfirmDialog = () => {
        setIsOpen(false);
    };
    return {
        isConfirmOpen,
        openConfirmDialog,
        closeConfirmDialog,
    };
};
export default useConfirmationDialog;
