import axios from 'axios';
import { ApiPollService } from '../shared/helpers/apiPollService';
/* eslint-disable class-methods-use-this */
export default class CustomerInfoService {
    constructor(config, delay) {
        this.model = {
            vat: '',
            bfid: '',
            city: '',
            code: '',
            depot: '',
            email: '',
            ibans: [],
            rubric: '',
            address: '',
            country: '',
            currency: '',
            zip_code: '',
            has_user: '',
            created_at: '',
            client_code: '',
            company_name: '',
            kontokorrent: '',
            phone_number: '',
            is_enabled: false,
            contact_person_name: '',
        };
        this.config = config;
        this.pollingService = new ApiPollService(delay || 10000);
    }
    getData() {
        return this.model;
    }
    handleResponse(response) {
        if (response.data) {
            this.model = response.data;
            return this.model;
        }
        return this.model;
    }
    makeRequest() {
        const { ErrorLogger } = window;
        const axiosRequest = axios(this.config);
        return axiosRequest
            .then(response => this.handleResponse(response))
            .catch((error) => {
            if (error.message === 'canceled') {
                return this.handleReject(error);
            }
            ErrorLogger.setErrors(error, new Date());
            return this.handleReject(error);
        });
    }
    makePollRequest(callback, delay) {
        if (delay) {
            this.pollingService.delay = delay;
        }
        if (this.config.url) {
            const callbackPayload = {
                url: this.config.url,
                action: callback,
            };
            this.pollingService.addCallback(callbackPayload);
        }
    }
    clearPollRequest(url) {
        this.pollingService.removeCallBack(url);
    }
    handleReject(error) {
        throw error;
    }
}
