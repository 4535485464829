import React from 'react';
import Alert from '@mui/material/Alert';
import PartialOrderItem from './PartialOrderItem';
const PartialOrderbookContent = ({ partialOrders }) => (React.createElement(React.Fragment, null, partialOrders && partialOrders.length
    ? partialOrders.map((orderItem) => (React.createElement(PartialOrderItem, { key: orderItem.code, orderItem: orderItem }))) : (React.createElement("tr", null,
    React.createElement("td", { colSpan: 7 },
        React.createElement(Alert, { severity: "info", variant: "outlined" },
            React.createElement("p", null,
                React.createElement("span", { className: "bold-text" }, "Items not found"))))))));
export default PartialOrderbookContent;
