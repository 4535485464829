import React, { useRef, useState, useEffect } from 'react';
import shortid from 'shortid';
import Decimal from 'decimal.js';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { NumericFormat } from 'react-number-format';
import { getEnvSettings } from '../../../../../config/environmentSettings';
const envSettings = getEnvSettings();
const getCreatedTime = (createdDate) => {
    const dateCreated = new Date(createdDate);
    return dateCreated.toLocaleTimeString();
};
const getExchangeOption = (exchanges, code) => {
    const option = exchanges.find(exchange => exchange.value === code);
    if (option) {
        return option.label;
    }
    return code;
};
/* eslint-disable react/jsx-indent */
const RenderHistoryRow = ({ channel, exchanges }) => {
    const prevChannel = useRef('');
    const refId = useRef(shortid.generate());
    const [tradesUpdated, setTradesUpdated] = useState([]);
    const exchangeTradeHistoryUpdate = (data) => {
        const { payload } = data;
        try {
            setTradesUpdated((prevTrades) => {
                const trades = payload.length > 10 ? payload.slice(0, 10) : payload;
                if (trades.length === 10) {
                    return trades;
                }
                if (Array.isArray(trades)) {
                    return [...trades, ...prevTrades].slice(0, 10);
                }
                return [];
            });
        }
        catch (e) {
            setTradesUpdated([]);
        }
    };
    useEffect(() => {
        const { OrderBookWS } = window;
        if (prevChannel.current !== '') {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        }
        OrderBookWS.addSubscription(channel, { callback: exchangeTradeHistoryUpdate, componentId: refId.current });
        setTradesUpdated([]);
        prevChannel.current = channel;
        return () => {
            OrderBookWS.removeSubscription(prevChannel.current, refId.current);
        };
    }, [channel]);
    return (React.createElement(TableBody, null, tradesUpdated.length ? tradesUpdated.map((message) => {
        const uniqueKey = `${message.external_code}${Math.random().toString(36).substring(7)}`;
        const exchangeName = getExchangeOption(exchanges, message.exchange);
        let classTD = '';
        if (message.action === 'Buy') {
            classTD = 'action-buy container_trade_color_green';
        }
        else if (message.action === 'Sell') {
            classTD = 'action-sell container_trade_color_red';
        }
        return (React.createElement(TableRow, { key: uniqueKey },
            React.createElement(TableCell, { className: classTD },
                React.createElement(NumericFormat, { displayType: "text", value: new Decimal(message.price).valueOf(), thousandSeparator: envSettings.thousandDivider })),
            React.createElement(TableCell, null,
                React.createElement(NumericFormat, { displayType: "text", value: new Decimal(message.amount).valueOf(), thousandSeparator: envSettings.thousandDivider })),
            React.createElement(TableCell, null, exchangeName),
            React.createElement(TableCell, null, getCreatedTime(message.created))));
    }) : (React.createElement(TableRow, { key: "noItems" },
        React.createElement(TableCell, { colSpan: 4 }, "No data")))));
};
export default RenderHistoryRow;
