import React from 'react';
import { Table, TableRow, TableBody, TableCell, TableHead, TableContainer, } from '@mui/material';
import RowSingleMarket from './RowSingleMarket';
const CollapsedItem = ({ markets }) => (React.createElement(TableContainer, null,
    React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
        React.createElement(TableHead, null,
            React.createElement(TableRow, null,
                React.createElement(TableCell, { className: "th_thinner" }, "Base"),
                React.createElement(TableCell, { className: "th_thinner" }, "Quote"))),
        React.createElement(TableBody, null, markets.map((marketItem, i) => React.createElement(RowSingleMarket, { key: i, marketItem: marketItem }))))));
export default CollapsedItem;
