import React from 'react';
import { Chip } from '@mui/material';
import { OrderInfo } from '../MergedOrderList/styling/styledComponents';
import { InfoItem, InfoItemLabel, InfoItemValue } from './styling/styledComponent';
const SubOrderHead = ({ color, orderItem }) => (React.createElement("div", null,
    React.createElement(OrderInfo, null,
        React.createElement(InfoItem, null,
            React.createElement(InfoItemLabel, null, "Order ID"),
            React.createElement(InfoItemValue, null,
                React.createElement(Chip, { size: "small", style: { color }, label: orderItem ? orderItem.code : '' }))),
        React.createElement(InfoItem, null,
            React.createElement(InfoItemLabel, null, "Split strategy"),
            React.createElement(InfoItemValue, null, orderItem ? orderItem.split_strategy : '')),
        React.createElement(InfoItem, null,
            React.createElement(InfoItemLabel, null, "Execution strategy"),
            React.createElement(InfoItemValue, null, orderItem ? orderItem.execution_strategy : '')),
        orderItem && orderItem.reference_id ? (React.createElement(InfoItem, null,
            React.createElement(InfoItemLabel, null, "Reference Id"),
            React.createElement(InfoItemValue, null, orderItem ? orderItem.reference_id : ''))) : null)));
export default SubOrderHead;
