import React, { useState, useEffect } from 'react';
import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import { useResizeDetector } from 'react-resize-detector';
import { TransfersCellItem } from '../../../../Custody/styling/style';
import getCustomerNameByCode from '../../../../../../shared/helpers/getCustomerNameByCode';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
const UsersTableCell = ({ value, columnKey, customers }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (columnKey === 'code') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)));
    }
    if (columnKey === 'created_at') {
        return (React.createElement(TableCell, { align: "right" }, value ? moment(value).format('YYYY-MM-DD H:mm') : 'n/a'));
    }
    if (columnKey === 'customer_code') {
        return (React.createElement(TableCell, { align: "right" }, getCustomerNameByCode(customers, value) || 'N/A'));
    }
    if (columnKey === 'is_enabled') {
        return (React.createElement(TableCell, { align: "right" },
            React.createElement(FiberManualRecordIcon, { color: value ? 'primary' : 'error', fontSize: "small" })));
    }
    return (React.createElement(TableCell, { align: "right" }, value || 'N/A'));
};
export default UsersTableCell;
