import { Box, Link, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import React from 'react';
const NotFound404 = () => (React.createElement(Box, { p: 4, sx: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    } },
    React.createElement(Typography, { variant: 'h3', fontWeight: 'bold', mb: 2 }, "Apologies, but the page you are searching for cannot be located."),
    React.createElement(Typography, { variant: 'h5', fontWeight: 'bold' },
        React.createElement(Link, { component: NavLink, to: '/' }, "Back Home"))));
export default NotFound404;
