import React, { useState, useEffect } from 'react';
import { Chip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { useResizeDetector } from 'react-resize-detector';
import { TransfersCellItem } from '../../styling/style';
import { statusBadgeData } from '../../../../../config/statusBadgeData';
import statusBadgeColor from '../../../../../shared/helpers/statusBadgeColor';
import CopyValue from '../../../../../shared/components/CopyValueComponent';
const AddressTableCell = ({ value, columnKey }) => {
    const { width, ref } = useResizeDetector();
    const [cellWidth, setCellWidth] = useState(0);
    useEffect(() => {
        if (width) {
            setCellWidth(width - 20);
        }
    }, [width]);
    if (columnKey === 'currency') {
        return (React.createElement(TableCell, { align: "left" }, value ? value.toUpperCase() : 'n/a'));
    }
    if (columnKey === 'code' || columnKey === 'address') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            value ? (React.createElement(CopyValue, { value: value })) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value)));
    }
    if (columnKey === 'created_at' || columnKey === 'updated_at') {
        return (React.createElement(TableCell, { align: "left" }, value ? `${new Date(value).toLocaleDateString()}, ${new Date(value).toLocaleTimeString()}` : 'n/a'));
    }
    if (columnKey === 'label') {
        return (React.createElement(TableCell, { align: "left", ref: ref },
            value ? (React.createElement(CopyValue, { value: value })) : null,
            React.createElement(TransfersCellItem, { style: { maxWidth: cellWidth } }, value || 'N/A')));
    }
    if (columnKey === 'state') {
        return (React.createElement(TableCell, { align: "left" },
            React.createElement(Chip, { size: "small", label: value || 'N/A', style: statusBadgeColor(statusBadgeData.stateBackgroundsWithdrawalsAddress[value || ''], statusBadgeData.stateBordersWithdrawalsAddress[value || ''], statusBadgeData.stateColorsWithdrawalsAddress[value || '']).badge })));
    }
    return (React.createElement(TableCell, { align: "left" }, value || 'N/A'));
};
export default AddressTableCell;
