import React from 'react';
import { Box, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { createStyles, makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => createStyles({
    root: {
        padding: theme.spacing(2),
        '&.link-active': {
            borderLeft: `2px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
        },
        '&:hover': {
            cursor: 'pointer',
        },
        '&.submenu': {
            padding: theme.spacing(1),
        }
    },
}));
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus, react/jsx-indent */
const TopBarMenuItem = ({ title, onClick, onCloseClick, removable, isActive, icon, submenu }) => {
    const classes = useStyles();
    return (React.createElement(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', onClick: onClick, className: `${classes.root} ${isActive ? 'link-active' : ''} ${submenu ? 'submenu' : ''}` },
        React.createElement("span", null, title),
        removable && (React.createElement(IconButton, { size: 'small', onClick: onCloseClick, onKeyDown: () => undefined },
            React.createElement(DeleteForeverIcon, { fontSize: 'small' }))),
        icon ? icon : null));
};
TopBarMenuItem.defaultProps = {
    isActive: false,
    removable: false,
    onCloseClick: () => undefined,
};
export default TopBarMenuItem;
