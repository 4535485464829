/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any, no-underscore-dangle, max-len */
export const getPermissionsDescription1 = () => ({
    customer: 'Create customer, Get customer list and by params, Customer info update',
    customer_details: 'Get customer list and by params (with sensetive data)',
    trader: '',
    trader_details: '',
    customer_activity: '',
    trader_activity: '',
    report: 'Rule for generate reconciliation and settlement',
    custody_report: 'Generate custody report as like: asset_under_custody, custody_fee_report, custody_transaction_report',
    report_trading: '',
    history: '',
    history_trading: 'Get list of trades ',
    balance: 'Get clients, customers balances and get list of transfers (manual deposits and withdrawals - deprecated), Create manual deposits and withdrawals - deprecated',
    order: 'Create order/partial and suborder, delete suborder update suborder and execute suborder, Get orders and partial order/s information and get order stats + settlement info, Cancel/ update order and partial order',
    users: 'Get policy, check 2FA, get users list, Password reset request (use by admin)',
    tenants: 'Create clients, Get client/s and by parameters, Update client personal info, FIX setting',
    invites: 'Create invite, resend invite, Get invites list',
    customer_invites: 'Create customer`s invite',
    reserves: 'Get balance from exchange and custody balance for deposit holder',
    settlement: 'Create order record on settlement service, Get list settlement`s state, Mark order settlement state as bank done (only for interservice communication) Also allow to mark order settlement state as bank done manual',
    order_problem: 'Set abort order/ set exchange external ID',
    roles_permissions: 'Get list of permissions, Update permissions',
    tenants_restrictions: 'Enable/Disable client',
    users_restrictions: 'Enable/Disable user',
    api_tokens: 'Create api token, Get list of api tokens, Delete api token',
    order_cancel_all: 'Cancel all open orders',
    order_cancel_bulk: 'Create orders cancel batch by filter (like currency), Get order cancel batch',
    exchange_withdrawals: 'Create withdrawal on exchange, List withdrawal from exchange',
    exchange: 'Enable/Disable exchange',
    fee_report: 'Create trade fee report',
    archive_settlement_info: 'Get /list SFTP archive files(settlement, customer updates, credit lines, etc)',
    audit_event: 'List audit events',
    custody_access_rules: '',
    client_custody_transaction: 'Create withdrawal, Get deposits / withdrawals list, Update reference id in deposit / sign withdrawals, Delete withdrawals',
    client_custody_wallet: 'Create wallet, Get wallet list, Update wallet label',
    client_custody_balance: 'Get wallet balance',
    client_custody_withdrawal_address: 'Create withdrawal address, Get list withdrawal addreses, Sign / approve / (update - send delete request for withdrawal address), Delete withdrawal address',
    client_custody_manual_aml: 'Used to create a manual aml check for a client custody withdrawal address',
    client_custody_address_master_sign: 'Create master_sign for withdrawal address',
    client_custody_withdrawal_master_sign: 'Master sign for withdrawal',
    client_custody_wallet_customer: 'Assign customer to wallet, Unassign customer from wallet',
    custody_transaction: 'Get list open positions / get deposit list / get withdrawal list (margin trading)',
    client_rfq_settings: 'Allows you to create RFQ Settings + and also allows you to assign existing RFQ Settings to a given client, Allows you to list RFQ settings and list assigned RFQ settings to a given client, Allows you to update RFQ Settings records, Allows you to delete RFQ Settings + and also allows you to unassign RFQ Settings from a client',
    auto_hedger: 'Get list of autohedger settings, Create and update autohedger settings, Delete autohedger settings',
    trades_volume_fee_settings: 'Create setting fee for client, Reed setting fee for client, Delete settings',
    custody_currency_configuration: 'Add new currerncy, Get list of currency, Update currency (label, min deposite size)',
    trading_transfers: 'Need client for create withdrawal/deposit, Get list for admin and client, Need admin for approve/decline withdrawal/deposit',
    admin_hedge_order: 'Add permission for admin create order',
    instruments: '',
    restrict_order_size: 'Signing mechanism for orders will be activated, and Placing Market orders won’t be possible',
    order_sign: 'For sign limit and RFQ limit order',
    trading_transfers_sign: 'Signature for fiat deposits/withdrawals',
});
