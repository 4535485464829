import { Box, Button, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DLTAutocomplete from '../../../../../shared/components/form/DLTAutocomplete';
import axios from 'axios';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import showNotification from '../../../../../shared/helpers/notifications';
import Messages from '../../../../../shared/helpers/errorMessages';
import { connect } from 'react-redux';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { CUSTOMER_SEARCH_SET_CLIENT, } from '../../../../../redux/actionTypes/customerSearchActionsTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import LoadingButton from '@mui/lab/LoadingButton';
import { FIAT_CURRENCIES } from '../../../../../shared/constants/FiatOptions';
import { DECIMAL_REGEX } from '../../../../../shared/regex/decimal';
const setCustomersClient = (client) => ({
    type: CUSTOMER_SEARCH_SET_CLIENT,
    selectedClient: client,
});
const formSchema = yup.object({
    currency: yup.string().required(),
    capacity: yup.string().matches(DECIMAL_REGEX).required(),
    client: yup.string().required(),
    customer: yup.string().required(),
});
const CreateSettlementLine = (props) => {
    const { customers, clients, dispatchClientSelect } = props;
    const { handleSubmit, control, register, trigger, setValue, formState: { isValid, errors }, } = useForm({
        defaultValues: formSchema.cast({ currency: '', client: '', customer: '', capacity: 0 }),
        resolver: yupResolver(formSchema),
    });
    const [loading, setLoading] = useState(false);
    const selectedClient = useWatch({ control, name: 'client' });
    const handleCreateLine = (currency, customer, capacity) => {
        setLoading(true);
        const params = {
            customer_code: customer,
            currency_code: currency,
            capacity,
        };
        axios
            .post('/settlement_line', params, { baseURL: getEnvSettings().adminApiUrl })
            .then(() => {
            props.toggleDialog(true);
            showNotification({
                message: `Settlement line was created`,
                color: 'success',
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.SETTLEMENT_LINE_CREATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
            });
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        dispatchClientSelect(selectedClient);
    }, [selectedClient]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DialogTitle, null, "Create Settlement Line"),
        React.createElement(Box, { component: 'form', onSubmit: handleSubmit((data) => handleCreateLine(data.currency, data.customer, data.capacity.toString())) },
            React.createElement(DialogContent, null,
                React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap' }, gap: 2 },
                    React.createElement(TextField, Object.assign({ InputLabelProps: { shrink: true }, error: !!errors.capacity, size: 'small', label: 'Capacity', placeholder: 'Amount' }, register('capacity', { valueAsNumber: true }), { onChange: (e) => {
                            setValue('capacity', e.target.value);
                            trigger('capacity');
                        } })),
                    React.createElement(DLTAutocomplete, { fullWidth: true, name: 'currency', label: 'Currency', options: FIAT_CURRENCIES, control: control }),
                    React.createElement(DLTAutocomplete, { fullWidth: true, label: 'Client', name: 'client', options: clients, control: control }),
                    React.createElement(DLTAutocomplete, { disabled: !selectedClient, fullWidth: true, label: 'Customer', name: 'customer', options: customers, control: control }))),
            React.createElement(DialogActions, null,
                React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained', disabled: !isValid }, "Create"),
                React.createElement(Button, { onClick: () => props.toggleDialog(false) }, "Cancel")))));
};
const mapStateToProps = (state) => {
    const mappedCustomers = getCustomersSelectedInputInterface(state).map((c) => ({
        value: c.value,
        label: c.label,
    }));
    const mappedClients = state.clientSearch.allClients.map((c) => ({
        value: c.code,
        label: c.company_name,
    }));
    return {
        customers: mappedCustomers,
        clients: mappedClients,
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchClientSelect: (client) => dispatch(setCustomersClient(client)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateSettlementLine);
