import axios from 'axios';
import { ApiPollService } from '../shared/helpers/apiPollService';
/* eslint-disable class-methods-use-this */
export default class SplitItemService {
    constructor(config, delay) {
        this.model = {
            code: '',
            status: '',
            action: '',
            amount: '',
            created: '',
            finished: '',
            client_code: '',
            reference_id: '',
            customer_code: '',
            split_strategy: '',
            execution_strategy: '',
            executed: {
                price: '',
                value: '',
                amount: '',
                bank_fee: '',
                broker_fee: '',
                partial: true,
            },
            suborders: [],
            market_item: {
                base: '',
                quote: '',
                exchange_code: '',
            },
        };
        this.config = config;
        this.pollingService = new ApiPollService(delay || 10000);
    }
    getData() {
        return this.model;
    }
    handleResponse(response) {
        if (response.data) {
            this.model = response.data;
            return this.model;
        }
        return this.model;
    }
    makeRequest() {
        const { ErrorLogger } = window;
        const axiosRequest = axios(this.config);
        return axiosRequest
            .then(response => this.handleResponse(response))
            .catch((error) => {
            if (error.message === 'canceled') {
                return this.handleReject(error);
            }
            ErrorLogger.setErrors(error, new Date());
            return this.handleReject(error);
        });
    }
    makePollRequest(callback, delay) {
        if (delay) {
            this.pollingService.delay = delay;
        }
        if (this.config.url) {
            const callbackPayload = {
                url: this.config.url,
                action: callback,
            };
            this.pollingService.addCallback(callbackPayload);
        }
    }
    clearPollRequest(url) {
        this.pollingService.removeCallBack(url);
    }
    handleReject(error) {
        throw error;
    }
}
