import { Box, Button, Grid, TextField } from '@mui/material';
import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
const formSchema = yup
    .object({
    name: yup.string().required().default(''),
})
    .required();
const formConfig = {
    name: { label: 'Name', type: 'text', required: true },
};
const PriceFeedEdit = (props) => {
    const { onClose, onSave, priceFeed, loading } = props;
    const { handleSubmit, register, trigger, setValue, formState: { errors }, } = useForm({
        defaultValues: formSchema.cast(priceFeed),
        resolver: yupResolver(formSchema),
    });
    return (React.createElement(Box, { component: 'form', onSubmit: handleSubmit(onSave) },
        React.createElement(Grid, { container: true, spacing: 2, py: 2 }, Object.keys(formConfig).map((s, i) => {
            const key = s;
            const field = formConfig[key];
            return (React.createElement(Grid, { item: true, xs: 6, key: i }, field.type === 'text' && (React.createElement(TextField, Object.assign({ fullWidth: true, InputLabelProps: { shrink: true }, error: !!errors[key], size: 'small', required: field.required, label: field.label, placeholder: field.label }, register(key), { onChange: (e) => {
                    setValue(key, e.target.value);
                    trigger(key);
                } })))));
        })),
        React.createElement(Box, { sx: { display: 'flex', justifyContent: 'flex-end', gap: 1 } },
            React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained' }, "Save"),
            React.createElement(Button, { onClick: onClose }, "Cancel"))));
};
export default PriceFeedEdit;
