import { Box, Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DLTAutocomplete from '../../../../../shared/components/form/DLTAutocomplete';
import axios from 'axios';
import { composeErrorMessage } from '../../../../../shared/helpers/interceptors';
import showNotification from '../../../../../shared/helpers/notifications';
import Messages from '../../../../../shared/helpers/errorMessages';
import { connect } from 'react-redux';
import { getCustomersSelectedInputInterface } from '../../../../../redux/selectors/customerSearchSelectors';
import { CUSTOMER_SEARCH_SET_CLIENT, } from '../../../../../redux/actionTypes/customerSearchActionsTypes';
import { getEnvSettings } from '../../../../../config/environmentSettings';
import LoadingButton from '@mui/lab/LoadingButton';
import { getCustodyCurrenciesOptions } from '../../../../../redux/selectors/currenciesSelectors';
const setCustomersClient = (client) => ({
    type: CUSTOMER_SEARCH_SET_CLIENT,
    selectedClient: client,
});
const formSchema = yup.object({
    currency: yup.string().required().nullable(),
    client: yup.string().required().nullable(),
    customer: yup.string().required().nullable(),
});
const InitiateSettlements = (props) => {
    const { customers, clients, currencies, dispatchClientSelect, depositHolder } = props;
    const { handleSubmit, control } = useForm({
        defaultValues: formSchema.cast({ currency: null, client: null, customer: null }),
        resolver: yupResolver(formSchema),
    });
    // const [currencies, setCurrencies] = useState<{ value: string | null; label: string }[]>([]);
    const [loading, setLoading] = useState(false);
    const selectedClient = useWatch({ control, name: 'client' });
    const handleInitiateSettlements = (currency, client, customer) => {
        setLoading(true);
        const params = {
            deposit_holder_code: client || customer ? null : depositHolder,
            client_code: client,
            customer_code: customer,
            currency_code: currency,
        };
        axios
            .post('/settlements/accounts/create_manually', params, { baseURL: getEnvSettings().adminApiUrl })
            .then(() => {
            props.toggleDialog(true);
            showNotification({
                message: `Settlements were initiated`,
                color: 'success',
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.SETTLEMENTS_INITIATE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
            });
        })
            .finally(() => {
            setLoading(false);
        });
    };
    useEffect(() => {
        dispatchClientSelect(selectedClient);
    }, [selectedClient]);
    return (React.createElement(React.Fragment, null,
        React.createElement(DialogTitle, null, "Initiate Settlements"),
        React.createElement(Box, { component: 'form', onSubmit: handleSubmit((data) => handleInitiateSettlements(data.currency, data.client, data.customer)) },
            React.createElement(DialogContent, null,
                React.createElement(Box, { sx: { display: 'flex', flexWrap: 'wrap' }, gap: 2 },
                    React.createElement(DLTAutocomplete, { fullWidth: true, name: 'currency', label: 'Currency', options: currencies, control: control }),
                    React.createElement(DLTAutocomplete, { fullWidth: true, label: 'Client', name: 'client', options: clients, control: control }),
                    React.createElement(DLTAutocomplete, { disabled: !selectedClient, fullWidth: true, label: 'Customer', name: 'customer', options: customers, control: control }))),
            React.createElement(DialogActions, null,
                React.createElement(LoadingButton, { loading: loading, type: 'submit', variant: 'contained' }, "Initiate"),
                React.createElement(Button, { onClick: () => props.toggleDialog(false) }, "Cancel")))));
};
const mapStateToProps = (state) => {
    var _a;
    const mappedCustomers = getCustomersSelectedInputInterface(state).map((c) => ({
        value: c.value,
        label: c.label,
    }));
    mappedCustomers.unshift({ value: null, label: 'All' });
    const mappedClients = state.clientSearch.allClients.map((c) => ({
        value: c.code,
        label: c.company_name,
    }));
    mappedClients.unshift({ value: null, label: 'All' });
    return {
        customers: mappedCustomers,
        clients: mappedClients,
        currencies: getCustodyCurrenciesOptions(state),
        depositHolder: ((_a = state.client.clientInfo) === null || _a === void 0 ? void 0 : _a.deposit_holder_code) || null,
    };
};
const mapDispatchToProps = (dispatch) => ({
    dispatchClientSelect: (client) => dispatch(setCustomersClient(client)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InitiateSettlements);
