import React, { useState } from 'react';
import { Box, Dialog, Button, TextField, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
const TwoFAModal = ({ open, codes, handleFunction, toggleFunc, loading, }) => {
    const [twoFA, setTwoFA] = useState('');
    const sendRequest = () => {
        const payload = {
            codes,
            totp_code: twoFA,
        };
        handleFunction(payload);
        setTwoFA('');
    };
    const onTwoFAChange = (evt) => {
        const { value } = evt.target;
        setTwoFA(value);
    };
    const handleChange = (evt) => {
        const { value } = evt.target;
        if (isNaN(Number(value)) || value.length > 6) {
            return;
        }
        onTwoFAChange(evt);
    };
    return (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
        React.createElement(DialogTitle, { id: "form-dialog-title" }, "Bulk approve"),
        React.createElement(DialogContent, null,
            React.createElement(Box, { display: 'flex', flexDirection: 'column', gap: 2 },
                React.createElement(Box, { p: 2 }, "You're approving bulk Withdrawal Addresses"),
                React.createElement(Box, null,
                    React.createElement(TextField, { id: "Two FA", type: "text", name: "Two FA", variant: "outlined", size: "small", value: twoFA, label: "Two FA", fullWidth: true, onChange: handleChange, inputProps: { pattern: '^(?:[0-9]\d*|0)?(?:\.\d+)?$' } })))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { color: "primary", variant: "contained", onClick: sendRequest, disabled: twoFA.length !== 6 }, "Approve"),
            React.createElement(Button, { variant: "outlined", onClick: toggleFunc }, "Cancel"))));
};
export default TwoFAModal;
