import * as types from '../actionTypes/workspaceActions';
import { readFromLocalStorage } from '../../shared/helpers/localStorageInterface';
const initialState = {
    sideMenuCollapsed: !!readFromLocalStorage('sideMenuCollapsed'),
    balances: readFromLocalStorage('balancesWidget'),
    lastSeenVersion: localStorage.getItem('lastSeenVersion'),
    workspaces: [],
    dashboards: [
        {
            name: 'trading',
            workspaces: [],
            activeWorkspace: 'system',
        },
        {
            name: 'custody',
            workspaces: [],
            activeWorkspace: 'custodySystem',
        },
        {
            name: 'manageOrders',
            workspaces: [],
            activeWorkspace: 'manageOrdersSystem',
        },
    ],
    loading: false,
    mappedDefaultWorkspaceRecord: null,
    activeDashboard: '',
};
const updateObjectInArray = (workspaces, action) => {
    if (action.type !== types.APPLY_SETTINGS_TO_WORKSPACE) {
        return [];
    }
    return workspaces.map((item, index) => {
        if (index !== action.index) {
            return item;
        }
        return Object.assign(Object.assign({}, item), { widgetSettings: {
                byId: Object.assign({}, item.widgetSettings.byId, action.workingSettings.byId),
                allIds: [],
            } });
    });
};
export const createDashboardInArray = (dashboards, action) => {
    if (action.type !== types.DASHBOARD_CREATE) {
        return [];
    }
    return dashboards.map((item) => {
        if (item.name !== action.dashboardName) {
            return item;
        }
        return Object.assign(Object.assign({}, item), { activeWorkspace: action.dashboardId, workspaces: [...item.workspaces, action.dashboardId] });
    });
};
export const updateDashboardInArray = (dashboards, action) => {
    if (action.type !== types.DASHBOARD_UPDATE) {
        return [];
    }
    return dashboards.map((item) => {
        if (item.name !== action.dashboardName) {
            return item;
        }
        return Object.assign(Object.assign({}, item), { activeWorkspace: action.dashboardId });
    });
};
export const deleteDashboardInArray = (dashboards, action) => {
    if (action.type !== types.DASHBOARD_DELETE) {
        return [];
    }
    return dashboards.map((item) => {
        if (item.name !== action.dashboardName) {
            return item;
        }
        return Object.assign(Object.assign({}, item), { workspaces: item.workspaces.filter((w) => w !== action.dashboardId) });
    });
};
/* eslint-disable @typescript-eslint/no-unsafe-assignment,
   @typescript-eslint/no-unsafe-member-access,
   @typescript-eslint/no-unsafe-return */
const workSpaceReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.WORKSPACE_FETCH_REQUEST:
            return Object.assign(Object.assign({}, state), { loading: true });
        case types.WORKSPACE_FETCH_SUCCESS:
            return Object.assign(Object.assign({}, state), { workspaces: action.workspaces, loading: false });
        case types.WORKSPACE_FETCH_FAIL:
            return Object.assign(Object.assign({}, state), { workspaces: [], loading: false });
        case types.ADD_NEW_WORKSPACE:
            return Object.assign(Object.assign({}, state), { workspaces: [...state.workspaces, action.workspaceRecord] });
        case types.REMOVE_WORKSPACE:
            state.workspaces.splice(action.index, 1);
            return Object.assign(Object.assign({}, state), { workspaces: [...state.workspaces] });
        case types.APPLY_SETTINGS_TO_WORKSPACE:
            return Object.assign(Object.assign({}, state), { workspaces: updateObjectInArray(state.workspaces, action) });
        case types.DEFAULT_WORKSPACE_UPDATE:
            return Object.assign(Object.assign({}, state), { mappedDefaultWorkspaceRecord: action.workspace, activeDashboard: action.activeDashboard });
        case types.DASHBOARD_CREATE:
            return Object.assign(Object.assign({}, state), { dashboards: createDashboardInArray(state.dashboards, action) });
        case types.DASHBOARD_UPDATE:
            return Object.assign(Object.assign({}, state), { dashboards: updateDashboardInArray(state.dashboards, action) });
        case types.DASHBOARD_DELETE:
            return Object.assign(Object.assign({}, state), { dashboards: deleteDashboardInArray(state.dashboards, action) });
        case types.DASHBOARD_POPULATE:
            return Object.assign(Object.assign({}, state), { dashboards: action.dashboards });
        case types.SIDE_MENU_UPDATE:
            return Object.assign(Object.assign({}, state), { sideMenuCollapsed: action.collapsed });
        case types.BALANCES_STATE_UPDATE:
            return Object.assign(Object.assign({}, state), { balances: { pinned: action.pinned } });
        case types.UPDATE_LAST_SEEN_VERSION:
            localStorage.setItem('lastSeenVersion', action.newVersion);
            return Object.assign(Object.assign({}, state), { lastSeenVersion: action.newVersion });
        default:
            return state;
    }
};
export default workSpaceReducer;
