import React from 'react';
import { Link, Route, Switch, useParams, useRouteMatch, } from 'react-router-dom';
const Deposit = () => {
    const { depositId } = useParams();
    return (React.createElement("div", { className: "megamodal_block_container" },
        React.createElement("h3", null,
            "We can fetch data here for Deposit: ",
            depositId)));
};
const Deposits = () => {
    const { path, url } = useRouteMatch();
    return (React.createElement("div", null,
        React.createElement("h4", null, "All deposits"),
        React.createElement("ul", null,
            React.createElement("li", null,
                React.createElement(Link, { to: `${url}/deposit_id1` }, "deposit 1")),
            React.createElement("li", null,
                React.createElement(Link, { to: `${url}/deposit_id2` }, "deposit 2")),
            React.createElement("li", null,
                React.createElement(Link, { to: `${url}/deposit_id3` }, "deposit 3"))),
        React.createElement(Switch, null,
            React.createElement(Route, { exact: true, path: path },
                React.createElement("h3", null, "Deposit related content")),
            React.createElement(Route, { path: `${path}/:depositId` },
                React.createElement(Deposit, null)))));
};
export default Deposits;
