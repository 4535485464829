import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
const initPayload = {
    base: [],
    quote: [],
    action_type: '',
    exchange_code: [],
    customer_code: '',
    partial_order_code: [],
};
const CancelOrdersDialog = ({ open, toggleFunc, cancelAllOrders, payload = initPayload, toggleSeveral = () => undefined, }) => (React.createElement(Dialog, { open: open, onClose: () => toggleFunc(), maxWidth: "sm", fullWidth: true },
    React.createElement(DialogTitle, { id: "form-dialog-title" }, "Confirmation"),
    React.createElement(DialogContent, null,
        React.createElement("div", { className: "modal__footer" },
            React.createElement("div", { className: "loading_block_flex" },
                React.createElement("div", { className: "cancel_all_orders_body" }, "Are you sure you want to cancel all orders?"),
                payload !== null && payload.quote.length > 0 ? (React.createElement("div", { className: "cancel_all_orders_body" },
                    "Fiat: ",
                    JSON.stringify(payload.quote))) : null,
                payload !== null && payload.base.length > 0 ? (React.createElement("div", { className: "cancel_all_orders_body" },
                    "Crypto: ",
                    JSON.stringify(payload.base))) : null,
                payload !== null && payload.exchange_code.length > 0 ? (React.createElement("div", { className: "cancel_all_orders_body" },
                    "Exchange: ",
                    JSON.stringify(payload.exchange_code))) : null,
                payload !== null && payload.action_type.length > 0 ? (React.createElement("div", { className: "cancel_all_orders_body" },
                    "Type: ",
                    payload.action_type)) : null))),
    React.createElement(DialogActions, null,
        React.createElement(Button, { color: "primary", variant: "contained", onClick: () => { cancelAllOrders(payload); toggleFunc(); toggleSeveral(); } }, "Yes"),
        React.createElement(Button, { variant: "outlined", onClick: toggleFunc }, "No"))));
export default CancelOrdersDialog;
