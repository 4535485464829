import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import Alert from '@mui/material/Alert';
import { connect } from 'react-redux';
import SubOrderbookComponent from './components/SubOrderbookComponent';
import SubOrdersService from '../../../../../services/subOrdersService';
import { useServiceFetchApis } from '../../../../../shared/hooks/useServiceFetchApis';
import { FilterGroup, FilterKey } from '../MergedOrderList/styling/styledComponents';
import { collectParamsFromSettings, buildUrlFromParams } from '../../../../../shared/helpers/createUrlFromParams';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const renderFilterParams = (filterParams, paramKeys) => {
    if (filterParams === null) {
        return null;
    }
    return (React.createElement(Box, { p: 2 },
        React.createElement("div", null, paramKeys.map(key => (React.createElement(FilterGroup, null,
            React.createElement(FilterKey, null, key === 'exchange_code' ? 'exchange' : key),
            " : ",
            filterParams[key]
                .map(param => React.createElement(Chip, { label: param, size: "small" }))))))));
};
/* eslint-disable react/jsx-indent */
const SubOrderbook = ({ subPage, settings, widgetKey, setSubPage, selectedClient, selectedCustomer, clientInfo, masterTenant, }) => {
    const [total, setTotal] = useState('0');
    const [settingsUrl, setSettingUrl] = useState('');
    const [paramKeys, setParamsKeys] = useState([]);
    const [filterParams, setFilterParams] = useState(null);
    const [subOrders, setSubOrders] = useState([]);
    const [service, setService] = useState(null);
    const { data } = useServiceFetchApis(service, settingsUrl, 4000);
    const handlePageChange = (event, page) => {
        setSubPage(page);
    };
    const handleSettingsChange = (widgetSettings, customer, page, selectedClient) => {
        const client = (masterTenant && widgetKey === 'ordersListRfq')
            ? { value: (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code) || '', label: '' } : selectedClient;
        const params = collectParamsFromSettings(widgetSettings, `${widgetKey}Suborders`);
        const settingUrl = buildUrlFromParams(params, customer, page, true, client);
        setSettingUrl(settingUrl);
    };
    useEffect(() => {
        const subOrdersService = new SubOrdersService({
            url: '',
            method: 'get',
        }, '/orders');
        setService(subOrdersService);
    }, []);
    useEffect(() => {
        if (data) {
            setSubOrders(data.records);
            setTotal(data.total);
        }
    }, [data]);
    useEffect(() => {
        if (settings && settings.length) {
            handleSettingsChange(settings, selectedCustomer, subPage + 1, selectedClient);
        }
    }, [settings, selectedCustomer, subPage, selectedClient]);
    useEffect(() => {
        const params = collectParamsFromSettings(settings, `${widgetKey}Suborders`);
        const keys = Object.keys(params);
        setParamsKeys(keys);
        setFilterParams(params);
    }, [settings]);
    return (React.createElement("div", null,
        renderFilterParams(filterParams, paramKeys),
        React.createElement("div", null, !subOrders.length ? (React.createElement(Box, { p: 1 },
            React.createElement(Alert, { severity: "info", variant: "outlined" }, "No items found"))) : (React.createElement(SubOrderbookComponent, { total: total, subOrders: subOrders, pageSuborder: subPage, onPageChange: handlePageChange })))));
};
const mapStateToProps = (state) => ({
    selectedClient: getSelectedClientInputValue(state),
    selectedCustomer: state.customersSearch.selectedCustomer,
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
});
export default connect(mapStateToProps)(SubOrderbook);
