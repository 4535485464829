import * as types from '../actionTypes/currenciesActionTypes';
export const initialState = {
    custodyCurrencies: [],
    tradingCurrencies: [],
    loadingCurrencies: false,
};
const currenciesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_CURRENCIES_INIT:
            return Object.assign(Object.assign({}, state), { loadingCurrencies: true });
        case types.FETCH_CURRENCIES_FAIL:
            return Object.assign(Object.assign({}, state), { loadingCurrencies: false });
        case types.FETCH_CURRENCIES_SUCCESS:
            return Object.assign(Object.assign({}, state), { loadingCurrencies: false, custodyCurrencies: action.currencies.custody, tradingCurrencies: action.currencies.trading });
        default:
            return state;
    }
};
export default currenciesReducer;
