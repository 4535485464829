import React, { useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, } from 'react-redux';
import shortid from 'shortid';
import buildUrl from 'build-url';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import ListItem from '@mui/material/ListItem';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { makeStyles, createStyles } from '@mui/styles';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { MaterialTableWrap, MaterialPopoverWrap, WalletsClientContainer, } from '../../../../Custody/styling/style';
import { INVITES_FETCH_REQUEST, ROWS_PER_PAGE__INVITES_SET_UPDATE, ADMIN_INVITES_PAGE_OF_ITEMS_SET_UPDATE, } from '../../../../../../redux/actionTypes/adminActionTypes';
import InvitesTableCell from './InvitesTableCell';
import InvitesCellActions from './InvitesCellActions';
import ReSendInvite from './components/InvitesActions';
import Messages from '../../../../../../shared/helpers/errorMessages';
import GreenSwitch from '../../../../../../shared/helpers/greenSwitch';
import InvitesService from '../../../../../../services/invitesService';
import Permissions from '../../../../../Layout/AdminSidebar/Permissions';
import showNotification from '../../../../../../shared/helpers/notifications';
import { composeErrorMessage } from '../../../../../../shared/helpers/interceptors';
import resizeWidgetHeight from '../../../../../../shared/helpers/resizeWidgetHeight';
import { getWidgetColumns } from '../../../../../../redux/selectors/custodySelectors';
import { getAllCustomers } from '../../../../../../redux/selectors/customerSearchSelectors';
import { CLOSE_ERROR_NOTICE } from '../../../../../../redux/actionTypes/apiErrorsActionTypes';
import { applyAllSettingsChanges } from '../../../../../../redux/actions/widgetSettingsActions';
import { SCHEMA_WORKING_REQUEST } from '../../../../../../redux/actionTypes/widgetSettingsActionTypes';
import { getEnvSettings } from '../../../../../../config/environmentSettings';
import { getSelectedClientInputValue } from '../../../../../../redux/selectors/clientSearchSelectors';
/* eslint-disable camelcase, @typescript-eslint/no-explicit-any,
  no-nested-ternary, @typescript-eslint/no-non-null-assertion */
const useStyles = makeStyles(() => createStyles({
    paperRoot: {
        '& > .MuiPaper-root': {
            backgroundColor: 'transparent',
        },
    },
}));
export const PageOfItemsInvitesUpdate = (pageOfItemsAdminInvites) => ({
    type: ADMIN_INVITES_PAGE_OF_ITEMS_SET_UPDATE,
    pageOfItemsAdminInvites,
});
const WidgetUnusedInvites = ({ columns, features, customers, clientInfo, selectedClient, totalInvites, unusedInvites, clientFromUsers, rowsPerPageInvites, invitesWidgetHeight, pageOfItemsAdminInvites, PageOfItemsInvitesUpdateDispatch, }) => {
    const classes = useStyles();
    const [url, setUrl] = useState('');
    const [total, setTotal] = useState(0);
    const [maxHeight, setMaxHeight] = useState(480);
    const [invites, setInvites] = useState([]);
    const [anchorColumns, setAnchorColumns] = useState(null);
    const [columnsToRender, setColumnsToRender] = useState([]);
    const columnsOpen = Boolean(anchorColumns);
    const dispatch = useDispatch();
    const errorNotice = useCallback(() => dispatch({ type: CLOSE_ERROR_NOTICE }), [dispatch]);
    const hideShowColumn = (colKey) => {
        const column = columns.find(c => c.key === colKey);
        const colIndex = columnsToRender.findIndex(c => c.key === (column === null || column === void 0 ? void 0 : column.key));
        const payload = {
            settingId: column === null || column === void 0 ? void 0 : column.id,
            settingValue: column === null || column === void 0 ? void 0 : column.value,
        };
        if (colIndex !== -1) {
            payload.settingValue = false;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else if (column) {
            payload.settingValue = true;
            dispatch({ type: SCHEMA_WORKING_REQUEST, payload });
        }
        else {
            setColumnsToRender([...columnsToRender]);
        }
        dispatch(applyAllSettingsChanges('manageUsers'));
    };
    const onChangePage = (event, pageNumber) => {
        PageOfItemsInvitesUpdateDispatch(pageNumber);
    };
    const handleColumnsClick = (event) => {
        setAnchorColumns(event.currentTarget);
    };
    const handleColumnsClose = () => {
        setAnchorColumns(null);
    };
    const setRowsPerPage = (value) => {
        dispatch({ type: ROWS_PER_PAGE__INVITES_SET_UPDATE, rowsPerPageInvites: value });
    };
    const cellActions = {
        actions: {
            reSendInvite: () => undefined,
        },
        actionsComponents: {
            reSendInvite: ReSendInvite,
        },
        actionsList: [
            { name: 'Re-send', action: 'reSendInvite' },
        ],
    };
    const handleReSendInvite = (email) => {
        const payload = { email };
        const invitesService = new InvitesService({
            url: '/invites/notification',
            method: 'post',
            data: payload,
            baseURL: getEnvSettings().frontendApiUrl,
        });
        invitesService.makeRequest()
            .then(() => {
            dispatch({ type: INVITES_FETCH_REQUEST, urlOptions: url });
            showNotification({
                message: 'Invite successfully send',
                color: 'success',
                dispatch: errorNotice,
            });
        })
            .catch((e) => {
            const message = composeErrorMessage(e, Messages.RE_SEND_INVITE);
            showNotification({
                message: `Error: ${message}`,
                color: 'error',
                dispatch: errorNotice,
            });
        });
    };
    useEffect(() => {
        if (columns && columns.length) {
            const cols = columns.filter(c => c.value);
            setColumnsToRender([...cols]);
        }
    }, [columns]);
    useEffect(() => {
        setInvites(unusedInvites);
        setTotal(totalInvites);
    }, [unusedInvites, totalInvites]);
    useEffect(() => {
        const urlOptionsForInvites = {
            queryParams: {
                limit: `${rowsPerPageInvites}`,
                page: `${pageOfItemsAdminInvites + 1}`,
                search: '',
                sort_by: 'created',
                sort_direction: 'desc',
                timeoutTriggered: 'false',
            },
        };
        if (clientFromUsers) {
            urlOptionsForInvites.queryParams.client_code = clientFromUsers.value;
        }
        else if (selectedClient) {
            urlOptionsForInvites.queryParams.client_code = selectedClient.value;
        }
        else if (clientInfo) {
            urlOptionsForInvites.queryParams.client_code = clientInfo.code;
        }
        const endpointUrlForInvites = buildUrl('', urlOptionsForInvites);
        setUrl(endpointUrlForInvites);
        dispatch({ type: INVITES_FETCH_REQUEST, urlOptions: endpointUrlForInvites });
    }, [clientInfo, selectedClient, clientFromUsers, pageOfItemsAdminInvites, rowsPerPageInvites]);
    useEffect(() => {
        resizeWidgetHeight(invitesWidgetHeight, setRowsPerPage, setMaxHeight);
    }, [invitesWidgetHeight]);
    return (React.createElement(Permissions, { features: features, permissions: ['invites.read'] },
        React.createElement("div", { className: "card-body unused_invites_wrap" },
            React.createElement(WalletsClientContainer, null,
                React.createElement("div", null,
                    React.createElement(IconButton, { onClick: handleColumnsClick, size: "large" },
                        React.createElement(ViewColumnIcon, null)),
                    React.createElement(Popover, { open: columnsOpen, anchorEl: anchorColumns, onClose: handleColumnsClose, anchorOrigin: { vertical: 'top', horizontal: 'left' }, transformOrigin: { vertical: 'top', horizontal: 'center' } },
                        React.createElement(MaterialPopoverWrap, null,
                            React.createElement(List, { dense: true, component: "nav", "aria-label": "more actions", className: "mui_table_settings_wrap" }, columns.map(col => (React.createElement(ListItem, { key: col.id },
                                React.createElement(ListItemText, { primary: col.title }),
                                React.createElement(ListItemSecondaryAction, null,
                                    React.createElement(GreenSwitch, { edge: "end", size: "small", color: "default", onChange: () => hideShowColumn(col.key), checked: !!columnsToRender.find(c => c.key === col.key), inputProps: { 'aria-labelledby': 'switch-list-label-wifi' } })))))))))),
            React.createElement(MaterialTableWrap, { className: classes.paperRoot },
                React.createElement(Paper, { className: "mui_table_root" },
                    React.createElement(TableContainer, { style: { maxHeight } },
                        React.createElement(Table, { size: "small", stickyHeader: true, "aria-label": "sticky table" },
                            React.createElement(TableHead, null,
                                React.createElement(TableRow, null,
                                    columnsToRender.map(column => (React.createElement(TableCell, { key: column.key, className: "mui_table_head_bg", style: { minWidth: column.minWidth }, align: column.key === 'created_at' ? 'left' : 'right' }, column.title))),
                                    React.createElement(TableCell, { key: "actions", align: "right", className: "tokens_table_cell_width mui_table_head_bg" }, "Actions"))),
                            React.createElement(TableBody, null, invites.map(invite => (React.createElement(TableRow, { hover: true, role: "checkbox", tabIndex: -1, key: shortid.generate() },
                                columnsToRender.map((column, i) => {
                                    const key = column.key;
                                    const value = key ? invite[key] : '';
                                    return (React.createElement(InvitesTableCell, { key: i, value: value, columnKey: key, customers: customers }));
                                }),
                                React.createElement(InvitesCellActions, { item: invite, actions: cellActions, handleClick: handleReSendInvite }))))))),
                    React.createElement(TablePagination, { count: total, component: "div", onPageChange: onChangePage, page: pageOfItemsAdminInvites, rowsPerPage: rowsPerPageInvites, rowsPerPageOptions: [maxHeight] }))))));
};
const mapStateToProps = (state, ownProps) => ({
    features: state.client.features,
    customers: getAllCustomers(state),
    clientInfo: state.client.clientInfo,
    totalInvites: state.admin.totalInvites,
    unusedInvites: state.admin.unusedInvites,
    selectedClient: getSelectedClientInputValue(state),
    clientFromUsers: state.admin.clientFromUsers,
    rowsPerPageInvites: state.admin.rowsPerPageInvites,
    columns: getWidgetColumns(state, ownProps.widgetKey),
    invitesWidgetHeight: state.widgets[ownProps.widgetKey],
    pageOfItemsAdminInvites: state.admin.pageOfItemsAdminInvites,
});
const mapDispatchToProps = dispatch => ({
    PageOfItemsInvitesUpdateDispatch: (page) => dispatch(PageOfItemsInvitesUpdate(page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WidgetUnusedInvites);
