import axios from 'axios';
import * as types from '../actionTypes/subOrdersActionTypes';
/* eslint-disable @typescript-eslint/restrict-template-expressions */
export const populateSubordersList = (subOrders) => ({
    type: types.SUBORDERS_ORDER_POPULATE,
    subOrders,
});
export const addNewSubOrder = (subOrder) => ({
    type: types.SUBORDERS_ORDER_ADD,
    subOrder,
});
export const removeSubOrder = (suborderId) => ({
    type: types.SUBORDERS_ORDER_REMOVE,
    id: suborderId,
});
export const cleanSuborders = () => ({
    type: types.SUBORDERS_ORDER_CLEAN,
});
export const upDateSubOrder = (subOrder) => ({
    type: types.SUBORDERS_ORDER_UPDATE,
    subOrder,
});
export const apiRemoveSubOrder = (uid, code) => axios
    .delete(`/orders/partials/${uid}/suborders/${code}`)
    .then(response => response)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
export const apiExecuteSubOrder = (uid, code) => axios
    .post(`/orders/partials/${uid}/suborders/${code}/execute`)
    .then(response => response)
    .catch((error) => {
    const { ErrorLogger } = window;
    ErrorLogger.setErrors(error, new Date());
    if (error.response) {
        throw error;
    }
    else {
        throw new Error(`Unknown, error: ${error}`);
    }
});
