import { styled } from '@mui/material/styles';
export const Amount = styled('span', {
    shouldForwardProp: (prop) => {
        return prop !== 'size' && prop !== 'bold' && prop !== 'textColor';
    },
})((prop) => ({
    whiteSpace: 'nowrap',
    fontFamily: 'Martian Mono, monospace',
    fontSize: prop.size ? `${prop.size}px` : '12px',
    fontWeight: prop.bold ? 'bold' : 'normal',
    color: prop.textColor,
}));
