import React, { useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import Alert from '@mui/lab/Alert';
import { connect } from 'react-redux';
import PartialOrderbook from './components/PartialOrderbook';
import SplitOrdersService from '../../../../../services/splitOrdersService';
import { useServiceFetchApis } from '../../../../../shared/hooks/useServiceFetchApis';
import { FilterGroup, FilterKey } from '../MergedOrderList/styling/styledComponents';
import { collectParamsFromSettings, buildUrlFromParams } from '../../../../../shared/helpers/createUrlFromParams';
import { getSelectedClientInputValue } from '../../../../../redux/selectors/clientSearchSelectors';
const renderFilterParams = (filterParams, paramKeys) => {
    if (filterParams === null) {
        return null;
    }
    return (React.createElement(Box, { p: 2 },
        React.createElement("div", null, paramKeys.map(key => (React.createElement(FilterGroup, null,
            React.createElement(FilterKey, null, key === 'exchange_code' ? 'exchange' : key),
            " : ",
            filterParams[key]
                .map(param => React.createElement(Chip, { label: param }))))))));
};
/* eslint-disable @typescript-eslint/no-explicit-any, react/jsx-indent */
const PartialOrderBooks = ({ settings, splitPage, widgetKey, clientInfo, selectedClient, setSplitPage, masterTenant, selectedCustomer, }) => {
    const [total, setTotal] = useState('0');
    const [settingsUrl, setSettingUrl] = useState('');
    const [paramKeys, setParamsKeys] = useState([]);
    const [service, setService] = useState(null);
    const [partialOrders, setPartialOrders] = useState([]);
    const [filterParams, setFilterParams] = useState(null);
    const { data } = useServiceFetchApis(service, settingsUrl, 4000);
    const handlePageChange = (event, page) => {
        setSplitPage(page);
    };
    const handleSettingsChange = (widgetSettings, customer, page, selectedClient) => {
        const client = (masterTenant && widgetKey === 'ordersListRfq')
            ? { value: (clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.code) || '', label: '' } : selectedClient;
        const params = collectParamsFromSettings(widgetSettings, `${widgetKey}PartialOrderbook`);
        const settingUrl = buildUrlFromParams(params, customer, page, false, client);
        setSettingUrl(settingUrl);
    };
    useEffect(() => {
        const serviceInstance = new SplitOrdersService({ url: '', method: 'get' }, '/orders/partials');
        setService(serviceInstance);
    }, []);
    useEffect(() => {
        if (data) {
            setPartialOrders(data.records);
            setTotal(data.total);
        }
    }, [data]);
    useEffect(() => {
        if (settings && settings.length) {
            handleSettingsChange(settings, selectedCustomer, splitPage + 1, selectedClient);
        }
    }, [settings, selectedCustomer, splitPage, selectedClient]);
    useEffect(() => {
        const params = collectParamsFromSettings(settings, `${widgetKey}PartialOrderbook`);
        const keys = Object.keys(params);
        setParamsKeys(keys);
        setFilterParams(params);
    }, [settings]);
    return (React.createElement("div", null,
        renderFilterParams(filterParams, paramKeys),
        React.createElement("div", null, !partialOrders.length ? (React.createElement(Box, { p: 1 },
            React.createElement(Alert, { severity: "info", variant: "outlined" }, "No items found"))) : (React.createElement(PartialOrderbook, { total: total, pageSplit: splitPage, partialOrders: partialOrders, onPageChange: handlePageChange })))));
};
const mapStateToProps = (state) => ({
    clientInfo: state.client.clientInfo,
    masterTenant: state.client.masterTenant,
    selectedClient: getSelectedClientInputValue(state),
    selectedCustomer: state.customersSearch.selectedCustomer,
});
export default connect(mapStateToProps)(PartialOrderBooks);
